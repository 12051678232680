import {BlockComponentProps} from 'Event/Marketplace/Block'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import FontSelect from 'lib/FontSelect'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import React from 'react'
import {Controller} from 'react-hook-form'

interface SummaryColumnLabelConfigProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  children: string
  inputName: string
}

export default function SummaryColumnLabelConfig(
  props: SummaryColumnLabelConfigProps,
) {
  const {id, inputName} = props
  const {sectionId, update} = useConfigurableSection()

  const save = (data: any) => {
    update(data.sections[sectionId])
  }

  const {
    form: {control, register},
  } = useConfig()

  return (
    <Config title="Purchase Summary Column" onSave={save}>
      <SettingsPanel>
        <CollapsibleSection
          label="Label"
          noDivider
          storageKey={`blocks-${id}-styling.placeholderText`}
        >
          <TextField
            name={`sections.${sectionId}.blocks.${id}.${inputName}`}
            defaultValue={props.children}
            label="Label"
            inputProps={{
              'aria-label': 'label',
              ref: register,
            }}
          />
        </CollapsibleSection>
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Font"
          noDivider
          storageKey={`blocks-${id}-styling.summaryColumnLabel.fontSize`}
        >
          <Controller
            control={control}
            name={`sections.${sectionId}.blocks.${id}.summaryColumnLabel.font`}
            defaultValue={props.summaryColumnLabel?.font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summaryColumnLabel.fontSize`}
            defaultValue={props.summaryColumnLabel?.fontSize ?? 18}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={10}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Border"
          noDivider
          storageKey={`blocks-${id}-styling.border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summaryColumnLabel.border.width`}
            defaultValue={props.summaryColumnLabel?.border.width ?? 1}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Border Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.summaryColumnLabel.border.color`}
            defaultValue={props.summaryColumnLabel?.border.color ?? '#c7c7c7'}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
