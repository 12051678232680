import React, {useState} from 'react'
import {Speaker} from 'Event/Speakers'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {Controller} from 'react-hook-form'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {useConfig} from 'organization/Event/Configurable'
import {onChangeCheckedHandler} from 'lib/dom'
import TextField from 'lib/ui/TextField'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Button from 'lib/ui/Button'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {generateHashId} from 'lib/crypto/hash'
import styled from 'styled-components'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'

export default function FeaturedSpeakerConfig(props: {
  speaker: Speaker
  id: string
}) {
  const {speaker, id} = props
  const [rules, setRules] = useState(speaker.rules)
  const {toggle} = useConfig()
  const {speakers} = useTownhallTemplate()

  const set = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const handleSave = (data: any) => {
    const {name, text} = data.featuredSpeakers.items[id]
    const saveData = {
      localization: addTranslation({
        [`feature_speakers_items_${id}_name`]: name || 'Speaker',
        [`feature_speakers_items_${id}_text`]: text || 'Speaker bio',
      }),
      featuredSpeakers: {
        items: {
          [id]: {
            ...data.featuredSpeakers.items[id],
            name: `{{feature_speakers_items_${id}_name}}`,
            text: `{{feature_speakers_items_${id}_text}}`,
            rules,
          },
        },
      },
    }

    set(saveData)
  }

  const handleRemove = () => {
    set({
      localization: removeTranslations([
        `feature_speakers_items_${id}_name`,
        `feature_speakers_items_${id}_text`,
      ]),
      featuredSpeakers: {
        items: {
          [props.id]: REMOVE,
        },
      },
    })
  }

  const handleMoveToSpeaker = () => {
    const numSpeakers = Object.keys(speakers.items).length
    const position = numSpeakers + 1

    generateHashId([
      'townhall_',
      'feature_speaker',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      set({
        featuredSpeakers: {
          items: {
            [props.id]: REMOVE,
          },
        },
        speakers: {
          items: {
            [id]: {
              ...speaker,
              position,
            },
          },
        },
      })
    })

    toggle()
  }

  return (
    <Config
      title="Featured Speaker"
      onSave={handleSave}
      onRemove={handleRemove}
    >
      <SettingsPanel>
        <Settings
          speaker={speaker}
          id={id}
          onMoveToSpeaker={handleMoveToSpeaker}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

function Settings(props: {
  speaker: Speaker
  id: string
  onMoveToSpeaker: () => void
}) {
  const {speaker, id, onMoveToSpeaker} = props
  const v = useAttendeeVariables()
  const {
    form: {control, register},
  } = useConfig()
  return (
    <>
      <Controller
        name={`featuredSpeakers.items.${id}.isActive`}
        control={control}
        defaultValue={speaker.isActive ?? true}
        render={({onChange, value}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <TextField
        name={`featuredSpeakers.items.${id}.name`}
        label="Speaker Name"
        required
        fullWidth
        inputProps={{
          ref: register,
          'aria-label': 'set speaker name',
        }}
        defaultValue={v(speaker.name)}
      />

      <Controller
        name={`featuredSpeakers.items.${id}.text`}
        defaultValue={v(speaker.text || '')}
        control={control}
        render={({onChange, value}) => (
          <StyledTextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </StyledTextEditorContainer>
        )}
      />

      <Controller
        name={`featuredSpeakers.items.${id}.image`}
        control={control}
        defaultValue={speaker.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Image"
            uploadInputProps={{
              'aria-label': 'speaker image upload',
            }}
            width={300}
            height={300}
            canResize
            disableAutoRemove
          />
        )}
      />

      <Button variant="contained" color="primary" onClick={onMoveToSpeaker}>
        Move to Other Speakers
      </Button>
    </>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
