import React, {useEffect, useCallback} from 'react'
import {Agenda} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useAutoUpdateSidebarItem} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {useForm} from 'react-hook-form'
import moment from 'moment'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {generateHashId} from 'lib/crypto/hash'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export function AgendaItemConfig(
  props: {
    agenda: Agenda
    id?: string
    listId: string
  } & ComponentConfigProps,
) {
  const {showing, onClose, agenda, id, listId} = props
  const {handleSubmit, control, watch, setValue, formState} = useForm()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const removeTranslation = useRemoveTranslations()

  useEffect(() => {
    if (!startDate || !endDate) {
      return
    }

    const shouldAdjustEndDate = moment(endDate).isBefore(moment(startDate))
    if (!shouldAdjustEndDate) {
      return
    }

    setValue('endDate', startDate)
  }, [startDate, endDate, setValue])

  const update = (id: string, updated: Agenda) => {
    const text = replaceAtPath(updated, 'text', `{{${id}_text}}`)
    const link = replaceAtPath(updated, 'link', `{{${id}_link}}`)
    saveTemplate({
      sidebarItems: {
        [listId]: {
          items: {[id]: updated},
        },
      },
      localization: addTranslation({
        [`${id}_text`]: text ?? '',
        [`${id}_link`]: link ?? '',
      }),
    })
  }

  const insert = (item: Agenda) => {
    generateHashId([
      'agenda_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `agenda_${id}`
      saveTemplate({
        sidebarItems: {
          [listId]: {
            items: {
              [componentId]: {
                ...item,
                text: `{{${componentId}_text}}`,
                link: `{{${componentId}_link}}`,
              },
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_text`]: item.text ?? '',
          [`${componentId}_link`]: item.link ?? '',
        }),
      })
    })
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    saveTemplate({
      sidebarItems: {
        [listId]: {
          items: {[id]: REMOVE},
        },
      },
      localization: removeTranslation([`${id}_text`, `${id}_link`]),
    })

    onClose()
  }, [id, saveTemplate, listId, removeTranslation, onClose])

  useAutoUpdateSidebarItem({
    item: id ? {items: {[id]: watch()}} : {},
    when: showing && Boolean(id),
  })

  const save = (data: Agenda) => {
    if (id === undefined) {
      insert(data)
      onClose()
      return
    }

    update(id, data)
    onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agenda"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} agenda={agenda} />
      </SettingsPanel>
      <Footer>
        <SaveButton />
        <RemoveButton
          aria-label="remove agenda"
          onClick={remove}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
