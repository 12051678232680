import React from 'react'
import Button from 'lib/ui/Button'
import {Label} from 'lib/ui/typography'
import styled from 'styled-components'

export default function MoveSectionButtons(props: {
  onMoveUp?: () => void
  onMoveDown?: () => void
}) {
  const {onMoveUp, onMoveDown} = props

  if (!onMoveUp && !onMoveDown) return null

  return (
    <Box>
      <Label>Move to section</Label>
      <ButtonBox>
        {onMoveUp && (
          <Button
            className="move-button"
            fullWidth
            variant="outlined"
            onClick={onMoveUp}
          >
            Move Up
          </Button>
        )}
        {onMoveDown && (
          <Button
            className="move-button"
            fullWidth
            variant="outlined"
            onClick={onMoveDown}
          >
            Move Down
          </Button>
        )}
      </ButtonBox>
    </Box>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing[3]};
  gap: ${(props) => props.theme.spacing[3]};
`
