import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  register: UseFormMethods['register']
}) {
  const {register} = props
  const template = usePanelsTemplate()
  const {welcomeText, homeMenuTitle} = template
  const v = useAttendeeVariables()

  return (
    <>
      <Label>Welcome Text</Label>
      <TextField
        name="welcomeText"
        aria-label="welcome text"
        fullWidth
        placeholder="Text"
        defaultValue={v(welcomeText)}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Menu Title</Label>
      <TextField
        name="homeMenuTitle"
        aria-label="home menu title"
        fullWidth
        defaultValue={v(homeMenuTitle)}
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}
