import {useEvent} from 'Event/EventProvider'
import {Certificate} from 'lib/event-api/certificates/types'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

interface UseDeleteCertificateData {
  certificate: Certificate
}

export function useDeleteCertificate() {
  const {client} = useOrganization()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: UseDeleteCertificateData) => {
      const url = api(`/certificates/${data.certificate.id}`)

      return client.delete<Certificate>(url)
    },
    onSuccess: (_data, args) => {
      const {certificate: removed} = args

      qc.setQueryData<Certificate[]>(
        ['events', event.id, 'certificates'],
        (certificates) => {
          if (!certificates) {
            return []
          }

          return certificates.filter(
            (certificate) => certificate.id !== removed.id,
          )
        },
      )
    },
  })
}
