import React from 'react'
import styled from 'styled-components'

export default function PopUpLogo(props: {logo: string | null}) {
  if (!props.logo) return null

  return (
    <LogoBox>
      <img src={props.logo} aria-label="login logo" />
    </LogoBox>
  )
}

const LogoBox = styled.div`
  margin-right: ${(props) => props.theme.spacing[4]};
  flex: 1;
  text-align: center;
  min-width: 100px;

  & > img {
    max-height: 100px;
    max-width: 100%;
  }
`
