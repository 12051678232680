import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation, useQueryClient} from 'react-query'

interface UseUpdatePurchasePageParams {
  client: Client
  purchasePage: PurchasePage
}

export function useUpdatePurchasePage(params: UseUpdatePurchasePageParams) {
  const {client, purchasePage} = params

  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<PurchasePage>) => {
      const url = marketplaceApi(`/purchase_pages/${purchasePage.id}`)
      return client.put<PurchasePage>(url, data)
    },
    onSuccess: (updated) => {
      qc.setQueryData<PurchasePage>(
        ['events', purchasePage.event_id, 'purchase_page'],
        () => updated,
      )
    },
  })
}
