import {useLeftyTemplate} from 'Event/template/Lefty'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import styled from 'styled-components'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {TemplateSave} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'

export default function SpeakersHeaderConfig() {
  const save = useSaveTemplate()
  const {speakers} = useLeftyTemplate()

  const {
    form: {register, control},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const handleSave: TemplateSave<Template> = (data) => {
    const menuTitle = replaceAtPath(
      data,
      'speakers.menuTitle',
      '{{speakers_menu_title}}',
    )

    const title = replaceAtPath(data, 'speakers.title', '{{speakers_title}}')

    const description = replaceAtPath(
      data,
      'speakers.description',
      '{{speakers_description}}',
    )

    const secondaryTitle = replaceAtPath(
      data,
      'speakers.secondaryTitle',
      '{{speakers_secondary_title}}',
    )

    save({
      ...data,
      localization: addTranslation({
        speakers_menu_title: menuTitle ?? 'Our Speakers',
        speakers_title: title || 'Speakers',
        speakers_description: description || '',
        speakers_secondary_title: secondaryTitle || '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={handleSave}>
      <SettingsPanel>
        <Title>Menu</Title>
        <TextField
          name="speakers.menuTitle"
          defaultValue={v(speakers.menuTitle || 'Our Speakers')}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'menu title',
            ref: register,
          }}
        />
        <Title>Page</Title>
        <TextField
          name="speakers.title"
          defaultValue={v(speakers.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'speakers title',
            ref: register,
          }}
        />
        <TextField
          name="speakers.secondaryTitle"
          defaultValue={v(speakers.secondaryTitle)}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'speakers secondary title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <StyledTextEditorContainer>
          <Controller
            name="speakers.description"
            defaultValue={v(speakers.description)}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </StyledTextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
