import React from 'react'
import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {BlockBase} from '../base'

export interface SpacerBlock extends BlockBase {
  type: 'Spacer'
}

export default function Spacer(props: SpacerBlock & BlockComponentProps) {
  return (
    <Container {...props}>
      <div style={{width: '100%', height: '100%'}}></div>
    </Container>
  )
}
