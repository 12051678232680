import React from 'react'
import TextEditor from 'lib/ui/form/TextEditor'
import {Controller, UseFormMethods} from 'react-hook-form'
import {TextProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/Text'
import Box from 'lib/ui/Box'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  text: TextProps
}) {
  const {control, text, register} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isEnabled"
        defaultValue={text.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <Box mb={2}>
        <Box mb={1}>
          <Label>Title</Label>
        </Box>
        <TextField
          aria-label="text title"
          fullWidth
          name="title"
          defaultValue={v(text.title)}
          inputProps={{
            ref: register,
          }}
        />
      </Box>
      <Box mb={2}>
        <Box mb={1}>
          <Label>Body</Label>
        </Box>
        <Controller
          name="body"
          control={control}
          defaultValue={v(text.body)}
          render={({value, onChange}) => (
            <TextEditor data={value} onChange={onChange} />
          )}
        />
      </Box>
    </>
  )
}
