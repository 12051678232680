import React from 'react'
import styled from 'styled-components'
import FaqList from 'Event/template/Panels/Dashboard/FAQs/FaqList'
import {
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
} from 'Event/template/Panels/Dashboard/FAQs/FaqList/Card'
import {PageTitle} from 'Event/template/Panels/Page'
import {useAttendeeVariables, useGuestVariables} from 'Event'
import {usePanelsTemplate} from 'Event/template/Panels'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import AddFaqButton from 'Event/FAQs/AddFaqButton'
import TitleConfig from 'Event/FAQs/TitleConfig'
import DescriptionConfig from 'Event/FAQs/DescriptionConfig'
import Scripts, {FAQS} from 'Event/Scripts'
import {useEditMode} from 'Event/EditModeProvider'

export default function PanelsFaqPage() {
  const template = usePanelsTemplate()
  const {faq: pageSettings} = template
  const v = useGuestVariables()
  const isEditMode = useEditMode()

  if (isEditMode) {
    return <ConfigurablePanelsFaqPage />
  }

  return (
    <>
      <Scripts page={FAQS} />
      <AddFaqButton />
      <PageTitle aria-label="faqs title">
        {v(pageSettings?.title || DEFAULT_TITLE)}
      </PageTitle>
      <SubTitle>
        <Content aria-label="description">
          {v(pageSettings?.description || DEFAULT_DESCRIPTION)}
        </Content>
      </SubTitle>
      <FaqList />
    </>
  )
}

function ConfigurablePanelsFaqPage() {
  const template = usePanelsTemplate()
  const {faq: pageSettings} = template
  const v = useAttendeeVariables()

  return (
    <>
      <Scripts page={FAQS} />
      <AddFaqButton />
      <Configurable>
        <TitleConfig />
        <PageTitle aria-label="faqs title">
          {v(pageSettings?.title || DEFAULT_TITLE)}
        </PageTitle>
      </Configurable>
      <Configurable>
        <DescriptionConfig />
        <SubTitle>
          <Content aria-label="description">
            {v(pageSettings?.description || DEFAULT_DESCRIPTION)}
          </Content>
        </SubTitle>
      </Configurable>
      <FaqList />
    </>
  )
}

const SubTitle = styled.div`
  text-align: left;
  margin: 20px 20px;
`
