import ConfigurableSectionLabel, {
  ConfigurableSectionLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableSectionLabel'
import React from 'react'

export default function ConfigurableBillingSectionLabel(
  props: ConfigurableSectionLabelProps,
) {
  const {billingSectionLabel} = props

  return (
    <ConfigurableSectionLabel
      {...props}
      children={billingSectionLabel ?? 'Billing'}
      inputName="billingSectionLabel"
    />
  )
}
