import React from 'react'
import styled from 'styled-components'
import {HashMap, createPositions, orderedIdsByPosition} from 'lib/list'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import {DraggableOverlay} from 'lib/ui/drag-and-drop'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import {useToggle} from 'lib/toggle'
import BulletedListItemConfig from 'organization/Marketplace/config/BlockConfig/BulletedListItemConfig'
import {ConfigContext} from 'organization/Event/Configurable'
import {useForm} from 'react-hook-form'
import {
  BulletItem,
  BulletedListBlock,
  BulletedListContainer,
  ListItem,
} from 'Event/Marketplace/Block/BulletedList'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {
  ConfigComponentProps,
  UpdateTemplateFunction,
} from 'organization/Marketplace/config/types'
import {addNewListItem} from 'organization/Marketplace/config/ConfigurableBlock'
import {MarketplaceTemplate} from 'Event/Marketplace/marketplace-template'

export default function ConfigurableBulletedList(
  props: BulletedListBlock & BlockComponentProps & ConfigComponentProps,
) {
  const {id: blockId} = props
  const {sectionId} = useConfigurableSection()

  return (
    <BulletedListContainer {...props}>
      <ListItems sectionId={sectionId} blockId={blockId} {...props} />
    </BulletedListContainer>
  )
}

function useHandleDrag(
  props: {
    items: HashMap<BulletItem>
    blockId?: string
    sectionId?: string
  } & ConfigComponentProps,
) {
  const {items, blockId, sectionId, updateTemplate} = props

  return (result: DropResult) => {
    const {destination, source} = result

    if (!blockId || !sectionId || !destination) {
      return
    }

    const ids = orderedIdsByPosition(items)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [blockId]: {
              items: createPositions(ids),
            },
          },
        },
      },
    })
  }
}

function ListItems(
  props: BulletedListBlock & {
    blockId?: string
    sectionId?: string
    template: MarketplaceTemplate
  } & ConfigComponentProps,
) {
  const {items} = props
  const handleDrag = useHandleDrag(props)

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="drag-and-drop-bullet-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <>
                {sortedIds.map((id, index: number) => {
                  const isLast = sortedIds.length === index + 1
                  return (
                    <DraggableListItem
                      item={items[id]}
                      key={id}
                      itemId={id}
                      index={index}
                      isLast={isLast}
                      {...props}
                    />
                  )
                })}
                {provided.placeholder}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

function DraggableListItem(
  props: BulletedListBlock & {
    item: BulletItem
    index: number
    itemId: string
    isLast: boolean
    sectionId?: string
    blockId?: string
    template: MarketplaceTemplate
    updateTemplate: UpdateTemplateFunction
  },
) {
  const {
    index,
    itemId,
    item,
    sectionId,
    blockId,
    updateTemplate,
    template,
  } = props
  const {flag: showingConfig, toggle: toggleConfig} = useToggle()
  const form = useForm()

  if (!sectionId || !blockId) {
    return null
  }

  const onCopy = () => {
    addNewListItem(item, blockId, sectionId, template, updateTemplate)
  }

  return (
    <>
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <BulletedListItemConfig
          isVisible={showingConfig}
          onClose={toggleConfig}
          list={item}
          itemId={itemId}
          sectionId={sectionId}
          blockId={blockId}
          updateTemplate={updateTemplate}
        />
        <DraggableHolder>
          <Draggable draggableId={itemId} index={index}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                <DraggableOverlay>
                  <Editable
                    aria-label="edit Bulleted list item"
                    onEdit={toggleConfig}
                    onCopy={onCopy}
                    key={index}
                    handleProps={provided.dragHandleProps}
                  >
                    <>
                      <ListItem {...props} item={item} />
                    </>
                  </Editable>
                </DraggableOverlay>
              </div>
            )}
          </Draggable>
        </DraggableHolder>
      </ConfigContext.Provider>
    </>
  )
}

const DraggableHolder = styled.div`
  & > div {
    left: auto !important;
    top: auto !important;
  }
`
