import {TextBlock} from 'Event/Marketplace/Block/Text'

export const createTextBlock = (): TextBlock => ({
  type: 'Text',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  text: 'Enter your text here.',
})
