import {Layout} from 'react-grid-layout'

interface ReplaceLayoutParams {
  layouts: Layout[]
  index: number
  updated: Layout
}

export function replaceLayout(params: ReplaceLayoutParams) {
  const {layouts, index, updated} = params

  const updatedLayout = Array.from(layouts)
  updatedLayout.splice(index, 1)
  updatedLayout.splice(index, 0, updated)

  return updatedLayout
}
