import styled from 'styled-components'
import {Label} from 'lib/ui/typography'
import RuleList from 'Event/attendee-rules/RuleConfig/RuleList'
import {RuleConfigProps} from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import {Rule} from 'Event/attendee-rules'
import Visible from 'lib/ui/layout/Visible'
import React, {useEffect, useState} from 'react'
import {
  createNestedRule,
  NestedRule,
  NESTED_RULE,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule'

export default function NestedRuleConfig(props: RuleConfigProps) {
  const [rules, setRules] = useState<NestedRule['rules']>(
    initialRules(props.rule),
  )
  const {onSet} = props
  const [descriptionVisible, setDescriptionVisible] = useState(true)

  const handleShowingRuleConfig = () => {
    if (props.onToggleNestedRule) {
      props.onToggleNestedRule()
    }

    setDescriptionVisible(!descriptionVisible)
  }

  useEffect(() => {
    onSet(createNestedRule(rules))
  }, [rules, onSet])

  return (
    <>
      <Visible when={descriptionVisible}>
        <StyledBox>
          <Label>With these rules are true</Label>
        </StyledBox>
      </Visible>
      <StyledRuleList
        rules={rules}
        onChange={setRules}
        onToggleRuleConfig={handleShowingRuleConfig}
        descriptionHidden
        form={props.form}
      />
    </>
  )
}

function initialRules(rule: Rule | null) {
  if (!rule || rule.source !== NESTED_RULE) {
    return []
  }

  return rule.rules
}

const StyledRuleList = styled(RuleList)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledBox = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
