import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import {PaymentIntent} from '@stripe/stripe-js'
import DialogContent from '@material-ui/core/DialogContent'
import {useAsync} from 'lib/async'
import SingleUseCreditCardForm from 'lib/stripe/SingleUseCreditCardForm'
import {useToggle} from 'lib/toggle'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Button from 'lib/ui/Button'
import {Title} from 'lib/ui/typography'
import {api} from 'lib/url'
import {Loader} from 'obvio/Billing/CreditCardSection/DefaultCardManagement/NewCardForm'
import {usePurchaseAddOns} from 'obvio/Billing/purchase-add-ons'
import {teamMemberClient} from 'obvio/obvio-client'
import {ConfirmationProps} from 'organization/AddOns/PurchaseAddOnPage/ConfirmDialog'
import {useOrganization} from 'organization/OrganizationProvider'

type OtherCardFormProps = ConfirmationProps & {
  onCancel: () => void
}

export function OtherCardForm(props: OtherCardFormProps) {
  return (
    <Dialog open>
      <StyledDialogContent>
        <Title mb={5}>Use a Different Card!</Title>

        <Content {...props} />
      </StyledDialogContent>
    </Dialog>
  )
}

function Content(props: OtherCardFormProps) {
  const {addOnDetail, onCancel, quantity, addOnKey, onSuccess} = props
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const purchaseAddOns = usePurchaseAddOns()
  const duration = addOnDetail.duration ? props.duration : undefined
  const {organization} = useOrganization()
  const paymentIntent = usePaymentIntent(
    quantity,
    duration,
    organization.id,
    addOnKey,
  )

  const paymentIntentId = paymentIntent?.id
  if (!paymentIntentId) {
    return (
      <Box>
        <Loader />
      </Box>
    )
  }

  const purchase = (paymentMethodId: string) => {
    if (processing) {
      return
    }

    toggleProcessing()

    purchaseAddOns({
      quantity,
      duration,
      addOnKey,
      paymentIntentId,
      paymentMethodId,
      organizationId: organization.id,
    })
      .then(onSuccess)
      .catch((e) => {
        setError(e.message)
        toggleProcessing()
      })
  }

  return (
    <>
      <ErrorAlert onClose={clearError}>{error}</ErrorAlert>

      <SingleUseCreditCardForm
        submitLabel="Purchase Add-on"
        onSuccess={purchase}
      />

      <Actions>
        <Button
          onClick={onCancel}
          aria-label="cancel different card"
          fullWidth
          disabled={processing}
        >
          Cancel
        </Button>
      </Actions>
    </>
  )
}

function usePaymentIntent(
  quantity: number,
  duration: number | undefined,
  organizationId: number,
  addOnKey: string,
) {
  const request = useCallback(() => {
    const url = api('/stripe/payment_intent/add_ons')

    return teamMemberClient.post<PaymentIntent>(url, {
      quantity: quantity,
      duration: duration,
      organization_id: organizationId,
      add_on_key: addOnKey,
    })
  }, [quantity, duration, organizationId, addOnKey])

  const {data} = useAsync(request)

  return data
}

const Box = styled.div`
  text-align: center;
`

const StyledDialogContent = styled(DialogContent)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 450px;
  }
`

const Actions = styled.div`
  text-align: right;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  margin-top: ${(props) => props.theme.spacing[4]};

  & > * {
    margin-right: ${(props) => props.theme.spacing[4]} !important;

    &:last-child {
      margin-right: 0;
    }
  }
`
