import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ConfigDialog'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {NumberItem} from 'Event/Marketplace/Block/NumberedList'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'

type NumberedListItemConfigProps = ComponentConfigProps & {
  list: NumberItem
  itemId: string
  sectionId: string
  blockId: string
  updateTemplate: UpdateTemplateFunction
}

export default function NumberedListItemConfig(
  props: NumberedListItemConfigProps,
) {
  const {list, itemId, sectionId, blockId, updateTemplate} = props
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(list.visibility)

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [blockId]: {
              items: {
                [itemId]: REMOVE,
              },
            },
          },
        },
      },
    })
  }

  const save = (data: any) => {
    data.sections[sectionId].blocks[blockId].items[
      itemId
    ].visibility = visibility
    updateTemplate(data)
  }

  return (
    <Config title="Numbered Item" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.text`}
          defaultValue={list.text}
          control={control}
          render={({value, onChange}) => (
            <TextEditorContainer>
              <TextEditor
                data={value}
                onChange={onChange}
                toolbar={['bold', 'italic', 'underline', 'fontColor']}
              />
            </TextEditorContainer>
          )}
        />

        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
      </SettingsPanel>
    </Config>
  )
}
