import React from 'react'
import {usePurchaseForm} from './PurchaseFormProvider'
import {usePurchase} from '../../PurchaseContext'
import {BlockComponentProps} from '../index'
import {PurchaseFormBlock, PurchaseFormProps} from '.'
import validator from 'validator'
import SectionLabel from './SectionLabel'

interface CustomerDetailFieldsProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  disabled?: boolean
  TextFieldComponent: React.FC<any>
  CustomerDetailsSectionLabelComponent?: React.FC<any>
}

export default function CustomerDetailFields(props: CustomerDetailFieldsProps) {
  const {
    disabled,
    TextFieldComponent,
    CustomerDetailsSectionLabelComponent = (
      props: PurchaseFormProps & BlockComponentProps,
    ) => (
      <SectionLabel {...props}>
        {props.customerDetailsLabel ?? 'Your Details'}
      </SectionLabel>
    ),
    ...config
  } = props

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    firstNameError,
    setFirstNameError,
    lastNameError,
    setLastNameError,
    emailError,
    setEmailError,
    phoneNumberError,
    setPhoneNumberError,
    isExistingAttendee,
  } = usePurchaseForm()
  const {selectedTicket} = usePurchase()

  const isEmpty = (value: string) => value.trim().length === 0

  const validateFirstName = (value: string) => {
    if (isEmpty(value)) {
      setFirstNameError('First name is required')
      return false
    }
    setFirstNameError(null)
    return true
  }

  const validateLastName = (value: string) => {
    if (isEmpty(value)) {
      setLastNameError('Last name is required')
      return false
    }
    setLastNameError(null)
    return true
  }

  const validateEmail = (value: string) => {
    if (isEmpty(value)) {
      setEmailError('Email is required')
      return false
    }

    if (!validator.isEmail(value)) {
      setEmailError('Incorrect email format')
      return false
    }

    setEmailError(null)
    return true
  }

  const validatePhoneNumber = (value: string) => {
    if (isEmpty(value) && config.requirePhoneNumber) {
      setPhoneNumberError('Phone number is required')
      return false
    }

    if (!isEmpty(value) && !validator.isMobilePhone(value)) {
      setPhoneNumberError('Incorrect phone format')
      return false
    }

    setPhoneNumberError(null)
    return true
  }

  const onChangeFirstName = (e: any) => {
    validateFirstName(e)
    setFirstName(e)
  }

  const onChangeLastName = (e: any) => {
    validateLastName(e)
    setLastName(e)
  }

  const onChangeEmail = (e: any) => {
    validateEmail(e)
    setEmail(e)
  }

  const onChangePhoneNumber = (e: any) => {
    validatePhoneNumber(e)
    setPhoneNumber(e)
  }

  return (
    <div>
      <CustomerDetailsSectionLabelComponent {...props} />
      <TextFieldComponent
        aria-label="purchase first name"
        config={config}
        placeholder="firstName"
        inputProps={{
          'aria-label': 'first name',
        }}
        value={firstName}
        onChange={onChangeFirstName}
        error={!!firstNameError}
        disabled={disabled || isExistingAttendee}
      />
      <TextFieldComponent
        aria-label="purchase last name"
        config={config}
        disabled={disabled || isExistingAttendee}
        placeholder="lastName"
        inputProps={{
          'aria-label': 'last name',
        }}
        value={lastName}
        onChange={onChangeLastName}
        error={!!lastNameError}
      />
      <TextFieldComponent
        aria-label="purchase email"
        config={config}
        disabled={disabled}
        placeholder="email"
        inputProps={{
          'aria-label': 'email',
        }}
        value={email}
        onChange={onChangeEmail}
        error={!!emailError}
      />
      {selectedTicket?.phone_number_enabled && (
        <TextFieldComponent
          aria-label="purchase phone number"
          config={config}
          disabled={disabled || isExistingAttendee}
          placeholder="phone"
          inputProps={{
            'aria-label': 'phone number',
          }}
          value={phoneNumber}
          onChange={onChangePhoneNumber}
          error={!!phoneNumberError}
        />
      )}
    </div>
  )
}
