import React, {useState} from 'react'
import styled from 'styled-components'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label} from 'lib/ui/typography'
import {onUnknownChangeHandler} from 'lib/dom'
import {Certificate} from 'lib/event-api/certificates/types'
import {useCertificates} from 'lib/event-api/certificates/use-certificates'
import Button from 'lib/ui/Button'
import {useAttendees} from 'organization/Event/AttendeesProvider'
import {useToggle} from 'lib/toggle'

interface DownloadCertificateDialogProps {
  isVisible: boolean
  onClose: () => void
  onSuccess: (message: string | null) => void
  onError: (message: string | null) => void
}

export default function DownloadCertificateDialog(
  props: DownloadCertificateDialogProps,
) {
  if (!props.isVisible) {
    return null
  }
  return <Content {...props} />
}

function Content(props: DownloadCertificateDialogProps) {
  const [certificateId, setCertificateId] = useState<number | null>()
  const {data: certificates = []} = useCertificates()
  const {downloadCertificate, processing} = useDownloadCertificate(
    props.onSuccess,
  )

  const selectCertificate = (id?: number) => {
    if (id === undefined) {
      return
    }
    const value = id || null
    setCertificateId(value)
  }

  const send = () => {
    const certificate = certificates.find((c) => c.id === certificateId)
    if (!certificate) {
      return
    }

    downloadCertificate(certificate)
    props.onClose()
  }

  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>Download Certificate</DialogTitle>
      <DialogContent>
        <StyledLabel>Select a certificate to download</StyledLabel>
        <Select
          fullWidth
          onChange={onUnknownChangeHandler(selectCertificate)}
          aria-label="pick certificate"
        >
          {certificates.map((c) => (
            <Option key={c.id} value={c.id} aria-label={`pick ${c.title}`}>
              {c.title}
            </Option>
          ))}
        </Select>

        <Actions>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={send}
            aria-label="download certificate"
            disabled={!certificateId || processing}
            className="mb-4"
          >
            Download Certificate
          </Button>
        </Actions>
      </DialogContent>
    </Dialog>
  )
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]}!important;
`

export function useDownloadCertificate(
  onSuccess: (message: string | null) => void,
) {
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const {downloadCertificate, clearError} = useAttendees()

  const process = (certificate: Certificate) => {
    if (processing) {
      return
    }

    toggleProcessing()
    onSuccess(null)
    clearError()

    downloadCertificate(certificate)
      .then(() => {
        onSuccess(
          "Download certificate request received. We'll send a download link to your email once it is ready.",
        )
      })
      .finally(toggleProcessing)
  }

  return {
    processing,
    downloadCertificate: process,
  }
}

const Actions = styled.div`
  margin: ${(props) => `0 0 ${props.theme.spacing[4]}`};
`
