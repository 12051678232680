import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'
import {UpsellDeclineButtonBlock} from 'Event/Marketplace/Block/UpsellDeclineButton'

interface UpselLDeclineButtonConfigProps extends UpsellDeclineButtonBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function UpsellPurchaseButtonConfig(
  props: UpselLDeclineButtonConfigProps,
) {
  const {id, onMoveSectionDown, onMoveSectionUp} = props

  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()
  const {
    form: {register, control, watch},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Upsell Decline Button" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <TextField
          name={`text`}
          defaultValue={props.text || ''}
          label="Text Line 1"
          inputProps={{
            ref: register,
            'aria-label': 'button text input',
          }}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={props}
          control={control}
          storageKey={`blocks-${id}-styling.typography`}
          label="Typography"
        />
        <BackgroundSection
          button={props}
          control={control}
          watch={watch}
          storageKey={`blocks-${id}-styling.background`}
        />
        <SizeSection
          button={props}
          control={control}
          storageKey={`blocks-${id}-styling.size`}
          sizeUnit="percent"
          disableNewLine
        />
        <BorderSection
          button={props}
          control={control}
          storageKey={`blocks-${id}-styling.border`}
        />
      </StylingPanel>
    </Config>
  )
}
