import React from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {eventRoutes} from 'Event/Routes'
import styled from 'styled-components'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useAttendeeVariables} from 'Event'

export default function Features() {
  const template = useTownhallTemplate()

  const {
    speakers: {menuTitle: speakerTitle, isEnabled: showingSpeakers},
    sponsors: {isEnabled: showingSponsors, menuTitle: sponsorPageTitle},
    zoomBackgrounds: {
      isEnabled: showingZoomBackgrounds,
      title: zoomBackgroundsTitle,
    },
    leaderboard: {title: pointsTitle, isVisible: showingPoints},
    faq: {title: faqsTitle, isEnabled: showingFaqs},
    imageWaterfall: {
      title: imageWaterfallTitle,
      isVisible: showingImageWaterfall,
    },
  } = template

  return (
    <Box>
      <FeatureLink to={eventRoutes.root} aria-label="dashboard">
        Dashboard
      </FeatureLink>
      <FeatureLink
        enabled={showingSpeakers}
        to={eventRoutes.speakers}
        aria-label="footer link speakers"
      >
        {speakerTitle}
      </FeatureLink>
      <FeatureLink
        enabled={showingSponsors}
        to={eventRoutes.sponsors}
        aria-label="footer link sponsors"
      >
        {sponsorPageTitle}
      </FeatureLink>
      <FeatureLink
        enabled={showingFaqs}
        to={eventRoutes.faq}
        aria-label="footer link faqs"
      >
        {faqsTitle}
      </FeatureLink>
      <FeatureLink
        enabled={showingPoints}
        to={eventRoutes.leaderboard}
        aria-label="footer link leaderboard"
      >
        {pointsTitle}
      </FeatureLink>
      <FeatureLink
        enabled={showingZoomBackgrounds}
        to={eventRoutes.backgrounds}
        aria-label="footer link backgrounds"
      >
        {zoomBackgroundsTitle}
      </FeatureLink>
      <FeatureLink
        enabled={showingImageWaterfall}
        to={eventRoutes.image_waterfall}
        aria-label="footer link image waterfall"
      >
        {imageWaterfallTitle}
      </FeatureLink>
    </Box>
  )
}

function FeatureLink(props: {
  enabled?: boolean
  to: string
  'aria-label': string
  children: string
}) {
  const {enabled = true, children} = props

  const v = useAttendeeVariables()

  const {
    footer: {textColor},
  } = useTownhallTemplate()

  if (!enabled) {
    return null
  }

  return (
    <StyledRelativeLink disableStyles {...props} textColor={textColor}>
      {v(children)}
    </StyledRelativeLink>
  )
}

const Box = styled.div`
  a {
    &:not(:last-child):after {
      content: ' • ';
    }
  }
`

const StyledRelativeLink = styled(RelativeLink)<{textColor: string | null}>`
  color: ${(props) => props.textColor};
`
