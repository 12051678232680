import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'
import React, {useState} from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {MoreActionsMenu} from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LiveIcon from 'assets/images/ui/icons/live.svg'
import {Tiny, Label, Description} from 'lib/ui/typography'
import defaultLogo from 'assets/images/logo_vertical.png'
import MoveEventDialog from 'organization/EventList/MoveEventDialog'
import {useIsOwner} from 'organization/OwnerProvider'
import {useObvioUser} from 'obvio/auth'
import {ListEventData} from 'organization/EventList'
import {usePermissions, VIEW_STATISTICS} from 'organization/PermissionsProvider'
import DeleteEventDialog from 'organization/EventList/DeleteEventDialog'
import {getSystemDomain} from 'lib/url'

export default function GridCard(props: {event: ListEventData}) {
  const {event} = props
  const label = `view ${event.name}`
  const routes = useEventRoutes(event)
  const {can} = usePermissions()
  const [showMoveDialog, setShowMoveDialog] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const defaultRoute = can(VIEW_STATISTICS) ? routes.statistics : routes.root

  const systemDomain = getSystemDomain(event)

  return (
    <Container aria-label="event-item">
      <Content>
        <RelativeLink to={defaultRoute} disableStyles aria-label={label}>
          <Box>
            <EventLogo event={event} />
          </Box>
        </RelativeLink>
        <Bottom>
          <LiveIndicatorContainer>
            <LiveIndicator isLive={props.event.is_live} />
          </LiveIndicatorContainer>
          <MoreActionsMenu>
            <RelativeLink
              to={routes.duplicate}
              disableStyles
              aria-label="duplicate event"
            >
              <MenuItem>Duplicate</MenuItem>
            </RelativeLink>

            <MoveToOrganizationButton setShowMoveDialog={setShowMoveDialog} />
            <DeleteEventButton setShowDeleteDialog={setShowDeleteDialog} />
          </MoreActionsMenu>
        </Bottom>
      </Content>
      <Name>{props.event.name}</Name>
      <URL>
        {props.event.slug}.{systemDomain}
      </URL>

      <MoveEventDialog
        event={event}
        isVisible={showMoveDialog}
        onClose={() => {
          setShowMoveDialog(false)
        }}
      />

      <DeleteEventDialog
        event={event}
        visible={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
        }}
      />
    </Container>
  )
}

function EventLogo(props: {event: ListEventData}) {
  const {event} = props
  const logo = event.logo?.url || defaultLogo

  if (event.is_copying) {
    return <>Building Your Duplicate</>
  }

  return <Image src={logo} alt={event.name} aria-label="event logo" />
}

export function MoveToOrganizationButton(props: {
  setShowMoveDialog: (status: boolean) => void
}) {
  const {setShowMoveDialog} = props

  const isOwner = useIsOwner()
  const user = useObvioUser()

  if (!isOwner && !user.is_admin) {
    return null
  }

  return (
    <MenuItem
      aria-label="move to organization"
      onClick={() => {
        setShowMoveDialog(true)
      }}
    >
      Move to organization
    </MenuItem>
  )
}

export function DeleteEventButton(props: {
  setShowDeleteDialog: (status: boolean) => void
}) {
  const {setShowDeleteDialog} = props

  const isOwner = useIsOwner()
  const user = useObvioUser()

  if (!isOwner && !user.is_admin) {
    return null
  }

  return (
    <MenuItem
      aria-label="delete event"
      onClick={() => {
        setShowDeleteDialog(true)
      }}
    >
      Delete event
    </MenuItem>
  )
}

export function LiveIndicator(props: {
  isLive: boolean
  Container?: React.FC<{children: any}>
}) {
  if (!props.isLive) {
    return null
  }

  const Container = props.Container || LiveIndicatorContainer

  return (
    <Container>
      <LiveTypography aria-label="live event">Live</LiveTypography>
      <img
        src={LiveIcon}
        aria-label="live indicator icon"
        alt="live indicator icon"
      />
    </Container>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;

  &:hover {
    background: ${grey[200]};
  }
`

const Name = styled(Label)`
  margin: 0;
  font-weight: 400;
`

const URL = styled(Description)`
  margin-top: ${(props) => props.theme.spacing[1]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  border-radius: ${(props) => props.theme.spacing[1]};
  &:hover img {
    opacity: 0.5;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gray300};
  padding-left: ${(props) => props.theme.spacing[2]};
`

const LiveIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.spacing[1]};
`

const LiveTypography = styled(Tiny)`
  color: ${(props) => props.theme.colors.primary};
`

const Image = styled.img`
  max-width: 100%;
  max-height: 152px;
`
