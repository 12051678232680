import React from 'react'
import {Lefty, useLeftyTemplate} from 'Event/template/Lefty'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Lefty/Dashboard/Footer/FooterConfig/Settings'
import Styling from 'Event/template/Lefty/Dashboard/Footer/FooterConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'

import {useAddTranslation} from 'Event/LanguageProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export const DEFAULT_FOOTER_IMAGE_SIZE = 100

export function FooterConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props

  const {footer} = useLeftyTemplate()
  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const {control, watch, handleSubmit, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Lefty>()

  const handleSave = (formData: Lefty['footer']) => {
    const image = formData.image
    pruneAssets({image}, saved.footer)

    const label = replaceAtPath(
      formData,
      'copyrightText',
      '{{footer_copyright_text}}',
    )

    save({
      footer: formData,
      localization: addTranslation({
        footer_copyright_text: label ?? '',
      }),
    })

    onClose()
  }

  useAutoUpdate({values: {footer: watch()}, when: showing})

  return (
    <ComponentConfig
      title="Footer"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(handleSave)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} footer={footer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} footer={footer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
