import {FieldProps, useSavedValue, FormTextField} from 'Event/Question'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {
  HasCharacterLimit,
  QuestionBase,
} from 'organization/Event/QuestionsProvider'
import {Controller} from 'react-hook-form'

export const SHORT_ANSWER_TEXT = 'short_answer_text'

export type ShortAnswerQuestion = QuestionBase &
  HasCharacterLimit & {
    type: typeof SHORT_ANSWER_TEXT
  }

export default function ShortAnswerText(props: FieldProps) {
  useSavedValue(props)
  const v = useAttendeeVariables()
  const label = `${v(props.question.label)} ${
    props.question.is_required ? '*' : ''
  }`
  const defaultValue = v(props.answer || '')

  return (
    <>
      <Controller
        name={props.name}
        defaultValue={defaultValue}
        control={props.control}
        render={({value, onChange}) => (
          <FormTextField
            label={label}
            inputProps={{
              'aria-label': props.question.label,
            }}
            value={value}
            fullWidth
            onChange={onChange}
            error={Boolean(props.error)}
            disabled={props.disabled}
            styles={props.inputStyles}
          />
        )}
      />

      {props.HelperText}
    </>
  )
}
