import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {BlogSectionProps} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig/Styling'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function BlogSectionConfig(props: {
  blogSection: BlogSectionProps
  sectionId: string
}) {
  const {blogSection, sectionId} = props

  const save = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const {dashboardSections, navBar} = data
    const {menuItems} = navBar
    const {
      [sectionId]: {title: menuTitle, ...others},
    } = menuItems
    const {
      [sectionId]: {description, title, ...otherUpdates},
    } = dashboardSections

    save({
      ...data,
      localization: addTranslation({
        [`navBar_menuItems_${sectionId}_title`]: menuTitle,
        [`dashboardSections_${sectionId}_title`]: title,
        [`dashboardSections_${sectionId}_description`]: description,
      }),
      navBar: {
        menuItems: {
          [sectionId]: {
            title: `{{navBar_menuItems_${sectionId}_title}}`,
            ...others,
          },
        },
      },
      dashboardSections: {
        [sectionId]: {
          title: `{{dashboardSections_${sectionId}_title}}`,
          description: `{{dashboardSections_${sectionId}_description}}`,
          ...otherUpdates,
        },
      },
    })
  }

  const remove = () =>
    save({
      localization: removeTranslations([
        `navBar_menuItems_${sectionId}_title`,
        `dashboardSections_${sectionId}_title`,
        `dashboardSections_${sectionId}_description`,
      ]),
      dashboardSections: {
        [sectionId]: REMOVE,
      },
      navBar: {
        menuItems: {
          [sectionId]: REMOVE,
        },
      },
    })

  return (
    <Config title="Blog" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings blogSection={blogSection} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling blogSection={blogSection} sectionId={sectionId} />
      </StylingPanel>
    </Config>
  )
}
