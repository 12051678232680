import React from 'react'
import {Controller} from 'react-hook-form'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Slider from 'lib/ui/Slider'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {useConfig} from 'organization/Event/Configurable'

export default function Styling() {
  const {
    form: {control},
  } = useConfig()

  const template = useTownhallTemplate()

  return (
    <>
      <Controller
        control={control}
        name="hero.imageSize"
        defaultValue={template.hero.imageSize}
        render={({value, onChange}) => (
          <Slider
            min={20}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="hero image size"
            label="Image Size"
            unit="%"
          />
        )}
      />
      <Controller
        name="hero.alignment"
        defaultValue={template.hero.alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Text Align"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>
        )}
      />

      <Controller
        name="hero.vertical_alignment"
        defaultValue={template.hero.vertical_alignment}
        control={control}
        render={({value, onChange}) => (
          <Select
            fullWidth
            label="Vertical Alignment"
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
          >
            <Option value="start">Top</Option>
            <Option value="center">Middle</Option>
            <Option value="end">Bottom</Option>
          </Select>
        )}
      />
    </>
  )
}
