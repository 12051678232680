import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import ColorPicker from 'lib/ui/ColorPicker'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {PurhaseFormTextFieldProps} from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormTextField'

export default function PurchaseFormTextFieldConfig(
  props: PurhaseFormTextFieldProps,
) {
  const {config} = props
  const {id} = config

  const {
    form: {control, register},
  } = useConfig()

  const {sectionId, update} = useConfigurableSection()

  const save = (data: any) => {
    update(data.sections[sectionId])
  }

  return (
    <Config title="Purchase Input Fields" onSave={save}>
      <SettingsPanel>
        <CollapsibleSection
          label="Main Contact Info Placeholders"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.placeholderText`}
        >
          <TextField
            name={`sections.${sectionId}.blocks.${id}.input.placeholder.firstName`}
            defaultValue={config.input.placeholder.firstName}
            label="First Name"
            inputProps={{
              'aria-label': 'firstname',
              ref: register,
            }}
          />
          <TextField
            name={`sections.${sectionId}.blocks.${id}.input.placeholder.lastName`}
            defaultValue={config.input.placeholder.lastName}
            label="Last Name"
            inputProps={{
              'aria-label': 'lastname',
              ref: register,
            }}
          />
          <TextField
            name={`sections.${sectionId}.blocks.${id}.input.placeholder.email`}
            defaultValue={config.input.placeholder.email}
            label="Email"
            inputProps={{
              'aria-label': 'email',
              ref: register,
            }}
          />
          <PhoneNumberField {...props} />
        </CollapsibleSection>

        <BillingAddressFields {...props} />

        <ShippingAddressFields {...props} />

        <CollapsibleSection
          label="Error Handling & Questions"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.errorTexts`}
        >
          <TextField
            name={`sections.${sectionId}.blocks.${id}.errors.requiredField`}
            defaultValue={config.errors.requiredField}
            label="Error Message"
            inputProps={{
              'aria-label': 'requiredField',
              ref: register,
            }}
          />

          <SameShippingAddressField {...props} />
        </CollapsibleSection>
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Font Size"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.size`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.fontSize`}
            defaultValue={config.input.fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={10}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Border"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.border.width`}
            defaultValue={config.input.border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Border Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.border.radius`}
            defaultValue={config.input.border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.border.color`}
            defaultValue={config.input.border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.backgroundColor`}
            defaultValue={config.input.backgroundColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Background Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Padding"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.padding`}
        >
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.input.padding`}
            values={config.input.padding}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Space Between"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.spaceBetween`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${id}.input.spacing`}
            defaultValue={config.input.spacing}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
                label="Size"
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

function SameShippingAddressField(props: PurhaseFormTextFieldProps) {
  const {config} = props
  const {id} = config
  const {sectionId} = useConfigurableSection()

  const {
    form: {register},
  } = useConfig()

  return (
    <TextField
      name={`sections.${sectionId}.blocks.${id}.questions.sameShippingAddress`}
      defaultValue={config.questions.sameShippingAddress}
      label="Shipping Address"
      inputProps={{
        'aria-label': 'sameShippingAddress',
        ref: register,
      }}
    />
  )
}

function PhoneNumberField(props: PurhaseFormTextFieldProps) {
  const {config} = props
  const {id} = config
  const {sectionId} = useConfigurableSection()

  const {
    form: {register},
  } = useConfig()

  return (
    <TextField
      name={`sections.${sectionId}.blocks.${id}.input.placeholder.phone`}
      defaultValue={props.config.input.placeholder.phone}
      label="Phone"
      inputProps={{
        'aria-label': 'phone',
        ref: register,
      }}
    />
  )
}

function BillingAddressFields(props: PurhaseFormTextFieldProps) {
  const {config} = props
  const {id} = config
  const {sectionId} = useConfigurableSection()

  const {
    form: {register},
  } = useConfig()

  return (
    <CollapsibleSection
      label="Billing Address Placeholders"
      noDivider
      storageKey={`blocks-${id}-styling.inputFields.placeholderText`}
    >
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.billingAddress1`}
        defaultValue={config.input.placeholder.billingAddress1}
        label="Billing Address 1"
        inputProps={{
          'aria-label': 'billing address 1',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.billingAddress2`}
        defaultValue={config.input.placeholder.billingAddress2}
        label="Billing Address 2"
        inputProps={{
          'aria-label': 'billing address 2',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.city`}
        defaultValue={config.input.placeholder.city}
        label="City"
        inputProps={{
          'aria-label': 'city',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.state`}
        defaultValue={config.input.placeholder.state}
        label="State"
        inputProps={{
          'aria-label': 'state',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.zipcode`}
        defaultValue={config.input.placeholder.zipcode}
        label="Zip Code"
        inputProps={{
          'aria-label': 'zipcode',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.country`}
        defaultValue={config.input.placeholder.country}
        label="Country"
        inputProps={{
          'aria-label': 'country',
          ref: register,
        }}
      />
    </CollapsibleSection>
  )
}

function ShippingAddressFields(props: PurhaseFormTextFieldProps) {
  const {config} = props
  const {id} = config
  const {sectionId} = useConfigurableSection()

  const {
    form: {register},
  } = useConfig()

  return (
    <CollapsibleSection
      label="Shipping Address Placeholders"
      noDivider
      storageKey={`blocks-${id}-styling.inputFields.placeholderText`}
    >
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingAddress1`}
        defaultValue={config.input.placeholder.shippingAddress1}
        label="Shipping Address 1"
        inputProps={{
          'aria-label': 'shipping address 1',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingAddress2`}
        defaultValue={config.input.placeholder.shippingAddress2}
        label="Shipping Address 2"
        inputProps={{
          'aria-label': 'shipping address 2',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingcity`}
        defaultValue={config.input.placeholder.shippingcity}
        label="City"
        inputProps={{
          'aria-label': 'shippingcity',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingstate`}
        defaultValue={config.input.placeholder.shippingstate}
        label="State"
        inputProps={{
          'aria-label': 'shippingstate',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingzipcode`}
        defaultValue={config.input.placeholder.shippingzipcode}
        label="Zip Code"
        inputProps={{
          'aria-label': 'shippingzipcode',
          ref: register,
        }}
      />
      <TextField
        name={`sections.${sectionId}.blocks.${id}.input.placeholder.shippingcountry`}
        defaultValue={config.input.placeholder.shippingcountry}
        label="Country"
        inputProps={{
          'aria-label': 'shippingcountry',
          ref: register,
        }}
      />
    </CollapsibleSection>
  )
}
