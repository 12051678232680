import React from 'react'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {useEvent} from 'Event/EventProvider'
import {routesWithValue} from 'lib/url'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import CertificateEditor from 'organization/Event/Certificates/CertificateEditor'
import {useCertificate} from 'lib/event-api/certificates/use-certificate'
import {Certificate as CertificateData} from 'lib/event-api/certificates/types'

export function useCertificateRoutes() {
  const {certificate: routeId} = useParams<{certificate: string}>()

  const id = parseInt(routeId)

  const {data: certificate} = useCertificate({id})

  const {event} = useEvent()
  const eventRoutes = useEventRoutes(event)

  if (!certificate) {
    throw new Error(`Missing certificate; invalid route id: '${routeId}'`)
  }

  return certificateRoutes({certificate, eventRoutes})
}

export function certificateRoutes({
  eventRoutes,
  certificate,
}: {
  eventRoutes: ReturnType<typeof useEventRoutes>
  certificate: CertificateData
}) {
  return routesWithValue(
    ':certificate',
    String(certificate.id),
    eventRoutes.certificates[':certificate'],
  )
}

export default function CertificateRoutes() {
  const {routes} = useOrganization()

  return (
    <PageBreadcrumbs page="Certificates">
      <Switch>
        <Route
          exact
          path={routes.events[':event'].certificates[':certificate'].design}
        >
          <CertificateEditor />
        </Route>
        <Redirect to={routes.events[':event'].certificates.root} />
      </Switch>
    </PageBreadcrumbs>
  )
}
