import React from 'react'
import HelperText from '../HelperText'
import styles from '../styles/Select.module.css'
import classNames from 'classnames'

export type SelectProps = {
  value: any
  children?: React.ReactNode
  onChange: (value: any, obj?: any) => void
  className?: string
  helperText?: string
  error?: boolean
  disabled?: boolean
  config: {
    input: {
      textColor: string
      fontSize: number
      backgroundColor: string
      spacing: number
      border: {
        width: number
        radius: number
        color: string
      }
      padding: {
        left: number
        right: number
        top: number
        bottom: number
      }
      placeholder: {
        firstName: string
        lastName: string
        email: string
        phone: string
        billingAddress1: string
        billingAddress2: string
        city: string
        state: string
        zipcode: string
        country: string
        shippingAddress1: string
        shippingAddress2: string
        shippingcity: string
        shippingstate: string
        shippingzipcode: string
        shippingcountry: string
      }
    }
  }
}

export default function Select(props: SelectProps) {
  const {disabled, className = '', helperText, onChange, config} = props

  return (
    <div
      className={classNames(className, styles.selectFormControl)}
      style={{
        marginBottom: config.input.spacing + 'px',
      }}
    >
      <select
        onChange={onChange}
        disabled={disabled}
        style={{
          backgroundColor: config.input.backgroundColor,
          border:
            props.config.input.border.color +
            ' ' +
            props.config.input.border.width +
            'px solid',
          borderRadius: props.config.input.border.radius + 'px',
          paddingLeft: props.config.input.padding.left + 'px',
          paddingRight: props.config.input.padding.right + 'px',
          paddingTop: props.config.input.padding.top + 'px',
          paddingBottom: props.config.input.padding.bottom + 'px',
          fontSize: props.config.input.fontSize + 'px',
          lineHeight: 'normal',
          width: 100 + '%',
        }}
      >
        {props.children}
      </select>
      <HelperText error={props.error}>{helperText}</HelperText>
    </div>
  )
}
