import {Controller} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import ColorPicker from 'lib/ui/ColorPicker'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function DescriptionConfig() {
  const saveTemplate = useSaveTemplate()
  const {login, textColor} = useTemplate()
  const descriptionTextColor = login.description.color
    ? login.description.color
    : textColor
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const text = replaceAtPath(
      data,
      'login.description.text',
      '{{login_description_text}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        login_description_text: text ?? '',
      }),
    })
  }

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <TextField
          name="login.description.text"
          defaultValue={v(login.description.text)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'set login form description',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="login.description.color"
          defaultValue={descriptionTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Description Text Color"
              color={value}
              onPick={onChange}
              aria-label="description text color"
            />
          )}
        />
        <Controller
          name="login.description.fontSize"
          defaultValue={login.description.fontSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              aria-label="description font size"
              value={value}
              onChange={onChange}
              step={1}
              min={5}
              max={50}
              label="Description Font Size"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
