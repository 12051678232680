import React, {useEffect, useState, useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import {Resource} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {generateHashId} from 'lib/crypto/hash'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation, useLanguages} from 'Event/LanguageProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {get as getAtPath} from 'lodash'
import {useTemplate} from 'Event/TemplateProvider'

export function ResourceItemConfig(
  props: ComponentConfigProps & {
    id?: string
    resource: Resource
    list: ResourceListProps
  },
) {
  const {resource, id, showing, onClose} = props

  const deleteFile = useDeleteFile()

  const {handleSubmit, control, register, setValue, formState} = useForm()

  const v = useAttendeeVariables()
  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const [filePath, setFilePath] = useState(v(resource.filePath))
  const {id: listId, removeItem} = useEditSidebarItem()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const template = useTemplate()
  const languages = useLanguages()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(resource.rules)
    setIsUrl(resource.isUrl)
    setFilePath(v(resource.filePath))
  }, [resource, showing, v])

  const update = (id: string, data: Resource) => {
    const {name} = data

    saveTemplate({
      sidebarItems: {
        [listId]: {
          resources: {
            [id]: {
              ...data,
              name: `{{${id}_name}}`,
              filePath: `{{${id}_filePath}}`,
            },
          },
        },
      },
      localization: addTranslation({
        [`${id}_name`]: name || '',
        [`${id}_filePath`]: filePath || '',
      }),
    })
  }

  const insert = (newResource: Resource) => {
    generateHashId([
      'lefty_',
      'sidebar_resource_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `resource_${id}`
      update(componentId, newResource)
    })
  }

  const save = (
    form: Pick<Resource, 'name' | 'url' | 'isVisible' | 'icon' | 'actionId'>,
  ) => {
    const data: Resource = {
      ...form,
      rules,
      isUrl,
      filePath,
    }

    if (id !== undefined) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error('Called remove outside of editing resource.')
    }

    for (const language of languages) {
      const filePath = getAtPath(
        template,
        `localization.translations.${language.name}.${id}_filePath`,
      )

      if (filePath) {
        deleteFile(filePath).catch((e) => {
          // Log error, but prevent it from crashing
          // app
          console.error(e)
        })
      }
    }

    removeItem(id, 'resources', [
      `sidebar_resource_${listId}_resource_${id}_name`,
    ])
    onClose()
  }, [deleteFile, id, onClose, removeItem, listId, languages, template])

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          filePath={filePath}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          variant="outlined"
          aria-label="remove resource"
          onClick={remove}
          showing={Boolean(id)}
        >
          REMOVE RESOURCE
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
