import {UpsellPage} from 'Event/Marketplace/upsell-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation, useQueryClient} from 'react-query'

interface UseUpdateUpsellPageParams {
  client: Client
  upsellPage: UpsellPage
}

export function useUpdateUpsellPage(params: UseUpdateUpsellPageParams) {
  const {client, upsellPage} = params

  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<UpsellPage>) => {
      const url = marketplaceApi(`/upsell_pages/${upsellPage.id}`)
      return client.put<UpsellPage>(url, data)
    },
    onSuccess: (updated) => {
      qc.setQueryData<UpsellPage>(
        ['events', upsellPage.event_id, 'upsell_page'],
        () => updated,
      )
    },
  })
}
