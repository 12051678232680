import React from 'react'
import {useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/WelcomeMessage/WelcomeMessageConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/WelcomeMessage/WelcomeMessageConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
import {Font} from 'lib/FontSelect'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export type WelcomeMessage = {
  text?: string
  font?: Font | null
  fontSize?: number
  textColor?: string | null
}

export function WelcomeMessageConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useCardsUpdate()
  const addTranslation = useAddTranslation()
  const {handleSubmit, control, watch, formState} = useForm()

  const save = (data: WelcomeMessage) => {
    const text = replaceAtPath(data, 'text', '{{welcome_message}}')

    update({
      welcomeMessage: data,
      localization: addTranslation({
        welcome_message: text ?? '',
      }),
    })
    onClose()
  }

  useAutoUpdate({values: {welcomeMessage: watch()}, when: showing})

  return (
    <ComponentConfig
      title="Welcome Message"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
