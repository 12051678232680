import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {BlockBase} from '../base'
import React from 'react'
import {Font, getFontStyles} from '../../lib/font'
import styles from './Title.module.css'
import CKEditorContent from '../../lib/ui/CKEditorContent'
import classNames from 'classnames'

export interface TitleBlock extends BlockBase {
  type: 'Title'
  text: string
  font: Font | null
  fontSize: number
  color: string
  opacity: number
  lineHeight: number
  letterSpacing: number
}

export default function Title(props: TitleBlock & BlockComponentProps) {
  const {text} = props

  return (
    <Container {...props}>
      <div
        className={classNames(styles.body, 'block-content')}
        style={{
          ...getFontStyles(props.font),
          fontSize: props.fontSize,
          color: props.color,
          lineHeight: `${props.lineHeight}%`,
          opacity: props.opacity,
          textAlign: props.alignment.horizontal,
          letterSpacing: props.letterSpacing,
        }}
      >
        <CKEditorContent className={styles.content}>{text}</CKEditorContent>
      </div>
    </Container>
  )
}
