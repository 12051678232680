import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function SignUpLinkConfig() {
  const {signUp} = useTemplate()
  const saveTemplate = useSaveTemplate()
  const {
    form: {register},
  } = useConfig()
  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const label = replaceAtPath(
      data,
      'signUp.linkLabel',
      '{{sign_up_link_label}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        sign_up_link_label: label ?? 'Sign Up',
      }),
    })
  }

  return (
    <Config title="Sign up Link" onSave={save}>
      <SettingsPanel>
        <TextField
          name="signUp.linkLabel"
          defaultValue={signUp.linkLabel}
          label="Text"
          inputProps={{
            'aria-label': 'sign up link',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}
