import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {useAttendeeVariables} from 'Event'
import Attachments from 'Event/business-mastery/TeamCompetition/Day/SubmissionForm/Attachments'
import {useForm} from 'react-hook-form'
import {useToggleArray} from 'lib/toggle'
import TextField from 'Event/business-mastery/TextField'
import {now, useLocalTime} from 'lib/date-time'
import SuccessDialog from 'lib/ui/Dialog/SuccessDialog'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import InputLabel from '@material-ui/core/InputLabel'
import {useSubmission} from 'Event/business-mastery/TeamCompetition/Day/use-submission'

/**
 * How long to show the Submitted Pop-Up/Alert before it auto-closes
 */
const SHOW_SUBMITTED_MESSAGE_TIMEOUT_SECS = 2

export default function SubmissionForm(props: {
  day: number
  submission: number
  disabled: boolean
  activeDay: number | null
  hidden?: boolean
  onComplete?: () => void
}) {
  const {disabled, day, activeDay, hidden, onComplete} = props

  const v = useAttendeeVariables()
  const [processing, toggleProcessing] = useToggleArray()
  const {register, handleSubmit, watch} = useForm()
  const [showingSubmitted, toggleSubmitted] = useToggleArray()
  const [newFiles, setNewFiles] = useState<File[]>([])

  useEffect(() => {
    if (!showingSubmitted) {
      return
    }

    const timer = setTimeout(() => {
      toggleSubmitted()
    }, SHOW_SUBMITTED_MESSAGE_TIMEOUT_SECS * 1000)

    return clearTimeout(timer)
  }, [showingSubmitted, toggleSubmitted])

  const submission = useSubmission(props.day, props.submission)

  const completedSubmission = submission.alreadySubmitted

  useEffect(() => {
    if (completedSubmission) {
      onComplete && onComplete()
    }
  }, [completedSubmission, onComplete])

  const handleAddNewFile = useCallback((file: File) => {
    setNewFiles((files) => [...files, file])
  }, [])

  const handleRemoveNewFile = useCallback((removedFile: File) => {
    setNewFiles((files) =>
      files.filter((file) => file.name !== removedFile.name),
    )
  }, [])

  if (submission.loading) {
    return null
  }

  const save = ({progress, value_added, measure_success}: any) => {
    if (processing) {
      return
    }

    toggleProcessing()
    Promise.all([
      ...newFiles.map((file) => submission.attachments.attachFile(file)),
      submission.progress.save(progress),
      submission.valueAdded.save(value_added),
      submission.measureSuccess.save(measure_success),
      submission.submittedAt.save(now()),
    ])
      .then(() => {
        setNewFiles([])
        toggleSubmitted()
      })
      .finally(toggleProcessing)
  }

  const hasFilledField =
    Boolean(watch('progress')) ||
    Boolean(watch('value_added')) ||
    Boolean(watch('measure_success'))

  const canFill = !submission.alreadySubmitted && !processing && !disabled
  const canSave = canFill && hasFilledField

  if (hidden) {
    return null
  }

  return (
    <>
      <SuccessDialog showing={showingSubmitted} onClose={toggleSubmitted}>
        {v('{{ submission_received_message }}', 'Submission Received!')}
      </SuccessDialog>
      <Accordion elevation={2}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            {v('{{ team_submission }}', 'Submission')} {props.submission}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledForm onSubmit={handleSubmit(save)}>
            <Box mb={2}>
              <Box mb={1}>
                <InputLabel>
                  {v('{{ team_progress_question }}', 'Progress?')}
                </InputLabel>
              </Box>
              <TextField
                name="progress"
                variant="outlined"
                fullWidth
                rows={5}
                multiline
                defaultValue={submission.progress.note?.body || null}
                disabled={!canFill}
                inputProps={{
                  ref: register,
                  'aria-label': 'progress',
                }}
              />
            </Box>
            <Box mb={2}>
              <Box mb={1}>
                <InputLabel>
                  {v('{{ team_value_added_question }}', 'Value added?')}
                </InputLabel>
              </Box>
              <TextField
                name="value_added"
                variant="outlined"
                fullWidth
                rows={5}
                multiline
                defaultValue={submission.valueAdded.note?.body || null}
                disabled={!canFill}
                inputProps={{
                  ref: register,
                  'aria-label': 'value added',
                }}
              />
            </Box>
            <Box mb={3}>
              <Box mb={1}>
                <InputLabel>
                  {v(
                    '{{ team_measuring_success_question }}',
                    'How are you measuring your success?',
                  )}
                </InputLabel>
              </Box>
              <TextField
                name="measure_success"
                variant="outlined"
                fullWidth
                rows={5}
                multiline
                defaultValue={submission.measureSuccess.note?.body || null}
                disabled={!canFill}
                inputProps={{
                  ref: register,
                  'aria-label': 'measure success',
                }}
              />
            </Box>
            <Box mb={3}>
              <Attachments
                day={day}
                attachments={submission.attachments}
                files={submission.attachments.note?.files ?? []}
                onAdd={handleAddNewFile}
                onRemove={handleRemoveNewFile}
                disabled={!canSave}
                newFiles={newFiles}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <SaveButton
                showing={!submission.alreadySubmitted}
                canSave={canSave}
                isLocked={disabled}
                activeDay={activeDay}
              />
              <SubmittedDate>{submission.submittedAt.note?.body}</SubmittedDate>
              <Box mt={2}>
                <SaveDescription showing={!submission.alreadySubmitted} />
              </Box>
            </Box>
          </StyledForm>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

function SaveButton(props: {
  showing: boolean
  activeDay: number | null
  canSave: boolean
  isLocked: boolean
}) {
  const {showing, canSave, isLocked, activeDay} = props
  const v = useAttendeeVariables()

  if (!showing) {
    return null
  }

  const activeLabel = v('{{ team_save }}', 'Save')

  const useActiveDayLabel = `${v(
    '{{ team_day_locked_button_active_day }}',
    'Locked, Use Day',
  )} ${activeDay}`

  const lockedOnlyLabel = v(
    '{{ team_day_locked_button_no_active_day }}',
    'Locked',
  )

  const lockedLabel = activeDay === null ? lockedOnlyLabel : useActiveDayLabel

  const label = isLocked ? lockedLabel : activeLabel

  const disabled = isLocked || !canSave

  return (
    <StyledSaveButton
      type="submit"
      variant="outlined"
      disabled={disabled}
      aria-label="save submission"
      fullWidth
      size="large"
    >
      {label}
    </StyledSaveButton>
  )
}

function SaveDescription(props: {showing: boolean}) {
  const v = useAttendeeVariables()
  if (!props.showing) {
    return null
  }

  return (
    <Typography color="error">
      <span>
        {v(
          '{{ team_save_warning }}',
          'Once saved, you cannot change any of your answers, or upload new files for this submission.',
        )}
      </span>
    </Typography>
  )
}

function SubmittedDate(props: {children?: string | null}) {
  const local = useLocalTime()
  const {children} = props
  if (!children) {
    return null
  }

  return <span>Submitted at: {local(children, 'DD-MM-YYYY')}</span>
}

const StyledForm = styled.form`
  width: 100%;
`

const StyledSaveButton = styled(Button)`
  background-color: #9ecb3d;
`
