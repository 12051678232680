import {Rule} from 'Event/attendee-rules'
import {hasMatch} from 'Event/attendee-rules/matcher'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {useSubmissions} from 'Event/SubmissionsProvider'
import {useEvent} from 'Event/EventProvider'

export default function VisibleOnMatch(props: {
  rules?: Rule[]
  children: React.ReactElement
}) {
  const show = useMatchOnRule()

  if (!show(props.rules)) {
    return null
  }

  return props.children
}

export function useMatchOnRule() {
  const {groups, tags} = useAttendeeProfile()
  const {answers} = useSubmissions()
  const isEditMode = useEditMode()
  const {event} = useEvent()
  const {forms} = event

  const check = (rules?: Rule[]) => {
    /**
     * Always show component in edit mode, otherwise it would be pretty
     * difficult to configure.
     */
    if (isEditMode) {
      return true
    }

    /**
     * If no rules have been configured, the default should be to SHOW the
     * component.
     */
    if (!rules || rules.length === 0) {
      return true
    }

    const showing = hasMatch({groups, tags, answers, forms}, rules)
    if (!showing) {
      return false
    }

    return true
  }

  return check
}
