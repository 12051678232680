import React, {useState} from 'react'
import {getFontStyles, Font} from '../../lib/font'
import {formatPrice} from '../../lib/currency'
import Container from '../../Block/Container'
import {BlockBase} from '../base'
import {BlockComponentProps} from '../index'
import styles from './TicketSelector.module.css'
import Icon from '../../lib/ui/Icon'
import RadioInput from '../../lib/ui/RadioInput'
import {usePurchase} from '../../PurchaseContext'
import {MarketplaceTicket} from '../../lib/marketplace-ticket'
import {useSection} from '../../Section'
import {rgba} from '../../lib/color'
import classNames from 'classnames'

export interface TicketSelectorBlock extends BlockBase {
  type: 'TicketSelector'
  name: {
    color: string
    opacity: number
    font: null | Font
    fontSize: number
  }
  title: {
    text: string
    color: string
    opacity: number
    font: null | Font
    fontSize: number
    enabled: boolean
  }
  description: {
    color: string
    opacity: number
    font: null | Font
    fontSize: number
    enabled: boolean
  }
  textHoverColor: string
  spacing: number
  selectorIcon: 'circle' | 'circle_check' | 'x' | 'hand' | 'ticket'
}

interface TicketSelectorProps
  extends TicketSelectorBlock,
    BlockComponentProps {}

export default function TicketSelector(props: TicketSelectorProps) {
  const {id} = props
  const {selectedTicket, setSelectedTicket, tickets} = usePurchase()
  const {calculateVisibility} = useSection()

  const selectTicket = (id: number) => {
    const target = tickets?.find((ticket) => ticket.id === id)

    if (!target) {
      return
    }

    setSelectedTicket(target)
    calculateVisibility()
  }

  return (
    <Container {...props}>
      <div className={classNames([styles.content, 'block-content'])} id={id}>
        <div className={styles.radioGroup}>
          <Title {...props} />
          {tickets.map((ticket, index) => {
            const isLast = tickets.length === index + 1
            const selected = ticket.id === selectedTicket?.id
            return (
              <Ticket
                key={ticket.id}
                {...props}
                ticket={ticket}
                isLast={isLast}
                selected={selected}
                onClick={() => selectTicket(ticket.id)}
              />
            )
          })}
        </div>
      </div>
    </Container>
  )
}

function Ticket(
  props: TicketSelectorBlock & {
    ticket: MarketplaceTicket
    selected: boolean
    isLast?: boolean
    onClick: () => void
  },
) {
  const {
    name,
    spacing,
    ticket,
    isLast,
    onClick,
    selected,
    textHoverColor,
  } = props
  const [hover, setHover] = useState(false)

  return (
    <div
      style={{
        marginBottom: isLast ? '0 !important' : spacing + 'px',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div className={styles.body}>
        <RadioInput
          value={ticket.id}
          checked={selected}
          checkedIcon={<RadioIcon {...props} checked hover={hover} />}
          icon={<RadioIcon {...props} hover={hover} />}
          name={'ticketSelector'}
          aria-label="ticket"
        />

        <div
          style={{
            color: hover ? textHoverColor : name.color,
            fontSize: name.fontSize + 'px',
            opacity: name.opacity / 100,
            margin: '0 0 0 24px',
            ...getFontStyles(name.font),
          }}
        >
          {ticket.name} - ${formatPrice(ticket.price)}
        </div>
      </div>
      <Description {...props} hover={hover} />
    </div>
  )
}

function RadioIcon(
  props: TicketSelectorBlock & {checked?: boolean; hover?: boolean},
) {
  const {checked, selectorIcon, name, hover, textHoverColor} = props

  const color = hover
    ? rgba(textHoverColor, name.opacity / 100)
    : rgba(name.color, name.opacity / 100)
  const size = name.fontSize

  switch (selectorIcon) {
    case 'circle':
      return (
        <RadioIconBox>
          {checked ? (
            <Icon className="fa-solid fa-circle" iconSize={size} />
          ) : (
            <Icon className="fa-regular fa-circle" iconSize={size} />
          )}
        </RadioIconBox>
      )
    case 'circle_check':
      return (
        <RadioIconBox>
          {checked ? (
            <Icon
              className="fa-sharp fa-solid fa-circle-check"
              iconSize={size}
              color={color}
            />
          ) : (
            <Icon
              className="fa-sharp fa-regular fa-circle-check"
              iconSize={size}
              color={color}
            />
          )}
        </RadioIconBox>
      )
    case 'x':
      return (
        <RadioIconBox>
          {checked ? (
            <Icon
              className="fa-solid fa-circle-xmark"
              iconSize={size}
              color={name.color}
            />
          ) : (
            <Icon
              className="fa-regular fa-circle-xmark"
              iconSize={size}
              color={name.color}
            />
          )}
        </RadioIconBox>
      )
    case 'hand':
      return (
        <RadioIconBox>
          {checked ? (
            <Icon
              className="fa-solid fa-hand-point-right"
              iconSize={size}
              color={name.color}
            />
          ) : (
            <Icon
              className="fa-light fa-hand-point-right"
              iconSize={size}
              color={name.color}
            />
          )}
        </RadioIconBox>
      )
    case 'ticket':
      return (
        <RadioIconBox>
          {checked ? (
            <Icon
              className="fa-solid fa-ticket"
              iconSize={size}
              color={name.color}
            />
          ) : (
            <Icon
              className="fa-light fa-ticket"
              iconSize={size}
              color={name.color}
            />
          )}
        </RadioIconBox>
      )
    default:
      throw new Error('Missing ticket selector icon')
  }
}

function Title(props: TicketSelectorBlock) {
  const {title} = props

  if (!title.enabled) {
    return null
  }

  return (
    <div
      style={{
        margin: '0 0 8px 0px',
        color: title.color,
        fontSize: title.fontSize + 'px',
        opacity: title.opacity / 100,
        ...getFontStyles(title.font),
      }}
    >
      {title.text}
    </div>
  )
}

function Description(
  props: TicketSelectorBlock & {ticket: MarketplaceTicket; hover?: boolean},
) {
  const {description, ticket, hover, textHoverColor} = props

  if (!description.enabled) {
    return null
  }

  return (
    <p
      style={{
        margin: '8px 0 0 44px',
        color: hover ? textHoverColor : description.color,
        fontSize: description.fontSize + 'px',
        opacity: description.opacity / 100,
        ...getFontStyles(description.font),
      }}
    >
      {ticket.description}
    </p>
  )
}

const RadioIconBox = (props: {children: React.ReactNode}) => {
  const {children} = props

  return <div className={styles.radioIconBox}>{children}</div>
}
