'use client'

import React, {useCallback, useEffect, useState} from 'react'

interface CountriesProviderProps {
  children: React.ReactNode
  countries: Country[]
  fetchStates: (countryId: number) => Promise<State[]>
}

export interface Country {
  id: number
  name: string
  code: string
}

export interface State {
  id: number
  name: string
}

interface CountriesContextProps {
  countries: Country[]
  getStates: (countryId: number) => Promise<State[]>
}

const CountriesContext = React.createContext<CountriesContextProps | undefined>(
  undefined,
)

export default function CountriesProvider(props: CountriesProviderProps) {
  const {children, countries, fetchStates} = props

  const [states, setStates] = useState<Record<string, State[]>>({})

  const getStates = useCallback(
    async (countryId: number) => {
      if (states[countryId]) {
        return states[countryId]
      }

      const result = await fetchStates(countryId)
      setStates((current) => ({
        ...current,
        [countryId]: result,
      }))

      return result
    },
    [fetchStates, states],
  )

  return (
    <CountriesContext.Provider value={{countries, getStates}}>
      {children}
    </CountriesContext.Provider>
  )
}

export function useCountries() {
  const context = React.useContext(CountriesContext)
  if (!context) {
    throw new Error('useCountries must be used within a CountriesProvider')
  }

  return context
}

export function useCountryName(countryId: number | undefined) {
  const {countries} = useCountries()
  return countries.find((c) => c.id === countryId)?.name
}

export function useStateName(
  countryId: number | undefined,
  stateId: number | undefined,
) {
  const {getStates} = useCountries()

  const [name, setName] = useState<string | null>(null)

  useEffect(() => {
    if (!countryId) {
      setName(null)
      return
    }

    getStates(countryId).then((states) => {
      const target = states.find((s) => s.id === stateId)

      if (!target) {
        setName(null)
        return
      }

      setName(target.name)
    })
  }, [countryId, getStates, stateId])

  return name
}

export function useStates(countryId: number | null) {
  const [states, setStates] = useState<State[]>([])
  const {getStates} = useCountries()

  useEffect(() => {
    if (!countryId) {
      setStates([])
      return
    }

    getStates(countryId).then((states) => setStates(states))
  }, [countryId, getStates])

  return states
}
