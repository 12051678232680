import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { icons } from '@ckeditor/ckeditor5-core/src/index';
import { ButtonView } from '@ckeditor/ckeditor5-ui/src';

const iconsMap = new Map( [
	[ 'left', icons.alignLeft ],
	[ 'right', icons.alignRight ],
	[ 'unset', icons.alignCenter ]
] );

const items = [ 'left', 'unset', 'right' ];
export default class CustomButtonAlignmentUI extends Plugin {
	static get requires() {
		return [ ContextualBalloon ];
	}

	static get pluginName() {
		return 'CustomButtonAlignmentUI';
	}

	init() {
		this._createDropdown();
	}

	_createDropdown() {
		const editor = this.editor;
		const componentFactory = editor.ui.componentFactory;

		for ( const item of items ) {
			this._addButton( item );
		}

		componentFactory.add( 'customButtonAlignment', locale => {
			const dropdownView = createDropdown( locale );
			const buttons = items.map( option => componentFactory.create( `customButtonAlignment:${ option }` ) as ButtonView );
			addToolbarToDropdown( dropdownView, buttons, {isVertical: true, ariaLabel: 'custom button alignment'} );

			dropdownView.buttonView.set( {
				tooltip: true,
				withText: true
			} );

			dropdownView.buttonView.bind( 'icon' ).toMany( buttons, 'isOn', ( ...areActive ) => {
				// Get the index of an active button.
				const index = areActive.findIndex( value => value );

				// If none of the commands is active, display either default Label or the first button's label.
				if ( index < 0 ) {
					return icons.alignCenter;
				}

				// Return active button's icon.
				return buttons[ index ].icon;
			} );

			dropdownView.bind( 'isEnabled' ).toMany( buttons, 'isEnabled', ( ...areEnabled ) => areEnabled.some( isEnabled => isEnabled ) );
			return dropdownView;
		} );
	}

	_addButton( option: string ) {
		const editor = this.editor;

		editor.ui.componentFactory.add( `customButtonAlignment:${ option }`, locale => {
			const command = editor.commands.get( 'customButtonAlignment' )!;
			const buttonView = new ButtonView( locale );

			buttonView.set( {
				label: option,
				icon: iconsMap.get( option ),
				tooltip: false,
				withText: false
			} );

			// Bind button model to command.
			buttonView.bind( 'isEnabled' ).to( command );
			buttonView.bind( 'isOn' ).to( command, 'value', value => value === option );

			// Execute command.
			this.listenTo( buttonView, 'execute', () => {
				editor.execute( 'customButtonAlignment', { value: option } );
				editor.editing.view.focus();
			} );

			return buttonView;
		} );
	}
}
