import React from 'react'
import styled from 'styled-components'
import {Description as DescriptionText} from 'lib/ui/typography'
import Label from 'lib/ui/TextField/Label'
import {useEvent} from 'Event/EventProvider'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import ConvertKitTagInput from 'organization/Event/DashboardConfig/ConvertKitTagInput'

export type DescribedConvertKitTagInputProps = {
  onChange: (tag: ConvertKitTag | null) => void
  value?: ConvertKitTag | null
  className?: string
  title?: string
  description?: string
  fullWidth?: boolean
  disabled?: boolean
}

export default function DescribedConvertKitTagInput(
  props: DescribedConvertKitTagInputProps,
) {
  const {className, title, description, value, onChange, disabled} = props
  const {event} = useEvent()
  if (!event.has_convert_kit) {
    return null
  }

  return (
    <>
      <FieldTitle>{title}</FieldTitle>
      <Box fullWidth={props.fullWidth} className={className}>
        <TopDescription>{description}</TopDescription>
        <TagInputHolder>
          <ConvertKitTagInput
            value={value}
            onChange={onChange}
            inputVariant={'outlined'}
            disabled={disabled}
            inputLabel={''}
            label={''}
          />
        </TagInputHolder>
        <RightDescription>{description}</RightDescription>
      </Box>
    </>
  )
}

function TopDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }
  return (
    <TopDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </TopDescriptionBox>
  )
}

function RightDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return (
    <RightDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </RightDescriptionBox>
  )
}

const Box = styled.div<{fullWidth?: boolean}>`
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  margin-bottom: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    align-items: baseline;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }
`

const TopDescriptionBox = styled.div`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const RightDescriptionBox = styled(TopDescriptionBox)`
  display: none;
  flex: 1 0 40%;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[7]}`};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  margin-left: ${(props) => props.theme.spacing[27]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: inline;
  }
`

const TagInputHolder = styled.div`
  max-width: 640px;
  width: 100%;

  div[role='combobox'] {
    border-radius: 3px;
  }
`

function FieldTitle(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <StyledLabel>{props.children}</StyledLabel>
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`
