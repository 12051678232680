import React from 'react'
import CheckboxesGroup from 'organization/Obie/Blocks/ProcessForm/Form/CheckboxesGroup'
import LongForm from 'organization/Obie/Blocks/ProcessForm/Form/LongForm'
import RadioOptions from 'organization/Obie/Blocks/ProcessForm/Form/RadioOptions'
import {
  QUESTION_TYPE_LONG_FORM,
  QUESTION_TYPE_MULTIPLE_CHOICE,
  QUESTION_TYPE_PATH_LONG_FORM,
  QUESTION_TYPE_PATH_MULTIPLE_CHOICE,
  QUESTION_TYPE_PATH_PARENT,
  QUESTION_TYPE_PATH_RADIO_OPTIONS,
  QUESTION_TYPE_RADIO_OPTIONS,
  FormQuestionProps,
} from 'organization/Obie/ObieQuestionsProvider'

export default function FormQuestionContent(props: FormQuestionProps) {
  const {question} = props

  switch (question?.type) {
    case QUESTION_TYPE_LONG_FORM:
      return <LongForm {...props} />
    case QUESTION_TYPE_MULTIPLE_CHOICE:
      return <CheckboxesGroup {...props} />
    case QUESTION_TYPE_PATH_PARENT:
      return <RadioOptions {...props} />
    case QUESTION_TYPE_PATH_LONG_FORM:
      return <LongForm {...props} />
    case QUESTION_TYPE_PATH_RADIO_OPTIONS:
      return <RadioOptions {...props} />
    case QUESTION_TYPE_PATH_MULTIPLE_CHOICE:
      return <CheckboxesGroup {...props} />
    case QUESTION_TYPE_RADIO_OPTIONS:
      return <RadioOptions {...props} />
    default:
      return <div>Unknown Question type</div>
  }
}
