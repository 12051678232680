import {useEvent} from 'Event/EventProvider'
import {Certificate} from 'lib/event-api/certificates/types'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

interface UseAddCertificateData {
  title: string
  source_pdf: File
}

export function useAddCertificate() {
  const {client} = useOrganization()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (data: UseAddCertificateData) => {
      const url = api(`/events/${event.id}/certificates`)

      const formData = new FormData()
      formData.set('source_pdf', data.source_pdf)
      formData.set('title', data.title)

      return client.post<Certificate>(url, formData)
    },
    onSuccess: (certificate) => {
      qc.setQueryData<Certificate[]>(
        ['events', event.id, 'certificates'],
        (certificates) => {
          if (!certificates) {
            return [certificate]
          }

          return [...certificates, certificate]
        },
      )
    },
  })
}
