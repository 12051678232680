import React from 'react'
import Container from '../../Block/Container'
import {BlockComponentProps} from '../index'
import {BlockBase} from '../base'

export interface SeparatorBlock extends BlockBase {
  type: 'Separator'
  separatorStyle: 'solid' | 'dotted' | 'dashed'
  color: string
  opacity: number
  height: number
  width: number
  radius: number
}

export default function Separator(props: SeparatorBlock & BlockComponentProps) {
  return (
    <Container {...props}>
      <hr
        style={{
          borderTop: `${props.height}px ${props.separatorStyle} ${props.color}`,
          width: `${props.width}%`,
          opacity: props.opacity / 100,
          borderRadius: `${props.radius}px`,
          margin: 0,
          borderBottom: 'none',
        }}
      />
    </Container>
  )
}
