import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import {Description as DescriptionText} from 'lib/ui/typography'
import Label from 'lib/ui/TextField/Label'
import DeleteIconButton from 'lib/ui/IconButton/DeleteIconButton'
import TextField from '@material-ui/core/TextField'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {onChangeStringHandler} from 'lib/dom'

export type DescribedGroupsInputProps = {
  'aria-label'?: string
  name: string
  className?: string
  label?: string
  title?: string
  disabled?: boolean
  description?: string
  fullWidth?: boolean
  existingGroups: GroupPair[]
  onChangeExistingGroups: (groups: GroupPair[]) => void
  newGroups: GroupPair[]
  onChangeNewGroups: (groups: GroupPair[]) => void
}

export type GroupPair = {
  key: string
  value: string
}

export default function DescribedGroupsInput(props: DescribedGroupsInputProps) {
  const {
    className,
    title,
    description,
    existingGroups,
    onChangeExistingGroups,
    newGroups,
    onChangeNewGroups,
  } = props

  const updateNewGroup = (targetIndex: number, updated: GroupPair) => {
    const updatedList = newGroups.map((g, i) => {
      const isTarget = i === targetIndex
      if (isTarget) {
        return updated
      }

      return g
    })

    onChangeNewGroups(updatedList)
  }

  const addGroup = () => {
    const added = [...newGroups, {key: '', value: ''}]
    onChangeNewGroups(added)
  }

  const onRemoveExistingGroup = (targetIndex: number) => {
    const removed = existingGroups.filter(
      (_group, index) => index !== targetIndex,
    )

    onChangeExistingGroups(removed)
  }

  const updateExistingGroup = (targetIndex: number, value: string) => {
    const updated = existingGroups.map((group, index) => {
      if (index !== targetIndex) {
        return group
      }

      return {
        key: group.key,
        value,
      }
    })

    onChangeExistingGroups(updated)
  }

  return (
    <>
      <FieldTitle>{title}</FieldTitle>
      <Box fullWidth={props.fullWidth} className={className}>
        <TopDescription>{description}</TopDescription>
        <Contents>
          {existingGroups.map((group: GroupPair, index) => (
            <GroupInput
              key={index}
              group={group}
              disabled={props.disabled}
              onChange={(value) => updateExistingGroup(index, value)}
              onRemove={() => onRemoveExistingGroup(index)}
            />
          ))}
          {newGroups.map((group, index) => (
            <NewGroupInput
              key={index}
              group={group}
              onChange={(updated) => updateNewGroup(index, updated)}
            />
          ))}
          <NewItemButton
            fullWidth
            variant="outlined"
            aria-label="add group"
            onClick={addGroup}
            text="Add Group"
          />
        </Contents>
        <RightDescription>{description}</RightDescription>
      </Box>
    </>
  )
}

const GroupInput = React.forwardRef(
  (
    props: {
      group: GroupPair
      disabled?: boolean
      onChange: (value: string) => void
      onRemove: () => void
    },
    ref,
  ) => {
    const {group, disabled, onChange} = props
    return (
      <GroupInputContainer data-testid="group-box">
        <StyledTextField
          variant="outlined"
          fullWidth
          inputProps={{ref, 'aria-label': `${group.key} input`}}
          defaultValue={group.value}
          disabled={disabled}
          label={props.group.key}
          onChange={onChangeStringHandler(onChange)}
        />
        <DeleteIconButton aria-label="remove group" onClick={props.onRemove} />
      </GroupInputContainer>
    )
  },
)

function NewGroupInput(props: {
  group: GroupPair
  onChange: (group: GroupPair) => void
}) {
  const {group} = props
  const update = (key: keyof GroupPair) => (value: string) => {
    const updated = {
      ...props.group,
      [key]: value,
    }

    props.onChange(updated)
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <TextField
          label="Group"
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group name'}}
          onChange={onChangeStringHandler(update('key'))}
          value={group.key}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          label="Value"
          fullWidth
          variant="outlined"
          inputProps={{'aria-label': 'group value'}}
          value={group.value}
          onChange={onChangeStringHandler(update('value'))}
        />
      </Grid>
    </Grid>
  )
}

function TopDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }
  return (
    <TopDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </TopDescriptionBox>
  )
}

function RightDescription(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return (
    <RightDescriptionBox>
      <DescriptionText>{props.children}</DescriptionText>
    </RightDescriptionBox>
  )
}

const Box = styled.div<{fullWidth?: boolean}>`
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  margin-bottom: ${(props) => props.theme.spacing[10]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    align-items: baseline;
    margin-bottom: ${(props) => props.theme.spacing[5]};
  }
`

const TopDescriptionBox = styled.div`
  padding: 0;
  border: none;
  margin-left: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

const RightDescriptionBox = styled(TopDescriptionBox)`
  display: none;
  flex: 1 0 40%;
  padding: ${(props) => `${props.theme.spacing[1]} ${props.theme.spacing[7]}`};
  border-left: 1px solid ${(props) => props.theme.colors.primary};
  margin-left: ${(props) => props.theme.spacing[27]};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: inline;
  }
`

const Contents = styled.div`
  max-width: 640px;
  width: 100%;
`

const GroupInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
  margin-right: ${(props) => props.theme.spacing[2]} !important;
`

function FieldTitle(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <StyledLabel>{props.children}</StyledLabel>
}

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]} !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`
