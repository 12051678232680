import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React, {useMemo} from 'react'
import Typography from '@material-ui/core/Typography'
import MuiButton, {ButtonProps} from '@material-ui/core/Button'
import {spacing} from 'lib/ui/theme'
import {useCardsTemplate} from 'Event/template/Cards'
import Paper from '@material-ui/core/Paper'
import Background, {ColorOverlay, Content} from 'Event/auth/Login/Background'
import BaseLogo from 'Event/auth/Login/Logo'
import Text from 'Event/auth/Login/Description/Text'
import defaultBackground from 'assets/images/background_login.png'
import {BackgroundPosition} from 'lib/ui/BackgroundPositionSelector'

export default function Page(props: {
  isPreview?: LoginProps['isPreview']
  children: React.ReactElement | React.ReactElement[]
}) {
  const {login, textColor} = useCardsTemplate()

  const randomBackground = useRandomBackground()

  const randomBackgroundImage = randomBackground ? randomBackground.image : null

  const backgroundImage =
    login.background || randomBackgroundImage || defaultBackground

  const backgroundPosition = randomBackground
    ? randomBackground.position
    : 'center center'

  return (
    <StyledBackground
      isPreview={props.isPreview}
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      isEnabled={login.backgroundEnabled}
      textColor={textColor}
      loginTextColor={login.description.color}
    >
      <Logo />
      <Container>{props.children}</Container>
    </StyledBackground>
  )
}

export function ErrorMessage(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

export function Button(props: ButtonProps) {
  const template = useCardsTemplate()
  const {login} = template
  const borderRadius = `${login.submitButton.borderRadius}px` || spacing[14]
  const hoverColor =
    login.submitButton.hoverBackgroundColor ||
    login.submitButton.backgroundColor
  return (
    <StyledButton
      variant="contained"
      fullWidth
      backgroundColor={login.submitButton.backgroundColor}
      color={login.submitButton.textColor}
      borderRadius={borderRadius}
      hoverColor={hoverColor}
      {...props}
    />
  )
}

export const DescriptionText = styled(Text)<{
  textColor?: string
  linkUnderline?: boolean
  linkColor?: string
}>`
  text-align: left;
  margin-bottom: ${(props) => props.theme.spacing[4]};

  a {
    color: ${(props) => props.textColor};
    text-decoration: none;

    &:hover {
      text-decoration: ${(props) =>
        props.linkUnderline ? 'underline' : 'none'};
    }
  }
`

const StyledBackground = styled(Background)<{
  isEnabled?: boolean
  backgroundImage: string
  backgroundPosition: BackgroundPosition
  textColor: string
  loginTextColor: string | null
}>`
  ${(props) =>
    props.isEnabled ? `background: url(${props.backgroundImage});` : ''}

  color: ${(props) =>
    props.loginTextColor ? props.loginTextColor : props.textColor} !important;
  transition: all 300ms ease-in 200ms;
  display: flex;
  background-size: cover;
  background-position: ${(props) => props.backgroundPosition};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;

  ${ColorOverlay} {
    flex-direction: column;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      flex-direction: row;
    }
  }

  ${Content} {
    padding: unset;
    height: 100%;
    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 100%;
      margin-bottom: 0;
      overflow: auto;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
      display: flex;
      flex-direction: row;
      margin: unset;
      width: 100%;
    }
  }
`

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius} !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background-color: ${(props) => props.backgroundColor} !important;

  &:hover {
    background-color: ${(props) => props.hoverColor} !important;
  }
`

export const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing[10]};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  vertical-align: middle;
  margin: auto;
  overflow: auto;
  color: inherit;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    border-radius: 10px 10px 0 0;
    height: unset;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    border-radius: 10px 10px 0 0;
    height: unset;
    width: 100%;
    margin-bottom: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: unset;
    height: 100vh;
    width: 100%;
    margin: unset;
  }
`

export const StyledFormContainer = styled.div`
  max-width: 450px;
  margin: auto;
`

export const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
  text-align: center;
`

export function useRandomBackground() {
  const template = useCardsTemplate()

  /**
   * This is annoying, but we need to pull out all individual background property
   * values so that they can be referenced as deps in useMemo. If we placed
   * template in there any updates could result in a new random bg.
   */
  const background1 = useMemo(
    () => ({
      image: template.backgroundImage1,
      position: template.backgroundImage1Position,
    }),
    [template.backgroundImage1, template.backgroundImage1Position],
  )
  const background2 = useMemo(
    () => ({
      image: template.backgroundImage2,
      position: template.backgroundImage2Position,
    }),
    [template.backgroundImage2, template.backgroundImage2Position],
  )
  const background3 = useMemo(
    () => ({
      image: template.backgroundImage3,
      position: template.backgroundImage3Position,
    }),
    [template.backgroundImage3, template.backgroundImage3Position],
  )
  const background4 = useMemo(
    () => ({
      image: template.backgroundImage4,
      position: template.backgroundImage4Position,
    }),
    [template.backgroundImage4, template.backgroundImage4Position],
  )
  const background5 = useMemo(
    () => ({
      image: template.backgroundImage5,
      position: template.backgroundImage5Position,
    }),
    [template.backgroundImage5, template.backgroundImage5Position],
  )

  const backgrounds = useMemo(
    () =>
      [
        background1,
        background2,
        background3,
        background4,
        background5,
      ].filter((background) => Boolean(background.image)),
    [background1, background2, background3, background4, background5],
  ) as {image: string; position: BackgroundPosition}[]

  return useMemo(() => {
    const numBackgrounds = backgrounds.length

    const hasBackground = numBackgrounds > 0
    if (!hasBackground) {
      return null
    }

    const randomIndex = Math.floor(Math.random() * numBackgrounds)
    return backgrounds[randomIndex]
  }, [backgrounds])
}

export const Logo = styled(BaseLogo)`
  width: 100%;
  text-align: center;
  padding: ${(props) => props.theme.spacing[4]};
  margin: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 70%;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: auto;
    width: 100%;
    margin-bottom: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: auto;
    width: 100%;
    margin-bottom: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin: unset;
    width: auto;
    overflow: auto;
  }
`
