import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {useToggleArray} from 'lib/toggle'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useEvent} from 'Event/EventProvider'
import {getEventUrl, routesWithValue} from 'lib/url'
import {eventRoutes} from 'Event/Routes'
import {useParams} from 'react-router-dom'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'

export default function ShareModal(props: {
  isVisible: boolean
  onClose: () => void
}) {
  const {isVisible, onClose} = props
  const [allowModeration, setAllowModeration] = useToggleArray()

  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>Share Link</DialogTitle>
      <DialogContent>
        <Switch
          checked={allowModeration}
          onChange={onChangeCheckedHandler(setAllowModeration)}
          aria-label="toggle allow moderation"
          labelPlacement="end"
          label="Allow Moderation"
        />
        <ModerationPageURL allowModeration={allowModeration} />
      </DialogContent>
    </Dialog>
  )
}

function ModerationPageURL(props: {allowModeration: boolean}) {
  const {event} = useEvent()
  const {form} = useForm()
  const eventUrl = getEventUrl(event)
  const {status} = useParams<{status: FormSubmissionStatus}>()

  const pageURL = () => {
    const url = `${eventUrl}${
      routesWithValue(
        ':status',
        status,
        eventRoutes.form_moderation.stage[':status'],
      ).root
    }?token=${form.access_token}`

    if (props.allowModeration) {
      return `${url}&show_controls=true`
    }

    return url
  }

  const copy = () => {
    navigator.clipboard.writeText(pageURL())
  }

  return (
    <TextField
      value={pageURL()}
      fullWidth
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyIconButton
              aria-label="copy moderation page url"
              onClick={copy}
            />
          </InputAdornment>
        ),
      }}
      inputProps={{
        'aria-label': 'moderation page url',
      }}
    />
  )
}
