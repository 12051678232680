import React, {useState} from 'react'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {useLoadFont} from 'lib/FontSelect'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import Published from 'Event/Dashboard/editor/views/Published'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled from 'lib/ui/layout/Scheduled'
import Grid, {GridProps} from '@material-ui/core/Grid'
import {HashMap} from 'lib/list'
import styled from 'styled-components'
import {EditPost} from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPostConfig'
import ExpandedPostDialog from 'Event/template/Townhall/Dashboard/Main/BlogSection/ExpandedPostDialog'
import BlogPost from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPost'
import {
  BlogSectionProps,
  TownhallBlogPost,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import AddBlogPostButton from 'Event/template/Townhall/Dashboard/Main/BlogSection/AddBlogPostButton'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {getSortedIds} from 'Event/Dashboard/components/BlogPosts'

export default function BlogPostList(
  props: BlogSectionProps & {sectionId: string},
) {
  const template = useTownhallTemplate()
  const {postStyles, textColor} = template

  const [expandedId, setExpandedId] = useState<string | null>(null)

  useLoadFont(postStyles.titleFont)

  return (
    <Box>
      <EditPost
        {...props}
        id={expandedId}
        onClose={() => setExpandedId(null)}
      />
      <ExpandedPostDialog
        {...props}
        id={expandedId}
        onClose={() => setExpandedId(null)}
      />
      <Row
        level={1}
        md={12}
        sm={12}
        xs={12}
        {...props}
        onSelectPost={setExpandedId}
        textColor={textColor}
      />
      <Row
        level={2}
        md={6}
        sm={6}
        xs={12}
        {...props}
        onSelectPost={setExpandedId}
        textColor={textColor}
      />
      <Row
        level={3}
        md={4}
        sm={6}
        xs={12}
        {...props}
        onSelectPost={setExpandedId}
        textColor={textColor}
      />
      <Row
        level={4}
        md={3}
        sm={6}
        xs={12}
        {...props}
        onSelectPost={setExpandedId}
        textColor={textColor}
      />
      <EditModeOnly>
        <StyledAddBlogPostButton {...props} />
      </EditModeOnly>
    </Box>
  )
}

function Row(
  props: Omit<GridProps, 'color'> &
    BlogSectionProps & {
      sectionId: string
      level: number
      onSelectPost: (editing: string | null) => void
      textColor: string
    },
) {
  return (
    <RowContainer>
      <Content {...props} />
    </RowContainer>
  )
}

function Content(
  props: Omit<GridProps, 'color'> &
    BlogSectionProps & {
      sectionId: string
      level: number
      onSelectPost: (editing: string | null) => void
      textColor: string
    },
) {
  const {
    items,
    level,
    onSelectPost,
    xs,
    sm,
    md,
    sectionId,
    postStyles,
    postFormStyles,
    textColor,
  } = props

  const levelPostIds = Object.keys(items).filter((id) => {
    const post = items[id]
    return post.level === level
  })

  const levelPosts = levelPostIds.reduce((acc, i) => {
    acc[i] = items[i]
    return acc
  }, {} as HashMap<TownhallBlogPost>)

  const sortedIds = getSortedIds(levelPosts)

  return (
    <Grid container spacing={3}>
      {sortedIds.map((id) => {
        const post = items[id]
        return (
          <Blog
            id={id}
            sectionId={sectionId}
            post={post}
            onSelectPost={onSelectPost}
            postStyles={postStyles}
            postFormStyles={postFormStyles}
            textColor={textColor}
            xs={xs}
            sm={sm}
            md={md}
          />
        )
      })}
    </Grid>
  )
}

function Blog(
  props: Omit<GridProps, 'color'> & {
    id: string
    sectionId: string
    post: TownhallBlogPost
    postStyles?: BlogSectionProps['postStyles']
    postFormStyles?: BlogSectionProps['postFormStyles']
    onSelectPost: (editing: string | null) => void
    textColor: string
  },
) {
  const {
    post,
    id,
    sectionId,
    postStyles,
    postFormStyles,
    onSelectPost,
    textColor,
    xs,
    sm,
    md,
  } = props

  useLoadFont(postStyles?.titleFont)

  return (
    <Published component={post}>
      <VisibleOnMatch rules={post.rules}>
        <Scheduled
          component={{
            showingFrom: post.publishAt,
            showingUntil: post.showingUntil,
          }}
        >
          <Grid item xs={xs} sm={sm} md={md} key={id}>
            <Editable
              key={id}
              aria-label="edit blog post"
              onEdit={() => onSelectPost(id)}
            >
              <BlogPost
                post={post}
                postId={id}
                sectionId={sectionId}
                postStyles={postStyles}
                postFormStyles={postFormStyles}
                onOpen={() => onSelectPost(id)}
                textColor={textColor}
              />
            </Editable>
          </Grid>
        </Scheduled>
      </VisibleOnMatch>
    </Published>
  )
}

const Box = styled.div`
  width: 100%;
`

const RowContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const StyledAddBlogPostButton = styled(AddBlogPostButton)`
  margin-top: ${(props) => props.theme.spacing[3]};
  margin-bottom: ${(props) => props.theme.spacing[3]};
`
