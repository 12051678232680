import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Configurable from 'organization/Event/Configurable'
import {NavBarConfig} from 'Event/template/Townhall/Dashboard/NavBar/NavBarConfig'
import Grid from 'lib/ui/Grid'
import Hidden from '@material-ui/core/Hidden'
import Menu from 'Event/template/Townhall/Dashboard/NavBar/Menu'
import Emojis from 'Event/template/Townhall/Dashboard/NavBar/EmojiList'
import Container from 'Event/template/Townhall/Dashboard/Container'
import {useQueryParams} from 'lib/url'
import {useHistory} from 'react-router-dom'
import qs from 'qs'
import LinkTabs from 'Event/template/Townhall/Dashboard/NavBar/LinkTabs'
import {scrollIntoView} from 'seamless-scroll-polyfill'

export default function NavBar(props: {
  sectionId: string
  setSectionId: (sectionId: string) => void
}) {
  const {navBar} = useTownhallTemplate()

  return (
    <Box
      backgroundColor={navBar.backgroundColor}
      color={navBar.color}
      height={navBar.height}
    >
      <Configurable aria-label="edit navbar">
        <NavBarConfig />
        <Container>
          <Content {...props} />
        </Container>
      </Configurable>
    </Box>
  )
}

function Content(props: {
  sectionId: string
  setSectionId: (sectionId: string) => void
}) {
  const {sectionId, setSectionId} = props
  useUrlQueryNav(sectionId, setSectionId)

  const setSectionUrl = useSetSectionUrl()

  const goToSection = (newSectionId: string) => {
    const isCurrent = newSectionId === sectionId
    if (isCurrent) {
      return
    }

    setSectionUrl(newSectionId)
  }

  return (
    <Grid container>
      <NavBarGrid item md={9} xs={6}>
        <Hidden mdUp>
          <Menu sectionId={sectionId} onSelectItem={goToSection} />
        </Hidden>
        <Hidden smDown>
          <LinkTabs sectionId={sectionId} onSelectItem={goToSection} />
        </Hidden>
      </NavBarGrid>
      <NavBarGrid item md={3} xs={6}>
        <Emojis />
      </NavBarGrid>
    </Grid>
  )
}

/**
 * Navigate the sections depending on url params, such
 * as `section=main`.
 *
 * @param sectionId
 * @param setTabId
 */
function useUrlQueryNav(sectionId: string, setSectionId: (id: string) => void) {
  const {section: value} = useQueryParams()

  const executeScroll = useCallback((id: string) => {
    // When clicking the main link, it should scroll to the first section.
    const el = document.getElementById(id)
    if (el) {
      scrollIntoView(el, {behavior: 'smooth'})
    }
  }, [])
  // Select a section from URL.
  useEffect(() => {
    if (!value) {
      return
    }
    const alreadySelected = sectionId === value
    if (alreadySelected) {
      return
    }

    executeScroll(value)
    setSectionId(value)
  }, [value, executeScroll, setSectionId, sectionId])
}

function useSetSectionUrl() {
  const history = useHistory()

  return (id: string) => {
    const query = {
      section: id,
    }

    const queryString = qs.stringify(query)
    history.push({
      search: queryString,
    })
  }
}

const Box = styled.div<{
  backgroundColor: string
  color: string
  height: number
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height}px;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  position: sticky;
  top: 0;
  z-index: 5;
`

const NavBarGrid = styled(Grid)`
  display: flex;
  align-items: center;
`
