import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import React, {useEffect} from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {uuid} from 'lib/uuid'

export default function ToggleCustomButtonsSwitch() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const {techCheck} = useTemplate()
  const save = useSaveTemplate()

  const isEmpty = Object.keys(techCheck.customButtons || {}).length === 0

  const {hasCustomButtons} = techCheck

  const handleToggleWaiver = (isEnabled: boolean) => {
    const id = uuid()
    const button: NavButtonWithSize = {
      position: 1,
      text: 'Button',
      link: '',
      size: 12,
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      isCertificateButton: false,
      areaId: null,
      formId: null,
      certificateId: null,
      newTab: true,
      actionId: null,
      isVisible: true,
      infusionsoftTag: null,
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
      mailchimpTag: null,
      zapierTag: null,
      highLevelTag: null,
      webhook: null,
    }

    // We always want to have at least 1 button. This is because on toggling on, the API
    // could return back an array, rather than an {}. This leads to unexpected
    // results when set() is called, so we need to make sure `customButtons`
    // is ALWAYS an array.
    const customButtons = isEmpty
      ? {
          [id]: button,
        }
      : techCheck.customButtons

    save({
      techCheck: {
        hasCustomButtons: isEnabled,
        customButtons,
      },
    })
  }

  // If a user removes all buttons, we'll automatically
  // toggle off custom buttons. This is to ensure that
  // a button will always exist, and the object
  // won't be cast to an array [].
  useEffect(() => {
    if (hasCustomButtons && isEmpty) {
      save({
        techCheck: {
          hasCustomButtons: false,
        },
      })
    }
  }, [isEmpty, hasCustomButtons, save])

  const label = techCheck.hasCustomButtons ? 'Custom Buttons' : 'Default Button'

  return (
    <Switch
      checked={techCheck.hasCustomButtons}
      onChange={onChangeCheckedHandler(handleToggleWaiver)}
      labelPlacement="end"
      aria-label="toggle custom buttons"
      label={label}
    />
  )
}
