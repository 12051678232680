import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function AdditionalContentConfig() {
  const {techCheck} = useTemplate()
  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const content = replaceAtPath(
      data,
      'techCheck.additionalContent',
      '{{tech_check_additional_content}}',
    )
    save({
      ...data,
      localization: addTranslation({
        tech_check_additional_content: content ?? '',
      }),
    })
  }

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Additional Content" onSave={handleSave}>
      <SettingsPanel>
        <TextEditorContainer>
          <Controller
            name="techCheck.additionalContent"
            defaultValue={techCheck.additionalContent}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}
