import AgendaSection, {
  AGENDA_SECTION,
  AgendaSectionProps,
} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import BlogSection, {
  BLOG_SECTION,
  BlogSectionProps,
} from 'Event/template/Townhall/Dashboard/Main/BlogSection'
import ResourceSection, {
  RESOURCE_SECTION,
  ResourceSectionProps,
} from 'Event/template/Townhall/Dashboard/Main/ResourceSection'
import React from 'react'
import CountdownTimerSection, {
  COUNTDOWN_TIMER,
  CountdownTimerSectionProps,
} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'

export type DashboardSectionProps =
  | BlogSectionProps
  | AgendaSectionProps
  | ResourceSectionProps
  | CountdownTimerSectionProps

export default function DashboardSection(
  props: DashboardSectionProps & {
    sectionId: string
    index: number
  },
) {
  return <Section {...props} />
}

type SectionItemProps = DashboardSectionProps & {
  sectionId: string
  index: number
}

function Section(props: SectionItemProps) {
  switch (props.name) {
    case BLOG_SECTION:
      return <BlogSection {...props} />
    case AGENDA_SECTION:
      return <AgendaSection {...props} />
    case RESOURCE_SECTION:
      return <ResourceSection {...props} />
    case COUNTDOWN_TIMER:
      return <CountdownTimerSection {...props} />
    default:
      return null
  }
}
