import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import IconPicker from 'lib/ui/form/IconPicker'
import {Label} from 'lib/ui/typography'
import FontSelect from 'lib/FontSelect'
import {BulletedListBlock} from 'Event/Marketplace/Block/BulletedList'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'

interface BulletedListConfigProps extends BulletedListBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function BulletedListConfig(props: BulletedListConfigProps) {
  const {
    padding,
    alignment,
    background,
    id,
    icon,
    font,
    fontSize,
    color,
    iconColor,
    iconSize,
    bulletSpace,
    textSpace,
    border,
    onMoveSectionUp,
    onMoveSectionDown,
  } = props
  const {
    form: {control},
  } = useConfig()

  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Bullet List" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`icon`}
          defaultValue={icon || ''}
          control={control}
          render={({value, onChange}) => (
            <IconPicker value={value} onChange={onChange} />
          )}
        />

        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />

        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Typography"
          storageKey={`blocks-${id}-typography`}
        >
          <Label>Font</Label>
          <Controller
            control={control}
            name={`font`}
            defaultValue={font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`fontSize`}
            defaultValue={fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Font Size"
              />
            )}
          />
          <Controller
            name={`color`}
            control={control}
            defaultValue={color}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Icon Style"
          storageKey={`blocks-${id}-icon-style`}
        >
          <Controller
            name={`iconColor`}
            control={control}
            defaultValue={iconColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`iconSize`}
            defaultValue={iconSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Icon Size"
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Bullet Spacing"
          storageKey={`blocks-${id}-bullet-spacing`}
        >
          <Controller
            name={`bulletSpace`}
            defaultValue={bulletSpace}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Space Between Bullets"
              />
            )}
          />
          <Controller
            name={`textSpace`}
            defaultValue={textSpace}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Text Indent"
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Border"
          noDivider
          storageKey={`blocks-${id}-border`}
        >
          <Controller
            name={`border.width`}
            defaultValue={border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Border Thickness"
              />
            )}
          />
          <Controller
            name={`border.radius`}
            defaultValue={border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`border.color`}
            defaultValue={border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-background`}
        >
          <Controller
            name={`background.image`}
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`background.color`}
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`background.opacity`}
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
          open={false}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
