import React, {useState} from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import {HashMap} from 'lib/list'
import Configurable from 'organization/Event/Configurable'
import {BlogSectionConfig} from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogSectionConfig'
import BlogPostList from 'Event/template/Townhall/Dashboard/Main/BlogSection/BlogPostList'
import {BlogPost} from 'Event/Dashboard/components/BlogPosts'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import SectionHeading from 'Event/template/Townhall/Dashboard/Main/SectionHeading'
import SectionDescription from 'Event/template/Townhall/Dashboard/Main/SectionDescription'
import {BaseSectionProps} from 'Event/template/Townhall/Dashboard/Main/section'
import {Font} from 'lib/FontSelect'
import {InputStyles} from 'Event/Question'
import PostStylesConfig from 'Event/template/Townhall/Dashboard/Main/BlogSection/PostStylesConfig'
import PostFormStylesConfig from 'Event/template/Townhall/Dashboard/Main/BlogSection/PostFormStylesConfig'
import {SectionDraggable} from 'Event/template/Townhall/Dashboard/Main'
import SectionContainer from 'Event/template/Townhall/Dashboard/Main/SectionContainer'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'
import {useAttendeeVariables} from 'Event'

export const BLOG_SECTION = 'blog'

export type TownhallBlogPost = BlogPost & {
  level: number
  image: string | null
}

export type BlogSectionProps = BaseSectionProps & {
  name: typeof BLOG_SECTION
  items: HashMap<TownhallBlogPost>
  postStyles?: {
    titleFont?: Font | null
    titleTextColor?: string | null
    titleFontSize?: number
    dateTextColor?: string | null
    dateFontSize?: number
    contentFontSize?: number
    contentTextColor?: string | null
    spacing?: number
    background?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
  }
  postFormStyles?: {
    width?: number
    position?: string
    buttonSize?: number
    buttonRadius?: number
    buttonColor?: string
    buttonBackgroundColor?: string
    buttonHoverBackgroundColor?: string
    buttonPosition?: string
    buttonFontSize?: number
    buttonBorderWidth?: number
    buttonBorderColor?: string
    inputStyles?: InputStyles
  }
}

export const createBlogSection = (): BlogSectionProps => ({
  name: BLOG_SECTION,
  title: 'Blog',
  description: '',
  items: {},
  descriptionFontStyles: [],
  isEnabled: true,
  background: '#FFFFFF',
  color: null,
  font: null,
  postStyles: {
    titleTextColor: null,
    titleFontSize: 30,
    dateTextColor: null,
    dateFontSize: 14,
    contentTextColor: null,
    contentFontSize: 17,
    spacing: 32,
    titleFont: null,
    background: '#FFFFFF',
  },
  postFormStyles: {
    width: 100,
    position: 'center',
    buttonSize: 100,
    buttonRadius: 0,
    buttonColor: 'pink',
    buttonBackgroundColor: 'blue',
    buttonHoverBackgroundColor: 'blue',
    buttonPosition: 'center',
    buttonFontSize: 27,
    buttonBorderWidth: 0,
    buttonBorderColor: 'black',
    inputStyles: {
      labelColor: '#7d7a7a',
      borderColor: '#7d7a7a',
      backgroundColor: '#7d7a7a',
      backgroundOpacity: 0,
      textColor: '#7d7a7a',
      helperTextColor: '#7d7a7a',
    },
  },
})

export default function BlogSection(
  props: BlogSectionProps & {
    sectionId: string
    index: number
  },
) {
  const {
    title,
    items,
    descriptionFontStyles,
    description,
    isEnabled,
    sectionId,
  } = props

  const isEdit = useEditMode()
  const hasBlog = Object.keys(items).length > 0

  const showing = hasBlog && isEnabled
  const v = useAttendeeVariables()

  const [dragHandleProps, setDragHandleProps] = useState<
    DraggableProvidedDragHandleProps | undefined
  >(undefined)

  if (!showing && !isEdit) {
    return null
  }

  return (
    <Configurable
      aria-label="blog section config"
      editableProps={{handleProps: dragHandleProps}}
    >
      <BlogSectionConfig blogSection={props} sectionId={sectionId} />
      <SectionDraggable
        id={sectionId}
        index={props.index}
        setDragHandleProps={setDragHandleProps}
      >
        <SectionContainer {...props} id={sectionId}>
          <SectionHeading aria-label="blogs title" color={props.color}>
            {v(title)}
          </SectionHeading>
          <SectionDescription
            aria-label="blogs description"
            description={v(description)}
            fontStyles={descriptionFontStyles}
            color={props.color}
          />
          <EditModeOnly>
            <PostStylesConfig blogSection={props} sectionId={sectionId} />
            <PostFormStylesConfig blogSection={props} sectionId={sectionId} />
          </EditModeOnly>
          <BlogPostList {...props} />
        </SectionContainer>
      </SectionDraggable>
    </Configurable>
  )
}
