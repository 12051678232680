import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {orderedIdsByPosition} from 'lib/list'
import {ConfigContext} from 'organization/Event/Configurable'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import PageSettingsButton from 'organization/Marketplace/config/PageSettingsButton'
import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import Container from 'Event/Marketplace/Container'
import {ThankYouPageBlock} from 'Event/Marketplace/thank-you-page'
import {PurchasePageBlock} from 'Event/Marketplace/purchase-page'
import {UpsellPageBlock} from 'Event/Marketplace/upsell-page'
import PageSettings from 'organization/Marketplace/config/PageSettings'
import {JsonSave} from 'lib/JsonUpdateProvider'
import AddSectionButton from 'organization/Marketplace/config/AddSectionButton'
import MoveableSection from 'organization/Marketplace/config/MoveableSection'
import ToggleMobileModeSwitch from 'organization/Marketplace/config/ToggleMobileModeSwitch'
import MobileModeContainer from 'organization/Marketplace/config/MobileModeContainer'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import EnabledSwitch from 'organization/Marketplace/config/EnabledSwitch'
import {PageTemplate} from 'Event/Marketplace/page-template'

interface ConfigPageProps {
  template: PageTemplate
  updateTemplate: JsonSave<PageTemplate>
  footer?: React.ReactNode
  availableBlocks: Array<
    | PurchasePageBlock['type']
    | ThankYouPageBlock['type']
    | UpsellPageBlock['type']
  >
  rightToolbarItems: JSX.Element
  ButtonConfig: React.FC<any>
  copyButton?: {
    value: string
    tooltip: string
  }
  enabled?: boolean
  setEnabled?: (enabled: boolean) => void
  disableEnableToggle?: boolean
  isUpsellPage?: boolean
}

export default function ConfigPage(props: ConfigPageProps) {
  const {
    template,
    updateTemplate,
    footer,
    availableBlocks,
    rightToolbarItems,
    ButtonConfig,
    copyButton,
    enabled,
    setEnabled,
    disableEnableToggle,
  } = props
  const {sections} = template
  const [showingConfig, toggleConfig] = useToggleArray()
  const form = useForm()

  const sectionIds = orderedIdsByPosition(sections)

  const handleCopyUrl = () => {
    if (!copyButton) return
    navigator.clipboard.writeText(copyButton.value)
  }

  return (
    <Page
      toolbarItems={
        <>
          <EnabledSwitch
            enabled={enabled}
            setEnabled={setEnabled}
            disabled={disableEnableToggle}
          />

          <PageSettingsButton onClick={toggleConfig} />
        </>
      }
      rightToolbarItems={
        <RightToolbarItems>
          <ToggleMobileModeSwitch />
          {copyButton && (
            <StyledCopyIconButton
              aria-label="copy button"
              beforeCopyTooltip={copyButton.tooltip}
              onClick={handleCopyUrl}
            />
          )}
          {rightToolbarItems}
        </RightToolbarItems>
      }
    >
      <ConfigContext.Provider
        value={{showing: showingConfig, toggle: toggleConfig, form}}
      >
        <PageSettings template={template} updateTemplate={updateTemplate} />
        <MobileModeContainer>
          <Container template={template}>
            {sectionIds.map((id, index) => (
              <MoveableSection
                key={id}
                sectionId={id}
                sectionIds={sectionIds}
                canMoveUp={index !== 0}
                canMoveDown={index !== sectionIds.length - 1} // is not last
                template={template}
                updateTemplate={updateTemplate}
                availableBlocks={availableBlocks}
                ButtonConfig={ButtonConfig}
              />
            ))}
            <AddSectionButton
              template={template}
              updateTemplate={updateTemplate}
            />
            {footer}
          </Container>
        </MobileModeContainer>
      </ConfigContext.Provider>
    </Page>
  )
}

const StyledCopyIconButton = styled(CopyIconButton)`
  margin-right: 15px;
  margin-top: 3px;
`

export const ErrorText = styled(Typography)`
  text-align: center;
  background: ${(props) => props.theme.colors.error};
  color: white;
  padding: 4.5px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  left: 232px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 0px;
  }
`

const RightToolbarItems = styled.div`
  display: flex;
  align-items: center;
`
