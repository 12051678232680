import React from 'react'
import styled from 'styled-components'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {Publishable} from 'Event/Dashboard/editor/views/Published'
import {HasRules} from 'Event/attendee-rules'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Published from 'Event/Dashboard/editor/views/Published'
import {useAttendeeVariables} from 'Event'
import {Icon} from 'lib/fontawesome/Icon'
import {Ordered} from 'lib/list'
import {useResourceUrl} from 'Event/Dashboard/components/resource'
import {useSubmitResourceAction} from 'Event/ActionsProvider'
import Scheduled, {HasSchedule} from 'lib/ui/layout/Scheduled'

export type Resource = Publishable &
  HasRules &
  Ordered &
  HasSchedule & {
    name: string
    filePath: string
    icon: string | null
    actionId: string | null
    isUrl?: boolean
    url?: string
  }

export const RESOURCE_ITEM = 'Resource Item'
export type ResourceItemProps = {
  id: string
  resource: Resource
  iconColor?: string | null
  index: number
  droppleid: string
}

export default React.memo((props: ResourceItemProps) => {
  const {resource} = props

  return (
    <Container resource={resource}>
      <ResourceItemLink resource={resource} iconColor={props.iconColor} />
    </Container>
  )
})

export function ResourceItemLink(props: {
  resource: Resource
  iconColor?: string | null
}) {
  const url = useResourceUrl(props.resource)
  const v = useAttendeeVariables()
  const awardPoints = useSubmitResourceAction(props.resource)

  return (
    <ResourceLink
      aria-label="event resource"
      to={url}
      onClick={awardPoints}
      newTab
    >
      <StyledIcon iconClass={props.resource.icon} iconColor={props.iconColor} />
      <LinkText aria-label="resource link">{v(props.resource.name)}</LinkText>
    </ResourceLink>
  )
}

export const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    resource: Resource
  }
>((props, ref) => {
  return (
    <VisibleOnMatch rules={props.resource.rules}>
      <Published component={props.resource}>
        <Scheduled component={props.resource}>
          <Box ref={ref}>{props.children}</Box>
        </Scheduled>
      </Published>
    </VisibleOnMatch>
  )
})

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[2]};
  width: 100%;
`

const ResourceLink = styled(AbsoluteLink)`
  align-items: center;
  min-height: 20px;
  font-size: 20px;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[1]};
  color: inherit !important;

  &:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
`

const LinkText = styled.span`
  font-weight: bold;
`

const StyledIcon = styled(Icon)<{
  iconColor?: string | null
}>`
  margin-right: ${(props) => props.theme.spacing[3]};
  color: ${(props) => (props.iconColor ? props.iconColor : 'inherit')};
`
