import {Tooltip} from '@material-ui/core'
import VerticalAlignCenterOutlined from '@material-ui/icons/VerticalAlignCenterOutlined'
import {PurchasePageSection} from 'Event/Marketplace/purchase-page'
import {useToggleArray} from 'lib/toggle'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {ConfigComponentProps} from 'organization/Marketplace/config/types'
import React from 'react'
import styled from 'styled-components'

export default function CenterBlockButton(
  props: {
    section: PurchasePageSection
    sectionId: string
    blockId: string
    isMobileMode?: boolean
  } & ConfigComponentProps,
) {
  const {section, sectionId, blockId, updateTemplate, isMobileMode} = props

  const [
    showingConfirmResizeDialog,
    toggleConfirmResizeDialog,
  ] = useToggleArray()

  const currentBlockDesktopLayout = props.section.layouts.desktop.find(
    (layout) => layout.i === blockId,
  )

  if (!currentBlockDesktopLayout) {
    return null
  }

  if (isMobileMode) {
    return null // hide center button for mobile
  }

  const layoutsOnSameRow = section.layouts.desktop.filter((blockLayout) => {
    const block = section.blocks[blockLayout.i]
    if (!block) {
      return false
    }

    return (
      blockLayout.y === currentBlockDesktopLayout.y &&
      block.visibility !== 'mobile_only'
    )
  })

  const hasOtherBlocksOnRow = layoutsOnSameRow.length > 1

  const color = hasOtherBlocksOnRow ? 'disabled' : 'primary'

  const handleClick = () => {
    if (hasOtherBlocksOnRow) {
      return
    }

    if (currentBlockDesktopLayout.w % 2 !== 0) {
      toggleConfirmResizeDialog()
      return
    }

    centerBlock()
  }

  const centerBlock = (newWidth?: number) => {
    const desktopLayoutId = section.layouts.desktop.findIndex(
      (layout) => layout.i === blockId,
    )

    const width = newWidth ?? section.layouts.desktop[desktopLayoutId].w
    const centeredX = (section.numColumns - width) / 2

    updateTemplate({
      sections: {
        [sectionId]: {
          layouts: {
            desktop: {
              ...section.layouts.desktop,
              [desktopLayoutId]: {
                ...section.layouts.desktop[desktopLayoutId],
                x: centeredX,
                w: width,
              },
            },
          },
        },
      },
    })
  }

  const resizeAndCenter = () => {
    // Calculate the required width for a block to be evenly centered.
    // eg. a 9/12 block can't but a 10/12 block can.
    const width =
      currentBlockDesktopLayout.w < section.numColumns
        ? currentBlockDesktopLayout.w + 1
        : currentBlockDesktopLayout.w - 1
    centerBlock(width)
    toggleConfirmResizeDialog()
  }

  return (
    <CenterBlockBox aria-label="center block">
      <ConfirmDialog
        onCancel={toggleConfirmResizeDialog}
        onConfirm={resizeAndCenter}
        showing={showingConfirmResizeDialog}
        description={`In order to center this item, it needs to be resized. Would you like to continue and allow Obvio to resize the item?`}
      />
      <Tooltip
        title={
          hasOtherBlocksOnRow
            ? 'This item cannot be centered on the page as there is at least one other content block on the same row.'
            : ''
        }
        placement="bottom-start"
      >
        <VerticalAlignCenterOutlined
          aria-label="center block svg"
          onClick={handleClick}
          style={{transform: 'rotate(90deg)'}}
          color={color}
        />
      </Tooltip>
    </CenterBlockBox>
  )
}

export const CenterBlockBox = styled.div`
  border-radius: 4px;
  background: white;
  cursor: pointer;
  z-index: 4;
  &:hover {
    opacity: 0.8;
  }
`
