import React, {useState} from 'react'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import {
  ConfigurableSectionContext,
  useConfigurableSection,
} from 'organization/Marketplace/config/ConfigurableSection'
import Grid, {GridBlock} from 'Event/Marketplace/Block/Grid'
import AddBlockDialog from 'organization/Marketplace/config/AddBlockDialog'
import {DeepPartialSubstitute} from 'lib/type-utils'
import styled from 'styled-components'
import ConfigurableBlock from 'organization/Marketplace/config/ConfigurableBlock'
import {Layout, Layouts} from 'react-grid-layout'
import isEqual from 'lodash/isEqual'
import {REMOVE} from 'lib/JsonUpdateProvider'
import {ConfigComponentProps} from 'organization/Marketplace/config/types'

const dragHandleClass = 'grid-block-item'

export default function ConfigurableGrid(
  props: GridBlock & BlockComponentProps & ConfigComponentProps,
) {
  const {
    id: blockId,
    layouts,
    blocks,
    template,
    updateTemplate,
    availableBlocks,
    ButtonConfig,
  } = props
  const {sectionId, update: updateSection} = useConfigurableSection()
  const [isDragging, setIsDragging] = useState(false)

  const update = (data: DeepPartialSubstitute<GridBlock, typeof REMOVE>) => {
    // Extend the sections update because we always want to save it to this
    // nested section (Grid) block.
    updateSection({
      blocks: {
        [blockId]: data,
      },
    })
  }

  const handleLayoutChange = (newLayouts: Layouts) => {
    if (isEqual(newLayouts, layouts)) {
      return
    }

    update({layouts: newLayouts})
  }

  const toggle = props.toggleAddBlockDialog ?? (() => {})

  return (
    <ConfigurableSectionContext.Provider value={{sectionId, update}}>
      <Box>
        <AddBlockDialog
          open={props.showingAddBlockDialog ?? false}
          onClose={toggle}
          layouts={layouts}
          availableBlocks={availableBlocks.filter(
            (type) =>
              type !== 'PurchaseForm' &&
              type !== 'Grid' &&
              type !== 'TicketSelector',
          )}
        />
        <Content>
          <ButtonsOverlay />
          <Grid
            {...props}
            disableItemResize
            ResponsiveReactGridLayoutProps={{
              isDraggable: true,
              isResizable: true,
              draggableHandle: `.${dragHandleClass}`,
              onLayoutChange: (
                _currentLayout: Layout[],
                allBreakpointLayouts: Layouts,
              ) => {
                handleLayoutChange(allBreakpointLayouts)
              },
              onDragStart: () => {
                setIsDragging(true)
              },
              onDragStop: () => {
                setIsDragging(false)
              },
            }}
          >
            {Object.entries(blocks).map(([id, block]) => (
              <ConfigurableBlock
                gridId={blockId}
                block={block}
                key={id}
                id={id}
                template={template}
                disableDragToOtherSection
                draggableHandle={dragHandleClass}
                getPreviewData={(data) => ({
                  sections: {
                    [sectionId]: {
                      blocks: {
                        [blockId]: {
                          blocks: data,
                        },
                      },
                    },
                  },
                })}
                updateTemplate={updateTemplate}
                availableBlocks={availableBlocks}
                ButtonConfig={ButtonConfig}
                isEditMode
                isDragging={isDragging}
              />
            ))}
          </Grid>
        </Content>
      </Box>
    </ConfigurableSectionContext.Provider>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  flex: 1;
`
const ButtonsOverlay = styled.div`
  height: 32px;
  background-image: repeating-linear-gradient(
    145deg,
    #c3ddf3 10px,
    #c3ddf3 10px,
    transparent 12px,
    transparent 20px
  );
`
