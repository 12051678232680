import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useWaiver} from 'Event/Step2/Waivers/Waiver'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function CheckboxConfig() {
  const {
    form: {register, control},
  } = useConfig()
  const save = useSaveTemplate()
  const {waiver, id} = useWaiver()
  const {waiver: settings} = useTemplate()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const handleSave = (data: any) => {
    const agreeStatement = replaceAtPath(
      data,
      `waiver.targets.${id}.agreeStatement`,
      '{{waiver_agree_statement}}',
    )

    save({
      ...data,
      localization: addTranslation({
        waiver_agree_statement: agreeStatement ?? '',
      }),
    })
  }

  return (
    <Config title="Checkbox" onSave={handleSave}>
      <SettingsPanel>
        <Title>Agree Statement</Title>
        <TextField
          name={`waiver.targets.${id}.agreeStatement`}
          defaultValue={v(waiver.agreeStatement)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'agree statement',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="waiver.checkboxColor"
          defaultValue={settings.checkboxColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="checkbox color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
