import React from 'react'
import {useStatistics} from 'Event/Statistics'
import {UserFilledIcon} from 'lib/ui/Icon'

export default function Header(props: {iconSize?: number}) {
  const {fontSize} = useStatistics()
  const defaultFontSize = 16

  const iconSize = props.iconSize ?? defaultFontSize

  return <UserFilledIcon color="white" iconSize={iconSize + fontSize} />
}
