import {useEditMode} from 'Event/EditModeProvider'
import {useAddTranslation} from 'Event/LanguageProvider'
import {Sponsor} from 'Event/Sponsors'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'
import Button from 'lib/ui/Button'
import React from 'react'

export default function AddSponsorButton() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const isEditMode = useEditMode()
  const {sponsors} = useTemplate()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const add = () => {
    const numSponsors = Object.keys(sponsors.items).length
    const position = numSponsors + 1

    generateHashId([
      'sponsor',
      position.toString(),
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `sponsor_${id}`

      const newSponsor: Sponsor = {
        name: `{{${componentId}_name}}`,
        description: `{{${componentId}_description}}`,
        formId: null,
        buttons: {},
        image: null,
        position,
      }

      saveTemplate({
        sponsors: {
          items: {
            [componentId]: newSponsor,
          },
        },
        localization: addTranslation({
          [`${componentId}_name`]: 'Sponsor',
          [`${componentId}_description`]: '',
        }),
      })
    })
  }

  if (!isEditMode) {
    return null
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add sponsor"
      onClick={add}
    >
      Add Sponsor
    </Button>
  )
}
