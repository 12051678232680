import React from 'react'
import ComponentConfig, {
  SaveButton,
  Footer,
  ComponentConfigProps,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {MenuItem} from '@material-ui/core'
import {onUnknownChangeHandler} from 'lib/dom'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Form} from 'organization/Event/FormsProvider'
import {useTemplate} from 'Event/TemplateProvider'
import Select from 'lib/ui/Select'
import Slider from 'lib/ui/Slider'
import {Controller, useForm} from 'react-hook-form'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import FontSelect from 'lib/FontSelect'
import Show from 'lib/Show'

export default function LowerThirdConfig(
  props: ComponentConfigProps & {
    form: Form
  },
) {
  const {showing, onClose, form} = props
  const template = useTemplate()

  const formId = form.id

  const {control, watch, formState, handleSubmit} = useForm({
    defaultValues: {
      formModeration: {
        form: {
          [formId]: {
            lowerThird: {
              size:
                template?.formModeration?.form[formId]?.lowerThird.size ?? 25,
              position:
                template?.formModeration?.form[formId]?.lowerThird.position ??
                'center',
            },
          },
        },
      },
    },
  })
  const save = useSaveTemplate()

  const styles = template?.formModeration?.form[formId]?.lowerThird

  useAutoUpdate({
    values: {
      formModeration: {
        form: {
          [formId]: watch(),
        },
      },
    },
    when: showing,
  })

  const submit = (data: any) => {
    const payload = {
      formModeration: {
        form: {
          [formId]: data,
        },
      },
    }
    save(payload)
    onClose()
  }

  const formModeration = watch(`formModeration`)
  const {size, position} = formModeration.form[formId].lowerThird

  const horizontalMarginTitle =
    position === 'flex-start' ? 'Left Margin' : 'Right Margin'
  const showHorizontalMargin = position !== 'center' && size !== 100
  const maxHorizontalMargin = 100 - size

  return (
    <ComponentConfig
      title="Lower Third Config"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <StylingPanel>
        <>
          <Title>Position</Title>
          <Controller
            name={`lowerThird.size`}
            defaultValue={styles?.size ?? 25}
            control={control}
            render={({value, onChange}) => (
              <Slider
                aria-label="size"
                value={value}
                onChange={onChange}
                step={1}
                min={25}
                max={100}
                unit={'%'}
                label="Size"
              />
            )}
          />

          <Controller
            name={`lowerThird.position`}
            defaultValue={styles?.position ?? 'center'}
            control={control}
            render={({value, onChange}) => (
              <Select
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
                fullWidth
              >
                <MenuItem value="flex-start">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="flex-end">Right</MenuItem>
              </Select>
            )}
          />
          <Show when={showHorizontalMargin}>
            <Controller
              name={`lowerThird.horizontalMargin`}
              defaultValue={styles?.horizontalMargin ?? 0}
              control={control}
              render={({value, onChange}) => (
                <Slider
                  aria-label="size"
                  value={value}
                  onChange={onChange}
                  step={1}
                  min={0}
                  max={maxHorizontalMargin}
                  unit={'%'}
                  label={horizontalMarginTitle}
                />
              )}
            />
          </Show>

          <Controller
            name={`lowerThird.verticalMargin`}
            defaultValue={styles?.verticalMargin ?? 10}
            control={control}
            render={({value, onChange}) => (
              <Slider
                aria-label="size"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
                unit={'%'}
                label="Bottom Margin"
              />
            )}
          />

          <Title>Logo</Title>

          <Controller
            name={`lowerThird.logo`}
            control={control}
            defaultValue={styles?.logo}
            render={({onChange, value}) => (
              <ImageAssetUploader
                label=""
                onChange={onChange}
                value={value}
                canResize
                uploadInputProps={{
                  'aria-label': 'set logo',
                }}
              />
            )}
          />
          <Title>Background</Title>

          <Controller
            name={`lowerThird.backgroundColor`}
            defaultValue={styles?.backgroundColor ?? '#ffffff'}
            control={control}
            render={({value, onChange}) => (
              <BackgroundPicker
                label="Background"
                background={value}
                onChange={onChange}
              />
            )}
          />

          <Title>Border</Title>
          <Controller
            name={`lowerThird.borderColor`}
            defaultValue={styles?.borderColor}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
                aria-label="border color"
              />
            )}
          />
          <Controller
            name={`lowerThird.borderThickness`}
            defaultValue={styles?.borderThickness}
            control={control}
            render={({value, onChange}) => (
              <Slider
                data-testid="border-thickness-slider"
                label="Border Thickness"
                aria-label="border thickness"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={60}
              />
            )}
          />
          <Controller
            name={`lowerThird.borderRadius`}
            defaultValue={styles?.borderRadius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                data-testid="border-radius-slider"
                label="Border Radius"
                aria-label="border radius"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={60}
              />
            )}
          />

          <Title>Typography - Names</Title>
          <Controller
            control={control}
            name={`lowerThird.names.font`}
            defaultValue={styles?.names?.font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />

          <Controller
            control={control}
            name={`lowerThird.names.fontSize`}
            defaultValue={styles?.names?.fontSize ?? 35}
            render={({value, onChange}) => (
              <Slider
                valueLabelDisplay="auto"
                aria-label="names font size"
                value={value}
                onChange={onChange}
                step={1}
                min={5}
                max={50}
                label="Font Size"
              />
            )}
          />

          <Controller
            name={`lowerThird.names.fontColor`}
            defaultValue={styles?.names?.fontColor ?? '#000000'}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Text Color"
                color={value}
                onPick={onChange}
                aria-label="title text color"
              />
            )}
          />

          <Title>Typography - Answers</Title>
          <Controller
            control={control}
            name={`lowerThird.answers.font`}
            defaultValue={styles?.answers?.font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />

          <Controller
            control={control}
            name={`lowerThird.answers.fontSize`}
            defaultValue={styles?.answers?.fontSize ?? 35}
            render={({value, onChange}) => (
              <Slider
                valueLabelDisplay="auto"
                aria-label="answers font size"
                value={value}
                onChange={onChange}
                step={1}
                min={5}
                max={50}
                label="Font Size"
              />
            )}
          />

          <Controller
            name={`lowerThird.answers.fontColor`}
            defaultValue={styles?.answers?.fontColor ?? '#000000'}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Text Color"
                color={value}
                onPick={onChange}
                aria-label="title text color"
              />
            )}
          />
        </>
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
