import {useEffect} from 'react'
import {useOrganization} from 'organization/OrganizationProvider'
import {usePurchasePage} from 'lib/marketplace-api/purchase-page/use-purchase-page'
import {useEvent} from 'Event/EventProvider'
import {useCreatePurchasePage} from 'lib/marketplace-api/purchase-page/use-create-purchase-page'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'

export default function useAutoCreatePurchasePage() {
  const {event} = useEvent()
  const {organization} = useOrganization()
  const {client} = useEventMarketplaceClient()

  const {
    data: purchasePage,
    isLoading,
    isSuccess: fetchedPurchasePage,
  } = usePurchasePage({
    event,
    noCache: true,
  })

  const createPurchasePage = useCreatePurchasePage({
    organization,
    event,
  })

  useEffect(() => {
    if (
      !fetchedPurchasePage ||
      purchasePage ||
      createPurchasePage.isLoading ||
      !client
    ) {
      return
    }

    createPurchasePage.mutate({client})
  }, [fetchedPurchasePage, purchasePage, createPurchasePage, client])

  if (isLoading) {
    return {
      isLoading,
      purchasePage: undefined,
    }
  }

  return {
    isLoading,
    purchasePage,
  }
}
