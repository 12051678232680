import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/Footer/FooterConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/Footer/FooterConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Config from 'organization/Event/Configurable/Config'
import {useAddTranslation} from 'Event/LanguageProvider'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {Template} from 'Event/template'

export const DEFAULT_FOOTER_IMAGE_SIZE = 100

export function FooterConfig() {
  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const handleSave: TemplateSave<Template> = (data) => {
    const label = replaceAtPath(
      data,
      'footer.copyrightText',
      '{{footer_copyright_text}}',
    )

    save({
      ...data,
      localization: addTranslation({
        footer_copyright_text: label ?? '',
      }),
    })
  }
  return (
    <Config title="Footer" onSave={handleSave}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}
