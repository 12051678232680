import React, {useEffect, useRef, useState} from 'react'
import Markdown from 'marked-react'
import styled, {keyframes} from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {StyledFormControl} from 'organization/Obie/Blocks/ProcessForm/Form/RadioOptions'
import {EffectProps} from 'organization/Obie/Blocks/ProcessForm/TypeEffect'
import {resolveOptions} from 'organization/Obie/ObieServiceProvider'

export default function SelectableOptionsList(props: EffectProps) {
  const {isVisible, onComplete, text, updateCompletion} = props
  const [selectedOption, setSelectedOption] = useState<number>(0)
  const optionsList = useRef<string[]>()
  const optionsComponents = useRef<JSX.Element[]>()

  // This is a cheap solution, but I can't figure out another way that plays
  // nicely right now and I don't want to think about it anymore. Basically, the
  // onComplete() was getting called before the parent-parent component was done
  // rendering, when we want to deal with a SelectableList. So... a little
  // timeout to solve it. Don't like it? Have a whack at it. -Tyler
  useEffect(() => {
    if (!isVisible) {
      return
    }

    const timeout = setTimeout(() => {
      onComplete && onComplete()
    }, 750)

    return () => clearTimeout(timeout)
  }, [isVisible, onComplete])

  if (!isVisible) {
    return null
  }

  // When an option is picked, we update the selectedOption state so we can show
  // the appropriate radio button. We also update the completion text in a parent
  // component for when user clicks save.
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    const index = Number(value)

    setSelectedOption(index)

    if (!updateCompletion || !optionsList.current) {
      return
    }

    updateCompletion(optionsList.current[index] || '')
  }

  // Ref because we want the resolve list to survive re-renders. No need to do
  // THAT work many times.
  if (!optionsList.current) {
    optionsList.current = resolveOptions(text)

    optionsComponents.current = optionsList.current.map((option, index) => (
      <SlidingContainer index={index} key={index}>
        <StyledFormControlLabel
          key={index}
          color="primary"
          value={index}
          control={<Radio />}
          label={<StyledMarkdownContainer>{option}</StyledMarkdownContainer>}
        />
      </SlidingContainer>
    ))
  }

  return (
    <StyledFormControl component="fieldset">
      <RadioGroup
        aria-label="completion-options"
        name={`completion-options`}
        value={selectedOption}
        onChange={handleChange}
      >
        {optionsComponents.current}
      </RadioGroup>
    </StyledFormControl>
  )
}

const MarkdownContainer = (props: {
  children: string
  className?: string
}): JSX.Element => {
  return (
    <div className={props.className}>
      <Markdown>{props.children}</Markdown>
    </div>
  )
}

const slideIn = keyframes`
  0% {
    right: -100vw;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1
  }
`
const SlidingContainer = styled.div<{
  index: number
}>`
  right: -100vw;
  position: relative;
  animation: ${slideIn} 0.5s linear ${(props) => props.index * 0.03}s 1 forwards;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[2]};
  border-radius: ${(props) => props.theme.spacing[2]};

  &:hover {
    background: ${(props) => props.theme.colors.sidebar.backgroundLight};
  }

  & > .MuiRadio-root {
    padding-top: 0 !important;
  }
`

const StyledMarkdownContainer = styled(MarkdownContainer)`
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`
