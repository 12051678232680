import React, {useState} from 'react'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import TemplateCard from 'organization/Marketplace/config/TemplateSelector/TemplateCard'
import {EventWithUpsellPage} from 'lib/marketplace-api/upsell-page/use-upsell-page-events'
import {UpsellPage} from 'Event/Marketplace/upsell-page'

const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

interface TemplateCardProps {
  event: EventWithUpsellPage
  updateTemplate: UpdateTemplateFunction
}

export default function UpsellPageEventTemplateCard(props: TemplateCardProps) {
  const {event, updateTemplate} = props

  const withCopiedAssets = useDuplicateAssets<UpsellPage['template']>()

  const [loading, setLoading] = useState(false)

  const onSelect = () => {
    setLoading(true)

    withCopiedAssets(event.upsellPageTemplate).then((template) => {
      setLoading(false)
      updateTemplate(template)
    })
  }

  const onViewUpsellPage = () => {
    window.open(`${marketplaceStoreUrl}/${event.slug}/upsell`, '_blank')
  }

  return (
    <TemplateCard
      event={event}
      updateTemplate={updateTemplate}
      onSelect={onSelect}
      loading={loading}
      pageLabel="Upsell Page"
      onView={onViewUpsellPage}
    />
  )
}
