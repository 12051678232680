import {usePrevious} from '../state'
import DefaultSelect, {SelectProps} from './select/Select'
import DefaultOption, {OptionProps} from './select/SelectOption'
import React, {useEffect} from 'react'
import {useStates} from '../../lib/CountriesProvider'
import {onUnknownChangeHandler} from '../dom'

export default function StateSelect(
  props: SelectProps & {
    countryId: number | null
    Select?: React.FC<SelectProps>
    Option?: React.FC<OptionProps>
    defaultLabel?: string
  },
) {
  const {
    Select = DefaultSelect,
    Option = DefaultOption,
    countryId,
    defaultLabel,
    config,
    ...selectProps
  } = props

  const {value, onChange} = selectProps

  const states = useStates(countryId)

  const prevCountryId = usePrevious(countryId)
  const didChangeCountry = countryId !== prevCountryId

  // Reset State whenever the country changes
  useEffect(() => {
    if (!didChangeCountry) {
      return
    }

    if (!value) {
      return
    }

    onChange(0)
  }, [didChangeCountry, onChange, value])

  return (
    <>
      <style>
        {`
          select {
            outline: none;
            border-color: ${props.error ? '#f44336 !important' : 'inherit'};
          }

          select:disabled {
            cursor: not-allowed;
            background: #e7e7e7!important;
            color: #c4c4c4!important;
          }
        `}
      </style>
      <Select
        config={config}
        aria-label="state"
        {...selectProps}
        disabled={props.disabled}
        onChange={onUnknownChangeHandler((value: any) =>
          onChange(parseInt(value)),
        )}
      >
        <Option value={0}>{defaultLabel ?? 'State'}</Option>

        {states.map((s) => (
          <Option value={s.id} key={s.id} selected={s.id === props.value}>
            {s.name}
          </Option>
        ))}
      </Select>
    </>
  )
}
