import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Button from '@material-ui/core/Button'
import {onChangeStringHandler} from 'lib/dom'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Dialog from 'lib/ui/Dialog'
import Box from '@material-ui/core/Box'
import {
  createLanguage,
  ENGLISH,
  Language,
} from 'Event/LanguageProvider/language'
import {useForm} from 'react-hook-form'
import {useLanguage} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function AddLanguageButton(props: {
  onAdd: (language: Language['name']) => void
}) {
  const {onAdd} = props
  const [dialogVisible, setDialogVisible] = useState(false)

  const toggleDialog = () => setDialogVisible(!dialogVisible)

  return (
    <>
      <AddLanguageDialog
        open={dialogVisible}
        onClose={toggleDialog}
        onAdd={onAdd}
      />
      <Button
        onClick={toggleDialog}
        variant="outlined"
        color="primary"
        aria-label="add language"
      >
        Add Language
      </Button>
    </>
  )
}

function AddLanguageDialog(props: {
  open: boolean
  onClose: () => void
  onAdd: (language: Language['name']) => void
}) {
  const {open, onClose, onAdd} = props
  const [language, setLanguage] = useState('')
  const {handleSubmit} = useForm()
  const {languages} = useLanguage()
  const template = useTemplate()
  const saveTemplate = useSaveTemplate()
  const [error, setError] = useState<string | null>(null)

  const current = useMemo(
    () => template.localization?.languages || [createLanguage(ENGLISH)],
    [template],
  )

  useEffect(() => {
    setLanguage('')
  }, [open])

  const add = useCallback(() => {
    if (!language) {
      return
    }

    setError(null)

    const alreadyExists = languages.map((l) => l.name).includes(language)
    if (alreadyExists) {
      setError('Language already exists')
      return
    }

    const updates = {
      localization: {
        languages: [...current, createLanguage(language)],
      },
    }

    saveTemplate(updates)
    onAdd(language)
    onClose()
  }, [current, language, languages, onClose, onAdd, saveTemplate])

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Add Language</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(add)}>
          <Box mb={1}>
            <TextField
              label="Language"
              value={language}
              fullWidth
              variant="outlined"
              onChange={onChangeStringHandler(setLanguage)}
              inputProps={{
                'aria-label': 'new language',
              }}
              error={!!error}
              helperText={error}
            />
          </Box>
          <Box mb={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              aria-label="save language"
            >
              Add
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
