import React, {useCallback, useEffect, useMemo, useState} from 'react'
import Page from 'organization/Event/Page'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import Summary from 'Event/Statistics/Summary'
import Areas from 'Event/Statistics/Areas'
import {useOrganization} from 'organization/OrganizationProvider'
import {api, useQueryParams} from 'lib/url'
import {useEvent} from 'Event/EventProvider'
import {useInterval} from 'lib/interval'
import {EventBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import styled from 'styled-components'
import {useLayout} from 'organization/Event/Layout'
import CheckedIn from 'Event/Statistics/Summary/CheckedInCard'
import TotalAttendees from 'Event/Statistics/Summary/TotalAttendeesCard'
import Visible from 'lib/ui/layout/Visible'
import IconButton from 'lib/ui/IconButton'
import Icon from 'lib/ui/Icon'
import CheckInStep from 'Event/Statistics/Summary/CheckInStepCard'
import Area from 'Event/Statistics/Areas/Area'
import Rooms from 'Event/Statistics/Areas/Rooms'
import {HideLiveChatSupport} from 'lib/WithLiveChatSupport'
import Button from '@material-ui/core/Button'
import FilterDialog from 'Event/Statistics/FilterDialog'
import FontSizeHandler from 'Event/Statistics/popup/FontSizeHandler'

const AUTO_REFRESH_METRICS_INTERVAL_SEC = 30

export type AreaMetrics = {
  id: number
  name: string
  attendee_count: number
}

export type EventMetrics = {
  attendees: {
    total: number
    num_password_created: number
    num_waiver_signed: number
    num_checked_in: number
    num_tech_check_completed_at: number
  }
  areas: AreaMetrics[]
}

export const STATISTIC_CHECKED_IN = 'checked_in'
export const STATISTIC_TOTAL_ATTENDEES = 'total_attendees'
export const STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED =
  'check_in_step_password_created'
export const STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED =
  'check_in_step_waiver_signed'
export const STATISTIC_CHECK_IN_STEP_CHECKED_IN = 'check_in_step_checked_in'
export const STATISTIC_AREA = 'area'

export type StatisticType =
  | typeof STATISTIC_CHECKED_IN
  | typeof STATISTIC_TOTAL_ATTENDEES
  | typeof STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED
  | typeof STATISTIC_CHECK_IN_STEP_CHECKED_IN
  | typeof STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED
  | typeof STATISTIC_AREA

type StatisticsContextProps = {
  metrics: EventMetrics
  visibleStatisticType: StatisticType | null
  openStatisticWindow: (statisticType: StatisticType, areaId?: number) => void
  increaseFont: () => void
  decreaseFont: () => void
  fontSize: number
}

const StatisticsContext = React.createContext<
  undefined | StatisticsContextProps
>(undefined)

const WIDTH = 500
const HEIGHT = 500

export default function Statistics() {
  const [
    visibleStatisticType,
    setVisibleStatisticType,
  ] = useState<StatisticType | null>(null)

  const metrics = useMetrics()
  const queryParams = useQueryParams()
  const {setFullScreen} = useLayout()

  const [fontSize, setFontSize] = useState(0)

  const increaseFont = () => {
    setFontSize(fontSize + 2)
  }

  const decreaseFont = () => {
    setFontSize(fontSize - 2)
  }

  const openStatisticWindow = (
    statisticType: StatisticType,
    areaId?: number,
  ) => {
    const left = (window.innerWidth - WIDTH) / 2
    const top = (window.innerHeight - HEIGHT) / 2
    const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${WIDTH}, height=${HEIGHT}, top=${top}, left=${left}`
    const separator = window.location.search ? '&' : '?'
    let url = `${window.location.href}${separator}statistic_type=${statisticType}`
    if (areaId) {
      url = `${url}&area_id=${areaId}`
    }
    window.open(url, '', options)
  }

  useEffect(() => {
    const statisticType = queryParams.statistic_type
    const hasValidStatisticType =
      statisticType === STATISTIC_CHECKED_IN ||
      statisticType === STATISTIC_TOTAL_ATTENDEES ||
      statisticType === STATISTIC_CHECK_IN_STEP_CHECKED_IN ||
      statisticType === STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED ||
      statisticType === STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED ||
      statisticType === STATISTIC_AREA

    if (hasValidStatisticType) {
      setVisibleStatisticType(statisticType)
      setFullScreen(true)
    }
  }, [queryParams.statistic_type, setFullScreen])

  if (!metrics) {
    return <FullPageLoader />
  }

  return (
    <StatisticsContext.Provider
      value={{
        metrics,
        visibleStatisticType,
        openStatisticWindow,
        increaseFont,
        decreaseFont,
        fontSize,
      }}
    >
      <Content />
    </StatisticsContext.Provider>
  )
}

function Content() {
  const {visibleStatisticType} = useStatistics()
  const isWindowMode = Boolean(visibleStatisticType)

  const [showingFilterDialog, setShowingFilterDialog] = useState(false)
  const toggleFilterDialog = () => setShowingFilterDialog(!showingFilterDialog)

  if (isWindowMode) {
    return (
      <HideLiveChatSupport>
        <Box>
          <WindowModeContent />
          <FontSizeHandler />
        </Box>
      </HideLiveChatSupport>
    )
  }

  return (
    <EventBreadcrumbs page="Statistics">
      <Page>
        <PageHeader>
          <Title text="Event Statistics" />
          <AddFilterButton
            variant="outlined"
            color="primary"
            size="small"
            aria-label="filters"
            onClick={toggleFilterDialog}
          >
            Filters
          </AddFilterButton>
        </PageHeader>
        <Summary />
        <Areas />

        <FilterDialog
          isVisible={showingFilterDialog}
          onClose={toggleFilterDialog}
        />
      </Page>
    </EventBreadcrumbs>
  )
}

function WindowModeContent() {
  const {visibleStatisticType} = useStatistics()
  const {metrics} = useStatistics()
  const queryParams = useQueryParams()
  const areaId = queryParams.area_id

  const {attendees} = metrics
  const {total} = attendees

  if (visibleStatisticType === STATISTIC_CHECKED_IN) {
    return (
      <StyledCheckedIn total={total} checked_in={attendees.num_checked_in} />
    )
  }

  if (visibleStatisticType === STATISTIC_TOTAL_ATTENDEES) {
    return (
      <StyledTotalAttendees
        total={total}
        checked_in={attendees.num_checked_in}
      />
    )
  }

  if (visibleStatisticType === STATISTIC_CHECK_IN_STEP_CHECKED_IN) {
    return (
      <StyledCheckInStep
        title="Checked In"
        completed={attendees.num_checked_in}
        total={total}
        statisticType={STATISTIC_CHECK_IN_STEP_CHECKED_IN}
      />
    )
  }

  if (visibleStatisticType === STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED) {
    return (
      <StyledCheckInStep
        title="Password Created"
        completed={attendees.num_password_created}
        total={total}
        statisticType={STATISTIC_CHECK_IN_STEP_PASSWORD_CREATED}
      />
    )
  }

  if (visibleStatisticType === STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED) {
    return (
      <StyledCheckInStep
        title="Waiver Signed"
        completed={attendees.num_waiver_signed}
        total={total}
        statisticType={STATISTIC_CHECK_IN_STEP_WAIVER_SIGNED}
      />
    )
  }

  if (visibleStatisticType === STATISTIC_AREA && areaId) {
    return <AreaStatistics areaId={Number(areaId)} />
  }

  return null
}

function AreaStatistics(props: {areaId?: number}) {
  const {areaId} = props

  const [selectedAreaId, setSelectedAreaId] = useState(0)
  const {
    metrics: {areas},
  } = useStatistics()
  const area = areas.find((area) => area.id === Number(areaId))

  const handleSelectArea = (areaId: number) => {
    if (selectedAreaId === areaId) {
      setSelectedAreaId(0)
      return
    }

    setSelectedAreaId(areaId)
  }

  if (!area) {
    return null
  }

  return (
    <>
      <StyledArea
        selected={Boolean(selectedAreaId)}
        disabled={false}
        id={area.id}
        name={area.name}
        numAttendees={area.attendee_count}
        onClick={handleSelectArea}
      />
      <Rooms areaId={selectedAreaId} />
    </>
  )
}

function useMetrics() {
  const {event} = useEvent()
  const {client} = useOrganization()

  const [metrics, setMetrics] = useState<EventMetrics | null>(null)

  const urlParams = useUrlParams()

  const url = api(
    `/events/${event.id}/metrics?${
      urlParams.filters ? 'filters=' + urlParams.filters : ''
    }`,
  )

  const fetchMetrics = useCallback(() => {
    client.get<EventMetrics>(url).then(setMetrics)
  }, [client, url])

  useInterval(fetchMetrics, AUTO_REFRESH_METRICS_INTERVAL_SEC * 1000)

  useEffect(() => {
    fetchMetrics()
  }, [fetchMetrics])

  return metrics
}

export function useStatistics() {
  const context = React.useContext(StatisticsContext)
  if (context === undefined) {
    throw new Error('useStatistics must be used within Statistics')
  }

  return context
}

export function OpenStatisticWindowButton(props: {
  statisticType: StatisticType
  areaId?: number
}) {
  const {statisticType, areaId} = props

  const {openStatisticWindow, visibleStatisticType} = useStatistics()
  const isWindowMode = Boolean(visibleStatisticType)

  return (
    <Visible when={!isWindowMode}>
      <IconButton onClick={() => openStatisticWindow(statisticType, areaId)}>
        <Icon
          className="fa-solid fa-arrow-up-right-from-square"
          color="white"
          iconSize={18}
        />
      </IconButton>
    </Visible>
  )
}

const Box = styled.div`
  min-height: 100%;
`
const AddFilterButton = styled(Button)`
  min-width: 120px;
`

const StyledCheckedIn = styled(CheckedIn)`
  min-height: 100%;
  padding-top: 0;
  border-radius: unset !important;
`

const StyledTotalAttendees = styled(TotalAttendees)`
  padding-top: 0;
  height: 100%;
  border-radius: unset !important;
`

const StyledCheckInStep = styled(CheckInStep)`
  height: calc(100% - 24px); // deducts the progress bar height
  > div:first-child {
    border-radius: unset;
  }
`

const StyledArea = styled(Area)`
  padding-top: 0;
  height: 100%;
  > div:first-child {
    border-radius: unset;
  }
`

function useUrlParams() {
  const queryParams = useQueryParams()
  return useMemo(() => queryParams, [queryParams])
}
