import ConfigurableSectionLabel, {
  ConfigurableSectionLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableSectionLabel'
import React from 'react'

export default function ConfigurableCustomerDetailsLabel(
  props: ConfigurableSectionLabelProps,
) {
  const {customerDetailsLabel} = props

  return (
    <ConfigurableSectionLabel
      {...props}
      children={customerDetailsLabel ?? 'Your details'}
      inputName="customerDetailsLabel"
    />
  )
}
