import React from 'react'
import Button from 'lib/ui/Button'
import {now} from 'lib/date-time'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {useAddTranslation} from 'Event/LanguageProvider'
import {generateHashId} from 'lib/crypto/hash'

export default function AddAgendaButton(props: {className?: string}) {
  const save = usePanelsUpdate()
  const addTranslations = useAddTranslation()
  const {agendaList} = usePanelsTemplate()

  const addAgenda = () => {
    const numButtons = Object.keys(agendaList).length
    const position = numButtons + 1

    generateHashId([
      'panels',
      'agenda_list',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `agenda_item_${id}`

      save({
        localization: addTranslations({
          [`${componentId}_text`]: 'Event',
          [`${componentId}_description`]: '',
        }),
        agendaList: {
          items: {
            [id]: {
              text: `{{${componentId}_text}}`,
              description: `{{${componentId}_description}}`,
              startDate: now(),
              endDate: now(),
              link: null,
              isVisible: true,
              speakers: [],
              position,
            },
          },
        },
      })
    })
  }

  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      fullWidth
      aria-label="add agenda event"
      onClick={addAgenda}
      className={props.className}
    >
      Add Agenda Event
    </Button>
  )
}
