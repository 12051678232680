import React from 'react'
import Container from '../../Block/Container'
import {BlockComponentProps} from '../index'
import {HashMap, Ordered, orderedIdsByPosition} from '../../lib/list'
import {BlockBase} from '../base'
import {Font} from '../../lib/font'
import styles from './FaqList.module.css'
import ListItem from './ListItem'
import classNames from 'classnames'

export type FaqItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  question: string
  answer: string
  showAnswerOnLoad: boolean
  background: {
    image: string
    color: string
    opacity: number
  }
  border: {
    width: number
    color: string
    radius: number
  }
}

export interface FaqListBlock extends BlockBase {
  type: 'FaqList'
  icon: string | null
  font: Font | null
  fontSize: number
  color: string
  iconColor: string
  iconSize: number
  faqSpace: number
  textSpace: number
  items: HashMap<FaqItem>
}

export default function FaqList(props: FaqListBlock & BlockComponentProps) {
  return (
    <FaqListContainer {...props}>
      <ListItems {...props} />
    </FaqListContainer>
  )
}

export function FaqListContainer(
  props: FaqListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props

  return (
    <Container {...props}>
      <div className={classNames(styles.faqListBox, 'block-content')}>
        {children}
      </div>
    </Container>
  )
}

function ListItems(props: FaqListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id, index: number) => {
        const isLast = sortedIds.length === index + 1
        return <ListItem key={id} {...props} item={items[id]} isLast={isLast} />
      })}
    </>
  )
}
