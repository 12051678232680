import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Emoji} from 'Event/Dashboard/components/EmojiList/emoji'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/Panels/Dashboard/EmojiList/EmojiListConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/EmojiList/EmojiListConfig/Styling'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {EmojiList} from 'Event/template/Panels/Dashboard/EmojiList'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {Rule} from 'Event/attendee-rules'

export default function EmojiListConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const template = usePanelsTemplate()
  const updateTemplate = usePanelsUpdate()
  const {emojiList} = template

  const [emojiWidth, setEmojiWidth] = useState(emojiList.emojiWidth)
  const [emojis, setEmojis] = useState(emojiList.emojis)
  const [rules, setRules] = useState<Rule[]>(emojiList.rules || [])

  const {control, setValue} = useForm()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setEmojiWidth(emojiList.emojiWidth)
    setEmojis(emojiList.emojis)
  }, [isVisible, emojiList])

  const save = () => {
    const data: EmojiList = {
      emojiWidth,
      emojis,
      rules,
    }

    updateTemplate({
      emojiList: data,
    })

    onClose()
  }

  const addNewEmoji = (emoji: Emoji['name']) => {
    const added = [...emojis, emoji]
    setEmojis(added)
  }

  const updateEmoji = (index: number) => (target: Emoji['name']) => {
    const updated = emojis.map((e, i) => {
      const isTarget = i === index
      if (isTarget) {
        return target
      }

      return e
    })

    setEmojis(updated)
  }

  return (
    <ComponentConfig showing={isVisible} onClose={onClose} title="Emojis">
      <SettingsPanel>
        <Settings
          emojis={emojis}
          addNewEmoji={addNewEmoji}
          updateEmoji={updateEmoji}
          setEmojis={setEmojis}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling emojiWidth={emojiWidth} setEmojiWidth={setEmojiWidth} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={emojiList}
        />
      </RulesPanel>
      <Footer>
        <SaveButton onClick={save} />
      </Footer>
    </ComponentConfig>
  )
}
