import React from 'react'
import {Grid} from '@material-ui/core'
import Icon from 'lib/ui/Icon'
import {Draggable, Droppable} from 'react-beautiful-dnd'
import {ReactComponent as MoveLeft} from 'assets/images/icons/move-left.svg'
import {ReactComponent as MoveRight} from 'assets/images/icons/move-right.svg'
import {ReactComponent as DeleteIcon} from 'assets/images/icons/delete.svg'
import {
  FormSubmission,
  FormSubmissionStatus,
  QuestionsPreview,
} from 'organization/Event/Form/FormModeration/Question'
import styled from 'styled-components'
import {parseISO, format} from 'date-fns'
import {sortSubmissions} from 'organization/Event/Form/FormModeration/QATab/sort-submissions'

interface SubmissionsColumnProps {
  submissions: FormSubmission[]
  status: FormSubmissionStatus
  canDrop?: boolean
  onMoveLeft?: (index: number) => void
  onMoveRight?: (index: number) => void
  onDelete: (index: number) => void
  onAnswer: (index: number) => void
}

export default function SubmissionsColumn(props: SubmissionsColumnProps) {
  const {
    submissions,
    status,
    canDrop = false,
    onMoveLeft,
    onMoveRight,
    onDelete,
    onAnswer,
  } = props

  return (
    <Droppable droppableId={status} key={status} isDropDisabled={!canDrop}>
      {(provided) => {
        return (
          <Items
            item
            xs={4}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {submissions.sort(sortSubmissions).map((item, index) => {
              return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => {
                    return (
                      <Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}
                      >
                        <ItemHead>
                          <ItemHeadLeft>
                            <ItemID>#{item.number}</ItemID>
                            <ItemDate>
                              {format(
                                parseISO(item.saved_at),
                                'MM-dd-yyy HH:mm',
                              )}
                            </ItemDate>
                          </ItemHeadLeft>
                          <ItemHeadRight>
                            {
                              <>
                                {onMoveLeft && (
                                  <MoveLeft
                                    aria-label="move left"
                                    style={{
                                      marginRight: '5px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => onMoveLeft(index)}
                                  />
                                )}
                                {onMoveRight && (
                                  <MoveRight
                                    aria-label="move right"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => onMoveRight(index)}
                                  />
                                )}
                              </>
                            }
                          </ItemHeadRight>
                        </ItemHead>
                        <ItemTitle>
                          {item.attendee_first_name} {item.attendee_last_name}
                        </ItemTitle>

                        <QuestionsPreview questions={item.answers} />

                        <ItemFooter>
                          <ItemFooterLeft></ItemFooterLeft>
                          <ItemFooterRight>
                            <DeleteIcon
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                onDelete(index)
                              }}
                            />

                            <MoveToAnswered
                              className={'fa-solid fa-circle-check'}
                              onClick={() => {
                                onAnswer(index)
                              }}
                            />
                          </ItemFooterRight>
                        </ItemFooter>
                      </Item>
                    )
                  }}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </Items>
        )
      }}
    </Droppable>
  )
}

const MoveToAnswered = styled(Icon)`
  margin-left: ${(props) => props.theme.spacing[4]};
  cursor: pointer;
`

const Items = styled(Grid)`
  padding: ${(props) => props.theme.spacing[3]};
`

const Item = styled(Grid)`
  padding: ${(props) => props.theme.spacing[2]};
  border: 2px solid #f5f5f5;
  border-radius: 3px;
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemHeadLeft = styled.div`
  display: flex;
  align-items: center;
`

const ItemHeadRight = styled.div`
  display: flex;
  align-items: center;
`

const ItemID = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #00000040;
`

const ItemDate = styled.div`
  margin-left: ${(props) => props.theme.spacing[4]};
  display: inline-block;
  color: #000000b5;
`

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing[8]};
`

const ItemFooterLeft = styled.div`
  display: flex;
  align-items: center;
`

const ItemFooterRight = styled.div`
  display: flex;
  align-items: center;
`
