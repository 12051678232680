import styled from 'styled-components'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useSection} from 'organization/Event/Layout/Sidebar/Section'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useLayout} from 'organization/Event/Layout'

export default function PageLink(props: {
  route: string
  children: string
  isFirst?: boolean
  isMenuLink?: boolean
  isHidden?: boolean
  hasSubPage?: boolean
}) {
  const {route, children, isFirst, isHidden, isMenuLink, hasSubPage} = props

  const {setExpandSection} = useSection()
  const {showingMobileSidebar, toggleMobileSidebar} = useLayout()
  const {pathname} = useLocation()

  const isShowing = hasSubPage ? pathname.includes(route) : pathname === route

  // Handle section 'expand'
  useEffect(() => {
    // We'll only collapse the section for the first item, this way it's
    // always sequential, and prevents subsequent links from hiding
    // the section incorrectly.
    if (isFirst) {
      setExpandSection(isShowing)
      return
    }

    if (isShowing) {
      setExpandSection(true)
    }
  }, [isShowing, setExpandSection, isFirst, pathname])

  // Between feature toggles and permissions, it's been getting tricky to allow
  // correct visibility of links while maintaining proper styling. Allowing a
  // prop to dictate what is visible and what is not. Permission is still resolved
  // outside...
  if (isHidden) {
    return null
  }

  const Component = isMenuLink ? MenuLink : SidebarLink

  // Hide mobile sidebar on nav. If a user has clicked on a link, they probably
  // don't need to see the sidebar anymore, so we'll hide it for them.
  const hideMobileSidebar = () => {
    if (!showingMobileSidebar) {
      return
    }

    toggleMobileSidebar()
  }

  return (
    <Component
      to={route}
      disableStyles
      selected={isShowing}
      onClick={hideMobileSidebar}
    >
      {children}
    </Component>
  )
}

const SidebarLink = styled(RelativeLink)<{
  selected: boolean
}>`
  display: block;
  color: #ffffff;
  padding-top: ${(props) => props.theme.spacing[2]};
  padding-bottom: ${(props) => props.theme.spacing[2]};
  padding-left: ${(props) => props.theme.spacing[16]};
  border-radius: 3px;
  margin: ${(props) => props.theme.spacing[2]} 0;

  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary : 'transparent'};

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }
`

// Link when shown in the popover menu on a section
// hover
const MenuLink = styled(RelativeLink)<{
  selected: boolean
}>`
  display: block;
  color: ${(props) => (props.selected ? '#ffffff' : '#000000')};
  padding: ${(props) => props.theme.spacing[2]};
  border-radius: 3px;
  font-size: 14px;
  line-height: 14px;

  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary : 'transparent'};

  &:hover {
    ${(props) =>
      props.selected
        ? ''
        : `background-color: ${props.theme.colors.menu.itemHoverBackground};`}
  }
`
