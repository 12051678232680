import {ButtonBlock} from '.'

export const createButtonBlock = (): ButtonBlock => ({
  type: 'Button',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  backgroundColor: '#3490DC',
  minSize: {
    width: 50,
    height: 50,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  text: 'My Button',
  textColor: '#FFFFFF',
  fontSize: 20,
  textSecond: null,
  isVisible: true,
  link: '',
  destination: 'link',
})
