import {Resource} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import FormControl from '@material-ui/core/FormControl'
import ResourceUpload from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import IconSelect from 'lib/fontawesome/IconSelect'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  resource: Resource
  isUrl?: boolean
  setIsUrl: (isUrl: boolean) => void
  filePath: string
  setFilePath: (filePath: string) => void
}) {
  const {
    control,
    register,
    resource,
    isUrl,
    setIsUrl,
    filePath,
    setFilePath,
  } = props

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={resource.isVisible || false}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Name</Label>
      <TextField
        name="name"
        defaultValue={v(resource.name || '')}
        aria-label="resource name"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <FormControl>
        <ToggleButtonGroup value={isUrl ? 'true' : 'false'} exclusive>
          <ToggleButton value="false" onClick={() => setIsUrl(false)}>
            File
          </ToggleButton>
          <ToggleButton
            value="true"
            aria-label="set url resource"
            onClick={() => setIsUrl(true)}
          >
            Link
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <ResourceUpload
        isUrl={isUrl}
        filePath={filePath}
        onChange={setFilePath}
      />
      <UrlField
        defaultValue={v(resource.url || '')}
        register={register}
        isUrl={isUrl}
      />
      <Controller
        name="actionId"
        defaultValue={resource.actionId || null}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect noneLabel="default" value={value} onChange={onChange} />
        )}
      />
      <Controller
        control={control}
        name="icon"
        defaultValue={resource.icon || ''}
        render={({value, onChange}) => (
          <IconSelect value={value || null} onChange={onChange} />
        )}
      />
    </>
  )
}

function UrlField(props: {
  isUrl?: boolean
  register: UseFormMethods['register']
  defaultValue: string
}) {
  if (!props.isUrl) {
    return null
  }

  return (
    <>
      <Label>URL starting with http:// or https://</Label>
      <TextField
        name="url"
        defaultValue={props.defaultValue}
        aria-label="resource external file url"
        fullWidth
        inputProps={{
          ref: props.register,
        }}
      />
    </>
  )
}
