import styled from 'styled-components'
import React from 'react'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import Button from 'lib/ui/Button'

export const DEFAULT_BUTTON_WIDTH_PERCENT = 100

export default function AddButton(props: {
  className?: string
  onAdd: (button: NavButtonProps) => void
}) {
  const add = () => {
    const button: NavButtonProps = {
      text: 'Button',
      link: '',
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      isCertificateButton: false,
      formId: null,
      areaId: null,
      actionId: null,
      certificateId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
      zapierTag: null,
      highLevelTag: null,
      webhook: null,
      width: DEFAULT_BUTTON_WIDTH_PERCENT,
      fontSize: 16,
      padding: 8,
      height: 32,
    }

    props.onAdd(button)
  }

  return (
    <StyledButton
      color="primary"
      aria-label="add new button"
      onClick={add}
      variant="outlined"
      size="small"
    >
      Add Button
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[2]} 0 !important;
`
