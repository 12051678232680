import React, {useCallback} from 'react'
import JsonUpdateProvider, {JsonSave} from 'lib/JsonUpdateProvider'
import {useUpdatePurchasePageTemplate} from 'lib/marketplace-api/purchase-page/use-update-purchase-page-template'
import {Client} from 'lib/ui/api-client'
import {useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'
import {
  PurchasePage,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'

const TemplateUpdateContext = React.createContext<
  JsonSave<PurchasePage['template']> | undefined
>(undefined)

interface PurchasePageTemplateUpdateProviderProps {
  children: (template: PurchasePageTemplate) => JSX.Element
  purchasePage: PurchasePage
  client: Client
}

export default function PurchasePageTemplateUpdateProvider(
  props: PurchasePageTemplateUpdateProviderProps,
) {
  const {children, purchasePage, client} = props

  const updateTemplate = useUpdatePurchasePageTemplate({
    client,
  })
  const {event} = useEvent()

  const qc = useQueryClient()

  const updateCachedTemplate = useCallback(
    (template: PurchasePageTemplate) => {
      qc.setQueryData<PurchasePage | undefined>(
        ['events', event.id, 'purchase_page'],
        (purchasePage) => {
          if (!purchasePage) {
            return
          }

          return {
            ...purchasePage,
            template,
          }
        },
      )
    },
    [qc, event],
  )

  const template = Array.isArray(purchasePage.template.sections)
    ? {
        ...purchasePage.template,
        sections: {},
      }
    : purchasePage.template

  return (
    <JsonUpdateProvider
      Context={TemplateUpdateContext}
      updatedSocketEventName={`.purchase_page.${purchasePage.id}.updated`}
      value={template}
      save={(updates) => updateTemplate.mutateAsync({purchasePage, updates})}
      onUpdate={updateCachedTemplate}
    >
      {(template) => children(template)}
    </JsonUpdateProvider>
  )
}

export function useUpdateTemplate() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateTemplate must be used within PurchasePageTemplateUpdateProvider.',
    )
  }

  return context
}
