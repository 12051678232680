import InputAdornment from '@material-ui/core/InputAdornment'
import {FieldProps, useSavedValue, FormTextField} from 'Event/Question'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {Question, QuestionBase} from 'organization/Event/QuestionsProvider'
import styled from 'styled-components'
import {Controller} from 'react-hook-form'

export const CURRENCY = 'currency'

export type CurrencyQuestion = QuestionBase & {
  type: typeof CURRENCY
  start_adornment: string | null
}

type CurrencyProps = FieldProps & {
  question: CurrencyQuestion
}

export default function Currency(props: CurrencyProps) {
  useSavedValue(props)

  const v = useAttendeeVariables()
  const label = `${v(props.question.label)} ${
    props.question.is_required ? '*' : ''
  }`
  const defaultValue = v(props.answer || '')

  return (
    <>
      <Controller
        name={props.name}
        defaultValue={defaultValue}
        control={props.control}
        rules={{
          required:
            props.question.is_required &&
            (props.requiredFieldMessage ?? 'This field is required'),
        }}
        render={({value, onChange}) => (
          <FormTextField
            label={label}
            inputProps={{
              'aria-label': props.question.label,
            }}
            value={value}
            onChange={onChange}
            fullWidth
            error={Boolean(props.error)}
            disabled={props.disabled}
            styles={props.inputStyles}
            startAdornment={
              <StyledInputAdornment position="start">
                {props.question.start_adornment || ''}
              </StyledInputAdornment>
            }
          />
        )}
      />

      {props.HelperText}
    </>
  )
}

export function isCurrencyQuestion(
  question?: Question,
): question is CurrencyQuestion {
  if (!question) {
    return false
  }

  return question.type === CURRENCY
}

const StyledInputAdornment = styled(InputAdornment)`
  & > * {
    font-family: inherit;
  }
`
