import React from 'react'
import styled from 'styled-components'
import {HeroConfig} from 'Event/template/Townhall/Dashboard/Hero/HeroConfig'
import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import TextContent from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'

export default function Hero() {
  return (
    <Configurable aria-label="edit hero">
      <HeroConfig />
      <>
        <Image />
        <Body />
      </>
    </Configurable>
  )
}

function Image() {
  const template = useTownhallTemplate()
  const {hero} = template

  if (!hero.image) {
    return null
  }

  return (
    <ImageBox align={hero.alignment}>
      <ImageSizer size={hero.imageSize}>
        <StyledImg src={hero.image} />
      </ImageSizer>
    </ImageBox>
  )
}

const ImageBox = styled.div<{align: 'left' | 'center' | 'right'}>`
  padding: ${(props) => props.theme.spacing[5]} 0;
  display: flex;
  justify-content: ${(props) =>
    props.align === 'left'
      ? 'flex-start'
      : props.align === 'right'
      ? 'flex-end'
      : 'center'};
`

const ImageSizer = styled.div<{size: number}>`
  width: ${(props) => props.size}%;
`
const StyledImg = styled.img`
  width: 100%;
`

function Body() {
  const v = useAttendeeVariables()
  const {
    hero: {body: description},
  } = useTownhallTemplate()

  return <TextContent>{v(description)}</TextContent>
}
