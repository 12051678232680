import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import BlockListItem from 'organization/Obie/Blocks/BlockListItem'
import {useObieService, Block} from 'organization/Obie/ObieServiceProvider'

export default function BlockList() {
  const {category: category_id, profile: profile_id} = useParams<{
    category: string
    profile: string
  }>()
  const {
    blocks,
    categories,
    categoryId,
    fetchBlocks,
    fetchCompletions,
    loadingBlocks,
    profileId,
    setCategoryId,
    setProfileId,
  } = useObieService()

  const category = categories.find(
    (category) => category.id === parseInt(category_id),
  )

  useEffect(() => {
    if (!category_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setCategoryId(parseInt(category_id))
  }, [setCategoryId, category_id])

  useEffect(() => {
    if (!profile_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setProfileId(parseInt(profile_id))
  }, [setProfileId, profile_id])

  useEffect(() => {
    if (!categoryId || profileId === undefined) {
      return
    }

    fetchBlocks(categoryId, profileId)
    fetchCompletions()
  }, [categoryId, profileId, fetchBlocks, fetchCompletions])

  if (!category) {
    return null
  }

  if (loadingBlocks) {
    return <FullPageLoader />
  }

  const profile = (category.profiles || []).find(
    (profile) => profile.id === profileId,
  )

  return (
    <Container>
      <TitleBox>
        <Title>
          {profile?.name || 'Default Profile'}: {category.name}
        </Title>
      </TitleBox>
      {blocks.map((block: Block, index: number) => (
        <BlockListItem key={index} block={block} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 10px;
`

const TitleBox = styled.div`
  margin: 60px 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 20px 0;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
`
