import React from 'react'
import TextField from 'Event/auth/Login/TextField'
import {onChangeStringHandler} from 'lib/dom'
import PhoneNumberField, {
  PhoneNumberFieldProps,
} from 'lib/ui/TextField/PhoneNumberField'
import {UseFormMethods} from 'react-hook-form'
import {useGuestVariables} from 'Event'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import PhoneNumberFieldConfig from 'Event/auth/SignUp/PhoneNumberField/PhoneNumberFieldConfig'
import {useQueryParams} from 'lib/url'
import {Controller} from 'react-hook-form'

export default function SignUpPhoneNumberField(props: {
  submitting: boolean
  errors: Record<string, any>
  register: UseFormMethods['register']
  control: UseFormMethods['control']
}) {
  const {signUp} = useTemplate()
  const v = useGuestVariables()
  const phoneNumberLabel = v(signUp.phoneNumberLabel)
  const {phone_number} = useQueryParams()

  return (
    <Configurable>
      <PhoneNumberFieldConfig />
      <Controller
        name="phone_number"
        defaultValue={phone_number}
        control={props.control}
        rules={{
          required: signUp.phoneNumberRequired
            ? `${phoneNumberLabel} is required`
            : false,
        }}
        render={({onChange, value}) => (
          <PhoneNumberField
            inputComponent={TextFieldWithRef}
            onChange={onChange}
            value={value}
            error={Boolean(props.errors.phone_number)}
            helperText={props.errors.phone_number}
            disabled={props.submitting}
            label={phoneNumberLabel}
            fullWidth
            variant="outlined"
            aria-label="phone number"
          />
        )}
      />
    </Configurable>
  )
}

const TextFieldWithRef = React.forwardRef<
  HTMLInputElement,
  PhoneNumberFieldProps
>((props, ref) => {
  const {inputProps, onChange, ...otherProps} = props

  return (
    <TextField
      inputProps={{
        ...inputProps,
        ref,
      }}
      {...otherProps}
      onChange={onChange ? onChangeStringHandler(onChange) : undefined}
    />
  )
})
