import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {useAutoUpdateSidebarItem} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceListConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceListConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function ResourceListConfig(
  props: ComponentConfigProps & {
    list: ResourceListProps
    id: string
  },
) {
  const {showing, onClose, list, id} = props
  const {handleSubmit, control, watch, formState, setValue} = useForm()
  const [rules, setRules] = useState<Rule[]>(list.rules || [])
  const addTranslation = useAddTranslation()
  const saveTemplate = useSaveTemplate()

  const save = (data: Pick<ResourceListProps, 'title' | 'description'>) => {
    const title = replaceAtPath(data, 'title', `{{${id}_title}}`)
    const description = replaceAtPath(
      data,
      'description',
      `{{${id}_description}}`,
    )
    saveTemplate({
      sidebarItems: {
        [id]: {...data, rules},
      },
      localization: addTranslation({
        [`${id}_title`]: title ?? '',
        [`${id}_description`]: description ?? '',
      }),
    })
    onClose()
  }

  useAutoUpdateSidebarItem({item: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Resources"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} list={list} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} list={list} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={list} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
