import {ThankYouPage} from 'Event/Marketplace/thank-you-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation} from 'react-query'

export function useUpdateThankYouPageTemplate(params: {client: Client}) {
  const {client} = params

  return useMutation({
    mutationFn: (data: {
      thankYouPage: ThankYouPage
      updates: Record<string, any>
    }) => {
      const {thankYouPage: purchasePage, updates} = data

      const url = marketplaceApi(`/thank_you_pages/${purchasePage.id}/template`)
      return client.put<ThankYouPage>(url, {
        template: updates,
      })
    },
  })
}
