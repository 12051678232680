import React from 'react'
import styled from 'styled-components'
import Published from 'Event/Dashboard/editor/views/Published'
import {useEditMode} from 'Event/EditModeProvider'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {Draggable} from 'react-beautiful-dnd'
import {AgendaItemConfig} from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItemConfig'
import Configurable from 'organization/Event/Configurable'
import Grid from 'lib/ui/Grid'
import {Speaker} from 'Event/Speakers'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {HashMap} from 'lib/list'
import AgendaEvent from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItem/AgendaEvent'
import EventTimes from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItem/EventTimes'
import SpeakerNames from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItem/SpeakerNames'
import SpeakerImages from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItem/SpeakerImageList'
import {useAttendeeVariables} from 'Event'

export default function AgendaItem(props: {
  agenda: Agenda
  id: string
  index: number
  sectionId: string
  borderColor: string
  color?: string | null
}) {
  const {agenda, id, index, sectionId, borderColor, color} = props
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return (
      <Published component={agenda}>
        <AgendaItemContainer aria-label="agenda" borderColor={borderColor}>
          <AgendaEvent agenda={agenda} color={color} />
          <Content agenda={agenda} />
        </AgendaItemContainer>
      </Published>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Configurable
          aria-label="agenda item config"
          editableProps={{handleProps: provided.dragHandleProps}}
        >
          <AgendaItemConfig agenda={agenda} id={id} sectionId={sectionId} />

          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Published component={agenda}>
              <AgendaItemContainer
                aria-label="agenda"
                borderColor={borderColor}
              >
                <AgendaEvent agenda={agenda} color={color} />
                <Content agenda={agenda} />
              </AgendaItemContainer>
            </Published>
          </div>
        </Configurable>
      )}
    </Draggable>
  )
}

function Content(props: {agenda: Agenda}) {
  const {agenda} = props

  const {speakers, featuredSpeakers} = useTownhallTemplate()

  const v = useAttendeeVariables()
  const allSpeakers: HashMap<Speaker> = {
    ...featuredSpeakers.items,
    ...speakers.items,
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        <EventTimes agenda={agenda} />
      </Grid>
      <Grid item xs={12} md={3}>
        <DescriptionText>{v(agenda.description || '')}</DescriptionText>
      </Grid>
      <Grid item xs={12} md={2}>
        <SpeakerNames speakers={allSpeakers} agenda={agenda} />
      </Grid>
      <Grid item xs={12} md={2}>
        <SpeakerImages speakers={allSpeakers} agenda={agenda} />
      </Grid>
    </Grid>
  )
}

const AgendaItemContainer = styled.div<{borderColor: string}>`
  padding: ${(props) => `${props.theme.spacing[4]} ${props.theme.spacing[5]}`};
  margin-bottom: ${(props) => props.theme.spacing[3]};
  border-bottom: 1px solid ${(props) => props.borderColor};
  margin-bottom: 0;
`

const DescriptionText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.16px;
`
