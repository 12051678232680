import React from 'react'
import styled from 'styled-components'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {useAttendeeVariables} from 'Event'
import {Agenda} from 'Event/template/Panels/Dashboard/AgendaList'
import {useAgendaUrl} from 'Event/Dashboard/components/agneda'

export default function AgendaEvent<T extends Agenda>(props: {
  agenda: T
  color?: string | null
}) {
  const v = useAttendeeVariables()
  const link = useAgendaUrl(props.agenda)

  if (link) {
    return (
      <StyledAbsoluteLink newTab to={link} textColor={props.color}>
        <EventText aria-label="agenda event">{v(props.agenda.text)}</EventText>
      </StyledAbsoluteLink>
    )
  }

  return <EventText aria-label="agenda event">{v(props.agenda.text)}</EventText>
}

const EventText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: 0.2px;
  display: block;
  margin-bottom: 6px;
`

const StyledAbsoluteLink = styled(AbsoluteLink)<{textColor?: string | null}>`
  color: ${(props) => props.textColor} !important;
  &:hover {
    text-decoration: none !important;
  }
`
