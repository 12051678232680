import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';
import CustomButtonTextFormView from './custombuttontextformview';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import { repositionContextualBalloon, getBalloonPositionData, getSelectedCustomButtonWidget } from '../utils';
import TextIcon from '../theme/text-icon.svg';
import { Editor } from '@ckeditor/ckeditor5-core';
import { ViewDocument } from '@ckeditor/ckeditor5-engine';
import CustomButtonTextCommand from './custombuttontextcommand';
import { LabeledFieldView } from '@ckeditor/ckeditor5-ui';

// Custom type for LabeledInputTextView
type LabeledInputTextView = LabeledFieldView & {
    fieldView: {
        value: string;
        element: HTMLInputElement;
        select(): void;
    };
}

export default class CustomButtonTextUI extends Plugin {
	static get requires() {
		return [ContextualBalloon];
	}

	static get pluginName() {
		return 'CustomButtonTextUi';
	}

	private _balloon!: ContextualBalloon;
	private _form!: CustomButtonTextFormView;

	init() {
		this._createButton();
		this._createForm();
	}

	override destroy() {
		super.destroy();
		this._form.destroy();
	}

	private _createButton() {
		const editor = this.editor as Editor;
		const t = editor.t;

		editor.ui.componentFactory.add('customButtonText', locale => {
			const view = new ButtonView(locale);

			view.set({
				label: t('Button Text'),
				icon: TextIcon,
				tooltip: true
			});

			this.listenTo(view, 'execute', () => {
				this._showForm();
			});

			return view;
		});
	}

	private _createForm() {
		const editor = this.editor as Editor;
		const view = editor.editing.view;
		const viewDocument = view.document as ViewDocument;

		this._balloon = editor.plugins.get('ContextualBalloon') as ContextualBalloon;

		this._form = new CustomButtonTextFormView(editor.locale);

		this._form.render();

		this.listenTo(this._form, 'submit', () => {
			editor.execute('updateCustomButton', {
				newValue: (this._form.labeledInput.fieldView.element as HTMLInputElement)!.value
			});

			this._hideForm(true);
		});

		this.listenTo(this._form, 'cancel', () => {
			this._hideForm(true);
		});

		this._form.keystrokes.set('Esc', (data, cancel) => {
			this._hideForm(true);
			cancel();
		});

		this.listenTo(editor.ui, 'update', () => {
			if (!getSelectedCustomButtonWidget(viewDocument.selection)) {
				this._hideForm(true);
			} else if (this._isVisible) {
				repositionContextualBalloon(editor);
			}
		});

		clickOutsideHandler({
			emitter: this._form,
			activator: () => this._isVisible,
			contextElements: [this._balloon.view.element!],
			callback: () => this._hideForm()
		});
	}

	private _showForm() {
		if (this._isVisible) {
			return;
		}

		const editor = this.editor as Editor;
		const command = editor.commands.get('customButtonText') as CustomButtonTextCommand;
		const labeledInput = this._form.labeledInput;

		if (!this._isInBalloon) {
			this._balloon.add({
				view: this._form,
				position: getBalloonPositionData(editor)
			});
		}
		const fieldView: LabeledInputTextView['fieldView'] = labeledInput.fieldView as LabeledInputTextView['fieldView'];

        fieldView.value = fieldView.element!.value = (command.value as string) || 'https://';
		fieldView.select()
	}

	private _hideForm(focusEditable?: boolean) {
		if (!this._isInBalloon) {
			return;
		}

		if (this._form.focusTracker.isFocused) {
			this._form.saveButtonView.focus();
		}

		this._balloon.remove(this._form);

		if (focusEditable) {
			this.editor.editing.view.focus();
		}
	}

	private get _isVisible() {
		return this._balloon.visibleView === this._form;
	}

	private get _isInBalloon() {
		return this._balloon.hasView(this._form);
	}
}
