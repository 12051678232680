import React from 'react'
import {VideoBlock} from '.'
import styles from './Video.module.css'

const VideoElement = React.forwardRef<
  HTMLDivElement,
  VideoBlock & {
    width: number
    height: number
    htmlCode?: string
  }
>((props, ref) => {
  const {htmlCode} = props

  return (
    <div
      className={styles.video}
      style={{
        width: props.width,
        height: props.height,
        outline: `${props.borderWidth || 0}px solid
        ${props.borderColor}`,
        borderRadius: props.borderRadius,
        margin: `${props.borderWidth || 0}px`,
        overflow: 'hidden',
      }}
      dangerouslySetInnerHTML={htmlCode ? {__html: htmlCode} : undefined}
      aria-label="video-container"
      ref={ref}
    />
  )
})
export default VideoElement
