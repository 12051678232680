import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'
import Button from 'lib/ui/Button'
import React from 'react'

export default function NewCustomButton() {
  const save = useSaveTemplate()
  const {
    techCheck: {customButtons},
  } = useTemplate()
  const addTranslation = useAddTranslation()

  const numButtons = Object.keys(customButtons).length

  const addButton = () => {
    generateHashId([
      'tech_check',
      'custom_button',
      String(numButtons + 1),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `techcheck_button_${id}`

      const button: NavButtonWithSize = {
        position: numButtons + 1,
        text: 'Button',
        link: '',
        size: 12,
        rules: [],
        isAreaButton: false,
        isImageUpload: false,
        isFormButton: false,
        isCertificateButton: false,
        areaId: null,
        formId: null,
        certificateId: null,
        newTab: true,
        actionId: null,
        isVisible: true,
        infusionsoftTag: null,
        mailchimpTag: null,
        zapierTag: null,
        highLevelTag: null,
        webhook: null,
        hubspotTag: null,
        activeCampaignTag: null,
        convertKitTag: null,
        ontraportTag: null,
      }

      save({
        localization: addTranslation({
          [`${componentId}_text`]: button.text,
        }),
        techCheck: {
          customButtons: {
            [id]: {
              ...button,
              text: `{{${componentId}_text}}`,
            },
          },
        },
      })
    })
  }
  return (
    <Button
      variant="outlined"
      color="primary"
      aria-label="add tech check button"
      onClick={addButton}
      fullWidth
    >
      New Button
    </Button>
  )
}
