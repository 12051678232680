import React from 'react'
import Container from '../../Block/Container'
import {HashMap, Ordered, orderedIdsByPosition} from '../../lib/list'
import {BlockBase} from '../base'
import {BlockComponentProps} from '../index'
import CKEditorContent from '../../lib/ui/CKEditorContent'
import styles from './BulletedList.module.css'
import {getFontStyles, Font} from '../../lib/font'
import classNames from 'classnames'

export type BulletItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  text: string
}

export interface BulletedListBlock extends BlockBase {
  type: 'BulletedList'
  icon: string | null
  font: Font | null
  fontSize: number
  color: string
  iconColor: string
  iconSize: number
  bulletSpace: number
  textSpace: number
  items: HashMap<BulletItem>
}

export default function BulletedList(
  props: BulletedListBlock & BlockComponentProps,
) {
  return (
    <BulletedListContainer {...props}>
      <ListItems {...props} />
    </BulletedListContainer>
  )
}

export function BulletedListContainer(
  props: BulletedListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props

  return (
    <Container {...props}>
      <div className={classNames(styles.bulletedListBox, 'block-content')}>
        {children}
      </div>
    </Container>
  )
}

function ListItems(props: BulletedListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id, index) => {
        const isLast = sortedIds.length === index + 1
        return <ListItem key={id} {...props} item={items[id]} isLast={isLast} />
      })}
    </>
  )
}

export function ListItem(
  props: BulletedListBlock & {
    item: BulletItem
    isLast: boolean
  },
) {
  const {item, icon} = props

  return (
    <div
      aria-label="bullet list"
      className={styles.listItemBox}
      style={{
        marginTop: props.bulletSpace + 'px',
        fontSize: props.fontSize + 'px',
        color: props.color,
        marginBottom: props.isLast ? props.bulletSpace + 'px' : 'inherit',
        ...getFontStyles(props.font),
      }}
    >
      <i
        className={icon ?? ''}
        style={{
          fontSize: props.iconSize + 'px',
          width: props.iconSize + 'px',
          height: props.iconSize + 'px',
          marginRight: props.textSpace + 'px',
          color: props.iconColor,
        }}
      />

      <CKEditorContent
        className={styles.editorContent}
        style={{
          marginTop:
            (props.iconSize / 2 - (props.fontSize * 1.4) / 2).toString() + 'px',
        }}
      >
        {item.text}
      </CKEditorContent>
    </div>
  )
}

export const createBulletItem = (): BulletItem => ({
  text: '<p>New Bullet</p>',
  visibility: 'all',
  position: 1,
})
