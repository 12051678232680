import {Box} from 'lib/ui/form/BackgroundPicker'
import styled from 'styled-components'

/**
 * Main selected tab panel
 */
export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => `${props.theme.spacing[6]} ${props.theme.spacing[5]}`};

  // Make any BackgroundPicker tabs override the padding so the tabs go all
  // the way to the edges.
  ${Box} {
    margin-left: -${(props) => props.theme.spacing[5]};
    margin-right: -${(props) => props.theme.spacing[5]};
  }

  > div:last-child {
    margin-bottom: 0;
  }

  .select-visibility {
    margin-top: auto;
  }
`
