import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate} from 'Event/template/Townhall'
import {ResourceSectionProps} from 'Event/template/Townhall/Dashboard/Main/ResourceSection'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch, {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import InputLabel from 'lib/ui/TextField/InputLabel'
import {useConfig} from 'organization/Event/Configurable'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function Settings(props: {
  section: ResourceSectionProps
  id: string
}) {
  const {navBar} = useTownhallTemplate()
  const {section, id} = props
  const {
    form: {control, register},
  } = useConfig()

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name={`dashboardSections.${id}.isEnabled`}
        defaultValue={section.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Controller
        name={`navBar.menuItems.${id}.isEnabled`}
        defaultValue={navBar.menuItems[id]?.isEnabled ?? true}
        control={control}
        render={({value, onChange}) => (
          <Switch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            labelPlacement="end"
            label={value ? 'Show NavBar Item' : 'Hide NavBar Item'}
            aria-label="toggle hide navbar item"
          />
        )}
      />
      <InputLabel>Menu Title</InputLabel>
      <TextField
        name={`navBar.menuItems.${id}.title`}
        defaultValue={v(navBar.menuItems[id]?.title ?? section.title)}
        aria-label="update resources menu title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <InputLabel>Title</InputLabel>
      <TextField
        name={`dashboardSections.${id}.title`}
        defaultValue={v(section.title)}
        aria-label="update resources title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <InputLabel>Description</InputLabel>
      <TextField
        name={`dashboardSections.${id}.description`}
        defaultValue={v(section.description)}
        aria-label="update resources description"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}
