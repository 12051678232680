import React from 'react'
import styled from 'styled-components'
import {OpenStatisticWindowButton, STATISTIC_AREA} from 'Event/Statistics'
import Visible from 'lib/ui/layout/Visible'
import Header from 'Event/Statistics/popup/Header'
import {Tiny} from 'lib/ui/typography'
import SubHead from 'Event/Statistics/popup/SubHead'
import UserFilledIcon from 'Event/Statistics/popup/UserFilledIcon'

export default function Area(props: {
  id: number
  name: string
  numAttendees: number
  disabled: boolean
  selected: boolean
  onClick: (id: number) => void
  className?: string
  canViewRooms?: boolean
}) {
  const {id, name, selected, disabled, numAttendees, onClick, className} = props

  const toggleRoomsLabel = selected ? 'Hide rooms' : 'View rooms'

  const handleClick = () => {
    onClick(id)
  }

  return (
    <Container className={className} disabled={disabled}>
      <DetailCard>
        <DetailHeader>
          <OpenStatisticWindowButton
            statisticType={STATISTIC_AREA}
            areaId={id}
          />
        </DetailHeader>
        <SubHead aria-label="area name">{name}</SubHead>
        <Row>
          <UserFilledIcon />
          <Header aria-label="number of attendees">{numAttendees}</Header>
        </Row>
      </DetailCard>
      <Visible when={Boolean(props.canViewRooms)}>
        <ViewRooms onClick={handleClick}>
          <Tiny>{toggleRoomsLabel}</Tiny>
        </ViewRooms>
      </Visible>
    </Container>
  )
}

type ContainerProps = {
  disabled: boolean
}

const Container = styled.div<ContainerProps>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

const DetailHeader = styled.div`
  position: absolute;
  top: ${(props) => props.theme.spacing[2]};
  right: ${(props) => props.theme.spacing[2]};
`

const DetailCard = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.sidebar.background};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[3]};
  height: 100%;
  padding: ${(props) => props.theme.spacing[9]} 0;
  border-top-left-radius: ${(props) => props.theme.spacing[1]};
  border-top-right-radius: ${(props) => props.theme.spacing[1]};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[2]};
`

const ViewRooms = styled.div`
  background-color: #f2f5f9;
  height: ${(props) => props.theme.spacing[6]};
  border-bottom-left-radius: ${(props) => props.theme.spacing[1]};
  border-bottom-right-radius: ${(props) => props.theme.spacing[1]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
