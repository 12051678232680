import React from 'react'
import {createPositions, Ordered, orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import AddAgendaButton from 'Event/template/Panels/Dashboard/AgendaList/AddAgendaButton'
import styled from 'styled-components'
import {HashMap} from 'lib/list'

import AgendaItem from 'Event/template/Panels/Dashboard/AgendaList/AgendaItem'
import {FontStyle} from 'lib/ui/typography/FontStyleInput'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'

import {usePanelsUpdate, usePanelsTemplate} from 'Event/template/Panels'

import {useAttendeeVariables, useGuestVariables} from 'Event'
import {AgendaListConfig} from 'Event/template/Panels/Dashboard/AgendaList/AgendaListConfig'
import {PageTitle, PageDescription} from 'Event/template/Panels/Page'
import Configurable from 'organization/Event/Configurable'
import StyledText from 'lib/ui/typography/StyledText'
import {Font, useLoadFont} from 'lib/FontSelect'
import {Publishable} from 'Event/Dashboard/editor/views/Published'
import Description from 'Event/template/Panels/Dashboard/AgendaList/Description'
import Footer from 'Event/template/Panels/Dashboard/AgendaList/Footer'

export type Agenda = Publishable &
  Ordered & {
    startDate: string
    endDate: string | null
    text: string
    description: string | null
    link: string | null
    speakers: string[]
  }

export interface AgendaListSettings {
  title: string
  menuTitle?: string
  items: HashMap<Agenda>
  isVisible?: boolean
  footer?: string
  footerFontStyles?: FontStyle[]
  description?: string
  descriptionFontStyles?: FontStyle[]
  color: string
  font: Font | null
  separator: {
    color: string
  }
}

export default function AgendaListContent() {
  const template = usePanelsTemplate()

  const {agendaList: list} = template
  const v = useGuestVariables()
  const isEditMode = useEditMode()

  useLoadFont(list.font)

  if (isEditMode) {
    return <ConfigurableAgendaListContent />
  }
  return (
    <>
      <PageTitle aria-label="agenda list">{v(list.title)}</PageTitle>
      <PageDescription>
        <StyledText
          Component={Description}
          fontStyles={list.descriptionFontStyles}
          aria-label="agenda list description"
          color={list.color}
          font={list.font}
        >
          {v(list.description)}
        </StyledText>
      </PageDescription>
      <ItemListing />
      <StyledText
        Component={Footer}
        fontStyles={list.footerFontStyles}
        aria-label="agendas footer description"
        color={list.color}
        font={list.font}
      >
        {v(list.footer || '')}
      </StyledText>
    </>
  )
}

function ConfigurableAgendaListContent() {
  const template = usePanelsTemplate()

  const {agendaList: list} = template
  const v = useAttendeeVariables()
  useLoadFont(list.font)
  return (
    <>
      <Configurable aria-label="agenda list config">
        <AgendaListConfig agendaList={list} />
        <PageTitle aria-label="agenda list">{v(list.title)}</PageTitle>
      </Configurable>
      <PageDescription>
        <StyledText
          Component={Description}
          fontStyles={list.descriptionFontStyles}
          aria-label="agenda list description"
          color={list.color}
          font={list.font}
        >
          {v(list.description)}
        </StyledText>
      </PageDescription>
      <ItemListing />
      <StyledText
        Component={Footer}
        fontStyles={list.footerFontStyles}
        aria-label="agendas footer description"
        color={list.color}
        font={list.font}
      >
        {v(list.footer || '')}
      </StyledText>
    </>
  )
}

function ItemListing() {
  const isEdit = useEditMode()
  const {agendaList: list} = usePanelsTemplate()

  if (!isEdit)
    return (
      <Container font={list.font} color={list.color}>
        <AgendaItemList />
      </Container>
    )

  return <DraggableList />
}

function DraggableList() {
  const handleDrag = useHandleDrag()
  const {agendaList: list} = usePanelsTemplate()

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="drag-and-drop-agendas">
          {(provided) => (
            <Container
              ref={provided.innerRef}
              font={list.font}
              color={list.color}
              {...provided.droppableProps}
            >
              <>
                <AgendaItemList />
                {provided.placeholder}
              </>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
      <StyledAddAgendaEventButton />
    </>
  )
}

function AgendaItemList() {
  const template = usePanelsTemplate()
  const {agendaList: list} = template

  const {separator} = list
  const ids = orderedIdsByPosition(list.items)

  return (
    <>
      {ids.map((id, index) => {
        const agenda = list.items[id]
        return (
          <AgendaItem
            agenda={agenda}
            id={id}
            key={id}
            index={index}
            borderColor={separator?.color || '#ffffff'}
            color={list.color}
          />
        )
      })}
    </>
  )
}

function useHandleDrag() {
  const template = usePanelsTemplate()
  const updateTemplate = usePanelsUpdate()

  const {agendaList: list} = template

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(list.items)

    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    updateTemplate({
      agendaList: {
        items: createPositions(ids),
      },
    })
  }
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
    font: Font | null
    color: string
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    {props.children}
  </Box>
))

const Box = styled.div<{font: Font | null; color: string}>`
  margin-bottom: 30px;
  width: 100%;
  color: ${(props) => props.color};
  ${(props) => (props.font ? `font-family: ${props.font.family};` : '')}
`

const StyledAddAgendaEventButton = styled(AddAgendaButton)`
  margin-top: ${(props) => props.theme.spacing[4]}!important;
`
