import React from 'react'
import BaseConfig from 'Event/Sponsors/SponsorConfig'
import Settings from 'Event/template/Lefty/Sponsors/SponsorList/Card/SponsorConfig/Settings'
import Styling from 'Event/template/Lefty/Sponsors/SponsorList/Card/SponsorConfig/Styling'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'

export default function SponsorConfig(props: {
  id: string
  sponsor: LeftySponsor
}) {
  return (
    <BaseConfig
      {...props}
      settingsPanel={<Settings id={props.id} sponsor={props.sponsor} />}
      stylingPanel={<Styling id={props.id} sponsor={props.sponsor} />}
    />
  )
}
