import {Link} from 'react-router-dom'
import Button from 'lib/ui/Button'
import React from 'react'
import {useEvent} from 'Event/EventProvider'

const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

export default function PreviewButton() {
  const {event} = useEvent()

  return (
    <Link
      to={{
        pathname: `${marketplaceStoreUrl}/${event.slug}/preview?page=upsell`,
      }}
      target="_blank"
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        aria-label="preview button"
      >
        Preview &gt;
      </Button>
    </Link>
  )
}
