import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'
import Title from 'lib/ui/ConfigPanel/Title'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function PhoneNumberFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const {signUp, login} = useTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const label = replaceAtPath(
      data,
      'signUp.phoneNumberLabel',
      '{{sign_up_phone_number_label}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        sign_up_phone_number_label: label ?? '',
      }),
    })
  }

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Phone Number Field" onSave={save}>
      <SettingsPanel>
        <Title>Phone Number</Title>
        <TextField
          name="signUp.phoneNumberLabel"
          defaultValue={v(signUp.phoneNumberLabel)}
          label="Label"
          inputProps={{
            'aria-label': 'phone number label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields templateKey="login.input" values={login.input} />
      </StylingPanel>
    </Config>
  )
}
