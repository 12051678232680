import { DowncastConversionApi, DowncastDispatcher } from "@ckeditor/ckeditor5-engine";
import { BaseEvent } from "@ckeditor/ckeditor5-utils";

export function modelToViewAttributeConverter( attributeKey: string ) {
	return (dispatcher: DowncastDispatcher) => {
		dispatcher.on( `attribute:${ attributeKey }:customButton`, converter );
	};

	function converter( evt: any, data: any, conversionApi: DowncastConversionApi ) {
		if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
			return;
		}

		const viewWriter = conversionApi.writer;
		const button = conversionApi.mapper.toViewElement( data.item )!;

		if ( data.attributeNewValue !== null ) {
			viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, button );
		} else {
			viewWriter.removeAttribute( data.attributeKey, button );
		}
	}
}

export function modelToViewStyleConverter( attributeKey: string, styleKey: string, styleUnit: string ) {
	return (dispatcher: DowncastDispatcher) => {
		dispatcher.on( `attribute:${ attributeKey }:customButton`, converter );
	};

	function converter( evt: any, data: any, conversionApi: DowncastConversionApi ) {
		if ( !conversionApi.consumable.consume( data.item, evt.name ) ) {
			return;
		}

		const viewWriter = conversionApi.writer;
		const button = conversionApi.mapper.toViewElement( data.item )!;

		if ( data.attributeNewValue !== null ) {
			viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, button );
			viewWriter.setStyle( styleKey, data.attributeNewValue + styleUnit, button );
		} else {
			viewWriter.removeAttribute( data.attributeKey, button );
		}
	}
}
