import {
  Editable,
  EditableProps,
} from 'Event/Dashboard/editor/views/EditComponent'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {useToggleArray} from 'lib/toggle'
import React from 'react'
import {useForm, UseFormMethods} from 'react-hook-form'

type ConfigurableContextProps = {
  showing: boolean
  toggle: () => void
  form: UseFormMethods
  getPreviewData?: (data: Record<string, any>) => Record<string, any>
}

export const ConfigContext = React.createContext<
  undefined | ConfigurableContextProps
>(undefined)

export default function Configurable(props: {
  // Expect <Config/>, and <Component/> pairs
  children: [JSX.Element, JSX.Element]
  className?: string
  dataTestId?: string
  'aria-label'?: string
  editableProps?: Partial<EditableProps>
  getPreviewData?: (data: Record<string, any>) => Record<string, any>
  alwaysShowButtons?: boolean
}) {
  const {children, editableProps, getPreviewData} = props
  const [showing, toggle] = useToggleArray()

  const isEditMode = useEditMode()
  const form = useForm()

  const config = isEditMode ? children[0] : null
  const target = children[1]

  // Only render edit components when required to prevent
  // divs from messing up layout.
  const body = isEditMode ? (
    <Editable
      onEdit={toggle}
      aria-label={props['aria-label']}
      className={props.className}
      dataTestId={props.dataTestId}
      alwaysShowButtons={props.alwaysShowButtons}
      {...editableProps}
    >
      <Clickable>{target}</Clickable>
    </Editable>
  ) : (
    target
  )

  return (
    <ConfigContext.Provider value={{showing, toggle, form, getPreviewData}}>
      {config}
      {body}
    </ConfigContext.Provider>
  )
}

export function ConfigButton(props: {
  children: (nToggle: () => void) => JSX.Element
  className?: string
  dataTestId?: string
  'aria-label'?: string
  config: JSX.Element
}) {
  const {children, config} = props
  const [showing, toggle] = useToggleArray()

  const form = useForm()

  return (
    <ConfigContext.Provider value={{showing, toggle, form}}>
      {config}
      {children(toggle)}
    </ConfigContext.Provider>
  )
}

export function useConfig() {
  const context = React.useContext(ConfigContext)
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}

// Always force content with min-height/width to allow empty content
// to still be clickable.
export const Clickable = styled.div`
  min-height: 24px;
  display: flex;
  flex-direction: column;
`

// A full-height Configurable useful for page-level configs where pages
// make have 100% height.
export const PageConfigurable = styled(Configurable)`
  height: 100%;

  ${Clickable} {
    height: 100%;
  }
`

export const MinContentConfigurable = styled(Configurable)`
  flex: 0;
`
