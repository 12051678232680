import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import IconPicker from 'lib/ui/form/IconPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {onChangeCheckedHandler} from 'lib/dom'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import Slider from 'lib/ui/Slider'
import {Label} from 'lib/ui/typography'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import {UseFormMethods} from 'react-hook-form'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import AlignButtons from 'lib/ui/form/AlignButtons'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {ButtonBlock} from 'Event/Marketplace/Block/Button/types'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'

interface ButtonConfigProps extends ButtonBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function UpsellPageButtonConfig(props: ButtonConfigProps) {
  const {
    id,
    icon,
    iconAlign,
    text,
    textSecond,
    image,
    padding,
    alignment,
    onMoveSectionUp,
    onMoveSectionDown,
  } = props
  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()
  const {
    form: {register, control, watch},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Button Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`icon`}
          defaultValue={icon || ''}
          control={control}
          render={({value, onChange}) => (
            <IconPicker value={value} onChange={onChange} />
          )}
        />

        <Controller
          name={`iconAlign`}
          defaultValue={iconAlign ?? 'left'}
          control={control}
          render={({value, onChange}) => (
            <AlignButtons value={value} onChange={onChange} />
          )}
        />

        <TextField
          name={`text`}
          defaultValue={text || ''}
          label="Text Line 1"
          inputProps={{
            ref: register,
            'aria-label': 'button text input',
          }}
        />

        <TextField
          name={`textSecond`}
          defaultValue={textSecond || ''}
          label="Text Line 2"
          inputProps={{
            ref: register,
            'aria-label': 'button text second input',
          }}
        />

        <Controller
          name={`image`}
          control={control}
          defaultValue={image || ''}
          render={({value, onChange}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              alt="button image"
              uploadInputProps={{
                'aria-label': 'upload button image',
              }}
              removeButtonProps={{
                'aria-label': 'remove button image',
              }}
              canResize
              disableAutoRemove
            />
          )}
        />

        <TargetInput
          control={control}
          register={register}
          watch={watch}
          buttonBlock={props}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={props}
          control={control}
          storageKey="shoppingCart.config.button.styling.typography"
          label="Typography - Line 1"
        />

        <TypographySection
          button={props.typographySecond ?? props}
          control={control}
          storageKey="shoppingCart.config.button.styling.typographySecond"
          namePrefix={`typographySecond`}
          label="Typography - Line 2"
        />

        <BackgroundSection
          button={props}
          control={control}
          watch={watch}
          storageKey="shoppingCart.config.button.styling.background"
        />
        <CollapsibleSection
          label="Size"
          storageKey="shoppingCart.config.button.styling.minSize"
        >
          <Controller
            name="minSize.height"
            defaultValue={props.minSize?.height || 50}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Height"
                unit="%"
              />
            )}
          />
          <Controller
            name="minSize.width"
            defaultValue={props.minSize?.width || 50}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Width"
                unit="%"
              />
            )}
          />
        </CollapsibleSection>
        <BorderSection
          button={props}
          control={control}
          storageKey="shoppingCart.config.button.styling.border"
        />
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey="shoppingCart.config.button.styling.spacing"
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}

export type TargetInputProps = {
  namePrefix?: string
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
  buttonBlock: ButtonConfigProps
}

function TargetInput(props: TargetInputProps) {
  const {namePrefix, buttonBlock, control} = props

  return (
    <>
      <Controller
        name={namePrefix ? `${namePrefix}.destination` : 'destination'}
        control={control}
        defaultValue={buttonBlock.destination || 'link'}
        render={({value, onChange}) => (
          <FormControl>
            <ToggleButtonGroup exclusive onChange={(e, v) => onChange(v)}>
              <ToggleButton selected={'link' === value} value="link">
                Link
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        )}
      />
      <LinkInput {...props} />
    </>
  )
}

function LinkInput(props: TargetInputProps) {
  const {register, buttonBlock, watch, control, namePrefix: prefixName} = props

  if (watch(`${prefixName}.destination`, buttonBlock.destination) !== 'link') {
    return null
  }
  return (
    <>
      <TextField
        name={`${prefixName}.link`}
        defaultValue={buttonBlock.link || ''}
        label="URL"
        inputProps={{
          'aria-label': 'button link input',
          ref: register,
        }}
        fullWidth
        helperText="Starting with https:// or http://"
      />
      <Controller
        name={`${prefixName}.newTab`}
        control={control}
        defaultValue={buttonBlock.newTab || false}
        render={({value, onChange}) => (
          <FormControl>
            <FormControlLabel
              label="New Tab"
              control={
                <Checkbox
                  checked={value}
                  onChange={onChangeCheckedHandler(onChange)}
                  inputProps={{
                    'aria-label': 'optional new tab',
                  }}
                />
              }
            />
          </FormControl>
        )}
      />
    </>
  )
}
