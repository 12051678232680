import Command from '@ckeditor/ckeditor5-core/src/command';
import { isCustomButtonElement } from '../utils';

export default class CustomButtonFontSizeCommand extends Command {
	override refresh() {
		const element = this.editor.model.document.selection.getSelectedElement();

		this.isEnabled = isCustomButtonElement( element );

		if ( element && isCustomButtonElement( element ) && element.hasAttribute( 'buttonFontSize' ) ) {
			this.value = parseInt( element.getAttribute( 'buttonFontSize' ) as string );
		} else {
			this.value = false;
		}
	}

	override execute(value: string) {
		const model = this.editor.model;
		const customButtonElement = model.document.selection.getSelectedElement()!;

		model.change( writer => {
			return writer.setAttribute('buttonFontSize', value, customButtonElement);
		} );
	}
}

