import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {usePrevious} from 'lib/state'
import {DeepPartial} from 'lib/type-utils'
import {equals, mergeDeepRight} from 'ramda'
import React, {useCallback, useEffect, useState} from 'react'

type TemplateUpdate<T> = (updates: DeepPartial<T>) => void

type TemplateEditorContextProps<T> = {
  update: TemplateUpdate<T>
  save: () => void
  clear: () => void

  // In case we need to reference the currently saved template without
  // any updated properties, we'll pass it along.
  saved: T
}

const TemplateEditorContext = React.createContext<
  undefined | TemplateEditorContextProps<any>
>(undefined)
/**
 * HOC component that allows us to preview templates without actually
 * saving them.
 *
 * @param props
 * @returns
 */
export default function TemplateEditor<T extends Record<string, any>>(props: {
  children: (template: T) => JSX.Element
  template: T
}) {
  const {template: saved} = props

  const [updates, setUpdates] = useState({})

  const clear = useCallback(() => {
    setUpdates({})
  }, [setUpdates])

  const send = useSaveTemplate()

  const save = () => {
    send(updates)

    // Clear local copy on save. This prevents incoming updates
    // from overwriting our changes as we're typing if the
    // config happens to be open.
    setUpdates({})
  }

  const update: TemplateUpdate<Template> = useCallback(
    (updates: any) => setUpdates((current) => mergeDeepRight(current, updates)),
    [],
  )

  const current = (mergeDeepRight(saved, updates) as unknown) as Template

  return (
    <TemplateEditorContext.Provider value={{update, save, clear, saved}}>
      {props.children((current as unknown) as T)}
    </TemplateEditorContext.Provider>
  )
}

export function useTemplateEditor<T extends Template>() {
  const context = React.useContext(TemplateEditorContext)
  if (context === undefined) {
    throw new Error('useTemplateEditor must be used within a TemplateEditor')
  }

  return (context as unknown) as TemplateEditorContextProps<T>
}

export function useAutoUpdate(props: {
  values: Record<string, any>
  when: boolean
}) {
  const {values, when: shouldUpdate} = props
  const prev = usePrevious(values)
  const hasChanges = !equals(values, prev)

  const {update} = useTemplateEditor()

  useEffect(() => {
    if (!shouldUpdate) {
      return
    }

    if (!hasChanges) {
      return
    }

    update(values)
  }, [hasChanges, update, values, shouldUpdate])
}
