import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Menu from 'lib/ui/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import {AbsoluteLink} from 'lib/ui/link/AbsoluteLink'
import {Recording} from 'organization/Event/Room/Recordings'
import {useLocalTime} from 'lib/date-time'
import Dialog from 'lib/ui/Dialog'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import {useOrganization} from 'organization/OrganizationProvider'
import {api} from 'lib/url'
import {useRoom} from 'organization/Event/Room/RoomProvider'

export default function MeetingCard(props: {
  recordings: Recording[]
  part: number
  hasMultipleParts: boolean
}) {
  const {recordings, part, hasMultipleParts} = props
  const localTime = useLocalTime()
  const [showSummary, setShowSummary] = useState<boolean>(false)

  const toggleShowSummary = () => {
    setShowSummary((cur) => !cur)
  }

  const hasRecordings = recordings.length > 0
  if (!hasRecordings) {
    return (
      <Card>
        <CardContent>No Recordings Available</CardContent>
      </Card>
    )
  }

  const main = recordings[0]

  const hasSummary = !!recordings.find((item) => item.file_type === 'SUMMARY')

  return (
    <Card elevation={4}>
      <SummaryDialog
        show={showSummary}
        recordings={recordings}
        onClose={toggleShowSummary}
      />
      <StyledReactPlayer playing controls light url={main.download_url} />
      <CardContent>
        <PartLabel showing={hasMultipleParts}>
          <div>
            Part: <b>{part}</b>
          </div>
        </PartLabel>
        <div>
          Start time: <b>{localTime(main.recording_start)}</b>
        </div>
        <div>
          End time: <b>{localTime(main.recording_end)}</b>
        </div>
        <div>
          File size: <b>{convertFileSizeToReadable(main.file_size)}</b>
        </div>
      </CardContent>
      <CardActions>
        <Menu
          button={({open}) => (
            <ButtonGroup variant="contained" color="primary">
              <Button
                color="primary"
                variant="contained"
                aria-label={`download ${main.name}`}
                href={main.download_url}
                download
              >
                Download
              </Button>
              <Button
                color="primary"
                size="small"
                aria-label="view other recordings"
                aria-haspopup="menu"
                onClick={open}
              >
                <ArrowDownIcon />
              </Button>
            </ButtonGroup>
          )}
        >
          {recordings
            .filter((recording) => recording.file_type !== 'SUMMARY')
            .map((recording, index) => {
              return (
                <AbsoluteLink
                  key={index}
                  to={recording.download_url}
                  aria-label={`download ${recording.name}`}
                  download
                  disableStyles
                >
                  <MenuItem>
                    {recording.name} - {recording.file_type} -{' '}
                    <b>{convertFileSizeToReadable(recording.file_size)}</b>
                  </MenuItem>
                </AbsoluteLink>
              )
            })}
          {hasSummary && (
            <MenuItem onClick={toggleShowSummary}>
              Show Zoom AI Companion Summary
            </MenuItem>
          )}
        </Menu>
      </CardActions>
    </Card>
  )
}

function SummaryDialog(props: {
  show: boolean
  recordings: Recording[]
  onClose: () => void
}) {
  const summaryRecording = props.recordings.find(
    (item) => item.recording_type === 'summary',
  )
  const summaryStepsRecording = props.recordings.find(
    (item) => item.recording_type === 'summary_next_steps',
  )

  return (
    <Dialog open={props.show} onClose={props.onClose} fullWidth={true}>
      <SummaryDialogContent>
        <Summary title="Summary" recording={summaryRecording} />
        <Summary title="Next Steps" recording={summaryStepsRecording} />
      </SummaryDialogContent>
    </Dialog>
  )
}

function Summary(props: {title: string; recording?: Recording}) {
  const {recording} = props
  const {fetchSummary, loading, summary} = useFetchSummary()

  const copy = () => {
    if (!summary) return
    navigator.clipboard.writeText(summary)
  }

  useEffect(() => {
    if (recording) {
      fetchSummary(recording.download_url)
    }
  }, [fetchSummary, recording])

  if (loading) {
    return <div>Loading ...</div>
  }

  return (
    <SummaryBox>
      <SummaryTitle>
        {props.title} <CopyIconButton aria-label="copy" onClick={copy} />
      </SummaryTitle>
      {summary}
    </SummaryBox>
  )
}

function PartLabel(props: {showing: boolean; children: React.ReactElement}) {
  if (!props.showing) {
    return null
  }

  return props.children
}

export function convertFileSizeToReadable(size: number) {
  let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (
    (size / Math.pow(1024, i)).toFixed(2) +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  )
}

export function useFetchSummary() {
  const [summary, setSummary] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const {client} = useOrganization()
  const {room} = useRoom()
  const fetchSummary = useCallback(
    (fileUrl: string) => {
      const url = api(`/rooms/${room.id}/recordings_summary?url=${fileUrl}`)
      client.get<any>(url).then((json) => {
        setSummary(parseSummary(json))
        setLoading(false)
      })
    },
    [client, room],
  )

  return {fetchSummary, summary, loading}
}

function parseSummary(json: {
  overall_summary?: string
  items?: [
    {
      rephrased_text?: string
    },
  ]
}): string | null {
  if (json.overall_summary) {
    return json.overall_summary
  }

  if (json.items) {
    let stepsResult = ''
    json.items.forEach((item, index) => {
      stepsResult += `${index + 1}. ${item.rephrased_text}\n`
    })

    return stepsResult
  }

  return null
}

const StyledReactPlayer = styled(ReactPlayer)`
  width: 100% !important;
  background-color: #000000;
`

const SummaryBox = styled.div`
  background: #eee;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  white-space: pre-wrap;
`

const SummaryDialogContent = styled.div`
  padding: 20px;
  padding-top: 60px;
`

const SummaryTitle = styled.span`
  font-weight: bold;
  font-weight: bold;
  display: flex !important;
  justify-content: space-between;
`
