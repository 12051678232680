import {ObvioEvent} from 'Event'
import {useEvents} from 'lib/event-api/organization/use-events'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export type EventWithPurchasePage = ObvioEvent & {
  purchasePageTemplate: PurchasePage['template']
}

export function usePurchasePageEvents() {
  const {organization} = useOrganization()
  const {data: events} = useEvents()

  return useQuery({
    queryKey: ['organizations', organization.id, 'purchase_page_events'],
    queryFn: () =>
      new Promise<EventWithPurchasePage[]>(async (resolve) => {
        if (!events) {
          resolve([])
          return
        }

        const purchasePages = events.map(async (event) => {
          const purchasePage = await client.get<PurchasePage | null>(
            marketplaceApi(`/events/${event.id}/purchase_page`),
          )

          if (!purchasePage?.template) {
            return null
          }

          const hasSections = Boolean(purchasePage.template.sections)
          if (!hasSections) {
            return null
          }

          return {
            ...event,
            purchasePageTemplate: purchasePage.template,
          }
        })

        const result = (await Promise.all(purchasePages)).filter(
          (purchasePage) => purchasePage,
        ) as EventWithPurchasePage[]

        resolve(result)
      }),
    enabled: !!events,
  })
}
