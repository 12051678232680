import React from 'react'
import {Font, getFontStyles} from '../../lib/font'

interface PurchaseSummaryColumn {
  children: string
  summaryColumnLabel?: {
    font: Font | null
    fontSize?: number
    border: {
      width: number
      color: string
    }
  }
}

export default function PurchaseSummaryColumnLabel(
  props: PurchaseSummaryColumn,
) {
  const {children, summaryColumnLabel} = props
  return (
    <span
      style={{
        ...getFontStyles(summaryColumnLabel?.font ?? null),
        fontSize: summaryColumnLabel?.fontSize ?? '16px',
      }}
    >
      {children}
    </span>
  )
}
