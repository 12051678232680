import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'

export default function BackToDashboardConfig() {
  const saveTemplate = useSaveTemplate()

  const template = useSimpleBlogTemplate()
  const {faq} = template
  const {
    form: {register, control},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const backToDashboardText = replaceAtPath(
      data,
      'faq.backToDashboardText',
      '{{faq_back_to_dashboard_text}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        [`faq_back_to_dashboard_text`]: backToDashboardText ?? '',
      }),
    })
  }

  return (
    <Config title="Back to Dashboard Text" onSave={save}>
      <SettingsPanel>
        <TextField
          name="faq.backToDashboardText"
          defaultValue={v(faq.backToDashboardText)}
          label="Back to Dashboard Text"
          fullWidth
          inputProps={{
            'aria-label': 'back to dashboard text',
            ref: register,
          }}
        />
        <Controller
          name="faq.backToDashboardTextColor"
          defaultValue={faq.backToDashboardTextColor}
          control={control}
          render={({onChange, value}) => (
            <ColorPicker
              label="Back to Dashboard Text Color"
              color={value}
              onPick={onChange}
              aria-label="text color"
            />
          )}
        />
      </SettingsPanel>
    </Config>
  )
}
