import React, {useState} from 'react'
import styled from 'styled-components'
import Slide from '@material-ui/core/Slide'
import {User} from 'auth/user'
import {rgba} from 'lib/color'
import {useToggle} from 'lib/toggle'
import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import Emojis from 'Event/template/NiftyFifty/Dashboard/EmojiList'
import LeftPanelConfig from 'Event/template/NiftyFifty/Dashboard/LeftPanel/LeftPanelConfig'
import MainNavDesktop from 'Event/template/NiftyFifty/Dashboard/MainNav/MainNavDesktop'
import Menu from 'Event/template/NiftyFifty/Dashboard/Menu'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled from 'lib/ui/layout/Scheduled'

export default function LeftPanel(props: {
  onChangeTab: (tab: number) => void
  user: User
}) {
  const [menuVisible, setMenuVisible] = useState(false)
  const toggleMenu = () => setMenuVisible(!menuVisible)

  const {flag: barConfigVisible, toggle: toggleBarConfig} = useToggle()
  const template = useNiftyFiftyTemplate()
  const {leftPanel, menu, emojiList} = template

  const handleChangeTab = (tab: number) => {
    props.onChangeTab(tab)
    setMenuVisible(false)
  }

  const background = () => {
    const menuBackgroundColor = rgba(
      menu.backgroundColor,
      menu.backgroundOpacity / 100,
    )

    const panelColor = rgba(
      leftPanel.backgroundColor,
      leftPanel.backgroundOpacity / 100,
    )

    if (menuVisible) {
      return menuBackgroundColor
    }

    if (!template.dashboardBackground.enabled) {
      return panelColor
    }

    if (template.dashboardBackground.image) {
      return `url(${template.dashboardBackground.image}) no-repeat center`
    }

    return panelColor
  }

  return (
    <Container background={background()} aria-label="left panel">
      <EditModeOnly>
        <LeftPanelConfig showing={barConfigVisible} onClose={toggleBarConfig} />
      </EditModeOnly>
      <Box
        backgroundColor={rgba(
          leftPanel.backgroundColor,
          leftPanel.backgroundOpacity,
        )}
      >
        <Editable onEdit={toggleBarConfig} aria-label="left panel edit">
          <StyledMenuIconButton
            active={menuVisible}
            iconColor={menu.iconColor}
            onClick={toggleMenu}
            aria-label="show side menu"
          />
        </Editable>
        <Main>
          <DashboardLogo
            src={template.dashboardLogo.image}
            size={template.dashboardLogo.size}
            enabled={template.dashboardLogo.enabled}
          />
          {/*
              Menu slide-in-out animation. Need to set content to null to avoid
              the exiting content from having a height, and the divs
              stacking while animating.
          */}
          <Slide in={menuVisible} direction="left" mountOnEnter unmountOnExit>
            <MenuBox visible={menuVisible}>
              {menuVisible ? (
                <Menu onChangeTab={handleChangeTab} user={props.user} />
              ) : null}
            </MenuBox>
          </Slide>
          <Slide in={!menuVisible} direction="right" mountOnEnter unmountOnExit>
            <MainContent visible={!menuVisible}>
              {menuVisible ? null : (
                <>
                  <MainNavDesktop />
                  <VisibleOnMatch rules={emojiList.rules}>
                    <Scheduled component={emojiList}>
                      <Emojis />
                    </Scheduled>
                  </VisibleOnMatch>
                </>
              )}
            </MainContent>
          </Slide>
        </Main>
      </Box>
    </Container>
  )
}

function DashboardLogo(props: {
  src?: string | null
  size: number
  enabled: boolean
}) {
  if (props.src && props.enabled) {
    return (
      <StyledDashboardLogo
        src={props.src}
        size={props.size}
        alt="dashboard logo"
      />
    )
  }
  return null
}

const Container = styled.div<{
  background: string
}>`
  background: ${(props) => props.background};
  background-size: cover;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
`

const Box = styled.div<{
  backgroundColor: string
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: ${(props) => props.theme.spacing[6]} 0;
`

const Main = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing[12]};
  flex: 1;

  /**
   * Hide overflow to make menu sliding in/out disappear at panel edge,
   * as well as avoid any jumpiness from the transition.
   */
  overflow: hidden;
`

const MainContent = styled.div<{
  visible: boolean
}>`
  flex: ${(props) => (props.visible ? 1 : 0)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  /**
  Allow buttons to scroll if required
  */
  overflow-y: hidden;
`

const MenuBox = styled.div<{
  visible: boolean
}>`
  flex: ${(props) => (props.visible ? 1 : 0)};
  display: flex;
`

const StyledMenuIconButton = styled(MenuIconButton)`
  margin-left: 24px;
  width: 30px;
  height: 30px;
  top: 0;
  transform: none;
`

const StyledDashboardLogo = styled.img<{size: number}>`
  width: ${(props) => props.size}%;
  height: auto;
  margin: 0 auto;
`
