import Option from 'lib/ui/Select/Option'
import React from 'react'
import {
  FormResponseRule,
  IS,
  IS_NOT,
  CONTAIN,
  DOES_NOT_CONTAIN,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import Select from 'lib/ui/Select'
import {onUnknownChangeHandler} from 'lib/dom'

export default function RuleTypeSelect(props: {
  type: FormResponseRule['type']
  onChange: (type: FormResponseRule['type']) => void
}) {
  return (
    <Select
      value={props.type}
      fullWidth
      onChange={onUnknownChangeHandler(props.onChange)}
      aria-label="pick form response rule type"
    >
      <Option value={IS}>{IS}</Option>
      <Option value={IS_NOT}>{IS_NOT}</Option>
      <Option value={CONTAIN}>{CONTAIN}</Option>
      <Option value={DOES_NOT_CONTAIN}>{DOES_NOT_CONTAIN}</Option>
    </Select>
  )
}
