import React from 'react'
import styled from 'styled-components'

interface SectionHeaderProps {
  children: string
}

export default function SectionHeader(props: SectionHeaderProps) {
  const {children} = props
  return (
    <Box>
      <Title>{children}</Title>
    </Box>
  )
}

const Box = styled.div`
  background: #000000;
  padding: 8px 0;
  width: 100%;
  margin-bottom: 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const Title = styled.h5`
  font-size: 32px;
  color: #ffffff;
  text-align: center;
  margin: 0;
`
