import {useLanguage} from 'Event/LanguageProvider'
import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import React from 'react'

export default function EnableTranslationSwitch() {
  const {translationsEnabled} = useLanguage()
  const setTranslationsEnabled = useSetTranslationsEnabled()

  return (
    <EnabledSwitch
      checked={translationsEnabled}
      onChange={onChangeCheckedHandler(setTranslationsEnabled)}
      aria-label="toggle translations enabled"
    />
  )
}

function useSetTranslationsEnabled() {
  const saveTemplate = useSaveTemplate()

  return (enabled: boolean) => {
    const localization: Partial<Template['localization']> = {
      translationsEnabled: enabled,
    }

    return saveTemplate({
      localization,
    })
  }
}
