import {UpsellPurchaseButtonBlock} from 'Event/Marketplace/Block/UpsellPurchaseButton'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import {Label} from 'lib/ui/typography'
import ColorPicker from 'lib/ui/ColorPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onChangeCheckedHandler, onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'
import Visible from 'lib/ui/layout/Visible'
import Switch from 'lib/ui/form/Switch'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'

interface UpsellPurchaseButtonConfigProps extends UpsellPurchaseButtonBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function UpsellPurchaseButtonConfig(
  props: UpsellPurchaseButtonConfigProps,
) {
  const {id, onMoveSectionUp, onMoveSectionDown} = props
  const {update} = useConfigurableSection()
  const {data: tickets} = useEventTickets()
  const {calculateVisibility} = useSection()
  const [visibility, setVisibility] = useState(props.visibility)
  const {
    form: {control},
  } = useConfig()

  const phoneNumberEnabled = tickets?.some(
    (ticket) => ticket.phone_number_enabled,
  )

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Upsell Purchase" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <CollapsibleSection
          label="Title"
          noDivider
          storageKey={`blocks-${id}-title-config`}
        >
          <Controller
            name={`title.enabled`}
            control={control}
            defaultValue={props.title?.enabled}
            render={({value, onChange}) => (
              <Switch
                checked={value}
                onChange={onChangeCheckedHandler(onChange)}
                arial-label="toggle title enabled"
                labelPlacement="end"
                label={value ? 'Title Enabled' : 'Title Disabled'}
              />
            )}
          />
        </CollapsibleSection>
        <Visible when={Boolean(phoneNumberEnabled)}>
          <CollapsibleSection
            label="Title"
            noDivider
            storageKey={`blocks-${id}-title-config`}
          >
            <Controller
              name={`requirePhoneNumber`}
              defaultValue={props.requirePhoneNumber ?? false}
              control={control}
              render={({value, onChange}) => (
                <Switch
                  checked={value}
                  label="Phone Number Required"
                  labelPlacement="end"
                  onChange={onChangeCheckedHandler(onChange)}
                />
              )}
            />
          </CollapsibleSection>
        </Visible>
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />

        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Border"
          storageKey={`blocks-${id}-styling.border`}
        >
          <Controller
            name={`border.color`}
            defaultValue={props.border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
          <Controller
            name={`border.width`}
            defaultValue={props.border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Width"
              />
            )}
          />
          <Controller
            name={`border.radius`}
            defaultValue={props.border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${id}-styling.background`}
        >
          <Controller
            name={`background.image`}
            control={control}
            defaultValue={props.background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'block background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`background.color`}
            defaultValue={props.background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`background.opacity`}
            defaultValue={props.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`alignment.vertical`}
            defaultValue={props.alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`alignment.horizontal`}
            defaultValue={props.alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-styling.paddingControls`}
          open={false}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={props.padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
