import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React, {useEffect, useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {
  useTownhallUpdate,
  Townhall,
  useTownhallTemplate,
} from 'Event/template/Townhall'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import MainNavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Townhall/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Styling'
import {usePruneAssets} from 'lib/asset'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
  disablePageSelect?: boolean
}

export function MainNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, button} = props

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button.rules)
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const set = useTownhallUpdate()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Townhall>()
  const {mainNav} = useTownhallTemplate()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(button.rules)
  }, [isVisible, button, props.id])

  useAutoUpdate({
    values: {
      mainNav: {
        buttons: {
          [id!]: watch(),
        },
      },
    },
    when: Boolean(id),
  })

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image
    pruneAssets({image}, saved.mainNav.buttons[id])
    const {text: _translatedText, ...otherUpdates} = updated

    set({
      localization: addTranslation({
        [`${id}_text`]: updated.text,
      }),
      mainNav: {
        buttons: {
          [id]: {
            ...otherUpdates,
            text: `{{${id}_text}}`,
          },
        },
      },
    })
  }

  const insert = (button: NavButtonWithSize) => {
    const numButtons = Object.keys(mainNav.buttons).length
    const position = numButtons + 1

    generateHashId([
      'townhall_',
      'main_nav_button',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `button_${id}`

      set({
        localization: addTranslation({
          [`${componentId}_text`]: button.text,
        }),
        mainNav: {
          buttons: {
            [componentId]: {
              ...button,
              text: `{{${componentId}_text}}`,
              position,
            },
          },
        },
      })
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    set({
      localization: removeTranslations([`${id}_text`]),
      mainNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
    })
    onClose()
  }, [set, id, removeTranslations, onClose])

  const save = (formData: any) => {
    const data: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, data)
    } else {
      insert(data)
    }
    onClose()
  }

  return (
    <MainNavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      handleSubmit={handleSubmit}
      save={save}
      formState={formState}
      isVisible={isVisible}
      showing={Boolean(id)}
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}
