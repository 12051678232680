import React from 'react'
import styled from 'styled-components'
import {HashMap} from 'lib/list'
import {Speaker} from 'Event/Speakers'
import {Agenda} from 'Event/template/NiftyFifty/Dashboard/AgendaList'

export default function SpeakerNames<T extends Agenda>(props: {
  agenda: T
  speakers: HashMap<Speaker>
}) {
  const {agenda, speakers} = props

  const names = agenda.speakers
    .map((id) => speakers[id]?.name)
    .filter((name) => Boolean(name)) // Handle missing speakers
    .join(', ')

  return <SpeakerNamesText>{names}</SpeakerNamesText>
}

const SpeakerNamesText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 0.16px;
`
