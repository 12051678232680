import React from 'react'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import TextField from 'lib/ui/TextField'
import {defaultGeneratingCertificateText} from 'Event/Dashboard/components/NavButton/CertificateButton'

export default function CertificateGeneratingConfig(props: {
  watch: UseFormMethods['watch']
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  button: NavButtonProps
}) {
  const {control, button, watch, register} = props

  const hasImage = !!watch('certificate.generatingImage')
  if (hasImage) {
    return <ImageInput control={control} button={button} />
  }

  return (
    <>
      <TextInput register={register} button={button} />
      <ImageInput control={control} button={button} />
    </>
  )
}

function TextInput(props: {
  register: UseFormMethods['register']
  button: NavButtonProps
}) {
  const {register, button} = props

  return (
    <TextField
      defaultValue={
        button.certificate?.generatingText ?? defaultGeneratingCertificateText
      }
      name="certificate.generatingText"
      label="Generating Certificate Text"
      fullWidth
      inputProps={{
        'aria-label': 'set generating text',
        ref: register({required: 'Generating text is required'}),
      }}
    />
  )
}

function ImageInput(props: {
  control: UseFormMethods['control']
  button: NavButtonProps
}) {
  const {control, button} = props

  return (
    <Controller
      name="certificate.generatingImage"
      control={control}
      defaultValue={button.certificate?.generatingImage ?? ''}
      render={({value, onChange}) => (
        <ImageAssetUploader
          onChange={onChange}
          value={value}
          alt="button image"
          uploadInputProps={{
            'aria-label': 'upload generating image',
          }}
          canResize
          disableAutoRemove
        />
      )}
    />
  )
}
