import React from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Box from 'lib/ui/Box'
import {Title, Typography} from 'lib/ui/typography'
import {InfoIcon} from 'lib/ui/Icon'

export default function ConfirmConnectDialog(props: {
  onClose: () => void
  url: string
}) {
  const {url, onClose} = props

  const goToStripe = () => {
    onClose()
    window.open(url, '_blank')?.focus()
  }

  return (
    <Dialog open>
      <DialogContent>
        <Box>
          <Title align="center" mb={4}>
            <InfoIcon color="info" /> Stripe Connect Account
          </Title>

          <Typography mb={4}>
            The initial step of linking your Obvio Marketplace to Stripe has
            been completed.
          </Typography>

          <Typography mb={8}>
            By clicking Proceed, you will be brought to Stripe.com to continue
            the onboarding process. When all the required information has been
            supplied, you will be returned back to Obvio.
          </Typography>

          <ButtonContainer>
            <Button
              aria-label="close salt overlay"
              color="primary"
              onClick={goToStripe}
              variant="contained"
            >
              Proceed
            </Button>
            <Button
              aria-label="close salt overlay"
              color="primary"
              onClick={onClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </ButtonContainer>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  & button {
    margin-right: ${(props) => props.theme.spacing[4]};

    &:last-child {
      margin-right: unset;
    }
  }
`
