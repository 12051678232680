import React from 'react'
import styled from 'styled-components'
import {Header as BaseHeader} from 'lib/ui/typography'
import {useStatistics} from 'Event/Statistics'

const DEFAULT_FONT_SIZE = 24
const DEFAULT_LINE_HEIGHT = 28

export default function Header(props: {
  children: React.ReactElement | number | string
  'aria-label'?: string
}) {
  const {fontSize} = useStatistics()

  return (
    <StyledHead
      color="white"
      delta={fontSize}
      aria-label={props['aria-label'] || 'statics panel header'}
    >
      {props.children}
    </StyledHead>
  )
}

const StyledHead = styled(BaseHeader)<{delta: number}>`
  font-size: ${(props) => DEFAULT_FONT_SIZE + props.delta}px;
  line-height: ${(props) => DEFAULT_LINE_HEIGHT + props.delta}px;
`
