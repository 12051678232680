import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import {useLocalization} from 'lib/LocalizationProvider'
import {Agenda} from 'Event/template/NiftyFifty/Dashboard/AgendaList'

export default function EventTimes<T extends Agenda>(props: {agenda: T}) {
  const start = moment(props.agenda.startDate).tz(moment.tz.guess())
  const getMonth = (date: moment.Moment) => date.format('MMMM')
  const getDay = (date: moment.Moment) => date.format('Do')

  const {has12HourTime} = useLocalization()
  const timeFormat = has12HourTime ? 'h:mma' : 'HH:mm'
  const getTime = (date: moment.Moment) => date.format(timeFormat)

  const getTimezone = (date: moment.Moment) => date.format('z')

  const tz = getTimezone(start)

  /**
   * Single start date only
   */
  if (
    !props.agenda.endDate ||
    props.agenda.startDate === props.agenda.endDate
  ) {
    return (
      <TimeText aria-label="agenda event times">
        <strong>{`${getMonth(start)} ${getDay(start)}:`}</strong>{' '}
        {`${getTime(start)} ${tz}`}
      </TimeText>
    )
  }

  /**
   * Start, and end same day, different times
   */
  const end = moment(props.agenda.endDate).tz(moment.tz.guess())
  const sameMonth = getMonth(end) === getMonth(start)
  const sameDay = getDay(end) === getDay(start)
  if (sameMonth && sameDay) {
    return (
      <TimeText aria-label="agenda event times">
        <strong>{`${getMonth(start)} ${getDay(start)}:`}</strong>{' '}
        {getTime(start)}
        {` - ${getTime(end)} ${tz}`}
      </TimeText>
    )
  }

  /**
   * Two different date/times
   */
  return (
    <TimeText aria-label="agenda event times">
      <strong>{`${getMonth(start)} ${getDay(start)}:`}</strong> {getTime(start)}
      {` - `}
      <strong>
        {getMonth(end)} {getDay(end)}:
      </strong>{' '}
      {getTime(end)} {tz}
    </TimeText>
  )
}

const TimeText = styled.div`
  font-size: 16px;
`
