import {TemplateSetPasswordForm} from 'Event/Step1/SetPasswordForm'
import {useObvioUser} from 'obvio/auth'
import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import RequiresPasswordSwitch from 'Event/Step1/SetPasswordForm/RequiresPasswordSwitch'
import RulesConfig from 'Event/Step1/SetPasswordForm/RulesConfig'
import {ConfigButton} from 'organization/Event/Configurable'
import Button from 'lib/ui/Button'
import {useTemplate} from 'Event/TemplateProvider'
import {CheckInBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useEvent} from 'Event/EventProvider'
import DisabledOverlay from 'lib/ui/DisabledOverlay'

export default function SetPasswordFormConfig() {
  const user = useObvioUser()
  const event = useEvent()
  const emailLoginOnly = event.event.allows_email_only_login

  return (
    <CheckInBreadcrumbs page="Password">
      <Page
        toolbarItems={
          <>
            <RequiresPasswordSwitch disabled={!!emailLoginOnly} />
            <SkipPasswordRulesButton />
          </>
        }
      >
        <>
          <DisabledOverlay
            show={!!emailLoginOnly}
            message={
              'The Password function has been disabled for this event. <br/> The Password function can be enabled on the Event Settings page.'
            }
          />
          <TemplateSetPasswordForm
            submit={() => {}}
            submitting={false}
            responseError={null}
            progress={25}
            user={user}
          />
        </>
      </Page>
    </CheckInBreadcrumbs>
  )
}

function SkipPasswordRulesButton() {
  const {login} = useTemplate()

  return (
    <ConfigButton config={<RulesConfig />}>
      {(onToggle) => (
        <Button
          variant="text"
          size="small"
          type="button"
          onClick={onToggle}
          disabled={!login.requiresPassword}
        >
          Skip Password Rules
        </Button>
      )}
    </ConfigButton>
  )
}
