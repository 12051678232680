import Command from '@ckeditor/ckeditor5-core/src/command';
import type { Editor } from 'ckeditor5/src/core';

export default class FontFamilyCommand extends Command {

	constructor( editor: Editor ) {
		super( editor );
	}

	public override refresh() {

		const model = this.editor.model;
		const doc = model.document;
		this.value = doc.selection.getAttribute( 'customFontFamily' );
		this.isEnabled = model.schema.checkAttributeInSelection( doc.selection, 'customFontFamily' );

	}

	public override execute( newFontFamily: string ) {
		const model = this.editor.model;
		const document = model.document;
		const selection = document.selection;

		model.change( writer => {
			if ( selection.isCollapsed ) {
				if ( newFontFamily ) {
					writer.setSelectionAttribute( "customFontFamily", newFontFamily );
				} else {
					writer.removeSelectionAttribute( "customFontFamily" );
				}
			} else {
				const ranges = model.schema.getValidRanges( selection.getRanges(), "customFontFamily" );

				for ( const range of ranges ) {
					if ( newFontFamily ) {
						writer.setAttribute( "customFontFamily", newFontFamily, range );
					} else {
						writer.removeAttribute( "customFontFamily", range );
					}
				}
			}
		} );
	}
}
