import React, {useCallback, useEffect, useState} from 'react'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'

export interface Category {
  id: number
  name: string
  profiles?: Profile[]
}

export interface Profile {
  id: number
  entity_id: string
  category_id: number
  name: string
}

export interface Block {
  id: number
  block: string
  block_raw: string
  category: Category
  questions: Question[]
  answer_sets: AnswerSet[]
  dependencies: number[]
  prompt: Prompt
}

export interface Prompt {
  block_id: number
  dependencies: number[]
  id: number
  name: string
}

export interface QuestionOption {
  id: number
  path: Question[]
  value: string
  is_other: boolean
}

export interface Question {
  id: number
  block_id: number
  question: string
  question_description: string
  helper_text: string
  example_text: string
  sort: number
  required: boolean
  type: number
  parent_question_id: number
  options: QuestionOption[]
  merge_code: string
  path_id: number
}

export interface Answer {
  id: number
  question_id: number
  question: Question
  answer_set_id: number
  answer: string | string[]
}
export interface AnswerSet {
  id: number
  entity_id: string
  block_id: number
  name: string
  answers: Answer[]
  complete: boolean
  completion: Completion
  profile_id: number
}

export interface Completion {
  id: number
  answer_set_id: number
  answer_set_name: string
  block_id: number
  prompt_id: number
  completion: string
}

export const AWAITING_MESSAGES = [
  "I'm working on it...",
  'Thank you for your patience...',
  'Hold tight...',
  'Just a moment, please...',
  'Please bear with me...',
  'Processing your request...',
  'On it — thank you for waiting!',
  'Stay tuned...',
  'Almost there...',
  'One moment, please...',
  'Your request is being handled...',
  "Hang tight, I'm on it...",
  "I'll be right with you...",
  'Working hard for you...',
  'Almost finished...',
  'Thank you for holding...',
  'Please hold...',
  'Processing in progress...',
  "I'll be right back...",
  "After these messages, I'll be right back...",
]

export interface ObieServiceProps {
  awaitingCompletion: boolean
  blocks: Block[]
  categories: Category[]
  categoryId: number
  completion: Completion | undefined
  completions: Completion[]
  copyCompletion: (completionId: number) => Promise<Completion>
  createCompletion: (
    answerSetId: number,
    dependencies?: string,
  ) => Promise<Completion>
  createProfile: (categoryId: number, name: string) => Promise<Profile>
  deleteAnswerSet: (
    categoryId: number,
    blockId: number,
    answerSetId: number,
  ) => Promise<void>
  deleteProfile: (categoryId: number, profileId: number) => Promise<void>
  fetchBlocks: (
    categoryId: number,
    profileId: number | undefined,
  ) => Promise<void>
  fetchCompletions: () => void
  findBlock: (block_id: number) => Block | undefined
  getBlock: (category_id: number, block_id: number) => Block
  getCategory: (category_id: number) => Category
  loadingBlocks: boolean
  loadingCategories: boolean
  loadingCompletions: boolean
  profileId: number | undefined
  regenerateCompletion: (
    completionId: number,
    dependencies?: string,
    extraInstructions?: string,
  ) => Promise<Completion>
  setAwaitingCompletion: (awaitingCompletion: boolean) => void
  setCategoryId: (categoryId: number) => void
  setCompletion: (completion?: Completion | undefined) => void
  setProfileId: (profileId: number | undefined) => void
  updateAnswerSet: (
    categoryId: number,
    blockId: number,
    answerSetId: number,
    name: string,
  ) => Promise<AnswerSet>
  updateCompletion: (
    completionId: number,
    completion: string,
  ) => Promise<Completion>
}

export const OBIE_RESPONSE_SPLITER = 'ENDOBIERESPONSE'
export const OBIE_NEW_LINE = '[NEW_LINE]'
export const OBIE_OPTIONS_LIST = '[OPTIONS]'

export const ObieService = React.createContext<ObieServiceProps | undefined>(
  undefined,
)

export default function ObieServiceProvider(props: {
  children: React.ReactElement
}) {
  const fetcherBlocks = useBlocks()
  const fetcherCategories = useCategories()
  const fetcherCompletions = useCompletions()
  const createrProfile = useProfileCreate()
  const deleterProfile = useProfileDelete()
  const deleterAnswerSet = useAnswerSetDelete()
  const updaterAnswerSet = useAnswerSetUpdate()
  const updaterCompletion = useCompletionUpdate()
  const createrCompletion = useCompletionCreate()
  const copierCompletion = useCompletionCopy()
  const regeneratorCompletion = useCompletionRegenerate()

  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [categoryId, setCategoryId] = useState<number>(0)
  const [profileId, setProfileId] = useState<number | undefined>()
  const [blocks, setBlocks] = useState<Block[] | null>(null)
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
  const [loadingBlocks, setLoadingBlocks] = useState<boolean>(false)
  const [categories, setCategories] = useState<Category[] | null>(null)
  const [completion, setCompletion] = useState<Completion | undefined>()
  const [completions, setCompletions] = useState<Completion[]>([])
  const [awaitingCompletion, setAwaitingCompletion] = useState<boolean>(false)
  const [loadingCompletions, setLoadingCompletions] = useState<boolean>(false)

  const getBlock = (category_id: number, block_id: number): Block => {
    const block = (blocks || []).find(
      (b) => b.id === block_id && b.category.id === category_id,
    )

    if (!block) {
      throw new Error(`(get) Invalid Block ID: ${block_id}, ${category_id}`)
    }

    return block
  }

  const findBlock = (block_id: number): Block | undefined => {
    const block = (blocks || []).find((b) => b.id === block_id)
    if (!block) {
      return
    }
    return block
  }

  const getCategory = (category_id: number): Category => {
    const category = (categories || []).find((c) => c.id === category_id)

    if (!category) {
      throw new Error(`(get) Invalid Category ID: ${category_id}`)
    }

    return category
  }

  const fetchCategories = useCallback(() => {
    setLoadingCategories(true)

    return fetcherCategories().then((response) => {
      setCategories(response || [])
      setLoadingCategories(false)
    })
  }, [fetcherCategories, setCategories, setLoadingCategories])

  const fetchBlocks = useCallback(
    (categoryId: number, profileId: number | undefined) => {
      if (profileId === undefined) {
        return new Promise<void>((resolve) => resolve)
      }

      setLoadingBlocks(true)
      setBlocks([])

      return fetcherBlocks(categoryId, profileId).then((response) => {
        setBlocks(response || [])

        setLoadingBlocks(false)
      })
    },
    [fetcherBlocks, setBlocks, setLoadingBlocks],
  )

  const fetchCompletions = useCallback(() => {
    setLoadingCompletions(true)

    return fetcherCompletions().then((response) => {
      setCompletions(response || [])
      setLoadingCompletions(false)
    })
  }, [fetcherCompletions, setCompletions, setLoadingCompletions])

  const deleteAnswerSet = useCallback(
    (categoryId: number, blockId: number, answerSetId: number) => {
      return deleterAnswerSet(categoryId, blockId, answerSetId).then(() => {
        fetchBlocks(categoryId, profileId)
      })
    },
    [profileId, deleterAnswerSet, fetchBlocks],
  )

  const createProfile = useCallback(
    (categoryId: number, name: string) => {
      return createrProfile(categoryId, name).then((response) => {
        fetchCategories()

        return response
      })
    },
    [createrProfile, fetchCategories],
  )

  const deleteProfile = useCallback(
    (categoryId: number, profileId: number) => {
      return deleterProfile(categoryId, profileId).then((response) => {
        fetchCategories()

        return response
      })
    },
    [deleterProfile, fetchCategories],
  )

  const createCompletion = useCallback(
    (answerSetId: number, dependencies?: string) => {
      return createrCompletion(profileId, answerSetId, dependencies).then(
        (response) => {
          fetchBlocks(categoryId, profileId)
          fetchCompletions()

          return response
        },
      )
    },
    [categoryId, profileId, createrCompletion, fetchBlocks, fetchCompletions],
  )

  const updateCompletion = useCallback(
    (completionId: number, completion: string) => {
      return updaterCompletion(completionId, completion).then((response) => {
        fetchCompletions()

        return response
      })
    },
    [updaterCompletion, fetchCompletions],
  )

  const copyCompletion = useCallback(
    (completionId: number) => {
      return copierCompletion(completionId).then((response) => {
        fetchBlocks(categoryId, profileId)
        fetchCompletions()

        return response
      })
    },
    [categoryId, profileId, copierCompletion, fetchBlocks, fetchCompletions],
  )

  const regenerateCompletion = useCallback(
    (
      completionId: number,
      dependencies?: string,
      extraInstructions?: string,
    ) => {
      return regeneratorCompletion(
        completionId,
        dependencies,
        extraInstructions,
      ).then((response) => {
        fetchBlocks(categoryId, profileId)
        fetchCompletions()

        return response
      })
    },
    [
      categoryId,
      profileId,
      regeneratorCompletion,
      fetchBlocks,
      fetchCompletions,
    ],
  )

  const updateAnswerSet = useCallback(
    (
      categoryId: number,
      blockId: number,
      answerSetId: number,
      name: string,
    ) => {
      return updaterAnswerSet(categoryId, blockId, answerSetId, name).then(
        (response) => {
          return fetchBlocks(categoryId, profileId).then(() => response)
        },
      )
    },
    [profileId, updaterAnswerSet, fetchBlocks],
  )

  // Whenever blocks and categories are updated, we make sure both of them are
  // available, which tells us that we're done the initial load. We want to know
  // this so that we don't show the "Loading" page when refreshing content after
  // changes.
  useEffect(() => {
    if (categories !== null) {
      setInitialLoad(false)
    }
  }, [categories, setInitialLoad])

  // Inital load!
  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  if (initialLoad) {
    return <FullPageLoader />
  }

  return (
    <ObieService.Provider
      value={{
        awaitingCompletion,
        blocks: blocks || [],
        categories: categories || [],
        categoryId,
        completion,
        completions,
        copyCompletion,
        createCompletion,
        createProfile,
        deleteAnswerSet,
        deleteProfile,
        fetchBlocks,
        fetchCompletions,
        findBlock,
        getBlock,
        getCategory,
        loadingBlocks,
        loadingCategories,
        loadingCompletions,
        profileId,
        regenerateCompletion,
        setAwaitingCompletion,
        setCategoryId,
        setCompletion,
        setProfileId,
        updateAnswerSet,
        updateCompletion,
      }}
    >
      {props.children}
    </ObieService.Provider>
  )
}

export function useObieService() {
  const context = React.useContext(ObieService)
  if (context === undefined) {
    throw new Error(`useObieService must be used within a ObieServiceProvider`)
  }

  return context
}

export function splitText(value: string): string[] {
  return value.replaceAll(OBIE_NEW_LINE, '\n').split(OBIE_RESPONSE_SPLITER)
}

function useBlocks() {
  const {client, organization} = useOrganization()
  return useCallback(
    (categoryId: number, profileId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/blocks?profile_id=${profileId}&with=category,questions,answerSets,answerSets.answers`,
      )

      return client.get<Block[]>(url)
    },
    [client, organization.id],
  )
}

function useCategories() {
  const {client, organization} = useOrganization()
  const url = api(`/organizations/${organization.id}/obie/categories`)

  return useCallback(() => client.get<Category[]>(url), [url, client])
}

export function useProfileCreate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (categoryId: number, name: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/profiles`,
      )

      return client.post<Profile>(url, {
        name: name,
      })
    },
    [client, organization.id],
  )
}

export function useProfileDelete() {
  const {client, organization} = useOrganization()

  return useCallback(
    (categoryId: number, profileId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/profiles/${profileId}`,
      )

      return client.delete<void>(url)
    },
    [client, organization.id],
  )
}

export function useCompletions() {
  const {client, organization} = useOrganization()
  const url = api(`/organizations/${organization.id}/obie/event/completions`)

  return useCallback(() => client.get<Completion[]>(url), [client, url])
}

export function useCompletionCopy() {
  const {client, organization} = useOrganization()

  return useCallback(
    (completion_id: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completion_id}/copy`,
      )

      return client.get<Completion>(url)
    },
    [client, organization.id],
  )
}

export function useCompletionCreate() {
  const {client, organization} = useOrganization()
  const url = api(`/organizations/${organization.id}/obie/event/completions`)

  return useCallback(
    (
      profileId: number | undefined,
      answer_set_id: number,
      dependencies?: string,
    ) => {
      return client.post<Completion>(url, {
        profile_id: profileId || 0,
        answer_set_id: answer_set_id,
        dependencies: JSON.parse(dependencies || '{}'),
      })
    },
    [client, url],
  )
}

export function useCompletionUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (completionId: number, completion: string) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completionId}`,
      )
      return client.put<Completion>(url, {
        completion: completion,
      })
    },
    [client, organization.id],
  )
}

export function useCompletionRegenerate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (
      completionId: number,
      dependencies?: string,
      extraInstructions?: string,
    ) => {
      const url = api(
        `/organizations/${organization.id}/obie/event/completions/${completionId}/regenerate`,
      )
      return client.post<Completion>(url, {
        dependencies: dependencies,
        extraInstructions: extraInstructions || undefined,
      })
    },
    [client, organization.id],
  )
}

export function useAnswerSetCreate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (
      categoryId: number,
      profileId: number | undefined,
      blockId: number,
      name?: string,
    ) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/blocks/${blockId}/answer-sets`,
      )

      let data: {[key: string]: any} = {
        profile_id: profileId || 0,
      }

      if (name) {
        data.name = name
      }

      return client.post<AnswerSet>(url, data)
    },
    [client, organization],
  )
}

export function useAnswerSetDelete() {
  const {client, organization} = useOrganization()

  return useCallback(
    (categoryId: number, blockId: number, answerSetId: number) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/blocks/${blockId}/answer-sets/${answerSetId}`,
      )
      return client.delete<void>(url)
    },
    [client, organization],
  )
}

export function useAnswerSetUpdate() {
  const {client, organization} = useOrganization()

  return useCallback(
    (
      categoryId: number,
      blockId: number,
      answerSetId: number,
      name: string,
    ) => {
      const url = api(
        `/organizations/${organization.id}/obie/categories/${categoryId}/blocks/${blockId}/answer-sets/${answerSetId}`,
      )
      return client.put<AnswerSet>(url, {name: name})
    },
    [client, organization],
  )
}

/**
 * Receives a string of text to processout [OPTION]...[/OPTION] values from. The
 * option tags may or may not include a numerical index as well: [OPTION1].
 *
 * @param text string
 * @returns array
 */
export function resolveOptions(text: string) {
  const resolvedOptions: string[] = []

  // RegExp to find all the option tags in the text which could be any mixed case
  // of [OPTION]..[/OPTION]
  const regexpTagsRaw = new RegExp('\\[OPTION.*?\\]', 'gi')

  // Find all the option tag (openers) that could exist in the text, we don't know
  // how many there might be, it's out of our control.
  const optionTagsRaw = text.match(regexpTagsRaw)
  // Iterate all the tags we found, so we can lowercase everything (normalize)
  // and then filter out duplicates, we only want to have unique tags to not
  // double up later.
  const optionTags = (optionTagsRaw || [])
    .map((value) => value.toLowerCase())
    .filter((value, index, array) => array.indexOf(value) === index)

  // RegExp to remove the square brackets around potential options in the text.
  const regexpBracket = new RegExp('\\[|\\]', 'g')

  // Iterate the unique option tags so we can regular expression out the option
  // text to present to the user.
  optionTags.forEach((tag) => {
    // Clean the brackets off the tag so we can use it to compute some more
    // regular expressions.
    const optionIdentifier = tag.replace(regexpBracket, '')

    // RegExp to find this current tag's value between.
    const regexpOptionValue = new RegExp(
      `\\[${optionIdentifier}\\].*?\\[/${optionIdentifier}\\]`,
      'gi',
    )

    // Depending on how the tag is built, whether it has a numerical index or not,
    // this match will give us an array of many, or one.
    const foundOption = text.match(regexpOptionValue)

    // Nothing found for this tag (shouldn't be possible since this tag came from
    // the text, it SHOULD be found), get out of here, since we can't do any
    // more processing.
    if (!foundOption?.length) {
      return
    }

    // Iterate each foundOption so we can push the option text onto an array to
    // ultimately render to user.
    foundOption.forEach((optionValue) => {
      // Regular expression so we can replace the open and close tags, we only
      // want the text inside it.
      const regexpTag = new RegExp(`\\[/?${optionIdentifier}\\]`, 'gi')
      const clean = optionValue.replace(regexpTag, '')

      resolvedOptions.push(clean)
    })
  })

  return resolvedOptions
}
