import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {AgendaSettings} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {generateHashId} from 'lib/crypto/hash'

export function AgendaItemConfig(
  props: {
    agenda: AgendaSettings
    id?: string
  } & ComponentConfigProps,
) {
  const {showing, onClose, agenda, id} = props
  const {update: updateItem, removeItem, id: listId} = useEditSidebarItem()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: AgendaSettings) => {
    const {text, link} = updated

    const localization = {
      [`sidebar_agenda_${listId}_item_${id}_text`]: text || '',
      [`sidebar_agenda_${listId}_item_${id}_link`]: link || '',
    }

    const updatedItem = {
      ...updated,
      text: `{{sidebar_agenda_${listId}_item_${id}_text}}`,
      link: `{{sidebar_agenda_${listId}_item_${id}_link}}`,
    }

    updateItem(
      {
        items: {
          [id]: updatedItem,
        },
      },
      localization,
    )
  }

  const insert = (item: AgendaSettings) => {
    generateHashId([
      'lefty_',
      'sidebar_agenda_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      update(id, item)
    })
  }

  const save = (data: AgendaSettings) => {
    if (id === undefined) {
      insert(data)
      onClose()
      return
    }

    update(id, data)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    removeItem(id, 'items', [
      `sidebar_agenda_${listId}_item_${id}_text`,
      `sidebar_agenda_${listId}_item_${id}_link`,
    ])
    onClose()
  }, [id, onClose, removeItem, listId])

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agenda"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          agenda={agenda}
          watch={watch}
          setValue={setValue}
        />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          fullWidth
          variant="outlined"
          aria-label="remove agenda"
          onClick={remove}
          hidden={!id}
        >
          REMOVE AGENDA
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
