import DefaultSelect, {SelectProps} from './select/Select'
import DefaultOption, {OptionProps} from './select/SelectOption'
import React from 'react'
import {useCountries} from '../../lib/CountriesProvider'
import {onUnknownChangeHandler} from '../dom'

export default function CountrySelect(
  props: SelectProps & {
    Select?: React.FC<SelectProps>
    Option?: React.FC<OptionProps>
    defaultLabel?: string
  },
) {
  const {
    Select = DefaultSelect,
    Option = DefaultOption,
    defaultLabel,
    onChange,
    ...selectProps
  } = props

  const {countries} = useCountries()

  return (
    <>
      <style>
        {`
          select {
            outline: none;
            border-color: ${props.error ? '#f44336 !important' : 'inherit'};
          }
        `}
      </style>
      <Select
        aria-label="country"
        {...selectProps}
        onChange={onUnknownChangeHandler((value: any) =>
          onChange(parseInt(value)),
        )}
      >
        <Option value={0}>{defaultLabel ?? 'Country'}</Option>

        {countries.map((c) => (
          <Option value={c.id} key={c.id} selected={c.id === props.value}>
            {c.name}
          </Option>
        ))}
      </Select>
    </>
  )
}
