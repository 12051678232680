import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import Box from '@material-ui/core/Box'
import {useAttendeeVariables} from 'Event'
import {useToggleArray} from 'lib/toggle'
import {useTeamCompetition} from 'Event/business-mastery/TeamCompetition'
import {useNote} from 'lib/note'
import TextField from 'Event/business-mastery/TextField'
import styled from 'styled-components'
import SubmitButton from 'Event/business-mastery/TeamCompetition/SubmitButton'
import SuccessDialog from 'lib/ui/Dialog/SuccessDialog'

export default function DetailsForm(props: {
  details: ReturnType<typeof useTeamDetails>
}) {
  const {details} = props
  const {handleSubmit, register, watch} = useForm()
  const v = useAttendeeVariables()
  const [processing, toggleProcessing] = useToggleArray()
  const [showingSubmitted, toggleSubmitted] = useToggleArray()

  useEffect(() => {
    if (!showingSubmitted) {
      return
    }

    const timer = setTimeout(() => {
      toggleSubmitted()
    }, 2000)

    return clearTimeout(timer)
  }, [showingSubmitted, toggleSubmitted])

  const submit = ({
    name,
    leader,
    slogan,
    project_name,
    project_url,
    team_communication_link,
  }: any) => {
    if (processing) {
      return
    }

    toggleProcessing()

    Promise.all([
      details.name.save(name),
      details.leader.save(leader),
      details.slogan.save(slogan),
      details.projectName.save(project_name),
      details.projectUrl.save(project_url),
      details.teamCommunicationLink.save(team_communication_link),
    ])
      .then(() => {
        toggleSubmitted()
      })
      .finally(toggleProcessing)
  }

  if (details.loading) {
    return null
  }

  return (
    <>
      <SuccessDialog showing={showingSubmitted} onClose={toggleSubmitted}>
        {v('{{ team_info_updated }}', 'Team Information Updated!')}
      </SuccessDialog>
      <form onSubmit={handleSubmit(submit)}>
        <Box display="flex" flexDirection="column">
          <StyledTextField
            name="name"
            label={v('{{team_name}}', 'Team Name')}
            fullWidth
            defaultValue={details.name.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'name',
            }}
            required
            disabled={processing}
          />
          <StyledTextField
            name="leader"
            label={v('{{team_leader}}', 'Team Leader')}
            fullWidth
            defaultValue={details.leader.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'leader',
            }}
            disabled={processing}
          />
          <StyledTextField
            name="slogan"
            label={v('{{team_slogan}}', 'Team Slogan')}
            fullWidth
            defaultValue={details.slogan.note?.body || null}
            multiline
            rows={3}
            inputProps={{
              ref: register,
              'aria-label': 'slogan',
            }}
            disabled={processing}
            InputLabelProps={{
              shrink:
                Boolean(details.slogan.note?.body) || Boolean(watch('slogan')),
            }}
          />
          <StyledTextField
            name="project_name"
            label={v('{{project_name}}', 'Project Name')}
            fullWidth
            defaultValue={details.projectName.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'project name',
            }}
            required
            disabled={processing}
          />
          <StyledTextField
            name="project_url"
            label={v('{{project_url}}', 'Project URL')}
            fullWidth
            defaultValue={details.projectUrl.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'project url',
            }}
            disabled={processing}
          />
          <StyledTextField
            name="team_communication_link"
            label={v('{{team_communication_link}}', 'Team Communication Link')}
            fullWidth
            defaultValue={details.teamCommunicationLink.note?.body || null}
            inputProps={{
              ref: register,
              'aria-label': 'team communication link',
            }}
            disabled={processing}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <SubmitButton>
              {v('{{team_update_details_button}}', 'Update')}
            </SubmitButton>
          </Box>
        </Box>
      </form>
    </>
  )
}

export function useTeamDetails() {
  const {teamId} = useTeamCompetition()

  const notes = {
    name: useNote(`team:${teamId}:details:name`),
    leader: useNote(`team:${teamId}:details:leader`),
    slogan: useNote(`team:${teamId}:details:slogan`),
    projectName: useNote(`team:${teamId}:details.project_name`),
    projectUrl: useNote(`team:${teamId}:details.project_url`),
    teamCommunicationLink: useNote(
      `team:${teamId}:details.team_communication_link`,
    ),
  }

  const loading = Object.values(notes).filter((n) => n.loading).length > 0

  return {
    ...notes,
    loading,
  }
}

const StyledTextField = styled(TextField)`
  > label {
    font-size: 18px;
  }

  > div {
    margin-top: 18px;

    > input {
      font-size: 18px;
    }
  }
`
