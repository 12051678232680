import React, {useEffect, useState, useCallback} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import BaseSettings from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Settings'
import BaseStyling from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig/Styling/index'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {v4 as uuid} from 'uuid'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function BlogPostButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string | null
    postId: string
    onUpdate?: (id: string, button: NavButtonWithSize | typeof REMOVE) => void
  },
) {
  const {showing: isVisible, onClose, id, button, postId, onUpdate} = props

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button.rules)
  const update = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const removeTranslation = useRemoveTranslations()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(button.rules)
  }, [isVisible, button])

  const set = (id: string, button: NavButtonWithSize) => {
    if (onUpdate) {
      onUpdate(id, button)
      return
    }

    const text = replaceAtPath(button, 'text', `{{${id}_text}}`)

    update({
      blogPosts: {
        [postId]: {
          buttons: {
            [id]: button,
          },
        },
      },
      localization: addTranslation({
        [`${id}_text`]: text ?? '',
      }),
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    if (onUpdate) {
      onUpdate(id, REMOVE)
      return
    }

    update({
      blogPosts: {
        [postId]: {
          buttons: {
            [id]: REMOVE,
          },
        },
      },
      localization: removeTranslation([`${id}_text`]),
    })
  }, [update, id, postId, onUpdate, removeTranslation])

  const submit = (form: NavButtonWithSize) => {
    const data: NavButtonWithSize = {
      ...form,
      rules,
    }

    let id = props.id || uuid()

    set(id, data)
    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Blog Post Button"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <BaseSettings
          control={control}
          register={register}
          watch={watch}
          button={button}
          setValue={setValue}
          id={props.id}
        />
      </SettingsPanel>
      <StylingPanel>
        <BaseStyling control={control} button={button} watch={watch} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput control={control} setValue={setValue} values={button} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove button"
          onClick={removeButton}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
