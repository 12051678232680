import styled from 'styled-components'
import React, {ReactElement} from 'react'
import grey from '@material-ui/core/colors/grey'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {
  Service,
  ZAPIER,
  useServices,
} from 'organization/Event/Services/ServicesProvider'
import DangerButton from 'lib/ui/Button/DangerButton'
import Typography from '@material-ui/core/Typography'
import ConfirmDialog from 'lib/ui/ConfirmDialog'

export default function Card(props: {
  service: Service
  link: string
  logo: string
  color: string
  label?: string
}) {
  const {service} = props

  const isLinkable = useIsLinkable(service)

  return (
    <Box>
      <ServiceLink
        disabled={!isLinkable}
        to={props.link}
        service={props.service}
      >
        <CardContent color={props.color}>
          <Image src={props.logo} alt={`${props.service} logo`} />
        </CardContent>
      </ServiceLink>
      <Bottom>
        <BottomLeft>
          <Typography variant="h6">
            {props.label ? props.label : props.service}
          </Typography>
          <Status service={props.service} />
        </BottomLeft>
      </Bottom>
    </Box>
  )
}

function ServiceLink(props: {
  children: ReactElement
  service: Service
  disabled: boolean
  to?: string
}) {
  const {disabled, to} = props

  if (disabled || !to) {
    return <>{props.children}</>
  }

  return (
    <StyledLink to={to} disableStyles aria-label={props.service}>
      {props.children}
    </StyledLink>
  )
}

function Status(props: {service: Service}) {
  const {isLinked} = useServices()

  if (isLinked(props.service)) {
    return <UnlinkButton service={props.service} />
  }

  return <StatusText>Not Linked</StatusText>
}

function UnlinkButton(props: {service: Service}) {
  const {service} = props
  const {unlink, remove} = useServices()

  const handleClickUnlink = () => {
    unlink(service).then(() => remove(service))
  }

  return (
    <ConfirmDialog
      onConfirm={handleClickUnlink}
      confirmLabel={'Unlink'}
      description="By clicking Unlink, this service will no longer be connected to Obvio for your Event. Are you sure you would like to continue?"
    >
      {(unlink) => (
        <DangerButton
          variant="outlined"
          onClick={unlink}
          size="small"
          aria-label="unlink service"
        >
          Unlink
        </DangerButton>
      )}
    </ConfirmDialog>
  )
}

function useIsLinkable(service: Service) {
  const {integrations} = useServices()

  if (service === ZAPIER) {
    return true // always allow zapier
  }

  // if linked is zapier allow others
  const linkedIntegration = integrations.find(
    (i) => i.is_linked && i.service !== ZAPIER,
  )

  if (!linkedIntegration) {
    return true // in no linked integration allow all
  }

  // allow only linked integration
  return linkedIntegration.service === service
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const CardContent = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  height: 100%;
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`

const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(RelativeLink)`
  flex: 1;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  display: block;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

  &:hover {
    background: ${grey[200]};
  }
`

const StatusText = styled.div`
  font-size: 16px;
  color: ${grey[700]};
`

const Image = styled.img`
  max-width: 100%;
`
