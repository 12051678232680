import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function DescriptionConfig() {
  const saveTemplate = useSaveTemplate()

  const template = useTemplate()
  const {faq} = template
  const {
    form: {control},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const description = replaceAtPath(
      data,
      'faq.description',
      '{{faq_description}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        faq_description: description ?? '',
      }),
    })
  }

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <Title>Description</Title>
        <Controller
          name="faq.description"
          defaultValue={v(faq.description)}
          control={control}
          render={({onChange, value}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>
    </Config>
  )
}
