import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Settings from 'Event/EventOfflinePage/OfflineContentConfig/Settings'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function OfflineContentConfig() {
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const {
    form: {control, register},
  } = useConfig()

  const save = (data: any) => {
    const description = replaceAtPath(
      data,
      'offlinePage.description',
      '{{offline_page_description}}',
    )
    const title = replaceAtPath(
      data,
      'offlinePage.title',
      '{{offline_page_title}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        offline_page_description: description ?? '',
        offline_page_title: title ?? '',
      }),
    })
  }

  return (
    <Config title="Event Offline Page" onSave={save}>
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
    </Config>
  )
}
