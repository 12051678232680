import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {User} from 'auth/user'
import Page from 'Event/template/Panels/Page'
import LeftPanel from 'Event/template/Panels/Dashboard/LeftPanel'
import RightPanel from 'Event/template/Panels/Dashboard/RightPanel'
import MobilePanel from 'Event/template/Panels/Dashboard/MobilePanel'
import SpeakerPage from 'Event/template/Panels/Dashboard/Speakers'
import TabPanel from 'lib/ui/tabs/TabPanel'
import Home from 'Event/template/Panels/Dashboard/Home'
import Leaderboard from 'Event/template/Panels/Dashboard/Leaderboard'
import Resources from 'Event/template/Panels/Dashboard/Resources'
import {usePanelsTemplate} from 'Event/template/Panels'
import Sponsors from 'Event/template/Panels/Dashboard/Sponsors'
import ImageWaterfall from 'Event/template/Panels/Dashboard/ImageWaterfall'
import FaqPage from 'Event/template/Panels/Dashboard/FAQs'
import {useQueryParams} from 'lib/url'
import {useHistory} from 'react-router-dom'
import {TABS} from 'Event/template/Panels/Dashboard/RightPanel/Bar'
import qs from 'qs'
import AgendaListContent from 'Event/template/Panels/Dashboard/AgendaList'

export default function PanelsDashboard(props: {user: User}) {
  const [tabIndex, setTabIndex] = useState(0)
  useUrlQueryNav(tabIndex, setTabIndex)

  const setUrlTab = useSetUrlTab()

  const handleChangeTab = (newTab: number) => {
    const isCurrent = newTab === tabIndex
    if (isCurrent) {
      return
    }

    setTabIndex(newTab)
    const tab = TABS[newTab]
    setUrlTab(tab)
  }

  return (
    <Page
      Left={<LeftPanel onChangeTab={handleChangeTab} user={props.user} />}
      Right={
        <RightPanel currentTab={tabIndex} onChangeTab={handleChangeTab}>
          <Content currentTab={tabIndex} />
        </RightPanel>
      }
      Mobile={
        <MobilePanel onChangeTab={handleChangeTab} user={props.user}>
          <Content currentTab={tabIndex} />
        </MobilePanel>
      }
    />
  )
}

/**
 * Navigate the tabs depending on url params, such
 * as `tab=speakers`.
 *
 * @param tabIndex
 * @param setTabIndex
 */
function useUrlQueryNav(
  tabIndex: number,
  setTabIndex: (index: number) => void,
) {
  const {tab: value} = useQueryParams()

  // Select a tab from URL.
  useEffect(() => {
    if (!value) {
      return
    }

    const target = getTabIndex(value)
    const alreadySelected = tabIndex === target
    if (alreadySelected) {
      return
    }

    setTabIndex(target)
  }, [value, setTabIndex, tabIndex])
}

function useSetUrlTab() {
  const history = useHistory()

  return (tab: string) => {
    const query = {
      tab,
    }

    const queryString = qs.stringify(query)
    history.push({
      search: queryString,
    })
  }
}

function getTabIndex(value: string) {
  const index = TABS.indexOf(value)

  // In panels, both zoom backgrounds and resources are on resources page.
  // so '?tab=backgrounds' query param means tab=resources
  const isResourceTab = index === -1 && value === 'backgrounds'
  if (isResourceTab) {
    return TABS.indexOf('resources')
  }

  const target = index === -1 ? 0 : index

  return target
}

function Content(props: {currentTab: number}) {
  const {currentTab} = props

  return (
    <>
      <ContentPanel index={0} currentIndex={currentTab}>
        <Home />
      </ContentPanel>
      <ContentPanel index={1} currentIndex={currentTab}>
        <SpeakerPage />
      </ContentPanel>
      <ContentPanel index={2} currentIndex={currentTab}>
        <Sponsors />
      </ContentPanel>
      <ContentPanel index={3} currentIndex={currentTab}>
        <Resources />
      </ContentPanel>
      <ContentPanel index={4} currentIndex={currentTab}>
        <Leaderboard />
      </ContentPanel>
      <ContentPanel index={5} currentIndex={currentTab}>
        <ImageWaterfall />
      </ContentPanel>
      <ContentPanel index={6} currentIndex={currentTab}>
        <FaqPage />
      </ContentPanel>
      <ContentPanel index={7} currentIndex={currentTab}>
        <AgendaListContent />
      </ContentPanel>
    </>
  )
}

function ContentPanel(props: {
  index: number
  currentIndex: number
  children: React.ReactElement
}) {
  const {
    rightPanel: {textColor},
  } = usePanelsTemplate()

  return (
    <StyledTabPanel
      disablePadding
      textColor={textColor}
      index={props.index}
      currentIndex={props.currentIndex}
    >
      {props.children}
    </StyledTabPanel>
  )
}

const StyledTabPanel = styled(TabPanel)<{
  textColor: string | null
}>`
  /*
    Explicitly set color to avoid dark mode's white color from
    rendering text invisible on white background.
  */
  color: ${(props) => props.textColor}!important;
  width: 100%;
`
