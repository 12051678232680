import {useTemplate} from 'Event/TemplateProvider'
import styled from 'styled-components'
import MuiButton, {ButtonProps} from '@material-ui/core/Button'
import React from 'react'
import {spacing} from 'lib/ui/theme'
import {Column} from 'lib/ui/layout'
import {Font, useLoadFont, fontStyles} from 'lib/FontSelect'
import Grid from '@material-ui/core/Grid'

export type LoginButtonProps = {
  label?: string
  font?: Font | null
  fontSize?: number
  textColor?: string
  hoverTextColor?: string
  backgroundColor?: string
  disableHover?: boolean
  hoverBackgroundColor?: string
  height?: number
  width?: Column
  borderColor?: string
  hoverBorderColor?: string
  borderWidth?: number
  borderRadius?: number
}

export default function Button(props: ButtonProps) {
  const template = useTemplate()
  const {login} = template
  const borderRadius = `${login.submitButton.borderRadius}px` || spacing[14]
  const hoverBackgroundColor =
    login.submitButton.hoverBackgroundColor ||
    login.submitButton.backgroundColor
  useLoadFont(login.submitButton.font)

  return (
    <div>
      <StyledGrid item xs={12} md={login.submitButton.width}>
        <StyledButton
          variant="contained"
          fullWidth
          backgroundColor={login.submitButton.backgroundColor}
          color={login.submitButton.textColor}
          borderRadius={borderRadius || '0px'}
          type="submit"
          hoverBackgroundColor={hoverBackgroundColor}
          font={login.submitButton.font}
          fontSize={login.submitButton.fontSize}
          hoverTextColor={login.submitButton.hoverTextColor}
          disableHover={login.submitButton.disableHover}
          height={login.submitButton.height}
          borderColor={login.submitButton.borderColor || '#000000'}
          borderWidth={login.submitButton.borderWidth}
          hoverBorderColor={login.submitButton.hoverBorderColor || '#000000'}
          {...props}
        />
      </StyledGrid>
    </div>
  )
}

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverBackgroundColor: _4,
    font: _5,
    fontSize: _6,
    hoverTextColor: _7,
    disableHover: _8,
    height: _9,
    borderColor: _10,
    borderWidth: _11,
    hoverBorderColor: _12,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius} !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background: ${(props) => props.backgroundColor} !important;
  ${(props) => fontStyles(props.font)}
  ${(props) =>
    props.fontSize ? `font-size: ${props.fontSize}px !important;` : ''}
  transition: ${(props) =>
    props.disableHover ? 'none' : 'opacity 0.2s ease-in-out'};
  height: ${(props) => props.height}px !important;
  width: 100%;
  ${(props) =>
    props.borderWidth
      ? `border: ${props.borderWidth}px solid ${props.borderColor};`
      : ''}

  &:hover {
    background: ${(props) => props.hoverBackgroundColor} !important;
    color: ${(props) => props.hoverTextColor} !important;
    ${(props) =>
      props.hoverBorderColor
        ? `border: ${props.borderWidth}px solid ${props.hoverBorderColor};`
        : ''}
  }
`

const StyledGrid = styled(Grid)`
  margin: 0 auto;
`
