import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import {makeStyles, createStyles} from '@material-ui/core/styles'
import {colors} from 'lib/ui/theme'
import {useObieQuestions} from 'organization/Obie/ObieQuestionsProvider'

export default function CustomizedProgressBars(props: {hidden?: boolean}) {
  const {hidden} = props
  const {percentage} = useObieQuestions()

  // If we're being hidden, we still want to render the component, just have 0
  // progress. This avoids content shifting when there's not supposed to be a
  // ProgressBar present.
  const resolvedPercentage = hidden ? 0 : percentage

  const useStylesLinerProgress = makeStyles(() =>
    createStyles({
      root: {
        height: 15,
      },
      colorPrimary: {
        backgroundColor: colors.sidebar.background,
      },
      bar: {
        backgroundColor: colors.primary,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
      },
    }),
  )

  const classes = useStylesLinerProgress()

  return (
    <LinearProgress
      classes={classes}
      variant="determinate"
      value={resolvedPercentage || 0}
    />
  )
}
