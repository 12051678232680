import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import Menu from 'lib/ui/Menu'
import Icon from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import Divider from '@material-ui/core/Divider'
import MoveQaBoard from 'assets/images/icons/move-qa-board.svg'
import MenuItem from 'lib/ui/Menu/MenuItem'
import Dialog from 'lib/ui/Dialog'
import {
  DialogBox,
  Header,
  MenuLeftSide,
  NumHolder,
  QuestionAnswers,
  QuestionAttendee,
  QuestionData,
  QuestionNumber,
  QuestionLeft,
  QuestionRight,
  QuestionRow,
  QuestionsInlinePreview,
  QuestionsPreview,
  FormSubmission,
} from 'organization/Event/Form/FormModeration/Question'
import {useFormSubmissions} from 'lib/event-api/forms/use-form-submissions'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useMoveSubmission} from 'organization/Event/Form/FormModeration/QATab/use-move-submission'

export default function DeletedTab() {
  const {form} = useForm()

  const {data: submissions = []} = useFormSubmissions({
    form,
    status: 'deleted',
  })

  const moveSubmission = useMoveSubmission({form})

  const [dialogVisible, setDialogVisible] = useState(false)
  const [dialogQuestion, setDialogQuestion] = useState<FormSubmission | null>(
    null,
  )

  const toggleDialog = () => setDialogVisible(!dialogVisible)

  const showFullResponse = (questionGroup: FormSubmission) => {
    setDialogQuestion(questionGroup)
    setDialogVisible(true)
  }

  const DialogQuestion = () => {
    if (!dialogQuestion) {
      return null
    }
    return (
      <DialogBox>
        <QuestionsPreview questions={dialogQuestion.answers} />
      </DialogBox>
    )
  }

  return (
    <Grid container>
      <Header item xs={12}>
        <MenuLeftSide>
          TRASH <NumHolder>{submissions.length}</NumHolder>
        </MenuLeftSide>
      </Header>

      {submissions.map((submission, index) => {
        return (
          <QuestionRow key={index}>
            <QuestionNumber>{submission.number}</QuestionNumber>
            <QuestionData>
              <QuestionLeft>
                <QuestionAttendee>
                  {submission.attendee_first_name}{' '}
                  {submission.attendee_last_name}
                </QuestionAttendee>

                <QuestionAnswers>
                  <QuestionsInlinePreview questions={submission.answers} />
                </QuestionAnswers>
              </QuestionLeft>
              <QuestionRight>
                <Menu
                  button={({open}) => (
                    <IconButton onClick={open} aria-label="view form actions">
                      <Icon
                        className="fas fa-ellipsis-h"
                        color="black"
                        iconSize={18}
                      />
                    </IconButton>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem
                    icon={MoveQaBoard}
                    children="View Full Response "
                    onClick={() => {
                      showFullResponse(submission)
                    }}
                  />
                  <Divider />
                  <MenuItem
                    icon={MoveQaBoard}
                    children="Move to Q&A Board"
                    onClick={() => {
                      moveSubmission({
                        fromStatus: 'deleted',
                        fromIndex: index,
                        toStatus: 'incoming',
                        toIndex: 0,
                      })
                    }}
                  />
                </Menu>
              </QuestionRight>
            </QuestionData>
          </QuestionRow>
        )
      })}

      <Dialog open={dialogVisible} onClose={toggleDialog} maxWidth="xs">
        <DialogQuestion />
      </Dialog>
    </Grid>
  )
}
