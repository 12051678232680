import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useSimpleBlogUpdate} from 'Event/template/SimpleBlog'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/SimpleBlog/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function TimerConfig(
  props: ComponentConfigProps & {
    countDownTimer: CountDownTimerSettings
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, countDownTimer} = props
  const updateTemplate = useSimpleBlogUpdate()

  const {control, handleSubmit, formState} = useForm()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const update = (id: string, updated: CountDownTimerSettings) => {
    const description = replaceAtPath(
      updated,
      'description',
      `{{${id}_description}}`,
    )

    const days = replaceAtPath(updated, 'labels.days', `{{${id}_days}}`)
    const hours = replaceAtPath(updated, 'labels.hours', `{{${id}_hours}}`)
    const minutes = replaceAtPath(
      updated,
      'labels.minutes',
      `{{${id}_minutes}}`,
    )
    const seconds = replaceAtPath(
      updated,
      'labels.seconds',
      `{{${id}_seconds}}`,
    )

    updateTemplate({
      countDownTimers: {
        [id]: updated,
      },
      localization: addTranslation({
        [`${id}_description`]: description ?? '',
        [`${id}_days`]: days ?? 'Days',
        [`${id}_hours`]: hours ?? 'Hours',
        [`${id}_minutes`]: minutes ?? 'Minutes',
        [`${id}_seconds`]: seconds ?? 'Seconds',
      }),
    })
  }

  const insert = (countDownTimer: CountDownTimerSettings) => {
    generateHashId([
      'countdown',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `countdown_${id}`
      updateTemplate({
        countDownTimers: {
          [componentId]: {
            ...countDownTimer,
            description: `{{${componentId}_description}}`,
            labels: {
              days: `{{${componentId}_days}}`,
              hours: `{{${componentId}_hours}}`,
              minutes: `{{${componentId}_minutes}}`,
              seconds: `{{${componentId}_seconds}}`,
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_description`]: countDownTimer.description ?? '',
          [`${componentId}_days`]: countDownTimer.labels.days ?? 'Days',
          [`${componentId}_hours`]: countDownTimer.labels.hours ?? 'Hours',
          [`${componentId}_minutes`]:
            countDownTimer.labels.minutes ?? 'Minutes',
          [`${componentId}_seconds`]:
            countDownTimer.labels.seconds ?? 'Seconds',
        }),
      })
    })
  }

  const removeCountDownTimer = useCallback(() => {
    if (!id) {
      throw new Error('Missing count down timer id')
    }

    updateTemplate({
      countDownTimers: {
        [id]: REMOVE,
      },
      localization: removeTranslations([
        `${id}_description`,
        `${id}_days`,
        `${id}_hours`,
        `${id}_minutes`,
        `${id}_seconds`,
      ]),
    })
  }, [updateTemplate, id, removeTranslations])

  const save = (data: CountDownTimerSettings) => {
    if (id) {
      update(id, data)
    } else {
      insert(data)
    }

    onClose()
  }

  const showingRemoveButton = Boolean(id)

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Count Down Timer Config"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} countDownTimer={countDownTimer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} countDownTimer={countDownTimer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove countdown"
          onClick={removeCountDownTimer}
          showing={showingRemoveButton}
        />
      </Footer>
    </ComponentConfig>
  )
}
