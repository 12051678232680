import styled from 'styled-components'
import React from 'react'
import Grid, {GridSize} from '@material-ui/core/Grid'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {DropResult} from 'react-beautiful-dnd'
import {orderedIdsByPosition, createPositions} from 'lib/list'
import Typography from '@material-ui/core/Typography'
import BackgroundCard from 'Event/Backgrounds/BackgroundList/BackgroundCard'

export default function BackgroundList() {
  const template = useTemplate()
  const {zoomBackgrounds: settings} = template
  const {items} = settings

  const isEditMode = useEditMode()
  const ids = orderedIdsByPosition(settings.items)
  const perRow = (12 / settings.imagesPerRow) as GridSize

  const isEmpty = ids.length === 0
  if (isEmpty) {
    return (
      <Typography
        align="center"
        aria-label="no backgrounds"
        style={{
          minHeight: '200px',
        }}
      >
        No Backgrounds Available
      </Typography>
    )
  }

  const backgrounds = ids.map((id, index) => (
    <VisibleOnMatch rules={items[id].rules} key={id}>
      <Grid item xs={12} md={perRow}>
        <BackgroundCard
          key={id}
          index={index}
          id={id}
          background={items[id]}
          isLast={index === ids.length - 1}
        />
      </Grid>
    </VisibleOnMatch>
  ))

  if (!isEditMode) {
    return (
      <Grid container spacing={2}>
        {backgrounds}
      </Grid>
    )
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Available Backgrounds
      </Typography>
      <DraggableList>
        {ids.map((id, index) => (
          <VisibleOnMatch rules={items[id].rules} key={id}>
            <BackgroundRow
              key={id}
              index={index}
              id={id}
              background={items[id]}
              isLast={index === ids.length - 1}
            />
          </VisibleOnMatch>
        ))}
      </DraggableList>
    </>
  )
}

function DraggableList(props: {children: React.ReactElement[]}) {
  const handleDrag = useHandleDragBackgrounds()

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="drag-and-drop-backgrounds">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            <>
              {props.children}
              {provided.placeholder}
            </>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export function useHandleDragBackgrounds() {
  const update = useSaveTemplate()
  const {
    zoomBackgrounds: {items},
  } = useTemplate()

  return (result: DropResult) => {
    const {destination, source} = result
    if (!destination) {
      return
    }
    const ids = orderedIdsByPosition(items)

    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      zoomBackgrounds: {
        items: createPositions(ids),
      },
    })
  }
}

const Box = styled.div`
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[8]};
`

const BackgroundRow = styled(BackgroundCard)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`
