import React from 'react'
import Menu from 'lib/ui/Menu'
import Icon from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import Divider from '@material-ui/core/Divider'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import styled from 'styled-components'
import {InlinePageLoader} from 'lib/ui/layout/FullPageLoader'
import MenuItem from 'lib/ui/Menu/MenuItem'
import MoveQaBoard from 'assets/images/icons/move-qa-board.svg'
import RefreshIcon from 'assets/images/icons/refresh.svg'
import {Link} from 'react-router-dom'
import {routesWithValue} from 'lib/url'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useQueryClient} from 'react-query'
import {useForm} from 'organization/Event/Form/FormProvider'

export default function SectionMenu(props: {status: FormSubmissionStatus}) {
  const {status} = props
  const qc = useQueryClient()
  const routes = useEventRoutes()
  const {form} = useForm()

  const formRoute = () =>
    routesWithValue(':form', String(form.id), routes.forms[':form'])

  const statusOnDeckRoute = () =>
    routesWithValue(':status', status, formRoute().moderation.stage[':status'])
  const statusLowerThirdRoute = () =>
    routesWithValue(
      ':status',
      status,
      formRoute().moderation.lower_third[':status'],
    )

  const refresh = () => {
    qc.invalidateQueries(['forms', form.id, 'submissions', {status}])
  }

  return (
    <Menu
      button={({open}) => (
        <IconButton onClick={open} aria-label="view form actions">
          <Icon className="fas fa-ellipsis-h" color="black" iconSize={18} />
        </IconButton>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Link to={statusOnDeckRoute().root} target="_blank">
        <MenuItem icon={MoveQaBoard}>Stage View</MenuItem>
      </Link>
      <Divider />
      <Link to={statusLowerThirdRoute().root} target="_blank">
        <MenuItem icon={MoveQaBoard}>Lower Third</MenuItem>
      </Link>
      <MenuItem onClick={refresh} icon={RefreshIcon}>
        Refresh
      </MenuItem>
      <Divider />
    </Menu>
  )
}

export const Loader = () => {
  return (
    <LoaderHolder>
      <InlinePageLoader />
    </LoaderHolder>
  )
}

const LoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]};
  width: 100%;
`
