import React from 'react'
import {orderedIdsByPosition} from 'Event/Marketplace/lib/list'
import {ThankYouPageTemplate} from 'Event/Marketplace/thank-you-page'
import PreviewButton from 'organization/Marketplace/ThankYouPageConfig/PreviewButton'
import {useUpdateTemplate} from 'organization/Marketplace/ThankYouPageConfig/ThankYouPageTemplateUpdateProvider'
import ThankYouPageButtonConfig from 'organization/Marketplace/config/BlockConfig/ThankYouPageButtonConfig'
import ConfigPage from 'organization/Marketplace/config/ConfigPage'
import ThankYouPageTemplateSelector from 'organization/Marketplace/config/TemplateSelector/ThankYouPageTemplateSelector'

interface ContentProps {
  template: ThankYouPageTemplate
}

export default function ThankYouPageConfigContent(props: ContentProps) {
  const {template} = props
  const updateTemplate = useUpdateTemplate()

  const {sections} = template

  const sectionIds = orderedIdsByPosition(sections)
  const hasSections = sectionIds.length > 0

  if (!hasSections) {
    return <ThankYouPageTemplateSelector updateTemplate={updateTemplate} />
  }

  return (
    <ConfigPage
      template={template}
      updateTemplate={updateTemplate}
      availableBlocks={[
        'Title',
        'Text',
        'Image',
        'Video',
        'Button',
        'BulletedList',
        'NumberedList',
        'FaqList',
        'CountdownTimer',
        'Icon',
        'Separator',
        'Grid',
        'Spacer',
      ]}
      rightToolbarItems={
        <>
          <PreviewButton />
        </>
      }
      ButtonConfig={ThankYouPageButtonConfig}
    />
  )
}
