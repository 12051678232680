import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Card from 'Event/template/Panels/Dashboard/Sponsors/SponsorList/Card'
import React from 'react'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {useEditMode} from 'Event/EditModeProvider'
import {VisibleSponsor} from 'Event/Sponsors'
import {SortableItem} from 'lib/ui/SortableGrid/SortableItem'
import SortableGrid from 'lib/ui/SortableGrid'

export default function SponsorList(props: {className?: string}) {
  const {
    sponsors: {perRow, items},
  } = usePanelsTemplate()

  const isEditMode = useEditMode()
  const ids = orderedIdsByPosition(items)
  const isEmpty = ids.length === 0

  if (isEmpty) {
    return <Typography align="center">No sponsor have been added</Typography>
  }

  const sponsors = ids.map((id, index) => (
    <VisibleSponsor key={id} sponsor={items[id]}>
      <Card key={id} id={id} index={index} sponsor={items[id]} />
    </VisibleSponsor>
  ))

  if (!isEditMode) {
    return <Box perRow={perRow}>{sponsors}</Box>
  }

  return <Content ids={ids} {...props} />
}

function Content(props: {ids: string[]; className?: string}) {
  const {
    sponsors: {perRow, items},
  } = usePanelsTemplate()
  const {ids} = props

  const update = usePanelsUpdate()

  const handleSort = (oldIndex: number, newIndex: number) => {
    if (oldIndex !== newIndex) {
      const moved = Array.from(ids)
      const [removed] = moved.splice(oldIndex, 1)
      moved.splice(newIndex, 0, removed)

      update({sponsors: {items: createPositions(moved)}})
    }
  }

  return (
    <SortableGrid onDrag={handleSort} ids={ids}>
      <Box className={props.className} perRow={perRow}>
        {ids.map((item, index) => (
          <SortableItem id={item} key={item}>
            <Card key={item} id={item} sponsor={items[item]} index={index} />
          </SortableItem>
        ))}
      </Box>
    </SortableGrid>
  )
}

const Box = styled.div<{perRow: number}>`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;

  grid-gap: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(${(props) => props.perRow}, 1fr);
  }
`
