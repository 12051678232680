import {FieldProps, InputStyles, useSavedValue} from 'Event/Question'
import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import {Controller} from 'react-hook-form'
import {onUnknownChangeHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'
import styled from 'styled-components'
import {
  HasOptions,
  Question,
  QuestionBase,
} from 'organization/Event/QuestionsProvider'
import {rgba} from 'lib/color'
import {useTemplate} from 'Event/TemplateProvider'
import {useLoadFont} from 'lib/FontSelect'
import {makeStyles} from '@material-ui/core/styles'

export const SELECT = 'select'

export type SelectQuestion = QuestionBase &
  HasOptions & {
    type: typeof SELECT
    allows_multiple_options: boolean
  }

export type SelectProps = FieldProps & {
  question: SelectQuestion
}

export default function Select(props: SelectProps) {
  useSavedValue(props)
  const v = useAttendeeVariables()
  const {inputStyles: questionFormStyle} = props

  return (
    <FormControl fullWidth error={props.hasError} disabled={props.disabled}>
      <StyledInputLabel color={questionFormStyle?.labelColor}>
        {v(props.question.label)}
      </StyledInputLabel>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue=""
        rules={{
          required:
            props.question.is_required &&
            (props.requiredFieldMessage ?? 'This field is required'),
        }}
        render={({onChange, value}) => (
          <SelectInput {...props} value={value} onChange={onChange} />
        )}
      />
      {props.HelperText}
    </FormControl>
  )
}

export function SelectInput(props: {
  question: SelectQuestion
  value: string
  onChange: (value: string) => void
  inputStyles?: InputStyles
}) {
  const {question, value, onChange, inputStyles} = props
  const {allows_multiple_options: allowsMultiple} = question
  const {font} = useTemplate()
  useLoadFont(font)

  const v = useAttendeeVariables()
  /**
   * MUI Select blows up if you give it an incorrect
   * type. Rather than crash the app, we'll just
   * clear out the value with the correct type.
   *
   * @param value
   * @returns
   */
  const dynamicType = (value: string[] | string) => {
    const defaultValue = allowsMultiple ? [] : ''

    if (!value) {
      return defaultValue
    }

    // Convert back to array
    if (allowsMultiple && !Array.isArray(value)) {
      return value.split(', ')
    }

    return value
  }

  const backgroundColor = rgba(
    inputStyles?.backgroundColor || '#ffffff',
    (inputStyles?.backgroundOpacity || 0) / 100,
  )

  const popoverStyles = usePopoverStyles()

  const fontFamily = font ? font.family : 'inherit'

  return (
    <StyledSelect
      fullWidth
      value={dynamicType(value)}
      onChange={onUnknownChangeHandler((val: string | string[]) => {
        if (!Array.isArray(val)) {
          onChange(val)
          return
        }

        onChange(val.join(', '))
      })}
      multiple={allowsMultiple}
      inputProps={{
        'aria-label': props.question.label,
      }}
      color={inputStyles?.textColor}
      borderColor={inputStyles?.borderColor}
      backgroundColor={backgroundColor}
      MenuProps={{
        PaperProps: {
          style: {
            fontFamily,
          },
          className: popoverStyles.root,
        },
      }}
    >
      {props.question.options.map(({value}, index) => (
        <MenuItem key={index} value={value}>
          {v(value)}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export function isSelectQuestion(
  question?: Question,
): question is SelectQuestion {
  if (!question) {
    return false
  }

  return question.type === SELECT
}

const StyledInputLabel = styled((props) => {
  const {color: _, ...otherProps} = props

  return <InputLabel {...otherProps} />
})`
  color: ${(props) => (props.color ? `${props.color} !important;` : '')};
  z-index: 1;
  pointer-events: none;
  font-family: inherit;
`

const StyledSelect = styled((props) => {
  const {color: _, borderColor: __, backgroundColor: ___, ...otherProps} = props

  return <MuiSelect {...otherProps} />
})`
  color: ${(props) => (props.color ? `${props.color} !important;` : '')};
  background-color: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor} !important;` : ''};
  /* default */
  &:before {
    border-bottom: 2px solid ${(props) => props.borderColor} !important;
  }
  /* hover */
  &:hover:before {
    border-bottom: 2px solid ${(props) => props.borderColor} !important;
  }
  /* focused */
  &:after {
    border-bottom: 2px solid ${(props) => props.borderColor} !important;
  }
`

const usePopoverStyles = makeStyles({
  root: {
    '& *': {
      fontFamily: 'inherit !important',
    },
  },
})
