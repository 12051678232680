import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {Box, Container, Typography} from '@material-ui/core'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useForm} from 'organization/Event/Form/FormProvider'
import Page from 'organization/Event/Page'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import QuestionStatistics from './QuestionStatistics'
import SingleMetric from './SingleMetric'
import {useFormStatistics} from 'lib/event-api/forms/use-form-statistics'
import InfoAlert from 'lib/ui/alerts/InfoAlert'
import {useOrganization} from 'organization/OrganizationProvider'
import {Form} from 'organization/Event/FormsProvider'
import {api} from 'lib/url'

export interface QuestionStatisticsCount {
  id: string | number
  answer_total: number
  answers: {
    [key: string]: {
      submission_count: number
    }
  } | null
  submission_count: number
}

export default function FormStatistics() {
  const {form, responseError} = useForm()
  const {data: statistics} = useFormStatistics({form})

  usePopulateFormStatistics()

  return (
    <Content>
      <>
        <PageHeader>
          <Title text="Form Statistics" />
        </PageHeader>
        <ErrorAlert>{responseError?.message}</ErrorAlert>
        {form.needs_to_populate_statistics && (
          <InfoAlert>
            Form statistics are currently being calculated, and results may
            change.
          </InfoAlert>
        )}
        <Typography variant="h5">{form.name}</Typography>
        <Container style={{display: 'flex', padding: 0, marginTop: 36}}>
          <Box width="100%">
            <SingleMetric
              name="Total Submissions"
              count={statistics?.total_submission_count ?? 0}
            />
          </Box>
          <Box width="100%" ml={4}>
            <SingleMetric
              name="Unique Attendee Submissions"
              count={statistics?.unique_attendee_submission_count ?? 0}
            />
          </Box>
        </Container>
        {form.questions.length > 0 && (
          <Container style={{padding: 0}}>
            {form.questions.map((question) => (
              <QuestionStatistics key={question.id} question={question} />
            ))}
          </Container>
        )}
      </>
    </Content>
  )
}

function usePopulateFormStatistics() {
  const {client} = useOrganization()
  const {form, update} = useForm()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (processing) {
      return
    }
    if (!form.needs_to_populate_statistics) {
      return
    }

    if (form.populate_statistics_status !== 'not_populated') {
      return
    }

    setProcessing(true)

    client
      .put<Form>(api(`/forms/${form.id}/statistics/populate`))
      .then((form) => update(form))
      .catch(() => {
        // ignore errors in case it's already being populated
      })
  }, [form, client, update, processing])
}

function Content(props: {children: JSX.Element}) {
  const routes = useEventRoutes()
  const {form} = useForm()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Forms',
        url: routes.forms.root,
      }}
      page={form.name}
    >
      <Page>{props.children}</Page>
    </PageBreadcrumbs>
  )
}
