import {hasFeatureToggle} from 'lib/FeatureToggle'
import {LEAP} from 'obvio/Billing/plans'
import {useOwner} from 'organization/OwnerProvider'
import {OBIE_ACCESS, usePermissions} from 'organization/PermissionsProvider'
import {useOwnerHasPlan} from 'organization/auth/IfOwnerPlan'

export function useCanAccessObie() {
  const {owner} = useOwner()
  const {can} = usePermissions()

  const hasObie = hasFeatureToggle(owner, 'obie')

  const isLeap = useOwnerHasPlan(LEAP)
  const hasPermission = can(OBIE_ACCESS)

  const ownerCanAccessObie = hasObie || isLeap

  return ownerCanAccessObie && hasPermission
}
