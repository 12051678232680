import {BlockComponentProps} from '../../Block'
import {VideoBlock} from '.'
import VideoContainer from './VideoContainer'
import VideoElement from './VideoElement'
import React, {useEffect, useState} from 'react'

export default function HtmlVideo(props: VideoBlock & BlockComponentProps) {
  const {html: embedCode} = props
  const [htmlCode, setHtmlCode] = useState(embedCode || '')

  useEffect(() => {
    if (!embedCode) {
      return
    }
    const txt = document.createElement('textarea')
    txt.innerHTML = embedCode
    setHtmlCode(txt.value)
  }, [embedCode])

  return (
    <VideoContainer {...props}>
      {(size) => (
        <VideoElement
          {...props}
          width={size.width}
          height={size.height}
          aria-label="video-container"
          htmlCode={htmlCode}
        />
      )}
    </VideoContainer>
  )
}
