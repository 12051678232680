import JsonUpdateProvider, {JsonSave} from 'lib/JsonUpdateProvider'
import {useCertificate} from 'lib/event-api/certificates/use-certificate'
import {useUpdateCertificate} from 'lib/event-api/certificates/use-update-certificate'
import {Certificate} from 'lib/event-api/certificates/types'
import TemplateEditor from 'organization/Event/TemplateEditor'
import React from 'react'
import {useParams} from 'react-router-dom'
import Content from 'organization/Event/Certificates/CertificateEditor/Content'

const TemplateUpdateContext = React.createContext<
  JsonSave<Certificate['template']> | undefined
>(undefined)

export default function CertificateEditor() {
  const {certificate: routeId} = useParams<{certificate: string}>()

  const id = parseInt(routeId)
  const {data: certificate} = useCertificate({id})

  if (!certificate) {
    return null
  }

  return <Editor certificate={certificate} />
}

interface EditorProps {
  certificate: Certificate
}

function Editor(props: EditorProps) {
  const {certificate} = props
  const {mutateAsync, isLoading} = useUpdateCertificate({certificate})

  const setPdf = (file: File) => {
    mutateAsync({
      source_pdf: file,
    })
  }

  return (
    <JsonUpdateProvider
      Context={TemplateUpdateContext}
      updatedSocketEventName={`.certificate.${certificate.id}.updated`}
      value={certificate.template ?? {blocks: {}}}
      save={(updates) =>
        mutateAsync({
          template: updates,
        })
      }
    >
      {(template) => (
        <TemplateEditor template={template}>
          {(template) => (
            <Content
              template={template}
              certificate={certificate}
              isSaving={isLoading}
              onChangePdf={setPdf}
            />
          )}
        </TemplateEditor>
      )}
    </JsonUpdateProvider>
  )
}

export function useUpdateTemplate() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateTemplate must be used within TemplateUpdateProvider.',
    )
  }

  return context
}
