import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import Switch from 'lib/ui/form/Switch'
import {useConfigPage} from 'organization/Marketplace/config/ConfigPageProvider'

export default function ToggleMobileModeSwitch() {
  const {setMobileMode, isMobileMode} = useConfigPage()

  return (
    <>
      <MobileSwitch
        checked
        onChange={() => {}}
        labelPlacement="end"
        label="Mobile"
        disabled
      />
      <DesktopSwitch
        checked={isMobileMode}
        onChange={onChangeCheckedHandler(setMobileMode)}
        aria-label="toggle mobile mode"
        labelPlacement="end"
        label="Mobile"
      />
    </>
  )
}

const DesktopSwitch = styled(Switch)`
  margin-bottom: 0;
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

const MobileSwitch = styled(Switch)`
  display: block;
  margin-bottom: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`
