import {Font, getFontStyles} from '../../lib/font'
import React from 'react'

interface PurchaseFormTitleProps {
  title?: {
    enabled?: boolean
    text?: string
    font?: Font | null
    fontSize?: number
    margin?: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
}

export default function PurchaseFormTitle(props: PurchaseFormTitleProps) {
  if (!props.title?.enabled) {
    return null
  }

  return (
    <h5
      style={{
        ...getFontStyles(props.title?.font ?? null),
        fontSize: props.title?.fontSize ?? '36px',
        marginTop: `${props.title?.margin?.top ?? 0}px`,
        marginRight: `${props.title?.margin?.right ?? 0}px`,
        marginBottom: `${props.title?.margin?.bottom ?? 16}px`,
        marginLeft: `${props.title?.margin?.left ?? 0}px`,
      }}
    >
      {props.title?.text ?? 'Your Order'}
    </h5>
  )
}
