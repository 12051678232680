import React, {useEffect, useState, useCallback} from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import SidebarNavButtonConfigBase from 'Event/Dashboard/components/Sidebar/SidebarNavButtonConfig'
import {usePruneAssets} from 'lib/asset'
import {SidebarNavProps} from 'Event/template'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'

export function SidebarNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
    nav: SidebarNavProps
    navId: string
  },
) {
  const {button, id, showing, onClose, navId} = props

  const [rules, setRules] = useState(button.rules)
  const pruneAssets = usePruneAssets()
  const saveTemplate = useSaveTemplate()
  const addTranslations = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(button.rules)
  }, [button, props.id, showing])

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image

    pruneAssets({image}, button)

    const text = replaceAtPath(updated, 'text', `{{${id}_text}}`)

    saveTemplate({
      sidebarItems: {
        [navId]: {
          buttons: {
            [id]: updated,
          },
        },
      },
      localization: addTranslations({
        [`${id}_text`]: text ?? '',
      }),
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing id')
    }

    onClose()

    saveTemplate({
      sidebarItems: {
        [navId]: {
          buttons: {
            [id]: REMOVE,
          },
        },
      },
      localization: removeTranslations([`${id}_text`]),
    })
  }, [id, navId, onClose, removeTranslations, saveTemplate])

  const insert = (button: NavButtonWithSize) => {
    generateHashId([
      'simple_blog_sidebar_nav_button',
      Math.random().toString(),
      Date.now().toString(),
    ]).then((id) => {
      const componentId = `sidebar_button_${id}`

      saveTemplate({
        sidebarItems: {
          [navId]: {
            buttons: {
              [componentId]: {
                ...button,
                text: `{{${componentId}_text}}`,
              },
            },
          },
        },
        localization: addTranslations({
          [`${componentId}_text`]: button.text,
        }),
      })
    })
  }

  const save = (formData: any) => {
    const button: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, button)
      onClose()
      return
    }

    insert(button)
    onClose()
  }

  return (
    <SidebarNavButtonConfigBase
      button={button}
      id={id}
      watch={watch}
      control={control}
      register={register}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      handleSubmit={handleSubmit}
      save={save}
      showing={showing}
      onClose={onClose}
      formState={formState}
    />
  )
}
