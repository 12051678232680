import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';
import CustomButtonLinkFormView from './custombuttonlinkformview';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import textAlternativeIcon from '../theme/link.svg';
import { repositionContextualBalloon, getBalloonPositionData, getSelectedCustomButtonWidget } from '../utils';
import { Editor } from '@ckeditor/ckeditor5-core';
import View from '@ckeditor/ckeditor5-engine/src/view/view';
import Document from '@ckeditor/ckeditor5-engine/src/view/document';
import { LabeledFieldView } from '@ckeditor/ckeditor5-ui';

// Custom type for LabeledInputTextView
type LabeledInputTextView = LabeledFieldView & {
    fieldView: {
        value: string;
        element: HTMLInputElement;
        select(): void;
    };
}

export default class CustomButtonLinkUI extends Plugin {
    private _balloon!: ContextualBalloon;
    public formView!: CustomButtonLinkFormView;

    static get requires() {
        return [ContextualBalloon];
    }

    static get pluginName() {
        return 'CustomButtonLinkUI';
    }

    init() {
        this._createButton();
        this.formView = this._createForm();
    }

    override destroy() {
        super.destroy();
        this.formView.destroy();
    }

    private _createButton() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add('customButtonLink', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: t('Button Link'),
                icon: textAlternativeIcon,
                tooltip: true
            });

            this.listenTo(view, 'execute', () => {
                this._showForm();
            });

            return view;
        });
    }

    private _createForm() {
        const editor = this.editor as Editor;
        const view = editor.editing.view as View;
        const viewDocument = view.document as Document;

        this._balloon = this.editor.plugins.get(ContextualBalloon) as ContextualBalloon;

        const formView = new CustomButtonLinkFormView(editor.locale);

        this.listenTo(formView, 'submit', () => {
            editor.execute('customButtonLink', {
                newValue: (formView.labeledInput.fieldView.element as HTMLInputElement)!.value
            });

            this._hideForm(true);
        });

        this.listenTo(formView, 'cancel', () => {
            this._hideForm(true);
        });

        formView.keystrokes.set('Esc', (data, cancel) => {
            this._hideForm(true);
            cancel();
        });

        this.listenTo(editor.ui, 'update', () => {
            if (!getSelectedCustomButtonWidget(viewDocument.selection)) {
                this._hideForm(true);
            } else if (this._isVisible) {
                repositionContextualBalloon(editor);
            }
        });

        clickOutsideHandler({
            emitter: formView,
            activator: () => this._isVisible,
            contextElements: [this._balloon.view.element!],
            callback: () => this._hideForm()
        });

        return formView;
    }

    private _showForm() {
        if (this._isVisible) {
            return;
        }

        const editor = this.editor as Editor;
        const command = editor.commands.get('customButtonLink')!;
        const labeledInput = this.formView.labeledInput;

        if (!this._isInBalloon) {
            this._balloon.add({
                view: this.formView,
                position: getBalloonPositionData(editor)
            });
        }
		const fieldView: LabeledInputTextView['fieldView'] = labeledInput.fieldView as LabeledInputTextView['fieldView'];

        fieldView.value = fieldView.element!.value = (command.value as string) || 'https://';

        fieldView.select();
    }

    private _hideForm(focusEditable?: boolean) {
        if (!this._isInBalloon) {
            return;
        }

        if (this.formView.focusTracker.isFocused) {
            this.formView.saveButtonView.focus();
        }

        this._balloon.remove(this.formView);

        if (focusEditable) {
            this.editor.editing.view.focus();
        }
    }

    private get _isVisible() {
        return this._balloon.visibleView === this.formView;
    }

    private get _isInBalloon() {
        return this._balloon.hasView(this.formView);
    }
}
