import React, {useRef} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {useToggleArray} from 'lib/toggle'
import Sidebar from 'organization/Obie/Sidebar'
import MobileSidebar from 'organization/Obie/Sidebar/MobileSidebar'
import {HideLiveChatSupport} from 'lib/WithLiveChatSupport'

type LayoutContextProps = {
  sidebarExpanded: boolean
  toggleSidebar: () => void
  showingMobileSidebar: boolean
  toggleMobileSidebar: () => void
  contentRef: React.RefObject<HTMLDivElement>
}

export const LayoutContext = React.createContext<
  LayoutContextProps | undefined
>(undefined)

export default function Layout(props: {
  children: JSX.Element | JSX.Element[]
  hideSidebar: boolean
  hideLinks?: boolean
}) {
  const {children} = props
  const [sidebarExpanded, toggleSidebar] = useToggleArray(true)
  const [showingMobileSidebar, toggleMobileSidebar] = useToggleArray(false)
  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <HideLiveChatSupport>
      <FullPageStyles />
      <LayoutContext.Provider
        value={{
          sidebarExpanded,
          toggleSidebar,
          showingMobileSidebar,
          toggleMobileSidebar,
          contentRef,
        }}
      >
        <Content hideSidebar={props.hideSidebar} hideLinks={props.hideLinks}>
          {children}
        </Content>
      </LayoutContext.Provider>
    </HideLiveChatSupport>
  )
}

function Content(props: {
  children: JSX.Element | JSX.Element[]
  hideSidebar: boolean
  hideLinks?: boolean
}) {
  const {contentRef} = useLayout()

  if (props.hideSidebar) {
    return (
      <Bottom>
        <Main ref={contentRef} isDark>
          <div>{props.children}</div>
        </Main>
      </Bottom>
    )
  }

  return (
    <Bottom>
      <Sidebar hideLinks={props.hideLinks} />
      <MobileSidebar />
      <Main ref={contentRef}>
        <div>{props.children}</div>
      </Main>
    </Bottom>
  )
}

export function useLayout() {
  const context = React.useContext(LayoutContext)
  if (context === undefined) {
    throw new Error('useLayout must be used within Layout.')
  }

  return context
}

const Bottom = styled.div`
  display: flex;
  flex: 1;
`

const Main = styled.div<{isDark?: boolean}>`
  flex: 1;
  background: ${(props) =>
    props.isDark ? props.theme.colors.sidebar.background : '#eeeeee'};

  position: relative;
  overflow: auto;
  scroll-behavior: smooth;
  height: 100vh;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #333; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the thumb */
    border: 3px solid #333;
  }
`

const FullPageStyles = createGlobalStyle`
  html, body, #root {
    min-height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`
