import {BlockComponentProps} from '../../Block'
import HtmlVideo from './HtmlVideo'
import UrlVideo from './UrlVideo'
import {BlockBase} from '../../Block/base'
import React from 'react'

export type VideoAspectRatio = '16:9' | '9:16' | '1:1' | 'custom'

export interface VideoBlock extends BlockBase {
  type: 'Video'
  url: string | null
  html: string | null
  borderWidth: number
  borderRadius: number
  borderColor: string
  aspectRatio: {
    value: VideoAspectRatio
    customWidthRatio: number
    customHeightRatio: number
  }
}

export default function Video(props: VideoBlock & BlockComponentProps) {
  return <VideoComponent {...props} />
}

function VideoComponent(props: VideoBlock & BlockComponentProps) {
  const {html: embedCode} = props

  if (embedCode) {
    return <HtmlVideo {...props} />
  }

  return <UrlVideo {...props} />
}
