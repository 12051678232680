import React from 'react'
import {useLeftyTemplate} from 'Event/template/Lefty'
import {Speaker} from 'Event/Speakers'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'
import Button from 'lib/ui/Button'
import {useEditMode} from 'Event/EditModeProvider'

export default function AddFeaturedSpeakerButton() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const {featuredSpeakers} = useLeftyTemplate()
  const save = useSaveTemplate()

  const add = () => {
    const numSpeakers = Object.keys(featuredSpeakers.items).length
    const position = numSpeakers + 1

    const newSpeaker: Speaker = {
      name: 'Speaker',
      text: 'Speaker bio',
      image: null,
      position,
    }

    generateHashId([
      'townhall_',
      'feature_speaker',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      save({
        featuredSpeakers: {
          items: {
            [id]: newSpeaker,
          },
        },
      })
    })
  }

  return (
    <StyledButton
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add featured speaker"
      onClick={add}
    >
      Add Featured Speaker
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => `${props.theme.spacing[8]} 0`}!important;
`
