import {Action} from 'Event/ActionsProvider'
import {useEvent} from 'Event/EventProvider'
import {Question} from 'organization/Event/QuestionsProvider'
import React from 'react'
import {Tag as InfusionsoftTag} from 'organization/Event/Services/Apps/Infusionsoft'
import {Tag as ZapierTag} from 'organization/Event/Services/Apps/Zapier'
import {Tag as ActiveCampaignTag} from 'organization/Event/Services/Apps/ActiveCampaign'
import {Tag as MailchimpTag} from 'organization/Event/Services/Apps/Mailchimp'
import {HubspotPlatformTag as HubspotTag} from 'organization/Event/Services/Apps/Hubspot'
import {HighLevelTag} from 'Event/highlevel'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'

type ZapierFormTag = Pick<ZapierTag, 'name'>

type HighLevelFormTag = Pick<HighLevelTag, 'name'>

type MailchimpFormTag = Pick<MailchimpTag, 'name'>

type ActiveCampaignFormTag = Pick<
  ActiveCampaignTag,
  'name' | 'active_campaign_id'
>

type InfusionsoftFormTag = Pick<InfusionsoftTag, 'infusionsoft_id' | 'name'>

type HubspotFormTag = Pick<
  HubspotTag,
  'property_label' | 'property_name' | 'property_type' | 'property_value'
>

export interface Form {
  id: number
  name: string
  questions: Question[]
  can_resubmit: boolean
  submission_webhook_url: string | null
  on_submit_redirect_url: string | null
  action: Action | null
  submit_label: string
  submitted_message: string
  field_required_message: string
  resubmit_button_label: string
  allows_multiple_submissions: boolean
  populate_statistics_status: 'not_populated' | 'processing' | 'done'
  needs_to_populate_statistics: boolean
  tags: {
    activecampaign?: ActiveCampaignFormTag
    zapier?: ZapierFormTag
    mailchimp?: MailchimpFormTag
    infusionsoft?: InfusionsoftFormTag
    hubspot?: HubspotFormTag
    highlevel?: HighLevelFormTag
    convertkit?: ConvertKitTag
    ontraport?: OntraportTag
  } | null
  access_token: string
}

export interface FormsContextProps {
  forms: Form[]
  add: (form: Form) => void
  update: (form: Form) => void
  remove: (form: Form) => void
}

export const FormsContext = React.createContext<FormsContextProps | undefined>(
  undefined,
)

export default function FormsProvider(props: {children: React.ReactElement}) {
  const {event, set: updateEvent} = useEvent()

  const sortedForms = event.forms.sort((a, b) => a.name.localeCompare(b.name))

  const setForms = (forms: Form[]) => {
    updateEvent({
      ...event,
      forms,
    })
  }

  const add = (form: Form) => {
    const added = [...event.forms, form]
    setForms(added)
  }

  const update = (target: Form) => {
    const updated = event.forms.map((f) => {
      const isTarget = f.id === target.id
      if (isTarget) {
        return target
      }

      return f
    })

    setForms(updated)
  }

  const remove = (target: Form) => {
    const remaining = event.forms.filter((f) => f.id !== target.id)
    setForms(remaining)
  }

  return (
    <FormsContext.Provider value={{forms: sortedForms, add, update, remove}}>
      {props.children}
    </FormsContext.Provider>
  )
}

export function useForms() {
  const context = React.useContext(FormsContext)
  if (context === undefined) {
    throw new Error(`useForms must be used within a FormsProvider`)
  }

  return context
}
