import React, {useCallback, useEffect, useState} from 'react'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {Area} from 'organization/Event/AreasProvider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import {onUnknownChangeHandler} from 'lib/dom'
import RoomControls from 'Event/Dashboard/TeamAccessControls/RoomControls'
import {Room} from 'Event/room'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {useAttendee} from 'Event/auth'
import Loop from '@material-ui/icons/Loop'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import {useInterval} from 'lib/interval'

/**
 * Access Tags
 */
export const TEAM_ACCESS_TAG = 'Team'
export const TEAM_ACCESS_COHOST_TAG = 'TeamCoHost'
export const TEAM_ACCESS_HOST_TAG = 'TeamHost'

const REFRESH_ROOM_INTERVAL_MS = 30000

interface TeamAccessControlProps {
  Container?: React.FC<any>
}

export type RoomDetails = Room & {
  area: Area
}

export default function TeamAccessControls(props: TeamAccessControlProps) {
  const {Container} = props
  const {tags} = useAttendeeProfile()

  const hasAccess = tags.includes(TEAM_ACCESS_TAG)

  if (!hasAccess) {
    return null
  }

  if (Container) {
    return (
      <Container>
        <Content />
      </Container>
    )
  }

  return <Content />
}

function Content() {
  const [selectedRoomId, setSelectedRoomId] = useState<number | null>(null)

  const user = useAttendee()
  const areas = useAreas()

  const {room, fetch, refresh: refreshRoom, loading} = useRoomDetails()

  useEffect(() => {
    if (!selectedRoomId) {
      return
    }

    fetch(selectedRoomId)
  }, [selectedRoomId, fetch])

  return (
    <Box>
      <RefreshButtonBox>
        <Button
          variant="text"
          size="small"
          color="dark"
          aria-label="refresh room"
          onClick={refreshRoom}
        >
          <Loop />
        </Button>
      </RefreshButtonBox>
      <Title>TEAM ACCESS for {user.first_name}</Title>
      <Select
        displayEmpty
        inputProps={{
          'aria-label': 'Select Zoom Room',
        }}
        fullWidth={true}
        onChange={onUnknownChangeHandler(setSelectedRoomId)}
        value={selectedRoomId || ''}
      >
        <MenuItem value="" disabled>
          Select Room
        </MenuItem>
        {areas.flatMap(areaMenuItems)}
      </Select>
      <SelectedRoom room={room} loading={loading} />
    </Box>
  )
}

function SelectedRoom(props: {room: RoomDetails | null; loading: boolean}) {
  const {loading, room} = props
  if (loading) {
    return <FullPageLoader />
  }

  if (!room) {
    return null
  }

  return <RoomControls room={room} />
}

function areaMenuItems(area: Area) {
  return [
    <StyledListSubheader key={`area header ${area.id}`}>
      {area.name}
    </StyledListSubheader>,
    ...area.rooms.map((r) => (
      <MenuItem key={r.id} value={r.id} aria-label={`select ${r.id} panel`}>
        {r.number} {r.description}
      </MenuItem>
    )),
  ]
}

export function useAreas() {
  const {client, event} = useEvent()

  const [areas, setAreas] = useState<Area[]>([])

  const url = api(`/events/${event.id}/areas`)

  useEffect(() => {
    client.get<Area[]>(url).then(setAreas)
  }, [client, url])

  return areas
}

export function useRoomDetails() {
  const {client} = useEvent()

  const [room, setRoom] = useState<RoomDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetch = useCallback(
    (selectedRoom: number | null) => {
      setLoading(true)

      const url = api(`/rooms/${selectedRoom}`)
      client
        .get<RoomDetails>(url)
        .then(setRoom)
        .finally(() => {
          setLoading(false)
        })
    },
    [client],
  )

  const refresh = useCallback(() => {
    if (!room) {
      return
    }

    fetch(room.id)
  }, [room, fetch])

  useInterval(refresh, REFRESH_ROOM_INTERVAL_MS)

  return {room, fetch, loading, refresh}
}

const Box = styled.div`
  border: 2px solid red;
  border-radius: 30px;
  padding: ${(props) => props.theme.spacing[5]};
  margin-bottom: 20px;
`

const RefreshButtonBox = styled.div`
  display: inline;
`

const Title = styled.h3`
  text-align: center;
  margin-top: 0;
`

const StyledListSubheader = styled(ListSubheader)`
  font-weight: bold;
  background: #ffffff;
  border-top: 1px solid #a0a9b0;
  border-bottom: 1px solid #a0a9b0;
  font-size: 16px;
`
