import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React, {useEffect, useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import NavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/ActionButtonConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/ActionButtonConfig/Styling'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {generateHashId} from 'lib/crypto/hash'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
  disablePageSelect?: boolean
}

export default function ActionButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, button} = props

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button.rules)
  const {
    actionNav: {buttons},
  } = useTownhallTemplate()

  const set = useTownhallUpdate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(button.rules)
  }, [isVisible, button, props.id])

  const update = (id: string, updated: NavButtonWithSize) => {
    const {text: translatedText, ...otherUpdates} = updated
    set({
      localization: addTranslation({
        [`${id}_text`]: translatedText,
      }),
      actionNav: {
        buttons: {
          [id]: otherUpdates,
        },
      },
    })
  }

  const insert = (button: NavButtonWithSize) => {
    const numButtons = Object.keys(buttons).length
    const position = numButtons + 1

    generateHashId([
      'townhall_',
      'action_nav_buttons',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `button_${id}`

      set({
        localization: addTranslation({
          [`${componentId}_text`]: button.text,
        }),
        actionNav: {
          buttons: {
            [componentId]: {
              ...button,
              text: `{{${componentId}_text}}`,
              position,
            },
          },
        },
      })
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    set({
      localization: removeTranslations([`${id}_text`]),
      actionNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
    })
  }, [set, id, removeTranslations])

  const save = (formData: any) => {
    const data: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (!id) {
      insert(formData)
    } else {
      update(id, data)
    }

    onClose()
  }

  return (
    <NavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      handleSubmit={handleSubmit}
      save={save}
      formState={formState}
      isVisible={isVisible}
      showing={Boolean(id)}
      title="Action Button"
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}
