import {ObvioEvent} from 'Event'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {EventWithPurchasePage} from 'lib/marketplace-api/purchase-page/use-purchase-page-events'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'
import {marketplaceApi} from 'lib/marketplace-api/url'

export function useMarketplacePurchasePageSampleEvents() {
  const {client} = useOrganization()

  return useQuery({
    queryKey: ['events', 'purchase_page_samples'],
    queryFn: () =>
      new Promise<EventWithPurchasePage[]>(async (resolve) => {
        const events = await client.get<Array<ObvioEvent>>(
          api('/events/purchase_page_samples'),
        )

        const purchasePages = events.map(async (event) => {
          const purchasePage = await client.get<PurchasePage | null>(
            marketplaceApi(`/events/${event.id}/purchase_page`),
          )

          if (!purchasePage?.template) {
            return null
          }

          const hasSections = Boolean(purchasePage.template.sections)
          if (!hasSections) {
            return null
          }

          return {
            ...event,
            purchasePageTemplate: purchasePage.template,
          }
        })

        const result = (await Promise.all(purchasePages)).filter(
          (purchasePage) => purchasePage,
        ) as EventWithPurchasePage[]

        resolve(result)
      }),
  })
}
