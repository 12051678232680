import React from 'react'
import SponsorList from 'Event/template/Panels/Dashboard/Sponsors/SponsorList'
import {useAttendeeVariables, useGuestVariables} from 'Event'
import {PageTitle, PageDescription} from 'Event/template/Panels/Page'
import {usePanelsTemplate} from 'Event/template/Panels'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Sponsors/HeaderConfig'
import AddSponsorButton from 'Event/Sponsors/AddSponsorButton'
import Scripts, {SPONSORS as SCRIPTS_SPONSORS} from 'Event/Scripts'
import PagePoints, {
  SPONSORS as POINTS_SPONSORS,
} from 'Event/PointsProvider/PagePoints'
import {useEditMode} from 'Event/EditModeProvider'

export default function Sponsors() {
  const {sponsors: sponsorsPageSettings} = usePanelsTemplate()
  const isEditMode = useEditMode()
  const v = useGuestVariables()

  if (isEditMode) {
    return <ConfigurableSponsors />
  }

  return (
    <PagePoints page={POINTS_SPONSORS}>
      <>
        <Scripts page={SCRIPTS_SPONSORS} />
        <PageTitle aria-label="sponsors title">
          {v(sponsorsPageSettings.title)}
        </PageTitle>
        <PageDescription aria-label="sponsors description">
          <Content>{v(sponsorsPageSettings.description)}</Content>
        </PageDescription>
        <SponsorList />
      </>
    </PagePoints>
  )
}

function ConfigurableSponsors() {
  const {sponsors: sponsorsPageSettings} = usePanelsTemplate()
  const v = useAttendeeVariables()

  return (
    <PagePoints page={POINTS_SPONSORS}>
      <>
        <Scripts page={SCRIPTS_SPONSORS} />
        <Configurable>
          <HeaderConfig />
          <PageTitle aria-label="sponsors title">
            {v(sponsorsPageSettings.title)}
          </PageTitle>
        </Configurable>
        <PageDescription aria-label="sponsors description">
          <Content>{v(sponsorsPageSettings.description)}</Content>
        </PageDescription>
        <SponsorList />
        <AddSponsorButton />
      </>
    </PagePoints>
  )
}
