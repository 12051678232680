import React from 'react'
import Container from '../../Block/Container'
import {BlockComponentProps} from '../index'
import {Font} from '../../lib/font'
import CustomerDetailFields from './CustomerDetailFields'
import BillingaddressFields from './BillingAddressFields'
import ShippingAddressFields from './ShippingAddressFields'
import OrderConfirmation from './OrderConfirmation'
import {usePurchaseForm} from './PurchaseFormProvider'
import PurchaseFormTextField from './PurchaseFormTextField'
import PurchaseFormButton from './PurchaseFormButton'
import {BlockBase} from '../base'
import {Column} from '../../lib/layout'
import PurchaseFormStripe from '../../PurchaseFormStripe'
import {usePurchase} from '../../PurchaseContext'
import FreePurchaseFormProvider from './FreePurchaseFormProvider'
import StripePurchaseFormProvider from './StripePurchaseFormProvider'
import PurchaseButton from './PurchaseButton'
import PurchaseFormTitle from './PurchaseFormTitle'

export const ADDRESS_TYPE_BILLING = 'billing'
export const ADDRESS_TYPE_SHIPPING = 'shipping'
export type AddressType =
  | typeof ADDRESS_TYPE_BILLING
  | typeof ADDRESS_TYPE_SHIPPING

export interface AttendeeCollectionProps {
  active: boolean
  backClick?: () => void
  config: PurchaseFormBlock
  continueClick: () => void
  disabled?: boolean
}

export interface PurchaseFormProps extends PurchaseFormBlock {
  TitleComponent?: React.FC<any>
  TextFieldComponent?: React.FC<any>
  ButtonComponent?: React.FC<any>
  CustomerDetailsSectionLabelComponent?: React.FC<any>
  BillingFieldsLabelComponent?: React.FC<any>
  ShippingFieldsLabelComponent?: React.FC<any>
  PurchaseSummaryLabelComponent?: React.FC<any>
  TotalAmountLabelComponent?: React.FC<any>
  ItemLabelComponent?: React.FC<any>
  CardFieldsLabelComponent?: React.FC<any>
  stripeAccountId?: string
}

export type PlaceholderType =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'billingAddress1'
  | 'billingAddress2'
  | 'city'
  | 'state'
  | 'zipcode'
  | 'country'

export interface PurchaseFormBlock extends BlockBase {
  type: 'PurchaseForm'
  isHidden: boolean
  title?: {
    enabled: boolean
    text: string
    font: Font | null
    fontSize?: number
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  input: {
    textColor: string
    fontSize: number
    backgroundColor: string
    spacing: number
    border: {
      width: number
      radius: number
      color: string
    }
    padding: {
      left: number
      right: number
      top: number
      bottom: number
    }
    placeholder: {
      firstName: string
      lastName: string
      email: string
      phone: string
      billingAddress1: string
      billingAddress2: string
      city: string
      state: string
      zipcode: string
      country: string
      shippingAddress1: string
      shippingAddress2: string
      shippingcity: string
      shippingstate: string
      shippingzipcode: string
      shippingcountry: string
    }
  }
  purchaseButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
    margin?: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  buttonStyle: {
    size: Column
    font: Font | null
    fontSize?: number
    backgroundColor?: string
    hoverBackgroundColor?: string
    textColor?: string
    hoverTextColor?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
    hoverBorderColor?: string
    height?: number
    width?: number
    disabledBackgroundColor?: string
    disabledTextColor?: string
  }
  buttonsAlignment: 'flex-start' | 'center' | 'flex-end'
  requirePhoneNumber: boolean
  errors: {
    requiredField: string
  }
  questions: {
    sameShippingAddress: string
  }
  sectionLabel?: {
    font: Font | null
    fontSize?: number
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  summaryColumnLabel?: {
    font: Font | null
    fontSize?: number
    border: {
      width: number
      color: string
    }
  }
  customerDetailsLabel?: string
  billingSectionLabel?: string
  shippingSectionLabel?: string
  purchaseSummaryLabel?: string
  totalAmountLabel?: string
  itemLabel?: string
  cardFieldsLabel?: string
}

export default function PurchaseForm(
  props: PurchaseFormProps & BlockComponentProps,
) {
  const {stripeAccountId, stripePublicKey} = usePurchase()

  const accountId = props.stripeAccountId ?? stripeAccountId

  if (!accountId) {
    return (
      <FreePurchaseFormProvider config={props}>
        <Content {...props} />
      </FreePurchaseFormProvider>
    )
  }

  return (
    <PurchaseFormStripe
      stripeAccountId={accountId}
      stripePublicKey={stripePublicKey}
    >
      <StripePurchaseFormProvider config={props}>
        <Content {...props} />
      </StripePurchaseFormProvider>
    </PurchaseFormStripe>
  )
}

function Content(props: PurchaseFormProps & BlockComponentProps) {
  const {
    TitleComponent = PurchaseFormTitle,
    TextFieldComponent = PurchaseFormTextField,
    ButtonComponent = PurchaseFormButton,
    CustomerDetailsSectionLabelComponent,
    BillingFieldsLabelComponent,
    ShippingFieldsLabelComponent,
    PurchaseSummaryLabelComponent,
    TotalAmountLabelComponent,
    CardFieldsLabelComponent,
    ItemLabelComponent,
  } = props
  const {hasError} = usePurchaseForm()
  const {isProcessing, selectedTicket} = usePurchase()

  return (
    <Container {...props}>
      <div className="block-content" style={{width: '100%'}}>
        <TitleComponent {...props} />
        <CustomerDetailFields
          TextFieldComponent={TextFieldComponent}
          CustomerDetailsSectionLabelComponent={
            CustomerDetailsSectionLabelComponent
          }
          {...props}
        />

        {selectedTicket?.billing_address_enabled && (
          <BillingaddressFields
            TextFieldComponent={TextFieldComponent}
            BillingFieldsLabelComponent={BillingFieldsLabelComponent}
            {...props}
          />
        )}

        {selectedTicket?.shipping_address_enabled && (
          <ShippingAddressFields
            {...props}
            TextFieldComponent={TextFieldComponent}
            ShippingFieldsLabelComponent={ShippingFieldsLabelComponent}
          />
        )}
        {selectedTicket && selectedTicket.price > 0 && (
          <OrderConfirmation
            {...props}
            PurchaseSummaryLabelComponent={PurchaseSummaryLabelComponent}
            TotalAmountLabelComponent={TotalAmountLabelComponent}
            ItemLabelComponent={ItemLabelComponent}
            CardFieldsLabelComponent={CardFieldsLabelComponent}
          />
        )}
        <ErrorBox showError={hasError} errors={props.errors} />
        <PurchaseButton
          {...props}
          ButtonComponent={ButtonComponent}
          disabled={isProcessing || !selectedTicket}
        />
      </div>
    </Container>
  )
}

export function ErrorBox(props: {
  showError: boolean
  errors: {
    requiredField: string
  }
}) {
  const {showError, errors} = props
  if (!showError) {
    return null
  }

  return (
    <div
      style={{
        marginBottom: '12px',
        color: '#f44336',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
      }}
    >
      {errors.requiredField}
    </div>
  )
}
