import {ObvioEvent} from 'Event'
import {ThankYouPage} from 'Event/Marketplace/thank-you-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

export function useThankYouPage(params: {event: ObvioEvent}) {
  const {event} = params

  return useQuery({
    queryKey: ['events', event.id, 'thank_you_page'],
    queryFn: () =>
      client.get<ThankYouPage | null>(
        marketplaceApi(`/events/${event.id}/thank_you_page`),
      ),
  })
}
