import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {Template} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function DescriptionConfig() {
  const save = useSaveTemplate()
  const {setPasswordForm} = useTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const handleSave: TemplateSave<Template> = (data) => {
    const label = replaceAtPath(
      data,
      `setPasswordForm.description`,
      `{{set_password_form_description}}`,
    )

    save({
      ...data,
      localization: addTranslation({
        set_password_form_description: label ?? '',
      }),
    })
  }

  const {
    form: {control},
  } = useConfig()

  return (
    <Config title="Description" onSave={handleSave}>
      <SettingsPanel>
        <TextEditorContainer>
          <Controller
            name="setPasswordForm.description"
            defaultValue={v(setPasswordForm.description)}
            control={control}
            render={({value, onChange}) => (
              <TextEditor
                data={value}
                onChange={onChange}
                aria-label="password create description"
              />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}
