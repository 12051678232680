import React from 'react'
import {ButtonBase as Button} from '../Button/ButtonBase'
import {Body} from '../Button/Content'
import styles from './PurchaseForm.module.css'
import {Column} from '../../lib/layout'
import {Font} from '../../lib/font'
import {BlockComponentProps} from '../../Block'

export interface PurchaseFormButtonProps extends BlockComponentProps {
  id: string
  text: string
  disabled?: boolean
  onClick?: () => void
  buttonStyle: {
    size: Column
    font: Font | null
    fontSize?: number
    backgroundColor?: string
    hoverBackgroundColor?: string
    textColor?: string
    hoverTextColor?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
    hoverBorderColor?: string
    height?: number
    width?: number
    disabledBackgroundColor?: string
    disabledTextColor?: string
  }
  purchaseButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
    margin?: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  buttonsAlignment: 'flex-start' | 'center' | 'flex-end'
  'aria-label'?: string
  type?: 'submit' | 'button' | 'reset'
}

export default function PurchaseFormButton(props: PurchaseFormButtonProps) {
  const {disabled, onClick, type, text, buttonStyle, purchaseButton, id} = props

  const buttonId = `purchase-form-button-${id}`
  const buttonClassName = styles.purchaseFormButton

  const bodyProps = {
    ...purchaseButton,
    ...buttonStyle,
    text: text,
  }

  const customStyles = {
    ...buttonStyle,
    height: '100%',
    minHeight: buttonStyle.height,
  }

  return (
    <div
      style={{
        marginTop: `${purchaseButton.margin?.top ?? 32}px`,
        marginRight: `${purchaseButton.margin?.right ?? 0}px`,
        marginBottom: `${purchaseButton.margin?.bottom ?? 0}px`,
        marginLeft: `${purchaseButton.margin?.left ?? 0}px`,
        flex: 1,
      }}
    >
      <Button
        {...customStyles}
        disabled={disabled}
        onClick={onClick}
        type={type}
        id={buttonId}
        className={buttonClassName}
      >
        <Body
          background={{
            image: '',
            color: '',
            opacity: 1,
          }}
          border={{
            width: 0,
            color: '',
            radius: 0,
          }}
          alignment={{
            vertical: 'center',
            horizontal: 'center',
          }}
          visibility={'all'}
          id={''}
          template={props.template}
          {...bodyProps}
          type="Button"
        />
      </Button>
    </div>
  )
}
