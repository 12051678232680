import React from 'react'
import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {BlockBase} from '../base'
import CKEditorContent from '../../lib/ui/CKEditorContent'

export interface TextBlock extends BlockBase {
  type: 'Text'
  text: string
}

export default function Text(props: TextBlock & BlockComponentProps) {
  const {text} = props
  return (
    <Container {...props}>
      <CKEditorContent
        style={{
          width: '100%',
        }}
        className="block-content"
      >
        {text}
      </CKEditorContent>
    </Container>
  )
}
