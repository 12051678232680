import {TeamMember} from 'auth/user'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import TeamMemberOnly from 'organization/auth/TeamMemberOnly'
import React, {useCallback, useEffect, useState} from 'react'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useObvioAuth, useTeamMemberEcho} from 'obvio/auth'
import {PlanInfo, getPlan} from 'obvio/Billing/plans'
import {usePrivateChannel} from 'lib/sockets/private-channel'

export interface OwnerContextProps {
  owner: TeamMember
  setOwner: (owner: TeamMember | null) => void
  plan: PlanInfo | null
}

export const OwnerContext = React.createContext<OwnerContextProps | undefined>(
  undefined,
)

export default function OwnerProvider(props: {children: React.ReactNode}) {
  const [owner, setOwner] = useState<TeamMember | null>(null)
  const [loading, setLoading] = useState(true)
  useLiveOwnerUpdates(owner, setOwner)

  const {
    organization: {id},
    client,
  } = useOrganization()

  const fetch = useCallback(() => {
    const url = api(`/organizations/${id}/owner`)
    return client.get<TeamMember>(url)
  }, [id, client])

  useEffect(() => {
    fetch()
      .then((owner) => {
        setOwner(owner)
      })
      .catch(() => {
        // ignore errors since we'll just assume they're unauthenticated
      })
      .finally(() => {
        setLoading(false)
      })
  }, [fetch])

  if (loading) {
    return <FullPageLoader />
  }

  if (!owner) {
    return <TeamMemberOnly />
  }

  const plan = owner.plan ? getPlan(owner.plan.name) : null

  return (
    <OwnerContext.Provider value={{owner, setOwner, plan}}>
      {props.children}
    </OwnerContext.Provider>
  )
}

function useLiveOwnerUpdates(
  owner: TeamMember | null,
  setOwner: (teamMember: TeamMember | null) => void,
) {
  const echo = useTeamMemberEcho()

  const channelName = owner ? `team_member.${owner.id}` : null
  const {socketId, channel, connected} = usePrivateChannel({
    echo,
    name: channelName,
  })

  // Listen to owner updates
  useEffect(() => {
    if (!channel || !socketId) {
      return
    }

    channel.listen('.updated', setOwner)

    return () => {
      channel.stopListening('.updated')
    }
  }, [channel, socketId, setOwner])

  const connecting = channelName ? connected === false : false
  return {connecting}
}

export function useOwner() {
  const context = React.useContext(OwnerContext)
  if (context === undefined) {
    throw new Error(`useOwner must be used within a OwnerProvider`)
  }

  return context
}

export function useIsOwner() {
  const {owner} = useOwner()
  const {user} = useObvioAuth()

  if (!user || !owner) {
    return false
  }

  return user.email === owner.email
}
