import React, {useEffect, useState, useCallback} from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import SidebarNavButtonConfigBase from 'Event/Dashboard/components/Sidebar/SidebarNavButtonConfig'
import {usePruneAssets} from 'lib/asset'
import {SidebarNavProps} from 'Event/template'
import {generateHashId} from 'lib/crypto/hash'

export function SidebarNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
    nav: SidebarNavProps
  },
) {
  const {button, id, showing, onClose} = props

  const [rules, setRules] = useState(button.rules)
  const {update: updateItem, id: listId, removeItem} = useEditSidebarItem()
  const pruneAssets = usePruneAssets()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(button.rules)
  }, [button, props.id, showing])

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image

    pruneAssets({image}, button)
    const {text} = updated
    const localization = {
      [`sidebar_nav_button_${listId}_button_${id}_text`]: text || '',
    }

    updateItem(
      {
        buttons: {
          [id]: {
            ...updated,
            text: `{{sidebar_nav_button_${listId}_button_${id}_text}}`,
          },
        },
      },
      localization,
    )
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing id')
    }
    removeItem(id, 'buttons', [
      `sidebar_nav_button_${listId}_button_${id}_text`,
    ])

    onClose()
  }, [id, onClose, removeItem, listId])

  const insert = (button: NavButtonWithSize) => {
    generateHashId([
      'lefty_',
      'sidebar_nav_button_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      update(id, button)
    })
  }

  const save = (formData: any) => {
    const button: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, button)
      onClose()
      return
    }

    insert(button)
    onClose()
  }

  return (
    <SidebarNavButtonConfigBase
      button={button}
      id={id}
      watch={watch}
      control={control}
      register={register}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      handleSubmit={handleSubmit}
      save={save}
      showing={showing}
      onClose={onClose}
      formState={formState}
    />
  )
}
