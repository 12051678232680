import React, {useState} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useEvent} from 'Event/EventProvider'
import {Attendee} from 'Event/attendee'
import {useAttendees} from 'organization/Event/AttendeesProvider'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useIfMounted} from 'lib/dom'

export default function RemoveAttendeeButton(props: {
  attendee: Attendee
  onError: (error: string | null) => void
}) {
  const [loading, setLoading] = useState(false)
  const {client} = useOrganization()
  const {event} = useEvent()
  const {remove} = useAttendees()
  const ifMounted = useIfMounted()
  const handleRemoveAttendee = () => {
    const url = api(`/events/${event.id}/attendees/${props.attendee.id}`)

    setLoading(true)
    client
      .delete(url)
      .then(() => {
        remove(props.attendee)
      })
      .catch(props.onError)
      .finally(() => ifMounted(() => setLoading(false)))
  }

  return (
    <ConfirmDialog
      description="This will remove attendee."
      onConfirm={handleRemoveAttendee}
    >
      {(confirm) => (
        <Tooltip title="Remove Attendee">
          <IconButton
            color="primary"
            aria-label="remove attendee"
            disabled={loading}
            onClick={confirm}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </ConfirmDialog>
  )
}
