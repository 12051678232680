import TextField from 'lib/ui/TextField'
import {NumberField} from 'lib/ui/TextField'
import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import {isTextQuestion} from 'organization/Event/QuestionsProvider'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function CharacterLimitInputs(props: QuestionTypeConfigProps) {
  const {question, control} = props

  return (
    <>
      <Controller
        name="character_limit"
        control={control}
        defaultValue={isTextQuestion(question) ? question.character_limit : ''}
        render={({value, onChange}) => (
          <NumberField
            aria-label="character limit"
            fullWidth
            onChange={onChange}
            value={value}
            label="Character Limit"
          />
        )}
      />
      <Controller
        name="character_limit_error_message"
        control={control}
        defaultValue={
          isTextQuestion(question) ? question.character_limit_error_message : ''
        }
        render={({value, onChange}) => (
          <TextField
            aria-label="limit error message"
            fullWidth
            onChange={onChange}
            value={value}
            label="Limit Error Message"
          />
        )}
      />
    </>
  )
}
