import ConfirmDialog from 'lib/ui/ConfirmDialog'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {communicationsApi} from 'lib/url'
import {teamMemberClient} from 'obvio/obvio-client'
import {
  Broadcast,
  useBroadcasts,
} from 'organization/Event/Broadcasts/BroadcastsProvider'
import React from 'react'
import {isPast, useCurrentTime} from 'lib/date-time'

export default function DeleteMenuItem(props: {broadcast: Broadcast}) {
  const {broadcast} = props
  const remove = useRemoveBroadcast(broadcast)

  const list = useBroadcasts()

  const handleDeleteBroadcast = () =>
    remove().then(() => list.remove(broadcast))

  const now = useCurrentTime()
  const isPastBroadcast = isPast(broadcast.send_at, now)

  if (isPastBroadcast) {
    return null
  }

  return (
    <ConfirmDialog
      onConfirm={handleDeleteBroadcast}
      description="Deleting a Broadcast cannot be undone."
    >
      {(confirm) => <MenuItem onClick={confirm}>Delete</MenuItem>}
    </ConfirmDialog>
  )
}

function useRemoveBroadcast(broadcast: Broadcast) {
  const url = communicationsApi(`/broadcasts/${broadcast.id}`)
  return () => teamMemberClient.delete(url)
}
