import {Sponsor} from 'Event/Sponsors'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import Box from 'lib/ui/Box'
import FormControl from 'lib/ui/FormControl'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import {useTemplate} from 'Event/TemplateProvider'
import Slider from 'lib/ui/Slider'
import Visible from 'lib/ui/layout/Visible'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Configurable from 'organization/Event/Configurable'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'

export type SponsorConfigProps = {
  id: string
  sponsor: Sponsor
  stylingPanel?: JSX.Element
  settingsPanel?: JSX.Element
}

export default function SponsorConfig(props: SponsorConfigProps) {
  const {
    sponsor,
    id,
    stylingPanel,
    settingsPanel = <DefaultSettingsPanel id={id} sponsor={sponsor} />,
  } = props
  const set = useSaveTemplate()
  const [rules, setRules] = useState(sponsor.rules)
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const handleSave = (data: any) => {
    const name = replaceAtPath(
      data,
      `sponsors.items.${id}.name`,
      `{{${id}_name}}`,
    )
    const description = replaceAtPath(
      data,
      `sponsors.items.${id}.description`,
      `{{${id}_description}}`,
    )

    const saveData = {
      sponsors: {
        ...data.sponsors,
        items: {
          [id]: {
            ...data.sponsors.items[id],
            rules,
          },
        },
        questionIcon: data.sponsors.questionIcon,
      },
      localization: addTranslation({
        [`${id}_name`]: name ?? '',
        [`${id}_description`]: description ?? '',
      }),
    }

    set(saveData)
  }

  const handleRemove = () => {
    set({
      sponsors: {
        items: {
          [props.id]: REMOVE,
        },
      },
      localization: removeTranslations([`${id}_name`, `${id}_description`]),
    })
  }

  return (
    <Config title="Sponsor" onSave={handleSave} onRemove={handleRemove}>
      <SettingsPanel>{settingsPanel}</SettingsPanel>
      {stylingPanel ? <StylingPanel>{stylingPanel}</StylingPanel> : <></>}
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

export function DefaultSettingsPanel(props: {id: string; sponsor: Sponsor}) {
  const {sponsor, id} = props
  const {
    form: {control, register},
  } = useConfig()
  const {sponsors} = useTemplate()

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name={`sponsors.items.${id}.isActive`}
        control={control}
        defaultValue={sponsor?.isActive ?? true}
        render={({onChange, value}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <TextField
        name={`sponsors.items.${id}.name`}
        label="Name"
        required
        fullWidth
        inputProps={{
          ref: register,
          'aria-label': 'sponsor name',
        }}
        defaultValue={v(sponsor.name)}
      />
      <InputLabel>Description</InputLabel>
      <Controller
        name={`sponsors.items.${id}.description`}
        defaultValue={v(sponsor.description || '')}
        control={control}
        render={({onChange, value}) => (
          <TextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </TextEditorContainer>
        )}
      />

      <Controller
        name={`sponsors.items.${id}.image`}
        control={control}
        defaultValue={sponsor.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Logo"
            uploadInputProps={{
              'aria-label': 'sponsor logo upload',
            }}
            width={300}
            height={100}
            canResize
            disableAutoRemove
          />
        )}
      />

      <Visible when={'imageSize' in sponsors}>
        <Controller
          name="sponsors.imageSize"
          defaultValue={'imageSize' in sponsors ? sponsors.imageSize : 1}
          control={control}
          render={({onChange, value}) => (
            <Slider
              min={1}
              hideUnits
              max={11}
              step={1}
              value={value}
              onChange={onChange}
              valueLabelDisplay="auto"
              aria-label="sponsor image size"
              label="Logo Width"
            />
          )}
        />
      </Visible>

      <InputLabel>Form</InputLabel>
      <Controller
        name={`sponsors.items.${id}.formId`}
        control={control}
        defaultValue={sponsor.formId}
        render={({onChange, value}) => (
          <FormControl fullWidth>
            <FormSelect value={value} onChange={onChange} />
          </FormControl>
        )}
      />

      <Box mb={2}>
        <InputLabel>Question Icon</InputLabel>
        <Controller
          name="sponsors.questionIcon"
          control={control}
          defaultValue={sponsors.questionIcon}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              canResize
              removeButtonProps={{
                'aria-label': 'remove sponsor question icon',
              }}
              uploadInputProps={{
                'aria-label': 'upload sponsor question icon',
              }}
              disableAutoRemove
            />
          )}
        />
      </Box>
    </>
  )
}

export function SponsorConfigurable(props: {
  children: [JSX.Element, JSX.Element]
}) {
  return <Configurable aria-label="edit sponsor">{props.children}</Configurable>
}
