import React, {useState} from 'react'

interface ConfigPageProviderProps {
  children: JSX.Element
}

interface ConfigPageContextProps {
  isMobileMode: boolean
  setMobileMode: (isMobileMode: boolean) => void
}

const ConfigPageContext = React.createContext<
  ConfigPageContextProps | undefined
>(undefined)

export default function ConfigPageProvider(props: ConfigPageProviderProps) {
  const {children} = props

  const [isMobileMode, setMobileMode] = useState(false)

  return (
    <ConfigPageContext.Provider
      value={{
        isMobileMode,
        setMobileMode,
      }}
    >
      {children}
    </ConfigPageContext.Provider>
  )
}

export function useConfigPage() {
  const context = React.useContext(ConfigPageContext)
  if (context === undefined) {
    throw new Error('useConfigPage must be used within a ConfigPageProvider')
  }

  return context
}
