import DialogContent from '@material-ui/core/DialogContent'
import Button, {ButtonProps} from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useEvent} from 'Event/EventProvider'
import {Question} from 'Event/Question'
import Dialog from 'lib/ui/Dialog'
import {Form} from 'organization/Event/FormsProvider'
import React from 'react'
import {DEFAULT_MODAL_BUTTON_TEXT} from 'Event/Dashboard/components/BlogPosts/BlogPostConfig'
import Box from '@material-ui/core/Box'
import {useAttendeeVariables} from 'Event'
import {BlogPost, FORM} from 'Event/Dashboard/components/BlogPosts'
import styled from 'styled-components'
import {
  DEFAULT_RESUBMIT_LABEL,
  DEFAULT_SUBMITTED_MESSAGE,
  DEFAULT_SUBMIT_LABEL,
} from 'organization/Event/Form'
import {useTemplate} from 'Event/TemplateProvider'
import {useSubmitForm} from 'Event/form'
import {useCheckQuestionRules} from 'Event/Question/use-is-visible-question'

export default function PostForm(props: {post: BlogPost}) {
  const {post} = props
  const {event} = useEvent()

  const form = event.forms.find((f) => f.id === post.formId)
  if (!form) {
    return null
  }

  return <Content form={form} post={post} />
}

function Content(props: {form: Form; post: BlogPost}) {
  const {form, post} = props
  const v = useAttendeeVariables()

  const {postFormStyles: formStyles} = useTemplate()

  const {
    openDialog,
    dialogVisible,
    resubmit,
    isEditing,
    alreadySubmitted,
    submit,
    answers,
    responseError,
    submitting,
    closeDialog,
    register,
    handleSubmit,
    errors: formErrors,
    control,
    setValue,
    watch,
  } = useSubmitForm(form, {
    showInDialog: post.isModalForm,
  })

  const checkQuestionRules = useCheckQuestionRules({watch, answers})

  const showing = post.attachment === FORM

  if (!showing) {
    return null
  }

  const body = (
    <Container justifyContent={formStyles.position}>
      <StyledForm
        onSubmit={handleSubmit(submit)}
        width={formStyles.width}
        onClick={(e) => e.stopPropagation()}
      >
        {form.questions
          .filter((question) => checkQuestionRules(question))
          .map((question, index) => (
            <Question
              formErrors={formErrors}
              key={question.id}
              index={index}
              control={control}
              question={question}
              answers={answers}
              register={register}
              responseError={responseError}
              setValue={setValue}
              disabled={submitting}
              inputStyles={formStyles.inputStyles}
              watch={watch}
            />
          ))}
        <Container justifyContent={formStyles.buttonPosition}>
          <FormButton disabled={submitting}>
            {v(form.submit_label || DEFAULT_SUBMIT_LABEL)}
          </FormButton>
        </Container>
      </StyledForm>
    </Container>
  )

  const submittedMessage = <SubmittedMessage resubmit={resubmit} form={form} />

  const canSubmitAgain = alreadySubmitted && !isEditing

  const content = canSubmitAgain ? submittedMessage : body

  if (post.isModalForm) {
    return (
      <PostFormDialog
        open={dialogVisible}
        onClose={closeDialog}
        onOpen={openDialog}
        form={form}
        post={post}
      >
        {content}
      </PostFormDialog>
    )
  }

  return content
}

function PostFormDialog(props: {
  children: JSX.Element
  open: boolean
  onClose: () => void
  onOpen: () => void
  form: Form
  post: BlogPost
}) {
  const {open, onClose, form, children, onOpen, post} = props
  const v = useAttendeeVariables()
  const {postFormStyles: formStyles} = useTemplate()

  const button = (
    <Container
      justifyContent={formStyles.buttonPosition}
      onClick={(e) => e.stopPropagation()}
    >
      <FormButton onClick={onOpen}>
        {v(post.modalButtonText) || DEFAULT_MODAL_BUTTON_TEXT}
      </FormButton>
    </Container>
  )

  // To prevent dialog content flash, we'll just NOT return the dialog
  // at all when it's hidden.
  if (!open) {
    return button
  }

  return (
    <>
      <Dialog open onClose={onClose}>
        <DialogTitle>{form.name}</DialogTitle>
        <DialogContent>
          <Box pb={2}>{children}</Box>
        </DialogContent>
      </Dialog>
      {button}
    </>
  )
}

function SubmittedMessage(props: {resubmit: () => void; form: Form}) {
  const v = useAttendeeVariables()

  const {postFormStyles: formStyles, postStyles} = useTemplate()

  if (!props.form.can_resubmit) {
    return (
      <FormSubmittedMessage textColor={postStyles.contentTextColor}>
        {v(props.form.submitted_message || DEFAULT_SUBMITTED_MESSAGE)}
      </FormSubmittedMessage>
    )
  }

  return (
    <div>
      <FormSubmittedMessage textColor={postStyles.contentTextColor}>
        {v(props.form.submitted_message || DEFAULT_SUBMITTED_MESSAGE)}
      </FormSubmittedMessage>
      <Container justifyContent={formStyles.buttonPosition}>
        <FormButton variant="text" onClick={props.resubmit}>
          {v(props.form.resubmit_button_label || DEFAULT_RESUBMIT_LABEL)}
        </FormButton>
      </Container>
    </div>
  )
}

const StyledForm = styled.form<{
  width: number
}>`
  width: ${(props) => props.width}%;
`

function FormButton(props: ButtonProps) {
  const {postFormStyles: formStyles} = useTemplate()

  return (
    <StyledButtonn
      type="submit"
      variant="outlined"
      color={formStyles.buttonColor}
      backgroundColor={formStyles.buttonBackgroundColor}
      backgroundHoverColor={formStyles.buttonHoverBackgroundColor}
      raidus={formStyles.buttonRadius}
      width={formStyles.buttonSize}
      borderWidth={formStyles.buttonBorderWidth}
      borderColor={formStyles.buttonBorderColor}
      fontSize={formStyles.buttonFontSize}
      {...props}
    />
  )
}

const StyledButtonn = styled((props) => {
  const {
    color: _1,
    backgroundColor: _2,
    backgroundHoverColor: _3,
    raidus: _4,
    fontSize: _5,
    width: _6,
    borderWidth: _7,
    borderColor: _8,
    ...otherProps
  } = props
  return <Button {...otherProps} />
})<{
  color: string
  backgroundColor: string
  backgroundHoverColor: string
  radius: string
  fontSize: number
  width: number
  borderWidth: number
  borderColor: string
}>`
  color: ${(props) => props.color} !important;
  cursor: pointer;
  font-size: ${(props) => props.fontSize}px !important;
  border-radius: ${(props) => props.raidus}px !important;
  background-color: ${(props) => props.backgroundColor} !important;
  width: ${(props) => props.width}% !important;
  border-width: ${(props) => props.borderWidth}px !important;
  border-color: ${(props) => props.borderColor} !important;
  &:hover {
    background-color: ${(props) => props.backgroundHoverColor} !important;
  }
  font-family: inherit !important;
`

const Container = styled.div<{
  justifyContent: string
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`
const FormSubmittedMessage = styled.p<{textColor: string | null}>`
  color: ${(props) => props.color};
`
