import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {Title} from 'lib/ui/typography'
import {CardHeader, MainCard, MainCardContent} from 'Event/Statistics/Summary'
import {
  OpenStatisticWindowButton,
  STATISTIC_TOTAL_ATTENDEES,
  useStatistics,
} from 'Event/Statistics'
import Header from 'Event/Statistics/popup/Header'
import UserFilledIcon from 'Event/Statistics/popup/UserFilledIcon'

const DEFAULT_TOTAL_FONT_SIZE = 36

export default function TotalAttendees(props: {
  total: number
  checked_in: number
  className?: string
}) {
  const {total, checked_in: checkedIn, className} = props
  const percent = total === 0 ? 0 : Math.round((checkedIn / total) * 100)

  const {fontSize} = useStatistics()

  return (
    <MainCard className={className}>
      <MainCardContent>
        <CardHeader>
          <Header>Total Attendees</Header>
          <OpenStatisticWindowButton
            statisticType={STATISTIC_TOTAL_ATTENDEES}
          />
        </CardHeader>
        <TotalAttendeesCenter>
          <UserFilledIcon iconSize={48} />
          <Total color="white" delta={fontSize}>
            {total}
          </Total>
          <Header>{`${percent}% Checked In`}</Header>
        </TotalAttendeesCenter>
        <Box />
      </MainCardContent>
    </MainCard>
  )
}

const TotalAttendeesCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Total = styled(Title)<{delta: number}>`
  margin-top: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
  font-size: ${(props) => DEFAULT_TOTAL_FONT_SIZE + props.delta}px;
`
