import NavButton, {
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {useToggle} from 'lib/toggle'
import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {DraggableOverlay} from 'lib/ui/drag-and-drop'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import BlogPostButtonConfig from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButtonConfig'
import Published from 'Event/Dashboard/editor/views/Published'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled from 'lib/ui/layout/Scheduled'
import Grid from '@material-ui/core/Grid'
import {useLoadFont} from 'lib/FontSelect'
import {REMOVE} from 'lib/JsonUpdateProvider'

export default React.memo(
  (props: {
    id: string
    button: NavButtonWithSize
    index: number
    postId: string
    onUpdate?: (id: string, button: NavButtonWithSize | typeof REMOVE) => void
  }) => {
    const {id, button: buttonProps, index, postId, onUpdate} = props
    const isEditMode = useEditMode()
    const {flag: configVisible, toggle: toggleConfig} = useToggle()
    const {flag: showingCopyConfig, toggle: toggleCopyConfig} = useToggle()

    useLoadFont(buttonProps.font)

    const editModeButton = (
      <VisibilityWrapper button={props.button}>
        <NavButton
          onClick={isEditMode ? toggleConfig : () => {}}
          {...buttonProps}
          aria-label="blog post button"
        />
      </VisibilityWrapper>
    )

    if (!isEditMode) {
      return (
        <VisibilityWrapper button={props.button}>
          <ButtonBox>
            <Box>
              <Grid item xs={12} md={props.button.size}>
                <NavButton
                  onClick={isEditMode ? toggleConfig : () => {}}
                  {...buttonProps}
                  aria-label="blog post button"
                />
              </Grid>
            </Box>
          </ButtonBox>
        </VisibilityWrapper>
      )
    }

    return (
      <ButtonBox>
        <BlogPostButtonConfig
          postId={postId}
          showing={configVisible}
          onClose={toggleConfig}
          id={id}
          button={buttonProps}
          onUpdate={onUpdate}
        />
        <BlogPostButtonConfig
          postId={postId}
          showing={showingCopyConfig}
          onClose={toggleCopyConfig}
          button={buttonProps}
          onUpdate={onUpdate}
        />
        <Draggable draggableId={id} index={index} key={id}>
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.draggableProps}>
              <Grid item xs={12} md={props.button.size}>
                <DraggableOverlay>
                  <Editable
                    onEdit={toggleConfig}
                    onCopy={toggleCopyConfig}
                    handleProps={provided.dragHandleProps}
                  >
                    <>{editModeButton}</>
                  </Editable>
                </DraggableOverlay>
              </Grid>
            </Box>
          )}
        </Draggable>
      </ButtonBox>
    )
  },
)

function VisibilityWrapper(props: {
  button: NavButtonWithSize
  children: React.ReactElement
}) {
  const {button: buttonProps, children} = props

  return (
    <VisibleOnMatch rules={props.button.rules}>
      <Scheduled component={props.button}>
        <Published component={buttonProps}>{children}</Published>
      </Scheduled>
    </VisibleOnMatch>
  )
}

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[1]};
  display: flex;
  justify-content: center;
`

const ButtonBox = styled.div`
  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`
