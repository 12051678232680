import {Font} from 'lib/FontSelect'
import styled from 'styled-components'

const Footer = styled.p<{
  color: string
  font: Font | null
}>`
  color: ${(props) => props.color};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.16px;
  margin-top: ${(props) => props.theme.spacing[12]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
  ${(props) => (props.font ? `font-family: ${props.font.family};` : '')}
`

export default Footer
