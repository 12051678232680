import Command from '@ckeditor/ckeditor5-core/src/command';
import { isCustomButtonElement } from '../utils';

export default class CustomButtonTextCommand extends Command {
	override refresh() {
		const element = this.editor.model.document.selection.getSelectedElement();

		this.isEnabled = isCustomButtonElement( element );

		if ( element && isCustomButtonElement( element ) && element.hasAttribute( 'text' ) ) {
			this.value = element.getAttribute( 'text' );
		} else {
			this.value = false;
		}
	}

	override execute( options: {newValue: string} ) {
		const model = this.editor.model;
		const customButtonElement = model.document.selection.getSelectedElement()!;

		model.change( writer => {
			writer.setAttribute( 'text', options.newValue, customButtonElement );
		} );
	}
}

