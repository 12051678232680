import {useLeftyTemplate} from 'Event/template/Lefty'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const template = useLeftyTemplate()

  const v = useAttendeeVariables()

  return (
    <>
      <Label>Other Speakers Title</Label>
      <Controller
        name="speakers.otherSpeakersTitle"
        control={control}
        defaultValue={v(template.speakers.otherSpeakersTitle || '')}
        render={({value, onChange}) => (
          <TextField
            value={value}
            aria-label="set other speakers title"
            fullWidth
            onChange={onChange}
          />
        )}
      />
    </>
  )
}
