import {api} from 'lib/url'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import {Form} from 'organization/Event/FormsProvider'
import {useQuery} from 'react-query'
import {useOrganization} from 'organization/OrganizationProvider'
import {client} from 'lib/ui/api-client'

export type FormSubmission = {
  id: string
  position: number
  attendee_first_name: string
  attendee_last_name: string
  answers: Answer[]
  saved_at: string
  number: number
  status: FormSubmissionStatus
}

export type Answer = {
  question: string
  answer: string
}

interface UseFormSubmissionsParams {
  form: Form
  status: FormSubmissionStatus
}

export function useFormSubmissions(params: UseFormSubmissionsParams) {
  const {form, status} = params
  const {client} = useOrganization()

  return useQuery({
    queryKey: ['forms', form.id, 'submissions', {status}],
    queryFn: () =>
      client.get<Array<FormSubmission>>(
        api(`/forms/${form.id}/submissions?status=${status}`),
      ),
  })
}

export function useFormSubmissionsByToken(params: UseFormSubmissionsParams) {
  const {form, status} = params

  return useQuery({
    queryKey: ['forms', form.id, 'submissions', {status}],
    queryFn: () =>
      client.get<Array<FormSubmission>>(
        api(
          `/forms/${form.id}/submissions?token=${form.access_token}&status=${status}`,
        ),
      ),
  })
}
