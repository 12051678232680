import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {Template} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function HeaderConfig() {
  const saveTemplate = useSaveTemplate()
  const {zoomBackgrounds} = useTemplate()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const {
    form: {register, control},
  } = useConfig()

  const handleSave: TemplateSave<Template> = (data) => {
    const title = replaceAtPath(
      data,
      'zoomBackgrounds.title',
      '{{zoom_backgrounds_title}}',
    )

    const menuTitle = replaceAtPath(
      data,
      'zoomBackgrounds.menuTitle',
      '{{zoom_backgrounds_menu_title}}',
    )

    const description = replaceAtPath(
      data,
      'zoomBackgrounds.description',
      '{{zoom_backgrounds_description}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        zoom_backgrounds_title: title ?? 'Zoom Virtual Backgrounds',
        zoom_backgrounds_menu_title: menuTitle ?? 'Zoom Virtual Backgrounds',
        zoom_backgrounds_description: description ?? '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={handleSave}>
      <SettingsPanel>
        <MenuTitleConfig />
        <Title>Page</Title>
        <TextField
          name="zoomBackgrounds.title"
          defaultValue={v(zoomBackgrounds.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'zoomBackgrounds title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <TextEditorContainer>
          <Controller
            name="zoomBackgrounds.description"
            defaultValue={v(zoomBackgrounds.description)}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

function MenuTitleConfig() {
  const {
    form: {register},
  } = useConfig()
  const v = useAttendeeVariables()

  const {zoomBackgrounds} = useTemplate()
  if (!('menuTitle' in zoomBackgrounds)) {
    return null
  }

  return (
    <>
      <Title>Menu</Title>
      <TextField
        name="zoomBackgrounds.menuTitle"
        defaultValue={v(zoomBackgrounds.menuTitle)}
        label="Text"
        fullWidth
        inputProps={{
          'aria-label': 'menu title',
          ref: register,
        }}
      />
    </>
  )
}
