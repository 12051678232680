import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import Text from 'Event/auth/Login/Description/Text'
import Configurable from 'organization/Event/Configurable'
import DescriptionConfig from 'Event/auth/Login/Description/DescriptionConfig'
import {useGuestVariables} from 'Event'

export default function Description(props: {
  className?: string
  Content?: React.FC<{
    textColor?: string
    loginColor?: string | null
    linkUnderline?: boolean
  }>
}) {
  const {login, textColor, linkUnderline} = useTemplate()

  const Content = props.Content || Text

  const v = useGuestVariables()

  return (
    <Configurable>
      <DescriptionConfig />
      <Content
        aria-label="event login description"
        className={props.className}
        textColor={textColor}
        loginColor={login.description.color}
        linkUnderline={linkUnderline}
      >
        {v(login.description.text)}
      </Content>
    </Configurable>
  )
}
