import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {Settings} from 'Event/Sponsors/HeaderConfig'
import React from 'react'
import {Controller} from 'react-hook-form'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Slider from 'lib/ui/Slider'
import {
  useNiftyFiftyTemplate,
  useNiftyFiftyUpdate,
} from 'Event/template/NiftyFifty'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function HeaderConfig() {
  const saveTemplate = useNiftyFiftyUpdate()
  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const title = replaceAtPath(data, 'sponsors.title', '{{sponsors_title}}')
    const menuTitle = replaceAtPath(
      data,
      'sponsors.menuTitle',
      '{{sponsors_menu_title}}',
    )
    const description = replaceAtPath(
      data,
      'sponsors.description',
      '{{sponsors_description}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        sponsors_title: title ?? '',
        sponsors_menu_title: menuTitle ?? '',
        sponsors_description: description ?? '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}

function Styling() {
  const {sponsors} = useNiftyFiftyTemplate()

  const {
    form: {control},
  } = useConfig()

  return (
    <>
      <Controller
        name="sponsors.spacing"
        defaultValue={sponsors.spacing}
        control={control}
        render={({onChange, value}) => (
          <Slider
            min={0}
            max={10}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            hideUnits
            aria-label="sponsor spacing"
            label="Space Between Sponsors"
          />
        )}
      />
    </>
  )
}
