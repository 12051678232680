import {useEvent} from 'Event/EventProvider'
import DisabledOverlay from 'lib/ui/DisabledOverlay'
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOGIN_URL,
  RESET_PASSWORD_URL,
} from 'lib/ui/form/TextEditor/variables'
import EmailConfig, {
  FORGOT_PASSWORD_EMAIL,
} from 'organization/Event/EmailConfig'
import React from 'react'

export default function ForgotPasswordEmailConfig() {
  const event = useEvent()
  const emailLoginOnly = event.event.allows_email_only_login

  return (
    <>
      <DisabledOverlay
        show={!!emailLoginOnly}
        message={
          'The Password function has been disabled for this event. <br/> The Password function can be enabled on the Event Settings page.'
        }
      />
      <EmailConfig
        type={FORGOT_PASSWORD_EMAIL}
        title="Forgot Password Email"
        label="forgot password email"
        bodyVariables={[
          LOGIN_URL,
          FIRST_NAME,
          LAST_NAME,
          EMAIL,
          RESET_PASSWORD_URL,
        ]}
      />
    </>
  )
}
