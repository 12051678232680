import styled from 'styled-components'
import React from 'react'
import Typography from '@material-ui/core/Typography/'
import {useForm} from 'react-hook-form'
import withStyles from '@material-ui/core/styles/withStyles'
import {spacing} from 'lib/ui/theme'
import {SetPasswordFormProps} from 'Event/Step1/SetPasswordForm'
import MuiButton from '@material-ui/core/Button'
import {useAttendeeVariables} from 'Event'
import {usePanelsTemplate} from 'Event/template/Panels'
import EditorContent from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import TitleConfig from 'Event/Step1/SetPasswordForm/TitleConfig'
import DescriptionConfig from 'Event/Step1/SetPasswordForm/DescriptionConfig'
import PasswordField from 'Event/Step1/SetPasswordForm/PasswordField'
import ConfirmPasswordField from 'Event/Step1/SetPasswordForm/ConfirmPasswordField'
import SubmitButton from 'Event/Step1/SetPasswordForm/SubmitButton'

export default function Content(props: SetPasswordFormProps) {
  const {register, handleSubmit, errors, watch} = useForm()
  const template = usePanelsTemplate()
  const v = useAttendeeVariables()

  const {setPasswordForm} = template

  return (
    <>
      <div>
        <Configurable aria-label="password form title">
          <TitleConfig />
          <Title align="center" variant="h3" aria-label="password title">
            {v(setPasswordForm.title)}
          </Title>
        </Configurable>
        <Configurable>
          <DescriptionConfig />
          <Description>{v(setPasswordForm?.description || '')}</Description>
        </Configurable>
        <form onSubmit={handleSubmit(props.submit)}>
          <PasswordField
            error={errors.password?.message}
            register={register}
            disabled={props.submitting}
          />
          <ConfirmPasswordField
            error={errors.password_confirmation?.message}
            register={register}
            watch={watch}
            disabled={props.submitting}
          />
          <Error>{props.responseError && props.responseError.message}</Error>
          <SubmitButton />
        </form>
      </div>
    </>
  )
}

function Description(props: {children?: string}) {
  if (!props.children) {
    return null
  }
  return <StyledEditorContent>{props.children}</StyledEditorContent>
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius}px !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background: ${(props) => props.backgroundColor} !important;

  &:hover {
    background: ${(props) => props.hoverColor} !important;
  }
`

const Title = styled(Typography)`
  font-size: 24px !important;
  line-height: 29px !important;
  margin-bottom: 8px !important;
  font-weight: bold !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 52px !important;
    line-height: 64px !important;
    margin-bottom: 0;
    font-weight: normal !important;
  }
`

const StyledEditorContent = styled(EditorContent)`
  font-size: 14px !important;
  line-height: 17px !important;
  margin-bottom: 45px !important;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 20px !important;
    line-height: 25px !important;
    margin-bottom: 62px !important;
  }
`
