import {
	View,
	LabeledFieldView,
	createLabeledInputText,
	ButtonView,
	submitHandler
} from '@ckeditor/ckeditor5-ui';
import { icons } from '@ckeditor/ckeditor5-core';
import FocusTracker from '@ckeditor/ckeditor5-utils/src/focustracker';
import FocusCycler, { FocusableView } from '@ckeditor/ckeditor5-ui/src/focuscycler';
import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class FormView extends View {
	public fontsizeInputView: LabeledFieldView<FocusableView>;
	public saveButtonView: ButtonView;
	public cancelButtonView: ButtonView;
	public focusTracker: FocusTracker;
	public keystrokes: KeystrokeHandler;
	public childViews: ViewCollection<View>;
	private _focusables: ViewCollection<FocusableView>;
	private _focusCycler: FocusCycler;

	constructor(locale: Locale) {
		super(locale);

		this.fontsizeInputView = this._createInput('FontSize');
		// (this.fontsizeInputView.fieldView.element as HTMLInputElement)!.type = "number";
		this.saveButtonView = this._createButton('Save', icons.check, 'ck-button-save');
		this.saveButtonView.type = 'submit';

		this.cancelButtonView = this._createButton('Cancel', icons.cancel, 'ck-button-cancel');

		this.focusTracker = new FocusTracker();
		this.keystrokes = new KeystrokeHandler();

		this.cancelButtonView.delegate('execute').to(this, 'cancel');

		this.childViews = this.createCollection([
			this.fontsizeInputView,
			this.saveButtonView,
			this.cancelButtonView
		]);

		this.setTemplate({
			tag: 'form',
			attributes: {
				class: ['ck', 'obvio-custom-font-size-input-form', 'ck-responsive-form'],
				tabindex: '-1'
			},
			children: this.childViews
		});

		this._focusables = new ViewCollection<FocusableView>();

		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				focusPrevious: 'shift+tab',
				focusNext: 'tab'
			}
		});
	}

	override render() {
		super.render();
		this.keystrokes.listenTo(this.element!);

		submitHandler({
			view: this
		});

		this._addKeyEvent();
	}

	focus() {
		(this.childViews.first as any).focus();
	}

	private _createInput(label: string): LabeledFieldView<FocusableView> {
		const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);

		labeledInput.label = label;

		return labeledInput;
	}

	private _createButton(label: string, icon: string, className: string): ButtonView {
		const button = new ButtonView();

		button.set({
			label,
			icon,
			tooltip: true,
			class: className
		});

		return button;
	}

	private _addKeyEvent() {
		[this.fontsizeInputView, this.saveButtonView, this.cancelButtonView].forEach(v => {
			this._focusables.add(v);
			this.focusTracker.add(v.element!);
		});

		this.keystrokes.listenTo(this.element!);

		const stopPropagation = (data: { stopPropagation: () => void }) => data.stopPropagation();

		this.keystrokes.set('arrowright', stopPropagation);
		this.keystrokes.set('arrowleft', stopPropagation);
		this.keystrokes.set('arrowup', stopPropagation);
		this.keystrokes.set('arrowdown', stopPropagation);

		this.listenTo(this.fontsizeInputView.fieldView.element!, 'selectstart', (evt, domEvt) => {
			domEvt.stopPropagation();
		}, { priority: 'high' });
	}
}
