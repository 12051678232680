import styled from 'styled-components'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback, useEffect, useState} from 'react'
import GridCard from 'organization/EventList/Cards/GridCard'
import ListCard from 'organization/EventList/Cards/ListCard'
import Page from 'lib/ui/layout/Page'
import {ObvioEvent} from 'Event'
import Layout from 'organization/user/Layout'
import {useBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {usePageView} from 'analytics'
import Title from 'lib/ui/PageHeader/Title'
import {CREATE_EVENTS} from 'organization/PermissionsProvider'
import HasPermission from 'organization/HasPermission'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {useIsOwner} from 'organization/OwnerProvider'
import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import {
  ToggleViewIcon,
  DesktopPageHeader,
  MobilePageHeader,
  HeaderLink,
} from 'lib/ui/GridCollection'
import {useInterval} from 'lib/interval'

/**
 * The events list doesn't need all of the Event's attributes, or
 * relations, so we'll just return the minimum required here to
 * speed up load times.
 */
export type ListEventData = Pick<
  ObvioEvent,
  | 'id'
  | 'end'
  | 'logo'
  | 'name'
  | 'num_expected_attendees'
  | 'slug'
  | 'start'
  | 'timezone'
  | 'is_live'
  | 'system_domain_id'
  | 'is_copying'
>

export default function EventList() {
  const {organization, routes, client} = useOrganization()
  const isOwner = useIsOwner()
  const [showingListView, toggleListView] = useToggleArray()

  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<ListEventData[] | null>(null)

  const hasEventBeingCopied = events?.some((event) => event.is_copying)

  usePageView()

  const canAccessSettings = isOwner
  const homeLink = canAccessSettings ? routes.settings : routes.events.root

  useBreadcrumbs([
    {title: organization.name, url: homeLink},
    {title: 'Events', url: routes.events.root},
  ])

  const fetchEvents = useCallback(() => {
    const url = api(`/organizations/${organization.id}/events`)

    client
      .get<ListEventData[]>(url)
      .then((events: ListEventData[]) => {
        setEvents(events)
      })
      .finally(() => setLoading(false))
  }, [client, organization.id])

  useInterval(fetchEvents, 3000, hasEventBeingCopied === false)
  useEffect(fetchEvents, [fetchEvents])

  if (loading || !events) {
    return null
  }

  return (
    <Layout>
      <Page>
        <DesktopPageHeader>
          <Title text="Events" />
          <Container>
            <Button onClick={toggleListView} aria-label="toggle view">
              <ToggleViewIcon isListView={showingListView} />
            </Button>
            <HasPermission required={CREATE_EVENTS}>
              <HeaderLink disableStyles to={routes.events.create}>
                <StyledNewItemButton
                  text="New Event"
                  aria-label="create event"
                />
              </HeaderLink>
            </HasPermission>
          </Container>
        </DesktopPageHeader>
        <MobilePageHeader>
          <Title text="Events" />
          <HasPermission required={CREATE_EVENTS}>
            <HeaderLink disableStyles to={routes.events.create}>
              <StyledNewItemButton text="New Event" aria-label="create event" />
            </HeaderLink>
          </HasPermission>
          <Container>
            <Button onClick={toggleListView} aria-label="toggle view">
              <ToggleViewIcon isListView={showingListView} />
            </Button>
          </Container>
        </MobilePageHeader>
        <Content events={events} showingListView={showingListView} />
      </Page>
    </Layout>
  )
}

function Content(props: {events: ListEventData[]; showingListView: boolean}) {
  const {events, showingListView} = props
  const isEmpty = events.length === 0
  if (isEmpty) {
    return <p>No events have been created</p>
  }

  if (showingListView) {
    return (
      <>
        {events.map((e, idx) => (
          <ListCard key={e.id} event={e} index={idx} />
        ))}
      </>
    )
  }

  return (
    <Grid>
      {events.map((e) => (
        <GridCard key={e.id} event={e} />
      ))}
    </Grid>
  )
}

const column = `minmax(270px, auto)`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: ${(props) => props.theme.spacing[8]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: ${column} ${column};
    grid-template-rows: auto auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: ${column} ${column} ${column};
    grid-template-rows: auto auto auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: ${column} ${column} ${column} ${column};
    grid-template-rows: auto auto auto auto;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.colors.gray200};
  div span,
  div i,
  button i {
    color: ${(props) => props.theme.colors.gray200};
  }

  div i {
    margin-left: ${(props) => props.theme.spacing[2]};
  }

  @media all and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    align-items: center;
    span,
    i,
    button {
      color: ${(props) => props.theme.colors.gray200};
    }

    i {
      margin-left: ${(props) => props.theme.spacing[2]};
      margin-right: ${(props) => props.theme.spacing[4]};
      font-size: 12px;
    }
  }
`

const StyledNewItemButton = styled(NewItemButton)`
  color: #ffffff !important;
`
