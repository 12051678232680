import {GridBlock} from 'Event/Marketplace/Block/Grid'

export const createGridBlock = (): GridBlock => ({
  type: 'Grid',
  visibility: 'all',
  numColumns: 4,
  blockSpacing: 2,
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  alignment: {
    vertical: 'start',
    horizontal: 'center',
  },
  blocks: {},
  layouts: {},
})
