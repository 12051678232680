import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from 'lib/ui/Dialog'
import Form from 'organization/EventList/MoveEventDialog/Form'

import {ObvioEvent} from 'Event'

export default function MoveEventDialog(props: {
  event: Pick<ObvioEvent, 'name' | 'slug' | 'id'>
  isVisible: boolean
  onClose: () => void
}) {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>Move to organization</DialogTitle>
      <DialogContent>
        <Form onClose={props.onClose} event={props.event} />
      </DialogContent>
    </Dialog>
  )
}
