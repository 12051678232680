import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {PointsSummaryProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/PointsSummary'
import {useAutoUpdateSidebarItem} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/PointsSummary/PointsSummaryConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/PointsSummary/PointsSummaryConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export function PointsSummaryConfig(
  props: ComponentConfigProps & {
    points: PointsSummaryProps
    id: string
  },
) {
  const {showing, onClose, points, id} = props
  const {handleSubmit, control, watch, formState, setValue} = useForm()
  const [rules, setRules] = useState<Rule[]>(points.rules || [])

  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const save = (data: PointsSummaryProps) => {
    const summary = replaceAtPath(data, 'summary', `{{${id}_summary}}`)
    const description = replaceAtPath(
      data,
      'description',
      `{{${id}_description}}`,
    )

    saveTemplate({
      sidebarItems: {
        [id]: {...data, rules},
      },
      localization: addTranslation({
        [`${id}_summary`]: summary ?? '',
        [`${id}_description`]: description ?? '',
      }),
    })
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: watch(),
    when: showing,
  })

  return (
    <ComponentConfig
      title="Points"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} points={points} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} points={points} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={points} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
