import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useCardsTemplate} from 'Event/template/Cards'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/layout/Divider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function PageLinkConfig(props: {name: string; value: string}) {
  const saveTemplate = useSaveTemplate()
  const {pageLinks} = useCardsTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const {
    form: {register, control},
  } = useConfig()

  const save = (data: any) => {
    const key = props.name.toLowerCase().replace('.', '_')
    const text = replaceAtPath(data, props.name, `{{${key}}}`)
    saveTemplate({
      ...data,
      localization: addTranslation({
        [key]: text ?? '',
      }),
    })
  }

  return (
    <Config title="Page Link" onSave={save}>
      <SettingsPanel>
        <TextField
          name={props.name}
          defaultValue={v(props.value)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'link text',
            ref: register,
          }}
        />
      </SettingsPanel>

      <StylingPanel>
        <Title>Divider</Title>
        <Controller
          name="pageLinks.dividerColor"
          defaultValue={pageLinks.dividerColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="divider color"
            />
          )}
        />
        <Divider />
        <Title>Typography</Title>
        <Controller
          name="pageLinks.textColor"
          defaultValue={pageLinks.textColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="text color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
