import {Template} from 'Event/template'
import {DEFAULTS, SimpleBlog} from 'Event/template/SimpleBlog'
import {CARDS} from 'Event/template/Cards'
import {createHashMap} from 'lib/list'
import {
  EMOJI_LIST,
  EmojiListProps,
} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/EmojiList'
import {
  RESOURCE_LIST,
  ResourceListProps,
} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/ResourceList'
import {
  TICKET_RIBBON_LIST,
  TicketRibbonListProps,
} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import {GREY_RIBBON} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList/Ribbon'
import {baseProps, navButtonWithSize} from 'Event/template/converter'
import {SidebarItemProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'

export function convert(template: Template): SimpleBlog {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    title: title(template),
    footer: footer(template),
    header: header(template),
    mainNav: mainNav(template),
    sidebar: sidebar(template),
    speakers: speakers(template),
    sponsors: sponsors(template),
    progressBar: progressBar(template),
    welcomeText: welcomeText(template),
    sidebarItems: sidebarItems(template),
    heroImageSize: heroImageSize(template),
    heroImage: heroImage(template),
    backgroundPosition: backgroundPosition(template),
    dashboardBackground: dashboardBackground(template),
    zoomBackgrounds: zoomBackgrounds(template),
  }
}

function title(template: Template): SimpleBlog['title'] {
  if ('title' in template) {
    return template.title
  }

  return DEFAULTS.title
}

function footer(template: Template): SimpleBlog['footer'] {
  if ('footer' in template) {
    return template.footer
  }

  return DEFAULTS.footer
}

function header(template: Template): SimpleBlog['header'] {
  if ('header' in template) {
    return {
      ...DEFAULTS.header,
      ...template.header,
    }
  }

  return DEFAULTS.header
}

function mainNav(template: Template): SimpleBlog['mainNav'] {
  if (template.name === CARDS) {
    return {
      buttons: navButtonWithSize(template.mainNav.buttons),
    }
  }

  return template.mainNav
}

function sidebar(template: Template): SimpleBlog['sidebar'] {
  if ('sidebar' in template) {
    return template.sidebar
  }

  return DEFAULTS.sidebar
}

function speakers(template: Template): SimpleBlog['speakers'] {
  return template.speakers
}

function sponsors(template: Template): SimpleBlog['sponsors'] {
  return template.sponsors
}

function progressBar(template: Template): SimpleBlog['progressBar'] {
  if ('progressBar' in template) {
    return template.progressBar
  }

  if ('checkIn' in template) {
    return {
      ...DEFAULTS.progressBar,
      step1Text: template.checkIn.step1Label,
      step2Text: template.checkIn.step2Label,
      step3Text: template.checkIn.step3Label,
    }
  }

  return {
    ...DEFAULTS.progressBar,
    step1Text: template.step1Label,
    step2Text: template.step2Label,
    step3Text: template.step3Label,
    textColor: template.textColor ?? DEFAULTS.progressBar.textColor,
  }
}

function welcomeText(template: Template): SimpleBlog['welcomeText'] {
  if ('welcomeMessage' in template) {
    return template.welcomeMessage.text ?? DEFAULTS.welcomeText
  }

  if ('hero' in template && 'body' in template.hero) {
    return template.hero.body
  }

  return DEFAULTS.welcomeText
}

function sidebarItems(template: Template): SimpleBlog['sidebarItems'] {
  if ('sidebarItems' in template) {
    return template.sidebarItems
  }

  const sidebarItems: SidebarItemProps[] = []

  if ('resourceList' in template) {
    const resources: ResourceListProps = {
      ...template.resourceList,
      type: RESOURCE_LIST,
      isEnabled: true,
      description: '',
      offsetBottom: 0,
      offsetTop: 0,
      resources: createHashMap(
        template.resourceList.resources.map((item) => {
          return {
            ...item,
            icon: 'fas fa-link',
          }
        }),
      ),
    }

    sidebarItems.push(resources)
  }

  if ('emojiList' in template) {
    const emojis: EmojiListProps = {
      ...template.emojiList,
      type: EMOJI_LIST,
    }
    sidebarItems.push(emojis)
  }

  if ('ticketRibbons' in template) {
    const ribbons: TicketRibbonListProps = {
      type: TICKET_RIBBON_LIST,
      ribbons: createHashMap(
        template.ticketRibbons.map((item) => ({
          ...item,
          name: GREY_RIBBON,
          text: item.hoverText,
          color: item.backgroundColor,
        })),
      ),
    }

    sidebarItems.push(ribbons)
  }

  return createHashMap(sidebarItems)
}

function heroImageSize(template: Template): SimpleBlog['heroImageSize'] {
  if ('hero' in template && 'imageSize' in template.hero) {
    return template.hero.imageSize
  }

  if ('heroImageSize' in template) {
    return template.heroImageSize
  }

  return DEFAULTS.heroImageSize
}

function heroImage(template: Template): SimpleBlog['heroImage'] {
  if ('hero' in template && 'image' in template.hero) {
    return template.hero.image ?? DEFAULTS.heroImage
  }

  if ('heroImage' in template) {
    return template.heroImage
  }

  return DEFAULTS.heroImage
}

function backgroundPosition(
  template: Template,
): SimpleBlog['backgroundPosition'] {
  if ('backgroundPosition' in template) {
    return template.backgroundPosition ?? DEFAULTS.backgroundPosition
  }

  if ('pageBackgroundPosition' in template) {
    return template.pageBackgroundPosition ?? DEFAULTS.backgroundPosition
  }

  return DEFAULTS.backgroundPosition
}

function dashboardBackground(
  template: Template,
): SimpleBlog['dashboardBackground'] {
  if ('dashboardBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.dashboardBackground,
    }
  }

  if ('pageBackground' in template) {
    return {
      ...DEFAULTS.dashboardBackground,
      ...template.pageBackground,
    }
  }

  return DEFAULTS.dashboardBackground
}

function zoomBackgrounds(template: Template): SimpleBlog['zoomBackgrounds'] {
  return template.zoomBackgrounds
}
