import {Template} from 'Event/template/index'
import {HashMap} from 'lib/list'
import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'

/**
 * Get all the base prop values shared by ALL templates.
 *
 * @param template
 * @returns
 */
export function baseProps(template: Template) {
  return {
    font: template.font,
    rewardAlert: template.rewardAlert,
    emojiPage: template.emojiPage,
    postStyles: template.postStyles,
    postFormStyles: template.postFormStyles,
    login: template.login,
    signUp: template.signUp,
    changePassword: template.changePassword,
    waiver: template.waiver,
    techCheck: template.techCheck,
    logo: template.logo,
    points_unit: template.points_unit,
    blogPosts: template.blogPosts,
    imageWaterfall: template.imageWaterfall,
    menu: template.menu,
    sponsors: template.sponsors,
    speakers: template.speakers,
    zoomBackgrounds: template.zoomBackgrounds,
    scripts: template.scripts,
    localization: template.localization,
    offlinePage: template.offlinePage,
    textColor: template.textColor,
    formModeration: template.formModeration,
  }
}

export function emojisFromSidebar(template: Template): string[] {
  const emojis: string[] = []

  if ('sidebarItems' in template) {
    for (const key in template.sidebarItems) {
      const item = template.sidebarItems[key]

      if (item.type === 'Emoji List') {
        emojis.push(...item.emojis)
      }
    }
  }
  return emojis
}

/**
 * Converts NavButtonProps to NavButtonWithSize
 *
 * @param buttons
 * @returns HashMap
 */
export function navButtonWithSize(
  buttons: HashMap<NavButtonProps>,
): HashMap<NavButtonWithSize> {
  const result: HashMap<NavButtonWithSize> = {}

  for (const id in buttons) {
    const button = buttons[id]
    result[id] = {
      ...button,
      size: 12,
    }
  }

  return result
}

export function navButtonsFromSidebar(
  template: Template,
): HashMap<NavButtonWithSize> {
  const result: HashMap<NavButtonWithSize> = {}

  if ('sidebarItems' in template) {
    for (const key in template.sidebarItems) {
      const item = template.sidebarItems[key]

      if (item.type === 'Sidebar Nav') {
        const buttons = navButtonWithSize(item.buttons)
        for (const id in buttons) {
          result[id] = buttons[id]
        }
      }
    }
  }

  return result
}
