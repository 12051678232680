import React, {useEffect, useRef, useState} from 'react'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import {DndContext} from '@dnd-kit/core'
import {useUpdateTemplate} from 'organization/Event/Certificates/CertificateEditor'
import Blocks from 'organization/Event/Certificates/CertificateEditor/Blocks'
import {Certificate} from 'lib/event-api/certificates/types'
import {createTextBlock} from 'lib/event-api/certificates/create-text-block'
import {restrictToParentElement} from '@dnd-kit/modifiers'
import grey from '@material-ui/core/colors/grey'
import yellow from '@material-ui/core/colors/yellow'
import green from '@material-ui/core/colors/green'
import Menu from 'lib/ui/Menu'
import ReplacePdfMenuItem from 'organization/Event/Certificates/CertificateEditor/ReplacePdfMenuItem'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'

interface ContentProps {
  certificate: Certificate
  template: NonNullable<Certificate['template']>
  isSaving: boolean
  onChangePdf: (file: File) => void
}

export default function Content(props: ContentProps) {
  const {template, certificate, isSaving, onChangePdf} = props

  const inputFile = useRef<any>(null)

  const [showingSavingIndicator, setShowingSavingIndicator] = useState(false)

  const updateTemplate = useUpdateTemplate()

  useEffect(() => {
    if (showingSavingIndicator || !isSaving) {
      return
    }

    // Hide saving indicator after seom time
    setShowingSavingIndicator(true)
    if (inputFile.current) {
      inputFile.current.value = ''
    }
    setTimeout(() => setShowingSavingIndicator(false), 2000)
  }, [isSaving, showingSavingIndicator])

  const addText = () => {
    const newBlock = createTextBlock()

    updateTemplate({
      blocks: {
        [newBlock.id]: newBlock,
      },
    })
  }

  return (
    <PageBreadcrumbs page="Certificates">
      <Page>
        <Header>
          <SavingIndicator
            isSaving={isSaving}
            showing={showingSavingIndicator}
          />

          <Menu
            button={({open}) => (
              <ButtonGroup variant="outlined" color="primary">
                <AddTextButton
                  color="primary"
                  variant="outlined"
                  onClick={addText}
                  size="small"
                >
                  Add Text
                </AddTextButton>
                <ArrowButton
                  color="primary"
                  size="small"
                  aria-label="view other recordings"
                  aria-haspopup="menu"
                  onClick={open}
                >
                  <ArrowDownIcon />
                </ArrowButton>
              </ButtonGroup>
            )}
          >
            <ReplacePdfMenuItem
              onSelectFile={onChangePdf}
              disabled={showingSavingIndicator}
            />
          </Menu>
        </Header>
        <DndContext modifiers={[restrictToParentElement]}>
          <Blocks template={template} certificate={certificate} />
        </DndContext>
      </Page>
    </PageBreadcrumbs>
  )
}

function SavingIndicator({
  isSaving,
  showing,
}: {
  isSaving: boolean
  showing: boolean
}) {
  const variant: CircularProgressProps['variant'] = isSaving
    ? 'indeterminate'
    : 'determinate'
  const labelText = isSaving ? 'saving...' : 'saved'

  return (
    <SavingIndicatorWrapper>
      <ProgressSpinner
        size={18}
        thickness={6}
        value={100}
        variant={variant}
        active={isSaving}
      />
      {showing && labelText}
    </SavingIndicatorWrapper>
  )
}

const SavingIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[2]};
  margin-right: ${(props) => props.theme.spacing[3]};
  color: ${grey[500]};
`

const ProgressSpinner = styled((props) => {
  const {active: _active, ...otherProps} = props
  return <CircularProgress {...otherProps} />
})<{active: boolean}>`
  color: ${(props) => (props.active ? yellow[700] : green[400])} !important;
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 20px 20px 50px #ddd;
  margin-bottom: 1px;
  position: fixed;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: white;
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[4]}`};
  z-index: 25;
`

const AddTextButton = styled(Button)`
  padding-top: 2px !important;
  padding-bottom: 2px !important;
`

const ArrowButton = styled(Button)`
  padding: 2px 0 !important;
`
