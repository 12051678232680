import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {ResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  list: ResourceListProps
}) {
  const {control, list} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isEnabled"
        defaultValue={list.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <Label>Title</Label>
      <Controller
        name="title"
        control={control}
        defaultValue={v(list.title)}
        render={({value, onChange}) => (
          <TextField
            aria-label="update resources title"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />

      <Label>Description</Label>
      <Controller
        name="description"
        control={control}
        defaultValue={v(list.description)}
        render={({value, onChange}) => (
          <TextField
            aria-label="update resources description"
            fullWidth
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}
