import {UpsellPage} from 'Event/Marketplace/upsell-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {useMutation} from 'react-query'

export function useUpdateUpsellPageTemplate(params: {client: Client}) {
  const {client} = params

  return useMutation({
    mutationFn: (data: {
      upsellPage: UpsellPage
      updates: Record<string, any>
    }) => {
      const {upsellPage: purchasePage, updates} = data

      const url = marketplaceApi(`/upsell_pages/${purchasePage.id}/template`)
      return client.put<UpsellPage>(url, {
        template: updates,
      })
    },
  })
}
