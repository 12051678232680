import styled from 'styled-components'
import {onChangeCheckedHandler} from 'lib/dom'
import React from 'react'
import Switch from 'lib/ui/form/Switch'

export default function EnabledSwitch(props: {
  enabled?: boolean
  setEnabled?: (enabled: boolean) => void
  disabled?: boolean
}) {
  const {enabled, setEnabled, disabled} = props

  if (!setEnabled) {
    return null
  }

  return (
    <StyledSwitch
      checked={enabled}
      onChange={onChangeCheckedHandler(setEnabled)}
      aria-label="toggle enable"
      labelPlacement="end"
      label="Enable"
      disabled={disabled}
    />
  )
}

const StyledSwitch = styled(Switch)`
  margin-bottom: 0;
`
