import React, {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import Accordion from 'lib/ui/Accordion'
import AccordionDetails from 'lib/ui/Accordion/AccordionDetails'
import AccordionSummary from 'lib/ui/Accordion/AccordionSummary'
import {TrashCan} from 'lib/ui/Icon'
import {Label, SubHead} from 'lib/ui/typography'
import {
  ZoomAttendanceScheduledAction,
  useFetchZoomAttendanceScheduleActions,
  useZoomAttendanceScheduleDelete,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import {formatDate} from 'organization/EventList/CreateEventForm/Form'

export default function ScheduledActionList(props: {onClose: () => void}) {
  const {onClose} = props
  const [schedules, setSchedules] = useState<ZoomAttendanceScheduledAction[]>()
  const [success, onSuccessMessage] = useState<string | null>(null)
  const fetchSchedules = useFetchZoomAttendanceScheduleActions()

  const getSchedules = useCallback(() => {
    fetchSchedules().then((response) => {
      setSchedules(response)
    })
  }, [fetchSchedules, setSchedules])

  useEffect(() => {
    getSchedules()
  }, [getSchedules])

  const pendingSchedules =
    schedules?.filter((schedule) => schedule.executed === false) || []
  const completedSchedules =
    schedules?.filter((schedule) => schedule.executed === true) || []

  return (
    <>
      <DialogTitle>Scheduled Actions</DialogTitle>

      <StyledSuccessAlert>{success}</StyledSuccessAlert>

      <DialogContent>
        <SubHead mb={4}>Pending</SubHead>
        <HeaderRow />

        <Listing
          schedules={pendingSchedules}
          getSchedules={getSchedules}
          onSuccessMessage={onSuccessMessage}
        />

        <SubHead mb={4} mt={6}>
          Completed
        </SubHead>
        <HeaderRow />

        <Listing schedules={completedSchedules} />

        <ButtonContainer>
          <Button color="primary" onClick={onClose} variant="outlined">
            Close
          </Button>
        </ButtonContainer>
      </DialogContent>
    </>
  )
}

const HeaderRow = () => {
  return (
    <HeaderBox>
      <FullGrid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <StrongLabel>Scheduled Action date/time</StrongLabel>
        </Grid>
        <Grid item xs={12} sm={2}>
          <StrongLabel>Action</StrongLabel>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StrongLabel>Area</StrongLabel>
        </Grid>
        <Grid item xs={12} sm={2}>
          <StrongLabel>Zoom Attendance Start</StrongLabel>
        </Grid>
        <Grid item xs={12} sm={2}>
          <StrongLabel>Zoom Attendance End</StrongLabel>
        </Grid>
      </FullGrid>
    </HeaderBox>
  )
}

const Listing = (props: {
  schedules: ZoomAttendanceScheduledAction[]
  getSchedules?: () => void
  onSuccessMessage?: Dispatch<SetStateAction<string | null>>
}) => {
  const {schedules, getSchedules, onSuccessMessage} = props
  const scheduleType = getSchedules ? 'Pending' : 'Completed'

  if (!schedules.length) {
    return <EmptySchedules>No {scheduleType} Scheduled Actions</EmptySchedules>
  }

  return (
    <div aria-label={`container-${scheduleType}`}>
      {schedules.map((schedule, key) => {
        return (
          <StyledAccordion key={key} id={`${schedule.id}`}>
            <AccordionSummary expandedIconName="menu">
              <ScheduleSummary schedule={schedule} />
            </AccordionSummary>
            <AccordionDetails>
              <ScheduleDetails
                getSchedules={getSchedules}
                onSuccessMessage={onSuccessMessage}
                schedule={schedule}
              />
            </AccordionDetails>
          </StyledAccordion>
        )
      })}
    </div>
  )
}

const ScheduleSummary = (props: {schedule: ZoomAttendanceScheduledAction}) => {
  const {schedule} = props
  const {eventAreas} = useZoomAttendance()

  const areaName = eventAreas.find((area) => area.id === schedule.area_id)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={2}>
        <MobileLabel>Scheduled Action date/time</MobileLabel>
        {formatDate(moment(schedule.scheduled_date_time))}
      </Grid>
      <Grid item xs={12} sm={2}>
        <MobileLabel>Action</MobileLabel>
        {schedule.action}
      </Grid>
      <Grid item xs={12} sm={3}>
        <MobileLabel>Area</MobileLabel>
        {areaName?.name}
      </Grid>
      <Grid item xs={12} sm={2}>
        <MobileLabel>Zoom Attendance Start</MobileLabel>
        {formatDate(moment(schedule.start_date_time))}
      </Grid>
      <Grid item xs={12} sm={2}>
        <MobileLabel>Zoom Attendance End</MobileLabel>
        {formatDate(moment(schedule.end_date_time))}
      </Grid>
    </Grid>
  )
}

const ScheduleDetails = (props: {
  getSchedules?: () => void
  onSuccessMessage?: (success: string | null) => void
  schedule: ZoomAttendanceScheduledAction
}) => {
  const {getSchedules, onSuccessMessage, schedule} = props
  const {humanReadableActionData, humanReadableFilterData} = useZoomAttendance()
  const doDelete = useZoomAttendanceScheduleDelete()

  const handleDelete = () => {
    if (schedule.executed === true || !getSchedules || !onSuccessMessage) {
      return
    }

    doDelete(schedule.id).then((response) => {
      onSuccessMessage(response.message || '')
      getSchedules()
    })
  }

  const deleteIcon = getSchedules ? (
    <StyledTrashCan
      aria-label={`delete-scheduled-action-${schedule.id}`}
      onClick={handleDelete}
      title="Delete this Scheduled Action"
    />
  ) : null

  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={12} sm={1}></Grid>
      <Grid item xs={12} sm={2}>
        <StrongLabel>Action Details</StrongLabel>
        {actionDetails(humanReadableActionData(schedule.data))}
      </Grid>
      <Grid item xs={12} sm={3}>
        <StrongLabel>Advanced Attendee Filter Details</StrongLabel>
        {humanReadableFilterData(schedule.data.filter || null)}
      </Grid>
      <Grid item xs={12} sm={2}>
        <StrongLabel>Team Member</StrongLabel>
        {schedule.team_member.first_name} {schedule.team_member.last_name}
      </Grid>
      <FlexGrid item xs={12} sm={4}>
        {deleteIcon}
      </FlexGrid>
    </StyledGrid>
  )
}

const actionDetails = (actionDataStrings: string[]) => {
  return actionDataStrings.map((actionDataString, key) => {
    return <div key={key}>{actionDataString}</div>
  })
}

const EmptySchedules = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.gray300};
  padding-top: ${(props) => props.theme.spacing[3]};
  text-align: center;
`

const HeaderBox = styled.div`
  margin-left: ${(props) => props.theme.spacing[4]};
  margin-right: ${(props) => props.theme.spacing[6]};
  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const StrongLabel = styled(Label)`
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[1]};
`

const MobileLabel = styled(Label)`
  font-weight: 500;
  margin-bottom: ${(props) => props.theme.spacing[1]};
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`

const StyledAccordion = styled(Accordion)`
  border-bottom: none;

  & > .MuiAccordionSummary-root {
    border-bottom: none;
  }
`

const FullGrid = styled(Grid)`
  width: 100%;
`
const StyledGrid = styled(Grid)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[6]} 0;

  & > button:last-child {
    margin-left: ${(props) => props.theme.spacing[4]};
  }
`

const FlexGrid = styled(Grid)`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding-right: ${(props) => props.theme.spacing[8]} !important;
`

const StyledTrashCan = styled(TrashCan)<{
  onClick?: () => void
  title: string
}>`
  font-size: ${(props) => props.theme.spacing[4]};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.error};
  }
`

const StyledSuccessAlert = styled(SuccessAlert)`
  margin: ${(props) => props.theme.spacing[6]};
  margin-top: 0;
`
