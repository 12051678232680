import {TextBlock} from 'lib/event-api/certificates/types'
import {uuid} from 'lib/uuid'

export const createTextBlock = (): TextBlock => ({
  id: uuid(),
  text: 'Text',
  font: null,
  fontSize: 60,
  color: '#3490DC',
  left: 0,
  top: 10,
  height: 0,
  width: 0,
})
