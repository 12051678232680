import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CustomButtonLinkUI from './custombuttonlinkui';
import CustomButtonLinkCommand from './custombuttonlinkcommand';

export default class CustomButtonMergeCodeLinks extends Plugin {
    private button!: ButtonView;
    private linkFormView!: any;

    init() {
        const editor = this.editor;
        const linkUI = editor.plugins.get(CustomButtonLinkUI) as CustomButtonLinkUI;
        this.linkFormView = linkUI.formView;
        const mergeCode: string = editor.config.get('customButton.mergeCode') as string;

        if (mergeCode) {
            this.button = this._createButton(mergeCode);

            this.linkFormView.once('render', () => {
                // Render button's template.
                this.button.render();

                // Register the button under the link form view, it will handle its destruction.
                this.linkFormView.registerChild(this.button);

                // Inject the element into DOM.
                this.linkFormView.element.insertBefore(this.button.element!, this.linkFormView.saveButtonView.element!);
            });
        }
    }

    private _createButton(label: string): ButtonView {
        const editor = this.editor;
        const button = new ButtonView();
        const linkCommand = editor.commands.get('customButtonLink') as CustomButtonLinkCommand;
        const that = this;

        button.set({
            label,
            withText: true,
            tooltip: true
        });

        button.bind('isEnabled').to(linkCommand);

        button.on('execute', () => {
            that.linkFormView.labeledInput.fieldView.element!.value = label;
        });

        return button;
    }
}
