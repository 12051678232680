import React from 'react'
import Configurable from 'organization/Event/Configurable'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import SectionLabel from 'Event/Marketplace/Block/PurchaseForm/SectionLabel'
import SectionLabelConfig from 'organization/Marketplace/config/ConfigurablePurchaseForm/SectionLabelConfig'

export interface ConfigurableSectionLabelProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  children: string
  inputName: string
}

export default function ConfigurableSectionLabel(
  props: ConfigurableSectionLabelProps,
) {
  return (
    <Configurable aria-label="edit section heading">
      <SectionLabelConfig {...props} />
      <SectionLabel {...props} />
    </Configurable>
  )
}
