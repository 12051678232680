import React from 'react'
import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {BlockBase} from '../base'
import defaultLogo from './logo_vertical.png'

export interface ImageBlock extends BlockBase {
  type: 'Image'
  image: null | string
  size: number
  sizeMobile: number
  borderWidth: number
  borderRadius: number
  borderColor: string
  isMobileMode?: boolean
}

export default function Image(props: ImageBlock & BlockComponentProps) {
  const {
    sizeMobile,
    borderWidth,
    borderColor,
    borderRadius,
    id,
    size,
    CustomComponents,
    width,
    height,
    isMobileMode,
  } = props
  const image = props.image || defaultLogo
  const className = `image-block-${id}`

  const ImageComponent = CustomComponents?.Image ?? DefaultImageComponent

  return (
    <>
      <style>
        {`
        .${className} {
          max-width: ${sizeMobile}%;
          max-height: ${sizeMobile}%;
          object-fit: contain;
          border: ${borderWidth}px solid ${borderColor};
          border-radius: ${borderRadius}px;
        }
        @media (min-width: 600px) {
          .${className} {
            max-width: ${isMobileMode ? sizeMobile : size}% !important;
            max-height: ${isMobileMode ? sizeMobile : size}%  !important;
          }
        }
        `}
      </style>
      <Container {...props}>
        <ImageComponent
          src={image}
          alt="block"
          className={className}
          width={width ? (size * width) / 100 : undefined}
          height={height ? (size * height) / 100 : undefined}
        />
      </Container>
    </>
  )
}

function DefaultImageComponent(
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
) {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} />
}
