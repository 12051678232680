import React from 'react'
import styled from 'styled-components'
import ObieLayout from 'organization/Obie/Layout'
import {Label, Description} from 'lib/ui/typography'
import bot from 'assets/images/icons/bot.svg'

export default function ObieLockedPage() {
  return (
    <ObieLayout hideSidebar={false} hideLinks>
      <Container>
        <TitleBox>
          <img src={bot} alt="OBIE robot" />
          <Title>OBIE</Title>
          <BetaBadge>Beta</BetaBadge>
        </TitleBox>
        <Box>
          <StyledLabel>
            Welcome to Obie, the Offer Builder Inspiration Engine!
          </StyledLabel>
          <StyledDescription>
            Obie can only be accessed by Organizations currently on the LEAP
            Plan
          </StyledDescription>
        </Box>
      </Container>
    </ObieLayout>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[15]};
  padding-bottom: ${(props) => props.theme.spacing[13]};
`
const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledDescription = styled(Description)`
  max-width: 600px;
`
const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => `0 ${props.theme.spacing[4]}`};
`

const BetaBadge = styled.span`
  margin: 10px;
  padding: 2px 4px;
  background-color: #3490dc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
`
