import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertCustomButtonCommand from './insertcustombutton/insertcustombuttoncommand';
import ObvioCustomButtonCommand from './obviocustombuttoncommand';
import { toCustomButtonWidget } from './utils';
import { modelToViewAttributeConverter, modelToViewStyleConverter } from './converter';
import { Editor } from '@ckeditor/ckeditor5-core';
import { Conversion, Element, ViewElement, Writer, DowncastWriter } from '@ckeditor/ckeditor5-engine';

export default class ObvioCustomButtonEditing extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add('insertCustomButton', new InsertCustomButtonCommand(this.editor));
        this.editor.commands.add('updateCustomButton', new ObvioCustomButtonCommand(this.editor));
    }

    private _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('customButton', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,
            isBlock: true,
            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            allowAttributes: ['text', 'href', 'float', 'buttonFontSize', 'buttonBackgroundColor', 'buttonFontColor']
        });
    }

    private _defineConverters() {
        const conversion: Conversion = this.editor.conversion;

        conversion.for('dataDowncast').elementToElement({
            model: 'customButton',
            view: (modelItem: Element, { writer: viewWriter }: { writer: DowncastWriter }) => {
                return createCustomButton(modelItem, viewWriter);
            }
        });

        // When user clicks the widget, it should enable the editing.
        conversion.for('editingDowncast').elementToElement({
            model: 'customButton',
            view: (modelElement: Element, { writer: viewWriter }: { writer: DowncastWriter }) => {
                return toCustomButtonWidget(createCustomButton(modelElement, viewWriter), viewWriter, 'custom button widget');
            }
        });

        conversion.for('downcast')
            .add(modelToViewAttributeConverter('href'))
            .add(modelToViewStyleConverter('float', 'float', ''))
            .add(modelToViewStyleConverter('buttonBackgroundColor', 'background-color', ''))
            .add(modelToViewStyleConverter('buttonFontColor', 'color', ''))
            .add(modelToViewStyleConverter('buttonFontSize', 'font-size', 'px'));

        conversion.for('upcast').elementToElement({
            view: {
                name: 'a',
                classes: ['obvio-custom-button']
            },
            model: (viewElement: ViewElement, { writer }: { writer: Writer }) => {
                const attributes = {
                    ...Object.fromEntries(viewElement.getAttributes() as Iterable<[string, string]>),
                    buttonBackgroundColor: viewElement.getStyle('background-color'),
                    buttonFontColor: viewElement.getStyle('color'),
                    buttonFontSize: viewElement.getStyle('font-size'),
                    text: (viewElement.getChild(0) as any).data
                };
                return writer.createElement('customButton', attributes);
            }
        });

        function createCustomButton(modelItem: Element, viewWriter: DowncastWriter) {
            const buttonText = modelItem.getAttribute('text') as string || 'Button';

            const view = viewWriter.createContainerElement('a', {
                class: 'obvio-custom-button',
                target: '_blank'
            });

            const innerText = viewWriter.createText(buttonText);
            viewWriter.insert(viewWriter.createPositionAt(view, 0), innerText);

            return view;
        }
    }
}
