import React from 'react'
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
  closestCenter,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
type DragHandler = (fromIndex: number, toIndex: number) => void

export default function SortableGrid(props: {
  children: JSX.Element
  onDrag: DragHandler
  ids: string[]
}) {
  const {children, onDrag, ids} = props

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event

    if (active?.id !== over?.id) {
      const oldIndex = ids.indexOf('' + active?.id)
      const newIndex = ids.indexOf('' + over?.id)

      onDrag(oldIndex, newIndex)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={ids} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  )
}
