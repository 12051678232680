import {useCallback, useState} from 'react'

export function useToggleArray(start: boolean = false): [boolean, () => void] {
  const [isTrue, setIsTrue] = useState(start)

  // use callback otherwise it would yield unexpected results in promises

  const toggle = useCallback(() => setIsTrue((isTrue) => !isTrue), [setIsTrue])

  return [isTrue, toggle]
}
