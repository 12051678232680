import {Grid} from '@material-ui/core'
import styled from 'styled-components'
import React from 'react'

export const questionStatuses = [
  'approved',
  'incoming',
  'on_deck',
  'deleted',
  'answered',
]

export type FormSubmissionStatus =
  | 'approved'
  | 'incoming'
  | 'on_deck'
  | 'deleted'
  | 'answered'

export type QAFormData = {
  [Status in FormSubmissionStatus]: FormSubmission[] | null
}

export type FormSubmission = {
  id: string
  position: number
  attendee_first_name: string
  attendee_last_name: string
  answers: Question[]
  saved_at: string
  number: number
  status: FormSubmissionStatus
}

export type Question = {
  question: string
  answer: string
}

export const emptyQuestions = {
  incoming: null,
  approved: null,
  on_deck: null,
  deleted: null,
  answered: null,
}

export function QuestionsPreview(props: {questions: Question[]}) {
  const {questions} = props

  const questionsWithAnswers = questions.filter((question) => !!question.answer)

  if (questionsWithAnswers.length > 1) {
    return (
      <div>
        {questionsWithAnswers.map((question) => {
          return (
            <div key={question.question}>
              <ItemQuestion>{question.question}</ItemQuestion>
              <ItemMessage>{question.answer}</ItemMessage>
            </div>
          )
        })}
      </div>
    )
  }

  return <ItemMessage>{questions[0].answer}</ItemMessage>
}

export function QuestionsInlinePreview(props: {questions: Question[]}) {
  const {questions} = props

  if (questions.length > 1) {
    return (
      <>
        {questions.map((question, index) => {
          return (
            <div key={index}>
              <b>{question.question}</b>
              {': '}
              {question.answer}
              {'    '}
            </div>
          )
        })}
      </>
    )
  }

  return <>{questions[0].answer}</>
}

export const QuestionRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 3px solid #f5f5f5;
  border-bottom: 3px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
`

export const QuestionNumber = styled.div`
  color: #00000040;
  background: #f5f5f5;
  font-size: 11px;
  font-weight: 500;
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[2]};
`

export const QuestionData = styled.div`s
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const QuestionAttendee = styled.div`
  font-weight: 600;
  padding: 0 ${(props) => props.theme.spacing[3]};
  display: inline-block;
  flex: none;
`

export const QuestionLeft = styled.div`
  display: flex;
  align-items: center;
`

export const QuestionRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.theme.spacing[5]};
`

export const QuestionAnswers = styled.div`
  padding-right: 10px;
  overflow: hidden;
  height: ${(props) => props.theme.spacing[6]};
`

export const DialogBox = styled.div`
  padding: ${(props) => props.theme.spacing[4]};
`

export const StyledDialogQuestion = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

export const Header = styled(Grid)`
  padding: ${(props) => props.theme.spacing[4]};
  box-shadow: 0px 4px 4px -1px #0000000d;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  border-radius: 0;
  margin-top: -1px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

export const NumHolder = styled.div`
  background: #f5f5f5;
  border: 1px solid #0000001a;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  display: inline;
  padding: ${(props) => props.theme.spacing[1]};
  margin-left: ${(props) => props.theme.spacing[4]};
  min-width: 28px;
  text-align: center;
`
export const MenuLeftSide = styled.div`
  display: flex;
  align-items: center;
`

const ItemQuestion = styled.div`
  font-size: 15px;
  font-weight: 600;
`
const ItemMessage = styled.div`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
