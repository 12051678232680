import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import React, {ChangeEvent, useEffect, useState} from 'react'
import Page from 'organization/Event/Page'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useForm} from 'organization/Event/Form/FormProvider'
import {Title} from 'lib/ui/typography'
import {TabContext, TabList, TabPanel} from '@material-ui/lab'
import {Tab} from '@material-ui/core'
import styled from 'styled-components'
import QATab from 'organization/Event/Form/FormModeration/QATab'
import DeletedTab from 'organization/Event/Form/FormModeration/DeletedTab'
import AnsweredTab from 'organization/Event/Form/FormModeration/AnsweredTab'
import {useEventSocket} from 'organization/Event/EventSocketProvider'
import {FormSubmission} from 'lib/event-api/forms/use-form-submissions'
import {useQueryClient} from 'react-query'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'

type FormSubmissionSocketUpdate = FormSubmission & {
  prev_status: FormSubmissionStatus | null
}

export default function FormModerate() {
  const routes = useEventRoutes()
  const {form} = useForm()

  const [value, setValue] = useState<string>('qa')

  const handleChange = (_event: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  useListenForFormSubmissionSavedUpdates()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Forms',
        url: routes.forms.root,
      }}
      page={form.name}
    >
      <Page>
        <TabContext value={value}>
          <TabsHolder>
            <Title>Form Moderation</Title>

            <StyledTabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: {display: 'none'},
              }}
            >
              <StyledTab
                label="Q&A Board"
                value="qa"
                selected={value === 'qa'}
              />
              <StyledTab
                label="Answered"
                value="answered"
                selected={value === 'answered'}
              />
              <StyledTab
                label="Trash"
                value="deleted"
                selected={value === 'deleted'}
              />
            </StyledTabList>
          </TabsHolder>

          <StyledTabPanel value="qa">
            <QATab />
          </StyledTabPanel>
          <StyledTabPanel value="answered">
            <AnsweredTab />
          </StyledTabPanel>
          <StyledTabPanel value="deleted">
            <DeletedTab />
          </StyledTabPanel>
        </TabContext>
      </Page>
    </PageBreadcrumbs>
  )
}

export function useListenForFormSubmissionSavedUpdates() {
  const {channel} = useEventSocket()
  const {
    form: {id: formId},
  } = useForm()
  const qc = useQueryClient()

  // Listen for socket updates
  useEffect(() => {
    if (!channel) {
      return
    }

    channel?.listen(
      '.form_submission.saved',
      (updated: FormSubmissionSocketUpdate) => {
        // Invalidate queries to trigger a refetch
        if (updated.prev_status) {
          qc.invalidateQueries([
            'forms',
            formId,
            'submissions',
            {status: updated.prev_status},
          ])
        }

        qc.invalidateQueries([
          'forms',
          formId,
          'submissions',
          {status: updated.status},
        ])
      },
    )

    return () => {
      channel?.stopListening('.form_submission.saved')
    }
  }, [channel, qc, formId])
}

const StyledTab = styled(Tab)<{
  selected?: boolean
}>`
  background: ${(props) => (props.selected ? '#FFFFFF' : '#F5F5F5')};
  border: 1px solid #f5f5f5;
  border-bottom: none;
  margin-right: 6px;
  border-radius: 3px 3px 0 0;
`

const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`

const StyledTabList = styled(TabList)`
  & > div > div {
    justify-content: flex-end;
  }
`
const TabsHolder = styled.div`
  display: flex;
  justify-content: space-between;
`
