import {UpsellDeclineButtonBlock} from 'Event/Marketplace/Block/UpsellDeclineButton'

export const createUpsellDeclineButtonBlock = (): UpsellDeclineButtonBlock => ({
  type: 'UpsellDeclineButton',
  visibility: 'all',
  text: 'No, Thank You!',
  textColor: '#FFFFFF',
  fontSize: 20,
  backgroundColor: '#3490DC',
  borderColor: '#000000',
  borderWidth: 0,
  borderRadius: 5,
  size: 12,
  height: 44,
  font: null,
  hoverBackgroundColor: 'rgba(52, 144, 220, 0.9)',
  width: 100,
  disabledBackgroundColor: '#3490DC',
  disabledTextColor: '#FFFFFF',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
})
