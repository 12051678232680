import ConfigurableSectionLabel, {
  ConfigurableSectionLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableSectionLabel'
import React from 'react'

export default function ConfigurableShippingSectionLabel(
  props: ConfigurableSectionLabelProps,
) {
  const {shippingSectionLabel} = props

  return (
    <ConfigurableSectionLabel
      {...props}
      children={shippingSectionLabel ?? 'Shipping'}
      inputName="shippingSectionLabel"
    />
  )
}
