import {BlockComponentProps} from 'Event/Marketplace/Block'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import FontSelect from 'lib/FontSelect'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import React from 'react'
import {Controller} from 'react-hook-form'

interface PurchaseFormTitleConfigProps
  extends PurchaseFormBlock,
    BlockComponentProps {}

export default function PurchaseFormTitleConfig(
  props: PurchaseFormTitleConfigProps,
) {
  const {id} = props
  const {sectionId, update} = useConfigurableSection()

  const save = (data: any) => {
    update(data.sections[sectionId])
  }

  const {
    form: {control, register},
  } = useConfig()

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <CollapsibleSection
          label="Label"
          noDivider
          storageKey={`blocks-${id}-styling.inputFields.placeholderText`}
        >
          <TextField
            name={`sections.${sectionId}.blocks.${id}.title.text`}
            defaultValue={props.title?.text ?? 'Your Order'}
            label="Title"
            inputProps={{
              ref: register,
            }}
          />
        </CollapsibleSection>
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Font"
          noDivider
          storageKey={`blocks-${id}-styling.fontSize`}
        >
          <Controller
            control={control}
            name={`sections.${sectionId}.blocks.${id}.title.font`}
            defaultValue={props.title?.font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${id}.title.fontSize`}
            defaultValue={props.title?.fontSize ?? 36}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={10}
                max={100}
                step={1}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Spacing"
          noDivider
          storageKey={`blocks-${id}-styling.spacing`}
        >
          <MarginPaddingInputs
            control={control}
            namePrefix={`sections.${sectionId}.blocks.${id}.title.margin`}
            values={
              props.title?.margin ?? {
                top: 0,
                right: 0,
                bottom: 16,
                left: 0,
              }
            }
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
