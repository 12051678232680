import React from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography/'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import {useAttendeeVariables} from 'Event'
import {SetPasswordFormProps} from 'Event/Step1/SetPasswordForm'
import {useTownhallTemplate} from 'Event/template/Townhall'
import StepIndicator from 'Event/template/Townhall/check-in/StepIndicator'
import CheckInPage from 'Event/template/Townhall/check-in/Page'
import {spacing} from 'lib/ui/theme'
import Content from 'lib/ui/form/TextEditor/Content'
import TitleConfig from 'Event/Step1/SetPasswordForm/TitleConfig'
import Configurable from 'organization/Event/Configurable'
import DescriptionConfig from 'Event/Step1/SetPasswordForm/DescriptionConfig'
import PasswordField from 'Event/Step1/SetPasswordForm/PasswordField'
import ConfirmPasswordField from 'Event/Step1/SetPasswordForm/ConfirmPasswordField'
import SubmitButton from 'Event/Step1/SetPasswordForm/SubmitButton'

export default function SetPasswordForm(props: SetPasswordFormProps) {
  const {register, handleSubmit, errors, watch} = useForm()
  const {setPasswordForm} = useTownhallTemplate()
  const v = useAttendeeVariables()

  return (
    <CheckInPage user={props.user}>
      <Container maxWidth="sm">
        <StepIndicator step={1} />
        <Configurable>
          <TitleConfig />
          <Typography align="center" variant="h6">
            {v(setPasswordForm.title)}
          </Typography>
        </Configurable>
        <Configurable>
          <DescriptionConfig />
          <Description>{v(setPasswordForm?.description || '')}</Description>
        </Configurable>
        <Box mt={2}>
          <form onSubmit={handleSubmit(props.submit)}>
            <PasswordField
              error={errors.password?.message}
              register={register}
              disabled={props.submitting}
            />
            <ConfirmPasswordField
              error={errors.password_confirmation?.message}
              register={register}
              watch={watch}
              disabled={props.submitting}
            />
            <Error>{props.responseError && props.responseError.message}</Error>
            <SubmitButton />
          </form>
        </Box>
      </Container>
    </CheckInPage>
  )
}
function Description(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <Content>{props.children}</Content>
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius}px !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background: ${(props) => props.backgroundColor} !important;

  &:hover {
    background: ${(props) => props.hoverColor} !important;
  }
`
