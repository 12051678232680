import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

export function useSetTicketPositions() {
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: (tickets: MarketplaceTicket[]) => {
      const url = marketplaceApi(`/events/${event.id}/tickets/positions`)
      return client!.post(url, {
        ticket_ids: tickets.map((ticket) => ticket.id),
      })
    },
    onMutate: (tickets) => {
      qc.setQueryData(['events', event.id, 'tickets'], tickets)
    },
    onError: () => {
      qc.invalidateQueries(['events', event.id, 'tickets'])
    },
  })
}
