import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import FormControl from '@material-ui/core/FormControl'
import ResourceUpload from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceUpload'
import IconSelect from 'lib/fontawesome/IconSelect'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import {useConfig} from 'organization/Event/Configurable'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  id: string
  sectionId: string
  resource: Resource
}) {
  const {resource, id, sectionId} = props
  const {
    form: {control, watch, register},
  } = useConfig()

  const isUrl = watch('isUrl', resource.isUrl)

  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.isVisible`}
        defaultValue={resource.isVisible || false}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config visible switch"
          />
        )}
      />
      <Label>Name</Label>
      <TextField
        name={`dashboardSections.${sectionId}.items.${id}.name`}
        defaultValue={v(resource.name || '')}
        aria-label="resource name"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.isUrl`}
        defaultValue={resource.isUrl ?? false}
        render={({value, onChange}) => (
          <FormControl>
            <ToggleButtonGroup value={value ? 'true' : 'false'} exclusive>
              <ToggleButton value="false" onClick={() => onChange(false)}>
                File
              </ToggleButton>
              <ToggleButton
                value="true"
                aria-label="set url resource"
                onClick={() => onChange(true)}
              >
                Link
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.filePath`}
        defaultValue={v(resource.filePath)}
        render={({value, onChange}) => (
          <ResourceUpload isUrl={isUrl} filePath={value} onChange={onChange} />
        )}
      />
      <UrlField
        defaultValue={resource.url || ''}
        register={register}
        isUrl={isUrl}
        id={id}
        sectionId={sectionId}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.actionId`}
        defaultValue={resource.actionId || null}
        control={control}
        render={({value, onChange}) => (
          <ActionSelect noneLabel="default" value={value} onChange={onChange} />
        )}
      />
      <Controller
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.icon`}
        defaultValue={resource.icon || ''}
        render={({value, onChange}) => (
          <IconSelect value={value || null} onChange={onChange} />
        )}
      />
    </>
  )
}

function UrlField(props: {
  isUrl?: boolean
  register: UseFormMethods['register']
  defaultValue: string
  id: string
  sectionId: string
}) {
  const {isUrl, sectionId, id, register, defaultValue} = props
  if (!isUrl) {
    return null
  }

  return (
    <>
      <Label>URL starting with http:// or https://</Label>
      <TextField
        name={`dashboardSections.${sectionId}.items.${id}.url`}
        defaultValue={defaultValue}
        aria-label="resource external file url"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}
