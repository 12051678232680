import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {usePanelsTemplate, usePanelsUpdate} from 'Event/template/Panels'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Panels/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export default function TimerConfig(
  props: ComponentConfigProps & {
    countDownTimer: CountDownTimerSettings
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, countDownTimer} = props
  const updateTemplate = usePanelsUpdate()

  const template = usePanelsTemplate()
  const {countDownTimers} = template
  const addTranslations = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const {control, handleSubmit, formState} = useForm()

  const update = (id: string, updated: CountDownTimerSettings) => {
    const {labels, ...data} = updated
    updateTemplate({
      localization: addTranslations({
        [`${id}_days_label`]: labels.days || 'Days',
        [`${id}_hours_label`]: labels.hours || 'Hours',
        [`${id}_minutes_label`]: labels.minutes || 'Minutes',
        [`${id}_seconds_label`]: labels.seconds || 'Seconds',
      }),
      countDownTimers: {
        [id]: data,
      },
    })
  }

  const insert = (countDownTimer: CountDownTimerSettings) => {
    const numButtons = Object.keys(countDownTimers).length
    const position = numButtons + 1

    generateHashId([
      'panels',
      'dashboard_count-timers',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `count_timer_${id}`
      updateTemplate({
        localization: addTranslations({
          [`${componentId}_days_label`]: countDownTimer.labels.days || 'Days',
          [`${componentId}_hours_label`]:
            countDownTimer.labels.hours || 'Hours',
          [`${componentId}_minutes_label`]:
            countDownTimer.labels.minutes || 'Minutes',
          [`${componentId}_seconds_label`]:
            countDownTimer.labels.seconds || 'Seconds',
        }),
        countDownTimers: {
          [componentId]: {
            ...countDownTimer,
            position,
            labels: {
              ...countDownTimer.labels,
              days: `{{${componentId}_days_label}}`,
              hours: `{{${componentId}_hours_label}}`,
              minutes: `{{${componentId}_minutes_label}}`,
              seconds: `{{${componentId}_seconds_label}}`,
            },
          },
        },
      })
    })
  }

  const removeCountDownTimer = useCallback(() => {
    if (!id) {
      throw new Error('Missing count down timer id')
    }

    updateTemplate({
      localization: removeTranslations([`${id}_text`]),
      countDownTimers: {
        [id]: REMOVE,
      },
    })
  }, [updateTemplate, id, removeTranslations])

  const save = (data: CountDownTimerSettings) => {
    if (id) {
      update(id, data)
    } else {
      insert(data)
    }

    onClose()
  }

  const showingRemoveButton = Boolean(id)

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Count Down Timer Config"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} countDownTimer={countDownTimer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} countDownTimer={countDownTimer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove countdown"
          onClick={removeCountDownTimer}
          showing={showingRemoveButton}
        />
      </Footer>
    </ComponentConfig>
  )
}
