import {PurchasePageBlock} from 'Event/Marketplace/purchase-page'
import {HashMap} from 'lib/list'
import {
  blockDefaultHeight,
  blockDefaultWidth,
} from 'organization/Marketplace/config/AddBlockDialog'
import {Layout, Layouts} from 'react-grid-layout'

/**
 * Resize layout to set invisible items to w:0, h:0
 * @param params
 */
export function resizeLayoutVisibility(params: {
  layouts: Layouts
  blocks: HashMap<PurchasePageBlock>
}): Layouts {
  const {layouts, blocks} = params

  const result: Layouts = {}
  for (const [breakpoint, layout] of Object.entries(layouts)) {
    const resizedLayout: Layout[] = []

    for (const item of layout) {
      const block = blocks[item.i]
      if (!block) {
        continue
      }

      if (breakpoint === 'desktop' && block.visibility === 'mobile_only') {
        resizedLayout.push({
          ...item,
          h: 0,
          w: 0,
          minW: 0,
          minH: 0,
        })
        continue
      }

      if (breakpoint === 'mobile' && block.visibility === 'desktop_only') {
        resizedLayout.push({
          ...item,
          h: 0,
          w: 0,
          minW: 0,
          minH: 0,
        })
        continue
      }

      resizedLayout.push({
        ...item,
        w: item.w || blockDefaultWidth,
        h: item.h || blockDefaultHeight,
      })
    }

    result[breakpoint] = resizedLayout
  }

  return result
}
