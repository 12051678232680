import React from 'react'
import {Body as ButtonContent} from '../Button/Content'
import {BlockComponentProps} from '..'
import Container from '../Container'
import {BlockBase} from '../base'
import {usePurchase} from '../../PurchaseContext'
import {ButtonBase} from '../Button/ButtonBase'
import {Font} from '../../lib/font'
import {Column} from '../../lib/layout'
import styles from './UpsellDeclineButton.module.css'

export interface UpsellDeclineButtonBlock extends BlockBase {
  type: 'UpsellDeclineButton'
  text: string
  size: Column
  font: Font | null
  fontSize?: number
  backgroundColor?: string
  hoverBackgroundColor?: string
  textColor?: string
  hoverTextColor?: string
  borderRadius?: number
  borderWidth?: number
  borderColor?: string
  hoverBorderColor?: string
  height?: number
  width?: number
  disabledBackgroundColor?: string
  disabledTextColor?: string
}

export default function UpsellDeclineButton(
  props: UpsellDeclineButtonBlock & BlockComponentProps,
) {
  const {padding: _padding, ...buttonProps} = props
  const {upsellDecline} = usePurchase()

  return (
    <Container {...props}>
      <ButtonBase
        {...buttonProps}
        className={styles.upsellDeclineButton}
        type="button"
        id={`upsell-purchase-button-${props.id}`}
        onClick={upsellDecline}
      >
        <ButtonContent {...props} type="Button" />
      </ButtonBase>
    </Container>
  )
}
