import React from 'react'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {Block, useObieService} from '../ObieServiceProvider'
import styled from 'styled-components'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import Button from 'lib/ui/Button'

export default function BlockCreateDependencies(props: {
  block: Block
  onClose: () => void
  startProcess: (formData: any) => void
  open: boolean
}) {
  const {block, onClose, open, startProcess} = props
  const {handleSubmit, control} = useForm()

  if (!open) {
    return null
  }

  const onSubmit = (formData: any) => startProcess(formData)

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>{block.block} depends on Completions</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {block.dependencies.map((blockId, key) => {
            return (
              <DependencyListItem
                blockId={blockId}
                control={control}
                key={key}
              />
            )
          })}

          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function DependencyListItem(props: {
  blockId: number
  control: UseFormMethods['control']
}) {
  const {blockId, control} = props
  const {findBlock} = useObieService()
  const block = findBlock(blockId)

  if (!block) {
    return null
  }

  const incompletes = block.answer_sets.filter(
    (answerSet) => answerSet.complete === false,
  )
  const allIncomplete = incompletes.length === block.answer_sets.length

  if (allIncomplete) {
    return (
      <Controller
        name={block.prompt.name}
        control={control}
        defaultValue=""
        render={({value, onChange}) => (
          <StyledSelect
            label={block.block}
            fullWidth
            required
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
            aria-label={`pick completed block ${block.id}`}
          >
            <Option value="">No Complete Blocks</Option>
          </StyledSelect>
        )}
      />
    )
  }

  const defaultValue =
    block.answer_sets.length === 1 ? block.answer_sets[0].id : ''

  return (
    <Controller
      name={block.prompt.name}
      control={control}
      defaultValue={defaultValue}
      render={({value, onChange}) => (
        <StyledSelect
          label={block.block}
          fullWidth
          required
          value={value}
          hidden={block.answer_sets.length === 1}
          onChange={onUnknownChangeHandler(onChange)}
          aria-label={`pick completed block ${block.id}`}
        >
          {(block.answer_sets || []).map((answerSet, key2) => {
            if (!answerSet.complete) {
              return null
            }

            return (
              <Option value={answerSet.id} key={key2}>
                {answerSet.name}
              </Option>
            )
          })}
        </StyledSelect>
      )}
    />
  )
}

const StyledSelect = styled(Select)<{
  hidden?: boolean
}>`
  display: ${(props) => (props.hidden === true ? 'none' : 'block')};
`

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`
