import React from 'react'
import {
  FormQuestionProps,
  StyledTextField,
  useObieQuestions,
} from 'organization/Obie/ObieQuestionsProvider'

export default function LongForm(props: FormQuestionProps) {
  const {question, setDirty} = props
  const {currentAnswer, setCurrentAnswer} = useObieQuestions()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(event.target.value)
    setDirty && setDirty()
  }

  return (
    <StyledTextField
      value={currentAnswer}
      fullWidth
      multiline
      onChange={handleChange}
      placeholder={question.example_text}
      required={question.required}
      rows={12}
      variant="filled"
    />
  )
}
