import ConfigurablePurchaseSummaryColumnLabel, {
  ConfigurablePurchaseSummaryColumnLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseSummaryColumnLabel'
import React from 'react'

export default function ConfigurableTotalAmountLabel(
  props: ConfigurablePurchaseSummaryColumnLabelProps,
) {
  const {totalAmountLabel} = props

  return (
    <ConfigurablePurchaseSummaryColumnLabel
      {...props}
      children={totalAmountLabel ?? 'Total'}
      inputName="totalAmountLabel"
    />
  )
}
