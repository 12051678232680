import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'

export default function PasswordFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const {login} = useTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const {
    form: {register},
  } = useConfig()

  const save = (data: any) => {
    const label = replaceAtPath(
      data,
      'login.passwordLabel',
      '{{login_password_label}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        login_password_label: label ?? 'Password',
      }),
    })
  }

  return (
    <Config title="Password Field" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Password Label"
          name="login.passwordLabel"
          defaultValue={v(login.passwordLabel)}
          inputProps={{
            'aria-label': 'password label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields templateKey="login.input" values={login.input} />
      </StylingPanel>
    </Config>
  )
}
