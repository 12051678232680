import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'
import {client} from 'lib/ui/api-client'
import {useAsync} from 'lib/async'
import TextField from '@material-ui/core/TextField'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Icon from 'lib/ui/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import {colors} from 'lib/ui/theme'
import {handleAutocomplete} from 'lib/dom'
import {Autocomplete, createFilterOptions} from '@material-ui/lab'
import WebFont from 'webfontloader'
import {BOLD, CAPS, FontStyle, ITALIC} from 'lib/ui/typography/FontStyleInput'
import {spacing} from 'lib/ui/theme'

export type Font = {
  family: string
  variants: string[]
  styles: FontStyle[]
}

type GoogleFontsResponse = {
  kind: string
  items: Font[]
}

const TRENDING_FONT_FAMILIES = [
  'Arial',
  'Courier New',
  'Montserrat',
  'Open Sans',
  'Oswald',
  'Pacifico',
  'Permanent Marker',
  'Poppins',
  'Roboto',
  'Shadows Into Light',
  'Times New Roman',
  'Ubuntu',
  'Verdana',
  'Water Brush',
]

const trendingFonts = TRENDING_FONT_FAMILIES.map(createTrendingFont)

const isTrendingFont = (font: Font) =>
  TRENDING_FONT_FAMILIES.includes(font.family)

const filterOptions = createFilterOptions<Font>({
  limit: 10,
})

export default function FontSelect(props: {
  value?: Font
  onChange: (value: any) => void
  className?: string
  hideBold?: boolean
  hideItalic?: boolean
  hideCaps?: boolean
}) {
  const {value, onChange, hideBold, hideItalic, hideCaps} = props

  const changeFontStyle = (
    _: React.MouseEvent<HTMLElement>,
    styles: FontStyle[],
  ) => {
    onChange({
      ...value,
      styles,
    })
  }

  return (
    <StyledFormControl fullWidth className={props.className}>
      <FontFamilySelect value={value} onChange={onChange} />
      <ToggleButtonGroup value={value?.styles} onChange={changeFontStyle}>
        <StyledToggleButton
          value={BOLD}
          disableRipple
          disabled={!value}
          aria-label="bold style"
          hidden={hideBold}
        >
          <Icon className="fas fa-bold" iconSize={18} />
        </StyledToggleButton>
        <StyledToggleButton
          value={ITALIC}
          disableRipple
          disabled={!value}
          aria-label="italic style"
          hidden={hideItalic}
        >
          <Icon className="fas fa-italic" iconSize={18} />
        </StyledToggleButton>
        <StyledToggleButton
          value={CAPS}
          disableRipple
          disabled={!value}
          aria-label="uppercase style"
          hidden={hideCaps}
        >
          <Icon className="fas fa-font-case" iconSize={18} />
        </StyledToggleButton>
      </ToggleButtonGroup>
    </StyledFormControl>
  )
}

export function FontFamilySelect(props: {
  value?: Font
  onChange: (value: any) => void
  className?: string
}) {
  const {value, onChange, className} = props
  const {data, loading} = useLoadGoogleFonts()
  const [regularFonts, setRegularFonts] = React.useState<Font[]>([])
  const fonts = [...trendingFonts, ...regularFonts]

  useEffect(() => {
    if (!data) {
      return
    }

    const regularFonts = data.items.filter((i) => !isTrendingFont(i))
    setRegularFonts(regularFonts)
  }, [data])

  return (
    <StyledAutocomplete
      className={className}
      options={fonts}
      value={value}
      onChange={handleAutocomplete(onChange)}
      loading={loading}
      aria-label="font selector"
      noOptionsText="No Font"
      getOptionLabel={(option) => option.family}
      getOptionSelected={(option) => option.family === value?.family}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          fullWidth
          fontFamily={value?.family}
          inputProps={{
            ...params.inputProps,
            'aria-label': 'font search',
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(font) => <FontOption font={font} />}
      filterOptions={filterOptions}
    />
  )
}

function createTrendingFont(fontFamily: string): Font {
  return {
    family: fontFamily,
    variants: [],
    styles: [],
  }
}

function useLoadGoogleFonts() {
  const request = useCallback(() => {
    return client.get<GoogleFontsResponse>(
      `https://www.googleapis.com/webfonts/v1/webfonts?fields=items(family%2Cvariants)&sort=TRENDING&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    )
  }, [])

  return useAsync(request)
}

export function useLoadFont(font?: Font | null) {
  useEffect(() => {
    if (!font) {
      return
    }

    // Trending fonts are bundled with the app, so we don't need
    // to load them again.
    if (isTrendingFont(font)) {
      return
    }

    const hasMultipleVariants = font.variants.length > 0

    const value = hasMultipleVariants
      ? `${font.family}:${font.variants.toString()}`
      : font.family

    WebFont.load({
      google: {
        families: [value],
      },
    })
  }, [font])
}

function FontOption(props: {font: Font}) {
  const {font} = props
  useLoadFont(font)

  return (
    <FontOptionLabel fontFamily={font.family}>{font.family}</FontOptionLabel>
  )
}

const FontOptionLabel = styled.div<{
  fontFamily: string
}>`
  font-family: ${(props) => `${props.fontFamily}, Rubik, sans-serif`};
`

const StyledFormControl = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: spacing[6],
  },
})(FormControl)

const StyledAutocomplete = withStyles({
  root: {
    flex: 1,
    marginRight: spacing[3],
    marginBottom: 0,
  },
})(Autocomplete) as typeof Autocomplete

const StyledTextField = styled(TextField)<{fontFamily?: string}>`
  input {
    font-family: ${(props) => `${props.fontFamily}, Rubik, sans-serif`};
  }
  margin-bottom: 0;
`

const StyledToggleButton = styled(
  withStyles({
    root: {
      border: 'none !important',
      borderRadius: '4px !important',
      padding: spacing[2],
      marginLeft: '8px !important',
      color: `${colors.gray100}`,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      '&:hover': {
        color: `white !important`,
      },
    },
    label: {
      width: 24,
      height: 24,
    },
    selected: {
      color: `white !important`,
    },
  })(ToggleButton),
)<{hidden?: boolean}>`
  display: ${(props) => (props.hidden ? 'none' : 'inline-flex')};
`

export const fontStyles = (font: Font | null) => {
  if (!font) {
    return ``
  }

  const styles = font.styles || []

  return `
    font-weight: ${styles.includes(BOLD) ? 'bold' : 'normal'};
    font-family: ${font.family};
    font-style: ${styles.includes(ITALIC) ? 'italic' : 'normal'};
    text-transform: ${styles.includes(CAPS) ? 'none' : 'uppercase'};
    `
}
