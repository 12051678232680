import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import InsertVariableCommand from "./InsertVariableCommand";

export const ELEMENT_NAME = "obvioVariable";
export const INSERT_COMMAND = "insertObvioVariable";

export default class Value extends Plugin {

	init() {

		this.editor.commands.add(
			INSERT_COMMAND,
			new InsertVariableCommand(this.editor)
		);

		this.editor.config.define("obvioVariables", {
			items: [],
		});
	}

}
