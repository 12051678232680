import {StripeCardNumberElement} from '@stripe/stripe-js'
import React from 'react'
import {PurchaseFormBlock} from '.'
import {UpsellPurchaseButtonBlock} from '../UpsellPurchaseButton'

export interface PurchaseFormData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

export interface PurchaseFormProviderProps {
  config: PurchaseFormBlock | UpsellPurchaseButtonBlock
  children: JSX.Element
}

interface PurchaseFormContextProps {
  useSameAddressForShipping: boolean
  setUseSameAddressForShipping: (sameAddress: boolean) => void
  setCardElement: (element: StripeCardNumberElement) => void
  firstName: string
  setFirstName: (value: string) => void
  firstNameError: string | null
  setFirstNameError: (value: string | null) => void
  lastName: string
  setLastName: (value: string) => void
  lastNameError: string | null
  setLastNameError: (value: string | null) => void
  email: string
  setEmail: (value: string) => void
  emailError: string | null
  setEmailError: (value: string | null) => void
  phoneNumber: string
  setPhoneNumber: (value: string) => void
  phoneNumberError: string | null
  setPhoneNumberError: (value: string | null) => void
  billingCountryId: number
  setBillingCountryId: (value: number) => void
  billingCountryIdError: string | null
  setBillingCountryIdError: (value: string | null) => void
  billingStateId: number
  setBillingStateId: (value: number) => void
  billingStateIdError: string | null
  setBillingStateIdError: (value: string | null) => void
  billingCity: string
  setBillingCity: (value: string) => void
  billingCityError: string | null
  setBillingCityError: (value: string | null) => void
  billingAddress1: string
  setBillingAddress1: (value: string) => void
  billingAddress1Error: string | null
  setBillingAddress1Error: (value: string | null) => void
  billingAddress2: string
  setBillingAddress2: (value: string) => void
  billingZipPostal: string
  setBillingZipPostal: (value: string) => void
  billingZipPostalError: string | null
  setBillingZipPostalError: (value: string | null) => void
  shippingCountryId: number
  setShippingCountryId: (value: number) => void
  shippingCountryIdError: string | null
  setShippingCountryIdError: (value: string | null) => void
  shippingStateId: number
  setShippingStateId: (value: number) => void
  shippingStateIdError: string | null
  setShippingStateIdError: (value: string | null) => void
  shippingCity: string
  setShippingCity: (value: string) => void
  shippingCityError: string | null
  setShippingCityError: (value: string | null) => void
  shippingAddress1: string
  setShippingAddress1: (value: string) => void
  shippingAddress1Error: string | null
  setShippingAddress1Error: (value: string | null) => void
  shippingAddress2: string
  setShippingAddress2: (value: string) => void
  shippingZipPostal: string
  setShippingZipPostal: (value: string) => void
  shippingZipPostalError: string | null
  setShippingZipPostalError: (value: string | null) => void
  hasError: boolean
  setHasError: (value: boolean) => void
  validateForm: (config: PurchaseFormBlock) => boolean
  validateBillingForm: (config: PurchaseFormBlock) => boolean
  validatShippingForm: (config: PurchaseFormBlock) => boolean
  onSubmit: () => void
  hasFilledCard: boolean
  cardError: string | null
  isExistingAttendee: boolean
}

export const PurchaseFormContext = React.createContext<
  PurchaseFormContextProps | undefined
>(undefined)

export function usePurchaseForm() {
  const context = React.useContext(PurchaseFormContext)
  if (context === undefined) {
    throw new Error(
      'usePurchaseForm must be used within a PurchaseFormProvider',
    )
  }

  return context
}
