import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React, {useState} from 'react'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import {SpacerBlock} from 'Event/Marketplace/Block/Spacer'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'

interface SpacerConfigProps extends SpacerBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function SpacerConfig(props: SpacerConfigProps) {
  const {id, onMoveSectionUp, onMoveSectionDown} = props
  const [visibility, setVisibility] = useState(props.visibility)

  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = () => {
    update({
      blocks: {
        [id]: {visibility},
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Spacer Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
    </Config>
  )
}
