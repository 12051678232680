import {ObvioEvent} from 'Event'
import {UpsellPage} from 'Event/Marketplace/upsell-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

export function useUpsellPage(params: {event: ObvioEvent}) {
  const {event} = params

  return useQuery({
    queryKey: ['events', event.id, 'upsell_page'],
    queryFn: () =>
      client.get<UpsellPage | null>(
        marketplaceApi(`/events/${event.id}/upsell_page`),
      ),
  })
}
