import React, {useState} from 'react'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {EventWithPurchasePage} from 'lib/marketplace-api/purchase-page/use-purchase-page-events'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import TemplateCard from 'organization/Marketplace/config/TemplateSelector/TemplateCard'

const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

interface TemplateCardProps {
  event: EventWithPurchasePage
  updateTemplate: UpdateTemplateFunction
}

export default function PurchasePageEventTemplateCard(
  props: TemplateCardProps,
) {
  const {event, updateTemplate} = props

  const withCopiedAssets = useDuplicateAssets<PurchasePage['template']>()

  const [loading, setLoading] = useState(false)

  const onSelect = () => {
    setLoading(true)

    withCopiedAssets(event.purchasePageTemplate).then((template) => {
      setLoading(false)
      updateTemplate(template)
    })
  }

  const onViewPurchasePage = () => {
    window.open(`${marketplaceStoreUrl}/${event.slug}`, '_blank')
  }

  return (
    <TemplateCard
      event={event}
      updateTemplate={updateTemplate}
      onSelect={onSelect}
      loading={loading}
      pageLabel="Purchase Page"
      onView={onViewPurchasePage}
    />
  )
}
