import React from 'react'
import {Box, Container, Typography} from '@material-ui/core'

type SingleMetricProps = {
  name: string
  count: Number
}

export default function SingleMetric({count, name}: SingleMetricProps) {
  return (
    <Container
      style={{
        background: '#d9d9d9',
        padding: 0,
        marginBottom: 32,
        borderRadius: 4,
      }}
    >
      <Box bgcolor="#f5f5f5" px={3} py={3} borderRadius="4px 4px 0 0">
        {name}
      </Box>
      <Box
        height={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h3">{count}</Typography>
      </Box>
    </Container>
  )
}
