import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {AgendaSettings} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/AgendaList'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {generateHashId} from 'lib/crypto/hash'

export function AgendaItemConfig(
  props: {
    agenda: AgendaSettings
    id?: string
    listId: string
  } & ComponentConfigProps,
) {
  const {showing, onClose, agenda, id, listId} = props
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: AgendaSettings) => {
    const text = replaceAtPath(updated, 'text', `{{${id}_text}}`)
    const link = replaceAtPath(updated, 'link', `{{${id}_link}}`)

    saveTemplate({
      sidebarItems: {
        [listId]: {
          items: {
            [id]: updated,
          },
        },
      },
      localization: addTranslation({
        [`${id}_text`]: text ?? '',
        [`${id}_link`]: link ?? '',
      }),
    })
  }

  const insert = (item: AgendaSettings) => {
    generateHashId([
      'simple_blog_sidebar_agenda_item',
      new Date().valueOf().toString(),
      item.position?.toString() ?? '',
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `agenda_${id}`
      update(componentId, item)
    })
  }

  const save = (data: AgendaSettings) => {
    if (id === undefined) {
      insert(data)
      onClose()
      return
    }

    update(id, data)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    saveTemplate({
      sidebarItems: {
        [listId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
      localization: removeTranslations([`${id}_text`]),
    })

    onClose()
  }, [id, saveTemplate, listId, removeTranslations, onClose])

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agenda"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          agenda={agenda}
          watch={watch}
          setValue={setValue}
        />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          fullWidth
          variant="outlined"
          aria-label="remove agenda"
          onClick={remove}
          hidden={!id}
        >
          REMOVE AGENDA
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
