import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from 'lib/ui/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

export default function ResetPasswordDialog(props: {
  open: boolean
  success: boolean
  loading: boolean
  error?: string
  onClose: () => void
}) {
  const {open, loading, success, error, onClose} = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <LoadingOverlay showing={loading}>
          <CircularProgress />
        </LoadingOverlay>
        <Success showing={success} />
        <Error>{error}</Error>
      </DialogContent>
    </Dialog>
  )
}

function Success(props: {showing: boolean}) {
  const {showing} = props

  if (!showing) {
    return null
  }

  return <Typography>Password reset link sent!</Typography>
}

function Error(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const LoadingOverlay = styled.div<{
  showing: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: ${(props) => (props.showing ? 'flex' : 'none')};
  z-index: 2;
  align-items: center;
  justify-content: center;
`

const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`
