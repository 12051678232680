import React from 'react'
import {Icon} from 'lib/fontawesome/Icon'
import styled from 'styled-components'
import {DEFAULT_ICON_ALIGN} from 'Event/Dashboard/components/NavButton'

export interface AlignButtonProps {
  value?: string
  onChange: (value: string) => void
}

export default function AlignButtons(props: AlignButtonProps) {
  const value = props.value ?? DEFAULT_ICON_ALIGN

  return (
    <AlignButtonsHolder>
      <div>Icon Alignment</div>
      <div>
        <SelectedIcon
          isSelected={value === 'left' ? true : false}
          iconClass="fa-solid fa-left-from-line"
          onClick={() => {
            props.onChange('left')
          }}
        />
        <SelectedIcon
          isSelected={value === 'right' ? true : false}
          iconClass="fa-solid fa-right-from-line"
          onClick={() => {
            props.onChange('right')
          }}
        />
      </div>
    </AlignButtonsHolder>
  )
}

const AlignButtonsHolder = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectedIcon = styled(Icon)<{
  isSelected: boolean
}>`
  margin: 0px 20px;
  font-size: 20px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  ${(props) => {
    if (props.isSelected === true) {
      return 'background: #3490dc;'
    }

    return null
  }}
`
