import {useTemplate} from 'Event/TemplateProvider'
import React from 'react'
import defaultBackground from 'assets/images/background_login.png'
import styled from 'styled-components'
import {rgba} from 'lib/color'
import {LoginProps} from 'Event/auth/Login'
import {PageConfigurable} from 'organization/Event/Configurable'
import BackgroundConfig from 'Event/auth/Login/Background/BackgroundConfig'

export default function Background(props: {
  children: JSX.Element | JSX.Element[]
  isPreview?: LoginProps['isPreview']
  className?: string
}) {
  const {isPreview} = props
  const {login} = useTemplate()

  const image = login.background || defaultBackground
  const color = rgba(login.backgroundColor, login.backgroundOpacity / 100)

  return (
    <PageConfigurable>
      <BackgroundConfig />
      <Page
        backgroundImage={image}
        isPreview={isPreview}
        aria-label="login background"
        isEnabled={login.backgroundEnabled}
        className={props.className}
      >
        <ColorOverlay color={color}>
          <Content>
            <>{props.children}</>
          </Content>
        </ColorOverlay>
      </Page>
    </PageConfigurable>
  )
}

export const Page = styled.div<{
  backgroundImage: string
  isPreview?: boolean
  isEnabled?: boolean
}>`
  ${(props) =>
    props.isEnabled ? `background: url(${props.backgroundImage});` : ''}
  display: flex;
  background-size: cover;
  background-position: center;
  position: ${(props) => (props.isPreview ? 'inherit' : 'absolute')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  overflow: auto; // Fix height not 100% on scroll
`

export const ColorOverlay = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: auto;
  padding: ${(props) => props.theme.spacing[4]};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 600px;
  }
`
