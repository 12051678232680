import Container from '../../Block/Container'
import React from 'react'
import {BlockComponentProps} from '../../Block'
import {BlockBase} from '../base'
import styles from './Icon.module.css'
import {parseFileLocation} from '../../lib/url'

export interface IconBlock extends BlockBase {
  icon: string | null
  color: string
  size: number
  opacity: number
  type: 'Icon'
}

export default function Icon(props: IconBlock & BlockComponentProps) {
  const {icon} = props

  return (
    <Container
      aria-label={`${icon} holder`}
      {...props}
      className={styles.iconContainer}
    >
      <StyledIcon iconClass={icon} {...props} />
    </Container>
  )
}

function StyledIcon(
  props: {
    iconClass?: string | null
  } & IconBlock &
    BlockComponentProps,
) {
  const {iconClass, color, size, opacity} = props

  if (!iconClass) {
    return null
  }

  const isImage = iconClass?.startsWith('url')
  if (!isImage) {
    return (
      <i
        className={`${iconClass}`}
        style={{
          color,
          fontSize: `${size}cqmin`,
          opacity: `${opacity}%`,
          width: `${size}cqmin`,
          height: `${size}cqmin`,
        }}
      />
    )
  }

  const image = parseFileLocation(iconClass)
  return (
    <img
      src={image?.url}
      alt={image?.name || ''}
      style={{
        color,
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        opacity: `${opacity}%`,
      }}
    />
  )
}
