import AgendaList, {
  AGENDA_LIST,
  AgendaListProps,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import React, {useCallback} from 'react'
import EmojiList, {
  EMOJI_LIST,
  EmojiListProps,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/EmojiList'
import PointsSummary, {
  PointsSummaryProps,
  POINTS_SUMMARY,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/PointsSummary'
import {
  RESOURCE_LIST,
  ResourceListProps,
  ResourceList,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList'
import SidebarNav from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/SidebarNav'
import {useLeftyUpdate} from 'Event/template/Lefty'
import TicketRibbons, {
  TicketRibbonListProps,
  TICKET_RIBBON_LIST,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import Text, {
  TextProps,
  TEXT,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/Text'
import {Draggable} from 'react-beautiful-dnd'
import {DraggableOverlay} from 'lib/ui/drag-and-drop'
import {useEditMode} from 'Event/EditModeProvider'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {DeepPartialSubstitute} from 'lib/type-utils'
import DragHandleBar from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/DragHandleBar'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
import Scheduled from 'lib/ui/layout/Scheduled'
import {SidebarNavProps, SIDEBAR_NAV} from 'Event/template'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export type SidebarItemProps =
  | AgendaListProps
  | ResourceListProps
  | EmojiListProps
  | PointsSummaryProps
  | TicketRibbonListProps
  | SidebarNavProps
  | TextProps

export type HasOffset = {
  offsetTop: number
  offsetBottom: number
}

type SidebarItemContextProps = {
  update: (
    data: DeepPartialSubstitute<SidebarItemProps, typeof REMOVE>,
    localization?: Record<string, string>,
  ) => void
  removeList: (localizations: string[]) => void
  removeItem: (id: string, itemsKey: string, localizations: string[]) => void
  id: string
}

const SidebarItemContext = React.createContext<
  SidebarItemContextProps | undefined
>(undefined)

export default function SidebarItem(
  props: SidebarItemProps & {id: string; index: number},
) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return <Item {...props} />
  }

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <DraggableOverlay draggableId={props.id}>
            <>
              <DragHandleBar handleProps={provided.dragHandleProps} />
              <Editable {...props} />
            </>
          </DraggableOverlay>
        </div>
      )}
    </Draggable>
  )
}

function Editable(props: SidebarItemProps & {id: string}) {
  const {id} = props
  const updateTemplate = useLeftyUpdate()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  const update = useCallback(
    (
      updated: DeepPartialSubstitute<SidebarItemProps, typeof REMOVE>,
      localization?: Record<string, string>,
    ) => {
      updateTemplate(
        localization
          ? {
              localization: addTranslation(localization),
              sidebarItems: {
                [id]: updated,
              },
            }
          : {
              sidebarItems: {
                [id]: updated,
              },
            },
      )
    },
    [id, updateTemplate, addTranslation],
  )

  const removeList = (localizations: string[]) => {
    updateTemplate({
      localization: removeTranslations(localizations),
      sidebarItems: {
        [id]: REMOVE,
      },
    })
  }

  const removeItem = (
    itemId: string,
    itemsKey: string,
    localizations: string[],
  ) => {
    updateTemplate({
      localization: removeTranslations(localizations),
      sidebarItems: {
        [id]: {
          [itemsKey]: {
            [itemId]: REMOVE,
          },
        },
      },
    })
  }

  return (
    <SidebarItemContext.Provider value={{update, removeList, removeItem, id}}>
      <Item {...props} />
    </SidebarItemContext.Provider>
  )
}

function Item(props: SidebarItemProps) {
  switch (props.type) {
    case AGENDA_LIST:
      return (
        <VisibleOnMatch rules={props.rules}>
          <Scheduled component={props}>
            <AgendaList {...props} />
          </Scheduled>
        </VisibleOnMatch>
      )
    case RESOURCE_LIST:
      return (
        <VisibleOnMatch rules={props.rules}>
          <Scheduled component={props}>
            <ResourceList {...props} />
          </Scheduled>
        </VisibleOnMatch>
      )
    case EMOJI_LIST:
      return (
        <VisibleOnMatch rules={props.rules}>
          <Scheduled component={props}>
            <EmojiList {...props} />
          </Scheduled>
        </VisibleOnMatch>
      )
    case POINTS_SUMMARY:
      return (
        <VisibleOnMatch rules={props.rules}>
          <PointsSummary {...props} />
        </VisibleOnMatch>
      )
    case TICKET_RIBBON_LIST:
      return <TicketRibbons {...props} />
    case SIDEBAR_NAV:
      return <SidebarNav {...props} />
    case TEXT:
      return (
        <VisibleOnMatch rules={props.rules}>
          <Scheduled component={props}>
            <Text {...props} />
          </Scheduled>
        </VisibleOnMatch>
      )
    default:
      return null
  }
}

export function useEditSidebarItem() {
  const context = React.useContext(SidebarItemContext)
  if (context === undefined) {
    throw new Error(
      'useEditSidebarItem must be used within a Editable sidebar item',
    )
  }

  return context
}

export function useAutoUpdateSidebarItem(props: {
  item: DeepPartialSubstitute<SidebarItemProps, typeof REMOVE>
  when: boolean
}) {
  const {item, when} = props
  const {id} = useEditSidebarItem()

  useAutoUpdate({
    values: {
      sidebarItems: {
        [id]: item,
      },
    },
    when,
  })
}
