import styled from 'styled-components'
import React from 'react'

export default function DisabledOverlay(props: {
  show: boolean
  message?: string
}) {
  const {show, message} = props

  if (!show) {
    return null
  }

  return (
    <Overlay aria-label="disabled overlay">
      <OverlayMessage>
        <OverlayText
          dangerouslySetInnerHTML={{
            __html: message ?? '',
          }}
        />
      </OverlayMessage>
    </Overlay>
  )
}

const OverlayText = styled.div`
  color: white;
  padding: 15px;
  font-size: 17px;
  text-align: center;
`

const OverlayMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled.div`
  background: rgb(0 0 0 / 80%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`
