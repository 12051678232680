import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function DescriptionConfig() {
  const saveTemplate = useSaveTemplate()
  const {signUp} = useTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()
  const save = (data: any) => {
    const description = replaceAtPath(
      data,
      'signUp.description',
      '{{sign_up_description}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        sign_up_description: description ?? '',
      }),
    })
  }

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <TextField
          name="signUp.description"
          defaultValue={v(signUp.description)}
          label="Description"
          fullWidth
          inputProps={{
            'aria-label': 'set form description',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}
