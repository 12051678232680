import React, {useCallback, useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import moment from 'moment'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from 'lib/ui/Grid'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import MetricCard from 'Event/Statistics/ZoomAttendance/MetricCard'
import TimeSeries from 'Event/Statistics/ZoomAttendance/TimeSeries'
import {duration} from 'lib/date-time'
import {onUnknownChangeHandler} from 'lib/dom'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import SuccessAlert from 'lib/ui/alerts/SuccessAlert'
import Dialog from 'lib/ui/Dialog'
import {ClockIcon, UserFilledIcon} from 'lib/ui/Icon'
import Header from 'lib/ui/layout/Header'
import Menu from 'lib/ui/Menu'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import Button from 'lib/ui/Button'
import HelperText from 'lib/ui/TextField/HelperText'
import {Title} from 'lib/ui/typography'
import {Spinner} from 'lib/ui/layout/FullPageLoader'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import AttendeeActionForm from 'organization/Event/ZoomAttendance/AttendeeActionForm'
import {
  ACTION_CUSTOM_WEBHOOK,
  ACTION_EXPORT_ATTENDEES,
  ACTION_EXTERNAL_TAG,
  ACTION_OBVIO_TAG_GROUP,
  ATTENDEE_ACTION_TYPES,
  useZoomAttendance,
} from 'organization/Event/ZoomAttendance/ZoomAttendanceProvider'
import {formatDate} from 'organization/EventList/CreateEventForm/Form'
import {ZOOM_ATTENDANCE, usePermissions} from 'organization/PermissionsProvider'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import ScheduledActionForm from './ScheduledActionForm'
import {useToggleArray} from 'lib/toggle'
import ScheduledActionList from './ScheduledActionList'

export default function ZoomAttendancePage() {
  const {can} = usePermissions()
  const routes = useEventRoutes()

  const [success, setSuccess] = useState<string | null>(null)
  const [actionFormOpen, setActionFormOpen] = useState<boolean>(false)
  const [
    actionFormAction,
    setActionFormAction,
  ] = useState<ATTENDEE_ACTION_TYPES | null>(null)
  const [scheduledActionListOpen, scheduledActionListToggle] = useToggleArray()
  const {
    scheduledActionFormOpen,
    scheduledActionFormToggle,
  } = useZoomAttendance()

  const onSuccessMessage = useCallback(
    (message: string | null) => {
      setSuccess(message)
    },
    [setSuccess],
  )

  if (!can(ZOOM_ATTENDANCE)) {
    return <Redirect to={routes.root} />
  }

  const openActionForm = (action: ATTENDEE_ACTION_TYPES) => {
    setActionFormAction(action)
    setActionFormOpen(true)
    setSuccess(null)
  }

  const onCloseActionForm = () => {
    setActionFormAction(null)
    setActionFormOpen(false)
  }

  const onCloseScheduledActionForm = () => {
    scheduledActionFormToggle()
  }

  const openScheduledActionList = () => {
    scheduledActionListToggle()
    setSuccess(null)
  }

  return (
    <PageBreadcrumbs page="Zoom Attendance">
      <Page>
        <Header>
          <Title aria-label="zoom attendance title">Zoom Attendance</Title>
        </Header>

        <Dialog open={actionFormOpen} onClose={onCloseActionForm}>
          <AttendeeActionForm
            action={actionFormAction || ''}
            onClose={onCloseActionForm}
            onSuccessMessage={onSuccessMessage}
          />
        </Dialog>

        <Dialog
          open={scheduledActionFormOpen}
          onClose={onCloseScheduledActionForm}
        >
          <ScheduledActionForm
            action={actionFormAction || ''}
            onCloseActionForm={onCloseActionForm}
            onSuccessMessage={onSuccessMessage}
          />
        </Dialog>

        <Dialog
          onClose={scheduledActionListToggle}
          open={scheduledActionListOpen}
        >
          <ScheduledActionList onClose={scheduledActionListToggle} />
        </Dialog>

        <SuccessAlert>{success}</SuccessAlert>

        <ButtonContainer>
          <Button
            color="primary"
            variant="outlined"
            onClick={openScheduledActionList}
          >
            Scheduled Actions
          </Button>
        </ButtonContainer>

        <Metrics />
        <Pickers />
        <Actions openActionForm={openActionForm} />
        <AttendanceStats />
      </Page>
    </PageBreadcrumbs>
  )
}

const AttendanceStats = () => {
  const {loading} = useZoomAttendance()

  if (loading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledCenter>
            <LoadingSpinner />
            <DimTimeSeries />
          </StyledCenter>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledTimeSeries />
      </Grid>
    </Grid>
  )
}

const LoadingSpinner = () => {
  const [periods, setPeriods] = useState('.')

  useEffect(() => {
    const timer = setInterval(() => {
      setPeriods((prev) => {
        if (prev.length === 5) {
          return '.'
        }
        return prev + '.'
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  return (
    <SpinnerContainer>
      <Spinner />
      <SpinnerLabel>
        Gathering Attendance Data
        <span style={{width: 20}}>{periods}</span>
      </SpinnerLabel>
    </SpinnerContainer>
  )
}

const Metrics = () => {
  const {zoomAttendance} = useZoomAttendance()

  if (!zoomAttendance) {
    return null
  }

  const averageAttendeeLength = calculateAverageSessionLength(
    zoomAttendance.duration_attendee_average,
  )
  const averageSessionLength = calculateAverageSessionLength(
    zoomAttendance.duration_session_average,
  )

  return (
    <MetricGrid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance sessions"
              icon={<UserFilledIcon color="white" />}
              title="Sessions"
              value={zoomAttendance.sessions}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance attendee duration"
              icon={<ClockIcon color="white" />}
              title="Average Attendee Duration"
              titleMobile="Avg. Attendee Duration"
              value={averageAttendeeLength}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance session duration"
              icon={<ClockIcon color="white" />}
              title="Average Session Duration"
              titleMobile="Avg. Session Duration"
              value={averageSessionLength}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance unique attendees"
              icon={<UserFilledIcon color="white" />}
              title="Unique Attendees"
              value={zoomAttendance.unique_attendees}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance min in zoom"
              icon={<UserFilledIcon color="white" />}
              title="Minimum In Zoom"
              value={zoomAttendance.min_in_zoom}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <MetricCard
              aria-label="zoom attendance max in zoom"
              icon={<UserFilledIcon color="white" />}
              title="Maximum In Zoom"
              value={zoomAttendance.max_in_zoom}
            />
          </Grid>
        </Grid>
      </Grid>
    </MetricGrid>
  )
}

const Pickers = () => {
  const {
    eventAreas,
    fetchZoomAttendance,
    loading,
    pickerArea,
    pickerEndDateTime,
    pickerStartDateTime,
    setPickerArea,
    setPickerEndDateTime,
    setPickerStartDateTime,
    zoomAttendance,
  } = useZoomAttendance()

  const handleStartDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }

    setPickerStartDateTime(date.toISOString())
  }

  const handleEndDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }

    setPickerEndDateTime(date.toISOString())
  }

  const handleFetchZoomAttendance = () => {
    fetchZoomAttendance(
      pickerStartDateTime || '',
      pickerEndDateTime || '',
      pickerArea,
    )
  }

  const buttonText = zoomAttendance ? 'Update' : 'Load'

  return (
    <Grid container spacing={2} alignItems="flex-end" justify="center">
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <StyledLocalizedDateTimePicker
          disabled={loading}
          value={pickerStartDateTime}
          labelFunc={formatDate}
          onChange={handleStartDate}
          fullWidth
          label="Start date/time"
          inputProps={{
            'aria-label': 'pick startDateTime',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <StyledLocalizedDateTimePicker
          disabled={loading}
          fullWidth
          inputProps={{
            'aria-label': 'pick endDateTime',
          }}
          label="End date/time"
          labelFunc={formatDate}
          onChange={handleEndDate}
          value={pickerEndDateTime}
        />
      </Grid>
      <Grid item xs={12} sm={9} lg={4} xl={4}>
        <StyledSelect
          aria-label="pick area"
          disabled={loading}
          fullWidth
          label="Area"
          onChange={onUnknownChangeHandler(setPickerArea)}
          value={pickerArea || 0}
        >
          <Option value={0}>All</Option>
          {Object.values(eventAreas).map((area, idx) => (
            <Option key={idx} value={area.id}>
              {area.name}
            </Option>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={12} sm={3} xl={2}>
        <LoadUpdateButton
          aria-label="load zoom attendance"
          color="primary"
          disabled={loading}
          fullWidth
          onClick={handleFetchZoomAttendance}
          variant="contained"
        >
          {buttonText}
        </LoadUpdateButton>
      </Grid>
    </Grid>
  )
}

const Actions = (props: {
  openActionForm: (action: ATTENDEE_ACTION_TYPES) => void
}) => {
  const {openActionForm} = props
  const {area, zoomAttendance} = useZoomAttendance()

  if (!zoomAttendance) {
    return null
  }

  return (
    <Grid container spacing={2} justify="center">
      <FlexGridItem item xs={12} sm={3} xl={2}>
        <Menu
          button={({open}) => (
            <StyledButton
              disabled={!area}
              color="primary"
              variant="outlined"
              onClick={open}
            >
              Attendee Actions
            </StyledButton>
          )}
        >
          <ActionMenuItem
            aria-label="zoom attendance action export attendees"
            label={ACTION_EXPORT_ATTENDEES}
            onClick={() => openActionForm(ACTION_EXPORT_ATTENDEES)}
          />
          <ActionMenuItem
            aria-label="zoom attendance action obvio taggroup"
            label={ACTION_OBVIO_TAG_GROUP}
            onClick={() => openActionForm(ACTION_OBVIO_TAG_GROUP)}
          />
          <ActionMenuItem
            aria-label="zoom attendance action external tag"
            label={ACTION_EXTERNAL_TAG}
            onClick={() => openActionForm(ACTION_EXTERNAL_TAG)}
          />
          <IfOwnerHasPlan plan={ENTERPRISE}>
            <ActionMenuItem
              aria-label="zoom attendance action custom webhook"
              label={ACTION_CUSTOM_WEBHOOK}
              onClick={() => openActionForm(ACTION_CUSTOM_WEBHOOK)}
            />
          </IfOwnerHasPlan>
        </Menu>
      </FlexGridItem>
      <FlexGridItem item xs={12}>
        <AreaHelperText selectedArea={area} />
      </FlexGridItem>
    </Grid>
  )
}

const ActionMenuItem = React.forwardRef<
  HTMLDivElement,
  {
    'aria-label'?: string
    label: string
    onClick?: () => void
  }
>((props, _ref) => {
  const {label, onClick} = props

  return (
    <MenuItem onClick={onClick} aria-label={props['aria-label']}>
      {label}
    </MenuItem>
  )
})

const AreaHelperText = (props: {selectedArea: number | null}) => {
  if (props.selectedArea) {
    return null
  }

  return (
    <StyledHelperText>
      Select an Area to perform Zoom Attendance Actions
    </StyledHelperText>
  )
}

const calculateAverageSessionLength = (durationSeconds: number) => {
  const base = moment('2000-01-01 00:00:00')
  const end = moment('2000-01-01 00:00:00').add(durationSeconds, 'seconds')
  const sessionLength = duration(base.format(), end.format())

  return `${sessionLength.hours}:${sessionLength.minutes}:${sessionLength.seconds}`
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const FlexGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
`

const MetricGrid = styled(Grid)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledHelperText = styled(HelperText)`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledTimeSeries = styled(TimeSeries)`
  margin-top: ${(props) => props.theme.spacing[2]};
`

const StyledLocalizedDateTimePicker = styled(LocalizedDateTimePicker)`
  margin-bottom: 0;
`

const StyledSelect = styled(Select)`
  margin-bottom: 0;
`

const DimTimeSeries = styled(StyledTimeSeries)`
  margin-top: ${(props) => props.theme.spacing[2]};
  opacity: 0.3;
`

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
`

const StyledCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[4]};
`
const LoadUpdateButton = styled(StyledButton)`
  width: 100%;
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.spacing[1]};
`

const SpinnerLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  align-items: center;
`
