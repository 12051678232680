import React, {useCallback} from 'react'
import JsonUpdateProvider, {JsonSave} from 'lib/JsonUpdateProvider'
import {Client} from 'lib/ui/api-client'
import {useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'
import {UpsellPage, UpsellPageTemplate} from 'Event/Marketplace/upsell-page'
import {useUpdateUpsellPageTemplate} from 'lib/marketplace-api/upsell-page/use-update-upsell-page-template'

const TemplateUpdateContext = React.createContext<
  JsonSave<UpsellPage['template']> | undefined
>(undefined)

interface UpsellPageTemplateUpdateProviderProps {
  children: (template: UpsellPageTemplate) => JSX.Element
  upsellPage: UpsellPage
  client: Client
}

export default function UpsellPageTemplateUpdateProvider(
  props: UpsellPageTemplateUpdateProviderProps,
) {
  const {children, upsellPage, client} = props

  const updateTemplate = useUpdateUpsellPageTemplate({
    client,
  })
  const {event} = useEvent()

  const qc = useQueryClient()

  const updateCachedTemplate = useCallback(
    (template: UpsellPageTemplate) => {
      qc.setQueryData<UpsellPage | undefined>(
        ['events', event.id, 'upsell_page'],
        (upsellPage) => {
          if (!upsellPage) {
            return
          }

          return {
            ...upsellPage,
            template,
          }
        },
      )
    },
    [qc, event],
  )

  const template = Array.isArray(upsellPage.template.sections)
    ? {
        ...upsellPage.template,
        sections: {},
      }
    : upsellPage.template

  return (
    <JsonUpdateProvider
      Context={TemplateUpdateContext}
      updatedSocketEventName={`.upsell_page.${upsellPage.id}.updated`}
      value={template}
      save={(updates) =>
        updateTemplate.mutateAsync({upsellPage: upsellPage, updates})
      }
      onUpdate={updateCachedTemplate}
    >
      {(template) => children(template)}
    </JsonUpdateProvider>
  )
}

export function useUpdateTemplate() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateTemplate must be used within UpsellPageTemplateUpdateProvider.',
    )
  }

  return context
}
