import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import Tickets from 'organization/Marketplace/Tickets'
import {
  MANAGE_MARKETPLACE,
  usePermissions,
} from 'organization/PermissionsProvider'
import PurchasePageConfig from 'organization/Marketplace/PurchasePageConfig'
import StripeConnectedCallbackHandler from 'organization/Marketplace/StripeConnectedCallbackHandler'
import CreateTicketForm from 'organization/Marketplace/Tickets/TicketForm/CreateTicketForm'
import EditTicketForm from 'organization/Marketplace/Tickets/TicketForm/EditTicketForm'
import ThankYouPageConfig from 'organization/Marketplace/ThankYouPageConfig'
import UpsellPageConfig from 'organization/Marketplace/UpsellPageConfig'

export default function MarketplaceRoutes() {
  const {routes} = useOrganization()

  const {can} = usePermissions()
  if (!can(MANAGE_MARKETPLACE)) {
    return <Redirect to={routes.settings} />
  }

  return (
    <Switch>
      <Route exact path={routes.events[':event'].marketplace.tickets.root}>
        <Tickets />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.tickets.add}>
        <CreateTicketForm />
      </Route>
      <Route
        exact
        path={routes.events[':event'].marketplace.tickets[':ticket'].root}
      >
        <EditTicketForm />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.purchase_page}>
        <PurchasePageConfig />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.thank_you_page}>
        <ThankYouPageConfig />
      </Route>
      <Route exact path={routes.events[':event'].marketplace.upsell_page}>
        <UpsellPageConfig />
      </Route>
      <Route exact path={routes.marketplace.account.connected}>
        <StripeConnectedCallbackHandler />
      </Route>
      <Redirect to={routes.settings} />
    </Switch>
  )
}
