import {useStripe} from '@stripe/react-stripe-js'
import {
  CreatePaymentMethodData,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import {usePurchase} from '../../PurchaseContext'
import React, {useEffect, useState} from 'react'
import styles from './PurchaseForm.module.css'
import usePurchaseFormFields from './use-purchase-form-fields'
import {
  PurchaseFormContext,
  PurchaseFormProviderProps,
} from './PurchaseFormProvider'

export default function StripePurchaseFormProvider(
  props: PurchaseFormProviderProps,
) {
  const {children, config} = props

  const {selectedTicket, submit, mainPurchaseData} = usePurchase()

  const {
    setEmail,
    email,
    firstName,
    setFirstName,
    setFirstNameError,
    lastName,
    setLastName,
    setLastNameError,
    phoneNumber,
    setPhoneNumber,
    setPhoneNumberError,
    billingAddress1,
    isExistingAttendee,
    billingAddress2,
    billingCountryId,
    billingAddress1Error,
    billingCity,
    billingCityError,
    billingCountryIdError,
    billingStateId,
    billingStateIdError,
    billingZipPostal,
    billingZipPostalError,
    shippingAddress1,
    shippingAddress1Error,
    shippingAddress2,
    shippingCity,
    shippingCityError,
    shippingCountryId,
    shippingCountryIdError,
    shippingStateId,
    shippingStateIdError,
    shippingZipPostal,
    shippingZipPostalError,
    setShippingAddress1,
    setShippingAddress1Error,
    setShippingAddress2,
    setShippingCity,
    setBillingAddress1,
    setBillingAddress1Error,
    setBillingAddress2,
    setBillingCity,
    setBillingCityError,
    setBillingCountryId,
    setBillingCountryIdError,
    setBillingStateId,
    setBillingStateIdError,
    setBillingZipPostal,
    setBillingZipPostalError,
    setEmailError,
    setHasError,
    setShippingCityError,
    setShippingCountryIdError,
    setShippingCountryId,
    setShippingStateId,
    setShippingStateIdError,
    setShippingZipPostal,
    setShippingZipPostalError,
    setUseSameAddressForShipping,
    validateBillingForm,
    validateForm,
    validateShippingForm,
    useSameAddressForShipping,
    firstNameError,
    phoneNumberError,
    emailError,
    lastNameError,
    hasError,
    getData,
  } = usePurchaseFormFields({
    ...config,
    values: mainPurchaseData,
  })

  const [hasFilledCard, setHasFilledCard] = useState(false)
  const [cardError, setCardError] = useState<string | null>(null)

  const stripe = useStripe()

  const [
    cardElement,
    setCardElement,
  ] = useState<StripeCardNumberElement | null>(null)

  useEffect(() => {
    if (!cardElement) {
      return
    }

    cardElement.on('change', (event) => {
      setHasFilledCard(event.complete)
      setCardError(event.error?.message ?? null)
    })

    return () => {
      cardElement.off('change')
    }
  }, [cardElement])

  const handleSubmit = async () => {
    const valid = validateForm()
    if (!valid) {
      return
    }

    const data = getData()

    if (!selectedTicket) {
      return
    }

    if (
      selectedTicket.billing_address_enabled &&
      selectedTicket.billing_address_required
    ) {
      const validBillingForm = validateBillingForm()
      if (!validBillingForm) {
        return
      }
    }

    if (
      selectedTicket.shipping_address_enabled &&
      selectedTicket.shipping_address_required
    ) {
      const validShippingForm = validateShippingForm()
      if (!validShippingForm) {
        return
      }
    }

    // Submit free tickets immediately without registering a card with
    // Stripe
    if (selectedTicket.price === 0) {
      submit({
        payment_method_id: null,
        ...data,
      })

      return
    }

    if (!stripe || !cardElement) {
      return
    }

    const paymentMethodData: CreatePaymentMethodData = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        phone: data.phone_number,
      },
    }

    const {paymentMethod} = await stripe.createPaymentMethod(paymentMethodData)

    submit({
      payment_method_id: paymentMethod?.id ?? null,
      ...data,
    })
  }

  return (
    <PurchaseFormContext.Provider
      value={{
        useSameAddressForShipping,
        setUseSameAddressForShipping,
        setCardElement,
        firstName,
        setFirstName,
        firstNameError,
        setFirstNameError,
        lastName,
        setLastName,
        lastNameError,
        setLastNameError,
        email,
        setEmail,
        emailError,
        setEmailError,
        phoneNumber,
        setPhoneNumber,
        phoneNumberError,
        setPhoneNumberError,
        billingCountryId,
        setBillingCountryId,
        billingCountryIdError,
        setBillingCountryIdError,
        billingStateId,
        setBillingStateId,
        billingStateIdError,
        setBillingStateIdError,
        billingAddress1,
        setBillingAddress1,
        billingAddress1Error,
        setBillingAddress1Error,
        billingAddress2,
        setBillingAddress2,
        billingCity,
        setBillingCity,
        billingCityError,
        setBillingCityError,
        billingZipPostal,
        setBillingZipPostal,
        billingZipPostalError,
        setBillingZipPostalError,
        shippingCountryId,
        setShippingCountryId,
        shippingCountryIdError,
        setShippingCountryIdError,
        shippingStateId,
        setShippingStateId,
        shippingStateIdError,
        setShippingStateIdError,
        shippingAddress1,
        setShippingAddress1,
        shippingAddress1Error,
        setShippingAddress1Error,
        shippingAddress2,
        setShippingAddress2,
        shippingCity,
        setShippingCity,
        shippingCityError,
        setShippingCityError,
        shippingZipPostal,
        setShippingZipPostal,
        shippingZipPostalError,
        setShippingZipPostalError,
        hasError,
        setHasError,
        validateForm,
        validateBillingForm,
        validatShippingForm: validateShippingForm,
        onSubmit: handleSubmit,
        hasFilledCard,
        cardError,
        isExistingAttendee,
      }}
    >
      <form
        className={styles.purchaseForm}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        {children}
      </form>
    </PurchaseFormContext.Provider>
  )
}

export function usePurchaseForm() {
  const context = React.useContext(PurchaseFormContext)
  if (context === undefined) {
    throw new Error(
      'usePurchaseForm must be used within a PurchaseFormProvider',
    )
  }

  return context
}
