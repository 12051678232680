import {useLeftyTemplate} from 'Event/template/Lefty'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import styled from 'styled-components'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {TemplateSave} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'

export default function SponsorsHeaderConfig() {
  const save = useSaveTemplate()
  const {sponsors} = useLeftyTemplate()

  const {
    form: {register, control},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const handleSave: TemplateSave<Template> = (data) => {
    const menuTitle = replaceAtPath(
      data,
      'sponsors.menuTitle',
      '{{sponsors_menu_title}}',
    )

    const title = replaceAtPath(data, 'sponsors.title', '{{sponsors_title}}')

    const description = replaceAtPath(
      data,
      'sponsors.description',
      '{{sponsors_description}}',
    )

    const secondaryTitle = replaceAtPath(
      data,
      'sponsors.secondaryTitle',
      '{{sponsors_secondary_title}}',
    )

    save({
      ...data,
      localization: addTranslation({
        sponsors_menu_title: menuTitle ?? 'Our Sponsors',
        sponsors_title: title || 'Sponsors',
        sponsors_description: description || '',
        sponsors_secondary_title: secondaryTitle || '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={handleSave}>
      <SettingsPanel>
        <Title>Menu</Title>
        <TextField
          name="sponsors.menuTitle"
          defaultValue={v(sponsors.menuTitle || 'Our Sponsors')}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'menu title',
            ref: register,
          }}
        />
        <Title>Page</Title>
        <TextField
          name="sponsors.title"
          defaultValue={v(sponsors.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'sponsors title',
            ref: register,
          }}
        />
        <TextField
          name="sponsors.secondaryTitle"
          defaultValue={v(sponsors.secondaryTitle)}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'sponsors secondary title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <StyledTextEditorContainer>
          <Controller
            name="sponsors.description"
            defaultValue={v(sponsors.description)}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </StyledTextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
