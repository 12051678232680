import Button from 'lib/ui/Button'
import React from 'react'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {generateHashId} from 'lib/crypto/hash'

export default function AddResourceButton(props: {
  className?: string
  sectionId: string
}) {
  const {sectionId} = props

  const updateTemplate = useTownhallUpdate()

  const addResource = () => {
    const newResource: Resource = {
      name: 'Resource',
      filePath: '',
      actionId: null,
      icon: null,
      isVisible: true,
      rules: [],
      backgroundColor: '#000000',
      font: null,
      fontSize: 16,
      textColor: '#FFFFFF',
      hoverTextColor: '#FFFFFF',
      hoverBackgroundColor: '#000000',
      disableHover: false,
      height: 34,
      size: 4,
      newLine: false,
      borderRadius: 0,
      borderWidth: 0,
      borderColor: '#FFFFFF',
      hoverBorderColor: '#FFFFFF',
    }
    generateHashId([
      'townhall_',
      'resource_item',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `resource_${id}`
      updateTemplate({
        dashboardSections: {
          [sectionId]: {
            items: {
              [componentId]: newResource,
            },
          },
        },
      })
    })
  }

  return (
    <Button
      size="large"
      variant="outlined"
      color="secondary"
      fullWidth
      aria-label="add resource"
      onClick={addResource}
      className={props.className}
    >
      Add Resource
    </Button>
  )
}
