import React, {useMemo, useState} from 'react'
import {
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  PieChart,
  Pie,
} from 'recharts'
import {Box, Container} from '@material-ui/core'

interface ChartEntry {
  name: string
  value: number
}

type ChartProps = {
  name: string
  data: ChartEntry[]
}

export default function Chart({data, name}: ChartProps) {
  const [currentChartType, setCurrentChartType] = useState(0)

  const chartTypes = ['Pie Chart', 'Bar Graph']

  const colors = ['#38de84', '#3690de', '#3ac1df', '#de8737', '#6c36de']

  const maxLengthName = useMemo(() => {
    let nameLength = data[0].name.length
    data.forEach((item) => {
      if (item.name.length > nameLength) {
        nameLength = item.name.length
      }
    })
    return nameLength
  }, [data])

  return (
    <Container
      style={{
        background: '#d9d9d9',
        padding: 0,
        marginBottom: 32,
        borderRadius: 4,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#f5f5f5"
        px={3}
        py={1.5}
        borderRadius="4px 4px 0 0"
      >
        {name}
        <Box display="flex" bgcolor="#d9d9d9" py="4px">
          {chartTypes.map((type, i) => {
            const isActive = Boolean(i === currentChartType)
            return (
              <p
                key={i}
                style={{
                  backgroundColor: isActive ? '#f5f5f5' : 'transparent',
                  padding: '8px 16px',
                  margin: '0 4px',
                  borderRadius: 4,
                  opacity: isActive ? 1 : 0.5,
                  cursor: 'pointer',
                }}
                onClick={() => setCurrentChartType(i)}
              >
                {type}
              </p>
            )
          })}
        </Box>
      </Box>
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {currentChartType === 0 && (
          <Box display="flex" width="100%" height="100%">
            <ResponsiveContainer width="50%">
              <PieChart>
                <Tooltip
                  formatter={(value: number, _name: string, props: any) => [
                    `${value}%`,
                    props.payload.name,
                  ]}
                  labelFormatter={() => ''}
                />
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={120}
                  stroke="none"
                  dataKey="value"
                >
                  {data.map((_, index: number) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="50%"
              paddingX={4}
            >
              {data.map((item, index) => (
                <Box
                  key={`item-${index}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  <div
                    style={{
                      width: 11,
                      minWidth: 11,
                      height: 11,
                      backgroundColor: colors[index % colors.length],
                      marginRight: 8,
                      borderRadius: 3,
                    }}
                  ></div>
                  <div
                    style={{
                      width: maxLengthName > 16 ? 100 : maxLengthName * 12,
                      lineHeight: 1.2,
                      marginRight: 32,
                    }}
                  >
                    {item.name}
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: 11,
                      backgroundColor: '#f5f5f5',
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        width: `${item.value}%`,
                        height: '100%',
                        backgroundColor: colors[index % colors.length],
                        borderRadius: 3,
                      }}
                    ></div>
                  </div>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {currentChartType === 1 && (
          <ResponsiveContainer width="90%" height="90%">
            <BarChart data={data}>
              <XAxis dataKey="name" tickLine={false} />
              <YAxis domain={[0, 100]} tickLine={false} unit="%" />
              <Tooltip
                formatter={(value: number, _name: string, props: any) => [
                  `${value}%`,
                  props.payload.name,
                ]}
                labelFormatter={() => ''}
              />
              <Bar dataKey="value">
                {data.map((_, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Container>
  )
}
