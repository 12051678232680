import Button from '@material-ui/core/Button'
import {useEditMode} from 'Event/EditModeProvider'
import React from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function NewSidebarNavButton(props: {
  className?: string
  listId: string
}) {
  const isEditMode = useEditMode()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  if (!isEditMode) {
    return null
  }

  const addButton = () => {
    generateHashId([
      'sidebar_nav_button',
      Math.random().toString(),
      Date.now().toString(),
    ]).then((id) => {
      const componentId = `sidebar_button_${id}`

      const button: NavButtonWithSize = {
        text: `{{${componentId}_text}}`,
        link: '',
        size: 12,
        rules: [],
        isAreaButton: false,
        isFormButton: false,
        isCertificateButton: false,
        areaId: null,
        formId: null,
        actionId: null,
        certificateId: null,
        isVisible: true,
        infusionsoftTag: null,
        isImageUpload: false,
        mailchimpTag: null,
        zapierTag: null,
        highLevelTag: null,
        webhook: null,
        disableHover: true,
        backgroundColor: '#000000',
        hoverBackgroundColor: '#000000',
        hubspotTag: null,
        activeCampaignTag: null,
        convertKitTag: null,
        ontraportTag: null,
      }

      saveTemplate({
        sidebarItems: {
          [props.listId]: {buttons: {[componentId]: button}},
        },
        localization: addTranslation({
          [`${componentId}_text`]: 'Button',
        }),
      })
    })
  }
  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      color="secondary"
      aria-label="add sidebar nav button"
      onClick={addButton}
      className={props.className}
    >
      New Button
    </Button>
  )
}
