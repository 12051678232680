import {PurchaseFormBlock} from '../PurchaseForm'

export const createPurchaseFormBlock = (): PurchaseFormBlock => ({
  type: 'PurchaseForm',
  visibility: 'all',
  title: {
    enabled: true,
    text: 'Your Order',
    font: null,
    fontSize: 36,
    margin: {
      top: 0,
      right: 0,
      bottom: 16,
      left: 0,
    },
  },
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  isHidden: false,
  border: {
    width: 0,
    radius: 14,
    color: '#000000',
  },
  padding: {
    left: 30,
    right: 30,
    top: 30,
    bottom: 30,
  },
  alignment: {
    vertical: 'start',
    horizontal: 'center',
  },
  input: {
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    fontSize: 23,
    spacing: 11,
    border: {
      width: 1,
      radius: 0,
      color: '#3490DC',
    },
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
    placeholder: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      billingAddress1: 'Billing Address 1',
      billingAddress2: 'Billing Address 2',
      city: 'City',
      state: 'State',
      zipcode: 'Zip Code',
      country: 'Country',
      shippingAddress1: 'Shipping Address 1',
      shippingAddress2: 'Shipping Address 1',
      shippingcity: 'City',
      shippingstate: 'State',
      shippingzipcode: 'Zip Code',
      shippingcountry: 'Country',
    },
  },
  purchaseButton: {
    text: 'Purchase {{ticket_price}}',
    margin: {
      top: 32,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  buttonStyle: {
    textColor: '#FFFFFF',
    fontSize: 20,
    backgroundColor: '#3490DC',
    borderColor: '#000000',
    borderWidth: 0,
    borderRadius: 5,
    size: 12,
    height: 44,
    font: null,
    hoverBackgroundColor: 'rgba(52, 144, 220, 0.9)',
    width: 100,
    disabledBackgroundColor: '#3490DC',
    disabledTextColor: '#FFFFFF',
  },
  buttonsAlignment: 'center',
  requirePhoneNumber: false,
  errors: {
    requiredField: 'Please Complete Required Fields',
  },
  questions: {
    sameShippingAddress: 'Is your shipping address is the same as billing?',
  },
})
