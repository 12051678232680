import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import {ThankYouPageContext} from 'Event/Marketplace/ThankYouPageContext'
import CountriesProvider, {State} from 'Event/Marketplace/lib/CountriesProvider'
import {fillUpsellPageTemplate} from 'Event/Marketplace/lib/fill-upsell-page-template'
import {useCountries} from 'lib/event-api/countries/use-countries'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import TemplateEditor from 'organization/Event/TemplateEditor'
import UpsellPageConfigContent from 'organization/Marketplace/UpsellPageConfig/UpsellPageConfigContent'
import UpsellPageConfigLoader from 'organization/Marketplace/UpsellPageConfig/UpsellPageConfigLoader'
import UpsellPageTemplateUpdateProvider from 'organization/Marketplace/UpsellPageConfig/UpsellPageTemplateUpdateProvider'
import useAutoCreateUpsellPage from 'organization/Marketplace/UpsellPageConfig/use-auto-create-upsell-page'
import ConfigPageProvider from 'organization/Marketplace/config/ConfigPageProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useCallback} from 'react'
import {Redirect} from 'react-router'

export default function UpsellPageConfig() {
  const {data: account} = useMarketplaceAccount()
  const {upsellPage} = useAutoCreateUpsellPage()
  const {routes} = useOrganization()

  const {client: marketplaceClient} = useEventMarketplaceClient()

  const {data: countries = []} = useCountries()

  const fetchStates = useCallback((countryId: number) => {
    return client.get<State[]>(api(`/countries/${countryId}/states`))
  }, [])

  if (account === null) {
    return <Redirect to={routes.settings} />
  }

  if (!account || !upsellPage || !marketplaceClient) {
    return <UpsellPageConfigLoader />
  }

  return (
    <CountriesProvider countries={countries} fetchStates={fetchStates}>
      <ThankYouPageContext.Provider
        value={{
          loginUrl: null,
        }}
      >
        <ConfigPageProvider>
          <PurchaseProvider>
            <UpsellPageTemplateUpdateProvider
              upsellPage={upsellPage}
              client={marketplaceClient}
            >
              {(template) => (
                <TemplateEditor template={template}>
                  {(template) => (
                    <UpsellPageConfigContent
                      template={fillUpsellPageTemplate(template)}
                      upsellPage={upsellPage}
                      client={marketplaceClient}
                    />
                  )}
                </TemplateEditor>
              )}
            </UpsellPageTemplateUpdateProvider>
          </PurchaseProvider>
        </ConfigPageProvider>
      </ThankYouPageContext.Provider>
    </CountriesProvider>
  )
}
