import React from 'react'
import {BaseProps} from '.'
import InputLabel from './InputLabel'

export type BaseTextFieldProps<T extends string | number> = {
  className?: string
  rounded?: boolean
  variant?: 'filled' | 'outlined'
  fullWidth?: boolean
  isTight?: boolean
  name?: string
  type?: 'text' | 'password' | 'number'
  'aria-label'?: string
  placeholder?: string
  value?: T
  defaultValue?: T | null
  disabled?: boolean
  error?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  helperText?: string | JSX.Element
  required?: boolean
  endAdornment?: JSX.Element
  startAdornment?: JSX.Element
  inputProps?: BaseProps<HTMLInputElement> &
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
  label?: string
  emptyValue?: string | number
}

export type TextFieldProps = Omit<BaseTextFieldProps<string>, 'onChange'> & {
  onChange?: (value: string) => void
}

type HelperTextProps = {
  children?: string | JSX.Element
  error?: boolean
  disabled?: boolean
  className?: string
}

export default function TextField(props: TextFieldProps) {
  const {label, ...textFieldProps} = props
  return (
    <>
      <InputLabel>{label}</InputLabel>
      <BaseTextField
        type="text"
        {...textFieldProps}
        onChange={(e) =>
          props.onChange && props.onChange(e.currentTarget.value)
        }
      />
    </>
  )
}

export function BaseTextField<T extends string | number>(
  props: BaseTextFieldProps<T>,
) {
  const {
    endAdornment = null,
    startAdornment = null,
    variant,
    inputProps,
    value,
    className,
    defaultValue,
    ...forwardProps
  } = props

  const hasBorder = variant === 'outlined'

  const baseProps = {
    ...forwardProps,
    backgroundColor: '#ffffff',
    color: '#000000',
    hasBorder,
    error: Boolean(props.error),
  }

  // Dynamically add 'value' relevant props to avoid warning about having
  // both 'value', and 'defaultValue' set.
  const keys = Object.keys(props)
  const hasValue = keys.includes('value')

  // Need an onChange when `value` is defined, or MUI will throw a warning.
  // In cases where we don't care about an onChange this should silence
  // the warning.
  const noopOnChange = () => {}

  const valueProps = hasValue
    ? {
        value: value || getEmptyValue(props.type || '', props.emptyValue),
        onChange: props.onChange || noopOnChange,
      }
    : {
        defaultValue: defaultValue || '',
      }

  const boxClassName = className ?? 'textFieldBox'
  const inputClassName = inputProps?.className ?? 'textFieldInput'

  const {
    error,
    backgroundColor,
    rounded,
    hasBorder: _hasBorder,
    helperText,
    placeholder,
  } = baseProps

  const color = inputProps?.disabled
    ? '#c4c4c4'
    : inputProps?.color ?? '#000000'

  return (
    <>
      <style>
        {`
          .${boxClassName} {
              display: flex;
              width: ${props.fullWidth ? '100%' : 'auto'};
              flex-direction: column;
              margin-bottom: 0px;
          }

          .${inputClassName} { 
              height: 40px;
              box-sizing: border-box;
              padding: 12px;
              color: ${color};
              background-color: ${backgroundColor};
              border-radius: ${rounded ? '24px' : '4px'};
              border-width: ${hasBorder || error ? '1px' : 0};
              border-color: ${error ? '#f44336' : '#dfdfdf'};
              border-style: solid;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
          }

          .${inputClassName}:focus {
              outline: none !important;
              border-color: ${baseProps.error ? '#f44336' : '#1976D2'};
          }
        
          .${inputClassName}::placeholder {
            color: ${inputProps?.disabled ? '#c4c4c4' : 'inherit'};
          }

          .${inputClassName}:disabled {
            cursor: not-allowed;
            background: #e7e7e7!important;
            color: #c4c4c4!important;
          }
        `}
      </style>
      <div className={boxClassName}>
        <div style={{display: 'flex'}}>
          {startAdornment}
          <input
            type="text"
            {...valueProps}
            {...inputProps}
            name={props.name}
            placeholder={placeholder}
          />
          {endAdornment}
        </div>
        <HelperText error={props.error} disabled={props.disabled}>
          {helperText}
        </HelperText>
      </div>
    </>
  )
}

function getEmptyValue(type: string, value?: string | number) {
  if (type === 'number') {
    return value === undefined ? 0 : value
  }

  return value === undefined ? '' : value
}

function HelperText(props: HelperTextProps) {
  const {children, className} = props
  if (!children) {
    return null
  }

  return (
    <div
      style={{
        marginTop: '12px',
        color: props.disabled ? '#c4c4c4' : '#939393',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
      }}
      className={className}
    >
      <Content {...props} />
    </div>
  )
}

function Content(props: HelperTextProps) {
  if (props.error) {
    return <span style={{color: '#f44336'}}>{props.children}</span>
  }

  if (!props.children) {
    return null
  }

  if (typeof props.children === 'string') {
    return <span>{props.children}</span>
  }

  return props.children
}
