import React from 'react'
import {AgendaSectionProps} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import Settings from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaSectionConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaSectionConfig/Styling'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function AgendaSectionConfig(props: {
  agendaSection: AgendaSectionProps
  sectionId: string
}) {
  const {agendaSection, sectionId} = props

  const save = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const {
    form: {setValue, control},
  } = useConfig()

  const handleSave = (data: any) => {
    const {dashboardSections, navBar} = data
    const {menuItems} = navBar
    const {
      [sectionId]: {title: menuTitle, ...others},
    } = menuItems
    const {
      [sectionId]: {description, title, footer, ...otherUpdates},
    } = dashboardSections

    save({
      ...data,
      localization: addTranslation({
        [`navBar_menuItems_${sectionId}_title`]: menuTitle,
        [`dashboardSections_${sectionId}_title`]: title,
        [`dashboardSections_${sectionId}_description`]: description,
        [`dashboardSections_${sectionId}_footer`]: footer,
      }),
      navBar: {
        menuItems: {
          [sectionId]: {
            title: `{{navBar_menuItems_${sectionId}_title}}`,
            ...others,
          },
        },
      },
      dashboardSections: {
        [sectionId]: {
          title: `{{dashboardSections_${sectionId}_title}}`,
          description: `{{dashboardSections_${sectionId}_description}}`,
          footer: `{{dashboardSections_${sectionId}_footer}}`,
          ...otherUpdates,
        },
      },
    })
  }

  const remove = () =>
    save({
      localization: removeTranslations([
        `navBar_menuItems_${sectionId}_title`,
        `dashboardSections_${sectionId}_title`,
        `dashboardSections_${sectionId}_description`,
        `dashboardSections_${sectionId}_footer`,
      ]),
      dashboardSections: {
        [sectionId]: REMOVE,
      },
      navBar: {
        menuItems: {
          [sectionId]: REMOVE,
        },
      },
    })

  return (
    <Config title="Agendas" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings agendaSection={agendaSection} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling agendaSection={agendaSection} sectionId={sectionId} />
      </StylingPanel>
      <RulesPanel
        rules={agendaSection.rules}
        control={control}
        name={`dashboardSections.${sectionId}.rules`}
      >
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={agendaSection}
          fromName={`dashboardSections.${sectionId}.showingFrom`}
          untilName={`dashboardSections.${sectionId}.showingUntil`}
        />
      </RulesPanel>
    </Config>
  )
}
