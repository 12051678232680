import React, {useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import Page from 'organization/Event/Page'
import Form, {
  TicketFormData,
} from 'organization/Marketplace/Tickets/TicketForm/Form'
import {useParams} from 'react-router-dom'
import {GroupPair} from 'lib/ui/form/DescribedGroupsInput'
import {TicketsBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {
  UpdateTicketData,
  useUpdateTicket,
} from 'lib/marketplace-api/tickets/use-update-ticket'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {useValidatedForm} from 'lib/form'
import {useMarketplaceAccount} from 'lib/marketplace-api/accounts/use-marketplace-account'

interface ContentProps {
  ticket: MarketplaceTicket
}

export default function EditTicketForm() {
  const eventRoutes = useEventRoutes()
  const {ticket: routeId} = useParams<{ticket: string}>()
  const ticketId = parseInt(routeId)

  const {data: tickets, isLoading} = useEventTickets()

  const ticket = tickets?.find((ticket) => ticket.id === ticketId)

  if (isLoading && !ticket) {
    return null
  }

  if (!ticket) {
    return <Redirect to={eventRoutes.marketplace.tickets.root} />
  }

  return <Content ticket={ticket} />
}

function Content(props: ContentProps) {
  const {data: account} = useMarketplaceAccount()
  const {ticket} = props
  const {mutate: updateTicket, isLoading: isUpdatingTicket} = useUpdateTicket({
    ticket,
  })

  const history = useHistory()
  const {
    errors: formErrors,
    handleSubmit,
    control,
    setValue,
    responseError,
    setResponseError,
    watch,
  } = useValidatedForm()
  const [newGroups, setNewGroups] = useState<GroupPair[]>([])
  const [existingGroups, setExistingGroups] = useState<GroupPair[]>(
    getGroupPairs(ticket.groups ?? []),
  )
  const eventRoutes = useEventRoutes()
  const [accountError, setAccountError] = useState<boolean>(false)

  const submit = (data: TicketFormData) => {
    const {
      infusionsoft_tag,
      active_campaign_tag,
      highlevel_tag,
      ontraport_tag,
      convert_kit_tag,
      mailchimp_tag,
      ...formData
    } = data

    const hasAccount = account && account.completed_setup
    const isPaidTicket = data.price > 0
    if (!hasAccount && isPaidTicket) {
      setAccountError(true)
      return
    }

    const groupsAsStrings = [...existingGroups, ...newGroups].map((group) => {
      // Need to convert to unkown first because there is no overlap, we've
      // actually got a GroupPair[] in data.
      const temp = (group as unknown) as GroupPair

      // Backend wants the group attribute to be an array of strings, group:value
      // in format.
      return `${temp.key}:${temp.value}`
    })

    const payload: UpdateTicketData = {...ticket, ...formData}

    // Put in our massaged string[] back into the data that is being submitted.
    data.groups = groupsAsStrings?.filter((group) => group !== ':')
    if (!data.groups) {
      payload.groups = ticket.groups
    }

    if (infusionsoft_tag) {
      payload['infusionsoft_tag_id'] = infusionsoft_tag.id
      payload['infusionsoft_tag_name'] = infusionsoft_tag.name
    }

    if (mailchimp_tag) {
      payload['mailchimp_tag_name'] = mailchimp_tag.name
    }

    if (convert_kit_tag) {
      payload['convert_kit_tag_id'] = convert_kit_tag.id
      payload['convert_kit_tag_name'] = convert_kit_tag.name
    }

    if (ontraport_tag) {
      payload['ontraport_tag_id'] = ontraport_tag.tag_id
      payload['ontraport_tag_name'] = ontraport_tag.tag_name
    }
    if (active_campaign_tag) {
      payload['active_campaign_tag_id'] = active_campaign_tag.id
      payload['active_campaign_tag_name'] = active_campaign_tag.tag
    }
    if (highlevel_tag) {
      payload['highlevel_tag_name'] = highlevel_tag.name
    }

    updateTicket(payload, {
      onError: (error: any) => {
        setResponseError(error)
      },
      onSuccess: () => {
        history.push(eventRoutes.marketplace.tickets.root)
      },
    })
  }

  return (
    <TicketsBreadcrumbs page={ticket.name}>
      <Page>
        <Form
          control={control}
          formErrors={formErrors}
          onSubmit={handleSubmit(submit)}
          responseError={responseError}
          setValue={setValue}
          submitting={isUpdatingTicket}
          ticket={ticket}
          existingGroups={existingGroups}
          onChangeExistingGroups={setExistingGroups}
          newGroups={newGroups}
          onChangeNewGroups={setNewGroups}
          watch={watch}
          isMissingStripeAccount={accountError}
        />
      </Page>
    </TicketsBreadcrumbs>
  )
}

const getGroupPairs = (groups: string[]): GroupPair[] => {
  const groupPairs = groups.map((group) => {
    const temp = group.split(':')

    return {key: temp[0], value: temp[1]}
  })

  return groupPairs
}
