import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'
import Title from 'lib/ui/ConfigPanel/Title'
import InfoTooltip from 'lib/ui/ConfigPanel/InfoTooltip'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'

export default function EmailFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const {login} = useTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const {
    form: {register},
  } = useConfig()

  const save = (data: any) => {
    const label = replaceAtPath(
      data,
      'login.emailLabel',
      '{{login_email_label}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        login_email_label: label ?? 'Email',
      }),
    })
  }

  return (
    <Config title="Email Field" onSave={save}>
      <SettingsPanel>
        <Title>
          Email{' '}
          <InfoTooltip>
            Will update Login, and Forgot Password inputs.
          </InfoTooltip>
        </Title>
        <TextField
          name="login.emailLabel"
          defaultValue={v(login.emailLabel)}
          label="Label"
          inputProps={{
            'aria-label': 'email label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields templateKey="login.input" values={login.input} />
      </StylingPanel>
    </Config>
  )
}
