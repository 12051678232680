import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import StyleFields from 'Event/auth/TextField/StyleFields'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function NewPasswordFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()
  const {changePassword} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  const save = (data: any) => {
    const newPasswordLabel = replaceAtPath(
      data,
      'changePassword.newPasswordLabel',
      '{{change_password_new_password_label}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        change_password_new_password_label: newPasswordLabel ?? '',
      }),
    })
  }

  return (
    <Config title="New Password Field" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Password Label"
          name="changePassword.newPasswordLabel"
          defaultValue={v(changePassword.newPasswordLabel)}
          inputProps={{
            'aria-label': 'new password label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields
          templateKey="changePassword.input"
          values={changePassword.input}
        />
      </StylingPanel>
    </Config>
  )
}
