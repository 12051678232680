import React, {useCallback} from 'react'
import {Agenda} from 'Event/template/Townhall/Dashboard/Main/AgendaSection'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/AgendaSection/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function AgendaItemConfig(props: {
  agenda: Agenda
  id: string
  sectionId: string
}) {
  const {agenda, id, sectionId} = props

  const save = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const {dashboardSections} = data
    const {
      [sectionId]: {items},
    } = dashboardSections

    const {
      [id]: {text, description, link, ...otherUpdates},
    } = items

    save({
      ...data,
      localization: addTranslation({
        [`dashboardSections_${sectionId}_items_${id}_text`]: text,
        [`dashboardSections_${sectionId}_items_${id}_description`]: description,
        [`dashboardSections_${sectionId}_items_${id}_description`]: link,
      }),
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: {
              text: `{{dashboardSections_${sectionId}_items_${id}_text}}`,
              description: `{{dashboardSections_${sectionId}_items_${id}_description}}`,
              link: `{{dashboardSections_${sectionId}_items_${id}_link}}`,
              ...otherUpdates,
            },
          },
        },
      },
    })
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    save({
      localization: removeTranslations([
        `dashboardSections.${sectionId}.items.${id}.text`,
        `dashboardSections.${sectionId}.items.${id}.description`,
        `dashboardSections.${sectionId}.items.${id}.link`,
      ]),
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [id, sectionId, save, removeTranslations])

  return (
    <Config title="Agenda" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings agenda={agenda} sectionId={sectionId} id={id} />
      </SettingsPanel>
    </Config>
  )
}
