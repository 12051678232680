import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown, addToolbarToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import { ButtonView } from '@ckeditor/ckeditor5-ui/src';

export const THEME_AUTO = 'Auto';
export const THEME_LIGHT = 'Light';
export const THEME_DARK = 'Dark';

const themeModes = [ THEME_AUTO, THEME_LIGHT, THEME_DARK ];

export default class CustomThemeUI extends Plugin {
	static get pluginName() {
		return 'CustomThemeUI';
	}

	init() {
		const editor = this.editor;
		const componentFactory = editor.ui.componentFactory;

		const themeConfig: any = this.editor.config.get( 'theme' );
		if( !themeConfig || !('callback' in themeConfig) || !('mode' in themeConfig) ){
			return;
		}
		const { callback: themeModeCallBack } = themeConfig;

		for ( const mode of themeModes ) {
			this._addButton( mode, themeModeCallBack );
		}

		componentFactory.add( 'customTheme', locale => {
			const dropdownView = createDropdown( locale );

			const buttons = themeModes.map( option => componentFactory.create( `customTheme:${ option }` ) as ButtonView );
			addToolbarToDropdown( dropdownView, buttons, {isVertical: true, ariaLabel: 'Theme mode toolbar'} );

			dropdownView.buttonView.set( {
				tooltip: true,
				withText: true
			} );

			dropdownView.extendTemplate( {
				attributes: {
					class: 'obvio-ck-theme-selection-dropdown'
				}
			} );

			dropdownView.buttonView.bind( 'label' ).toMany( buttons, 'isOn', ( ...areActive ) => {
				// Get the index of an active button.
				const index = areActive.findIndex( value => value );

				// If none of the commands is active, display either default Label or the first button's label.
				if ( index < 0 ) {
					return THEME_AUTO;
				}

				// Return active button's icon.
				return (buttons[ index ] as ButtonView).label;
			} );

			dropdownView.bind( 'isEnabled' ).toMany( buttons, 'isEnabled', ( ...areEnabled ) => areEnabled.some( isEnabled => isEnabled ) );

			return dropdownView;
		} );
	}

	_addButton( option: string, callback: (val: string) => {} ) {
		const editor = this.editor;

		editor.ui.componentFactory.add( `customTheme:${ option }`, locale => {
			const command = editor.commands.get( 'customTheme' )!;
			const buttonView = new ButtonView( locale );

			buttonView.set( {
				label: option,
				tooltip: false,
				withText: true,
				isToggleable: true
			} );

			// Bind button model to command.
			buttonView.bind( 'isEnabled' ).to( command );
			buttonView.bind( 'isOn' ).to( command, 'value', value => value === option );

			// Execute command.
			this.listenTo( buttonView, 'execute', () => {
				editor.execute( 'customTheme', { value: option, callback } );
				editor.editing.view.focus();
			} );

			return buttonView;
		} );
	}
}
