import {useEvent} from 'Event/EventProvider'
import {Certificate} from 'lib/event-api/certificates/types'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export function useCertificates() {
  const {event} = useEvent()

  const {client} = useOrganization()

  return useQuery({
    queryKey: ['events', event.id, 'certificates'],
    queryFn: () =>
      client.get<Certificate[]>(api(`/events/${event.id}/certificates`)),
  })
}
