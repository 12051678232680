import React from 'react'
import {useEditMode} from 'Event/EditModeProvider'
import ConfigurablePurchaseFormTextField from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseFormTextField'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import ConfigurableCustomerDetailsLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableCustomerDetailsLabel'
import ConfigurableBillingSectionLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableBillingSectionLabel'
import ConfigurableShippingSectionLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableShippingSectionLabel'
import ConfigurablePurchaseSummaryLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseSummaryLabel'
import ConfigurableTotalAmountLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableTotalAmountLabel'
import ConfigurablePurchaseFormButton from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseFormButton'
import ConfigurablePurchaseFormTitle from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseFormTitle'
import ConfigurableCardFieldsLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableCardFieldsLabel'
import ConfigurableItemLabel from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableItemLabel'
import UpsellPurchaseButton, {
  UpsellPurchaseButtonBlock,
} from 'Event/Marketplace/Block/UpsellPurchaseButton'

interface ConfigurableUpsellPurchaseButtonProps
  extends UpsellPurchaseButtonBlock,
    BlockComponentProps {}

export default function ConfigurableUpsellPurchaseButton(
  props: ConfigurableUpsellPurchaseButtonProps,
) {
  const isEditMode = useEditMode()

  const paddingTop = isEditMode
    ? Math.max(props.padding.top, 24)
    : props.padding.top

  return (
    <UpsellPurchaseButton
      {...props}
      padding={{
        ...props.padding,
        top: paddingTop,
      }}
      TitleComponent={ConfigurablePurchaseFormTitle}
      TextFieldComponent={ConfigurablePurchaseFormTextField}
      ButtonComponent={ConfigurablePurchaseFormButton}
      CustomerDetailsSectionLabelComponent={ConfigurableCustomerDetailsLabel}
      BillingFieldsLabelComponent={ConfigurableBillingSectionLabel}
      ShippingFieldsLabelComponent={ConfigurableShippingSectionLabel}
      PurchaseSummaryLabelComponent={ConfigurablePurchaseSummaryLabel}
      TotalAmountLabelComponent={ConfigurableTotalAmountLabel}
      ItemLabelComponent={ConfigurableItemLabel}
      CardFieldsLabelComponent={ConfigurableCardFieldsLabel}
      stripeAccountId="fake_stripe_account"
    />
  )
}
