import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Box from 'lib/ui/Box'
import Slider from 'lib/ui/Slider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {useCardsTemplate} from 'Event/template/Cards'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import styled from 'styled-components'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import {useAttendeeVariables} from 'Event'

const MIN_HERO_IMAGE_SIZE_PERCENT = 20
const MAX_HERO_IMAGE_SIZE_PERCENT = 100

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {
    hero: {text, imageSize, image},
  } = useCardsTemplate()
  const v = useAttendeeVariables()

  return (
    <>
      <Title>Image</Title>
      <Box mb={2}>
        <Controller
          name="image"
          defaultValue={image}
          control={control}
          render={({value, onChange}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              canResize
              disableAutoRemove
            />
          )}
        />
      </Box>
      <Controller
        control={control}
        name="imageSize"
        defaultValue={imageSize}
        render={({value, onChange}) => (
          <Slider
            min={MIN_HERO_IMAGE_SIZE_PERCENT}
            max={MAX_HERO_IMAGE_SIZE_PERCENT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="hero image size"
            label="Size"
          />
        )}
      />
      <Divider />
      <Title>Text</Title>
      <TextEditorContainer>
        <Controller
          name="text"
          defaultValue={v(text ?? '')}
          control={control}
          render={({value, onChange}) => (
            <StyledTextEditor
              data={value || ''}
              onChange={onChange}
              aria-label="hero text"
            />
          )}
        />
      </TextEditorContainer>
    </>
  )
}

const StyledTextEditor = styled(TextEditor)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
