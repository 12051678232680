import React, {useCallback} from 'react'
import PurchasePageConfigLoader from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigLoader'
import useAutoCreatePurchasePage from 'organization/Marketplace/PurchasePageConfig/use-auto-create-purchase-page'
import PurchasePageTemplateUpdateProvider from 'organization/Marketplace/PurchasePageConfig/PurchasePageTemplateUpdateProvider'
import TemplateEditor from 'organization/Event/TemplateEditor'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import PurchaseProvider from 'Event/Marketplace/PurchaseProvider'
import CountriesProvider, {State} from 'Event/Marketplace/lib/CountriesProvider'
import {useCountries} from 'lib/event-api/countries/use-countries'
import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {fillPurchasePageTemplate} from 'Event/Marketplace/lib/fill-purchase-page-template'
import PurchasePageConfigContent from 'organization/Marketplace/PurchasePageConfig/PurchasePageConfigContent'
import ConfigPageProvider from 'organization/Marketplace/config/ConfigPageProvider'
import {ThankYouPageContext} from 'Event/Marketplace/ThankYouPageContext'

export default function PurchasePageConfig() {
  const {purchasePage} = useAutoCreatePurchasePage()

  const {client: marketplaceClient} = useEventMarketplaceClient()

  const {data: countries = []} = useCountries()

  const fetchStates = useCallback((countryId: number) => {
    return client.get<State[]>(api(`/countries/${countryId}/states`))
  }, [])

  if (!purchasePage || !marketplaceClient) {
    return <PurchasePageConfigLoader />
  }

  return (
    <CountriesProvider countries={countries} fetchStates={fetchStates}>
      <PurchaseProvider>
        <ThankYouPageContext.Provider
          value={{
            loginUrl: null,
          }}
        >
          <ConfigPageProvider>
            <PurchasePageTemplateUpdateProvider
              purchasePage={purchasePage}
              client={marketplaceClient}
            >
              {(template) => (
                <TemplateEditor template={template}>
                  {(template) => (
                    <PurchasePageConfigContent
                      template={fillPurchasePageTemplate(template)}
                      purchasePage={purchasePage}
                      client={marketplaceClient}
                    />
                  )}
                </TemplateEditor>
              )}
            </PurchasePageTemplateUpdateProvider>
          </ConfigPageProvider>
        </ThankYouPageContext.Provider>
      </PurchaseProvider>
    </CountriesProvider>
  )
}
