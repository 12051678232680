import {TicketRibbon} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList/Ribbon'
import DefaultRibbonSelect from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbonConfig/DefaultRibbonSelect'
import TicketRibbonUpload from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {fieldError} from 'lib/form'
import React from 'react'
import {UseFormMethods} from 'react-hook-form'
import {useAttendeeVariables} from 'Event'

const MAX_NUM_CHARACTERS = 9

type ConfigProps = {
  processing: boolean
  setProcessing: (v: boolean) => void
  ticketRibbon: TicketRibbon
  customRibbon: TicketRibbon['customRibbon']
  setCustomRibbon: (customRibbon: TicketRibbon['customRibbon']) => void
  control: UseFormMethods['control']
}

export default function Settings(props: {
  register: UseFormMethods['register']
  errors: UseFormMethods['errors']
  ticketRibbon: TicketRibbon
  configProps: ConfigProps
}) {
  const {register, errors, ticketRibbon, configProps} = props
  const error = fieldError('text', {
    form: errors || {},
    response: null,
  })

  const v = useAttendeeVariables()

  return (
    <>
      <DefaultRibbonSelect {...configProps} />
      <TicketRibbonUpload
        {...configProps}
        width={800}
        height={150}
        disableAutoRemove
      />
      <Label>Text</Label>
      <TextField
        name="text"
        fullWidth
        defaultValue={v(ticketRibbon.text)}
        aria-label="ticket ribbon text input"
        error={!!error}
        helperText={error}
        inputProps={{
          ref: register({
            maxLength: {
              value: MAX_NUM_CHARACTERS,
              message: `Maximum of ${MAX_NUM_CHARACTERS} characters`,
            },
          }),
        }}
      />
    </>
  )
}
