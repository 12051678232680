import React from 'react'
import styled from 'styled-components'
import {SubHead as BaseSubHead} from 'lib/ui/typography'
import {useStatistics} from 'Event/Statistics'

const DEFAULT_FONT_SIZE = 18
const DEFAULT_LINE_HEIGHT = 21

export default function SubHead(props: {
  children: React.ReactElement | number | string
  'aria-label'?: string
}) {
  const {fontSize} = useStatistics()

  return (
    <StyledSubHead
      color="white"
      delta={fontSize}
      aria-label={props['aria-label'] || 'statics sub head'}
    >
      {props.children}
    </StyledSubHead>
  )
}

const StyledSubHead = styled(BaseSubHead)<{delta: number}>`
  font-size: ${(props) => DEFAULT_FONT_SIZE + props.delta}px;
  line-height: ${(props) => DEFAULT_LINE_HEIGHT + props.delta}px;
`
