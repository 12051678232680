import NavButton, {
  FirstRow,
  IconBox,
  NavButtonProps,
} from 'Event/Dashboard/components/NavButton'
import styled from 'styled-components'
import {useCardsTemplate} from 'Event/template/Cards'
import React from 'react'

export type CardsNavButtonProps = NavButtonProps & {
  row: 1 | 2
}

export default function CardsNavButton(props: CardsNavButtonProps) {
  const {row: _, ...buttonProps} = props
  const {mainNav} = useCardsTemplate()
  return <StyledNavButton {...buttonProps} height={mainNav.buttonHeight} />
}

// Nav buttons for cards should have the icon stacked, rather
// then inline.
const StyledNavButton = styled(NavButton)`
  ${FirstRow} {
    flex-direction: column;
    justify-content: center;
  }

  ${IconBox} {
    width: auto;
  }
`
