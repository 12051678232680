import React from 'react'

export type HelperTextProps = {
  children?: string | JSX.Element
  error?: boolean
  disabled?: boolean
  className?: string
}

export default function HelperText(props: HelperTextProps) {
  const {children, className} = props
  if (!children) {
    return null
  }

  return (
    <div
      style={{
        marginTop: '12px',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '17px',
        color: props.disabled ? '#c4c4c4' : '#939393',
      }}
      className={className}
    >
      <Content {...props} />
    </div>
  )
}

function Content(props: HelperTextProps) {
  if (props.error) {
    return (
      <span
        style={{
          color: '#f44336',
        }}
      >
        {props.children}
      </span>
    )
  }

  if (!props.children) {
    return null
  }

  if (typeof props.children === 'string') {
    return <span>{props.children}</span>
  }

  return props.children
}
