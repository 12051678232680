import React from 'react'
import styled from 'styled-components'
import {useStatistics} from 'Event/Statistics'
import IconButton from 'lib/ui/IconButton'
import Icon from 'lib/ui/Icon'

export default function FontSizeHandler() {
  const {increaseFont, decreaseFont} = useStatistics()

  return (
    <Container>
      <StyledIconButton onClick={decreaseFont}>
        <Icon className="fa-solid fa-minus" color="white" iconSize={15} />
      </StyledIconButton>
      <StyledIconButton onClick={increaseFont}>
        <Icon className="fa-solid fa-plus" color="white" iconSize={15} />
      </StyledIconButton>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  right: ${(props) => props.theme.spacing[5]};
  bottom: ${(props) => props.theme.spacing[8]};
`

const StyledIconButton = styled(IconButton)`
  padding: ${(props) => props.theme.spacing[2]};
  background-color: ${(props) => props.theme.colors.gray200};
`
