import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import {EVENT_SETTINGS, usePermissions} from 'organization/PermissionsProvider'
import {MenuItemActionProps} from 'organization/Event/AttendeeManagement/AttendeeTools'

export default function DownloadCertificateMenuItem(
  props: MenuItemActionProps & {onClick: () => void; disabled?: boolean},
) {
  const {can} = usePermissions()

  if (!can(EVENT_SETTINGS)) {
    /**
     * Already set a primary button
     */
    return null
  }

  return (
    <MenuItem
      onClick={props.onClick}
      disabled={props.disabled}
      aria-label="download certificate item"
    >
      Download Certificate
    </MenuItem>
  )
}
