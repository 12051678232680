import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import TextField from 'lib/ui/TextField'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'

export default function SubmitButtonConfig() {
  const saveTemplate = useSaveTemplate()
  const {
    waiver: {button, submittingText},
  } = useTemplate()
  const {
    form: {register, control, watch},
  } = useConfig()

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const text = replaceAtPath(data, 'text', '{{waiver_submit_button}}')
    const submittingText = replaceAtPath(
      data,
      'submittingText',
      '{{waiver_submitting_text}}',
    )

    saveTemplate({
      waiver: {
        button: data,
      },
      localization: addTranslation({
        waiver_submit_button: text ?? '',
        waiver_submitting_text: submittingText ?? '',
      }),
    })
  }

  return (
    <Config
      title="Submit Button"
      onSave={save}
      preview={{
        waiver: {
          button: watch(),
        },
      }}
    >
      <SettingsPanel>
        <ContentInput
          button={button}
          control={control}
          register={register}
          watch={watch}
        />
        <TextField
          label="Submitting Text"
          name="submittingText"
          defaultValue={v(submittingText)}
          inputProps={{
            ref: register,
            'aria-label': 'submitting text',
          }}
          fullWidth
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.typography"
        />
        <BackgroundSection
          button={button}
          control={control}
          watch={watch}
          storageKey="waiver.submitButton.config.background"
        />
        <SizeSection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.size"
          disableNewLine
        />
        <BorderSection
          button={button}
          control={control}
          storageKey="waiver.submitButton.config.border"
        />
      </StylingPanel>
    </Config>
  )
}
