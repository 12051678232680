import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import bot from 'assets/images/icons/bot.svg'
import {TrashCan} from 'lib/ui/Icon'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {Label, Description} from 'lib/ui/typography'
import CreateProfileDialog from 'organization/Obie/CreateProfileDialog'
import DeleteProfileDialog from 'organization/Obie/DeleteProfileDialog'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {useObieService, Category} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function Obie() {
  const {categories, setProfileId} = useObieService()
  const {routes} = useOrganization()
  const history = useHistory()

  const [createProfileOpen, setCreateProfileOpen] = useState<boolean>(false)
  const [createProfileCategory, setCreateProfileCategory] = useState<
    number | undefined
  >()
  const [deleteProfileOpen, setDeleteProfileOpen] = useState<boolean>(false)
  const [deleteProfileCategory, setDeleteProfileCategory] = useState<
    number | undefined
  >()
  const [deleteProfileProfile, setDeleteProfileProfile] = useState<
    number | undefined
  >()

  // On THIS page load, we need to unset the profileId inside the OBIE state.
  useEffect(() => {
    setProfileId(undefined)
  }, [setProfileId])

  const toggleCreateProfileDialog = (categoryId?: number) => {
    if (categoryId) {
      setCreateProfileCategory(categoryId)
      setCreateProfileOpen(true)

      return
    }

    setCreateProfileCategory(undefined)
    setCreateProfileOpen(false)
  }

  const toggleDeleteProfileDialog = (
    categoryId?: number,
    profileId?: number,
  ) => {
    if (categoryId && profileId) {
      setDeleteProfileCategory(categoryId)
      setDeleteProfileProfile(profileId)
      setDeleteProfileOpen(true)

      return
    }

    setDeleteProfileCategory(undefined)
    setDeleteProfileProfile(undefined)
    setDeleteProfileOpen(false)
  }

  const handleCreateClose = () => toggleCreateProfileDialog()
  const handleDeleteClose = () => toggleDeleteProfileDialog()

  const goToProfile = (categoryId: number, profileId: number) => {
    history.push(
      profileRoute(routes, String(categoryId), String(profileId)).root,
    )
  }

  return (
    <Container>
      <CreateProfileDialog
        categoryId={createProfileCategory}
        onClose={handleCreateClose}
        onSubmit={goToProfile}
        open={createProfileOpen}
      />
      <DeleteProfileDialog
        categoryId={deleteProfileCategory}
        profileId={deleteProfileProfile}
        onClose={handleDeleteClose}
        open={deleteProfileOpen}
      />

      <TitleBox>
        <img src={bot} alt="OBIE robot" />
        <Title>OBIE</Title>
        <BetaBadge>Beta</BetaBadge>
      </TitleBox>
      <Box>
        <StyledLabel>
          Welcome to Obie, the Offer Builder Inspiration Engine!
        </StyledLabel>
        <StyledDescription>
          Obie can help you create the building blocks for your Live Event!
          Select the block type that you wish to create by clicking on it below!
        </StyledDescription>
      </Box>

      <List>
        {categories.map((category: Category, index: number) => {
          // Handling option clicks outside of the <Select />'s onChange handler,
          // because we are packing in a few different functionalities into the
          // options of the dropdown.
          const handleClickDefaultProfile = () => {
            goToProfile(category.id, 0)
          }
          const handleClickNewProfile = () => {
            toggleCreateProfileDialog(category.id)
          }
          // The <Select /> requires an onChange, so here we go.
          const handleNoOp = () => {}

          // We're only going to iterate profiles if the Category has any.
          const profiles = category.profiles || []

          return (
            <Item key={index}>
              <ItemText>{category.name}</ItemText>
              <Select
                fullWidth
                onChange={handleNoOp}
                aria-label="pick value"
                label="Profile"
                value=""
              >
                <StyledOption value={0}>
                  <div onClick={handleClickDefaultProfile}>Default Profile</div>
                </StyledOption>

                {profiles.map((profile, index) => {
                  // Same "handling option clicks..." bit from above, except
                  // specific to the profile options, not just the categories.
                  const handleClickProfile = () => {
                    goToProfile(category.id, profile.id)
                  }
                  const handleClickDeleteProfile = () => {
                    toggleDeleteProfileDialog(category.id, profile.id)
                  }

                  return (
                    <StyledOption key={index}>
                      <div onClick={handleClickProfile}>{profile.name}</div>
                      <StyledTrashCan
                        onClick={handleClickDeleteProfile}
                        title={`Remove the ${profile.name} Profile`}
                      />
                    </StyledOption>
                  )
                })}

                <NewOption>
                  <div onClick={handleClickNewProfile}>Create New Profile</div>
                </NewOption>
              </Select>
            </Item>
          )
        })}
      </List>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing[15]};
  padding-bottom: ${(props) => props.theme.spacing[13]};
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 0;
  margin: ${(props) => `0 ${props.theme.spacing[4]}`};
`

const BetaBadge = styled.span`
  margin: 10px;
  padding: 2px 4px;
  background-color: #3490dc;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${(props) => props.theme.spacing[2]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

const StyledLabel = styled(Label)`
  margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledDescription = styled(Description)`
  max-width: 600px;
`

const StyledOption = styled(Option)`
  display: flex;
  justify-content: space-between;
  padding: 0;

  & > div:first-child {
    margin: ${(props) => props.theme.spacing[2]} 0
      ${(props) => props.theme.spacing[2]} ${(props) => props.theme.spacing[4]};
    width: 100%;
  }
`

const StyledTrashCan = styled(TrashCan)<{
  onClick: (profileId: number) => void
}>`
  margin: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[2]} 0;
  &:hover {
    color: ${(props) => props.theme.colors.error};
  }
`

const NewOption = styled(Option)`
  border-top: solid 1px ${(props) => props.theme.colors.grey500};
  margin-top: ${(props) => props.theme.spacing[2]};
`

const Item = styled.div`
  flex: 0 1 calc(33.33% - 10px);
  flex-direction: column;
  background-color: #ffffff;
  padding: ${(props) => props.theme.spacing[6]};
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`

const ItemText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  text-align: center;
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
