import {TicketRibbon} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList/Ribbon'
import React, {useCallback, useEffect, useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm, UseFormMethods} from 'react-hook-form'
import {useDeleteCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload/UploadedTicketRibbon'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbonConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbonConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {usePruneCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'

export interface TicketRibbonConfigProps {
  control: UseFormMethods['control']
  customRibbon?: TicketRibbon['customRibbon']
  setCustomRibbon: (customRibbon: TicketRibbon['customRibbon']) => void
  processing: boolean
  setProcessing: (procesing: boolean) => void
  ticketRibbon: TicketRibbon
}

export function TicketRibbonConfig(
  props: ComponentConfigProps & {
    id?: string
    listId: string
    ticketRibbon: TicketRibbon
  },
) {
  const {showing, onClose, ticketRibbon, id, listId} = props
  const [processing, setProcessing] = useState(false)
  const deleteCustomRibbon = useDeleteCustomRibbon()
  const {
    control,
    register,
    errors,
    handleSubmit,
    formState,
    setValue,
  } = useForm()

  const [rules, setRules] = useState(ticketRibbon.rules)
  const [customRibbon, setCustomRibbon] = useState(ticketRibbon.customRibbon)
  const pruneCustomRibbon = usePruneCustomRibbon()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(ticketRibbon.rules)
    setCustomRibbon(ticketRibbon.customRibbon)
  }, [showing, ticketRibbon])

  const update = (id: string, updated: TicketRibbon) => {
    pruneCustomRibbon({
      current: updated.customRibbon,
      saved: ticketRibbon.customRibbon,
    })

    const text = replaceAtPath(updated, 'text', `{{${id}_text}}`)

    saveTemplate({
      sidebarItems: {
        [listId]: {
          ribbons: {
            [id]: updated,
          },
        },
      },
      localization: addTranslation({
        [`${id}_text`]: text ?? '',
      }),
    })
  }

  const insert = (newRibbon: TicketRibbon) => {
    generateHashId([
      'ticket_ribbon',
      newRibbon.name,
      Math.random().toString(36).substring(2, 15),
      Date.now().toString(),
    ]).then((id) => {
      const componentId = `ribbon_${id}`
      saveTemplate({
        sidebarItems: {
          [listId]: {
            ribbons: {
              [componentId]: {
                ...newRibbon,
                text: `{{${componentId}_text}}`,
              },
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_text`]: newRibbon.text ?? '',
        }),
      })
    })
  }

  const save = (formData: any) => {
    const ribbon: TicketRibbon = {
      ...formData,
      rules,
      customRibbon,
    }

    if (id !== undefined) {
      update(id, ribbon)
      onClose()
      return
    }

    insert(ribbon)
    onClose()
  }

  const configProps = {
    processing,
    setProcessing,
    ticketRibbon,
    customRibbon,
    setCustomRibbon,
    control,
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error('Missing id')
    }

    if (ticketRibbon.customRibbon) {
      deleteCustomRibbon(ticketRibbon.customRibbon).catch(() => {
        /**
         * Ignore - if a model/image has already been removed
         * on server due to an aborted request, this will
         * fail. So we'll just ignore it, and proceed.
         */
      })
    }

    saveTemplate({
      sidebarItems: {
        [listId]: {
          ribbons: {
            [id]: REMOVE,
          },
        },
      },
      localization: removeTranslations([`${id}_text`]),
    })

    onClose()
  }, [
    deleteCustomRibbon,
    id,
    listId,
    onClose,
    removeTranslations,
    saveTemplate,
    ticketRibbon.customRibbon,
  ])

  return (
    <ComponentConfig
      title="Ticket Ribbon"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          register={register}
          errors={errors}
          ticketRibbon={ticketRibbon}
          configProps={configProps}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} ticketRibbon={ticketRibbon} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={ticketRibbon}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton aria-label="remove ticket ribbon" onClick={remove}>
          REMOVE TICKET RIBBON
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
