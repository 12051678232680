import React from 'react'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {onChangeCheckedHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const template = useNiftyFiftyTemplate()
  const {resourceList: list} = template
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isVisible"
        control={control}
        defaultValue={list.isVisible}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="toggle resources"
          />
        )}
      />
      <Label>Title</Label>
      <TextField
        name="title"
        aria-label="update resources title"
        fullWidth
        defaultValue={v(list.title)}
        inputProps={{
          ref: register,
        }}
      />
      <Label>Menu Title</Label>
      <TextField
        name="menuTitle"
        aria-label="update resources menu field"
        fullWidth
        defaultValue={v(list.menuTitle)}
        inputProps={{
          ref: register,
        }}
      />
    </>
  )
}
