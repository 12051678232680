import React, {useContext, useState} from 'react'
import {useToggleArray} from 'lib/toggle'
import AddBlockButton from 'organization/Marketplace/config/AddBlockButton'
import {Layout, Layouts} from 'react-grid-layout'
import ConfigurableBlock, {
  BLOCK_DRAG_HANDLE as blockDragHandle,
} from 'organization/Marketplace/config/ConfigurableBlock'
import isEqual from 'lodash/isEqual'
import Section from 'Event/Marketplace/Section'
import {grey} from '@material-ui/core/colors'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {resizeLayoutVisibility} from 'organization/Marketplace/config/resize-layout-visibility'
import {BlockBase} from 'Event/Marketplace/Block/base'
import {
  PurchasePageBlock,
  PurchasePageSection,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'
import {useMoveBlock} from 'organization/Marketplace/config/use-move-block'
import {DeepPartialSubstitute} from 'lib/type-utils'
import {JsonSave, REMOVE} from 'lib/JsonUpdateProvider'
import {
  ThankYouPageBlock,
  ThankYouPageSection,
  ThankYouPageTemplate,
} from 'Event/Marketplace/thank-you-page'
import {
  UpsellPageBlock,
  UpsellPageSection,
  UpsellPageTemplate,
} from 'Event/Marketplace/upsell-page'
import {useConfigPage} from 'organization/Marketplace/config/ConfigPageProvider'
import AddBlockDialog from 'organization/Marketplace/config/AddBlockDialog'
import {PageTemplate} from 'Event/Marketplace/page-template'

interface ConfigurableSectionProps {
  section: PageTemplate['sections'][number]
  id: string
  template: PageTemplate
  sectionIds: string[]
  updateTemplate: JsonSave<
    PurchasePageTemplate | ThankYouPageTemplate | UpsellPageTemplate
  >
  availableBlocks: Array<
    | PurchasePageBlock['type']
    | ThankYouPageBlock['type']
    | UpsellPageBlock['type']
  >
  ButtonConfig: React.FC<any>
}

interface ConfigurableSectionContextProps {
  sectionId: string
  update: (
    data: DeepPartialSubstitute<PurchasePageSection, typeof REMOVE>,
  ) => void
}

export const ConfigurableSectionContext = React.createContext<
  undefined | ConfigurableSectionContextProps
>(undefined)

export default function ConfigurableSection(props: ConfigurableSectionProps) {
  const {
    section,
    id: sectionId,
    template,
    sectionIds,
    updateTemplate,
    availableBlocks,
    ButtonConfig,
  } = props
  const [showingAddBlockDialog, toggleAddBlockDialog] = useToggleArray()
  const isEditMode = useEditMode()
  const {isMobileMode} = useConfigPage()
  const [isDragging, setIsDragging] = useState(false)

  const moveBlock = useMoveBlock()

  const handleLayoutChange = (newLayouts: Layouts) => {
    const layoutWithVisibilitySizes = resizeLayoutVisibility({
      layouts: newLayouts,
      blocks: section.blocks,
    })

    if (isEqual(layoutWithVisibilitySizes, section.layouts)) {
      return
    }

    updateTemplate({
      sections: {
        [sectionId]: {layouts: layoutWithVisibilitySizes},
      },
    })
  }

  const currentSectionIndex = sectionIds.indexOf(sectionId)
  const canBlockMoveUp = currentSectionIndex !== 0
  const canBlockMoveDown = currentSectionIndex !== sectionIds.length - 1

  const handleBlockMoveUp = (blockId: string) => {
    moveBlock({
      sourceSectionId: sectionId,
      targetSectionId: sectionIds[currentSectionIndex - 1],
      blockId,
      template,
      updateTemplate,
    })
  }

  const handleBlockMoveDown = (blockId: string) => {
    moveBlock({
      sourceSectionId: sectionId,
      targetSectionId: sectionIds[currentSectionIndex + 1],
      blockId,
      template,
      updateTemplate,
    })
  }

  const updateSection = (
    data: DeepPartialSubstitute<
      PurchasePageSection | ThankYouPageSection | UpsellPageSection,
      typeof REMOVE
    >,
  ) => {
    updateTemplate({
      sections: {
        [sectionId]: data,
      },
    })
  }

  return (
    <ConfigurableSectionContext.Provider
      value={{sectionId, update: updateSection}}
    >
      <Box
        hasBorder={isEditMode}
        isMobileMode={isMobileMode}
        visibility={section.visibility}
      >
        <AddBlockDialog
          open={showingAddBlockDialog}
          onClose={toggleAddBlockDialog}
          layouts={section.layouts}
          availableBlocks={availableBlocks}
        />
        <Section
          section={section}
          disableItemResize
          ResponsiveReactGridLayoutProps={{
            isDraggable: true,
            isResizable: true,
            draggableHandle: `.${blockDragHandle}`,
            onLayoutChange: (
              _currentLayout: Layout[],
              allBreakpointLayouts: Layouts,
            ) => {
              handleLayoutChange(allBreakpointLayouts)
            },
            onDragStart: () => {
              setIsDragging(true)
            },
            onDragStop: () => {
              setIsDragging(false)
            },
          }}
          isMobileMode={isMobileMode}
          template={template}
          minTopPadding={24}
        >
          {section.blocks
            ? Object.entries(section.blocks).map(([id, block]) => (
                <ConfigurableBlock
                  block={block}
                  key={id}
                  id={id}
                  template={template}
                  onMoveSectionUp={
                    canBlockMoveUp ? () => handleBlockMoveUp(id) : undefined
                  }
                  onMoveSectionDown={
                    canBlockMoveDown ? () => handleBlockMoveDown(id) : undefined
                  }
                  updateTemplate={updateTemplate}
                  availableBlocks={availableBlocks}
                  ButtonConfig={ButtonConfig}
                  isEditMode
                  isDragging={isDragging}
                />
              ))
            : null}
        </Section>
        <AddBlockButton onClick={toggleAddBlockDialog} />
      </Box>
    </ConfigurableSectionContext.Provider>
  )
}

export function useConfigurableSection() {
  const context = useContext(ConfigurableSectionContext)
  if (!context) {
    throw new Error(
      'useConfigurableSection must be used within ConfigurableSection',
    )
  }

  return context
}

const Box = styled.div<{
  hasBorder: boolean
  visibility: BlockBase['visibility']
  isMobileMode: boolean
}>`
  padding-top: 10px;
  border-bottom: ${(props) =>
    props.hasBorder ? `2px dashed ${grey[600]}` : 'none'};

  display: ${(props) =>
    props.visibility === 'desktop_only' ? 'none' : 'block'};
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: ${(props) =>
      (props.visibility === 'mobile_only' && !props.isMobileMode) ||
      (props.visibility === 'desktop_only' && props.isMobileMode)
        ? 'none'
        : 'block'};
  }
`
