import React, {useState, useEffect} from 'react'
import Markdown from 'marked-react'
import styled from 'styled-components'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import SelectableOptionsList from 'organization/Obie/Blocks/ProcessForm/SelectableOptionsList'
import {useLayout} from 'organization/Obie/Layout'
import {
  OBIE_OPTIONS_LIST,
  splitText,
} from 'organization/Obie/ObieServiceProvider'

const SPEED = 6

export type EffectProps = {
  text: string
  onComplete?: () => void
  containerRef?: React.RefObject<HTMLDivElement>
  autoScroll?: boolean
  updateCompletion?: (newValue: string) => void
  className?: string
  isVisible?: boolean
}

// TypingEffect Component
export function TypingEffect(props: EffectProps) {
  const {text, onComplete, autoScroll, containerRef} = props
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)

  const [finished, setFinished] = useState(false)

  useEffect(() => {
    const regex = /\|$/

    if (text && index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev.replace(regex, '') + text[index] + '|')
        setIndex(index + 1)
      }, SPEED)

      return () => clearTimeout(timeout)
    } else {
      onComplete && onComplete() // Notify when typing is complete

      setFinished(true)
      setDisplayedText((prev) => prev.replace(regex, ''))
    }
  }, [index, text, onComplete, setFinished])

  useEffect(() => {
    if (autoScroll && containerRef?.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [displayedText, autoScroll, containerRef])

  if (finished && props.updateCompletion) {
    return (
      <TypedText>
        <MarkdownEditor
          data={displayedText}
          onChange={props.updateCompletion}
          theme="Dark"
        />
      </TypedText>
    )
  }
  return (
    <TypedText withPadding className={props.className}>
      <Markdown>{displayedText}</Markdown>
    </TypedText>
  )
}

// FirstTextComponent
const FirstTextComponent = (props: EffectProps) => {
  const {className, text, onComplete, containerRef, autoScroll} = props

  return (
    <TypingEffect
      text={text}
      onComplete={onComplete}
      containerRef={containerRef}
      autoScroll={autoScroll}
      className={className}
    />
  )
}

// SecondTextComponent
const SecondTextComponent = (props: EffectProps) => {
  const {isVisible, text, onComplete, containerRef, autoScroll} = props

  const [typingCompleted, setTypingCompleted] = useState<boolean>(false)

  // If we're not supposed to be visible, split. This is usually waiting for some
  // other render to happen, order of operations.
  if (!isVisible) {
    return null
  }

  // If we don't have any text provided to us, try to do any onComplete sent in
  // and get out of here.
  if (!text) {
    onComplete && onComplete()

    return null
  }

  // Normal operation "onComplete". Because we have some paths, we need to make
  // handlers for each case, so that we can easily define them.
  const handleStandardComplete = () => {
    onComplete && onComplete()
  }

  // The "onComplete" handler when there is a SelectableOptionsList.
  const handleTypingCompleted = () => {
    setTypingCompleted(true)
  }

  // Checking for the Selectable Options List identifier, so decisions can be made.
  const optionsListParts = text.split(OBIE_OPTIONS_LIST)

  // If there is a SelectableOptionsList to render, there may or may not be any
  // text in the first element of the (now) array of text.
  const typingText = optionsListParts.length > 1 ? optionsListParts[0] : text

  // If there is a SelectableOptionsList to render, the onComplete for the
  // TypingEffect component in this case will be to toggle the visiblity of the
  // list itself. Otherwise we just need to do the standard complete routine that
  // may have been passed in.
  const handleOnComplete =
    optionsListParts.length > 1 ? handleTypingCompleted : handleStandardComplete

  return (
    <>
      <TypingEffect
        text={typingText}
        onComplete={handleOnComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
        updateCompletion={props.updateCompletion}
      />
      <SelectableOptionsList
        isVisible={typingCompleted}
        text={optionsListParts[1]}
        onComplete={handleStandardComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
        updateCompletion={props.updateCompletion}
      />
    </>
  )
}

// ParentComponent
export default function ParentComponent(props: {
  text: string
  onFinish?: () => void
  updateCompletion?: (newValue: string) => void
  className?: string
}) {
  const {className} = props
  const [showSecondText, setShowSecondText] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const {contentRef: containerRef} = useLayout()

  const splitTextParts = splitText(props.text)

  const handleFirstTextComplete = () => {
    if (!splitTextParts[1]) {
      props.onFinish && props.onFinish()

      return
    }

    setShowSecondText(true)
  }

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      if (container) {
        const atBottom =
          Math.ceil(container.scrollTop + container.clientHeight) >=
          container.scrollHeight

        setAutoScroll(atBottom)
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef])

  return (
    <>
      <FirstTextComponent
        text={splitTextParts[0]}
        onComplete={handleFirstTextComplete}
        containerRef={containerRef}
        autoScroll={autoScroll}
        className={className}
      />
      <SecondTextComponent
        isVisible={showSecondText}
        text={splitTextParts[1]}
        containerRef={containerRef}
        autoScroll={autoScroll}
        onComplete={props.onFinish}
        updateCompletion={props.updateCompletion}
      />
    </>
  )
}

// Styled Component for Typed Text
const TypedText = styled.div<{withPadding?: boolean}>`
  color: white;
  margin-bottom: 16px;
  line-height: 1.5;
  padding: 0
    ${(props) => (props.withPadding ? 'var(--ck-spacing-standard)' : 0)};
`
