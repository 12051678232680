import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import React from 'react'
import {Controller} from 'react-hook-form'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ConfigDialog'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {FaqItem} from 'Event/Marketplace/Block/FaqList'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'

type FaqListItemConfigProps = ComponentConfigProps & {
  list: FaqItem
  itemId: string
  sectionId: string
  blockId: string
  updateTemplate: UpdateTemplateFunction
}

export default function FaqListItemConfig(props: FaqListItemConfigProps) {
  const {list, itemId, sectionId, blockId, updateTemplate} = props
  const {
    form: {control},
  } = useConfig()

  const handleRemove = () => {
    updateTemplate({
      sections: {
        [sectionId]: {
          blocks: {
            [blockId]: {
              items: {
                [itemId]: REMOVE,
              },
            },
          },
        },
      },
    })
  }

  const save = (data: any) => {
    updateTemplate(data)
  }

  return (
    <Config title="Faq Item" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.showAnswerOnLoad`}
          control={control}
          defaultValue={list.showAnswerOnLoad}
          render={({onChange, value}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              labelPlacement="end"
              label={value ? 'Show on Load Enabled' : 'Show on Load Disabled'}
            />
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.question`}
          defaultValue={list.question}
          control={control}
          render={({value, onChange}) => (
            <TextEditorContainer>
              <TextEditor
                data={value}
                onChange={onChange}
                toolbar={['bold', 'italic', 'underline', 'fontColor']}
              />
            </TextEditorContainer>
          )}
        />
        <Controller
          name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.answer`}
          defaultValue={list.answer}
          control={control}
          render={({value, onChange}) => (
            <TextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </TextEditorContainer>
          )}
        />
      </SettingsPanel>

      <StylingPanel>
        <CollapsibleSection
          label="Border"
          noDivider
          storageKey={`blocks-${blockId}-border`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.border.width`}
            defaultValue={list.border.width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Border Thickness"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.border.radius`}
            defaultValue={list.border.radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={0}
                max={50}
                step={1}
                onChange={onChange}
                value={value}
                label="Radius"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.border.color`}
            defaultValue={list.border.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Border Color"
                color={value}
                onPick={onChange}
              />
            )}
          />
        </CollapsibleSection>

        <CollapsibleSection
          label="Background"
          storageKey={`blocks-${blockId}-background`}
        >
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.background.image`}
            control={control}
            defaultValue={list.background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'item background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.background.color`}
            defaultValue={list.background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="block background color"
              />
            )}
          />
          <Controller
            name={`sections.${sectionId}.blocks.${blockId}.items.${itemId}.background.opacity`}
            defaultValue={list.background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="block background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
