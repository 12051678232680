import React from 'react'
import {useTownhallUpdate} from 'Event/template/Townhall'
import Settings from 'Event/template/Townhall/Dashboard/Hero/HeroConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/Hero/HeroConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Config from 'organization/Event/Configurable/Config'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export function HeroConfig() {
  const update = useTownhallUpdate()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const label = replaceAtPath(data, 'hero.body', '{{hero_body}}')

    update({
      ...data,
      localization: addTranslation({
        hero_body: label ?? '',
      }),
    })
  }

  return (
    <Config title="Hero" onSave={handleSave}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}
