import React from 'react'
import {getFontStyles} from '../../lib/font'
import styles from './NavButton.module.css'
import {LinkButtonProps} from '../Button/types'
import {ButtonBase} from './ButtonBase'
import Icon, {IconProps} from '../../lib/ui/Icon'

export function Content(props: LinkButtonProps) {
  const opacity = 1
  const fontSize = props.fontSize ?? 29

  // Remove padding for image buttons, so that the image goes all
  // the way to the edges. This assumes a button can NOT include
  // both text, and an image.
  const textButtonPadding = '15px 30px'
  const padding = props.image ? 0 : textButtonPadding

  return (
    <ButtonBase
      id={props.id}
      fullWidth
      textTransform="uppercase"
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      hoverTextColor={props.hoverTextColor}
      className={styles.styledButton}
      hoverBackgroundColor={props.hoverBackgroundColor}
      disableHover={props.disableHover}
      borderRadius={props.borderRadius}
      borderWidth={props.borderWidth}
      borderColor={props.borderColor}
      hoverBorderColor={props.hoverBorderColor}
      opacity={opacity}
      width={100}
      fontSize={fontSize}
      typographySecond={props.typographySecond}
      style={{
        ...getFontStyles(props.font || null),
        padding,
      }}
    >
      <Body {...props} />
    </ButtonBase>
  )
}

export function Body(props: LinkButtonProps) {
  const {
    iconStacked,
    icon,
    image,
    text,
    textSecond,
    textColor,
    fontSize,
  } = props
  const iconSize = props.iconSize ?? props.fontSize ?? 29
  const iconColor = props.textColor ?? '#FFFFFF'

  const iconAlign = props.iconAlign ?? 'left'
  const fontSizeSecond = props.typographySecond?.fontSize ?? 29
  const fontSecons = props.typographySecond?.font ?? null
  const secondTextColor = props.typographySecond?.textColor ?? null

  if (image) {
    return <Image image={image} />
  }

  return (
    <>
      <div
        className="firstRow"
        style={{
          display: 'flex',
          flexDirection: iconAlign === 'left' ? 'row' : 'row-reverse',
          color: textColor,
          fontSize: fontSize,
        }}
      >
        <ButtonIcon
          stacked={iconStacked}
          className={icon}
          color={iconColor}
          iconAlign={iconAlign}
          iconSize={iconSize}
        />
        {text}
      </div>
      <div
        className="secondText"
        style={{
          ...getFontStyles(fontSecons),
          fontSize: `${fontSizeSecond}px`,
          color: secondTextColor ?? textColor,
        }}
      >
        {textSecond ?? ''}
      </div>
    </>
  )
}

export function ButtonIcon(
  props: Omit<IconProps, 'className'> & {
    className?: string | null
    stacked?: boolean
    iconAlign?: 'left' | 'right'
    iconSize?: number
  },
) {
  const {stacked, className, color, iconAlign, iconSize} = props

  if (!className) {
    return null
  }

  return (
    <div
      className={styles.iconBox}
      style={{
        width: iconSize ? `${iconSize}px` : 'auto',
        height: iconSize ? `${iconSize}px` : 'auto',
        display: `${stacked ? 'block' : 'inline-block'} !important`,
        marginRight: stacked || iconAlign === 'right' ? 0 : '16px',
        marginLeft: stacked || iconAlign === 'left' ? 0 : '16px',
        marginBottom: stacked ? '8px' : 0,
      }}
    >
      <Icon className={className} color={color} iconSize={iconSize} />
    </div>
  )
}

export function Image(props: {image?: string}) {
  const {image} = props

  if (!image) {
    return null
  }

  return <img className={styles.styledImage} src={image} alt="" />
}
