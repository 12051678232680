/* eslint-disable jsx-a11y/anchor-has-content */
import React, {CSSProperties} from 'react'

export interface AbsoluteLinkProps {
  id: string
  className?: string
  children: React.ReactNode
  disabled?: boolean
  'aria-label'?: string
  onClick?: (e?: React.MouseEvent) => void
  newTab?: boolean
  disableStyles?: boolean
  noUnderline?: boolean
  to: string
  download?: boolean
  style?: CSSProperties
}

export const AbsoluteLink = React.forwardRef(
  (props: AbsoluteLinkProps, ref: React.Ref<any>) => {
    const {
      disableStyles,
      noUnderline,
      to,
      className,
      download,
      disabled,
      children,
      onClick,
    } = props
    const target = props.newTab ? '_blank' : '_self'
    const rel = props.newTab ? 'noopener noreferrer' : ''

    const underline = !disableStyles && !noUnderline

    if (disabled) {
      return (
        <DisabledLink
          onClick={(e) => e?.preventDefault()}
          ref={ref}
          href={to}
          className={className}
          aria-label={props['aria-label']}
          disableStyles={disableStyles}
          style={props.style}
        >
          {children}
        </DisabledLink>
      )
    }

    return (
      <StyledLink
        onClick={onClick}
        ref={ref}
        href={to}
        underline={underline}
        className={className}
        target={target}
        rel={rel}
        aria-label={props['aria-label']}
        download={download}
        style={props.style}
      >
        {children}
      </StyledLink>
    )
  },
)

interface DisabledLinkProps extends StyledLinkProps {
  disableStyles?: boolean
}

const DisabledLink = React.forwardRef<HTMLAnchorElement, DisabledLinkProps>(
  (props, ref) => {
    const {disableStyles, ...linkProps} = props

    return (
      <StyledLink
        {...linkProps}
        ref={ref}
        style={{
          opacity: disableStyles ? 1 : 0.8,
          cursor: 'not-allowed',
        }}
      />
    )
  },
)

interface StyledLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  underline?: boolean
  style?: React.CSSProperties
}

const StyledLink = React.forwardRef<HTMLAnchorElement, StyledLinkProps>(
  (props, ref) => {
    const {id, underline, style, ...linkProps} = props

    const className = `link-${id}`

    return (
      <>
        <style>
          {`
          .${className}:hover {
            text-decoration: ${underline ? 'underline' : 'none'}
          }
        `}
        </style>
        <a
          {...linkProps}
          ref={ref}
          style={{
            fontSize: 'inherit',
            ...style,
          }}
        />
      </>
    )
  },
)
