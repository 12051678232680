import {ObvioEvent} from 'Event'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {EventWithUpsellPage} from 'lib/marketplace-api/upsell-page/use-upsell-page-events'
import {UpsellPage} from 'Event/Marketplace/upsell-page'

export function useMarketplaceUpsellPageSampleEvents() {
  const {client} = useOrganization()

  return useQuery({
    queryKey: ['events', 'upsell_page_samples'],
    queryFn: () =>
      new Promise<EventWithUpsellPage[]>(async (resolve) => {
        const events = await client.get<Array<ObvioEvent>>(
          api('/events/upsell_page_samples'),
        )

        const upsellPages = events.map(async (event) => {
          const upsellPage = await client.get<UpsellPage | null>(
            marketplaceApi(`/events/${event.id}/upsell_page`),
          )

          if (!upsellPage?.template) {
            return null
          }

          const hasSections = Boolean(upsellPage.template.sections)
          if (!hasSections) {
            return null
          }

          return {
            ...event,
            upsellPageTemplate: upsellPage.template,
          }
        })

        const result = (await Promise.all(upsellPages)).filter(
          (purchasePage) => purchasePage,
        ) as EventWithUpsellPage[]

        resolve(result)
      }),
  })
}
