import React from 'react'
import Container from '../../Block/Container'
import {BlockComponentProps} from '../index'
import BillingaddressFields from '../PurchaseForm/BillingAddressFields'
import ShippingAddressFields from '../PurchaseForm/ShippingAddressFields'
import OrderConfirmation from '../PurchaseForm/OrderConfirmation'
import {usePurchaseForm} from '../PurchaseForm/PurchaseFormProvider'
import PurchaseFormTextField from '../PurchaseForm/PurchaseFormTextField'
import PurchaseFormButton from '../PurchaseForm/PurchaseFormButton'
import PurchaseFormStripe from '../../PurchaseFormStripe'
import {usePurchase} from '../../PurchaseContext'
import FreePurchaseFormProvider from '../PurchaseForm/FreePurchaseFormProvider'
import StripePurchaseFormProvider from '../PurchaseForm/StripePurchaseFormProvider'
import PurchaseButton from '../PurchaseForm/PurchaseButton'
import PurchaseFormTitle from '../PurchaseForm/PurchaseFormTitle'
import {ErrorBox} from '../PurchaseForm'
import {UpsellPurchaseButtonBlock} from './'

export const ADDRESS_TYPE_BILLING = 'billing'
export const ADDRESS_TYPE_SHIPPING = 'shipping'
export type AddressType =
  | typeof ADDRESS_TYPE_BILLING
  | typeof ADDRESS_TYPE_SHIPPING

export interface AttendeeCollectionProps {
  active: boolean
  backClick?: () => void
  config: UpsellPurchaseButtonBlock
  continueClick: () => void
  disabled?: boolean
}

export interface UpsellPurchaseFormProps extends UpsellPurchaseButtonBlock {
  TitleComponent?: React.FC<any>
  TextFieldComponent?: React.FC<any>
  ButtonComponent?: React.FC<any>
  CustomerDetailsSectionLabelComponent?: React.FC<any>
  BillingFieldsLabelComponent?: React.FC<any>
  ShippingFieldsLabelComponent?: React.FC<any>
  PurchaseSummaryLabelComponent?: React.FC<any>
  TotalAmountLabelComponent?: React.FC<any>
  ItemLabelComponent?: React.FC<any>
  CardFieldsLabelComponent?: React.FC<any>
  stripeAccountId?: string
}

export default function UpsellPurchaseForm(
  props: UpsellPurchaseFormProps & BlockComponentProps,
) {
  const {stripeAccountId, stripePublicKey} = usePurchase()

  const accountId = props.stripeAccountId ?? stripeAccountId

  if (!accountId) {
    return (
      <FreePurchaseFormProvider config={props}>
        <Content {...props} />
      </FreePurchaseFormProvider>
    )
  }

  return (
    <PurchaseFormStripe
      stripeAccountId={accountId}
      stripePublicKey={stripePublicKey}
    >
      <StripePurchaseFormProvider config={props}>
        <Content {...props} />
      </StripePurchaseFormProvider>
    </PurchaseFormStripe>
  )
}

function Content(props: UpsellPurchaseFormProps & BlockComponentProps) {
  const {
    TitleComponent = PurchaseFormTitle,
    TextFieldComponent = PurchaseFormTextField,
    ButtonComponent = PurchaseFormButton,
    BillingFieldsLabelComponent,
    ShippingFieldsLabelComponent,
    PurchaseSummaryLabelComponent,
    TotalAmountLabelComponent,
    CardFieldsLabelComponent,
    ItemLabelComponent,
    isEditMode,
  } = props
  const {hasError} = usePurchaseForm()
  const {isProcessing, selectedTicket} = usePurchase()

  return (
    <Container {...props}>
      <div className="block-content" style={{width: '100%'}}>
        <TitleComponent {...props} />
        {(selectedTicket?.billing_address_enabled || isEditMode) && (
          <BillingaddressFields
            TextFieldComponent={TextFieldComponent}
            BillingFieldsLabelComponent={BillingFieldsLabelComponent}
            {...props}
          />
        )}
        {(selectedTicket?.shipping_address_enabled || isEditMode) && (
          <ShippingAddressFields
            {...props}
            TextFieldComponent={TextFieldComponent}
            ShippingFieldsLabelComponent={ShippingFieldsLabelComponent}
          />
        )}
        {((selectedTicket && selectedTicket.price > 0) || isEditMode) && (
          <OrderConfirmation
            {...props}
            PurchaseSummaryLabelComponent={PurchaseSummaryLabelComponent}
            TotalAmountLabelComponent={TotalAmountLabelComponent}
            ItemLabelComponent={ItemLabelComponent}
            CardFieldsLabelComponent={CardFieldsLabelComponent}
          />
        )}
        <ErrorBox showError={hasError} errors={props.errors} />
        <PurchaseButton
          {...props}
          ButtonComponent={ButtonComponent}
          disabled={isProcessing || !selectedTicket}
        />
      </div>
    </Container>
  )
}
