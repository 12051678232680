import ConfigurableSectionLabel, {
  ConfigurableSectionLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableSectionLabel'
import React from 'react'

export default function ConfigurablePurchaseSummaryLabel(
  props: ConfigurableSectionLabelProps,
) {
  const {purchaseSummaryLabel} = props

  return (
    <ConfigurableSectionLabel
      {...props}
      children={purchaseSummaryLabel ?? 'Purchase Summary'}
      inputName="purchaseSummaryLabel"
    />
  )
}
