import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import CustomFontSizeInputFormView from './fontsizeformview';
import fontSizeIcon from '../../../obvio-custom-button/theme/font-size.svg';
import {DEFAULT_FONT_SIZE, showFakeVisualSelection, hideFakeVisualSelection, makeSelectionHighlighter} from '../color-utils';
import { DropdownView, InputTextView, LabeledFieldView } from '@ckeditor/ckeditor5-ui';
import FontSizeCommand from './fontsizecommand';

const VISUAL_SELECTION_MARKER_NAME = 'font-size-ui';

// Custom type for LabeledInputTextView
type LabeledInputTextView = LabeledFieldView & {
    fieldView: {
        value: string;
        element: HTMLInputElement;
        select(): void;
    };
}

export default class FontSizeUI extends Plugin {

	static get pluginName() {
		return 'CustomFontSizeUI';
	}

	init() {
		const editor = this.editor;

		editor.ui.componentFactory.add( 'customFontSize', locale => {
			const dropdown = createDropdown( locale );
			const form = new CustomFontSizeInputFormView( editor.locale );
			const command: FontSizeCommand = editor.commands.get( 'customFontSize' ) as FontSizeCommand;

			this._setUpDropdown( dropdown, form, command );
			this._setUpForm( dropdown, form, command );

			// Renders a fake visual selection marker on an expanded/collapsed selection.
			makeSelectionHighlighter(editor, VISUAL_SELECTION_MARKER_NAME)

			return dropdown;
		} );
	}

	private _setUpDropdown( dropdown: DropdownView, form: CustomFontSizeInputFormView, command: FontSizeCommand ) {
		const editor = this.editor;
		const t = editor.t;
		const button = dropdown.buttonView;

		dropdown.bind( 'isEnabled' ).to( command );
		dropdown.panelView.children.add( form );

		button.set( {
			label: t( 'FontSize' ),
			icon: fontSizeIcon,
			tooltip: true
		} );

		button.on( 'open', () => {

			const fieldView: LabeledInputTextView['fieldView'] = form.fontsizeInputView.fieldView as LabeledInputTextView['fieldView'];
			fieldView.value = (fieldView.element as HTMLInputElement).value = (command.value || DEFAULT_FONT_SIZE) as string;

			(fieldView.element as HTMLInputElement).type = "number";

			fieldView.select();
			form.focus();

		}, { priority: 'low' } );

		dropdown.on( 'submit', () => {

			editor.execute( 'customFontSize', (form.fontsizeInputView.fieldView.element as HTMLInputElement).value );
			closeUI();

		} );

		dropdown.on( 'cancel', () => {
			closeUI();
		} );

		// Show the selection color
		dropdown.on('change:isOpen', (evt, name, isVisible) => {
			if( isVisible ) {
				showFakeVisualSelection(editor.model, VISUAL_SELECTION_MARKER_NAME)
			}else{
				hideFakeVisualSelection(editor.model, VISUAL_SELECTION_MARKER_NAME)
			}
		})

		function closeUI() {
			editor.editing.view.focus();
			dropdown.isOpen = false;
		}
	}
	private _setUpForm( dropdown: DropdownView, form: CustomFontSizeInputFormView, command: FontSizeCommand ) {
		form.delegate( 'submit', 'cancel' ).to( dropdown );

        (form.fontsizeInputView.fieldView as InputTextView).bind('value').to(command, 'value');

	}
}
