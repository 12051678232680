import React from 'react'
import styles from './VisibleContent.module.css'
import {BlockBase} from './base'
import classNames from 'classnames'

export interface VisibleContentProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  visibility: BlockBase['visibility']
  isMobileMode?: boolean
}

const VisibleContent = React.forwardRef<HTMLDivElement, VisibleContentProps>(
  (props, ref) => {
    const {className, visibility, isMobileMode, ...divProps} = props

    if (visibility === 'desktop_only' && isMobileMode) {
      return null
    }

    return (
      <div
        {...divProps}
        className={classNames(className, {
          [styles.mobileOnly]: visibility === 'mobile_only' && !isMobileMode,
          [styles.desktopOnly]: visibility === 'desktop_only',
        })}
        ref={ref}
      />
    )
  },
)

VisibleContent.displayName = 'VisibleContent'

export default VisibleContent
