import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ConfirmRemoveButton from 'lib/ui/Button/ConfirmRemoveButton'
import ConfigPanel from 'lib/ui/ConfigPanel'
import Tab from 'lib/ui/ConfigPanel/Tab'
import TabPanel, {TabPanelBox} from 'lib/ui/ConfigPanel/TabPanel'
import TabLabel from 'lib/ui/ConfigPanel/TabLabel'
import {TabProps} from '@material-ui/core/Tab'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import Button, {ButtonProps} from 'lib/ui/Button'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'
import {usePrevious} from 'lib/state'
import {Body} from 'lib/ui/ConfigPanel/Content'
import {Panel} from 'organization/Event/DashboardConfig/ComponentConfigPanel/Panel'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'

export interface ComponentConfigProps {
  showing: boolean
  isDialogModal?: boolean
  onClose: () => void
}

/**
 * Config sections, child components must be wrapped in these
 * components to render in the correct spot.
 */
const SECTIONS = [SettingsPanel, StylingPanel, RulesPanel]

export default function ComponentConfig(
  props: ComponentConfigProps & {
    title: string
    onSubmit?: (e: React.FormEvent) => void
    children: JSX.Element | JSX.Element[]
    hasChanges?: boolean
  },
) {
  const {
    showing: isVisible,
    onClose,
    title,
    onSubmit = (event) => {
      // Prevent submitting if no submit handler
      // was provided.
      event.preventDefault()
    },
    children,
  } = props

  const {clear: clearUpdates} = useTemplateEditor()

  const prevShowing = usePrevious(isVisible)

  const components = Array.isArray(children) ? children : [children]

  const body = createBody(components)

  const otherComponents = components.filter((c) => !SECTIONS.includes(c.type))

  // Clear all unsaved template edits when the panel closes
  useEffect(() => {
    // Only clear if it was hidden to prevent unecessary re-renders
    // on load.
    if (!prevShowing) {
      return
    }

    if (isVisible) {
      return
    }

    clearUpdates()
  }, [isVisible, clearUpdates, prevShowing])

  const [
    showingUnsavedChangesDialog,
    setShowingUnsavedChangesDialog,
  ] = useState(false)

  const handleClose = () => {
    if (props.hasChanges) {
      setShowingUnsavedChangesDialog(true)
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (!isVisible) {
      setShowingUnsavedChangesDialog(false)
    }
  }, [isVisible, setShowingUnsavedChangesDialog])

  return (
    <DarkThemeProvider>
      <ConfigPanel
        title={title}
        open={isVisible}
        onClose={handleClose}
        container={<Form onSubmit={onSubmit} noValidate />}
        isDialogMode={props.isDialogModal}
      >
        <ConfirmUnsavedChangesDialog
          onDiscard={onClose}
          onCancel={() => setShowingUnsavedChangesDialog(false)}
          open={showingUnsavedChangesDialog}
          onSubmit={props.onSubmit}
        />
        <Body>{body}</Body>
        <>{otherComponents}</>
      </ConfigPanel>
    </DarkThemeProvider>
  )
}

/**
 * Dynamically create the <ConfigPanel> body components. Components
 * should be pairs of Tab/TabPanel(s), and here we're checking to
 * see if the component names are one of the defined sections
 *
 * @param components
 * @returns
 */
function createBody(components: JSX.Element[]) {
  let result = []

  // A lot of repition here, unfortunately Tabs/TabPanel(s) have to be
  // top-level components to receive props.
  for (const [index, component] of components.entries()) {
    const panel = <TabPanel key={`${index}_panel`}>{component}</TabPanel>

    if (component.type === SettingsPanel) {
      result.push(<SettingsTab key={`${index}_tab`} />)
      result.push(panel)
    }

    if (component.type === StylingPanel) {
      result.push(<StylingTab key={`${index}_tab`} />)
      result.push(panel)
    }

    if (component.type === RulesPanel) {
      result.push(<RulesTab key={`${index}_tab`} />)
      result.push(panel)
    }
  }

  return result
}

function SettingsTab(props: TabProps) {
  return (
    <Tab {...props}>
      <TabLabel iconClass="fas fa-cog" text="Settings" />
    </Tab>
  )
}

function StylingTab(props: TabProps) {
  return (
    <Tab {...props}>
      <TabLabel iconClass="fas fa-pencil" text="Styling" />
    </Tab>
  )
}

export function RulesTab(props: TabProps) {
  return (
    <Tab {...props}>
      <TabLabel iconClass="fas fa-check-double" text="Rules" />
    </Tab>
  )
}

export function SaveButton(
  props: Omit<ButtonProps, 'children'> & {children?: string},
) {
  const label = props.children || 'SAVE'
  return (
    <SpacedButton
      fullWidth
      variant="contained"
      color="success"
      type="submit"
      aria-label="save"
      {...props}
    >
      {label}
    </SpacedButton>
  )
}

export function CancelButton(props: Omit<ButtonProps, 'children'>) {
  return (
    <SpacedButton
      fullWidth
      variant="contained"
      color="default"
      type="button"
      aria-label="cancel"
      {...props}
    >
      CANCEL
    </SpacedButton>
  )
}

const SpacedButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[2]}!important;
  margin-right: ${(props) => props.theme.spacing[2]}!important;
`

export type RemoveButtonProps = Omit<ButtonProps, 'children'> & {
  children?: string
  showing?: boolean
}

export function RemoveButton(props: RemoveButtonProps) {
  const label = props.children || 'REMOVE'

  /**
   * If we didn't specify a showing prop, let's show by default
   */
  const showing = props.showing === undefined ? true : props.showing

  return (
    <StyledRemoveButton
      fullWidth
      variant="outlined"
      aria-label="remove"
      showing={showing}
      {...props}
    >
      {label}
    </StyledRemoveButton>
  )
}

const StyledRemoveButton = styled((props) => {
  const {showing: _, ...otherProps} = props
  return <ConfirmRemoveButton {...otherProps} />
})`
  ${(props) => (props.showing ? '' : 'display: none;')}
  margin-left: ${(props) => props.theme.spacing[2]}!important;
  margin-right: ${(props) => props.theme.spacing[2]}!important;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.input.background};
  padding: ${(props) => `${props.theme.spacing[2]}`}!important;
  border-bottom-left-radius: 4px;

  > * {
    flex: 1;
  }
`

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;

  // Override style to make main content full height to only scroll
  // inner container.
  ${TabPanelBox} {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    // Make inner panel scroll
    ${Panel} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
