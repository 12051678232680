import React, {useCallback} from 'react'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useEventAuth} from 'Event/auth'
import {useEvent} from 'Event/EventProvider'
import {api, useQueryParams} from 'lib/url'
import {useAsync} from 'lib/async'
import {Redirect, useParams} from 'react-router-dom'
import {eventRoutes} from 'Event/Routes'
import {useWithAttendeeData} from 'Event/auth/attendee-data'
import {parseVariables} from 'lib/template'

export interface RedirectLink {
  id: number
  redirect_slug: string
  destination: string
  description: string
}

export default function CustomRedirects() {
  const {user} = useEventAuth()
  const {data: customRedirect, loading} = useFetchRedirect()
  const {redirect_slug} = useParams<{redirect_slug: string}>()
  const withAttendeeData = useWithAttendeeData()
  const queryParams = useQueryParams()

  if (loading) {
    return <FullPageLoader />
  }

  if (!customRedirect) {
    return <Redirect to={eventRoutes.login} />
  }

  const hasVariables = parseVariables(customRedirect.destination).length !== 0

  if (!hasVariables) {
    window.location.href = mergeQueryParams(
      customRedirect.destination,
      queryParams,
    )
    return <FullPageLoader />
  }

  if (hasVariables && user) {
    window.location.href = mergeQueryParams(
      withAttendeeData(customRedirect.destination),
      queryParams,
    )
    return <FullPageLoader />
  }

  // Here means that we NEED Attendee data, but here is no logged in session at
  // the moment. We're going to redirect the user the the /ra/[redirect_slug]
  // route, so we can utilize the Deep Link logic that will send them (essentially)
  // back to this component after authenticating through the Login component.
  //
  // This works because the /ra/[redirect_slug] ONLY exists in authenticated
  // routes, which is how the Deep Link flow can work.
  const destinationWithAuth = eventRoutes.ra[':redirect_slug'].root.replace(
    ':redirect_slug',
    redirect_slug,
  )

  return <Redirect to={destinationWithAuth} />
}

function mergeQueryParams(destination: string, queryParams: object): string {
  const newDestination = new URL(destination)

  for (const [key, value] of Object.entries(queryParams)) {
    newDestination.searchParams.append(key, value || '')
  }

  return newDestination.href
}

function useFetchRedirect() {
  const {event, client} = useEvent()
  const {redirect_slug} = useParams<{redirect_slug: string}>()
  const url = api(`/redirects/${event.id}/${redirect_slug}`)
  const request = useCallback(() => client.get<RedirectLink>(url), [
    url,
    client,
  ])

  return useAsync(request)
}
