import React from 'react'
import styles from './styles/Checkbox.module.css'
import {Label} from './typography'

export type CheckboxProps = {
  color?: string
  label?: string
  checked?: boolean
  disabled?: boolean
  'aria-label'?: string
  onChange?: (v: boolean) => void
}

export default function Checkbox(props: CheckboxProps) {
  const {label, checked, disabled, onChange, color} = props

  const toggle = () => {
    if (!onChange || disabled) {
      return
    }

    onChange(!checked)
  }

  const labelClassName = styles.checkboxLabel
  const spanClassName = styles.checkboxSpan

  return (
    <>
      <style>
        {`
             .${labelClassName}{
              cursor: ${disabled ? 'not-allowed' : 'pointer'};
              color: ${disabled ? '#c4c4c4' : '#000000'};
            }
  
            .${labelClassName}::hover + span{
              background-color: #ccc;
            }
  
            .${labelClassName} input:checked  + span{
              background-color: ${color ? color : '#000000'};
            }
  
            .${labelClassName} input:checked  + span::after{
              display: block;
            }

          .${spanClassName}{
            border: 1px solid ${disabled ? '#c4c4c4' : '#000000'};
          }

          .${spanClassName}::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          
        `}
      </style>

      <Label className={labelClassName} onClick={toggle}>
        {label}
        <input
          className={styles.checkboxInput}
          type="checkbox"
          aria-label={props['aria-label']}
          checked={checked}
          readOnly
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          disabled={disabled}
        />
        <span className={spanClassName} />
      </Label>
    </>
  )
}
