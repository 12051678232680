import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'

export default function DescriptionConfig() {
  const saveTemplate = useSaveTemplate()
  const {login} = useTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const description = replaceAtPath(
      data,
      'login.passwordReset.description',
      '{{password_reset_description}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        password_reset_description: description ?? '',
      }),
    })
  }

  const {
    form: {register},
  } = useConfig()

  return (
    <Config title="Description" onSave={save}>
      <SettingsPanel>
        <Title>Description</Title>
        <TextField
          name="login.passwordReset.description"
          defaultValue={v(login.passwordReset.description)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'set form description',
            ref: register,
          }}
        />
        <Divider />
        <Title>Success Mesage</Title>
        <TextField
          name="login.passwordReset.successMessage"
          defaultValue={login.passwordReset.successMessage}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'success message',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}
