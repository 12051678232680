import React from 'react'
import styled from 'styled-components'
import {Tiny as BaseTiny} from 'lib/ui/typography'
import {useStatistics} from 'Event/Statistics'

const DEFAULT_FONT_SIZE = 12
const DEFAULT_LINE_HEIGHT = 14

export default function Tiny(props: {children: React.ReactElement | string}) {
  const {fontSize} = useStatistics()

  return (
    <Text color="white" delta={fontSize}>
      {props.children}
    </Text>
  )
}

const Text = styled(BaseTiny)<{delta: number}>`
  font-size: ${(props) => DEFAULT_FONT_SIZE + props.delta}px;
  line-height: ${(props) => DEFAULT_LINE_HEIGHT + props.delta}px;
`
