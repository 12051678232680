import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useObvioUser} from 'obvio/auth'
import {TemplateChangePassword} from 'Event/auth/ChangePassword/'
import {useEvent} from 'Event/EventProvider'
import DisabledOverlay from 'lib/ui/DisabledOverlay'

export default function ChangePasswordConfig() {
  const user = useObvioUser()
  const event = useEvent()
  const emailLoginOnly = event.event.allows_email_only_login

  return (
    <DesignBreadcrumbs page="Change Password">
      <Page>
        <>
          <DisabledOverlay
            show={!!emailLoginOnly}
            message={
              'The Password function has been disabled for this event. <br/> The Password function can be enabled on the Event Settings page.'
            }
          />
          <TemplateChangePassword user={user} />
        </>
      </Page>
    </DesignBreadcrumbs>
  )
}
