import React from 'react'
import styled from 'styled-components'
import {rgba} from 'lib/color'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useToggleArray} from 'lib/toggle'
import {useEditMode} from 'Event/EditModeProvider'
import SponsorForm from 'Event/Sponsors/SponsorForm'
import Buttons from 'Event/Sponsors/Buttons'
import QuestionIcon from 'Event/Sponsors/QuestionIcon'
import Logo from 'Event/Sponsors/Logo'
import {Sponsor} from 'Event/Sponsors'
import {SponsorConfigurable} from 'Event/Sponsors/SponsorConfig'
import {useAttendeeVariables} from 'Event'
import TextContent from 'lib/ui/form/TextEditor/Content'
import SponsorConfig from 'Event/template/Panels/Dashboard/Sponsors/SponsorList/Card/SponsorConfig'
import {useSortable} from '@dnd-kit/sortable'
import {DraggableAttributes} from '@dnd-kit/core'
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities'

/**
 * Button width is fixed for all buttons. This sets the width relative
 * to the current size of the sponsor card.
 */
const BUTTONS_WIDTH_PERCENT = 75

type SponsorProps = {
  index: number
  sponsor: Sponsor
  className?: string
  id: string
  listeners?: SyntheticListenerMap | undefined
  attributes?: DraggableAttributes
}

export default function Card(props: SponsorProps) {
  const {id} = props
  const isEditMode = useEditMode()
  const {attributes, listeners} = useSortable({id})

  if (!isEditMode) {
    return <Content {...props} />
  }

  return (
    <div className={props.className}>
      <Content {...props} attributes={attributes} listeners={listeners} />
    </div>
  )
}

function Content(props: SponsorProps) {
  const {sponsor, id, listeners, attributes} = props
  const [showingForm, toggleForm] = useToggleArray()

  const {
    sponsors: {cardBackgroundColor, cardBackgroundOpacity},
  } = usePanelsTemplate()

  const backgroundColor = rgba(cardBackgroundColor, cardBackgroundOpacity / 100)

  return (
    <Box aria-label="sponsor" backgroundColor={backgroundColor}>
      <SponsorConfigurable>
        <SponsorConfig id={props.id} sponsor={sponsor} />
        <div>
          <SponsorForm
            sponsor={sponsor}
            showing={showingForm}
            onClose={toggleForm}
          />
          <StyledGrid>
            <DragBox {...listeners} {...attributes} />
            <StyledImage sponsor={sponsor} />
            <Body sponsor={sponsor} />
            <ButtonsContainer>
              <Buttons sponsorId={id} />
            </ButtonsContainer>
            <StyledQuestionIcon
              id={id}
              sponsor={sponsor}
              onClick={toggleForm}
            />
          </StyledGrid>
        </div>
      </SponsorConfigurable>
    </Box>
  )
}

function Body(props: {sponsor: Sponsor}) {
  const {sponsor} = props
  const v = useAttendeeVariables()

  if (!sponsor.description) {
    return null
  }

  return (
    <BodyBox>
      <TextContent>{v(sponsor.description)}</TextContent>
    </BodyBox>
  )
}

const BodyBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  z-index: 1;

  /** 
   * Remove any bottom margin from the last element which
   * would mess up vertical centering.
  **/
  > div {
    > *:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledGrid = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10% 4%;
  height: 100%;
`

const Box = styled((props) => {
  const {backgroundColor: _, ...otherProps} = props
  return <div {...otherProps} />
})<{backgroundColor: string}>`
  background: ${(props) => props.backgroundColor};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  > div {
    flex: 1;
  }
`

const StyledImage = styled(Logo)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  img {
    width: 50%;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 8px;
  z-index: 1;
  width: ${BUTTONS_WIDTH_PERCENT}%;
`

const StyledQuestionIcon = styled(QuestionIcon)`
  position: absolute;
  right: 16px;
  bottom: 8px;
  width: 20%;
  max-width: 80px;
  z-index: 1;
`

const DragBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  cursor: pointer;
`
