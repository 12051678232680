import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import TextField from 'lib/ui/TextField'
import {useObieService} from 'organization/Obie/ObieServiceProvider'

export default function CreateProfileDialog(props: {
  categoryId?: number
  onClose: () => void
  onSubmit: (categoryId: number, profileId: number) => void
  open: boolean
}) {
  const {categoryId, onClose, open} = props
  const {handleSubmit, control} = useForm()
  const {getCategory, createProfile} = useObieService()

  if (!open || !categoryId) {
    return null
  }

  const category = getCategory(categoryId)

  const onSubmit = (formData: any) => {
    createProfile(categoryId, formData.name).then((response) => {
      props.onSubmit(categoryId, response.id)
    })
  }

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>{category.name} : Create New Profile</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({value, onChange}) => (
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={onChange}
                required
                type="text"
                value={value}
                variant="outlined"
              />
            )}
          />
          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`
