import Box from '@material-ui/core/Box'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import Dialog from 'lib/ui/Dialog'
import React from 'react'

export default function DuplicatingEventDialog() {
  const {event} = useEvent()

  if (event.is_copying) {
    return (
      <Dialog open>
        <DialogContent>
          <Box py={5}>
            <Box mb={2}>
              <Typography align="center" variant="h5">
                Building Your Duplicate
              </Typography>
            </Box>
            <Typography align="center">
              Please wait, will be ready soon.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return null
}
