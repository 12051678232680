import React from 'react'
import styles from './styles/RadioInput.module.css'

interface RadionInputProps {
  checked: boolean
  icon: JSX.Element
  checkedIcon: JSX.Element
  name: string
  value: string | number
}

export default function RadioInput(props: RadionInputProps) {
  const {name, value} = props

  return (
    <div className={styles.holder}>
      <label className={styles.label}>
        <input
          type="radio"
          name={name}
          value={value}
          className={styles.hiddenInput}
        />
        <RadioIcon {...props} />
      </label>
    </div>
  )
}

const RadioIcon = (props: RadionInputProps) => {
  const {checked, icon, checkedIcon} = props

  if (checked) {
    return checkedIcon
  }

  return icon
}
