import MenuItem from '@material-ui/core/MenuItem'
import {onUnknownChangeHandler} from 'lib/dom'
import {useCertificates} from 'lib/event-api/certificates/use-certificates'
import Select, {SelectProps} from 'lib/ui/Select'
import React from 'react'

export default function CertificateSelect(
  props: Omit<SelectProps, 'onChange' | 'value'> & {
    onChange: (id: string | null) => void
    value?: string | null
  },
) {
  const {onChange, value} = props
  const {data: certificates = []} = useCertificates()

  const selectCertificate = (id?: string) => {
    if (id === undefined) {
      return
    }
    const value = id || null
    onChange(value)
  }

  return (
    <Select
      {...props}
      value={value || ''}
      fullWidth
      aria-label="select certificate"
      onChange={onUnknownChangeHandler(selectCertificate)}
    >
      <MenuItem value={0}>None</MenuItem>
      {certificates.map((c) => (
        <MenuItem key={c.id} value={c.id} aria-label={`pick ${c.title}`}>
          {c.title}
        </MenuItem>
      ))}
    </Select>
  )
}
