import React from 'react'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import {Controller} from 'react-hook-form'
import FormControl from 'lib/ui/FormControl'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import {useTemplate} from 'Event/TemplateProvider'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import Select from 'lib/ui/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {onUnknownChangeHandler} from 'lib/dom'
import {LeftySponsor} from 'Event/template/Lefty/Sponsors/SponsorList'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {sponsor: LeftySponsor; id: string}) {
  const {sponsor, id} = props
  const {
    form: {control, register},
  } = useConfig()
  const {sponsors} = useTemplate()
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name={`sponsors.items.${id}.isActive`}
        control={control}
        defaultValue={sponsor?.isActive ?? true}
        render={({onChange, value}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
          />
        )}
      />
      <TextField
        name={`sponsors.items.${id}.name`}
        label="Name"
        required
        fullWidth
        inputProps={{
          ref: register,
          'aria-label': 'sponsor name',
        }}
        defaultValue={v(sponsor.name)}
      />
      <InputLabel>Description</InputLabel>
      <Controller
        name={`sponsors.items.${id}.description`}
        defaultValue={v(sponsor.description || '')}
        control={control}
        render={({onChange, value}) => (
          <StyledTextEditorContainer>
            <TextEditor data={value} onChange={onChange} />
          </StyledTextEditorContainer>
        )}
      />

      <Controller
        name={`sponsors.items.${id}.image`}
        control={control}
        defaultValue={sponsor.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Logo"
            uploadInputProps={{
              'aria-label': 'sponsor logo upload',
            }}
            width={435}
            height={264}
            canResize
            disableAutoRemove
          />
        )}
      />

      <InputLabel>Select Level</InputLabel>
      <Controller
        name={`sponsors.items.${id}.level`}
        control={control}
        defaultValue={sponsor.level}
        render={({onChange, value}) => (
          <FormControl fullWidth>
            <Select
              {...props}
              value={value}
              fullWidth
              aria-label="select sponsor level"
              onChange={onUnknownChangeHandler(onChange)}
            >
              <MenuItem value={1}>One Per Row</MenuItem>
              <MenuItem value={2}>Two Per Row</MenuItem>
              <MenuItem value={3}>Three Per Row</MenuItem>
              <MenuItem value={4}>Four Per Row</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <InputLabel>Form</InputLabel>
      <Controller
        name={`sponsors.items.${id}.formId`}
        control={control}
        defaultValue={sponsor.formId}
        render={({onChange, value}) => (
          <FormControl fullWidth>
            <FormSelect value={value} onChange={onChange} />
          </FormControl>
        )}
      />
      <>
        <InputLabel>Question Icon</InputLabel>
        <Controller
          name="sponsors.questionIcon"
          control={control}
          defaultValue={sponsors.questionIcon}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              canResize
              removeButtonProps={{
                'aria-label': 'remove sponsor question icon',
              }}
              uploadInputProps={{
                'aria-label': 'upload sponsor question icon',
              }}
              disableAutoRemove
            />
          )}
        />
      </>
    </>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
