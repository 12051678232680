import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {TeamMember} from 'auth/user'
import {useEvent} from 'Event/EventProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api, useQueryParams} from 'lib/url'
import {getLengthAsDuration} from 'lib/date-time'
import {ActiveCampaignTag} from 'Event/activeCampaign'
import {HighLevelTag} from 'Event/highlevel'
import {HubspotTag} from 'Event/hubspot'
import {InfusionsoftTag} from 'Event/infusionsoft'
import {MailchimpTag} from 'Event/mailchimp'
import {ZapierTag} from 'Event/zapier'
import {useAreas} from 'organization/Event/AreasProvider'
import {ConvertKitTag} from 'organization/Event/Services/Apps/ConvertKit/Config/Tags'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import {Webhook} from 'organization/Event/WebhooksProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export interface ZoomAttendance {
  areas: Area[]
  attendee_events: AttendeeEvents[]
  duration_attendee_average: number
  duration_session_average: number
  duration_total: number
  min_in_zoom: number
  max_in_zoom: number
  sessions: number
  unique_attendees: number
}

export interface ZoomAttendanceAction {
  message?: string
}

export interface ZoomAttendanceScheduledAction {
  action: string
  area_id: number
  data: ActionData
  end_date_time: string
  event_id: number
  executed: boolean
  id: number
  scheduled_date_time: string
  start_date_time: string
  team_member_id: number
  team_member: TeamMember
}

export interface AttendeeEvents {
  name: string
  joins: number
  lefts: number
}

export interface Area {
  id: number
  name: string
}

export interface AttendeeFilterData {
  [filterType: string]: {
    value: number
    metric: string
  }
}

export interface ActionData {
  exportType?: string

  webhookId?: number
  webhook?: Webhook

  tags?: string[]
  groupName?: string
  groupValue?: string

  activeCampaignTag?: ActiveCampaignTag
  convertKitTag?: ConvertKitTag
  highLevelTag?: HighLevelTag
  hubspotTag?: HubspotTag
  infusionsoftTag?: InfusionsoftTag
  mailchimpTag?: MailchimpTag
  ontraportTag?: OntraportTag
  zapierTag?: ZapierTag

  filter?: AttendeeFilterData
}

export interface ZoomAttendanceContextProps {
  area: number | null
  attendeeFilterData: AttendeeFilterData | null
  convertMinToDurationString: (minuteValue: number) => string
  endDateTime: string | null
  eventAreas: Area[]
  fetchZoomAttendance: (start: string, end: string, area: number | null) => void
  humanReadableActionData: (data: ActionData) => string[]
  humanReadableFilterData: (data: AttendeeFilterData | null) => string
  loading: boolean
  pickerArea: number | null
  pickerEndDateTime: string | null
  pickerStartDateTime: string | null
  scheduledActionData: object
  scheduledActionFormOpen: boolean
  scheduledActionFormToggle: (state?: boolean) => void
  setArea: (area: number | null) => void
  setAttendeeFilterData: (
    open: boolean,
    minMax: string,
    minutesPercentage: string,
    filteraValue: number,
  ) => void
  setEndDateTime: (date: string | null) => void
  setPickerArea: (area: number | null) => void
  setPickerEndDateTime: (date: string | null) => void
  setPickerStartDateTime: (date: string | null) => void
  setScheduledActionData: (data: object) => void
  setStartDateTime: (date: string | null) => void
  startDateTime: string | null
  zoomAttendance?: ZoomAttendance
}

export const ACTION_EXPORT_ATTENDEES = 'Export Attendees'
export const ACTION_OBVIO_TAG_GROUP = 'Add Obvio Tag or Group'
export const ACTION_EXTERNAL_TAG = 'Add External Tag'
export const ACTION_CUSTOM_WEBHOOK = 'Send a Custom Webhook'
export const ATTENDEE_ACTIONS = [
  ACTION_EXPORT_ATTENDEES,
  ACTION_OBVIO_TAG_GROUP,
  ACTION_EXTERNAL_TAG,
  ACTION_CUSTOM_WEBHOOK,
]

export type ATTENDEE_ACTION_TYPES =
  | typeof ACTION_EXPORT_ATTENDEES
  | typeof ACTION_OBVIO_TAG_GROUP
  | typeof ACTION_EXTERNAL_TAG
  | typeof ACTION_CUSTOM_WEBHOOK

export const OPTION_MIN = 'minimumDuration'
export const OPTION_MAX = 'maximumDuration'
export const OPTION_MINUTES = 'minutes'
export const OPTION_PERCENTAGE = 'percent'

export const SLIDER_LABEL_MIN_MINUTES = 'Minimum Minutes Attended'
export const SLIDER_LABEL_MAX_MINUTES = 'Maximum Minutes Attended'
export const SLIDER_LABEL_MIN_PERCENTAGE = 'Minimum Percentage Attended'
export const SLIDER_LABEL_MAX_PERCENTAGE = 'Maximum Percentage Attended'
export const SLIDER_LABEL_DEFAULT = SLIDER_LABEL_MIN_PERCENTAGE

export const EXPORT_TYPE_DETAIL = 'Detail'
export const EXPORT_TYPE_SUMMARY = 'Summary'
export const EXPORT_TYPES = {
  detail: EXPORT_TYPE_DETAIL,
  summary: EXPORT_TYPE_SUMMARY,
}

export const ZoomAttendanceContext = React.createContext<
  ZoomAttendanceContextProps | undefined
>(undefined)

export default function ZoomAttendanceProvider(props: {
  children: React.ReactElement
}) {
  const history = useHistory()
  const fetch = useFetchZoomAttendance()
  const {event} = useEvent()
  const {areas: eventAreas, loading: loadingAreas} = useAreas()
  const queryParams = useQueryParams()

  const [scheduledActionData, setScheduledActionData] = useState({})
  const [
    scheduledActionFormOpen,
    setScheduledActionFormOpen,
  ] = useState<boolean>(false)
  const [startDateTime, setStartDateTime] = useState<string | null>(null)
  const [endDateTime, setEndDateTime] = useState<string | null>(null)
  const [area, setArea] = useState<number | null>(null)
  const [pickerStartDateTime, setPickerStartDateTime] = useState<string | null>(
    null,
  )
  const [pickerEndDateTime, setPickerEndDateTime] = useState<string | null>(
    null,
  )
  const [pickerArea, setPickerArea] = useState<number | null>(null)
  const [zoomAttendance, setZoomAttendance] = useState<ZoomAttendance>()
  const [loading, setLoading] = useState<boolean>(false)

  const [
    attendeeFilterData,
    setAttendeeFilterDataInternal,
  ] = useState<AttendeeFilterData | null>(null)

  const convertMinToDurationString = (minuteValue: number): string => {
    let labelValue = ''
    const duration = getLengthAsDuration(minuteValue, 'minutes', false)

    if (duration.days) {
      labelValue += `${duration.days}d `
    }
    if (duration.hours !== '00') {
      labelValue += `${duration.hours}h `
    }
    labelValue += `${duration.minutes}m`

    return labelValue
  }

  const scheduledActionFormToggle = (state?: boolean) => {
    if (state === undefined) {
      setScheduledActionFormOpen((current) => !current)
      return
    }

    setScheduledActionFormOpen(state)
  }

  const humanReadableActionData = (data: ActionData): string[] => {
    const parts: string[] = []

    if (data.exportType) {
      const exportType =
        data.exportType === 'detail'
          ? EXPORT_TYPES.detail
          : EXPORT_TYPES.summary
      parts.push(`Export Type: ${exportType}`)
    }

    if (data.webhookId) {
      const customWebhook = data.webhook as Webhook
      parts.push(`Custom Webhook: ${customWebhook.custom_label}`)
    }

    if (data.tags?.length) {
      parts.push(`Tags: "${data.tags.join('", "')}"`)
    }

    if (data.groupName) {
      parts.push(
        `Group Name: "${data.groupName}" - Group Value: "${data.groupValue}"`,
      )
    }

    if (data.activeCampaignTag) {
      parts.push(`Active Campaign Tag: "${data.activeCampaignTag.tag}"`)
    }
    if (data.convertKitTag) {
      parts.push(`Convertkit Tag: "${data.convertKitTag.name}"`)
    }
    if (data.highLevelTag) {
      parts.push(`Highlevel Tag: "${data.highLevelTag.name}"`)
    }
    if (data.hubspotTag) {
      parts.push(`Hubspot Tag: "${data.hubspotTag.property_value}"`)
    }
    if (data.infusionsoftTag) {
      parts.push(`Keap Tag: "${data.infusionsoftTag.name}"`)
    }
    if (data.mailchimpTag) {
      parts.push(`Mailchimp Tag: "${data.mailchimpTag.name}"`)
    }
    if (data.ontraportTag) {
      parts.push(`Ontraport Tag: "${data.ontraportTag.tag_name}"`)
    }
    if (data.zapierTag) {
      parts.push(`Zapier Tag: "${data.zapierTag.name}"`)
    }

    return parts
  }

  const humanReadableFilterData = (data: AttendeeFilterData | null): string => {
    if (!data) {
      return 'No Filtering Supplied'
    }

    const minMax = Object.keys(data)[0]
    const filterData = data[minMax as keyof typeof data]

    if (!filterData?.metric) {
      return 'No Filtering Supplied'
    }

    const metric = filterData.metric
    let filterDataString = ''

    switch (`${minMax}.${metric}`) {
      case `${OPTION_MIN}.${OPTION_MINUTES}`:
        filterDataString = `${SLIDER_LABEL_MIN_MINUTES}: ${convertMinToDurationString(
          filterData.value,
        )}`
        break
      case `${OPTION_MAX}.${OPTION_MINUTES}`:
        filterDataString = `${SLIDER_LABEL_MAX_MINUTES}: ${convertMinToDurationString(
          filterData.value,
        )}`
        break
      case `${OPTION_MIN}.${OPTION_PERCENTAGE}`:
        filterDataString = `${SLIDER_LABEL_MIN_PERCENTAGE}: ${filterData.value}%`
        break
      case `${OPTION_MAX}.${OPTION_PERCENTAGE}`:
        filterDataString = `${SLIDER_LABEL_MAX_PERCENTAGE}: ${filterData.value}%`
        break
    }

    return filterDataString
  }

  const setAttendeeFilterData = useCallback(
    (
      open: boolean,
      minMax: string,
      minutesPercentage: string,
      filterValue: number,
    ) => {
      // If the Attendee Filter is NOT open, ensure that the attendeeFilterData
      // state is null. The filter should only be allowed to open, not subsequently
      // re-close.
      if (!open) {
        setAttendeeFilterDataInternal(null)

        return
      }

      setAttendeeFilterDataInternal({
        [minMax]: {
          value: filterValue,
          metric: minutesPercentage,
        },
      })
    },
    [setAttendeeFilterDataInternal],
  )

  const fetchZoomAttendance = useCallback(
    (start: string, end: string, area: number | null) => {
      setLoading(true)

      setStartDateTime(start)
      setEndDateTime(end)
      setArea(area)
      setPickerStartDateTime(start)
      setPickerEndDateTime(end)
      setPickerArea(area)

      let queryParts = []

      if (start) {
        queryParts.push(`start=${start}`)
      }
      if (end) {
        queryParts.push(`end=${end}`)
      }
      if (area) {
        queryParts.push(`area=${area}`)
      }

      // Only going to mess with history if there are query string parts in the
      // currnet location.
      if (queryParts.length) {
        const querySearch = `?${queryParts.join('&')}`

        // For unknown (to me, anyway...) reasons, each history.push() seems to be
        // re-adding the current location. This means that when travelling "back",
        // there are pages that need double-back actions. Checking if the current
        // search string in history is equal to what we're about to try and push,
        // if NOT, then we add to history. Otherwise, no-op.
        if (history.location.search !== querySearch) {
          history.push({search: querySearch})
        }
      }

      fetch(start, end, area).then((result) => {
        setZoomAttendance(result)
        setLoading(false)
      })
    },
    [fetch, history],
  )

  useEffect(() => {
    const queryStartDateTime = queryParams.start || ''
    const queryEndDateTime = queryParams.end || ''
    const queryArea = Number(queryParams.area) || null

    // No event? Fughetabowtit...
    if (!event) {
      return
    }

    // The start/end times are required, if we don't have those in the query string,
    // we're not going to process anything, even if the area is provided.
    if (!queryStartDateTime || !queryEndDateTime) {
      return
    }

    // If the query string values are the same as what is already in state, there's
    // nothing to process. Effectively, there hasn't been any change.
    const hasChanges =
      !loading &&
      (queryStartDateTime !== startDateTime ||
        queryEndDateTime !== endDateTime ||
        queryArea !== area)

    if (hasChanges) {
      // We have something to fetch, so away we go!
      fetchZoomAttendance(queryStartDateTime, queryEndDateTime, queryArea)
    }
  }, [
    area,
    endDateTime,
    event,
    fetchZoomAttendance,
    loading,
    queryParams,
    startDateTime,
  ])

  // Once we have the Event object, try to set the state for the start/end times.
  // Unless there is a current value already - from the query params.
  useEffect(() => {
    if (!event) {
      return
    }

    setStartDateTime((current) => current || event.start)
    setEndDateTime((current) => current || event.end)
    setPickerStartDateTime((current) => current || event.start)
    setPickerEndDateTime((current) => current || event.end)
  }, [event])

  if (loadingAreas) {
    return <FullPageLoader />
  }

  return (
    <ZoomAttendanceContext.Provider
      value={{
        area,
        attendeeFilterData,
        convertMinToDurationString,
        endDateTime,
        eventAreas,
        fetchZoomAttendance,
        humanReadableActionData,
        humanReadableFilterData,
        loading,
        pickerArea,
        pickerEndDateTime,
        pickerStartDateTime,
        scheduledActionData,
        scheduledActionFormOpen,
        scheduledActionFormToggle,
        setArea,
        setAttendeeFilterData,
        setEndDateTime,
        setPickerArea,
        setPickerEndDateTime,
        setPickerStartDateTime,
        setScheduledActionData,
        setStartDateTime,
        startDateTime,
        zoomAttendance,
      }}
    >
      {props.children}
    </ZoomAttendanceContext.Provider>
  )
}

export function useFetchZoomAttendance() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id: eventId} = event

  return useCallback(
    (
      startDateTime: string | null,
      endDateTime: string | null,
      area: number | null,
    ) => {
      const url = api(`/events/${eventId}/zoom_attendance`)
      return client.post<ZoomAttendance>(url, {
        start: startDateTime,
        end: endDateTime,
        areaId: area,
      })
    },
    [client, eventId],
  )
}

export function useZoomAttendanceAction() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id: eventId} = event

  return useCallback(
    (
      action: string,
      startDateTime: string | null,
      endDateTime: string | null,
      area: number | null,
      data: {},
    ) => {
      const url = api(`/events/${eventId}/zoom_attendance/action`)
      return client.post<ZoomAttendanceAction>(url, {
        action: action,
        start: startDateTime,
        end: endDateTime,
        areaId: area,
        data: data,
      })
    },
    [client, eventId],
  )
}

export function useZoomAttendanceScheduleAction() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id: eventId} = event

  return useCallback(
    (
      action: string,
      startDateTime: string | null,
      endDateTime: string | null,
      area: number | null,
      scheduledDateTime: string | null,
      data: {},
    ) => {
      const url = api(`/events/${eventId}/zoom_attendance/action/schedule`)
      return client.post<ZoomAttendanceAction>(url, {
        action: action,
        start: startDateTime,
        end: endDateTime,
        areaId: area,
        scheduledDateTime: scheduledDateTime,
        data: data,
      })
    },
    [client, eventId],
  )
}

export function useFetchZoomAttendanceScheduleActions() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id: eventId} = event

  return useCallback(() => {
    const url = api(`/events/${eventId}/zoom_attendance/action/schedule`)
    return client.get<ZoomAttendanceScheduledAction[]>(url)
  }, [client, eventId])
}

export function useZoomAttendanceScheduleDelete() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const {id: eventId} = event

  return useCallback(
    (scheduleId: number) => {
      const url = api(
        `/events/${eventId}/zoom_attendance/action/schedule/${scheduleId}`,
      )
      return client.delete<ZoomAttendanceAction>(url)
    },
    [client, eventId],
  )
}

export function useZoomAttendance() {
  const context = React.useContext(ZoomAttendanceContext)
  if (context === undefined) {
    throw new Error(
      'useZoomAttendance must be used within an ZoomAttendanceProvider',
    )
  }

  return context
}
