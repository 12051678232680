import React from 'react'
import {useForm} from 'react-hook-form'
import {NiftyFifty, useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/NiftyFifty/ImageWaterfall/ImageWaterfallConfig/Settings'
import Styling from 'Event/template/NiftyFifty/ImageWaterfall/ImageWaterfallConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function ImageWaterfallConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const updateTemplate = useNiftyFiftyUpdate()
  const {control, register, handleSubmit, formState} = useForm()

  const addTranslation = useAddTranslation()

  const submit = (data: NiftyFifty['imageWaterfall']) => {
    updateTemplate({
      localization: addTranslation({
        image_waterfall_title: data.title || '',
        image_waterfall_menu_title: data.menuTitle || '',
        image_waterfall_description: data.description || '',
        image_waterfall_upload_form_title: data.uploadFormTitle || '',
        image_waterfall_upload_form_description:
          data.uploadFormDescription || '',
        image_waterfall_upload_button_text: data.uploadButtonText || '',
      }),
      imageWaterfall: {
        ...data,
        title: '{{image_waterfall_title}}',
        menuTitle: '{{image_waterfall_menu_title}}',
        description: '{{image_waterfall_description}}',
        uploadFormTitle: '{{image_waterfall_upload_form_title}}',
        uploadFormDescription: '{{image_waterfall_upload_form_description}}',
        uploadButtonText: `{{image_waterfall_upload_button_text}}`,
      },
    })

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Image Waterfall"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
