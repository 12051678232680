import {Template} from 'Event/template'
import {useTemplate} from 'Event/TemplateProvider'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'

export default function TitleConfig() {
  const save = useSaveTemplate()
  const {setPasswordForm} = useTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const {
    form: {register},
  } = useConfig()

  const handleSave: TemplateSave<Template> = (data) => {
    const label = replaceAtPath(
      data,
      `setPasswordForm.title`,
      `{{set_password_title}}`,
    )

    save({
      ...data,
      localization: addTranslation({
        set_password_title: label ?? '',
      }),
    })
  }

  return (
    <Config title="Title" onSave={handleSave}>
      <SettingsPanel>
        <TextField
          name="setPasswordForm.title"
          defaultValue={v(setPasswordForm.title)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'set password form title',
            ref: register,
          }}
        />
      </SettingsPanel>
    </Config>
  )
}
