import FormControl from '@material-ui/core/FormControl'
import React, {useEffect} from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import AreaConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/AreaConfig'
import LinkConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/LinkConfig'
import FormConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/FormConfig'
import CertificateConfig from 'Event/Dashboard/components/NavButton/NavButtonConfig/TargetInput/CertificateConfig'
import {UseFormMethods} from 'react-hook-form'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import Visible from 'lib/ui/layout/Visible'

export type TargetInputProps = {
  disablePageSelect?: boolean
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  watch: UseFormMethods['watch']
  setValue: UseFormMethods['setValue']
  button: NavButtonProps
}

// ButtonGroup expects a numerical value to indicate
// which button is selected.
const TYPE = {
  LINK: 0,
  JOIN_AREA: 1,
  FORM: 2,
  IMAGE_UPLOAD: 3,
  CERTIFICATE: 4,
}

type TargetButtonProps = Pick<
  NavButtonProps,
  'isAreaButton' | 'isFormButton' | 'isImageUpload' | 'isCertificateButton'
>

export default function TargetInput(props: TargetInputProps) {
  const {register, setValue, button, watch} = props

  const {imageWaterfall} = useTemplate()

  // Must manually register dynamic form fields for 'watch' to be triggered
  // when called via setValue.
  //
  // Reference: https://github.com/react-hook-form/react-hook-form/discussions/3160#discussioncomment-95785
  useEffect(() => {
    register('isAreaButton')
    register('isFormButton')
    register('isImageUpload')
    register('isCertificateButton')

    setValue('isAreaButton', button.isAreaButton)
    setValue('isFormButton', button.isFormButton)
    setValue('isImageUpload', button.isImageUpload)
    setValue('isCertificateButton', button.isCertificateButton)
  }, [register, button, setValue])

  const value = () => {
    if (watch('isAreaButton', button.isAreaButton)) {
      return TYPE.JOIN_AREA
    }

    if (watch('isFormButton', button.isFormButton)) {
      return TYPE.FORM
    }

    if (watch('isImageUpload', button.isImageUpload)) {
      return TYPE.IMAGE_UPLOAD
    }

    if (watch('isCertificateButton', button.isCertificateButton)) {
      return TYPE.CERTIFICATE
    }

    return TYPE.LINK
  }

  const updateValue = (name: keyof TargetButtonProps, value: boolean) => {
    setValue(name, value)
  }

  return (
    <>
      <FormControl>
        <ToggleButtonGroup exclusive>
          <TargetButton
            {...props}
            label="Link"
            value={TYPE.LINK}
            selected={value()}
            isAreaButton={false}
            isFormButton={false}
            isImageUploadButton={false}
            isCertificateButton={false}
            updateValue={updateValue}
          />
          <TargetButton
            {...props}
            label="Join Area"
            aria-label="configure button to join room"
            value={TYPE.JOIN_AREA}
            selected={value()}
            isAreaButton={true}
            isFormButton={false}
            isImageUploadButton={false}
            isCertificateButton={false}
            updateValue={updateValue}
          />
          <TargetButton
            {...props}
            label="Form"
            aria-label="configure button to add form"
            value={TYPE.FORM}
            selected={value()}
            isAreaButton={false}
            isFormButton={true}
            isImageUploadButton={false}
            isCertificateButton={false}
            updateValue={updateValue}
          />
          <TargetButton
            {...props}
            label="Certificate"
            aria-label="configure button to add certificate"
            value={TYPE.CERTIFICATE}
            selected={value()}
            isAreaButton={false}
            isFormButton={false}
            isImageUploadButton={false}
            isCertificateButton={true}
            updateValue={updateValue}
          />
          <Visible when={imageWaterfall.isVisible}>
            <TargetButton
              {...props}
              label="Image Uploader"
              aria-label="configure button to image upload"
              value={TYPE.IMAGE_UPLOAD}
              selected={value()}
              isAreaButton={false}
              isFormButton={false}
              isImageUploadButton={true}
              isCertificateButton={false}
              updateValue={updateValue}
            />
          </Visible>
        </ToggleButtonGroup>
      </FormControl>
      <LinkConfig {...props} />
      <AreaConfig {...props} />
      <FormConfig {...props} />
      <CertificateConfig {...props} />
    </>
  )
}

function TargetButton(props: {
  className?: string
  label: string
  value: number
  selected: number
  isImageUploadButton: boolean
  isAreaButton: boolean
  isFormButton: boolean
  isCertificateButton: boolean
  'aria-label'?: string
  updateValue: (name: keyof TargetButtonProps, value: boolean) => void
}) {
  const {
    label,
    value,
    selected,
    isImageUploadButton,
    isAreaButton,
    isFormButton,
    isCertificateButton,
    updateValue,
  } = props

  return (
    <ToggleButton
      className={props.className}
      selected={value === selected}
      value={value}
      aria-label={props['aria-label']}
      onClick={() => {
        updateValue('isAreaButton', isAreaButton)
        updateValue('isFormButton', isFormButton)
        updateValue('isImageUpload', isImageUploadButton)
        updateValue('isCertificateButton', isCertificateButton)
      }}
    >
      {label}
    </ToggleButton>
  )
}
