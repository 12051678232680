import {PageTemplate} from 'Event/Marketplace/page-template'
import {REMOVE} from 'lib/JsonUpdateProvider'
import {getBlockLayout} from 'organization/Marketplace/config/get-block-layout'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'

interface MoveBlockParams {
  sourceSectionId: string | null
  targetSectionId: string
  blockId: string | null
  template: PageTemplate
  updateTemplate: UpdateTemplateFunction
}

export function useMoveBlock() {
  return (params: MoveBlockParams) => {
    const {
      sourceSectionId,
      blockId,
      template,
      targetSectionId,
      updateTemplate,
    } = params

    if (!sourceSectionId || !blockId) {
      return
    }

    const layout = getBlockLayout({
      sectionId: sourceSectionId,
      blockId: blockId,
      template,
    })

    if (!layout) {
      return
    }

    const sourceSection = template.sections[sourceSectionId]
    const targetSection = template.sections[targetSectionId]

    const sourceBlock = sourceSection.blocks[blockId]

    updateTemplate({
      sections: {
        [targetSectionId]: {
          blocks: {
            [blockId]: sourceBlock,
          },
          layouts: {
            desktop: [
              ...(targetSection.layouts.desktop ?? []),
              {
                i: blockId,
                x: 0,
                y: 1000000000000, // place at bottom
                w: layout.desktop.w,
                h: layout.desktop.h,
              },
            ],
            mobile: [
              ...(targetSection.layouts.mobile ?? []),
              {
                i: blockId,
                x: 0,
                y: 1000000000000, // place at bottom
                w: layout.mobile.w,
                h: layout.mobile.h,
              },
            ],
          },
        },
        [sourceSectionId]: {
          blocks: {
            [blockId]: REMOVE,
          },
          // Remove layout from previous section
          layouts: {
            desktop: sourceSection.layouts.desktop.filter(
              (layout) => layout.i !== blockId,
            ),
            mobile: sourceSection.layouts.mobile.filter(
              (layout) => layout.i !== blockId,
            ),
          },
        },
      },
    })
  }
}
