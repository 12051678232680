import React from 'react'
import Slider from 'lib/ui/Slider'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {useConfig} from 'organization/Event/Configurable'

export default function BorderSection(props: {
  resource: Resource
  storageKey: string
  sectionId: string
  id: string
}) {
  const {resource, id, sectionId} = props

  const {
    form: {control},
  } = useConfig()

  return (
    <CollapsibleSection label="Border" storageKey={props.storageKey}>
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.borderColor`}
        control={control}
        defaultValue={resource.borderColor || '#000000'}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.hoverBorderColor`}
        control={control}
        defaultValue={resource.hoverBorderColor || '#000000'}
        render={({value, onChange}) => (
          <ColorPicker label="Hover Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.borderWidth`}
        defaultValue={resource.borderWidth || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Width"
          />
        )}
      />
      <Controller
        name={`dashboardSections.${sectionId}.items.${id}.borderRadius`}
        defaultValue={resource.borderRadius || 0}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={0}
            max={100}
            onChange={onChange}
            valueLabelDisplay="off"
            value={value}
            label="Radius"
          />
        )}
      />
    </CollapsibleSection>
  )
}
