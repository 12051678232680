import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import Config from 'organization/Event/Configurable/Config'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimerSectionConfig/Styling'
import Settings from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimerSectionConfig/Settings'
import {CountdownTimerSectionProps} from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function CountdownTimerSectionConfig(props: {
  section: CountdownTimerSectionProps
  id: string
}) {
  const {section, id: sectionId} = props

  const save = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const {
    form: {setValue, control},
  } = useConfig()

  const handleSave = (data: any) => {
    const {navBar} = data
    const {menuItems} = navBar
    const {
      [sectionId]: {title: menuTitle, ...others},
    } = menuItems

    save({
      ...data,
      localization: addTranslation({
        [`navBar_menuItems_${sectionId}_title`]: menuTitle,
      }),
      navBar: {
        menuItems: {
          [sectionId]: {
            title: `{{navBar_menuItems_${sectionId}_title}}`,
            ...others,
          },
        },
      },
    })
  }

  const remove = () =>
    save({
      localization: removeTranslations([`navBar_menuItems_${sectionId}_title`]),
      dashboardSections: {
        [sectionId]: REMOVE,
      },
      navBar: {
        menuItems: {
          [sectionId]: REMOVE,
        },
      },
    })

  return (
    <Config
      title="Countdown Timer Section"
      onSave={handleSave}
      onRemove={remove}
    >
      <SettingsPanel>
        <Settings countdownTimerSection={section} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling countdownTimerSection={section} sectionId={sectionId} />
      </StylingPanel>
      <RulesPanel
        rules={section.rules}
        control={control}
        name={`dashboardSections.${sectionId}.rules`}
      >
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={section}
          fromName={`dashboardSections.${sectionId}.showingFrom`}
          untilName={`dashboardSections.${sectionId}.showingUntil`}
        />
      </RulesPanel>
    </Config>
  )
}
