import styled from 'styled-components'
import NavButton, {
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import React from 'react'
import {Draggable, DraggableProvidedDraggableProps} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {DraggableOverlay} from 'lib/ui/drag-and-drop'
import {useToggle} from 'lib/toggle'
import ActionButtonConfig from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/ActionButtonConfig'
import {useTownhallTemplate} from 'Event/template/Townhall'

type ActionButtonProps = {
  id: string
  button: NavButtonWithSize
  index: number
  hasTopBorder: boolean
}

export default React.memo((props: ActionButtonProps) => {
  const isEditMode = useEditMode()
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const {flag: showingCopyConfig, toggle: toggleCopyConfig} = useToggle()
  const {
    actionNav: {buttonHeight},
  } = useTownhallTemplate()

  const edit = () => {
    toggleConfig()
  }

  const button = (
    <StyledNavButton
      onClick={isEditMode ? edit : () => {}}
      {...props.button}
      aria-label="action button"
      height={buttonHeight}
    />
  )

  if (!isEditMode) {
    return <Container button={props.button}>{button}</Container>
  }

  return (
    <>
      <ActionButtonConfig
        showing={configVisible}
        onClose={toggleConfig}
        id={props.id}
        button={props.button}
      />
      <ActionButtonConfig
        showing={showingCopyConfig}
        onClose={toggleCopyConfig}
        button={props.button}
      />
      <Draggable draggableId={props.id} index={props.index}>
        {(provided) => (
          <Container
            button={props.button}
            ref={provided.innerRef}
            draggableProps={provided.draggableProps}
          >
            <DraggableOverlay>
              <Editable
                onEdit={edit}
                onCopy={toggleCopyConfig}
                handleProps={provided.dragHandleProps}
              >
                <>{button}</>
              </Editable>
            </DraggableOverlay>
          </Container>
        )}
      </Draggable>
    </>
  )
})

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    button: NavButtonWithSize
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  return <Button ref={ref} {...props} />
})

const Button = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    button: NavButtonWithSize
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  return (
    <>
      <Box ref={ref} {...props.draggableProps}>
        {props.children}
      </Box>
    </>
  )
})

const StyledNavButton = styled(NavButton)`
  text-align: left;
  flex-direction: row;
  padding: 15px 25px !important;
  justify-content: flex-start;
  background: unset;
  border: unset;

  &:hover {
    border: unset;
    background: unset;
  }
`

const Box = styled.div`
  width: 100%;
`
