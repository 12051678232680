import TextEditor, {TextEditorProps} from 'lib/ui/form/TextEditor'
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOGIN_URL,
  ORDER_TOTAL,
  TICKET_NAME,
} from 'lib/ui/form/TextEditor/variables'
import React from 'react'

export default function HtmlNotificationTextEditor(props: TextEditorProps) {
  return (
    <TextEditor
      toolbar={[
        'heading',
        'customFontSize',
        'fontColor',
        'fontBackgroundColor',
        'alignment',
        'bold',
        'italic',
        'strikethrough',
        'superscript',
        'removeFormat',
        '-',
        'link',
        'imageUpload',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        'obvioVariable', // Registered as a custom CK Editor Plugin
        'insertCustomButton',
        'customTheme',
      ]}
      variables={[
        LOGIN_URL,
        FIRST_NAME,
        LAST_NAME,
        EMAIL,
        TICKET_NAME,
        ORDER_TOTAL,
      ]}
      {...props}
    />
  )
}
