import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  FormQuestionProps,
  QUESTION_TYPE_PATH_PARENT,
  StyledTextField,
  useObieQuestions,
} from 'organization/Obie/ObieQuestionsProvider'

export default function RadioOptions(props: FormQuestionProps) {
  const {question, setDirty} = props
  const {
    currentAnswer,
    currentQuestionId,
    setCurrentAnswer,
    setCurrentSelectedOptionId,
    setCurrentSelectedPathId,
  } = useObieQuestions()

  const [resolvedAnswer, setResolvedAnswer] = useState<string>('')
  const [showingOtherOption, setShowingOtherOption] = useState<boolean>(false)

  const options = question.options.map((opt, index) => (
    <FormControlLabel
      key={index}
      color="primary"
      value={opt.value}
      control={<Radio />}
      label={opt.value}
    />
  ))

  const setValues = useCallback(
    (value: string) => {
      // Because we can have an "other" option, we need a local placeholder for the
      // option-that-was-picked's value.
      setResolvedAnswer(value)

      // Need to know if any of the options happen to be an "other".
      const hasOther = question.options.find((opt) => opt.is_other)

      // Find the selected option.
      const selectedOption = question.options.find((opt) => opt.value === value)

      // Sets the currently selected option id, in case this is a PathQuestion type
      // and we need to render path Questions and determine the "next" Question.
      setCurrentSelectedOptionId(selectedOption?.id)

      // If this question is a PathParent type, record the current path we're on
      // so we know how to show the other Questions.
      if (question.type === QUESTION_TYPE_PATH_PARENT) {
        setCurrentSelectedPathId(selectedOption?.id)
      }

      // If the selected option happens to be an "other", we need to clear out the
      // current answer (because we're showing a text input) and set a flag to show
      // the TextField for user input.
      if (selectedOption?.is_other) {
        setCurrentAnswer('')
        setShowingOtherOption(true)
        return
      }

      // If we haven't resolved a selectedOption, but we happen to have an "other"
      // option available, we have to trust in the data that the currentValue we
      // have at the moment is for the "other", because the value won't match any
      // of the ACTUAL values for the options, so we render the "other" input.
      if (!selectedOption && hasOther) {
        setResolvedAnswer(hasOther.value)
        setShowingOtherOption(true)
        setCurrentSelectedOptionId(hasOther.id)
        return
      }

      // Down here means that the selected option is NOT an "other" option, so hide
      // the TextField and set the currentAnswer state to the value of the option
      // that was selected.
      setShowingOtherOption(false)
      setCurrentAnswer(value)
    },
    [
      question,
      setCurrentAnswer,
      setCurrentSelectedOptionId,
      setCurrentSelectedPathId,
      setResolvedAnswer,
      setShowingOtherOption,
    ],
  )

  // When the currentAnswer value is changing, we need to do some evaluation. Only
  // want to do it though when OUR Question is the current Question. It breaks
  // the flow if the currentSelectedOptionId changes while IN a path.
  useEffect(() => {
    if (
      !currentAnswer ||
      question.id !== currentQuestionId ||
      showingOtherOption
    ) {
      return
    }

    setValues(currentAnswer)
  }, [
    currentAnswer,
    currentQuestionId,
    question,
    setValues,
    showingOtherOption,
  ])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    setDirty && setDirty()

    setValues(value)
  }

  return (
    <>
      <StyledFormControl component="fieldset">
        <RadioGroup
          aria-label="question-options"
          name={`question-${question.id}-options`}
          value={resolvedAnswer}
          onChange={handleChange}
        >
          {options}
        </RadioGroup>
      </StyledFormControl>
      <OtherOptionTextField {...props} showing={showingOtherOption} />
    </>
  )
}

function OtherOptionTextField(
  props: FormQuestionProps & {
    showing: boolean
  },
) {
  const {question} = props
  const {currentAnswer, setCurrentAnswer} = useObieQuestions()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAnswer(event.target.value)
  }

  if (!props.showing) {
    return null
  }

  return (
    <StyledTextField
      fullWidth
      multiline
      rows={5}
      variant="filled"
      placeholder={question.example_text}
      defaultValue={currentAnswer}
      onChange={handleChange}
    />
  )
}

export const StyledFormControl = styled((props) => {
  return <FormControl {...props} />
})`
  color: white;
  & .MuiIconButton-label {
    color: white;
  }
`
