import TextField from 'lib/ui/TextField'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import IconPicker from 'lib/ui/form/IconPicker'
import {useAttendeeVariables} from 'Event'

type TextInputProps = {
  text: string
  icon?: string | null
}

type ImageInputProps = {
  image?: string
}

type ContentInputProps = {
  button: TextInputProps & ImageInputProps
  register: UseFormMethods['register']
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
  disableImageInput?: boolean
}

/**
 *
 * @param props ButtonProps & UseFormMethods(register, watch, control)
 * @returns component
 */
export default function ContentInput(props: ContentInputProps) {
  const {button, register, control, watch, disableImageInput} = props

  const v = useAttendeeVariables()

  const text = watch('text', v(button.text))
  const icon = watch('icon', button.icon)
  const image = watch('image', button.image)

  if (!disableImageInput && image) {
    return <ImageInput image={image} control={control} />
  }

  return (
    <>
      <TextInput
        text={text}
        icon={icon}
        register={register}
        control={control}
      />
      <ImageInput image={image} control={control} hidden={disableImageInput} />
    </>
  )
}

function TextInput(props: {
  text: TextInputProps['text']
  icon: TextInputProps['icon']
  register: UseFormMethods['register']
  control: UseFormMethods['control']
}) {
  const {text, icon, register, control} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="icon"
        defaultValue={icon || ''}
        control={control}
        render={({value, onChange}) => (
          <IconPicker value={value} onChange={onChange} />
        )}
      />
      <TextField
        label="Text"
        name="text"
        defaultValue={v(text)}
        inputProps={{
          ref: register,
          'aria-label': 'button name input',
        }}
        fullWidth
      />
    </>
  )
}

function ImageInput(props: {
  hidden?: boolean
  image: ImageInputProps['image']
  control: UseFormMethods['control']
}) {
  const {image, control, hidden} = props

  if (hidden) {
    return null
  }

  return (
    <Controller
      name="image"
      control={control}
      defaultValue={image || ''}
      render={({value, onChange}) => (
        <ImageAssetUploader
          onChange={onChange}
          value={value}
          alt="button image"
          uploadInputProps={{
            'aria-label': 'upload button image',
          }}
          removeButtonProps={{
            'aria-label': 'remove button image',
          }}
          canResize
          disableAutoRemove
        />
      )}
    />
  )
}
