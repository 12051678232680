import React from 'react'
import Settings from 'Event/template/Townhall/Dashboard/TopBar/TopBarConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/TopBar/TopBarConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Config from 'organization/Event/Configurable/Config'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export function TopBarConfig() {
  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const handleSave: TemplateSave<Template> = (data) => {
    const label = replaceAtPath(data, 'topBar.text', '{{topbar_text}}')

    save({
      ...data,
      localization: addTranslation({
        topbar_text: label ?? 'Townhall Demo',
      }),
    })
  }

  return (
    <Config title="Townhall TopBar" onSave={handleSave}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
      <StylingPanel>
        <Styling />
      </StylingPanel>
    </Config>
  )
}
