import React from 'react'
import {BlockComponentProps} from '../index'
import {Font} from '../../lib/font'
import {BlockBase} from '../base'
import {Column} from '../../lib/layout'
import {usePurchase} from '../../PurchaseContext'
import Container from '../Container'
import UpsellPurchaseForm from './UpsellPurchaseForm'
import PurchaseButton from '../PurchaseForm/PurchaseButton'
import PurchaseFormButton from '../PurchaseForm/PurchaseFormButton'

export interface UpsellPurchaseButtonBlock extends BlockBase {
  type: 'UpsellPurchaseButton'
  title?: {
    enabled: boolean
    text: string
    font: Font | null
    fontSize?: number
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  input: {
    textColor: string
    fontSize: number
    backgroundColor: string
    spacing: number
    border: {
      width: number
      radius: number
      color: string
    }
    padding: {
      left: number
      right: number
      top: number
      bottom: number
    }
    placeholder: {
      firstName: string
      lastName: string
      email: string
      phone: string
      billingAddress1: string
      billingAddress2: string
      city: string
      state: string
      zipcode: string
      country: string
      shippingAddress1: string
      shippingAddress2: string
      shippingcity: string
      shippingstate: string
      shippingzipcode: string
      shippingcountry: string
    }
  }
  purchaseButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
    margin?: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  buttonStyle: {
    size: Column
    font: Font | null
    fontSize?: number
    backgroundColor?: string
    hoverBackgroundColor?: string
    textColor?: string
    hoverTextColor?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
    hoverBorderColor?: string
    height?: number
    width?: number
    disabledBackgroundColor?: string
    disabledTextColor?: string
  }
  buttonsAlignment: 'flex-start' | 'center' | 'flex-end'
  requirePhoneNumber: boolean
  errors: {
    requiredField: string
  }
  questions: {
    sameShippingAddress: string
  }
  sectionLabel?: {
    font: Font | null
    fontSize?: number
    margin: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  summaryColumnLabel?: {
    font: Font | null
    fontSize?: number
    border: {
      width: number
      color: string
    }
  }
  customerDetailsLabel?: string
  billingSectionLabel?: string
  shippingSectionLabel?: string
  purchaseSummaryLabel?: string
  totalAmountLabel?: string
  itemLabel?: string
  cardFieldsLabel?: string
}

interface UpsellPurchaseButtonProps
  extends UpsellPurchaseButtonBlock,
    BlockComponentProps {
  TitleComponent?: React.FC<any>
  TextFieldComponent?: React.FC<any>
  ButtonComponent?: React.FC<any>
  CustomerDetailsSectionLabelComponent?: React.FC<any>
  BillingFieldsLabelComponent?: React.FC<any>
  ShippingFieldsLabelComponent?: React.FC<any>
  PurchaseSummaryLabelComponent?: React.FC<any>
  TotalAmountLabelComponent?: React.FC<any>
  ItemLabelComponent?: React.FC<any>
  CardFieldsLabelComponent?: React.FC<any>
  stripeAccountId?: string
}

export default function UpsellPurchaseButton(props: UpsellPurchaseButtonProps) {
  const {padding: _padding, ButtonComponent = PurchaseFormButton} = props

  const {hasSavedCard, isProcessing, selectedTicket, submit} = usePurchase()

  if (hasSavedCard) {
    return (
      <Container {...props}>
        <PurchaseButton
          {...props}
          ButtonComponent={ButtonComponent}
          disabled={isProcessing || !selectedTicket}
          onClick={() => {
            submit()
          }}
        />
      </Container>
    )
  }

  return <UpsellPurchaseForm {...props} />
}
