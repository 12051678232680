import React from 'react'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {BlockBase} from 'Event/Marketplace/Block/base'

export default function VisibilitySetting(props: {
  visibility: BlockBase['visibility']
  setVisibility: (visibility: BlockBase['visibility']) => void
}) {
  const {visibility, setVisibility} = props

  return (
    <Select
      fullWidth
      label="Select visibility"
      value={visibility ?? 'all'}
      onChange={onUnknownChangeHandler(setVisibility)}
      aria-label="select visibility"
      className="select-visibility"
    >
      <Option value="all">All</Option>
      <Option value="mobile_only">Mobile Only</Option>
      <Option value="desktop_only">Desktop Only</Option>
    </Select>
  )
}
