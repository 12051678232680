import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {useAttendeeVariables} from 'Event'
import {useCardsTemplate} from 'Event/template/Cards'
import {TextConfig} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/Text/TextConfig'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import Section, {
  SectionBox,
} from 'Event/template/Cards/Dashboard/Sidebar/Section'
import {HasOffset} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {useToggle} from 'lib/toggle'
import {Ordered} from 'lib/list'
import {RemoveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import Content from 'lib/ui/form/TextEditor/Content'
import {useEditMode} from 'Event/EditModeProvider'
import Heading from 'Event/template/Cards/Dashboard/Sidebar/Heading'
import {HasRules} from 'Event/attendee-rules'
import {HasSchedule} from 'lib/ui/layout/Scheduled'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useRemoveTranslations} from 'Event/LanguageProvider'
import {REMOVE} from 'lib/JsonUpdateProvider'

export const TEXT = 'Text'
export interface TextProps extends Ordered, HasOffset, HasRules, HasSchedule {
  type: typeof TEXT
  padding: number
  body: string
  isEnabled: boolean
  title: string
}

export const createText = (): TextProps => ({
  type: TEXT,
  padding: 5,
  body: 'This is sample text.  Click Edit Text to modify this text.',
  isEnabled: true,
  title: '',
  offsetTop: 0,
  offsetBottom: 0,
})

export default function Text(props: TextProps & {id: string}) {
  const {sidebar} = useCardsTemplate()
  const {title, body, padding, isEnabled, offsetTop, offsetBottom} = props
  const v = useAttendeeVariables()
  const {flag: configVisible, toggle: toggleConfig} = useToggle()
  const isEditMode = useEditMode()

  if (!isEditMode && !isEnabled) {
    return null
  }

  return (
    // Disable side padding to allow text to go all all the
    // way to border.
    <Section offsetTop={offsetTop} offsetBottom={offsetBottom} disablePaddingX>
      <Title titleText={title} />
      <Body aria-label="text" textColor={sidebar.textColor} padding={padding}>
        {v(body)}
      </Body>
      <EditModeOnly>
        <SectionBox disablePaddingY>
          <TextConfig
            showing={configVisible}
            onClose={toggleConfig}
            text={props}
            id={props.id}
          />
          <EditButton
            onClick={toggleConfig}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            aria-label="edit text"
          >
            Edit Text
          </EditButton>
          <RemoveTextButton id={props.id} />
        </SectionBox>
      </EditModeOnly>
    </Section>
  )
}

function RemoveTextButton(props: {id: string}) {
  const saveTemplate = useSaveTemplate()
  const removeTranslation = useRemoveTranslations()

  const remove = () => {
    saveTemplate({
      sidebarItems: {
        [props.id]: REMOVE,
      },
    })
    removeTranslation([`${props.id}_title`, `${props.id}_body`])
  }

  return (
    <RemoveButton size="large" showing onClick={remove}>
      Remove Text
    </RemoveButton>
  )
}

function Title(props: {titleText: string}) {
  const v = useAttendeeVariables()

  if (!props.titleText) {
    return null
  }
  return <StyledHeading>{v(props.titleText)}</StyledHeading>
}

const Body = styled(Content)<{
  textColor: string | null
  padding: number
}>`
  color: ${(props) => props.textColor};
  padding: ${(props) => props.padding}px;
`

const EditButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing[2]}!important;
`

// Default Heading has negative margins, so we'll override
// those here.
const StyledHeading = styled(Heading)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`
