import React, {useCallback} from 'react'
import JsonUpdateProvider, {JsonSave} from 'lib/JsonUpdateProvider'
import {Client} from 'lib/ui/api-client'
import {useQueryClient} from 'react-query'
import {useEvent} from 'Event/EventProvider'
import {
  ThankYouPage,
  ThankYouPageTemplate,
} from 'Event/Marketplace/thank-you-page'
import {useUpdateThankYouPageTemplate} from 'lib/marketplace-api/thank-you-page/use-update-thank-you-page-template'

const TemplateUpdateContext = React.createContext<
  JsonSave<ThankYouPage['template']> | undefined
>(undefined)

interface ThankYouPageTemplateUpdateProviderProps {
  children: (template: ThankYouPageTemplate) => JSX.Element
  thankYouPage: ThankYouPage
  client: Client
}

export default function ThankYouPageTemplateUpdateProvider(
  props: ThankYouPageTemplateUpdateProviderProps,
) {
  const {children, thankYouPage, client} = props

  const updateTemplate = useUpdateThankYouPageTemplate({
    client,
  })
  const {event} = useEvent()

  const qc = useQueryClient()

  const updateCachedTemplate = useCallback(
    (template: ThankYouPageTemplate) => {
      qc.setQueryData<ThankYouPage | undefined>(
        ['events', event.id, 'thank_you_page'],
        (thankYouPage) => {
          if (!thankYouPage) {
            return
          }

          return {
            ...thankYouPage,
            template,
          }
        },
      )
    },
    [qc, event],
  )

  const template = Array.isArray(thankYouPage.template.sections)
    ? {
        ...thankYouPage.template,
        sections: {},
      }
    : thankYouPage.template

  return (
    <JsonUpdateProvider
      Context={TemplateUpdateContext}
      updatedSocketEventName={`.thank_you_page.${thankYouPage.id}.updated`}
      value={template}
      save={(updates) =>
        updateTemplate.mutateAsync({thankYouPage: thankYouPage, updates})
      }
      onUpdate={updateCachedTemplate}
    >
      {(template) => children(template)}
    </JsonUpdateProvider>
  )
}

export function useUpdateTemplate() {
  const context = React.useContext(TemplateUpdateContext)
  if (context === undefined) {
    throw new Error(
      'useUpdateTemplate must be used within ThankYouPageTemplateUpdateProvider.',
    )
  }

  return context
}
