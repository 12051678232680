import {useEvent} from 'Event/EventProvider'
import {Certificate} from 'lib/event-api/certificates/types'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'

interface UseUpdateCertificateParams {
  certificate: Certificate
}

type UseUpdateCertificateData = {
  source_pdf?: File | undefined
  template?: Record<string, any>
}

export function useUpdateCertificate(params: UseUpdateCertificateParams) {
  const {certificate} = params
  const {client} = useOrganization()
  const {event} = useEvent()

  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: UseUpdateCertificateData) => {
      const url = api(`/certificates/${certificate.id}`)

      const payload = getPayload(data)
      return client.patch<Certificate>(url, payload)
    },
    onSuccess: (updated) => {
      qc.setQueryData<Certificate[]>(
        ['events', event.id, 'certificates'],
        (certificates) => {
          if (!certificates) {
            return []
          }

          return certificates.map((certificate) => {
            if (certificate.id === updated.id) {
              return updated
            }

            return certificate
          })
        },
      )
    },
  })
}

function getPayload(
  data: UseUpdateCertificateData,
): FormData | Record<string, any> {
  if (!data.source_pdf) {
    return data
  }

  const formData = new FormData()

  formData.set('source_pdf', data.source_pdf)

  if (data.template) {
    formData.set('template', JSON.stringify(data.template))
  }

  return formData
}
