import {
  IS,
  IS_NOT,
  CONTAIN,
  DOES_NOT_CONTAIN,
  FormResponseRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import {OTHER as OTHER_OPTION} from 'Event/Question/Radio'
import {Answer} from 'Event/SubmissionsProvider'
import {Form} from 'organization/Event/FormsProvider'

type MatchesFormResponseRuleParams = {
  answers: Answer[]
  forms: Form[]
  rule: FormResponseRule
}

export function matchesFormResponseRule(
  params: MatchesFormResponseRuleParams,
): boolean {
  const {answers, forms, rule} = params

  const answer = answers.find((answer) => {
    if (!answer) {
      return false
    }
    return answer.question_id === rule.question_id
  })

  if (rule.value === OTHER_OPTION) {
    return matchesOtherOption({forms, rule, answer})
  }

  if (answer && rule.type === CONTAIN) {
    return answer && answer?.value.includes(rule.value)
  }

  if (rule.type === DOES_NOT_CONTAIN) {
    return !answer?.value.includes(rule.value)
  }

  if (rule.type === IS_NOT) {
    return answer?.value !== rule.value
  }

  return answer?.value === rule.value
}

function matchesOtherOption(
  params: Pick<MatchesFormResponseRuleParams, 'forms' | 'rule'> & {
    answer?: Answer
  },
) {
  const {forms, rule, answer} = params

  const form = forms.find((form) => form.id === rule.form_id)
  if (!form) {
    return false
  }

  const question = form.questions.find((q) => q.id === rule.question_id)
  if (!question) {
    return false
  }

  if (!('options' in question)) {
    return false
  }

  const selectedAvailableOption = question.options.find(
    (option) => option.value === answer?.value,
  )

  // If the user DID select an available option, then this rule
  // would only match if the type was IS NOT OTHER.
  if (selectedAvailableOption) {
    return rule.type === IS_NOT
  }

  // User selected some 'OTHER' option, so the only type
  // that makes sense is IS OTHER.
  return rule.type === IS
}
