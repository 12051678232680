import React from 'react'
import CKEditorContent from '../../lib/ui/CKEditorContent'
import {getFontStyles} from '../../lib/font'
import {rgba} from '../../lib/color'
import {FaqItem, FaqListBlock} from './index'
import {useToggleArray} from '../../lib/use-toggle'
import styles from './FaqList.module.css'
import {useSection} from '../../Section'

interface ListItemProps extends FaqListBlock {
  item: FaqItem
  isLast: boolean
}

export default function ListItem(props: ListItemProps) {
  const {item, icon, isLast} = props

  const [expanded, toggleExpand] = useToggleArray(item.showAnswerOnLoad)
  const {calculateVisibility} = useSection()

  const backgroundColor = rgba(
    item.background.color || '#FFFFFF',
    item.background.opacity / 100,
  )

  const handleToggleExpand = () => {
    toggleExpand()
    calculateVisibility()
  }

  return (
    <div
      style={{
        padding: 8,
        marginTop: props.faqSpace + 'px',
        backgroundImage: item.background.image
          ? `url(${item.background.image})`
          : undefined,
        backgroundColor: backgroundColor,
        boxShadow: `inset 0 0 0 100cqh ${backgroundColor}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        border: `${item.border.color} ${item.border.width}px solid`,
        borderRadius: item.border.radius + 'px',
        width: '100%',
        height: '100%',
        marginBottom: isLast ? props.faqSpace + 'px' : 'inherit',
      }}
      aria-label="faq list"
      onClick={handleToggleExpand}
    >
      <div className={styles.card}>
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            fontSize: props.fontSize + 'px',
            color: props.color,
            alignItems: 'center',
            ...getFontStyles(props.font),
          }}
        >
          <i
            className={icon ?? ''}
            style={{
              fontSize: props.iconSize + 'px',
              width: props.iconSize + 'px',
              height: props.iconSize + 'px',
              marginRight: props.textSpace + 'px',
              marginLeft: '6px',
              color: props.iconColor,
              transform: expanded
                ? 'rotate(-180deg) translateX(0.25em)'
                : 'unset',
              transition: '0.3s ease',
            }}
          />

          <CKEditorContent className={styles.cKEditorContent}>
            {item.question}
          </CKEditorContent>
        </div>

        <Answer expanded={expanded} answer={item.answer} />
      </div>
    </div>
  )
}

const Answer = (props: {expanded: boolean; answer: string}) => {
  const {expanded, answer} = props

  if (!expanded) {
    return null
  }

  return (
    <div className={styles.cardContent}>
      <CKEditorContent>{answer}</CKEditorContent>
    </div>
  )
}
