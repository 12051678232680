import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {AgendaListProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList'
import {
  useAutoUpdateSidebarItem,
  useEditSidebarItem,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import Styling from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'

export function AgendaListConfig(
  props: ComponentConfigProps & {
    list: AgendaListProps
  },
) {
  const {list} = props
  const {showing, onClose} = props

  const [rules, setRules] = useState(list.rules)

  const {update: updateItem, id: listId} = useEditSidebarItem()

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState,
  } = useForm()

  const save = (data: AgendaListProps) => {
    const {title, description, footer} = data

    const localization = {
      [`sidebar_agenda_list_${listId}_title`]: title || '',
      [`sidebar_agenda_list_${listId}_description`]: description || '',
      [`sidebar_agenda_list_${listId}_footer`]: footer || '',
    }

    const updated = {
      ...data,
      title: `{{sidebar_agenda_list_${listId}_title}}`,
      description: `{{sidebar_agenda_list_${listId}_description}}`,
      footer: `{{sidebar_agenda_list_${listId}_footer}}`,
    }
    updateItem({...updated, rules}, localization)
    onClose()
  }

  useAutoUpdateSidebarItem({item: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agendas"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} list={list} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} list={list} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={list} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
