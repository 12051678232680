import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import {StyleFields} from 'Event/template/Cards/Login/Title/TitleConfig'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'

export default function TitleConfig() {
  const saveTemplate = useCardsUpdate()
  const {login} = useCardsTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const {
    form: {register},
  } = useConfig()

  const save = (data: any) => {
    const text = replaceAtPath(
      data,
      'login.passwordReset.forgotPasswordTitle',
      '{{forgot_password_title}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        forgot_password_title: text ?? '',
      }),
    })
  }

  return (
    <Config title="Title" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="login.passwordReset.forgotPasswordTitle"
          defaultValue={v(login.passwordReset.forgotPasswordTitle)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}
