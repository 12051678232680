import ConfigurablePurchaseSummaryColumnLabel, {
  ConfigurablePurchaseSummaryColumnLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurablePurchaseSummaryColumnLabel'
import React from 'react'

export default function ConfigurableItemLabel(
  props: ConfigurablePurchaseSummaryColumnLabelProps,
) {
  const {itemLabel} = props

  return (
    <ConfigurablePurchaseSummaryColumnLabel
      {...props}
      children={itemLabel ?? 'Item'}
      inputName="itemLabel"
    />
  )
}
