import {useEditMode} from 'Event/EditModeProvider'
import ReactDOM from 'react-dom'
import EditIconButton from 'lib/ui/IconButton/EditIconButton'
import CopyIconButton from 'lib/ui/IconButton/DuplicateIconButton'
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import MoveIconButton from 'lib/ui/IconButton/MoveIconButton'
import AddIconButton from 'lib/ui/IconButton/AddIconButton'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'
import {DragHandle} from 'lib/ui/drag-and-drop'

export const EDIT_COMPONENT_CLASS = 'edit-component'
export const EDIT_COMPONENT_BUTTON_CLASS = 'edit-component-button'

export const DUPLICATE_COMPONENT_CLASS = 'duplicate-component'
export const DUPLICATE_COMPONENT_BUTTON_CLASS = 'duplicate-component-button'

export const MOVE_UP_COMPONENT_BUTTON_CLASS = 'move-up-component-button'
export const MOVE_DOWN_COMPONENT_BUTTON_CLASS = 'move-down-component-button'

export const ADD_ITEM_BUTTON_CLASS = 'add-item-button'

export type EditableProps = {
  children: React.ReactElement
  onEdit: () => void
  onCopy?: () => void
  moveUp?: () => void
  moveDown?: () => void
  addItem?: () => void
  className?: string
  dataTestId?: string
  ['aria-label']?: string
  copyRightSpacing?: number
  alwaysShowButtons?: boolean
  additionalButtons?: JSX.Element
  handleProps?: DraggableProvidedDragHandleProps
}

/**
 * A more generic version of EditComponent where handling the edit/config
 * dialog rendering is handled by the parent.
 *
 * @param props
 * @returns
 */
export function Editable(props: EditableProps) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return props.children
  }

  return (
    <EditComponentOverlay
      onClick={props.onEdit}
      onCopy={props.onCopy}
      onMoveDown={props.moveDown}
      onMoveUp={props.moveUp}
      onAddItem={props.addItem}
      className={props.className}
      aria-label={props['aria-label']}
      dataTestId={props.dataTestId}
      copyRightSpacing={props.copyRightSpacing}
      alwaysShowButtons={props.alwaysShowButtons}
      additionalButtons={props.additionalButtons}
      handleProps={props.handleProps}
    >
      {props.children}
    </EditComponentOverlay>
  )
}

export function EditComponentOverlay(props: {
  onClick: () => void
  onCopy?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onAddItem?: () => void
  children: React.ReactElement
  disableChildInteraction?: boolean
  className?: string
  ['aria-label']?: string
  dataTestId?: string
  copyRightSpacing?: number
  alwaysShowButtons?: boolean
  additionalButtons?: JSX.Element
  handleProps?: DraggableProvidedDragHandleProps
  isDragging?: boolean
  resizable?: boolean
}) {
  const {isDragging, alwaysShowButtons, resizable} = props

  const className = `${EDIT_COMPONENT_CLASS} ${props.className || ''}`
  const label = props['aria-label'] ?? 'edit component'
  const [overlayEl, setOverlayEl] = useState<HTMLDivElement | null>(null)
  const [boxEl, setBoxEl] = useState<HTMLDivElement | null>(null)
  const [hover, setHover] = useState(false)
  const [positionCalculated, setPositionCalculated] = useState(!resizable)

  const visible = hover && !isDragging

  const calculatePosition = useCallback(() => {
    if (!resizable) {
      return
    }
    if (!overlayEl || !boxEl) {
      return
    }

    const windowHeight = window.innerHeight
    const overlayDimensions = overlayEl.getBoundingClientRect()
    const boxDimensions = boxEl.getBoundingClientRect()

    if (!overlayDimensions.top || !boxDimensions.top) {
      return
    }

    const top =
      overlayDimensions.height < 40
        ? overlayDimensions.top - 34
        : overlayDimensions.top

    // Max TOP before element go out of the screen
    const maxTop = windowHeight - boxDimensions.height

    // If element is going out of the screen. Move it out of the screen, but in right.
    if (top > maxTop) {
      boxEl.style.right = `-${boxDimensions.width}px`
      boxEl.style.top = `${windowHeight - boxDimensions.height}px`
      boxEl.style.left = `auto` // overwrite last left
      return
    }

    // If element is in screen show it as normal
    boxEl.style.right = `auto` // overwrite last right
    boxEl.style.top = `${top}px`
    boxEl.style.left = `${
      overlayDimensions.left +
      overlayDimensions.width -
      boxDimensions.width -
      10
    }px`
  }, [overlayEl, boxEl, resizable])

  useEffect(() => {
    if (alwaysShowButtons) {
      calculatePosition()
      setPositionCalculated(true)
      return
    }

    setPositionCalculated(false)
    const timer = setTimeout(() => {
      calculatePosition()
      setPositionCalculated(true)
    }, 150)

    return () => {
      clearTimeout(timer)
    }
  }, [calculatePosition, visible, alwaysShowButtons])

  useEffect(() => {
    document.addEventListener('scroll', calculatePosition, true)

    return () => {
      document.removeEventListener('scroll', calculatePosition, true)
    }
  }, [calculatePosition])

  const showing = visible && (!resizable || positionCalculated)

  const buttons = (
    <ButtonsHolder ref={setBoxEl} resizable={resizable}>
      <StyledAddIconButton
        onClick={props.onAddItem}
        className={ADD_ITEM_BUTTON_CLASS}
        showing={Boolean(props.onAddItem)}
        type="button"
        aria-label="add item"
        rightSpacing={props.copyRightSpacing}
        alwaysShowButtons={props.alwaysShowButtons}
        visible={showing}
      />
      <StyledCopyIconButton
        onClick={props.onCopy}
        className={DUPLICATE_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onCopy)}
        type="button"
        aria-label="duplicate component"
        rightSpacing={props.copyRightSpacing}
        alwaysShowButtons={props.alwaysShowButtons}
        visible={showing}
      />
      <StyledMoveIconButton
        onClick={props.onMoveUp}
        className={MOVE_UP_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onMoveUp)}
        type="button"
        aria-label="move component up"
        direction="up"
        rightSpacing={10}
        alwaysShowButtons={props.alwaysShowButtons}
        visible={showing}
      />
      <StyledMoveIconButton
        onClick={props.onMoveDown}
        className={MOVE_DOWN_COMPONENT_BUTTON_CLASS}
        showing={Boolean(props.onMoveDown)}
        type="button"
        aria-label="move component down"
        direction="down"
        rightSpacing={Boolean(props.onMoveUp) ? 18 : 10}
        alwaysShowButtons={props.alwaysShowButtons}
        visible={showing}
      />
      <AdditionalButtonsBox visible={showing}>
        {props.additionalButtons}
      </AdditionalButtonsBox>
      {props.handleProps && (
        <DragHandle handleProps={props.handleProps} visible={showing} />
      )}
      <StyledEditIconButton
        onClick={props.onClick}
        className={EDIT_COMPONENT_BUTTON_CLASS}
        type="button"
        aria-label={label}
        alwaysShowButtons={props.alwaysShowButtons}
        visible={showing}
      />
    </ButtonsHolder>
  )

  return (
    <EditComponentBox
      className={className}
      data-testid={props.dataTestId}
      ref={setOverlayEl}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <InteractionOverlay disable={props.disableChildInteraction} />
      <ButtonsOverlay alwaysShowButtons={props.alwaysShowButtons} />
      {resizable ? ReactDOM.createPortal(buttons, document.body) : buttons}
      {props.children}
    </EditComponentBox>
  )
}

const ButtonsOverlay = styled.div<{alwaysShowButtons?: boolean}>`
  display: ${(props) => (props.alwaysShowButtons ? 'block' : 'none')};
  position: absolute;
  height: 34px;
  width: 100%;
  background-image: repeating-linear-gradient(
    145deg,
    #c3ddf3 10px,
    #c3ddf3 10px,
    transparent 12px,
    transparent 20px
  );
  border-bottom: 1px solid #c3ddf3;
  background-color: white;
  z-index: 2;
`

const InteractionOverlay = styled.div<{disable?: boolean}>`
  display: ${(props) => (props.disable ? 'block' : 'none')};
  position: ${(props) => (props.disable ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const StyledAddIconButton = styled(AddIconButton)<{
  rightSpacing?: number
  alwaysShowButtons?: boolean
  visible?: boolean
}>`
  z-index: 4;
  display: inline-flex;
  opacity: ${(props) => (props.alwaysShowButtons || props.visible ? 1 : 0)};

  &:hover {
    opacity: 0.8;
  }
`

const StyledEditIconButton = styled(EditIconButton)<{
  alwaysShowButtons?: boolean
  visible?: boolean
}>`
  z-index: 4;
  display: inline-flex;
  opacity: ${(props) => (props.alwaysShowButtons || props.visible ? 1 : 0)};

  &:hover {
    opacity: 0.8;
  }
`

const StyledCopyIconButton = styled(CopyIconButton)<{
  rightSpacing?: number
  alwaysShowButtons?: boolean
  visible?: boolean
}>`
  z-index: 4;
  display: inline-flex;
  opacity: ${(props) => (props.alwaysShowButtons || props.visible ? 1 : 0)};

  &:hover {
    opacity: 0.8;
  }
`

const StyledMoveIconButton = styled(MoveIconButton)<{
  rightSpacing: number
  alwaysShowButtons?: boolean
  visible?: boolean
}>`
  z-index: 4;
  display: inline-flex;
  opacity: ${(props) => (props.alwaysShowButtons || props.visible ? 1 : 0)};

  &:hover {
    opacity: 0.8;
  }
`

const ButtonsHolder = styled.div<{resizable?: boolean}>`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  ${(props) => (!props.resizable ? 'width: 100%;' : '')}
  gap: 10px;
  height: 34px;
  align-items: center;
  z-index: 10;
`

const AdditionalButtonsBox = styled.div<{visible?: boolean}>`
  display: inline-flex;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  gap: 10px;
  height: 24px;
`

export const EditComponentBox = styled.div`
  position: relative;
  width: 100%;
`
