import {hasFeatureToggle} from 'lib/FeatureToggle'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {LEAP} from 'obvio/Billing/plans'
import {useObvioUser} from 'obvio/auth'
import {useArea} from 'organization/Event/Area/AreaProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useIsOwner, useOwner} from 'organization/OwnerProvider'
import {useOwnerHasPlan} from 'organization/auth/IfOwnerPlan'
import React, {useCallback} from 'react'

/**
 * Pre-defined system permissions. We expect the API to return one of these
 * permissions. If a permission has been added/updated in the API, we'll
 * need to update it here as well.
 */
export const CREATE_EVENTS = 'events.create'
export const EVENT_SETTINGS = 'event.settings'
export const EVENT_DESIGN = 'event.design'
export const EMOJI_PAGE = 'emoji.page'
export const UPDATE_TEAM = 'team.update'
export const START_ROOMS = 'rooms.start'
export const CHECK_IN_ATTENDEES = 'attendees.check_in'
export const UPDATE_ATTENDEES = 'attendees.update'
export const VIEW_RECORDINGS = 'recordings.view'
export const START_AREAS = 'areas.start'
export const ZOOM_ATTENDANCE = 'zoom.attendance'
export const MANAGE_MARKETPLACE = 'marketplace.manage'
export const SCHEDULE_BROADCASTS = 'broadcasts.schedule'
export const VIEW_STATISTICS = 'statistics.view'
export const PURCHASE_ADD_ONS = 'purchase.add_ons'
export const OBIE_ACCESS = 'obie.access'

export type Permission =
  | typeof CREATE_EVENTS
  | typeof EVENT_SETTINGS
  | typeof EVENT_DESIGN
  | typeof EMOJI_PAGE
  | typeof UPDATE_TEAM
  | typeof START_AREAS
  | typeof ZOOM_ATTENDANCE
  | typeof START_ROOMS
  | typeof CHECK_IN_ATTENDEES
  | typeof UPDATE_ATTENDEES
  | typeof VIEW_RECORDINGS
  | typeof MANAGE_MARKETPLACE
  | typeof SCHEDULE_BROADCASTS
  | typeof VIEW_STATISTICS
  | typeof PURCHASE_ADD_ONS
  | typeof OBIE_ACCESS

export const SORT_ORDER: Permission[] = [
  CREATE_EVENTS,
  EVENT_SETTINGS,
  VIEW_STATISTICS,
  EVENT_DESIGN,
  EMOJI_PAGE,
  START_AREAS,
  START_ROOMS,
  ZOOM_ATTENDANCE,
  VIEW_RECORDINGS,
  UPDATE_ATTENDEES,
  CHECK_IN_ATTENDEES,
  MANAGE_MARKETPLACE,
  SCHEDULE_BROADCASTS,
  UPDATE_TEAM,
  PURCHASE_ADD_ONS,
  OBIE_ACCESS,
]

/**
 * Permissions that only an owner may update.
 */
export const OWNER_ONLY_PERMISSIONS = [PURCHASE_ADD_ONS]

interface PermissionsContextProps {
  user: Permission[]
  all: Permission[]
  can: (permission: Permission) => boolean
}

export const PermissionsContext = React.createContext<
  PermissionsContextProps | undefined
>(undefined)

export default function PermissionsProvider(props: {
  children: React.ReactNode
}) {
  const {owner} = useOwner()
  const ownerHasObieFeature = hasFeatureToggle(owner, 'obie')
  const ownerHasLeapPlan = useOwnerHasPlan(LEAP)

  const fetchUserPermissions = useFetchUserPermissions()
  const {data: user, loading: loadingUser} = useAsync(fetchUserPermissions)

  const fetchAll = useFetchAllPermissions()
  const {data: all, loading: loadingAll} = useAsync(fetchAll)

  const loading = loadingUser || loadingAll

  if (loading || !user || !all) {
    return <FullPageLoader />
  }

  const can = (permission: Permission) => user.includes(permission)

  const sorted = all.sort((a, b) => {
    const aIndex = SORT_ORDER.indexOf(a)
    const bIndex = SORT_ORDER.indexOf(b)

    // Sort in order defined in SORT_ORDER. Since we're using the index, we'll just
    // sort the index number in ascending order.
    return aIndex - bIndex
  })

  const filtered = sorted.filter((permission) => {
    if (permission === OBIE_ACCESS) {
      return ownerHasObieFeature || ownerHasLeapPlan
    }

    return true
  })

  return (
    <PermissionsContext.Provider value={{user, all: filtered, can}}>
      {props.children}
    </PermissionsContext.Provider>
  )
}

function useFetchUserPermissions() {
  const {
    organization: {id},
    client,
  } = useOrganization()

  return useCallback(() => {
    const url = api(`/organizations/${id}/permissions`)
    return client.get<Permission[]>(url)
  }, [id, client])
}

function useFetchAllPermissions() {
  const {client} = useOrganization()

  return useCallback(() => {
    const url = api(`/permissions`)
    return client.get<Permission[]>(url)
  }, [client])
}

export function usePermissions() {
  const context = React.useContext(PermissionsContext)
  if (context === undefined) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`)
  }

  return context
}

export function label(permission: Permission) {
  switch (permission) {
    case CREATE_EVENTS:
      return 'Create Events'
    case EVENT_SETTINGS:
      return 'Event Settings'
    case EVENT_DESIGN:
      return 'Event Design'
    case EMOJI_PAGE:
      return 'Emoji Page'
    case UPDATE_TEAM:
      return 'Update Team'
    case START_ROOMS:
      return 'Start Rooms'
    case CHECK_IN_ATTENDEES:
      return 'Check-In Attendees'
    case UPDATE_ATTENDEES:
      return 'Update Attendees'
    case VIEW_RECORDINGS:
      return 'View Recordings'
    case START_AREAS:
      return 'Start Areas'
    case ZOOM_ATTENDANCE:
      return 'Zoom Attendance'
    case MANAGE_MARKETPLACE:
      return 'Marketplace'
    case SCHEDULE_BROADCASTS:
      return 'Broadcasts'
    case VIEW_STATISTICS:
      return 'Event Statistics'
    case PURCHASE_ADD_ONS:
      return 'Purchase Add-ons'
    case OBIE_ACCESS:
      return 'Obie Access'
    default:
      throw new Error(
        `Unhandled permission: ${permission}; was it added to the Permission type?`,
      )
  }
}

/**
 * Checks whether a user can start a room. This hook
 * takes into account the current area, and allows
 * tech check agents to start rooms.
 *
 * @returns
 */
export function useCanStartRooms() {
  const {can} = usePermissions()
  const {area} = useArea()

  if (can(START_ROOMS)) {
    return true
  }

  /**
   * If is tech check agent, lets allow them
   * to start tech-check rooms.
   */
  if (!can(CHECK_IN_ATTENDEES)) {
    return false
  }

  return area.is_tech_check
}

export function IfCanStartRooms(props: {
  children: JSX.Element | JSX.Element[]
}) {
  const canStartRooms = useCanStartRooms()
  if (!canStartRooms) {
    return null
  }

  return <>{props.children}</>
}

export function useCanUpdatePermission(permission: Permission) {
  const isOwner = useIsOwner()
  const user = useObvioUser()

  if (isOwner || user.is_admin) {
    return true
  }

  return !OWNER_ONLY_PERMISSIONS.includes(permission)
}
