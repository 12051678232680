import React from 'react'
import Page from 'organization/Event/TemplateEditor/Page'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

export default function ThankYouPageConfigLoader() {
  return (
    <Page
      toolbarItems={
        <>
          <Skeleton variant="rect" width={86} height={32} />
        </>
      }
    >
      <Box paddingX={2} paddingTop={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={64} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" width="100%" height={250} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" width="100%" height={400} />
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}
