import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {RedirectLink} from 'Event/Redirects/CustomRedirect'
import RedirectUpdateForm from 'organization/Event/Redirects/UpdateDialog/RedirectUpdateForm'
import Dialog from 'lib/ui/Dialog'

export default function RedirectsUpdateDialog(props: {
  onClose: () => void
  onUpdate: (redirect: RedirectLink) => void
  redirect: RedirectLink | null
}) {
  if (!props.redirect) {
    return null
  }

  return (
    <Dialog open onClose={props.onClose}>
      <DialogTitle>Update Redirect</DialogTitle>
      <DialogContent>
        <RedirectUpdateForm {...props} />
      </DialogContent>
    </Dialog>
  )
}
