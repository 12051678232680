import {api} from 'lib/url'
import {
  FormSubmission,
  FormSubmissionStatus,
} from 'organization/Event/Form/FormModeration/Question'
import {Form} from 'organization/Event/FormsProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useMutation, useQueryClient} from 'react-query'
import {client} from 'lib/ui/api-client'

interface UseSetFormSubmissions {
  form: Form
}

interface UseSetSubmissionsData {
  status: FormSubmissionStatus
  submissions: FormSubmission[]
}

export function useSetSubmissions(params: UseSetFormSubmissions) {
  const {form} = params
  const {client} = useOrganization()
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: UseSetSubmissionsData) => {
      if (data.submissions.length === 0) {
        return Promise.resolve()
      }
      const url = api(`/forms/${form.id}/submissions`)
      return client.patch(url, {submissions: data.submissions})
    },
    onMutate: (data) => {
      const {status, submissions} = data

      qc.setQueryData<FormSubmission[]>(
        ['forms', form.id, 'submissions', {status}],
        () => submissions,
      )
    },
    onError: (_error, data) => {
      const {status} = data
      // Re-fetch to get latest fields on error
      qc.invalidateQueries({
        queryKey: ['forms', form.id, 'submissions', {status}],
        exact: true,
      })
    },
  })
}

export function useSetSubmissionsByToken(params: UseSetFormSubmissions) {
  const {form} = params
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (data: UseSetSubmissionsData) => {
      if (data.submissions.length === 0) {
        return Promise.resolve()
      }
      const url = api(`/forms/${form.id}/submissions`)
      return client.patch(url, {
        token: form.access_token,
        submissions: data.submissions,
      })
    },
    onMutate: (data) => {
      const {status, submissions} = data

      qc.setQueryData<FormSubmission[]>(
        ['forms', form.id, 'submissions', {status}],
        () => submissions,
      )
    },
    onError: (_error, data) => {
      const {status} = data
      // Re-fetch to get latest fields on error
      qc.invalidateQueries({
        queryKey: ['forms', form.id, 'submissions', {status}],
        exact: true,
      })
    },
  })
}
