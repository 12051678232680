import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'
import {useToggleArray} from 'lib/toggle'
import IconButton from 'lib/ui/IconButton'
import {Icon} from 'lib/fontawesome/Icon'
import {grey} from '@material-ui/core/colors'
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

export interface DialogProps extends MuiDialogProps {
  children: React.ReactElement | React.ReactElement[]
  open: boolean
  expandable?: boolean
  onClose?: () => void
  className?: string
  icons?: JSX.Element | JSX.Element[]
}

export default function Dialog(props: DialogProps) {
  const [expanded, resize] = useToggleArray()

  const fullWidth = props.fullWidth || expanded

  const maxWidth = fullWidth ? false : props.maxWidth || 'sm' // Mui defaults to sm

  return (
    <MuiDialog
      disableBackdropClick
      onClick={(e) => {
        e.stopPropagation()
        props.onClose && props.onClose()
      }}
      open={props.open}
      onClose={props.onClose}
      disableEnforceFocus={props.disableEnforceFocus}
      className={props.className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        'aria-label': 'dialog paper',
        style: {
          width: '100%',
        },
        onClick: (e) => e.stopPropagation(),
        ...props.PaperProps,
      }}
    >
      <ButtonContainer>
        {props.icons}
        <ResizeButton
          onClick={resize}
          showing={props.expandable === undefined ? true : props.expandable}
          aria-label="resize modal"
        >
          <ExpandToggleIcon expanded={expanded} />
        </ResizeButton>
        <CloseButton
          onClick={props.onClose}
          aria-label="close dialog"
          hidden={!props.onClose}
        >
          <CloseIcon fontSize="small" />
        </CloseButton>
      </ButtonContainer>
      <>{props.children}</>
    </MuiDialog>
  )
}

export const DialogBody = styled(DialogContent)`
  overflow-x: hidden;
`

const ExpandToggleIcon = (props: {expanded: boolean}) => (
  <ExpandIcon
    iconClass={`fa-thin ${
      props.expanded
        ? 'fa-arrow-down-left-and-arrow-up-right-to-center'
        : 'fa-arrow-up-right-and-arrow-down-left-from-center'
    }`}
  />
)

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${(props) => props.theme.spacing[2]};
  right: ${(props) => props.theme.spacing[2]};
`

const CloseButton = styled(IconButton)<{
  hidden?: boolean
}>`
  ${(props) => (props.hidden ? 'display: none;' : '')}

  svg {
    color: ${grey[500]};
  }
`

const ExpandIcon = styled(Icon)`
  font-size: 12px;
`

const ResizeButton = styled(IconButton)<{
  showing?: boolean
}>`
  width: 20px;
  height: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing[2]};

  svg {
    color: ${grey[500]};
  }

  // Hide on mobile as it's already full width.
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: ${(props) => (props.showing ? 'flex' : 'none')};
  }
`
