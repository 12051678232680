import Command from '@ckeditor/ckeditor5-core/src/command';
import { THEME_AUTO } from './customthemeui';

interface ThemeConfig {
    mode?: string;
    callback?: (value: string) => void;
}

interface ExecuteOptions {
    value: string;
    callback?: (value: string) => void;
}

export default class CustomThemeCommand extends Command {

    constructor(editor: any) {
        super(editor);

        const themeConfig: ThemeConfig = editor.config.get('theme') || {};
        this.isEnabled = true;
        this.value = themeConfig.mode || THEME_AUTO;
    }

    override refresh(): void {
        // Implement refresh logic if needed
    }

    override execute({ value, callback }: ExecuteOptions): void {
        this.value = value;
        if (typeof callback === 'function') {
            callback(value);
        }
    }
}
