import {colors} from 'lib/ui/theme'
import {CheckSquare} from 'lib/ui/Icon'
import {INTERVAL_YEAR, PlanInfo} from 'obvio/Billing/plans'
import React from 'react'
import styled from 'styled-components'
import Switch from 'lib/ui/form/Switch'
import Grid from 'lib/ui/Grid'
import {formatPrice} from 'lib/currency'

const generalFeatures: string[] = [
  'Fully Customizable Code-less Templates',
  'Full HD Zoom Meetings',
  'Zoom Pre-Configured For Events',
  'Dashboard CTA Buttons',
  'Dashboard Update Posts',
  'Downloadable Resources',
  'Integrated Gamification System',
  'Customizable Form Engine',
  'Speaker Bios',
  'Sponsor Listings',
  'Event FAQ Section',
  'Downloadable Zoom Backgrounds',
  'Ticket Marketplace (coming soon)',
  'Communication Engine (coming soon)',
  'Much More..',
]

export default function PlanSummary(props: {
  billingInterval: string
  plan: PlanInfo | null
  toggleBillingInterval: () => void
}) {
  const {billingInterval, plan, toggleBillingInterval} = props
  if (!plan) {
    return null
  }

  const planPrice =
    billingInterval === INTERVAL_YEAR ? plan.priceYearly : plan.priceMonthly
  const intervalLabel = billingInterval === INTERVAL_YEAR ? 'Year' : 'Month'

  return (
    <Box>
      <Main>
        <Name>{plan.label}</Name>
        <Price>
          <Amount>${formatPrice(planPrice, {numDecimals: 0})}</Amount>
          <YearLabel>/ {intervalLabel}</YearLabel>
        </Price>
        <Description>{plan.description}</Description>
        <IntervalToggle
          checked={billingInterval === INTERVAL_YEAR}
          toggleBillingInterval={toggleBillingInterval}
        />
      </Main>
      <Right>
        <Features plan={plan} />
      </Right>
    </Box>
  )
}

function Feature(props: {feature: string}) {
  const {feature} = props

  return (
    <FeatureBox>
      <FeatureIcon>
        <CheckSquare color={colors.success} iconSize={20} />
      </FeatureIcon>
      <FeatureDetails>{feature}</FeatureDetails>
    </FeatureBox>
  )
}

function Features(props: {plan: PlanInfo}) {
  const list = getPlanFeatureList(props.plan)

  return (
    <FeaturesContainer>
      {list.map((f) => (
        <Feature feature={f} key={f} />
      ))}
    </FeaturesContainer>
  )
}

function getPlanFeatureList(plan: PlanInfo) {
  const [customizable, ...rest] = generalFeatures

  switch (plan.name) {
    case 'basic':
      return [customizable, 'Includes 1 Zoom Meeting for Your Events', ...rest]
    case 'professional':
      return [customizable, 'Includes 5 Zoom Meetings for Your Events', ...rest]
    case 'enterprise':
      return [
        customizable,
        'Includes 25 Zoom Meetings for Your Events',
        ...rest,
      ]
    default:
      throw new Error(`Missing plan features for plan: ${plan.name}`)
  }
}

function IntervalToggle(props: {
  checked: boolean
  toggleBillingInterval: () => void
}) {
  const {checked, toggleBillingInterval} = props

  return (
    <Grid container spacing={2}>
      <StyledGrid item xs={3}>
        Monthly
      </StyledGrid>
      <StyledGrid item xs={6}>
        <StyledSwitch
          onChange={toggleBillingInterval}
          checked={checked}
          labelPlacement="top"
          aria-label="change billing interval"
        />
      </StyledGrid>
      <StyledGrid item xs={3}>
        Yearly
      </StyledGrid>
    </Grid>
  )
}

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
`

const StyledSwitch = styled(Switch)`
  margin: 0;
`

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 52px;
  }
`

const Main = styled.div`
  padding: 14px 16px;
  margin: 0 0 28px;
  background: #f2f5f9;
  width: 250px;
  min-height: 180px;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0 28px 0 0;
  }
`

const Name = styled.span`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  display: block;
  margin-bottom: 8px;
`

const Price = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
`

const Amount = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 48px;
  margin: 0 8px 0 0;
`

const YearLabel = styled.span`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #606f7b;
`

const Description = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.spacing[4]};
  font-family: 'Rubik';
  font-size: 14px;
  line-height: 150%;
  color: #606f7b;
`

const Right = styled.div`
  flex: 1;
  min-width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: auto;
  }
`

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  row-gap: 16px;
  column-gap: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: auto auto;
    grid-template-rows: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: auto auto auto;
  }
`

const FeatureBox = styled.div`
  display: flex;
`

const FeatureIcon = styled.div`
  margin-right: ${(props) => props.theme.spacing[3]};
`

const FeatureDetails = styled.span`
  font-family: Rubik;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
