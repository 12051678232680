import React from 'react'
import {Font, getFontStyles} from '../../lib/font'

interface SectionLabelProps {
  children: string
  font?: Font | null
  fontSize?: number
  margin?: {
    top: number
    right: number
    bottom: number
    left: number
  }
}

export default function SectionLabel(props: SectionLabelProps) {
  const {children} = props
  return (
    <h5
      style={{
        ...getFontStyles(props.font ?? null),
        fontSize: props.fontSize ?? '18px',
        fontWeight: 'bold',
        marginTop: `${props.margin?.top ?? 16}px`,
        marginRight: `${props.margin?.right ?? 0}px`,
        marginBottom: `${props.margin?.bottom ?? 16}px`,
        marginLeft: `${props.margin?.left ?? 0}px`,
      }}
    >
      {children}
    </h5>
  )
}
