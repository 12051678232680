import {createCountdown} from 'Event/Dashboard/components/CountDownTimer'
import {CountdownTimerBlock} from 'Event/Marketplace/Block/CountdownTimer'

export const createCountdownTimerBlock = (): CountdownTimerBlock => ({
  type: 'CountdownTimer',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  countdownTimer: createCountdown(0),
})
