import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {Icon} from 'lib/fontawesome/Icon'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOwner} from 'organization/OwnerProvider'
import HasPermission from 'organization/HasPermission'
import {PURCHASE_ADD_ONS} from 'organization/PermissionsProvider'
import {useLocation} from 'react-router'
import {useOrganization} from 'organization/OrganizationProvider'

export interface PlanLimitsApplyData {
  add_on_key: string
  quantity: number
}

export interface PlanLimitsData {
  message: string
  limit_key: string
  can_purchase?: boolean
  can_apply?: PlanLimitsApplyData
}

export interface PlanLimitsResponse {
  plan_limits: PlanLimitsData
}

const lockoutKeys = ['attendeesMax']

type PlanLimitsContextProps = {
  checkPlanLimits: (error?: any, overrideLockout?: boolean) => void
  returnLocation: string
}

const PlanLimitsContext = React.createContext<
  undefined | PlanLimitsContextProps
>(undefined)

export default function PlanLimitsProvider(props: {
  children: React.ReactElement
}) {
  const {owner} = useOwner()
  const location = useLocation()

  const [planLimitsData, setPlanLimitsData] = useState<PlanLimitsData | null>(
    null,
  )
  const [returnLocation, setReturnLocation] = useState<string>('')
  const [overrideLockout, setOverrideLockout] = useState<boolean>(false)

  const checkPlanLimits = (error?: any, overrideLockout?: boolean) => {
    if (error?.plan_limits) {
      setPlanLimitsData(error.plan_limits)
      setReturnLocation(location.pathname)
      setOverrideLockout(overrideLockout || false)
    }
  }

  useEffect(() => {
    if (owner.has_unresolved_plan_limits) {
      setPlanLimitsData(owner.has_unresolved_plan_limits)
    } else {
      setPlanLimitsData(null)
    }
  }, [owner])

  return (
    <PlanLimitsContext.Provider
      value={{
        checkPlanLimits,
        returnLocation,
      }}
    >
      <PlanLimitsOverlay
        data={planLimitsData}
        overrideLockout={overrideLockout}
        returnLocation={returnLocation}
      />
      {props.children}
    </PlanLimitsContext.Provider>
  )
}

export function usePlanLimits() {
  const context = React.useContext(PlanLimitsContext)
  if (context === undefined) {
    throw new Error('usePlanLimits must be used within a PlanLimitsProvider')
  }

  return context
}

function PlanLimitsOverlay(props: {
  data: PlanLimitsData | null
  overrideLockout: boolean
  returnLocation: string
}) {
  const {owner} = useOwner()
  const {data, overrideLockout, returnLocation} = props
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setIsOpen(true)
  }, [data, owner])

  // If there's Plan Limit (data) to display or there isn't a plan associated
  // with the owner, there are no plan limits to properly enforce/display. We'll
  // get out of here now.
  if (!data || !owner.plan) {
    return null
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Box>
          <WarningIcon iconClass="far fa-exclamation-triangle" />
          <Title>Plan limits have been exceeded</Title>
          <Description>{data.message}</Description>

          <Actions>
            <HasPermission required={PURCHASE_ADD_ONS}>
              <GoToPurchaseButton
                canPurchase={!!data.can_purchase}
                limitKey={data.limit_key}
                returnLocation={returnLocation}
              />
            </HasPermission>
            <CloseButton
              limitKey={data.limit_key}
              onClick={handleClose}
              overrideLockout={overrideLockout}
            />
          </Actions>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

function GoToPurchaseButton(props: {
  canPurchase?: boolean
  children?: string
  limitKey: string
  returnLocation: string
}) {
  const {routes} = useOrganization()

  if (!props.canPurchase) {
    return null
  }

  const label = props.children || 'Increase Limits'
  let addon = ''

  switch (props.limitKey) {
    case 'roomsMax':
      addon = 'zoom_meetings'
      break
    case 'attendeesMax':
      addon = 'attendees'
      break
  }

  const returnParam = props.returnLocation
    ? `&return=${props.returnLocation}`
    : ''

  return (
    <RelativeLink
      to={`${routes.add_ons.purchase_add_on}?addon=${addon}${returnParam}`}
      disableStyles
    >
      <Button aria-label="go to purchase" color="primary" variant="contained">
        {label}
      </Button>
    </RelativeLink>
  )
}

function CloseButton(props: {
  limitKey: string
  onClick: () => void
  overrideLockout: boolean
}) {
  const {limitKey, onClick, overrideLockout} = props

  const isLockout = lockoutKeys.find((key) => key === limitKey)

  if (isLockout && !overrideLockout) {
    return null
  }

  return (
    <Button
      aria-label="close plan limits overlay"
      onClick={onClick}
      variant="contained"
    >
      Close
    </Button>
  )
}

const Actions = styled.div`
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing[5]};

  & > * {
    margin-right: ${(props) => props.theme.spacing[4]} !important;

    &:last-child {
      margin-right: 0;
    }
  }
`

const Box = styled.div`
  text-align: center;
`

const Title = styled.h1`
  margin: 0 0 ${(props) => props.theme.spacing[2]};
`

const Description = styled.p`
  margin: 0 0 ${(props) => props.theme.spacing[5]};
`

const WarningIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.error};
  font-size: 4rem;
  margin-bottom: ${(props) => props.theme.spacing[4]};
`
