import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import {Droppable} from 'react-beautiful-dnd'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import TicketItem from 'organization/Marketplace/Tickets/TicketItem'

export default function TicketListing() {
  return (
    <Box>
      <Droppable droppableId="tickets-droppable">
        {(provided) => (
          <div>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Slug</TableCell>
              <DateTableCell>Available From</DateTableCell>
              <DateTableCell>Available To</DateTableCell>
              <PriceTableCell>Price</PriceTableCell>
              <IndicatorTableCell>
                {/* Indicator Icons Cell */}
              </IndicatorTableCell>
              <MenuTableCell>{/* "More Menu" Cell */}</MenuTableCell>
            </TableRow>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <TicketContent />
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </Box>
  )
}

function TicketContent() {
  const eventRoutes = useEventRoutes()
  const {data: tickets} = useEventTickets()

  if (!tickets || tickets.length === 0) {
    return (
      <TableRow>
        <AddNewTableCell>
          <StyledRelativeLink
            disableStyles
            to={eventRoutes.marketplace.tickets.add}
          >
            <NewItemButton text="New Ticket" color="primary" />
          </StyledRelativeLink>
        </AddNewTableCell>
      </TableRow>
    )
  }

  return (
    <>
      {tickets.map((ticket, index) => (
        <TicketItem key={index} ticket={ticket} index={index} />
      ))}
    </>
  )
}

export const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`
export const TableCell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 62px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  vertical-align: inherit;

  button {
    text-align: left;
  }
`
export const DateTableCell = styled(TableCell)`
  display: none;
  flex: unset;
  width: 150px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    display: flex;
  }
`
export const PriceTableCell = styled(TableCell)`
  flex: unset;
  width: 110px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 160px;
  }
`
export const IndicatorTableCell = styled(TableCell)`
  flex: unset;
  width: 90px;
`
export const MenuTableCell = styled(TableCell)`
  flex: unset;
  width: 50px;
`
export const CopyCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[2]};
`
const AddNewTableCell = styled(TableCell)`
  justify-content: center;
  border-bottom: none !important;
  text-align: center;
`
const StyledRelativeLink = styled(RelativeLink)`
  display: inline-block;
`
