import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import React from 'react'
import {isCurrencyQuestion} from 'Event/Question/Currency'
import TextField from 'lib/ui/TextField'

export function CurrencyConfig(props: QuestionTypeConfigProps) {
  const {question, register} = props

  return (
    <TextField
      label="Currency"
      fullWidth
      variant="outlined"
      name="start_adornment"
      aria-label="currency symbol"
      inputProps={{
        ref: register,
      }}
      defaultValue={
        isCurrencyQuestion(question) ? question.start_adornment : ''
      }
    />
  )
}
