import FontSizeCommand from './fontsizecommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { DowncastConversionApi, ViewAttributeElement, ViewElement } from '@ckeditor/ckeditor5-engine';
import type { Editor } from 'ckeditor5/src/core';

export default class FontSizeEditing extends Plugin {
	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'CustomFontSizeEditing';
	}

	constructor( editor: Editor ) {
		super( editor );

		editor.conversion.for( 'upcast' ).elementToAttribute( {
			view: matchUpcastElement(),
			model: {
				key: "customFontSize",
				value: renderUpcastElement()
			}
		} );

		editor.conversion.for( 'downcast' ).attributeToElement( {
			model: "customFontSize",
			view: renderDowncastElement()
		} );

		editor.commands.add( 'customFontSize', new FontSizeCommand( editor ) );

		editor.model.schema.extend( '$text', { allowAttributes: 'customFontSize' } );

		editor.model.schema.setAttributeProperties( 'customFontSize', {
			isFormatting: true,
			copyOnEnter: true
		} );
	}

}

function matchUpcastElement() {
	return (element: ViewElement) => {

		if ( element.name === 'span' ) {
			const fontsize = element.getStyle( 'font-size' );
			if ( fontsize ) {
				return {
					name: true,
					styles: [ 'font-size' ]
				};
			}
		}
		return null;
	};
}

function renderUpcastElement() {
	return (element: ViewElement) => {

		const fontsize = element.getStyle( 'font-size' );
		if ( fontsize ) {
			return fontsize.replace( /\s/g, '' ).replace('px', '');
		}
		return null;
	};
}

function renderDowncastElement( ) {

	return ( modelAttributeValue: string, { writer }: DowncastConversionApi ): ViewAttributeElement =>{

		const attributes = modelAttributeValue ? {
			style: `font-size:${ modelAttributeValue }px;`
		} : {};

		return writer.createAttributeElement( 'span', attributes, { priority: 7 } );
	};
}



