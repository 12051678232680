import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import {DroppableProvidedProps} from 'react-beautiful-dnd'
import {useHasVisibleItems} from 'Event/attendee-rules/matcher'
import {orderedIdsByPosition} from 'lib/list'
import ResourceItem from 'Event/business-mastery/TeamCompetition/ResourceItem'
import {useCardsTemplate} from 'Event/template/Cards'
import {ResourceListProps as SidebarResourceListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/ResourceList'

export const RESOURCE_ICON = {
  pdf: 'picture_as_pdf',
  attachment: 'attachment',
  people: 'people',
  image: 'image',
  photoLibrary: 'photo_library',
}

interface ResourceListProps {
  tag: string
}

export function ResourceList(props: ResourceListProps) {
  const {tag} = props
  const {sidebarItems} = useCardsTemplate()

  const resourceLists = Object.values(sidebarItems).filter(
    (item) => item.type === 'Resource List',
  ) as SidebarResourceListProps[]

  const resourceList = resourceLists.find((list) =>
    list.rules?.some(
      (rule) =>
        rule.source === 'Tags' &&
        rule.type === 'include' &&
        rule.target === tag,
    ),
  )

  const resources = resourceList?.resources ?? {}
  const ids = orderedIdsByPosition(resources)
  const hasVisibleItems = useHasVisibleItems(Object.values(resources))

  if (!hasVisibleItems) {
    return null
  }

  return (
    <Container>
      {ids.map((id: string, index: number) => {
        const resource = resources[id]
        return (
          <ResourceItem
            droppleid={`resource-item-${index}`}
            id={id}
            resource={resource}
            index={index}
            key={index}
          />
        )
      })}
    </Container>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    <Grid container>{props.children}</Grid>
  </Box>
))

const Box = styled.div`
  margin-bottom: 30px;
  width: 100%;
`
