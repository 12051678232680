import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import moment from 'moment-timezone'
import Typography from '@material-ui/core/Typography'
import {useAttendeeVariables} from 'Event'
import {Attendee} from 'Event/attendee'
import {useAttendee, useSetGroup} from 'Event/auth'
import Day from 'Event/business-mastery/TeamCompetition/Day'
import DetailsForm, {
  useTeamDetails,
} from 'Event/business-mastery/TeamCompetition/DetailsForm'
import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import CardsPage from 'Event/template/Cards/Page'
import {useAsync} from 'lib/async'
import {useInterval} from 'lib/interval'
import QRCode from 'lib/QRCode'
import {useToggleArray} from 'lib/toggle'
import {PaginatedCollection} from 'lib/ui/api-client'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api, useQueryParams} from 'lib/url'
import React, {useCallback, useEffect, useState} from 'react'
import {Redirect} from 'react-router'
import styled from 'styled-components'
import SectionHeader from 'Event/business-mastery/TeamCompetition/SectionHeader'
import TextEditorContent from 'lib/ui/form/TextEditor/Content'
import SubmitButton from 'Event/business-mastery/TeamCompetition/SubmitButton'
import {ResourceList} from 'Event/business-mastery/TeamCompetition/ResourceList'

export const TEAM_ID_GROUP_KEY = `TeamId`
export const IN_PERSON_QUERY = 'in_person'

type TeamCompetitionContextProps = {
  teamId?: string
  meetingId: string | null
  isInPerson: boolean
}

type DayStatus = {
  is_locked: boolean
  is_visible: boolean
}

const TeamCompetitionContext = React.createContext<
  undefined | TeamCompetitionContextProps
>(undefined)

export default function TeamCompetition() {
  const [meetingId, setMeetingId] = useState<string | null>(null)
  const {
    team_id: teamId,
    [IN_PERSON_QUERY]: inPerson = false,
    meeting_id: urlMeetingId,
  } = useQueryParams()

  const findAttendeesWithTeamId = useAttendeesWithTeamId()

  // Set Meeting ID either from URL (?meeting_id=abcd1234) or
  // from fetching it from the team creator
  useEffect(() => {
    if (meetingId) {
      return
    }

    if (urlMeetingId) {
      setMeetingId(urlMeetingId)
      return
    }

    if (!teamId) {
      return
    }

    findAttendeesWithTeamId(teamId).then((page) => {
      const creator = page.data.find(
        (attendee) => attendee.groups['TeamRole'] === 'Creator',
      )

      if (!creator) {
        return
      }

      const teamMeetingId = creator.groups['MeetingId']
      if (!teamMeetingId) {
        return
      }

      setMeetingId(String(teamMeetingId))
    })
  }, [urlMeetingId, setMeetingId, meetingId, teamId, findAttendeesWithTeamId])

  if (!teamId) {
    return <Redirect to={eventRoutes.root} />
  }

  const isInPerson = inPerson === 'true'

  // loading...
  if (isInPerson && !meetingId) {
    return null
  }

  return (
    <TeamCompetitionContext.Provider value={{teamId, isInPerson, meetingId}}>
      <Content />
    </TeamCompetitionContext.Provider>
  )
}

function Content() {
  const attendee = useAttendee()
  const teamDetails = useTeamDetails()
  const v = useAttendeeVariables()

  const {client} = useEvent()

  useSetGroups()

  const {
    day_one = '2024-08-11',
    day_two = '2024-08-15',
    day_three = '2024-08-16',
    day_four = '2024-08-17',
    day_five = '2024-08-18',
  } = useQueryParams()

  const fetchDays = useCallback(
    () =>
      client.get<{
        '1': DayStatus
        '2': DayStatus
        '3': DayStatus
        '4': DayStatus
        '5': DayStatus
      }>(
        api(
          `/business_mastery/team_competition/days?day_one=${day_one}&day_two=${day_two}&day_three=${day_three}&day_four=${day_four}&day_five=${day_five}`,
        ),
      ),
    [day_one, day_two, day_three, day_four, day_five, client],
  )

  const {data: dayStatuses} = useAsync(fetchDays)

  if (teamDetails.loading || !dayStatuses) {
    return <FullPageLoader />
  }

  const activeDayStatus = Object.entries(dayStatuses).find(
    ([_day, status]) =>
      status.is_locked === false && status.is_visible === true,
  )

  const activeDay = activeDayStatus ? parseInt(activeDayStatus[0]) : null

  return (
    <CardsPage user={attendee}>
      <Box my={5}>
        <TextEditorContent>{v('{{team_heading_topofpage}}')}</TextEditorContent>
        <Top>
          <Grid item xs={12} md={6}>
            <SectionHeader>
              {v('{{team_heading_teaminfo}}', 'Team Information')}
            </SectionHeader>
            <TextEditorContent>
              {v('{{team_description_teaminfo}}')}
            </TextEditorContent>
            <DetailsForm details={teamDetails} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeader>
              {v('{{team_heading_meetwithteam}}', 'Meet With My Team')}
            </SectionHeader>
            <TextEditorContent>
              {v('{{team_description_meetwithteam}}')}
            </TextEditorContent>
            <TeamMembers />
            <ZoomSection />
            <JoinQRCode />
          </Grid>
        </Top>
      </Box>
      <Box my={5}>
        <Top>
          <Grid item xs={12} md={6}>
            <SectionHeader>
              {v('{{team_heading_resources}}', 'Resources')}
            </SectionHeader>
            <TextEditorContent>
              {v('{{team_description_resources}}')}
            </TextEditorContent>
            <ResourceList tag="bm_resources" />
          </Grid>
          <Grid item xs={12} md={6}>
            <SectionHeader>
              {v('{{team_heading_marketing}}', 'Marketing/Advertising')}
            </SectionHeader>
            <TextEditorContent>
              {v('{{team_description_marketing}}')}
            </TextEditorContent>
            <ResourceList tag="bm_marketing" />
          </Grid>
        </Top>
      </Box>
      <SectionHeader>
        {v('{{team_heading_dailysubs}}', 'Gladiator Reports')}
      </SectionHeader>
      <TextEditorContent>
        {v('{{team_description_dailysubs}}')}
      </TextEditorContent>
      <Day
        day={1}
        isShowing={dayStatuses['1'].is_visible}
        isLocked={dayStatuses['1'].is_locked}
        activeDay={activeDay}
        description="Did you choose a project? What is the project? Why did you choose this project? How will you measure your success? "
      />
      <Day
        day={2}
        isShowing={dayStatuses['2'].is_visible}
        isLocked={dayStatuses['2'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={3}
        isShowing={dayStatuses['3'].is_visible}
        isLocked={dayStatuses['3'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={4}
        isShowing={dayStatuses['4'].is_visible}
        isLocked={dayStatuses['4'].is_locked}
        activeDay={activeDay}
        description="What is your project update and/or progress? What Business Mastery principles are you implementing? What are your challenges and victories? And how are you measuring that? "
      />
      <Day
        day={5}
        isShowing={dayStatuses['5'].is_visible}
        isLocked={dayStatuses['5'].is_locked}
        activeDay={activeDay}
        description="What are the results of your project? What did you learn? (What went well? What could you do even better?) How can your team apply this to your businesses?"
      />
    </CardsPage>
  )
}

function useSetGroups() {
  const {teamId, meetingId} = useTeamCompetition()
  const user = useAttendee()
  const [processing, toggleProcessing] = useToggleArray()
  const setGroup = useSetGroup()
  const {join_team = true, meeting_id} = useQueryParams()

  // Allow users to visit with `?join_team=false` to avoid having
  // their group set. Useful for crew members.
  const canSwitchTeam = join_team === true

  const isCurrentTeam = user.groups[TEAM_ID_GROUP_KEY] === teamId
  const isCurrentMeeting = user.groups['MeetingId'] === meetingId
  const isCreator = user.groups['TeamRole'] === 'Creator'

  const shouldSetTeamId = teamId && canSwitchTeam && !isCurrentTeam
  const shouldSetMeetingId = meetingId && !isCurrentMeeting
  const shouldSetTeamRole = meeting_id && !isCreator

  useEffect(() => {
    if (processing) {
      return
    }

    if (shouldSetTeamId) {
      toggleProcessing()
      setGroup('TeamId', teamId).then(toggleProcessing)
      return
    }

    if (shouldSetMeetingId) {
      toggleProcessing()
      setGroup('MeetingId', meetingId).then(() => {
        const joinedTime =
          moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss.000') +
          '-04:00' // EST UTC offset
        setGroup('JoinedTeamTime', joinedTime).then(toggleProcessing)
      })
      return
    }

    if (shouldSetTeamRole) {
      toggleProcessing()
      setGroup('TeamRole', 'Creator').then(toggleProcessing)
      return
    }
  }, [
    meetingId,
    processing,
    setGroup,
    shouldSetMeetingId,
    shouldSetTeamId,
    shouldSetTeamRole,
    teamId,
    toggleProcessing,
  ])
}

function ZoomSection() {
  const {teamId, meetingId} = useTeamCompetition()
  const joinLink = `https://obv-io.zoom.us/j/${meetingId}`
  const v = useAttendeeVariables()

  if (!meetingId) {
    return null
  }

  return (
    <div>
      <Box
        mb={2}
        style={{
          fontSize: '24px',
        }}
      >
        <Typography variant="h6">
          {v('{{team_id_label}}', 'Team Name to Join')}
        </Typography>
        <Typography>
          {teamId}
          <br></br>
          <br></br>
        </Typography>
        <Typography variant="h6">
          {v('{{team_zoom_meeting_id}}', 'Zoom Meeting ID')}
        </Typography>
        <Typography>
          <a href={joinLink} target="_blank" rel="noopener noreferrer">
            {meetingId}
          </a>
          <br></br>
        </Typography>
      </Box>
      <a href={joinLink} target="_blank" rel="noopener noreferrer">
        <SubmitButton type="button">
          {v('{{team_meet_with_my_team_button}}', 'MEET WITH MY TEAM')}
        </SubmitButton>
      </a>
    </div>
  )
}

function JoinQRCode() {
  const v = useAttendeeVariables()
  const {isInPerson, teamId} = useTeamCompetition()

  const url = window.location.href
  if (!isInPerson) {
    return null
  }

  return (
    <div>
      <Typography variant="h6">
        {v('{{team_scan_qr_code}}', 'Team QR Code')}{' '}
        <Typography variant="body1">(Short Code: {teamId})</Typography>
      </Typography>
      <QRCode>{url}</QRCode>
    </div>
  )
}

function TeamMembers() {
  const v = useAttendeeVariables()

  const members = useFetchTeamMembers()

  return (
    <div>
      <Typography variant="h6">
        {v('{{team_members}}', 'Team Members')}
      </Typography>
      <ul
        style={{
          maxHeight: '222px',
          overflowY: 'auto',
        }}
      >
        {members.map((member) => (
          <li key={member.id}>
            <Typography>
              {member.first_name} {member.last_name} -{' '}
              <a href={`mailto:${member.email}`}>{member.email}</a>
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  )
}

/**
 * How often to fetch team members
 */
const POLL_MEMBERS_INTERVAL_SECS = 10

function useFetchTeamMembers() {
  const {teamId} = useTeamCompetition()
  const findAttendeesWithTeamId = useAttendeesWithTeamId()
  const [members, setMembers] = useState<Attendee[]>([])
  const [fetching, setFetching] = useState(false)

  const request = useCallback(
    async (page = 1) => {
      if (!teamId) {
        return
      }

      const paginated = await findAttendeesWithTeamId(teamId, page)

      if (page === 1) {
        setMembers(paginated.data)
      } else {
        setMembers((members) => [...members, ...paginated.data])
      }

      if (paginated.next_page_url) {
        await request(page + 1)
      }
    },
    [findAttendeesWithTeamId, teamId],
  )

  const poll = useCallback(() => {
    if (fetching) {
      return
    }

    setFetching(true)
    request().then(() => {
      setFetching(false)
    })
  }, [fetching, request])

  useInterval(poll, POLL_MEMBERS_INTERVAL_SECS * 1000)
  useEffect(() => {
    request()
  }, [request]) // fetch on load

  return members
}

export function useAttendeesWithTeamId() {
  const {
    client,
    event: {id: eventId},
  } = useEvent()

  return useCallback(
    (teamId: string, page: number = 1) => {
      let url = api(
        `/events/${eventId}/attendees?per_page=10&group_is=${TEAM_ID_GROUP_KEY}:${teamId}&page=${page}`,
      )
      return client.get<PaginatedCollection<Attendee>>(url)
    },
    [client, eventId],
  )
}

export function useTeamCompetition() {
  const context = React.useContext(TeamCompetitionContext)
  if (context === undefined) {
    throw new Error('useTeamCompetition must be used within TeamCompetition')
  }

  return context
}

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    gap: 20px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 80px;
    justify-content: space-between;
  }
`
