import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import {useEvent} from 'Event/EventProvider'
import {onChangeStringHandler} from 'lib/dom'
import React from 'react'
import {HighLevelTag} from 'Event/highlevel'

export default function HighLevelTagInput(
  props: Omit<TextFieldProps, 'value' | 'onChange'> & {
    onChange: (tag: HighLevelTag | null) => void
    value?: HighLevelTag | null
    inputVariant?: 'filled' | 'standard' | 'outlined'
  },
) {
  const {disabled, value, onChange, ...textFieldProps} = props
  const {event} = useEvent()

  const name = value?.name || ''

  if (!event.has_highlevel) {
    return null
  }

  return (
    <TextField
      value={name}
      variant={props.inputVariant}
      label="High Level Tag"
      fullWidth
      inputProps={{
        'aria-label': 'highlevel tag',
      }}
      onChange={onChangeStringHandler((name) => onChange({name}))}
      disabled={disabled}
      helperText={'Add high level tag, when user tag an action'}
      {...textFieldProps}
    />
  )
}
