import React, {RefObject, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTownhallTemplate} from 'Event/template/Townhall'
import Configurable from 'organization/Event/Configurable'
import {TopBarConfig} from 'Event/template/Townhall/Dashboard/TopBar/TopBarConfig'
import {rgba} from 'lib/color'
import {Font, fontStyles, useLoadFont} from 'lib/FontSelect'
import {DEFAULT_FONT_SIZE} from 'Event/Dashboard/components/NavButton'
import {ceil} from 'lodash'
import {useAttendeeVariables} from 'Event'

export default function TopBar() {
  const {topBar, textColor} = useTownhallTemplate()
  const backgroundColor = rgba(
    topBar.backgroundColor,
    topBar.backgroundOpacity / 100,
  )

  const [windowsWidth, setWindowsWidth] = useState<number>(window.innerWidth)
  const [oneElementWidth, setOneElementWidth] = useState<number | null>(null)

  const groupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowsWidth(window.innerWidth)
      getOneElementWidth()
    })

    getOneElementWidth()

    return () => {
      window.removeEventListener('resize', () => {})
    }
  })

  const getOneElementWidth = () => {
    if (!groupRef.current?.offsetWidth) {
      return
    }

    if (!oneElementWidth) {
      setOneElementWidth(groupRef.current?.offsetWidth)
    }
  }

  return (
    <Configurable aria-label="edit top bar">
      <TopBarConfig />
      <Box
        textColor={textColor}
        topBarColor={topBar.color}
        backgroundColor={backgroundColor}
        padding={topBar.padding}
      >
        <MeasureSegment groupRef={groupRef} show={!oneElementWidth} />
        <Segments
          windowsWidth={windowsWidth}
          oneElementWidth={oneElementWidth}
        />
      </Box>
    </Configurable>
  )
}

function MeasureSegment(props: {
  groupRef: RefObject<HTMLDivElement>
  show: boolean
}) {
  if (!props.show) {
    return null
  }

  return <Segment groupRef={props.groupRef} />
}

function Segments(props: {
  windowsWidth: number
  oneElementWidth: number | null
}) {
  if (!props.oneElementWidth) {
    return null
  }

  const windowsWidth = props.windowsWidth
  const oneElementWidth = props.oneElementWidth

  const numSegments = ceil(windowsWidth / oneElementWidth) + 1 // always add 1 more for wide screens
  const segments = new Array(numSegments)
    .fill(0)
    .map((_v, index) => <Segment key={index} />)

  return <>{segments}</>
}

function Segment(props: {groupRef?: React.RefObject<HTMLDivElement>}) {
  return (
    <Group ref={props.groupRef}>
      <Text />
      <Logo />
    </Group>
  )
}

function Logo() {
  const template = useTownhallTemplate()
  const {title} = template

  if (!template.logo) {
    return null
  }

  return (
    <LogoImage
      src={template.logo}
      alt={title}
      size={template.logoSize}
      aria-label="logo"
    />
  )
}

function Text() {
  const {topBar} = useTownhallTemplate()
  const v = useAttendeeVariables()

  useLoadFont(topBar.font)

  return (
    <StyledSpan
      space={topBar.logoSpacing}
      font={topBar.font}
      fontSize={topBar.fontSize}
    >
      {v(topBar.text)}
    </StyledSpan>
  )
}

const LogoImage = styled.img<{
  size: number
}>`
  width: ${(props) => props.size || 100}px;
  object-fit: contain;
  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
`

const Group = styled.div`
  display: flex;
`

const StyledSpan = styled.span<{
  font: Font | null
  fontSize: number
  space: number
}>`
  ${(props) => fontStyles(props.font)}
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: inherit !important;
  margin: 0px ${(props) => props.space}px;
  font-size: ${(props) => props.fontSize || DEFAULT_FONT_SIZE}px;
  width: max-content;
`

const Box = styled.div<{
  backgroundColor: string
  textColor: string
  topBarColor: string | null
  padding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}>`
  background: ${(props) => props.backgroundColor};
  color: ${(props) =>
    props.topBarColor ? props.topBarColor : props.textColor};
  padding-left: ${(props) => props.padding.left}px;
  padding-top: ${(props) => props.padding.top}px;
  padding-bottom: ${(props) => props.padding.bottom}px;
  padding-right: ${(props) => props.padding.right}px;
  display: flex;
  justify-content: left;
  overflow: hidden;
`
