import React from 'react'
import styled from 'styled-components'
import {FormModerationLowerThirdConfig} from 'Event/template'

export default function PopUp(
  props: {
    children: React.ReactElement
    popUpRef: React.MutableRefObject<HTMLDivElement | null>
    popUpBottomPixels: number
  } & FormModerationLowerThirdConfig,
) {
  return (
    <StyledPopUp
      {...props}
      ref={props.popUpRef}
      popUpBottomPixels={props.popUpBottomPixels}
    >
      <PopUpContent {...props}>{props.children}</PopUpContent>
    </StyledPopUp>
  )
}

const StyledPopUp = styled.div<
  FormModerationLowerThirdConfig & {popUpBottomPixels: number}
>`
  position: fixed;
  display: flex;
  justify-content: ${(props) => props.position || 'center'};
  width: 100%;
  bottom: ${(props) => props.popUpBottomPixels}px;
  right: ${(props) =>
    props.position === 'flex-end' ? props.horizontalMargin : 0}%;
  left: ${(props) =>
    props.position === 'flex-end' ? '' : props.horizontalMargin}%;
`

const PopUpContent = styled.div<FormModerationLowerThirdConfig>`
  background: ${(props) => props.backgroundColor || '#fff'};
  width: ${(props) => props.size || 25}%;
  display: flex;
  padding: ${(props) => props.theme.spacing[4]};
  border-radius: ${(props) => props.borderRadius || 0}px;
  border-width: ${(props) => props.borderThickness || 0}px;
  border-color: ${(props) => props.borderColor || '#fff'};
  border-style: solid;
`
