import View from '@ckeditor/ckeditor5-ui/src/view';
import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';

import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import LabeledFieldView from '@ckeditor/ckeditor5-ui/src/labeledfield/labeledfieldview';
import { createLabeledInputText } from '@ckeditor/ckeditor5-ui/src/labeledfield/utils';

import submitHandler from '@ckeditor/ckeditor5-ui/src/bindings/submithandler';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import FocusTracker from '@ckeditor/ckeditor5-utils/src/focustracker';
import FocusCycler, { FocusableView } from '@ckeditor/ckeditor5-ui/src/focuscycler';

import checkIcon from '@ckeditor/ckeditor5-core/theme/icons/check.svg';
import cancelIcon from '@ckeditor/ckeditor5-core/theme/icons/cancel.svg';
import { Locale } from '@ckeditor/ckeditor5-utils';

export default class CustomButtonTextFormView extends View {
	public focusTracker: FocusTracker;
	public keystrokes: KeystrokeHandler;
	public labeledInput: LabeledFieldView;
	public saveButtonView: ButtonView;
	public cancelButtonView: ButtonView;
	protected _focusables: ViewCollection<FocusableView>;
	protected _focusCycler: FocusCycler;

	constructor(locale: Locale) {
		super(locale);

		this.focusTracker = new FocusTracker();
		this.keystrokes = new KeystrokeHandler();
		this.labeledInput = this._createLabeledInputView();
		this.saveButtonView = this._createButton('Save', checkIcon, 'ck-button-save');
		this.saveButtonView.type = 'submit';
		this.cancelButtonView = this._createButton('Cancel', cancelIcon, 'ck-button-cancel', 'cancel');

		this._focusables = new ViewCollection<FocusableView>();

		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				focusPrevious: 'shift + tab',
				focusNext: 'tab'
			}
		});

		this.setTemplate({
			tag: 'form',

			attributes: {
				class: [
					'ck',
					'ck-responsive-form',
					'obvio-custom-button-input-form'
				],
				tabindex: '-1'
			},

			children: [
				this.labeledInput,
				this.saveButtonView,
				this.cancelButtonView
			]
		});
	}

	override render() {
		super.render();

		this.keystrokes.listenTo(this.element!);

		submitHandler({ view: this });

		[this.labeledInput, this.saveButtonView, this.cancelButtonView].forEach(v => {
			this._focusables.add(v);
			this.focusTracker.add(v.element!);
		});
	}

	private _createButton(label: string, icon: string, className: string, eventName?: string): ButtonView {
		const button = new ButtonView(this.locale);

		button.set({
			label,
			icon,
			tooltip: true
		});

		button.extendTemplate({
			attributes: {
				class: className
			}
		});

		if (eventName) {
			button.delegate('execute').to(this, eventName);
		}

		return button;
	}

	private _createLabeledInputView(): LabeledFieldView {
		const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);

		labeledInput.label = 'Text';
		labeledInput.fieldView.placeholder = 'Text';

		return labeledInput;
	}
}
