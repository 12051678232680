import React, {useState} from 'react'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import Grid from '@material-ui/core/Grid'
import Page from 'organization/Event/Page'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import UpsellPageUserEventsGrid from 'organization/Marketplace/config/TemplateSelector/UpsellPageUserEventsGrid'
import UpsellPageObvioEventsGrid from 'organization/Marketplace/config/TemplateSelector/UpsellPageObvioEventsGrid'

interface UpsellPageTemplateSelectorProps {
  updateTemplate: UpdateTemplateFunction
}

export default function UpsellPageTemplateSelector(
  props: UpsellPageTemplateSelectorProps,
) {
  const {updateTemplate} = props
  const [showOwnEvents, setShowOwnEvents] = useState(false)

  return (
    <Page>
      <PageHeader>
        <Title text={`Choose Your Upsell Page Template`} />
        <Switch
          checked={showOwnEvents}
          labelPlacement="end"
          label="Show My Own Events"
          aria-label="switch templates"
          onChange={onChangeCheckedHandler(setShowOwnEvents)}
        />
      </PageHeader>
      <Grid container spacing={2}>
        {showOwnEvents ? (
          <UpsellPageUserEventsGrid updateTemplate={updateTemplate} />
        ) : (
          <UpsellPageObvioEventsGrid updateTemplate={updateTemplate} />
        )}
      </Grid>
    </Page>
  )
}
