import React from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect, {
  SelectProps as MuiSelectProps,
} from '@material-ui/core/Select'
import HelperText from 'lib/ui/TextField/HelperText'

export type SelectProps = MuiSelectProps & {
  label?: string | null
  'aria-label'?: string
  value?: string | number
  children?: React.ReactNode
  onChange: (value: any, obj?: any) => void
  className?: string
  helperText?: string
}

export default function Select(props: SelectProps) {
  const {label, value, className = '', helperText} = props

  return (
    <StyledFormControl
      fullWidth={props.fullWidth}
      className={className}
      error={props.error}
    >
      <Label {...props} />
      <StyledSelect
        value={value}
        inputProps={{
          'aria-label': props['aria-label'],
        }}
        disabled={props.disabled}
        fullWidth
        onChange={props.onChange}
        hasLabel={Boolean(label)}
        disableUnderline
        MenuProps={{
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        required={props.required}
      >
        {props.children}
      </StyledSelect>
      <HelperText error={props.error}>{helperText}</HelperText>
    </StyledFormControl>
  )
}

function Label(props: SelectProps) {
  if (!props.label) {
    return null
  }

  return <StyledInputLabel shrink={false}>{props.label}</StyledInputLabel>
}

const StyledFormControl = styled(FormControl)`
  margin-bottom: ${(props) => props.theme.spacing[6]};
`

const StyledInputLabel = styled(InputLabel)`
  position: relative;
  transform: none;
  color: ${(props) => props.theme.colors.input.label};
`

export const StyledSelect = styled(({hasLabel: _, ...otherProps}) => (
  <MuiSelect {...otherProps} />
))<{
  hasLabel?: boolean
}>`
  ${(props) =>
    props.hasLabel ? `margin-top: ${props.theme.spacing[3]} !important;` : ''}

  > div {
    background: ${(props) => props.theme.colors.input.background};
    border-radius: 3px !important;
    padding: ${(props) =>
      `${props.theme.spacing[3]} ${props.theme.spacing[3]}`};
    border: 1px solid ${(props) => props.theme.colors.input.filled};
    box-sizing: border-box;
    line-height: 14px;
  }

  &.Mui-focused {
    > div {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  // Arrow icon
  > svg {
    right: ${(props) => props.theme.spacing[1]};
  }
`
