import React, {useEffect, useRef, useState} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {useLayout} from 'organization/Event/Layout'
import {HideLiveChatSupport} from 'lib/WithLiveChatSupport'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {useToggleArray} from 'lib/toggle'
import SettingsIcon from '@material-ui/icons/Settings'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useTemplate} from 'Event/TemplateProvider'
import LowerThirdConfig from 'organization/Event/Form/FormModeration/LowerThirdPage/LowerThirdPageConfig'
import {fontStyles, useLoadFont} from 'lib/FontSelect'
import {FormModerationLowerThirdConfig} from 'Event/template'
import {useFormSubmissions} from 'lib/event-api/forms/use-form-submissions'
import Show from 'lib/Show'
import {useParams} from 'react-router-dom'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import {useListenForFormSubmissionSavedUpdates} from 'organization/Event/Form/FormModeration'
import PopUp from 'organization/Event/Form/FormModeration/LowerThirdPage/PopUp'
import PopUpLogo from 'organization/Event/Form/FormModeration/LowerThirdPage/PopUpLogo'

export default function LowerThird() {
  const {form} = useForm()
  const template = useTemplate()
  const styles = template?.formModeration?.form[form.id]?.lowerThird

  const {status} = useParams<{status: FormSubmissionStatus}>()

  const {data: submissions} = useFormSubmissions({
    form,
    status,
  })

  const {setFullScreen, showFullScreen} = useLayout()

  useEffect(() => {
    if (showFullScreen) {
      return
    }
    setFullScreen(true)
    return () => {
      if (showFullScreen) {
        setFullScreen(false)
      }
    }
  }, [showFullScreen, setFullScreen])

  const [zoom, setZoom] = useState<number>(1)
  const [showingSettings, toggleSettings] = useToggleArray()

  const zoomIn = () => {
    setZoom((currentZoom) => currentZoom + 0.1)
  }

  const zoomOut = () => {
    setZoom((currentZoom) => currentZoom - 0.1)
  }

  const calculateZoom = (pixels: number) => {
    return pixels * zoom
  }

  useLoadFont(styles?.names?.font)
  useLoadFont(styles?.answers?.font)

  const popUpRef = useRef<HTMLDivElement>(null)
  const [popUpBottomPixels, setPopUpBottomPixels] = useState<number>(0)
  const [showMenu, setShowMenu] = useState<boolean>(true)
  const [mouseTimeOut, setMouseTimeOut] = useState<NodeJS.Timeout | null>(null)

  const setTimer = () => {
    setMouseTimeOut(
      setTimeout(() => {
        if (showingSettings) return
        setShowMenu(false)
      }, 5000),
    )
  }

  useEffect(() => {
    setTimer()
    // we don't need callback in SetTimer, so :
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mouseLeave = () => {
    if (showingSettings) return

    setShowMenu(false)
  }

  const mouseEnter = () => {
    setShowMenu(true)
  }

  const mouseMove = () => {
    if (mouseTimeOut) {
      setShowMenu(true)
      clearTimeout(mouseTimeOut)
    }

    setTimer()
  }

  useEffect(() => {
    const popUpHeight = popUpRef?.current?.clientHeight ?? 0
    const screenLeft = window.innerHeight - popUpHeight
    const verticalMargin = styles?.verticalMargin ?? 10
    const popUpBottom = Math.floor((screenLeft / 100) * verticalMargin)
    setPopUpBottomPixels(popUpBottom)
  }, [styles, popUpRef])
  useListenForFormSubmissionSavedUpdates()

  if (!submissions) {
    return (
      <HideLiveChatSupport>
        <FullPageLoader />
      </HideLiveChatSupport>
    )
  }

  if (!submissions[0]) {
    return (
      <HideLiveChatSupport>
        <GlobalStyles />
      </HideLiveChatSupport>
    )
  }

  return (
    <HideLiveChatSupport>
      <GlobalStyles />
      <Page
        style={{height: '100vh', cursor: showMenu ? 'default' : 'none'}}
        onMouseLeave={mouseLeave}
        onMouseEnter={mouseEnter}
        onMouseMove={mouseMove}
      >
        <PopUp
          {...styles}
          popUpRef={popUpRef}
          popUpBottomPixels={popUpBottomPixels}
        >
          <>
            <PopUpLogo logo={styles?.logo} />
            <PopUpItem>
              <PopUpNames
                {...styles}
                style={{
                  fontSize: `${calculateZoom(styles?.names?.fontSize || 35)}px`,
                }}
              >
                {submissions[0].attendee_first_name}{' '}
                {submissions[0].attendee_last_name}
              </PopUpNames>

              <PopUpAnswer
                {...styles}
                style={{
                  fontSize: `${calculateZoom(
                    styles?.answers?.fontSize || 35,
                  )}px`,
                }}
              >
                {' '}
                {submissions[0].answers[0].answer}{' '}
              </PopUpAnswer>
            </PopUpItem>
          </>
        </PopUp>

        <Show when={showMenu}>
          <ZoomButtons>
            <ZoomButton onClick={zoomOut}>-</ZoomButton>
            <ZoomButton onClick={zoomIn}>+</ZoomButton>

            <ZoomButton onClick={toggleSettings}>
              <SettingsIcon />
            </ZoomButton>

            <LowerThirdConfig
              form={form}
              showing={showingSettings}
              onClose={toggleSettings}
            />
          </ZoomButtons>
        </Show>
      </Page>
    </HideLiveChatSupport>
  )
}

const PopUpNames = styled.div<FormModerationLowerThirdConfig>`
  font-weight: bold;
  ${(props) => fontStyles(props?.names?.font || null)}
  color: ${(props) => props?.names?.fontColor || '#000'};
`

const PopUpAnswer = styled.div<FormModerationLowerThirdConfig>`
  ${(props) => fontStyles(props?.answers?.font || null)}
  color: ${(props) => props?.answers?.fontColor || '#000'};
`

const PopUpItem = styled.div`
  color: #000000;
  flex: 11;
`

const ZoomButtons = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
`

const ZoomButton = styled.div`
  width: 40px;
  height: 30px;
  background: #676767;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #000000;

  &:last-child {
    border-right: 0px;
  }
`

const Page = styled.div`
  padding: ${(props) => props.theme.spacing[8]};
`
const GlobalStyles = createGlobalStyle`
body {
    background: #000000; 
}
`
