import {api} from 'lib/url'
import {Form} from 'organization/Event/FormsProvider'
import {useQuery} from 'react-query'
import {client} from 'lib/ui/api-client'

interface UseFindFormByAccessTokenParams {
  token: string
}

export function useFindFormByAccessToken(
  params: UseFindFormByAccessTokenParams,
) {
  const {token} = params

  return useQuery({
    queryKey: ['form', {token}],
    queryFn: () => client.get<Form>(api(`/form?access_token=${token}`)),
  })
}
