import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {usePanelsTemplate} from 'Event/template/Panels'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const template = usePanelsTemplate()
  const {leaderboard} = template
  const {control, register} = props
  const v = useAttendeeVariables()

  return (
    <>
      <FormControl>
        <Controller
          name="isVisible"
          control={control}
          defaultValue={leaderboard.isVisible}
          render={({value, onChange}) => (
            <EnabledSwitch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="toggle points"
            />
          )}
        />
      </FormControl>
      <Label>Title</Label>
      <TextField
        name="title"
        aria-label="set leaderboard page title"
        defaultValue={v(leaderboard.title)}
        fullWidth
        inputProps={{
          ref: register({
            required: 'Title is required',
          }),
        }}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={leaderboard.description}
        rules={{
          required: 'Description is required',
        }}
        render={({value, onChange}) => (
          <TextEditorContainer>
            <TextEditor
              data={value}
              onChange={onChange}
              customLinks={['Leaderboard']}
            />
          </TextEditorContainer>
        )}
      />
      <Label>Menu Title</Label>
      <TextField
        name="menuTitle"
        aria-label="set leaderboard page menu title"
        fullWidth
        defaultValue={v(leaderboard.menuTitle)}
        inputProps={{
          ref: register({
            required: 'Menu title is required',
          }),
        }}
      />
      <Label>Points Unit</Label>
      <TextField
        name="points_unit"
        aria-label="points unit"
        fullWidth
        defaultValue={v(template.points_unit)}
        inputProps={{
          ref: register({
            required: 'Points unit is required',
          }),
        }}
      />
    </>
  )
}
