import React from 'react'
import {
  DialogBox,
  QuestionsPreview,
  FormSubmission,
} from 'organization/Event/Form/FormModeration/Question'
import Dialog from 'lib/ui/Dialog'

interface SelectedSubmissionDialogProps {
  formSubmission: FormSubmission | null
  showing: boolean
  onClose: () => void
}

export default function SelectedSubmissionDialog(
  props: SelectedSubmissionDialogProps,
) {
  const {formSubmission, showing, onClose} = props

  if (!formSubmission) {
    return null
  }
  return (
    <Dialog open={showing} onClose={onClose} maxWidth="xs">
      <DialogBox>
        <QuestionsPreview questions={formSubmission.answers} />
      </DialogBox>
    </Dialog>
  )
}
