import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Slider from 'lib/ui/Slider'
import {useAttendeeVariables} from 'Event'

export default function Settings() {
  const {
    form: {control},
  } = useConfig()
  const template = useTownhallTemplate()
  const v = useAttendeeVariables()

  return (
    <>
      <Title>Top Logo</Title>
      <Controller
        name="logo"
        control={control}
        defaultValue={template.logo}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove logo',
            }}
            uploadInputProps={{
              'aria-label': 'top horizontal logo upload',
            }}
            disableAutoRemove
          />
        )}
      />

      <Title>Text</Title>
      <Controller
        name="topBar.text"
        control={control}
        defaultValue={v(template.topBar.text)}
        render={({onChange, value}) => (
          <TextField fullWidth value={value} onChange={onChange} />
        )}
      />

      <Title>Space between logo and text</Title>
      <Controller
        name="topBar.logoSpacing"
        control={control}
        defaultValue={template.topBar.logoSpacing}
        render={({onChange, value}) => (
          <Slider
            label="Space between logo and text"
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="space between logo and text"
            unit="px"
          />
        )}
      />
    </>
  )
}
