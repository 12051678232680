import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import MultilineTextField from 'lib/ui/TextField/TextArea'
import InputLabel from 'lib/ui/TextField/InputLabel'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'
import {useToggleArray} from 'lib/toggle'
import BackgroundRepeatSelector from 'lib/ui/BackgroundRepeatSelector'
import TextField from 'lib/ui/TextField'
import FontSelect from 'lib/FontSelect'
import {Label} from 'lib/ui/typography'
import {JsonSave} from 'lib/JsonUpdateProvider'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {PageTemplate} from 'Event/Marketplace/page-template'

export default function PageSettings(props: {
  template: PageTemplate
  updateTemplate: JsonSave<PageTemplate>
}) {
  const {template, updateTemplate} = props

  const {background, padding, typography, contentMaxWidth} = template

  const {font, fontSize, textColor} = typography

  const {
    form: {register, control},
  } = useConfig()
  const [showingOptions, toggleOptions] = useToggleArray()
  const {data: tickets} = useEventTickets()

  return (
    <Config title="Page Settings" onSave={updateTemplate}>
      <SettingsPanel>
        {'title' in template ? (
          <TextField
            name="title"
            defaultValue={template.title}
            label="Title"
            inputProps={{
              'aria-label': 'page title',
              ref: register,
            }}
          />
        ) : (
          <></>
        )}

        {'description' in template ? (
          <TextField
            name="description"
            defaultValue={template.description}
            label="Description"
            inputProps={{
              'aria-label': 'page description',
              ref: register,
            }}
          />
        ) : (
          <></>
        )}

        {'favicon' in template ? (
          <Controller
            name="favicon"
            control={control}
            defaultValue={template.favicon}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Favicon"
                uploadInputProps={{
                  'aria-label': 'page favicon',
                }}
                width={32}
                height={32}
                disableAutoRemove
              />
            )}
          />
        ) : (
          <></>
        )}

        {'headHtml' in template ? (
          <>
            <InputLabel>Head HTML</InputLabel>
            <MultilineTextField
              name="headhtml"
              defaultValue={template.headHtml || ''}
              rows={10}
              fullWidth
              inputProps={{
                'aria-label': 'page head html',
                ref: register,
              }}
            />
          </>
        ) : (
          <></>
        )}

        {'footerHtml' in template ? (
          <>
            <InputLabel>Footer HTML</InputLabel>
            <MultilineTextField
              name="footerhtml"
              defaultValue={template.footerHtml || ''}
              rows={10}
              fullWidth
              inputProps={{
                'aria-label': 'page footer html',
                ref: register,
              }}
            />
          </>
        ) : (
          <></>
        )}

        {'ticket_id' in template ? (
          <Controller
            name="ticket_id"
            control={control}
            defaultValue={template?.ticket_id}
            render={({onChange, value}) => (
              <Select
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
                label="Ticket"
                fullWidth
              >
                {(tickets || []).map((ticket) => (
                  <Option key={ticket.id} value={ticket.id}>
                    {ticket.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        ) : (
          <></>
        )}
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Typography"
          storageKey="purchase-page-typography"
        >
          <Label>Font</Label>
          <Controller
            control={control}
            name="typography.font"
            defaultValue={font}
            render={({value, onChange}) => (
              <FontSelect value={value} onChange={onChange} />
            )}
          />
          <Controller
            name="typography.fontSize"
            defaultValue={fontSize}
            control={control}
            render={({value, onChange}) => (
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                valueLabelDisplay="off"
                value={value}
                label="Font Size"
              />
            )}
          />
          <Controller
            name="typography.textColor"
            control={control}
            defaultValue={textColor}
            render={({value, onChange}) => (
              <ColorPicker label="Color" color={value} onPick={onChange} />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Background"
          storageKey={`purchase-page-background`}
        >
          <Controller
            name="background.image"
            control={control}
            defaultValue={background.image}
            render={({onChange, value}) => (
              <ImageAssetUploader
                onChange={onChange}
                value={value}
                uploadLabel="Upload"
                uploadInputProps={{
                  'aria-label': 'page background image',
                }}
                width={1920}
                height={1200}
                canResize
                disableAutoRemove
                additionalActions={
                  <PositionSelectorButton onClick={toggleOptions} />
                }
              />
            )}
          />
          <Controller
            name="background.position"
            control={control}
            defaultValue={background.position}
            render={({onChange, value}) => (
              <BackgroundPositionSelector
                showing={showingOptions}
                onChange={onChange}
                value={value}
                label="Background Position"
              />
            )}
          />
          <Controller
            name="background.repeat"
            defaultValue={background.repeat}
            control={control}
            render={({value, onChange}) => (
              <BackgroundRepeatSelector
                showing={showingOptions}
                onChange={onChange}
                value={value}
                label="Background Repeat"
              />
            )}
          />
          <Controller
            name="background.color"
            defaultValue={background.color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="page background color"
              />
            )}
          />
          <Controller
            name="background.opacity"
            defaultValue={background.opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="page background opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection label="Spacing" storageKey="purchase-page-spacing">
          <MarginPaddingInputs
            control={control}
            namePrefix="padding"
            values={padding}
            settings={{max: 300}}
          />
        </CollapsibleSection>
        <Controller
          name="contentMaxWidth"
          defaultValue={contentMaxWidth}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Constrained Content Max Width"
              unit="px"
              valueLabelDisplay="auto"
              aria-label="content max width"
              value={value}
              onChange={onChange}
              step={1}
              min={720}
              max={1280}
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
