import React from 'react'
import styled from 'styled-components'
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
  FormQuestionProps,
  MULTIPLE_CHOICE_SEPARATOR,
  useObieQuestions,
} from 'organization/Obie/ObieQuestionsProvider'
import {QuestionOption} from 'organization/Obie/ObieServiceProvider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: 'white',
      '&$checked': {
        color: 'white',
      },
    },
    formControl: {
      margin: theme.spacing(3),
      color: 'white',
    },
  }),
)

export default function CheckboxesGroup(props: FormQuestionProps) {
  const {question, setDirty} = props
  const {currentAnswer, setCurrentAnswer} = useObieQuestions()
  // Need to get the currentAnswer value local, but we want it as an array. It's
  // being split/joined on a particluar separater, as there COULD be commas in
  // option values.
  let localAnswer: string[] = []

  // If the currentAnswer value from the provider is a string, we attempt to split
  // it at the MULTIPLE_CHOICE_SEPARATOR to turn it into an array. If currentAnswer
  // is not a string, we no-op set the localAnswer value back to itself and do
  // further checks below.
  localAnswer =
    typeof currentAnswer === 'string'
      ? currentAnswer.split(MULTIPLE_CHOICE_SEPARATOR)
      : localAnswer

  // If the localAnswer length is 0 (it's empty) and the currentAnswer value is
  // NOT a string, we assume it's in array form already (there's some gymnastics
  // involved keeping state between front and backends), so we straight set the
  // value. Otherwise we no-op set the localAnswer value back to itself because
  // it's already an array from the step before.
  localAnswer =
    !localAnswer.length && typeof currentAnswer !== 'string'
      ? currentAnswer
      : localAnswer

  // Cleaning out any empty options - they cause problems.
  localAnswer = localAnswer.filter((answer) => answer)

  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, name} = event.target

    setDirty && setDirty()

    // If the change we're handling is a box was checked, we need to add to the
    // array of currentAnswer.
    if (checked) {
      // Need to re-join the local array as a string for state storage.
      const newValue = [...localAnswer, name].join(MULTIPLE_CHOICE_SEPARATOR)
      setCurrentAnswer(newValue)
      return
    }

    // Filter out an previously selected answer that is not equal to the current
    // one. Re-join the local array as a string for state storage.s
    const newValue = localAnswer
      .filter((v: string) => v !== name)
      .join(MULTIPLE_CHOICE_SEPARATOR)
    setCurrentAnswer(newValue)
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          {question.options.map((option: QuestionOption, index: number) => {
            // Determine from the local array of selected answers if THIS option
            // has been selected, so we can mark it as checked.
            const checked = localAnswer.some(
              (ans: string) => ans === option.value,
            )

            return (
              <FormControlLabel
                key={`multi-choice-${index}`}
                control={
                  <StyledCheckBox
                    checked={checked}
                    name={option.value}
                    onChange={handleChange}
                  />
                }
                label={option.value}
              />
            )
          })}
        </FormGroup>
      </FormControl>
    </div>
  )
}

const StyledCheckBox = styled(Checkbox)`
  color: white;
`
