import moment from 'moment-timezone'

export const isPast = (target: string, start = now(), timezone = 'UTC') =>
  !date(target, timezone).isAfter(start)

export const now = () => new Date().toISOString()

/**
 * Date comparison utils
 *
 * @param target
 * @returns
 */
export const date = (target: string, timezone = 'UTC') => {
  return {
    isAfter: (value: string) => {
      /**
       * Handle bug where sometimes moment.isAfter would return true
       * for the same string
       */
      if (target === value) {
        return false
      }

      return moment.tz(target, timezone).isAfter(moment.tz(value, timezone))
    },
  }
}

export interface Duration {
  days?: string
  hours: string
  minutes: string
  seconds: string
}

/**
 *
 * @param start
 * @param end
 * @returns an object with days, hours, minutes and seconds
 */
export const duration = (
  start: string,
  end: string,
  leadingZero?: boolean,
): Duration => {
  const duration = moment.duration(moment(end).diff(moment(start)))

  if (leadingZero === undefined) {
    leadingZero = true
  }

  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const diffToString = (diff: number, leadingZero: boolean) => {
    if (diff < 0) return '00'
    if (leadingZero && diff < 10) return `0${diff}`
    return `${diff}`
  }

  if (days < 1)
    return {
      hours: diffToString(hours, leadingZero),
      minutes: diffToString(minutes, leadingZero),
      seconds: diffToString(seconds, leadingZero),
    }
  return {
    days: leadingZero && days < 10 ? `0${days}` : `${days}`,
    hours: diffToString(hours, leadingZero),
    minutes: diffToString(minutes, leadingZero),
    seconds: diffToString(seconds, leadingZero),
  }
}
