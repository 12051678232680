import ActionButton from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton'
import styled from 'styled-components'
import React from 'react'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import NewActionButton from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/NewActionButton'
import {
  Townhall,
  useTownhallTemplate,
  useTownhallUpdate,
} from 'Event/template/Townhall'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import Scheduled from 'lib/ui/layout/Scheduled'
import Grid from '@material-ui/core/Grid'
import {useMatchOnRule} from 'Event/attendee-rules/VisibleOnMatch'

export default function Buttons(props: {className?: string}) {
  const {actionNav} = useTownhallTemplate()
  const {separator} = actionNav
  const isEditMode = useEditMode()

  const ids = useButtonIDList()

  const buttons = ids.map((id, index) => (
    <Scheduled component={actionNav.buttons[id]} key={id}>
      <>
        <Card padding={actionNav.padding}>
          <ActionButton
            id={id}
            index={index}
            button={actionNav.buttons[id]}
            hasTopBorder={index === 0 && actionNav.enabled === false}
          />
        </Card>
        <Separator
          borderColor={separator.color}
          borderWidth={separator.width}
          showing={index !== ids.length - 1}
        />
      </>
    </Scheduled>
  ))

  if (isEditMode) {
    return <Editable className={props.className}>{buttons}</Editable>
  }

  return (
    <Container align={actionNav.align} className={props.className}>
      {buttons}
    </Container>
  )
}

function Editable(props: {children: JSX.Element[]; className?: string}) {
  const handleDrag = useHandleDrag()
  const template = useTownhallTemplate()
  const {actionNav} = template

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId="action_buttons">
        {(provided) => (
          <Container
            align={actionNav.align}
            className={props.className}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <>
              <StyledNewActionButton />
              {props.children}
              {provided.placeholder}
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    align: Townhall['actionNav']['align']
    className?: string
    children: React.ReactElement | React.ReactElement[]
  } & Partial<DroppableProvidedProps>
>((props, ref) => (
  <Box className={props.className} ref={ref} {...props}>
    <Grid container justify={props.align}>
      {props.children}
    </Grid>
  </Box>
))

function useHandleDrag() {
  const set = useTownhallUpdate()
  const template = useTownhallTemplate()
  const {actionNav: actionPanel} = template

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(actionPanel.buttons)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    set({
      actionNav: {
        buttons: createPositions(ids),
      },
    })
  }
}

function useButtonIDList() {
  const template = useTownhallTemplate()

  const {actionNav} = template
  const isEditMode = useEditMode()

  const checkRule = useMatchOnRule()
  const ids = orderedIdsByPosition(actionNav.buttons).filter((id) =>
    checkRule(actionNav.buttons[id].rules),
  )

  if (isEditMode) {
    return ids
  }

  return ids.filter((id) => actionNav.buttons[id].isVisible)
}

const Box = styled.div`
  width: 100%;
`

const StyledNewActionButton = styled(NewActionButton)`
  margin-top: ${(props) => props.theme.spacing[5]};
  padding-top: ${(props) => props.theme.spacing[2]} !important;
  padding-bottom: ${(props) => props.theme.spacing[2]} !important;
`

const Card = styled.div<{
  padding?: {
    left: number
    right: number
    top: number
    bottom: number
  }
}>`
  padding-left: ${(props) => (props.padding ? props.padding.left : 0)}px;
  padding-right: ${(props) => (props.padding ? props.padding.right : 0)}px;
  padding-top: ${(props) => (props.padding ? props.padding.top : 0)}px;
  padding-bottom: ${(props) => (props.padding ? props.padding.bottom : 0)}px;
  color: ${(props) => props.color};
  width: 100%;
`

const Separator = styled.div<{
  borderColor: string
  borderWidth: number
  showing: boolean
}>`
  width: 100%;
  border-bottom: solid ${(props) => props.borderWidth}px
    ${(props) => props.borderColor};
  display: ${(props) => (props.showing ? 'block' : 'none')};
`
