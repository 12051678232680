import {LoginProps} from 'Event/auth/Login'
import styled from 'styled-components'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiButton, {ButtonProps} from '@material-ui/core/Button'
import {usePanelsTemplate} from 'Event/template/Panels'
import Background from 'Event/auth/Login/Background'
import Logo from 'Event/auth/Login/Logo'

export default function Page(props: {
  isPreview?: LoginProps['isPreview']
  children: React.ReactElement | React.ReactElement[]
}) {
  const template = usePanelsTemplate()
  const {login, textColor} = template

  return (
    <StyledBackground
      textColor={textColor}
      descriptionTextColor={login.description.color}
      isPreview={props.isPreview}
    >
      <Logo />
      <>{props.children}</>
    </StyledBackground>
  )
}

export function ErrorMessage(props: {children?: string}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

export function Button(props: ButtonProps) {
  const template = usePanelsTemplate()
  const {login} = template
  const borderRadius = `${login.submitButton.borderRadius}px`
  const hoverColor = login.submitButton.hoverBackgroundColor
  return (
    <StyledButton
      variant="contained"
      fullWidth
      backgroundColor={login.submitButton.backgroundColor}
      color={login.submitButton.textColor}
      borderRadius={borderRadius}
      hoverColor={hoverColor}
      {...props}
    />
  )
}

export const StyledButton = styled(
  ({
    color: _1,
    backgroundColor: _2,
    borderRadius: _3,
    hoverColor: _4,
    ...otherProps
  }) => <MuiButton {...otherProps} />,
)`
  border-radius: ${(props) => props.borderRadius} !important;
  height: 50px;
  color: ${(props) => props.color} !important;
  background: ${(props) => props.backgroundColor} !important;

  &:hover {
    background: ${(props) => props.hoverColor} !important;
  }
`

export const ErrorText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing[3]}!important;
  text-align: center;
`

const StyledBackground = styled(Background)<{
  textColor: string
  descriptionTextColor: string | null
}>`
  color: ${(props) =>
    props.descriptionTextColor
      ? props.descriptionTextColor
      : props.textColor} !important;
`
