import Block from 'organization/Event/Certificates/CertificateEditor/Block'
import {Certificate} from 'lib/event-api/certificates/types'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useDroppable} from '@dnd-kit/core'

interface BlocksProps {
  certificate: Certificate
  template: NonNullable<Certificate['template']>
}

export default function Blocks(props: BlocksProps) {
  const {template, certificate} = props
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const ids = Object.keys(template.blocks)

  const {setNodeRef} = useDroppable({
    id: 'certificate-page',
  })

  useEffect(() => {
    setNodeRef(container)
  }, [container, setNodeRef])

  return (
    <Container>
      <img
        src={certificate.file.url}
        alt="pdf page"
        style={{
          width: `${certificate.page_width / 300}in`,
          height: `${certificate.page_height / 300}in`,
        }}
      />
      <PositionOverlay ref={setContainer}>
        {ids.map((id) => (
          <Block key={id} id={id} template={template} container={container} />
        ))}
      </PositionOverlay>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  margin: 72px auto 0;
  border: 3px solid ${(props) => props.theme.colors.primary};
`

const PositionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
