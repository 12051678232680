import {
	View,
	LabeledFieldView,
	createLabeledInputText,
	ButtonView,
	submitHandler,
	ListView,
	ListItemView
} from '@ckeditor/ckeditor5-ui';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { FONT_FAMILY_NAMES, DEFAULT_FONTS } from './fontfamilies';
import "../../theme/style.css"
import FocusTracker from '@ckeditor/ckeditor5-utils/src/focustracker';
import FocusCycler, { FocusableView } from '@ckeditor/ckeditor5-ui/src/focuscycler';
import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import { Emitter, Locale } from '@ckeditor/ckeditor5-utils';

export default class FormView extends View {
	public fontFamilyInputView: LabeledFieldView<FocusableView>;
	public nameListView: ListView;

	private childViews: ViewCollection;
	private focusTracker: FocusTracker;
	private keystrokes: KeystrokeHandler;
	private _focusables: ViewCollection<FocusableView>;
	private _focusCycler: FocusCycler;

	constructor(locale: Locale) {
		super(locale);

		this.fontFamilyInputView = this._createInput('Search Font Family Name')!;

		this.nameListView = new ListView(this.locale);
		this._createNameList();

		this.childViews = this.createCollection([
			this.fontFamilyInputView,
			this.nameListView,
		]);

		this.setTemplate({
			tag: 'form',
			attributes: {
				class: ['ck', 'obvio-custom-font-family-input-form'],
				tabindex: '-1'
			},
			children: this.childViews
		});

		const inputField = this.fontFamilyInputView.fieldView!;

		inputField.on('input', () => {
			const searchVal = (inputField.element as HTMLInputElement).value.trim()!;

			if (searchVal.length > 2) {
				this._updateNameList(searchVal);
			} else {
				this._updateNameList('');
			}

		});

		this.focusTracker = new FocusTracker();
		this.keystrokes = new KeystrokeHandler();
		this._focusables = new ViewCollection<FocusableView>();

		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				focusPrevious: 'shift+tab',
				focusNext: 'tab'
			}
		});
	}

	override render() {
		super.render();

		submitHandler({
			view: this
		});

		this._addKeyEvent();
	}

	focus() {
		// this.childViews.first!.focus();
		(this.childViews.first as any).focus();
	}

	private _createInput(label: string): LabeledFieldView {
		const labeledInput = new LabeledFieldView(this.locale, createLabeledInputText);
		labeledInput.label = label;

		labeledInput.extendTemplate({
			attributes: {
				class: [
					'obvio-font-family-name-input-field'
				],
				tabindex: '-1'
			}
		});

		return labeledInput;
	}

	private _createNameList() {
		const items = getDropdownItemsDefinitions(DEFAULT_FONTS);
		this._updateListView(items);

		this.nameListView.extendTemplate({
			attributes: {
				class: [
					'obvio-font-family-name-list'
				],
				tabindex: '-1'
			}
		});
	}

	public _updateNameList(filterKey: string) {
		const filtered = filterKey === '' ? DEFAULT_FONTS : filterFontNames(filterKey);
		const items = getDropdownItemsDefinitions(filtered);

		this.nameListView.items.clear();
		this._updateListView(items);
	}

	private _updateListView(items: Collection<{ type: string, model: Model }>) {
		const listView = this.nameListView;

		listView.items.bindTo(items).using(({ type, model }) => {
			const listItemView = new ListItemView(this.locale);

			const buttonView = new ButtonView(this.locale);
			buttonView.bind( ...Object.keys(model) as Array<keyof ButtonView>).to(model);
			buttonView.delegate('execute').to(listItemView);

			listItemView.children.add(buttonView);
			return listItemView;
		});
	}

	private _addKeyEvent() {
		[this.fontFamilyInputView, this.nameListView]
			.forEach(v => {
				this._focusables.add(v);
				this.focusTracker.add(v.element as Element);
			});

		this.keystrokes.listenTo(this.element as unknown as Emitter);

		const stopPropagation = (data: any) => data.stopPropagation();

		this.keystrokes.set('arrowright', stopPropagation);
		this.keystrokes.set('arrowleft', stopPropagation);
		this.keystrokes.set('arrowup', stopPropagation);
		this.keystrokes.set('arrowdown', stopPropagation);

		this.listenTo(this.fontFamilyInputView.element!, 'selectstart', (evt, domEvt) => {
			domEvt.stopPropagation();
		}, { priority: 'high' });
	}
}

function getDropdownItemsDefinitions(filterFontNames: string[]): Collection<{ type: string, model: Model }> {
	const itemDefinitions = new Collection<{ type: string, model: Model }>();

	for (const name of filterFontNames) {
		const definition = {
			type: 'button',
			model: new Model({
				commandParam: name.toString(),
				label: name.toString(),
				class: 'obvio-fontfamily-name-item',
				labelStyle: `font-family: ${name};`,
				withText: true
			})
		};

		itemDefinitions.add(definition);
	}

	return itemDefinitions;
}

function filterFontNames(key: string): string[] {
	return FONT_FAMILY_NAMES.filter(name => name.toLowerCase().includes(key.toLowerCase()));
}
