'use client'

import {BlockComponentProps} from '../../Block'
import {VideoBlock} from '.'
import VideoContainer from './VideoContainer'
import VideoElement from './VideoElement'
import React, {useEffect, useRef} from 'react'

// Prevent from error: Property 'loading' does not exist on type 'HTMLIFrameElement'.
type HTMLIFrameElementWithLoading = HTMLIFrameElement & {
  loading?: 'eager' | 'lazy'
}

export default function UrlVideo(props: VideoBlock & BlockComponentProps) {
  const {url: videoUrl} = props
  const videoElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!videoUrl) {
      return
    }

    const embedUrl = getEmbedUrl(videoUrl)
    if (!embedUrl) {
      return
    }

    const iframe = document.createElement(
      'iframe',
    ) as HTMLIFrameElementWithLoading
    iframe.src = embedUrl
    iframe.title = 'video'
    iframe.loading = 'lazy'
    iframe.setAttribute('allowfullscreen', '')
    if (videoElement && videoElement.current) {
      videoElement.current.innerHTML = ''
      videoElement.current.appendChild(iframe)
    }
  }, [videoUrl])

  return (
    <VideoContainer {...props}>
      {(size) => (
        <VideoElement
          {...props}
          width={size.width}
          height={size.height}
          aria-label="video-container"
          ref={videoElement}
        />
      )}
    </VideoContainer>
  )
}

function getEmbedUrl(videoUrl: string) {
  if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
    const videoId =
      videoUrl.split('v=')[1]?.split('&')[0] ||
      videoUrl.split('youtu.be/')[1]?.split('?')[0]
    return `https://www.youtube.com/embed/${videoId}`
  }

  if (videoUrl.includes('vimeo.com')) {
    const videoId = videoUrl.split('vimeo.com/')[1]?.split('?')[0]
    return `https://player.vimeo.com/video/${videoId}`
  }
  if (videoUrl.includes('wistia.com')) {
    const videoId = videoUrl.split('wistia.com/medias/')[1]?.split('?')[0]
    return `https://fast.wistia.net/embed/iframe/${videoId}`
  }

  if (videoUrl.includes('searchie.io')) {
    const videoId = videoUrl.split('watch/')[1]?.split('?')[0]
    return `https://app.searchie.io/file/${videoId}/embed`
  }

  return null
}
