import {PointsSummaryProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/PointsSummary'
import {onChangeCheckedHandler} from 'lib/dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import TextEditor from 'lib/ui/form/TextEditor'
import {Label} from 'lib/ui/typography'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  points: PointsSummaryProps
}) {
  const {control, points} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isEnabled"
        defaultValue={points.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Controller
        name="logo"
        control={control}
        defaultValue={points.logo || ''}
        render={({onChange, value}) => (
          <ImageAssetUploader
            label="Points Logo"
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove points summary logo',
            }}
            uploadInputProps={{
              'aria-label': 'logo upload',
            }}
          />
        )}
      />
      <Box mb={2}>
        <Alert severity="info">
          <AlertTitle>Variables</AlertTitle>
          <div>
            <Typography variant="caption">
              {`{{leaderboard_points}} - Attendee's current points`}
            </Typography>
          </div>
          <Typography variant="caption">
            {`{{points_unit}} - Name for points`}
          </Typography>
        </Alert>
      </Box>
      <Box mb={2}>
        <Box mb={1}>
          <Label>Summary</Label>
        </Box>
        <Controller
          name="summary"
          control={control}
          defaultValue={v(points.summary || '')}
          render={({value, onChange}) => (
            <TextEditor
              data={value}
              onChange={onChange}
              toolbar={['bold', 'italic', 'link', 'alignment']}
              customLinks={['Leaderboard']}
            />
          )}
        />
      </Box>
      <Box mb={1}>
        <Box mb={1}>
          <Label>Description</Label>
        </Box>
        <Controller
          name="description"
          control={control}
          defaultValue={v(points.description || '')}
          render={({value, onChange}) => (
            <TextEditor
              data={value}
              onChange={onChange}
              toolbar={['bold', 'italic', 'link', 'alignment']}
              customLinks={['Leaderboard']}
            />
          )}
        />
      </Box>
    </>
  )
}
