import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Select from 'lib/ui/Select'
import MenuItem from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {
  ALPHA_NUMERIC,
  EMAIL,
  NUMERIC,
  PHONE_NUMBER,
  Question,
} from 'organization/Event/QuestionsProvider'

export default function ValidationSelect(props: {
  type: string
  question?: Question
  control: UseFormMethods['control']
}) {
  const {question, control} = props

  return (
    <Controller
      name="validation_rule"
      defaultValue={question?.validation_rule || null}
      control={control}
      render={({onChange, value}) => (
        <Select
          value={value || 0}
          fullWidth
          onChange={onUnknownChangeHandler((val) => onChange(val || null))}
          variant="outlined"
          aria-label="validation rule"
          label="Validation"
        >
          <MenuItem value={0}>None</MenuItem>
          <MenuItem value={EMAIL} aria-label="email">
            Email
          </MenuItem>
          <MenuItem value={PHONE_NUMBER} aria-label="phone number">
            Phone Number
          </MenuItem>
          <MenuItem value={ALPHA_NUMERIC} aria-label="alpha numeric">
            Alphanumeric
          </MenuItem>
          <MenuItem value={NUMERIC} aria-label="number">
            Number
          </MenuItem>
        </Select>
      )}
    />
  )
}
