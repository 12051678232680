import EventList from 'organization/EventList'
import CreateEventForm from 'organization/EventList/CreateEventForm'
import {RouteEventProvider} from 'Event/EventProvider'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {useOrganization} from 'organization/OrganizationProvider'
import EventRoutes from 'organization/Event/EventRoutes'
import Team from 'organization/Team'
import {
  EVENT_SETTINGS,
  CREATE_EVENTS,
  UPDATE_TEAM,
  PURCHASE_ADD_ONS,
} from 'organization/PermissionsProvider'
import AuthorizedPage from 'organization/AuthorizedPage'
import FormsProvider from 'organization/Event/FormsProvider'
import OrganizationSetting from 'organization/Settings'
import EventSocketProvider from 'organization/Event/EventSocketProvider'
import DownloadAreaAttendees from 'organization/Event/Area/DownloadAreaAttendees'
import DownloadFormSubmissions from 'organization/Event/Form/DownloadFormSubmissions'
import DownloadQuestionSubmissions from 'organization/Event/Form/DownloadQuestionSubmissions'
import DownloadRoomAttendees from 'organization/Event/Room/DownloadRoomAttendees'
import DownloadAttendees from 'Event/DownloadAttendees'
import DownloadWaivers from 'Event/DownloadWaivers'
import PlanProvider from 'obvio/Billing/PlanProvider'
import MarketplaceRoutes from 'organization/Marketplace/MarketplaceRoutes'
import Layout from 'organization/Event/Layout'
import DuplicateEventForm from 'organization/EventList/DuplicateEventForm'
import VerifyEmailPage from 'organization/Settings/CommunicationSettings/VerifyEmailPage'
import EmailsProvider from 'organization/Settings/CommunicationSettings/EmailsProvider'
import PreviewAttendeeProfileProvider from 'Event/attendee-rules/AttendeeProfileProvider/PreviewAttendeeProfileProvider'
import {StaticDateProvider} from 'lib/date-time/StaticDateProvider'
import EventTimezone from 'organization/Event/EventTimezone'
import DownloadImageEntriesExport from 'Event/DownloadImageEntriesExport'
import PreviewSubmissionsProvider from 'Event/SubmissionsProvider/PreviewSubmissionsProvider'
import DownloadAllFormSubmissions from 'organization/Event/DownloadAllFormSubmissions'
import OrganizationBilling from 'organization/AddOns'
import PurchaseAddOnPage from 'organization/AddOns/PurchaseAddOnPage'
import PlanLimitsProvider from 'obvio/PlanLimitsProvider'
import HostJoinRoomPage from 'organization/Event/Room/HostJoinRoomPage'
import DownloadCertificates from 'Event/DownloadCertificates'
import ObieServiceProvider from 'organization/Obie/ObieServiceProvider'
import ObieRoutes from 'organization/Obie/ObieRoutes'

export default function UserRoutes() {
  const {routes} = useOrganization()

  return (
    <Switch>
      {/*
          Handle login redirect. Placed first here, rather than on login success to
          avoid hitting the event catch-all below, and getting a React
          render error .
        */}
      <Redirect path={routes.login} to={routes.events.root} />
      <Route path={routes.add_ons.purchase_add_on}>
        <PurchaseAddOnPage />
      </Route>
      <Route path={routes.add_ons.root}>
        <AuthorizedPage permission={PURCHASE_ADD_ONS}>
          <StaticDateProvider>
            <OrganizationBilling />
          </StaticDateProvider>
        </AuthorizedPage>
      </Route>
      <Route path={routes.obie.root}>
        <ObieServiceProvider>
          <ObieRoutes />
        </ObieServiceProvider>
      </Route>
      <PlanLimitsProvider>
        <Switch>
          <Route path={routes.settings}>
            <EmailsProvider>
              <OrganizationSetting />
            </EmailsProvider>
          </Route>
          <Route path={routes.emails.verify[':token'].root}>
            <VerifyEmailPage />
          </Route>
          <Route path={routes.events.create}>
            <AuthorizedPage permission={CREATE_EVENTS}>
              <CreateEventForm />
            </AuthorizedPage>
          </Route>
          <Route path={routes.events.root} exact>
            <EventList />
          </Route>
          <Route path={routes.team}>
            <AuthorizedPage permission={UPDATE_TEAM}>
              <Team />
            </AuthorizedPage>
          </Route>
          <Route path={routes.area_attendees_export[':file'].root}>
            <DownloadAreaAttendees />
          </Route>
          <Route path={routes.form_submissions[':file'].root}>
            <DownloadFormSubmissions />
          </Route>
          <Route path={routes.question_submissions[':file'].root}>
            <DownloadQuestionSubmissions />
          </Route>
          <Route path={routes.room_attendees_export[':file'].root}>
            <DownloadRoomAttendees />
          </Route>
          <Route path={routes.attendees_export[':file'].root}>
            <DownloadAttendees />
          </Route>
          <Route path={routes.waivers[':file'].root}>
            <DownloadWaivers />
          </Route>
          <Route path={routes.image_entries[':file'].root}>
            <DownloadImageEntriesExport />
          </Route>
          <Route path={routes.form_submissions_all[':file'].root}>
            <DownloadAllFormSubmissions />
          </Route>
          <Route path={routes.certificates[':file'].root}>
            <DownloadCertificates />
          </Route>
          <Route
            path={routes.events[':event'].areas[':area'].rooms[':room'].join}
          >
            <HostJoinRoomPage />
          </Route>
          <Route path={routes.events[':event'].root}>
            <RouteEventProvider>
              <Switch>
                {/* Any routes that shouldn't inherit event layout can be defined here */}
                <Route path={routes.events[':event'].duplicate}>
                  <AuthorizedPage permission={EVENT_SETTINGS}>
                    <DuplicateEventForm />
                  </AuthorizedPage>
                </Route>
                <Route>
                  <FormsProvider>
                    <PlanProvider>
                      <PreviewSubmissionsProvider>
                        <PreviewAttendeeProfileProvider>
                          <EventSocketProvider>
                            <StaticDateProvider>
                              <Layout>
                                <EventTimezone>
                                  <EventRoutes />
                                </EventTimezone>
                              </Layout>
                            </StaticDateProvider>
                          </EventSocketProvider>
                        </PreviewAttendeeProfileProvider>
                      </PreviewSubmissionsProvider>
                    </PlanProvider>
                  </FormsProvider>
                </Route>
              </Switch>
            </RouteEventProvider>
          </Route>

          <Route path={routes.marketplace.root}>
            <MarketplaceRoutes />
          </Route>
          <Redirect to={routes.events.root} />
        </Switch>
      </PlanLimitsProvider>
    </Switch>
  )
}
