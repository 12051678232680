import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {Lefty, useLeftyUpdate} from 'Event/template/Lefty'
import Settings from 'Event/template/Lefty/Dashboard/Hero/HeroConfig/Settings'
import Styling from 'Event/template/Lefty/Dashboard/Hero/HeroConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAddTranslation} from 'Event/LanguageProvider'

export function HeroConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useLeftyUpdate()
  const {handleSubmit, control, register, watch, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Lefty>()
  const addTranslation = useAddTranslation()

  const save = ({
    heroImageSize,
    welcomeText,
    heroImage,
    welcomeTextFont,
    welcomeTextColor,
    welcomeTextFontSize,
    welcomeTextAlign,
  }: any) => {
    pruneAssets({heroImage}, saved)

    update({
      welcomeText: '{{welcome_text}}',
      heroImageSize,
      heroImage,
      welcomeTextFont,
      welcomeTextColor,
      welcomeTextFontSize,
      welcomeTextAlign,
      localization: addTranslation({
        welcome_text: welcomeText,
      }),
    })

    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      title="Hero"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
