import Published, {Publishable} from 'Event/Dashboard/editor/views/Published'
import styled from 'styled-components'
import React from 'react'
import {HasRules} from 'Event/attendee-rules'
import {getDiffDatetime, now} from 'lib/date-time'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {HashMap} from 'lib/list'
import {withDefaults} from 'lib/object'
import {DeepRequired} from 'lib/type-utils'
import {blogPostDefaults as baseBlogPostDefaults} from 'Event/Dashboard/components/BlogPosts/AddBlogPostButton'
import {useState} from 'react'
import {useTemplate} from 'Event/TemplateProvider'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import PostFormStylesConfig from 'Event/Dashboard/components/BlogPosts/PostFormStylesConfig'
import PostStylesConfig from 'Event/Dashboard/components/BlogPosts/PostStylesConfig'
import AddBlogPostButton from 'Event/Dashboard/components/BlogPosts/AddBlogPostButton'
import {EditPost} from 'Event/Dashboard/components/BlogPosts/BlogPostConfig'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled, {HasSchedule} from 'lib/ui/layout/Scheduled'
import {useLoadFont} from 'lib/FontSelect'
import {Template, getTemplateExport} from 'Event/template'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {generateHashId} from 'lib/crypto/hash'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export type BlogPost = Publishable &
  HasRules &
  Pick<HasSchedule, 'showingUntil'> & {
    title: string
    postedAt: string
    publishAt: string | null
    content: string
    formId?: number | null
    isModalForm?: boolean
    modalButtonText?: string
    hideDate?: boolean
    buttons: HashMap<NavButtonWithSize>
    attachment?: BlogPostAttachment
    buttonsPosition?: 'flex-right' | 'center' | 'flex-end'
    buttonsWidth?: number
    isWelcomePost?: boolean
    isPinned: boolean
  }

export type BlogPostProps = {
  post: BlogPost
  isLast: boolean
  postId: string
}

export function BlogPostList(props: {
  children: (props: BlogPostProps) => JSX.Element
}) {
  const template = useTemplate()
  const {blogPosts: posts, postStyles} = template
  const [editing, setEditing] = useState<string | null>(null)

  const sortedIds = getSortedIds(posts)
  useLoadFont(postStyles.titleFont)

  return (
    <div>
      <EditPost id={editing} onClose={() => setEditing(null)} />
      <EditModeOnly>
        <PostStylesConfig />
        <PostFormStylesConfig />
        <StyledAddBlogPostButton />
      </EditModeOnly>
      {sortedIds.map((id, index) => {
        const post = posts[id]

        const isLast = index === sortedIds.length - 1

        return (
          <Editable key={id} onEdit={() => setEditing(id)}>
            <Published component={post}>
              <VisibleOnMatch rules={post.rules}>
                <Scheduled
                  component={{
                    showingFrom: post.publishAt,
                    showingUntil: post.showingUntil,
                  }}
                >
                  {props.children({
                    isLast,
                    post,
                    postId: id,
                  })}
                </Scheduled>
              </VisibleOnMatch>
            </Published>
          </Editable>
        )
      })}
    </div>
  )
}

/**
 * Blog post can have attachments that come after the text. There should
 * only be one type of attachment, ie. either shows a form, or
 * buttons, but not both.
 */

export const FORM = 'form'
export const BUTTONS = 'buttons'
export type BlogPostAttachment = typeof FORM | typeof BUTTONS

/**
 * Checks whether a post should be published (visible), depending
 * on the post's 'publishAt' property.
 * @param post
 * @returns
 */
export function shouldPublish(post: BlogPost) {
  if (!post.publishAt) {
    return true
  }

  // is past publish date
  return getDiffDatetime(post.publishAt, now()) < 0
}

/**
 * Returns an array of sorted BlogPost ids.
 *
 * @param posts
 * @returns string[]
 */
export function getSortedIds(posts: Record<string, BlogPost>): string[] {
  return Object.entries(posts)
    .sort(([_aId, aPost], [_bId, bPost]) => {
      const getDate = (post: BlogPost) => {
        return post.publishAt || post.postedAt
      }

      const isNewer = getDiffDatetime(getDate(aPost), getDate(bPost)) > 0

      // Sort pinned post first
      if (aPost.isPinned && !bPost.isPinned) {
        return -1
      }

      if (!aPost.isPinned && bPost.isPinned) {
        return 1
      }

      // newer first
      if (isNewer) {
        return -1
      }

      return 1
    })
    .map(([id]) => id)
}

export function useUpdatePost<T extends BlogPost>() {
  const updateTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  return (id: string, updated: Partial<T>) => {
    const componentId = `blogpost_${id}`

    const title = replaceAtPath(updated, 'title', `{{${componentId}_title}}`)
    const content = replaceAtPath(
      updated,
      'content',
      `{{${componentId}_content}}`,
    )

    updateTemplate({
      blogPosts: {
        [id]: updated,
      },
      localization: addTranslation({
        [`${componentId}_title`]: title ?? '',
        [`${componentId}_content`]: content ?? '',
      }),
    })
  }
}

export function useInsertPost<T extends BlogPost>() {
  const updateTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  return (post: T) => {
    generateHashId([
      'blog',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `blogpost_${id}`

      updateTemplate({
        blogPosts: {
          [id]: {
            ...post,
            title: `{{${componentId}_title}}`,
            content: `{{${componentId}_content}}`,
          },
        },
        localization: addTranslation({
          [`${componentId}_title`]: post.title,
          [`${componentId}_content`]: post.content,
        }),
      })
    })
  }
}

export function useRemovePost() {
  const updateTemplate = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const template = useTemplate()

  return (id: string) => {
    const post = template.blogPosts[id]

    const translatedButtonFields = Object.keys(post.buttons).map((buttonId) => {
      return `${buttonId}_text`
    })

    updateTemplate({
      blogPosts: {
        [id]: REMOVE,
      },
      localization: removeTranslations([
        `${id}_title`,
        `${id}_content`,
        ...translatedButtonFields,
      ]),
    })
  }
}

export function fillBlogPostDefaults<T extends BlogPost>(
  blogPosts: HashMap<T>,
  templateName: Template['name'],
) {
  const templateDefaults = getTemplateExport(templateName, 'blogPostDefaults')
  const defaults = templateDefaults ?? baseBlogPostDefaults

  return Object.entries(blogPosts).reduce((acc, [id, post]) => {
    acc[id] = withDefaults(defaults, post) as DeepRequired<T>
    return acc
  }, {} as DeepRequired<HashMap<T>>)
}

const StyledAddBlogPostButton = styled(AddBlogPostButton)`
  margin-bottom: ${(props) => props.theme.spacing[5]}!important;
`
