import {useAttendeeVariables} from 'Event'

/**
 * Returns the URL for a given resource.
 *
 * @param resource
 * @returns
 */
export function useAgendaUrl(agenda: {link: string | null}): string {
  const v = useAttendeeVariables()
  if (agenda.link) {
    return v(agenda.link)
  }
  return ''
}
