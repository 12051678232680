import React from 'react'
import styled from 'styled-components'
import SidebarNavButton from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/SidebarNav/SidebarNavButton'
import NewSidebarNavButton from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/SidebarNav/NewSidebarNavButton'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {RemoveButton} from 'organization/Event/DashboardConfig/ConfigDialog'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Section from 'Event/template/Lefty/Dashboard/Sidebar/Section'
import Scheduled from 'lib/ui/layout/Scheduled'
import Grid from '@material-ui/core/Grid'
import {SidebarNavProps, SIDEBAR_NAV} from 'Event/template'

export const createSidebarNav = (): SidebarNavProps => ({
  type: SIDEBAR_NAV,
  buttons: {},
})

export default function SidebarNav(props: SidebarNavProps) {
  return (
    <Section>
      <Content {...props} />
    </Section>
  )
}

function Content(props: SidebarNavProps) {
  const isEditMode = useEditMode()
  const {buttons} = props

  const hasButtons = Object.keys(buttons).length > 0
  if (!hasButtons) {
    return (
      <EditModeOnly>
        <EmptyConfig {...props} />
      </EditModeOnly>
    )
  }

  const ids = orderedIdsByPosition(buttons)
  const buttonComponents = ids.map((id, index) => {
    const button = buttons[id]
    return (
      <VisibleOnMatch rules={button.rules} key={id}>
        <Scheduled component={button}>
          <StyledGrid item xs={12} md={button.size}>
            <SidebarNavButton
              {...button}
              nav={props}
              id={id}
              backgroundColor={button.backgroundColor}
              hoverBackgroundColor={button.hoverBackgroundColor}
              textColor={button.textColor}
              borderWidth={button.borderWidth}
              borderRadius={button.borderRadius}
              borderColor={button.borderColor}
              index={index}
            />
          </StyledGrid>
        </Scheduled>
      </VisibleOnMatch>
    )
  })

  if (!isEditMode) {
    return <>{buttonComponents}</>
  }

  return <DraggableList {...props}>{buttonComponents}</DraggableList>
}

function EmptyConfig(props: SidebarNavProps) {
  return (
    <>
      <RemoveSidebarNavButton />
      <StyledNewSidebarNavButton nav={props} />
    </>
  )
}
function DraggableList(
  props: SidebarNavProps & {children: React.ReactElement[]},
) {
  const handleDrag = useHandleDrag(props)

  return (
    <>
      <RemoveSidebarNavButton />
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="sidebar_nav_buttons">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props.children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <StyledNewSidebarNavButton nav={props} />
      </DragDropContext>
    </>
  )
}

function RemoveSidebarNavButton() {
  const {removeList} = useEditSidebarItem()

  return (
    <RemoveButton onClick={() => removeList([])} size="large">
      Remove Buttons
    </RemoveButton>
  )
}

function useHandleDrag(props: SidebarNavProps) {
  const {update} = useEditSidebarItem()

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(props.buttons)
    const [removed] = ids.splice(source.index, 1)
    ids.splice(destination.index, 0, removed)

    update({
      buttons: createPositions(ids),
    })
  }
}

const StyledNewSidebarNavButton = styled(NewSidebarNavButton)`
  margin-top: ${(props) => props.theme.spacing[6]} !important;
  margin-bottom: ${(props) => props.theme.spacing[5]}!important;
`

const StyledGrid = styled(Grid)`
  margin: 0 auto;
`
