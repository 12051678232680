import React from 'react'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import PageHeader from 'lib/ui/PageHeader'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import Title from 'lib/ui/PageHeader/Title'
import Page from 'organization/Event/Page'
import TicketFilter from 'organization/Marketplace/Tickets/TicketFilter'
import TicketListing from 'organization/Marketplace/Tickets/TicketListing'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {useEventTickets} from 'lib/marketplace-api/tickets/use-event-tickets'
import {useSetTicketPositions} from 'lib/marketplace-api/tickets/use-position-tickets'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'

export default function Tickets() {
  const eventRoutes = useEventRoutes()
  const {data: tickets} = useEventTickets()

  const setTicketPositions = useSetTicketPositions()

  const handlePositionTickets = (result: DropResult) => {
    const {destination, source} = result
    if (!tickets || !destination || source.index === destination?.index) {
      return
    }

    const moved = Array.from(tickets)
    const [removed] = moved.splice(source.index, 1)
    moved.splice(destination.index, 0, removed)

    setTicketPositions.mutate(moved)
  }

  return (
    <PageBreadcrumbs page="Tickets">
      <Page>
        <PageHeader>
          <Title text="Marketplace Tickets" />
          <RelativeLink disableStyles to={eventRoutes.marketplace.tickets.add}>
            <NewItemButton
              aria-label="new ticket"
              text="New Ticket"
              color="primary"
            />
          </RelativeLink>
        </PageHeader>
        <TicketFilter />
        <DragDropContext onDragEnd={handlePositionTickets}>
          <TicketListing />
        </DragDropContext>
      </Page>
    </PageBreadcrumbs>
  )
}
