import Slider from 'lib/ui/Slider'
import ColorPicker from 'lib/ui/ColorPicker'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'

const MIN_BORDER_WIDTH = 0
const MAX_BORDER_WIDTH = 50

const MIN_BORDER_RADIUS = 0
const MAX_BORDER_RADIUS = 50

export default function Border(props: {
  control: UseFormMethods['control']
  button: NavButtonWithSize
}) {
  const {control, button} = props

  return (
    <CollapsibleSection
      label="Border"
      storageKey="sidebar.sidebarNavButtonConfig.styling.border"
    >
      <Controller
        name="borderColor"
        control={control}
        defaultValue={button.borderColor || '#000000'}
        render={({value, onChange}) => (
          <ColorPicker label="Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="hoverBorderColor"
        control={control}
        defaultValue={button.hoverBorderColor || '#000000'}
        render={({value, onChange}) => (
          <ColorPicker label="Hover Color" color={value} onPick={onChange} />
        )}
      />
      <Controller
        name="borderWidth"
        control={control}
        defaultValue={button.borderWidth || 0}
        render={({value, onChange}) => (
          <Slider
            min={MIN_BORDER_WIDTH}
            max={MAX_BORDER_WIDTH}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Width"
          />
        )}
      />
      <Controller
        name="borderRadius"
        control={control}
        defaultValue={button.borderRadius || 0}
        render={({value, onChange}) => (
          <Slider
            min={MIN_BORDER_RADIUS}
            max={MAX_BORDER_RADIUS}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            label="Radius"
          />
        )}
      />
    </CollapsibleSection>
  )
}
