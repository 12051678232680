import React, {useCallback, useEffect, useReducer, useRef} from 'react'
import {Button, NavButtonProps} from 'Event/Dashboard/components/NavButton'
import {useAttendeeVariables} from 'Event'
import {useEvent} from 'Event/EventProvider'
import {useEditMode} from 'Event/EditModeProvider'
import {Certificate} from 'lib/event-api/certificates/types'
import {api} from 'lib/url'
import {
  DEFAULT_SNACKBAR_SUCCESS_BACKGROUND_COLOR,
  DEFAULT_SNACKBAR_SUCCESS_COLOR,
  useSnackbar,
} from 'lib/ui/SnackbarProvider'
import {FileLocation, downloadFile} from 'lib/http-client'

const readyPollIntervalMs = 3000

export const defaultEmailSentText =
  'Your certificate will be sent to your email'

export const defaultGeneratingCertificateText = 'Generating..'

export default function CertificateButton(props: NavButtonProps) {
  const {certificateId} = props
  const {event} = useEvent()
  const isEditMode = useEditMode()

  const certificate = event.certificates.find((certificate) => {
    if (!certificateId) {
      return false
    }

    return certificate.id === parseInt(certificateId)
  })
  if (!certificate) {
    return <Button {...props} />
  }

  if (isEditMode) {
    return <Button {...props} />
  }

  return <DownloadButton certificate={certificate} button={props} />
}

interface DownloadState {
  generating: boolean
  ready: boolean
  downloading: boolean
  completed: boolean
}

function DownloadButton(props: {
  certificate: Certificate
  button: NavButtonProps
}) {
  const {client} = useEvent()
  const {certificate, button} = props
  const {id: certificateId, title: certificateTitle} = certificate

  const downloadPollTimer = useRef(0)

  const [downloadState, setDownloadState] = useReducer(
    (state: DownloadState, action: Partial<DownloadState>) => ({
      ...state,
      ...action,
    }),
    {
      generating: false,
      ready: false,
      downloading: false,
      completed: false,
    },
  )

  const {generating, ready, downloading, completed} = downloadState

  const {enqueueSnackbar, setBackgroundColor, setTextColor} = useSnackbar()

  const blocks = certificate.template?.blocks || {}

  const v = useAttendeeVariables()
  const ids = Object.keys(blocks)

  const download = useCallback(async () => {
    if (!ready) {
      return
    }

    if (completed) {
      return
    }

    if (downloading) {
      return
    }

    setDownloadState({
      downloading: true,
    })

    const {url} = await client.get<{url: string | null}>(
      api(`/certificates/${certificateId}/download`),
    )

    if (!url) {
      return
    }

    downloadFile(url, `${certificateTitle}.pdf`).then(() => {
      setDownloadState({
        downloading: false,
        completed: true,
      })
    })
  }, [ready, downloading, client, certificateId, certificateTitle, completed])

  // Set up dl pooll timer
  useEffect(() => {
    const timerRef = downloadPollTimer.current

    if (!ready) {
      return
    }

    if (completed) {
      clearInterval(timerRef)
      return
    }

    downloadPollTimer.current = window.setInterval(
      download,
      readyPollIntervalMs,
    )
    return () => {
      clearInterval(timerRef)
    }
  }, [ready, download, completed])

  const generate = () => {
    if (ready) {
      setDownloadState({
        completed: false,
      })
      return
    }

    if (generating) {
      return
    }

    // Need to set the snackback colors each time in case we have
    // multiple buttons that each have different snackback
    // color values.
    setBackgroundColor(
      button.certificate?.emailSentBackgroundColor ??
        DEFAULT_SNACKBAR_SUCCESS_BACKGROUND_COLOR,
    )
    setTextColor(
      button.certificate?.emailSentTextColor ?? DEFAULT_SNACKBAR_SUCCESS_COLOR,
    )

    setDownloadState({
      generating: true,
    })

    ids.forEach((id) => {
      blocks[id] = {
        ...blocks[id],
        text: v(blocks[id].text),
      }
    })
    const url = api(`/certificates/${certificate.id}/download`)
    client
      .post<FileLocation[]>(url, {
        template: {
          blocks,
        },
      })
      .then(() => {
        setDownloadState({
          ready: true,
        })
        const alertText =
          button.certificate?.emailSentText ?? defaultEmailSentText
        enqueueSnackbar(v(alertText), {variant: 'success'})
      })
      .finally(() => {
        setDownloadState({
          generating: false,
        })
      })
  }

  const busy = generating || (ready && !completed)

  const generatingText =
    button.certificate?.generatingText ?? defaultGeneratingCertificateText
  const generatingImage = button.certificate?.generatingImage ?? undefined // if null => undefined

  const buttonImage = busy ? generatingImage : undefined
  const firstRowText = busy ? v(generatingText) : button.text
  const secondRowText = busy ? '' : button.textSecond

  const backgroundColor = busy
    ? button.hoverBackgroundColor
    : button.backgroundColor
  const textColor = busy ? button.hoverTextColor : button.textColor

  return (
    <Button
      {...button}
      text={firstRowText}
      textSecond={secondRowText}
      image={buttonImage}
      onClick={generate}
      disabled={busy}
      backgroundColor={backgroundColor ?? button.backgroundColor}
      textColor={textColor ?? button.textColor}
    />
  )
}
