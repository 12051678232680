import React, {useState} from 'react'
import {useDuplicateAssets} from 'organization/Event/SelectTemplatePage'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import TemplateCard from 'organization/Marketplace/config/TemplateSelector/TemplateCard'
import {EventWithThankYouPage} from 'lib/marketplace-api/thank-you-page/use-thank-you-page-events'
import {ThankYouPage} from 'Event/Marketplace/thank-you-page'

const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

interface TemplateCardProps {
  event: EventWithThankYouPage
  updateTemplate: UpdateTemplateFunction
}

export default function ThankYouPageEventTemplateCard(
  props: TemplateCardProps,
) {
  const {event, updateTemplate} = props

  const withCopiedAssets = useDuplicateAssets<ThankYouPage['template']>()

  const [loading, setLoading] = useState(false)

  const onSelect = () => {
    setLoading(true)

    withCopiedAssets(event.thankYouPageTemplate).then((template) => {
      setLoading(false)
      updateTemplate(template)
    })
  }

  const onViewThankYouPage = () => {
    window.open(`${marketplaceStoreUrl}/${event.slug}/thank_you`, '_blank')
  }

  return (
    <TemplateCard
      event={event}
      updateTemplate={updateTemplate}
      onSelect={onSelect}
      loading={loading}
      pageLabel="Thank You Page"
      onView={onViewThankYouPage}
    />
  )
}
