import SignUp from 'Event/auth/SignUp'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/TemplateEditor/Page'
import React from 'react'
import SignUpEnabledSwitch from 'Event/auth/SignUp/SignUpEnabledSwitch'
import Button, {ButtonProps} from 'lib/ui/Button'
import {useEvent} from 'Event/EventProvider'
import {ConfigContext} from 'organization/Event/Configurable'
import {useToggleArray} from 'lib/toggle'
import {useForm} from 'react-hook-form'
import PageSettings from 'Event/auth/SignUp/PageSettings'
import {Link} from 'react-router-dom'
import {ObvioEvent} from 'Event'
import {getEventUrl} from 'lib/url'
import styled from 'styled-components'
import {eventRoutes} from 'Event/Routes'

interface PageSettingsButtonProps extends Omit<ButtonProps, 'children'> {}

export default function SignUpConfig() {
  const {event} = useEvent()
  const [showingConfig, toggleConfig] = useToggleArray()
  const isSignUpEnabled = event.sign_up_enabled
  const form = useForm()

  return (
    <DesignBreadcrumbs page="Sign Up">
      <Page
        toolbarItems={
          <>
            <SignUpEnabledSwitch />
            <PageSettingsButton
              onClick={toggleConfig}
              disabled={!isSignUpEnabled}
            />
            <SignUpUrl event={event} show={isSignUpEnabled} />
          </>
        }
      >
        <ConfigContext.Provider
          value={{showing: showingConfig, toggle: toggleConfig, form}}
        >
          <PageSettings />
          <SignUp />
        </ConfigContext.Provider>
      </Page>
    </DesignBreadcrumbs>
  )
}

function SignUpUrl(props: {event: ObvioEvent; show: boolean}) {
  const {event, show} = props

  if (!show) return null

  const eventUrl = getEventUrl(event)
  const signUpUrl = eventRoutes.signUp

  const url = `${eventUrl}${signUpUrl}`

  return (
    <StyledLink to={{pathname: url}} target="_blank">
      {url}
    </StyledLink>
  )
}

function PageSettingsButton(props: PageSettingsButtonProps) {
  return (
    <Button
      variant="outlined"
      fullWidth
      color="primary"
      size="small"
      aria-label="page settings"
      {...props}
    >
      Page Settings
    </Button>
  )
}

const StyledLink = styled(Link)`
  margin-left: 1.25rem;
  color: ${(props) => props.theme.colors.primary};
`
