import React from 'react'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import Settings from 'Event/template/NiftyFifty/Leaderboard/LeaderboardConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function LeaderboardConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const {control, register, handleSubmit, formState} = useForm()
  const updateTemplate = useNiftyFiftyUpdate()
  const addTranslation = useAddTranslation()

  const submit = (data: {
    title: string
    description: string
    points_unit: string
    menuTitle: string
    isVisible: boolean
  }) => {
    const {
      points_unit,
      title,
      description,
      menuTitle,
      ...leaderboardData
    } = data

    updateTemplate({
      localization: addTranslation({
        leaderboard_title: title,
        leaderboard_description: description,
        leaderboard_menuTitle: menuTitle,
        leaderboard_points_unit: points_unit,
      }),
      leaderboard: {
        ...leaderboardData,
        title: '{{leaderboard_title}}',
        description: '{{leaderboard_description}}',
        menuTitle: '{{leaderboard_menuTitle}}',
      },
      points_unit: '{{points_unit}}',
    })

    props.onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Leaderboard"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
