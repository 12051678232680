import {useDelay} from './use-delay'
import {useOnResize} from './use-on-resize'
import {useCallback, useEffect, useMemo, useState} from 'react'
import ReactGridLayout, {Layouts} from 'react-grid-layout'
import {Subject, Subscription} from 'rxjs'
import {debounceTime} from 'rxjs/operators'

interface useResizedLayoutsParams {
  layoutsObject: Layouts
  /**
   * Flag to manually force a re-render.
   */
  shouldRerender?: boolean
  rowHeight?: number
  blockSpacing?: number
  onSizeInit?: () => void
  breakpoint?: string | null
}

export function useResizedLayouts(params: useResizedLayoutsParams) {
  const {
    layoutsObject,
    shouldRerender,
    rowHeight = 10,
    blockSpacing = 10,
    onSizeInit,
    breakpoint,
  } = params

  const [resizedLayouts, setResizedLayouts] = useState(layoutsObject)
  const [initialized, setInitialized] = useState(false)
  const [
    resizeSubscription,
    setResizeSubscription,
  ] = useState<Subscription | null>(null)
  const layoutsString = JSON.stringify(resizedLayouts)

  const subject = useMemo(() => new Subject<string>(), [])

  const calculateResize = useCallback(() => {
    subject.next(layoutsString)
  }, [subject, layoutsString])

  useEffect(() => {
    if (!resizeSubscription) {
      return
    }

    calculateResize()
  }, [shouldRerender, calculateResize, resizeSubscription])

  const initSize = useCallback(() => {
    // Wait for breakpoint to be set if one is required
    if (breakpoint === null) {
      return
    }

    if (initialized) {
      return
    }

    setResizedLayouts(
      resizeLayout(JSON.parse(layoutsString), rowHeight, blockSpacing),
    )
    if (!initialized) {
      setInitialized(true)
    }
  }, [blockSpacing, initialized, rowHeight, layoutsString, breakpoint])
  useDelay(initSize, 25) // delay to make sure things DOM is rendered
  useOnResize(calculateResize)

  useEffect(() => {
    if (initialized && onSizeInit) {
      onSizeInit()
    }
  }, [initialized, onSizeInit])

  useEffect(() => {
    // Debounce to avoid race conditions on resize
    const subscription = subject.pipe(debounceTime(150)).subscribe({
      next: (val) => {
        setResizedLayouts(
          resizeLayout(JSON.parse(val), rowHeight, blockSpacing),
        )
      },
    })

    setResizeSubscription(subscription)

    return () => {
      subscription.unsubscribe()
    }
  }, [blockSpacing, rowHeight, subject])

  return resizedLayouts
}

export function resizeLayout(
  layouts: Layouts,
  rowHeight: number,
  blockSpacing: number,
) {
  const result: Layouts = {}
  for (const [key, breakpointLayouts] of Object.entries(layouts)) {
    const resizedBreakpoints: ReactGridLayout.Layout[] = []

    for (const layout of breakpointLayouts) {
      const {i} = layout

      const el = document.getElementById(i)

      if (!el) {
        continue
      }

      const content = getResizeContent(el)
      if (!content) {
        continue
      }

      const contentHeight = getContentHeight(el)

      const blockHeight =
        rowHeight * layout.h + blockSpacing * layout.h - blockSpacing * 1

      const unitHeight = rowHeight + blockSpacing - blockSpacing / layout.h

      const contentIsVerticallyClipped = contentHeight > blockHeight

      const minHeight = Math.ceil(contentHeight / unitHeight)

      const height = contentIsVerticallyClipped ? minHeight : layout.h

      resizedBreakpoints.push({...layout, h: height})
    }

    result[key] = resizedBreakpoints
  }

  return result
}

function getResizeContent(el: HTMLElement) {
  // If a block has specified a different element to be the content
  // then use that for sizing. eg. PurchaseForm has different
  // content sizes per step.
  const contentBlock = el.querySelector('.block-content')
  if (contentBlock) {
    return contentBlock
  }

  return el.firstElementChild
}

function getContentHeight(el: HTMLElement) {
  // If a block has specified a different content element, calculate
  // it's height as well as any spacing that the container imposes.
  // eg. container padding / border.
  const contentEl = el.querySelector('.block-content')
  if (contentEl) {
    const borderWidth = parseInt(el.style.borderWidth)
    const paddingTop = parseInt(el.style.paddingTop)
    const paddingBottom = parseInt(el.style.paddingBottom)

    const containerSpacing = borderWidth * 2 + paddingTop + paddingBottom

    return contentEl.clientHeight + containerSpacing
  }

  return el.clientHeight
}
