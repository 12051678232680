import React from 'react'
import TextField, {TextFieldProps} from '../../lib/ui/TextField'
import {BlockComponentProps} from '../index'
import {PlaceholderType, PurchaseFormBlock} from '../PurchaseForm'
import styles from './PurchaseForm.module.css'

export interface PurhaseFormTextFieldProps extends TextFieldProps {
  config: PurchaseFormBlock & BlockComponentProps
  placeholder: PlaceholderType
}

export default function PurhaseFormTextField(
  props: TextFieldProps & {
    config: PurchaseFormBlock
    placeholder: PlaceholderType
  },
) {
  const {config, placeholder} = props
  const className = `${styles.purchaseFormTextField} ${
    props.error ? 'has-error' : ''
  }`

  return (
    <>
      <style>
        {`
        .${styles.purchaseFormTextField} input {
          margin-bottom: unset;
          background-color: ${props.config.input.backgroundColor};
          border: ${props.config.input.border.width}px solid;
          border-color: ${props.config.input.border.color};
          border-radius: ${props.config.input.border.radius}px;
          padding-left: ${props.config.input.padding.left}px;
          padding-right: ${props.config.input.padding.right}px;
          padding-top: ${props.config.input.padding.top}px;
          padding-bottom: ${props.config.input.padding.bottom}px;
          font-size: ${props.config.input.fontSize}px;
          width: 100%;
          height: auto !important;
          outline: none;
        }

        .${styles.purchaseFormTextField} input:disabled {
          cursor: not-allowed;
          background: #e7e7e7!important;
          color: #c4c4c4!important;
        }

        .${styles.purchaseFormTextField}.has-error input {
          border-color: #f44336;
        }
        `}
      </style>
      <div
        style={{
          marginBottom: config.input.spacing + 'px',
        }}
      >
        <TextField
          {...props}
          className={className}
          placeholder={config.input.placeholder[placeholder]}
          inputProps={{
            disabled: props.disabled,
          }}
        />
      </div>
    </>
  )
}
