import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import styled from 'styled-components'

export default function HeaderConfig() {
  const saveTemplate = useSaveTemplate()

  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const title = replaceAtPath(data, 'sponsors.title', '{{sponsors_title}}')
    const description = replaceAtPath(
      data,
      'sponsors.description',
      '{{sponsors_description}}',
    )
    const menuTitle = replaceAtPath(
      data,
      'sponsors.menuTitle',
      '{{sponsors_menu_title}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        sponsors_title: title ?? '',
        sponsors_description: description ?? '',
        sponsors_menu_title: menuTitle ?? '',
      }),
    })
  }

  return (
    <Config title="Header" onSave={save}>
      <SettingsPanel>
        <Settings />
      </SettingsPanel>
    </Config>
  )
}

function MenuTitleConfig() {
  const {
    form: {register},
  } = useConfig()

  const {sponsors} = useTemplate()
  const v = useAttendeeVariables()

  if (!('menuTitle' in sponsors)) {
    return null
  }

  return (
    <>
      <Title>Menu</Title>
      <TextField
        name="sponsors.menuTitle"
        defaultValue={v(sponsors.menuTitle) || 'Our Sponsors'}
        label="Text"
        fullWidth
        inputProps={{
          'aria-label': 'menu title',
          ref: register,
        }}
      />
    </>
  )
}

export function Settings() {
  const {sponsors} = useTemplate()

  const {
    form: {register, control},
  } = useConfig()

  const v = useAttendeeVariables()

  return (
    <>
      <MenuTitleConfig />
      <Title>Page</Title>
      <TextField
        name="sponsors.title"
        defaultValue={v(sponsors.title)}
        label="Title"
        fullWidth
        inputProps={{
          'aria-label': 'sponsors title',
          ref: register,
        }}
      />
      <InputLabel>Description</InputLabel>
      <StyledTextEditorContainer>
        <Controller
          name="sponsors.description"
          defaultValue={v(sponsors.description)}
          control={control}
          render={({value, onChange}) => (
            <TextEditor data={value} onChange={onChange} />
          )}
        />
      </StyledTextEditorContainer>
    </>
  )
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
