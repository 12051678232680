import {AND, Rule} from 'Event/attendee-rules'
import styled from 'styled-components'
import React, {useState} from 'react'
import Button from 'lib/ui/Button'
import RuleForm from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm'
import {TAGS} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import {GROUP} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import {RuleValue} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule'
import ConditionSelector from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/ConditionSelector'

export type Filter = {
  index: number
  rule: Rule | null
}

export default function FilterList(props: {
  filters?: Filter[]
  close?: () => void
  onChange: (rule: Filter[]) => void
  className?: string
  disabled?: boolean
}) {
  const [filters, setFilters] = useState<Filter[]>(props.filters ?? [])
  const [lastUsedCondition, setLastUsedCondition] = useState<Rule['condition']>(
    props.filters ? props.filters[0]?.rule?.condition ?? AND : AND,
  )
  const addNewFilter = () => {
    setFilters([
      ...filters,
      {
        index: filters.length,
        rule: null,
      },
    ])
  }

  const updateFilters = (updates: Filter[]) => {
    props.onChange(updates)
    setFilters(updates)
  }

  const saveFilter = (filter: Filter) => {
    const updated = filters.map((f, i) => (i === filter.index ? filter : f))
    updateFilters(updated)
  }

  const updateCondition = (condition: Rule['condition']) => {
    const updated = filters.map((f) => {
      if (!f.rule) {
        return f
      }

      return {
        ...f,
        rule: {
          ...f.rule,
          condition: condition,
        },
      }
    })
    updateFilters(updated)
    setLastUsedCondition(condition)
  }

  const deleteFilter = (filter: Filter) => {
    const removed = filters.filter((_, i) => i !== filter.index)
    updateFilters(removed)
  }

  return (
    <div className={props.className}>
      <Filters
        filters={filters}
        saveFilter={saveFilter}
        deleteFilter={deleteFilter}
        updateCondition={updateCondition}
        usedCondition={lastUsedCondition}
      />
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={addNewFilter}
        aria-label="add rule"
        disabled={props.disabled}
      >
        Add Filter
      </Button>
    </div>
  )
}

function Filters(props: {
  className?: string
  filters: Filter[]
  saveFilter: (filter: Filter) => void
  deleteFilter: (filter: Filter) => void
  updateCondition: (condition: Rule['condition']) => void
  usedCondition: Rule['condition']
}) {
  const hasFilters = props.filters.length > 0

  if (!hasFilters) {
    return <EmptyFiltersText>No filters have been added</EmptyFiltersText>
  }

  return (
    <FiltersContainer>
      {props.filters.map((filter, index) => (
        <FilterItem
          key={filter.index}
          filter={filter}
          isFirst={index === 0}
          save={props.saveFilter}
          delete={props.deleteFilter}
          updateCondition={props.updateCondition}
          usedCondition={props.usedCondition}
        />
      ))}
    </FiltersContainer>
  )
}

function FilterItem(props: {
  filter: Filter
  save: (filter: Filter) => void
  delete: (filter: Filter) => void
  updateCondition: (condition: Rule['condition']) => void
  usedCondition: Rule['condition']
  isFirst: boolean
}) {
  const {filter} = props
  const [showingRuleForm, setShowingRuleForm] = useState(false)

  const updateCondition = (condition: Rule['condition']) => {
    props.updateCondition(condition)
  }

  const onDelete = () => {
    props.delete(filter)
    setShowingRuleForm(false)
  }

  const onCreate = (rule: Rule) => {
    const attachCondition: Rule = {
      ...rule,
      condition: props.usedCondition,
    }

    props.save({
      ...filter,
      rule: attachCondition,
    })
    setShowingRuleForm(false)
  }

  const onClose = () => {
    if (!filter.rule) {
      props.delete(filter)
    }

    setShowingRuleForm(false)
  }

  if (showingRuleForm || !filter.rule) {
    return (
      <RuleForm
        close={onClose}
        onDelete={onDelete}
        onCreate={onCreate}
        rule={filter.rule}
        sources={[TAGS, GROUP]}
        description="Rule"
      />
    )
  }

  return (
    <Box>
      <StyledConditionSelector
        visible={!props.isFirst}
        onSelect={updateCondition}
        rule={filter.rule}
      />
      <FilterContainer onClick={() => setShowingRuleForm(true)}>
        <RuleValue rule={filter.rule} />
      </FilterContainer>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
`
const StyledConditionSelector = styled(ConditionSelector)`
  margin-right: ${(props) => props.theme.spacing[4]}!important;
`

const FiltersContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`

const EmptyFiltersText = styled.p`
  margin: ${(props) => props.theme.spacing[4]} 0;
  text-align: center;
`

const FilterContainer = styled.div`
  flex: 1;
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[4]}`};
  border: 1px solid ${(props) => props.theme.colors.border};
  margin-bottom: ${(props) => props.theme.spacing[3]};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.menu.itemHoverBackground};
  }
`
