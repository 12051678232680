import {PlatformActions} from 'Event/ActionsProvider/platform-actions'
import {useAttendee} from 'Event/auth'
import Dashboard from 'Event/Dashboard'
import PagePoints, {DASHBOARD} from 'Event/PointsProvider/PagePoints'
import {Template} from 'Event/template'
import {FileLocation} from 'lib/http-client'
import React, {useCallback} from 'react'
import {Form} from 'organization/Event/FormsProvider'
import {
  useUserTranslations,
  useGuestTranslations,
} from 'Event/LanguageProvider/translations'
import {useWithAttendeeData, useWithPoints} from 'Event/auth/attendee-data'
import {pipe} from 'ramda'
import {useRemoveVariables, useReturnFallback} from 'lib/template'
import {CustomTicketRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import {useEditMode} from 'Event/EditModeProvider'
import {Certificate} from 'lib/event-api/certificates/types'

// Can't use 'Event' because that's already a native DOM type
// for browser events and we'd lose TS safety/import assist.
export interface ObvioEvent {
  updated_at: string
  id: number
  name: string
  slug: string
  system_domain_id: number | null
  start: string
  end: string
  description: string
  has_ended: boolean
  num_expected_attendees: number
  num_registered_attendees: number
  template: Template | null
  logo: FileLocation | null
  platform_actions: PlatformActions
  favicon: FileLocation | null
  forms: Form[]
  certificates: Certificate[]
  has_infusionsoft: boolean
  has_active_campaign: boolean
  domains: Domain[]
  ticket_ribbons: CustomTicketRibbon[]
  is_online: boolean
  has_started: boolean
  has_mailchimp: boolean
  has_zapier: boolean
  has_hubspot: boolean
  has_highlevel: boolean
  has_convert_kit: boolean
  has_ontraport: boolean
  is_live: boolean
  webhook_url: string | null
  webhook_access_token_id: number | null
  has_webhook_crc_salt: boolean
  allows_additional_waivers: boolean
  default_attendee_password: string | null
  timezone: string | null
  sign_up_enabled: boolean
  turn_off_actions_date: string | null
  allows_email_only_login: boolean
  is_copying: boolean
}

export interface Domain {
  id: number
  url: string
}

export default function Event() {
  const attendee = useAttendee()

  // We fetch the user, and split the user from the attendee profile to allow
  // stubbing out data for org users while configuring dashboard.
  return (
    <PagePoints page={DASHBOARD}>
      <Dashboard user={attendee} />
    </PagePoints>
  )
}

/**
 * Attendee variables will replace known {{ variables }} for
 * a given attendee.
 *
 * @returns
 */
export function useAttendeeVariables() {
  const ifLive = useIfLive()
  const withTranslations = useUserTranslations()
  const withAttendeeData = useWithAttendeeData()
  const withPoints = useWithPoints()
  const removeVariables = useRemoveVariables()
  const returnFallback = useReturnFallback()

  return useCallback(
    (text: string = '', fallback = '') => {
      const process = pipe(
        withTranslations,
        withAttendeeData,
        withPoints,
        ifLive(removeVariables),
        returnFallback(fallback),
      )
      return process(text)
    },
    [
      withAttendeeData,
      withTranslations,
      withPoints,
      removeVariables,
      returnFallback,
      ifLive,
    ],
  )
}

/**
 * Guest variables don't assume the user is logged in, and
 * will only replace what is available as a guest.
 *
 * @returns
 */
export function useGuestVariables() {
  const withTranslations = useGuestTranslations()
  const removeVariables = useRemoveVariables()
  const ifLive = useIfLive()

  return useCallback(
    (text: string = '') => {
      const process = pipe(withTranslations, ifLive(removeVariables))
      return process(text)
    },
    [withTranslations, removeVariables, ifLive],
  )
}

/**
 * Helper to only apply text transformation if we are NOT in edit mode.
 */
function useIfLive() {
  const isEditMode = useEditMode()

  return useCallback(
    (transform: (text: string) => string) => (text: string) => {
      if (isEditMode) {
        return text
      }

      return transform(text)
    },
    [isEditMode],
  )
}
