import {useTownhallTemplate} from 'Event/template/Townhall'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import InputLabel from 'lib/ui/InputLabel'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {TemplateSave} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'
export default function BackgroundsHeaderConfig() {
  const save = useSaveTemplate()
  const {zoomBackgrounds} = useTownhallTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()
  const {
    form: {register, control},
  } = useConfig()

  const handleSave: TemplateSave<Template> = (data) => {
    const title = replaceAtPath(
      data,
      'zoomBackgrounds.title',
      '{{zoom_backgrounds_title}}',
    )

    const description = replaceAtPath(
      data,
      'zoomBackgrounds.description',
      '{{zoom_backgrounds_description}}',
    )

    const secondaryTitle = replaceAtPath(
      data,
      'zoomBackgrounds.secondaryTitle',
      '{{zoom_backgrounds_secondary_title}}',
    )

    save({
      ...data,
      localization: addTranslation({
        zoom_backgrounds_title: title || 'Zoom Backgrounds',
        zoom_backgrounds_description: description || '',
        zoom_backgrounds_secondary_title: secondaryTitle || '',
      }),
    })
  }
  return (
    <Config title="Header" onSave={handleSave}>
      <SettingsPanel>
        <TextField
          name="zoomBackgrounds.title"
          defaultValue={v(zoomBackgrounds.title)}
          label="Title"
          fullWidth
          inputProps={{
            'aria-label': 'zoomBackgrounds title',
            ref: register,
          }}
        />
        <TextField
          name="zoomBackgrounds.secondaryTitle"
          defaultValue={v(zoomBackgrounds.secondaryTitle)}
          label="Secondary Title"
          fullWidth
          inputProps={{
            'aria-label': 'zoomBackgrounds secondary title',
            ref: register,
          }}
        />
        <InputLabel>Description</InputLabel>
        <TextEditorContainer>
          <Controller
            name="zoomBackgrounds.description"
            defaultValue={v(zoomBackgrounds.description)}
            control={control}
            render={({value, onChange}) => (
              <TextEditor data={value} onChange={onChange} />
            )}
          />
        </TextEditorContainer>
      </SettingsPanel>
    </Config>
  )
}
