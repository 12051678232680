import {usePurchase} from '../../PurchaseContext'
import {useEffect, useState} from 'react'
import validator from 'validator'
import {useDebounce} from 'react-use'
import {useSection} from '../../Section'
import {PurchaseFormBlock} from '.'
import {UpsellPurchaseButtonBlock} from '../UpsellPurchaseButton'

/**
 * How long to wait for user to stop typing before
 * validating the email
 */
const validateEmailDelayMs = 250

export type PurchaseStep =
  | 'customer_details'
  | 'address_billing'
  | 'address_shipping'
  | 'order_confirmation'

export interface PurchaseFormData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  billing_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  shipping_address?: {
    address_1: string
    address_2?: string
    country_id: string
    state_id: string
    city: string
    zip_postal: string
  }
  payment_method_id: string | null
}

export default function usePurchaseFormFields(
  params: {
    values?: PurchaseFormData | null
  } & (PurchaseFormBlock | UpsellPurchaseButtonBlock),
) {
  const {values} = params
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [billingCountryId, setBillingCountryId] = useState(0)
  const [billingStateId, setBillingStateId] = useState(0)
  const [billingAddress1, setBillingAddress1] = useState('')
  const [billingAddress2, setBillingAddress2] = useState('')
  const [billingCity, setBillingCity] = useState('')
  const [billingZipPostal, setBillingZipPostal] = useState('')
  const [shippingCountryId, setShippingCountryId] = useState(0)
  const [shippingStateId, setShippingStateId] = useState(0)
  const [shippingAddress1, setShippingAddress1] = useState('')
  const [shippingAddress2, setShippingAddress2] = useState('')
  const [shippingCity, setShippingCity] = useState('')
  const [shippingZipPostal, setShippingZipPostal] = useState('')
  const [firstNameError, setFirstNameError] = useState<string | null>(null)
  const [lastNameError, setLastNameError] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [billingAddress1Error, setBillingAddress1Error] = useState<
    string | null
  >(null)
  const [billingCountryIdError, setBillingCountryIdError] = useState<
    string | null
  >(null)
  const [billingStateIdError, setBillingStateIdError] = useState<string | null>(
    null,
  )
  const [billingCityError, setBillingCityError] = useState<string | null>(null)
  const [billingZipPostalError, setBillingZipPostalError] = useState<
    string | null
  >(null)
  const [shippingCountryIdError, setShippingCountryIdError] = useState<
    string | null
  >(null)
  const [shippingStateIdError, setShippingStateIdError] = useState<
    string | null
  >(null)
  const [shippingAddress1Error, setShippingAddress1Error] = useState<
    string | null
  >(null)
  const [shippingCityError, setShippingCityError] = useState<string | null>(
    null,
  )
  const [shippingZipPostalError, setShippingZipPostalError] = useState<
    string | null
  >(null)

  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null)
  const [hasError, setHasError] = useState(false)
  const [step, setStep] = useState<PurchaseStep>('customer_details')
  const [isExistingAttendee, setIsExistingAttendee] = useState(false)

  const {shouldValidateEmail, eventId} = usePurchase()
  const {calculateVisibility} = useSection()

  // pre-fill email
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlEmail = urlParams.get('email')
    if (urlEmail) {
      setEmail(urlEmail)
    }
  }, [setEmail])

  // Fill values if they've been set, ie. from a Main purchase before an Upsell.

  useEffect(() => {
    if (!values) {
      return
    }

    setFirstName(values.first_name)
    setLastName(values.last_name)
    setEmail(values.email)
    setPhoneNumber(values.phone_number)
    setBillingCountryId(parseInt(values.billing_address?.country_id ?? '0'))
    setBillingStateId(parseInt(values.billing_address?.state_id ?? '0'))
    setBillingAddress1(values.billing_address?.address_1 ?? '')
    setBillingAddress2(values.billing_address?.address_2 ?? '')
    setBillingCity(values.billing_address?.city ?? '')
    setBillingZipPostal(values.billing_address?.zip_postal ?? '')
    setShippingCountryId(parseInt(values.shipping_address?.country_id ?? '0'))
    setShippingStateId(parseInt(values.shipping_address?.state_id ?? '0'))
    setShippingAddress1(values.shipping_address?.address_1 ?? '')
    setShippingAddress2(values.shipping_address?.address_2 ?? '')
    setShippingCity(values.shipping_address?.city ?? '')
    setShippingZipPostal(values.shipping_address?.zip_postal ?? '')
  }, [values])

  useDebounce(
    () => {
      if (!shouldValidateEmail) {
        return
      }
      fetch(`/api/event/${eventId}/attendee?email=${email}`)
        .then((res) => {
          if (!res.ok) {
            return {attendee: null}
          }

          return res.json()
        })
        .then((data) => {
          const {attendee} = data
          if (attendee) {
            setFirstName(attendee.first_name)
            setLastName(attendee.last_name)
            setPhoneNumber(attendee.phone_number ?? '')
            setIsExistingAttendee(true)
            calculateVisibility() // resize
          } else {
            setIsExistingAttendee(false)
          }
        })
    },
    validateEmailDelayMs,
    [email],
  )

  const [useSameAddressForShipping, setUseSameAddressForShipping] = useState(
    true,
  )

  const isEmpty = (value: string) => value.trim().length === 0

  const validateForm = () => {
    let valid = true

    if (isEmpty(firstName) && !isExistingAttendee) {
      setFirstNameError('First name is required')
      valid = false
    }
    if (isEmpty(lastName) && !isExistingAttendee) {
      setLastNameError('Last name is required')
      valid = false
    }
    if (isEmpty(email)) {
      setEmailError('Email is required')
      valid = false
    }

    if (!validator.isEmail(email)) {
      setEmailError('Incorrect email format')
      valid = false
    }

    if (
      isEmpty(phoneNumber) &&
      params.requirePhoneNumber &&
      !isExistingAttendee
    ) {
      setPhoneNumberError('Phone number is required')
      valid = false
    }

    if (!isEmpty(phoneNumber) && !validator.isMobilePhone(phoneNumber)) {
      setPhoneNumberError('Incorrect phone format')
      valid = false
    }

    setHasError(!valid)

    return valid
  }

  const validateBillingForm = () => {
    let valid = true

    setBillingAddress1Error(null)
    setBillingCountryIdError(null)
    setBillingStateIdError(null)
    setBillingCityError(null)
    setBillingZipPostalError(null)

    if (isExistingAttendee) {
      return true
    }

    if (isEmpty(billingAddress1)) {
      setBillingAddress1Error('Billing Address 1 is required')
      valid = false
    }

    if (billingCountryId === 0) {
      setBillingCountryIdError('Billing Country is required')
      valid = false
    }
    if (billingStateId === 0) {
      setBillingStateIdError('Billing State is required')
      valid = false
    }

    if (isEmpty(billingCity)) {
      setBillingCityError('Billing City is required')
      valid = false
    }

    if (isEmpty(billingZipPostal)) {
      setBillingZipPostalError('Billing Zip Postal is required')
      valid = false
    }

    setHasError(!valid)

    return valid
  }

  const validateShippingForm = () => {
    let valid = true

    setShippingAddress1Error(null)
    setShippingCountryIdError(null)
    setShippingStateIdError(null)
    setShippingCityError(null)
    setShippingZipPostalError(null)

    if (isExistingAttendee) {
      return true
    }

    if (isEmpty(shippingAddress1)) {
      setShippingAddress1Error('Shipping Address 1 is required')
      valid = false
    }

    if (shippingCountryId === 0) {
      setShippingCountryIdError('Shipping Country is required')
      valid = false
    }
    if (shippingStateId === 0) {
      setShippingStateIdError('Shipping State is required')
      valid = false
    }

    if (isEmpty(shippingCity)) {
      setShippingCityError('Shipping City is required')
      valid = false
    }

    if (isEmpty(shippingZipPostal)) {
      setShippingZipPostalError('Shipping Zip Postal is required')
      valid = false
    }

    setHasError(!valid)

    return valid
  }

  const getData = (): Omit<PurchaseFormData, 'payment_method_id'> => {
    const data: Omit<PurchaseFormData, 'payment_method_id'> = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
    }

    if (billingAddress1 && !isExistingAttendee) {
      data.billing_address = {
        address_1: billingAddress1,
        address_2: billingAddress2,
        country_id: String(billingCountryId),
        state_id: String(billingStateId),
        city: billingCity,
        zip_postal: billingZipPostal,
      }
    }

    if (shippingAddress1 && !isExistingAttendee) {
      data.shipping_address = {
        address_1: shippingAddress1,
        address_2: shippingAddress2,
        country_id: String(shippingCountryId),
        state_id: String(shippingStateId),
        city: shippingCity,
        zip_postal: shippingZipPostal,
      }
    }

    if (
      billingAddress1 &&
      !shippingAddress1 &&
      useSameAddressForShipping &&
      !isExistingAttendee
    ) {
      data.shipping_address = {
        address_1: billingAddress1,
        address_2: billingAddress2,
        country_id: String(billingCountryId),
        state_id: String(billingStateId),
        city: billingCity,
        zip_postal: billingZipPostal,
      }
    }

    return data
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    billingCountryId,
    setBillingCountryId,
    billingStateId,
    setBillingStateId,
    billingAddress1,
    setBillingAddress1,
    billingAddress2,
    setBillingAddress2,
    billingCity,
    setBillingCity,
    billingZipPostal,
    setBillingZipPostal,
    shippingCountryId,
    setShippingCountryId,
    shippingStateId,
    setShippingStateId,
    shippingAddress1,
    setShippingAddress1,
    shippingAddress2,
    setShippingAddress2,
    shippingCity,
    setShippingCity,
    shippingZipPostal,
    setShippingZipPostal,
    firstNameError,
    setFirstNameError,
    lastNameError,
    setLastNameError,
    emailError,
    setEmailError,
    billingAddress1Error,
    setBillingAddress1Error,
    billingCountryIdError,
    setBillingCountryIdError,
    billingStateIdError,
    setBillingStateIdError,
    billingCityError,
    setBillingCityError,
    billingZipPostalError,
    setBillingZipPostalError,
    shippingAddress1Error,
    setShippingAddress1Error,
    shippingCountryIdError,
    setShippingCountryIdError,
    shippingStateIdError,
    setShippingStateIdError,
    shippingCityError,
    setShippingCityError,
    shippingZipPostalError,
    setShippingZipPostalError,
    phoneNumberError,
    setPhoneNumberError,
    hasError,
    setHasError,
    step,
    setStep,
    isExistingAttendee,
    setIsExistingAttendee,
    validateForm,
    validateBillingForm,
    validateShippingForm,
    useSameAddressForShipping,
    setUseSameAddressForShipping,
    getData,
  }
}
