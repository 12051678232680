import React from 'react'
import styled from 'styled-components'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {useToggleArray} from 'lib/toggle'
import Dialog from 'lib/ui/Dialog'
import {Button, NavButtonProps} from 'Event/Dashboard/components/NavButton'
import Content from 'lib/ui/form/TextEditor/Content'
import {useAttendeeVariables} from 'Event'
import Configurable from 'organization/Event/Configurable'
import ConfirmButtonConfig, {
  DEFAULT_FONT_SIZE,
  DEFAULT_LABEL,
} from 'Event/Dashboard/components/NavButton/ConfirmedButton/ConfirmButtonConfig'
import DescriptionConfig, {
  DEFAULT_DESCRIPTION,
} from 'Event/Dashboard/components/NavButton/ConfirmedButton/DescriptionConfig'

export default function ConfirmDialog(
  props: NavButtonProps & {
    onConfirm: () => void
    children: (confirm: () => void) => React.ReactElement
  },
) {
  const [showing, toggleDialog] = useToggleArray()
  const {
    confirmDescription: description = DEFAULT_DESCRIPTION,
    confirmButtonLabel: buttonLabel = DEFAULT_LABEL,
  } = props
  const v = useAttendeeVariables()

  const handleConfirm = () => {
    toggleDialog()
    props.onConfirm()
  }

  return (
    <>
      <Dialog open={showing} onClose={toggleDialog}>
        <InnerContent>
          <StyledDialogContent>
            <Configurable>
              <DescriptionConfig />
              <Content>{v(description)}</Content>
            </Configurable>
          </StyledDialogContent>
          <StyledDialogAction disableSpacing>
            <Configurable>
              <ConfirmButtonConfig />
              <Button
                onClick={handleConfirm}
                aria-label="confirm"
                text={buttonLabel}
                padding={8}
                fontSize={props.confirmButtonFontSize || DEFAULT_FONT_SIZE}
                backgroundColor={props.confirmButtonBackgroundColor}
                textColor={props.confirmButtonTextColor}
              />
            </Configurable>
          </StyledDialogAction>
        </InnerContent>
      </Dialog>
      {props.children(toggleDialog)}
    </>
  )
}

const InnerContent = styled.div`
  padding: 20px 10px;
  background-color: #f1f1f1;
`

const StyledDialogAction = styled(DialogActions)`
  justify-content: center !important;
`

const StyledDialogContent = styled(DialogContent)``
