import {FieldProps, useSavedValue, FormTextField} from 'Event/Question'
import React from 'react'
import {useAttendeeVariables} from 'Event'
import {
  HasCharacterLimit,
  QuestionBase,
} from 'organization/Event/QuestionsProvider'
import {Controller} from 'react-hook-form'

export const LONG_ANSWER_TEXT = 'long_answer_text'

export type LongAnswerQuestion = QuestionBase &
  HasCharacterLimit & {
    type: typeof LONG_ANSWER_TEXT
  }

export default function LongAnswerText(props: FieldProps) {
  useSavedValue(props)
  const v = useAttendeeVariables()
  const label = `${v(props.question.label)} ${
    props.question.is_required ? '*' : ''
  }`

  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        rules={{
          required:
            props.question.is_required &&
            (props.requiredFieldMessage ?? 'This field is required'),
        }}
        render={({value, onChange}) => (
          <FormTextField
            label={label}
            inputProps={{
              'aria-label': props.question.label,
            }}
            fullWidth
            multiline
            rows={4}
            value={value}
            onChange={onChange}
            disabled={props.disabled}
            error={Boolean(props.error)}
            styles={props.inputStyles}
          />
        )}
      />

      {props.HelperText}
    </>
  )
}
