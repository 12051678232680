import {withDefaults} from 'lib/object'
import {useEvent} from 'Event/EventProvider'
import {useAsync} from 'lib/async'
import {DeepRequired} from 'lib/type-utils'
import {api, useQueryParams} from 'lib/url'
import {useCallback} from 'react'
import {Tags} from 'Event/attendee'
import {Group} from 'organization/Event/AttendeesProvider'

export interface Room {
  id: number
  number: number
  description: string | null
  is_online: boolean
  is_paused: boolean
  has_registration: boolean
  registration_url: string | null
  max_num_attendees: number | null
  attendee_count: number
  access_token: string
  template: null | {
    description?: string
    firstNameLabel?: string
    lastNameLabel?: string
    emailLabel?: string
    joinButtonText?: string
    missingRegistrationAttendeeMessage?: string
    phoneNumberLabel?: string
    phoneNumberRequired?: boolean
    hasPhoneNumberField?: boolean
    tags?: Tags
    groups?: Group[]
  }
  register_existing_attendees_only: boolean
}

const DEFAULT_TEMPLATE: NonNullable<DeepRequired<Room['template']>> = {
  firstNameLabel: 'First name',
  lastNameLabel: 'Last Name',
  emailLabel: 'Email',
  joinButtonText: 'Join room',
  phoneNumberLabel: 'Phone number',
  hasPhoneNumberField: false,
  phoneNumberRequired: false,
  description: '',
  missingRegistrationAttendeeMessage: 'Attendee not found',
  tags: [],
  groups: [],
}

export type RoomWithTemplate = Omit<Room, 'template'> & {
  template: NonNullable<DeepRequired<Room['template']>>
}

export function useFindRoomByToken() {
  const {token} = useQueryParams()
  const {client} = useEvent()
  const url = api(`/room?access_token=${token}`)
  const request = useCallback(() => client.get<Room>(url), [url, client])

  const {data: room, loading} = useAsync(request)
  if (loading || !room) {
    return null
  }

  const template = withDefaults(DEFAULT_TEMPLATE, room.template || {})

  return {
    ...room,
    template,
  }
}
