import React from 'react'
import Configurable from 'organization/Event/Configurable'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import PurchaseSummaryColumnLabel from 'Event/Marketplace/Block/PurchaseForm/PurchaseSummaryColumnLabel'
import SummaryColumnLabelConfig from 'organization/Marketplace/config/ConfigurablePurchaseForm/PurchaseSummaryColumnLabelConfig'

export interface ConfigurablePurchaseSummaryColumnLabelProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  children: string
  inputName: string
}

export default function ConfigurablePurchaseSummaryColumnLabel(
  props: ConfigurablePurchaseSummaryColumnLabelProps,
) {
  return (
    <Configurable>
      <SummaryColumnLabelConfig {...props} />
      <PurchaseSummaryColumnLabel {...props} />
    </Configurable>
  )
}
