import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import Visible from 'lib/ui/layout/Visible'
import Menu from 'lib/ui/Menu'
import {routesWithValue} from 'lib/url'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {
  AnswerSet,
  useObieService,
  Block,
} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function CompletionMenu(props: {
  answerSet?: AnswerSet
  onCollapse: () => void
  setEditing: (state: boolean) => void
  showing: boolean
  block: Block
}) {
  const {answerSet, onCollapse, setEditing, showing, block} = props
  const history = useHistory()
  const {routes} = useOrganization()
  const {copyCompletion, profileId} = useObieService()

  if (!showing) {
    return null
  }

  const onClickClose = () => {
    onCollapse()
    setEditing(false)
  }

  const onClickEdit = () => {
    setEditing(true)
  }

  const onClickCopy = () => {
    if (!answerSet?.completion.id) {
      return null
    }

    copyCompletion(answerSet.completion.id)
  }

  const editAnswers = () => {
    if (!answerSet) {
      return
    }
    const pRoute = profileRoute(
      routes,
      String(block.category.id),
      String(profileId),
    )

    const processRoute = routesWithValue(
      ':block',
      String(block.id),
      pRoute.blocks[':block'],
    )

    history.push(`${processRoute.process}?answer_set_id=${answerSet.id}`)
  }

  return (
    <Menu
      button={({open}) => (
        <IconButton
          aria-label="account menu"
          variant="text"
          color="dark"
          onClick={open}
        >
          <Icon className="fas fa-ellipsis-h" color="black" iconSize={18} />
        </IconButton>
      )}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={onClickClose}>Close</MenuItem>
      <MenuItem onClick={onClickCopy}>Copy Response</MenuItem>
      <MenuItem onClick={onClickEdit}>Edit Response</MenuItem>
      <Visible when={block.questions.length > 0}>
        <MenuItem onClick={editAnswers}>Edit My Answers</MenuItem>
      </Visible>

      <Divider />
      <DeleteMenuItem answerSet={answerSet} />
    </Menu>
  )
}

const DeleteMenuItem = (props: {answerSet?: AnswerSet}) => {
  const {answerSet} = props
  const {categoryId, deleteAnswerSet} = useObieService()

  if (!answerSet) {
    return null
  }

  const onClick = () => {
    deleteAnswerSet(categoryId, answerSet.block_id, answerSet.id)
  }

  return <DangerMenuItem onClick={onClick}>Delete</DangerMenuItem>
}

const DangerMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.colors.error};
`
