import React, {useState} from 'react'
import styled from 'styled-components'
import Title from 'lib/ui/PageHeader/Title'
import Button from 'lib/ui/Button'
import {AreaMetrics, useStatistics} from 'Event/Statistics'
import Area from 'Event/Statistics/Areas/Area'
import Rooms from 'Event/Statistics/Areas/Rooms'
import {useToggleArray} from 'lib/toggle'

const NUM_AREAS_WHEN_COLLAPSED = 4

export default function Areas() {
  const {
    metrics: {areas},
  } = useStatistics()

  const [collapsed, toggleCollapsed] = useToggleArray(true)
  const [selectedAreaId, setSelectedAreaId] = useState(0)

  areas.sort((a, b) => {
    const order = b.attendee_count - a.attendee_count
    if (order) {
      return order
    }

    if (a.name < b.name) {
      return -1
    }

    return 1
  })

  const hasShowMoreButton = areas.length > NUM_AREAS_WHEN_COLLAPSED
  const visibleAreas = collapsed
    ? areas.slice(0, NUM_AREAS_WHEN_COLLAPSED)
    : areas

  const gridSize = calculateGridSize(visibleAreas.length)

  const handleSelectArea = (areaId: number) => {
    if (selectedAreaId === areaId) {
      setSelectedAreaId(0)
      return
    }

    setSelectedAreaId(areaId)
  }

  return (
    <Container>
      <Title text="Live on Zoom" />
      <Content>
        <Top>
          <Grid gridSize={gridSize}>
            {visibleAreas.map((area: AreaMetrics) => {
              const selected = area.id === selectedAreaId

              return (
                <Area
                  selected={selected}
                  disabled={Boolean(selectedAreaId) && !selected}
                  onClick={handleSelectArea}
                  id={area.id}
                  key={area.id}
                  name={area.name}
                  numAttendees={area.attendee_count}
                  canViewRooms
                />
              )
            })}
          </Grid>
          <PlusButton
            showMore={collapsed}
            visible={hasShowMoreButton}
            onClick={toggleCollapsed}
          />
        </Top>
        <Rooms areaId={selectedAreaId} />
        <ShowMoreButton
          showMore={collapsed}
          visible={hasShowMoreButton}
          onClick={toggleCollapsed}
        />
      </Content>
    </Container>
  )
}

function PlusButton(props: {
  visible: boolean
  showMore: boolean
  onClick: () => void
}) {
  const {visible, showMore, onClick} = props

  const buttonLabel = showMore ? '+' : '-'

  if (!visible) {
    return null
  }

  return (
    <PlusButtonContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        aria-label="show all areas"
      >
        {buttonLabel}
      </Button>
    </PlusButtonContainer>
  )
}

function ShowMoreButton(props: {
  visible: boolean
  showMore: boolean
  onClick: () => void
}) {
  const {visible, showMore, onClick} = props

  const buttonLabel = showMore ? 'Show More +' : 'Show Less -'

  if (!visible) {
    return null
  }

  return (
    <ButtonContainer>
      <Button variant="contained" color="primary" onClick={onClick}>
        {buttonLabel}
      </Button>
    </ButtonContainer>
  )
}

function calculateGridSize(numItems: number) {
  if (numItems <= 4) {
    return numItems
  }

  return 4
}

const Container = styled.div`
  padding-top: ${(props) => props.theme.spacing[15]};
`

const Content = styled.div`
  padding-top: ${(props) => props.theme.spacing[6]};
`

const PlusButtonContainer = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    align-items: ;
    justify-content: center;
    margin-top: ${(props) => props.theme.spacing[5]};
    margin-bottom: ${(props) => props.theme.spacing[5]};
    margin-left: ${(props) => props.theme.spacing[6]};
  }
`

const ButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const Top = styled.div`
  display: flex;
  align-items: flex-start;
`

type GridProps = {
  gridSize: number
}

const Grid = styled.div<GridProps>`
  flex: 1;

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: auto;
  grid-gap: ${(props) => props.theme.spacing[4]};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: ${(props) => `repeat(${props.gridSize}, auto)`};
  }
`
