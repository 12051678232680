import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Slider from 'lib/ui/Slider'
import {Label} from 'lib/ui/typography'
import TextField from 'lib/ui/TextField'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {useAttendeeVariables} from 'Event'

const MIN_HERO_IMAGE_SIZE_PERCENT = 20
const MAX_HERO_IMAGE_SIZE_PERCENT = 100

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
}) {
  const {control, register} = props
  const template = useSimpleBlogTemplate()
  const {welcomeText, heroImage, heroImageSize} = template

  const v = useAttendeeVariables()

  return (
    <>
      <Box mb={2}>
        <Label>Image</Label>
        <Controller
          name="heroImage"
          control={control}
          defaultValue={heroImage}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Upload"
              canResize
              uploadInputProps={{
                'aria-label': 'hero image upload',
              }}
              disableAutoRemove
            />
          )}
        />
      </Box>
      <Controller
        control={control}
        name="heroImageSize"
        defaultValue={heroImageSize}
        render={({value, onChange}) => (
          <Slider
            min={MIN_HERO_IMAGE_SIZE_PERCENT}
            max={MAX_HERO_IMAGE_SIZE_PERCENT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="hero image size"
            label="Image Size"
          />
        )}
      />
      <Label>Text</Label>
      <TextField
        name="welcomeText"
        placeholder="Text"
        defaultValue={v(welcomeText)}
        aria-label="dashboard welcome text"
        inputProps={{
          ref: register,
        }}
        fullWidth
      />
    </>
  )
}
