import React from 'react'
import styled from 'styled-components'
import SnackbarContent from '@material-ui/core/SnackbarContent'

export const Snackbar = styled(
  ({
    color: _1,
    backgroundColor: _2,
    ...otherProps
  }: {
    color: string
    backgroundColor: string
    message: React.ReactElement
    style?: React.CSSProperties
  }) => <SnackbarContent {...otherProps} />,
)`
  color: ${(props) => props.color}!important;
  background-color: ${(props) => props.backgroundColor} !important;
`
