import {ObvioEvent} from 'Event'
import {useEvents} from 'lib/event-api/organization/use-events'
import {PurchasePage} from 'Event/Marketplace/purchase-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'
import {ThankYouPage} from 'Event/Marketplace/thank-you-page'

export type EventWithThankYouPage = ObvioEvent & {
  thankYouPageTemplate: ThankYouPage['template']
}

export function useThankYouPageEvents() {
  const {organization} = useOrganization()
  const {data: events} = useEvents()

  return useQuery({
    queryKey: ['organizations', organization.id, 'thank_you_page_events'],
    queryFn: () =>
      new Promise<EventWithThankYouPage[]>(async (resolve) => {
        if (!events) {
          resolve([])
          return
        }

        const thankYouPages = events.map(async (event) => {
          const thankYouPage = await client.get<PurchasePage | null>(
            marketplaceApi(`/events/${event.id}/thank_you_page`),
          )

          if (!thankYouPage?.template) {
            return null
          }

          const hasSections = Boolean(thankYouPage.template.sections)
          if (!hasSections) {
            return null
          }

          return {
            ...event,
            thankYouPageTemplate: thankYouPage.template,
          }
        })

        const result = (await Promise.all(thankYouPages)).filter(
          (thankYouPage) => thankYouPage,
        ) as EventWithThankYouPage[]

        resolve(result)
      }),
    enabled: !!events,
  })
}
