import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useCardsTemplate, useCardsUpdate} from 'Event/template/Cards'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import Title from 'lib/ui/ConfigPanel/Title'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'

export default function AdditionalDescriptionConfig() {
  const saveTemplate = useCardsUpdate()
  const {login, textColor} = useCardsTemplate()
  const additionalDescriptionTextColor = login.additionalDescription.color
    ? login.additionalDescription.color
    : textColor

  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const save = (data: any) => {
    const text = replaceAtPath(
      data,
      'login.additionalDescription.text',
      '{{login_additional_description}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        login_additional_description: text ?? '',
      }),
    })
  }

  const {
    form: {register, control},
  } = useConfig()

  return (
    <Config title="Helper Text" onSave={save}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="login.additionalDescription.text"
          defaultValue={v(login.additionalDescription.text)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="login.additionalDescription.color"
          defaultValue={additionalDescriptionTextColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="color"
            />
          )}
        />
        <Controller
          name="login.additionalDescription.fontSize"
          defaultValue={login.additionalDescription.fontSize}
          control={control}
          render={({value, onChange}) => (
            <Slider
              valueLabelDisplay="auto"
              aria-label="font size"
              value={value}
              onChange={onChange}
              step={1}
              min={5}
              max={50}
              label="Font Size"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
