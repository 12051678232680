import React from 'react'
import styled from 'styled-components'
import {Title} from 'lib/ui/ConfigDrawer'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import {SidebarIcon, WindowIcon} from 'lib/ui/Icon'
import CloseIcon from '@material-ui/icons/Close'
import grey from '@material-ui/core/colors/grey'
import IconButton from 'lib/ui/IconButton'
import Content from 'lib/ui/ConfigPanel/Content'
import {useToggleArray} from 'lib/toggle'
import {Icon} from 'lib/fontawesome/Icon'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

export type ConfigPanelProps = {
  title?: string
  open: boolean
  onClose: () => void
  container?: JSX.Element
  children: JSX.Element[]
  className?: string
  isDialogMode?: boolean
}

export default function ConfigPanel(props: ConfigPanelProps) {
  const {open, onClose} = props
  const [isDialogMode, toggleDialogMode] = useToggleArray(props.isDialogMode)
  const [isExpanded, expand] = useToggleArray()

  return ReactDOM.createPortal(
    <>
      <Background
        onClick={onClose}
        className={classNames({
          open,
          dialog: isDialogMode,
          panel: !isDialogMode,
        })}
      />
      <Body
        isExpanded={isExpanded}
        className={classNames({
          dialog: isDialogMode,
          panel: !isDialogMode,
          open,
        })}
      >
        <DarkThemeProvider>
          {open ? (
            <>
              <Title>{props.title}</Title>
              <ButtonContainer>
                <SwitchButton
                  toggleDialogMode={toggleDialogMode}
                  isDialogMode={isDialogMode}
                />

                <ResizeButton
                  onClick={expand}
                  showing={true}
                  aria-label="resize modal"
                >
                  <ExpandToggleIcon expanded={isExpanded} />
                </ResizeButton>

                <CloseButton onClick={onClose} aria-label="close config">
                  <CloseIcon fontSize="small" />
                </CloseButton>
              </ButtonContainer>
              <Content {...props} />
            </>
          ) : (
            <></>
          )}
        </DarkThemeProvider>
      </Body>
    </>,
    document.body,
  )
}

function SwitchButton(props: {
  toggleDialogMode: () => void
  isDialogMode: boolean
}) {
  const {toggleDialogMode, isDialogMode} = props

  if (isDialogMode) {
    return (
      <PanelButton aria-label="switch to panel" onClick={toggleDialogMode}>
        <SidebarIcon color={grey[500]} iconSize={18} />
      </PanelButton>
    )
  }

  return (
    <DialogButton aria-label="switch to dialog" onClick={toggleDialogMode}>
      <WindowIcon color={grey[500]} iconSize={20} />
    </DialogButton>
  )
}

const Background = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: none;
  top: 0;
  left: 0;

  &.dialog {
    background: rgba(0, 0, 0, 0.5);
  }

  &.open {
    display: block;
  }
`

const Body = styled.div<{isExpanded: boolean}>`
  width: ${(props) => (props.isExpanded ? 'calc(100% - 64px) !important' : '')};
  max-width: ${(props) =>
    props.isExpanded ? 'calc(100% - 64px)  !important' : ''};

  &.dialog {
    margin: 32px;
    height: 100%;
    outline: 0;
    position: relative;
    max-width: 600px;
    width: 100%;
    max-height: calc(100% - 64px);
    display: none;
    flex-direction: column;
    z-index: 110;
    background: #222222;
    color: #ffffff;
    overflow-y: auto;
    outline: 0;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.panel {
    display: flex;
    flex-direction: column;
    z-index: 110;
    background: #222222;
    color: #ffffff;
    overflow-y: auto;
    outline: 0;
    position: fixed;
    width: 90%;
    right: -480px;
    top: 3%;
    height: 94%;

    @media (min-width: 600px) {
      transition: right 0.25s ease-out;
      top: 64px;
      width: 480px;
      height: calc(100% - 64px);
      border-radius: 4px 0px 0px 4px;
    }
  }

  &.dialog.open {
    display: flex;
  }

  &.panel.open {
    right: 5%;

    @media (min-width: 600px) {
      right: 0;
    }
  }
`

const DialogButton = styled(IconButton)`
  margin-right: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${(props) => props.theme.spacing[7]};
  right: ${(props) => props.theme.spacing[2]};
`

const CloseButton = styled(IconButton)<{
  hidden?: boolean
}>`
  ${(props) => (props.hidden ? 'display: none;' : '')}

  svg {
    color: ${grey[500]};
  }
`

const PanelButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.spacing[3]};
`

const ResizeButton = styled(IconButton)<{
  showing?: boolean
}>`
  width: 20px;
  height: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.spacing[2]};

  svg {
    color: ${grey[500]};
  }

  // Hide on mobile as it's already full width.
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: ${(props) => (props.showing ? 'flex' : 'none')};
  }
`

const ExpandToggleIcon = (props: {expanded: boolean}) => (
  <ExpandIcon
    iconClass={`fa-thin ${
      props.expanded
        ? 'fa-arrow-down-left-and-arrow-up-right-to-center'
        : 'fa-arrow-up-right-and-arrow-down-left-from-center'
    }`}
  />
)

const ExpandIcon = styled(Icon)`
  font-size: 12px;
`
