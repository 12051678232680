import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import LoadingGrid from 'organization/Marketplace/config/TemplateSelector/LoadingGrid'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import UpsellPageEventTemplateCard from 'organization/Marketplace/config/TemplateSelector/UpsellPageEventTemplateCard'
import {useUpsellPageEvents} from 'lib/marketplace-api/upsell-page/use-upsell-page-events'

interface UserEventsGridProps {
  updateTemplate: UpdateTemplateFunction
}

export default function UpsellPageUserEventsGrid(props: UserEventsGridProps) {
  const {updateTemplate} = props
  const {event: currentEvent} = useEvent()
  const {data: events} = useUpsellPageEvents()

  // Don't want to include current Event as a selection
  const otherEvents = events
    ? events.filter((item) => item.id !== currentEvent.id)
    : []

  if (!events) {
    return <LoadingGrid />
  }

  if (otherEvents.length < 1) {
    return <Typography>You don't have any events with a Upsell Page</Typography>
  }

  return (
    <>
      {otherEvents.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <UpsellPageEventTemplateCard
            event={event}
            updateTemplate={updateTemplate}
          />
        </Grid>
      ))}
    </>
  )
}
