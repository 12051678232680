import {ObvioEvent} from 'Event'
import {ThankYouPage} from 'Event/Marketplace/thank-you-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {Organization} from 'organization'
import {useMutation, useQueryClient} from 'react-query'

export function useCreateThankYouPage(params: {
  event: ObvioEvent
  organization: Organization
}) {
  const {event} = params
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (args: {client: Client}) => {
      const {client} = args
      const url = marketplaceApi(`/events/${event.id}/thank_you_page`)
      const data = await client.post<ThankYouPage>(url)

      return data
    },
    onSuccess: (thankYouPage) => {
      qc.setQueryData<ThankYouPage>(
        ['events', event.id, 'thank_you_page'],
        () => {
          return thankYouPage
        },
      )
    },
  })
}
