import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LoadingGrid from 'organization/Marketplace/config/TemplateSelector/LoadingGrid'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import ThankYouPageEventTemplateCard from 'organization/Marketplace/config/TemplateSelector/ThankYouPageEventTemplateCard'
import {useMarketplaceThankYouPageSampleEvents} from 'lib/event-api/event/use-marketplace-thank-you-page-sample-events'

interface ObvioEventsGridProps {
  updateTemplate: UpdateTemplateFunction
}

export default function ThankYouPageObvioEventsGrid(
  props: ObvioEventsGridProps,
) {
  const {updateTemplate} = props
  const {data: events} = useMarketplaceThankYouPageSampleEvents()

  if (!events) {
    return <LoadingGrid />
  }

  if (events.length < 1) {
    return (
      <Typography>You don't have any events with a Thank You Page</Typography>
    )
  }

  return (
    <>
      {events.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <ThankYouPageEventTemplateCard
            event={event}
            updateTemplate={updateTemplate}
          />
        </Grid>
      ))}
    </>
  )
}
