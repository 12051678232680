import React from 'react'
import styled from 'styled-components'
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {useLeftyTemplate} from 'Event/template/Lefty'

export default function DragHandleBar(props: {
  handleProps?: DraggableProvidedDragHandleProps
}) {
  const isEditMode = useEditMode()
  const template = useLeftyTemplate()
  if (!isEditMode) {
    return null
  }

  return (
    <Box>
      <Handle {...props.handleProps}>
        <Bar textColor={template.sidebar.textColor} />
        <Bar textColor={template.sidebar.textColor} />
      </Handle>
    </Box>
  )
}

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[2]};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Handle = styled.div`
  display: inline-block !important;
  &:hover {
    opacity: 0.8;
  }
`

const Bar = styled.div<{
  textColor: string | null
}>`
  width: 20px;
  background: ${(props) => props.textColor};
  border-radius: 4px;
  height: 2px;
  margin: 4px 0;
`
