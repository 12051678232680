import React from 'react'
import SpeakerList from 'Event/template/Panels/Dashboard/Speakers/SpeakerList'
import {PageDescription, PageTitle} from 'Event/template/Panels/Page'
import {useAttendeeVariables, useGuestVariables} from 'Event'
import {usePanelsTemplate} from 'Event/template/Panels'
import Content from 'lib/ui/form/TextEditor/Content'
import Configurable from 'organization/Event/Configurable'
import HeaderConfig from 'Event/Speakers/HeaderConfig'
import AddSpeakerButton from 'Event/Speakers/AddSpeakerButton'
import Scripts, {SPEAKERS} from 'Event/Scripts'
import {useEditMode} from 'Event/EditModeProvider'

export default function SpeakerPage() {
  const {speakers: speakerPageSettings} = usePanelsTemplate()
  const v = useGuestVariables()
  const isEditMode = useEditMode()

  if (isEditMode) {
    return <ConfigurableSpeakerPage />
  }

  return (
    <>
      <Scripts page={SPEAKERS} />
      <PageTitle aria-label="speakers title">
        {v(speakerPageSettings.title)}
      </PageTitle>
      <PageDescription>
        <Content>{v(speakerPageSettings.description)}</Content>
      </PageDescription>
      <SpeakerList />
    </>
  )
}

function ConfigurableSpeakerPage() {
  const {speakers: speakerPageSettings} = usePanelsTemplate()
  const v = useAttendeeVariables()

  return (
    <>
      <Scripts page={SPEAKERS} />
      <Configurable>
        <HeaderConfig />
        <PageTitle aria-label="speakers title">
          {v(speakerPageSettings.title)}
        </PageTitle>
      </Configurable>
      <PageDescription>
        <Content>{v(speakerPageSettings.description)}</Content>
      </PageDescription>
      <SpeakerList />
      <AddSpeakerButton />
    </>
  )
}
