import React, {useRef} from 'react'
import {MenuItemActionProps} from 'organization/Event/AttendeeManagement/AttendeeTools'
import MenuItem from 'lib/ui/Menu/MenuItem'

const ReplacePdfMenuItem = React.forwardRef<
  HTMLLIElement,
  Omit<MenuItemActionProps, 'onClick'> & {
    onSelectFile: (file: File) => void
    disabled?: boolean
  }
>((props, ref) => {
  const inputEl = useRef<HTMLInputElement | null>(null)

  const handleFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files ? evt.target.files[0] : null
    if (!file) {
      return
    }

    props.onSelectFile(file)
  }

  const selectFile = () => {
    if (!inputEl.current) {
      return
    }

    inputEl.current.click()
  }

  return (
    <>
      <MenuItem disabled={props.disabled} onClick={selectFile} ref={ref}>
        Replace PDF Doc
      </MenuItem>
      <input
        type="file"
        ref={inputEl}
        accept=".pdf,.jpeg,.jpg,.png,.gif"
        onChange={handleFileSelect}
        hidden
        required
        aria-label="pdf upload input"
      />
    </>
  )
})

export default ReplacePdfMenuItem
