import FontFamilyCommand from './fontfamilycommand';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import type { Editor } from 'ckeditor5/src/core';
import type {
	ViewAttributeElement,
	ViewElement,
	DowncastConversionApi
} from 'ckeditor5/src/engine.js';

export default class FontFamilyEditing extends Plugin {
	/**
      * @inheritDoc
      */
	static get pluginName() {
		return 'customFontFamilyEditing';
	}

	constructor( editor: Editor ) {
		super( editor );

		editor.conversion.for( 'upcast' ).elementToAttribute( {
			view: matchUpcastElement(),
			model: {
				key: "customFontFamily",
				value: renderUpcastElement()
			}
		} );

		editor.conversion.for( 'downcast' ).attributeToElement( {
			model: "customFontFamily",
			view: renderDowncastElement()
		} );

		editor.commands.add( 'customFontFamily', new FontFamilyCommand( editor ) );

		editor.model.schema.extend( '$text', { allowAttributes: 'customFontFamily' } );

		editor.model.schema.setAttributeProperties( 'customFontFamily', {
			isFormatting: true,
			copyOnEnter: true
		} );
	}

}

function matchUpcastElement() {
	return (element: ViewElement) => {

		if ( element.name === 'span' ) {
			const fontFamily = element.getStyle( 'font-family' );
			if ( fontFamily ) {
				return {
					name: true,
					styles: [ 'font-family' ]
				};
			}
		}
		return null;
	};
}

function renderUpcastElement() {
	return (element: ViewElement) => {

		const fontFamily = element.getStyle( 'font-family' );
		if ( fontFamily ) {
			return fontFamily;
		}
		return null;
	};
}

function renderDowncastElement( ) {

	return ( modelAttributeValue: string, { writer }: DowncastConversionApi ): ViewAttributeElement =>{

		const attributes = modelAttributeValue ? {
			style: `font-family: ${ modelAttributeValue };`
		} : {};

		return writer.createAttributeElement( 'span', attributes, { priority: 7 } );
	};
}
