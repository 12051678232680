import React from 'react'
import Button from 'lib/ui/Button'
import {Speaker} from 'Event/Speakers'
import styled from 'styled-components'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation} from 'Event/LanguageProvider'

type AddSpeakerButtonProps = {
  onAdd?: (speaker: Speaker) => void
}

export default function AddSpeakerButton(props: AddSpeakerButtonProps) {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content {...props} />
}

function Content(props: AddSpeakerButtonProps) {
  const {onAdd} = props
  const isEditMode = useEditMode()
  const {speakers} = useTemplate()
  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const add = () => {
    const numSpeakers = Object.keys(speakers.items).length
    const position = numSpeakers + 1

    generateHashId([
      'speaker',
      position.toString(),
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `speaker_${id}`

      const newSpeaker: Speaker = {
        name: `{{${componentId}_name}}`,
        text: `{{${componentId}_text}}`,
        image: null,
        position,
      }

      save({
        speakers: {
          items: {
            [componentId]: newSpeaker,
          },
        },
        localization: addTranslation({
          [`${componentId}_name`]: 'Speaker',
          [`${componentId}_text`]: 'Speaker bio',
        }),
      })

      onAdd && onAdd(newSpeaker)
    })
  }

  if (!isEditMode) {
    return null
  }

  return (
    <StyledButton
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add speaker"
      onClick={add}
    >
      Add Speaker
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => `${props.theme.spacing[8]} 0`}!important;
`
