import TextField from 'lib/ui/TextField'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useCardsTemplate} from 'Event/template/Cards'
import Title from 'lib/ui/ConfigPanel/Title'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {welcomeMessage} = useCardsTemplate()
  const v = useAttendeeVariables()
  return (
    <>
      <Title>Text</Title>
      <Controller
        name="text"
        defaultValue={v(welcomeMessage.text)}
        control={control}
        render={({value, onChange}) => (
          <TextField
            fullWidth
            value={value}
            aria-label="dashboard welcome text"
            placeholder="Text"
            onChange={onChange}
          />
        )}
      />
    </>
  )
}
