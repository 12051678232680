import ConfigurableSectionLabel, {
  ConfigurableSectionLabelProps,
} from 'organization/Marketplace/config/ConfigurablePurchaseForm/ConfigurableSectionLabel'
import React from 'react'

export default function ConfigurableCardFieldsLabel(
  props: ConfigurableSectionLabelProps,
) {
  const {cardFieldsLabel} = props

  return (
    <ConfigurableSectionLabel
      {...props}
      children={cardFieldsLabel ?? 'Card'}
      inputName="cardFieldsLabel"
    />
  )
}
