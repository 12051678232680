import React from 'react'
import styled from 'styled-components'
import {Block, useObieService} from 'organization/Obie/ObieServiceProvider'
import {Tiny} from 'lib/ui/typography'
import {colors} from 'lib/ui/theme'

export default function BlockName(props: {
  block: Block
  showing: boolean
  onClick: () => void
}) {
  const {block, showing, onClick} = props

  return (
    <BlockInfo showing={showing} onClick={onClick}>
      <span>{block.block}</span>
      <BlockDependenciesInfo block={block} />
    </BlockInfo>
  )
}
function BlockDependenciesInfo(props: {block: Block}) {
  const {block} = props

  const {
    unCompletedBlocks,
    hasUnCompletedDependency,
  } = useUncompletedDependencies(block)

  if (!hasUnCompletedDependency) {
    return null
  }

  const blockDependencies = unCompletedBlocks.map((b, index) => (
    <BlockDependency block={b} key={index} />
  ))

  return (
    <div>
      <ItalicTiny color={colors.gray400} fontSize={14}>
        This block requires the following to be completed first
      </ItalicTiny>
      {blockDependencies}
    </div>
  )
}

function BlockDependency(props: {block?: Block}) {
  const {block} = props

  if (!block) {
    return null
  }

  return (
    <ItalicTiny ml={4} color={colors.gray400} fontSize={14}>
      <li>
        {block.category.name}: {block.block}
      </li>
    </ItalicTiny>
  )
}

export function useUncompletedDependencies(block: Block) {
  const {dependencies = []} = block

  const {findBlock} = useObieService()

  const unCompletedBlocks = dependencies
    .filter((blockId: number) => {
      const onBlock = findBlock(blockId)

      if (!onBlock) {
        return false
      }

      const hasCompletion = onBlock.answer_sets.some(
        (answerSet) => answerSet.complete,
      )
      if (hasCompletion) {
        return false
      }
      return true
    })
    .map((blockId) => findBlock(blockId))

  return {
    hasUnCompletedDependency: unCompletedBlocks.length > 0,
    unCompletedBlocks,
  }
}

const ItalicTiny = styled(Tiny)`
  font-style: italic;
  display: block;
`

const BlockInfo = styled.div<{showing?: boolean}>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: ${(props) => (props.showing ? 'flex' : 'none')};
  flex-direction: column;
  flex: 1;
  justify-content: center;
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing[4]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex: 1;
  }
`
