import React from 'react'
import {HashMap, Ordered, orderedIdsByPosition} from '../../lib/list'
import CKEditorContent from '../../lib/ui/CKEditorContent'
import {getFontStyles, Font} from '../../lib/font'
import {BlockBase} from '../base'
import {BlockComponentProps} from '../index'
import {rgba} from '../../lib/color'
import Container from '../../Block/Container'
import styles from './NumberedList.module.css'
import classNames from 'classnames'

export type NumberItem = Ordered & {
  visibility: 'all' | 'mobile_only' | 'desktop_only'
  text: string
}

export interface NumberedListBlock extends BlockBase {
  type: 'NumberedList'
  font: Font | null
  fontSize: number
  color: string
  numberSpace: number
  textSpace: number
  items: HashMap<NumberItem>
  counter: {
    style: 'numeric' | 'alphabetical' | 'roman'
    outline: 'none' | 'circle' | 'square'
    font: Font | null
    fontSize: number
    color: string
    background: {
      color: string
      opacity: number
    }
  }
}

export default function NumberedList(
  props: NumberedListBlock & BlockComponentProps,
) {
  return (
    <NumberedListContainer {...props}>
      <ListItems {...props} />
    </NumberedListContainer>
  )
}

export function NumberedListContainer(
  props: NumberedListBlock &
    BlockComponentProps & {
      children: React.ReactNode
    },
) {
  const {children} = props

  return (
    <Container {...props}>
      <div className={classNames(styles.numberedListBox, 'block-content')}>
        {children}
      </div>
    </Container>
  )
}

function ListItems(props: NumberedListBlock) {
  const {items} = props

  if (!items) {
    return null
  }

  const sortedIds = orderedIdsByPosition(items)

  return (
    <>
      {sortedIds.map((id, index) => {
        const isLast = sortedIds.length === index + 1
        return (
          <ListItem
            key={id}
            number={index}
            {...props}
            item={items[id]}
            isLast={isLast}
          />
        )
      })}
    </>
  )
}

export function ListItem(
  props: NumberedListBlock & {
    item: NumberItem
    number: number
    isLast: boolean
  },
) {
  const {item} = props

  const backgroundColor = props.counter.background.color
    ? rgba(
        props.counter.background.color || '#FFFFFF',
        props.counter.background.opacity / 100,
      )
    : ''

  const counterNumber = convertCounterNumber(
    props.number + 1,
    props.counter.style,
  )
  return (
    <div
      style={{
        marginTop: props.numberSpace + 'px',
        display: 'flex',
        alignItems: 'center',
        fontSize: props.fontSize + 'px',
        color: props.color,
        marginBottom: props.isLast ? props.numberSpace + 'px' : 'inherit',
        ...getFontStyles(props.font),
      }}
      aria-label="number list"
    >
      <NumberedItem {...props} backgroundColor={backgroundColor}>
        {' '}
        <span className={styles.counterNumberHolder}>{counterNumber}</span>{' '}
      </NumberedItem>
      <CKEditorContent className={styles.cKEditorContent}>
        {item.text}
      </CKEditorContent>
    </div>
  )
}

function convertCounterNumber(
  number: number,
  outline: NumberedListBlock['counter']['style'],
) {
  if (outline === 'numeric') {
    return number
  }

  if (outline === 'alphabetical') {
    return convertToAlphabetical(number)
  }

  if (outline === 'roman') {
    return convertToRoman(number)
  }
}

const NumberedItem = (
  props: NumberedListBlock & {
    backgroundColor: string
    children: React.ReactNode
  },
) => {
  const {counter, children} = props

  if (counter.outline === 'none') {
    return (
      <div
        style={{
          fontSize: props.counter.font + 'px',
          color: props.counter.color,
          marginRight: props.textSpace + 'px',
          textAlign: 'center',
          lineHeight: 0,
          minWidth: `calc(${props.counter.fontSize}px * 1.5)`,
          minHeight: `calc(${props.counter.fontSize}px * 1.5)`,
          ...getFontStyles(props.counter.font),
        }}
      >
        {children}
      </div>
    )
  }

  if (counter.outline === 'circle') {
    return (
      <div
        style={{
          background: props.backgroundColor,
          borderRadius: '100%',
          fontSize: props.counter.fontSize + 'px',
          color: props.counter.color,
          marginRight: props.textSpace + 'px',
          textAlign: 'center',
          lineHeight: 0,
          minWidth: `calc(${props.counter.fontSize}px * 1.5)`,
          minHeight: `calc(${props.counter.fontSize}px * 1.5)`,
          ...getFontStyles(props.counter.font),
        }}
      >
        {children}
      </div>
    )
  }

  if (counter.outline === 'square') {
    return (
      <div
        style={{
          background: props.backgroundColor,
          fontSize: props.counter.fontSize + 'px',
          color: props.counter.color,
          marginRight: props.textSpace + 'px',
          textAlign: 'center',
          lineHeight: 0,
          minWidth: `calc(${props.counter.fontSize}px * 1.5)`,
          minHeight: `calc(${props.counter.fontSize}px * 1.5)`,
          ...getFontStyles(props.counter.font),
        }}
      >
        {children}
      </div>
    )
  }

  return null
}

export const createNumberItem = (): NumberItem => ({
  text: '<p>New Item</p>',
  visibility: 'all',
  position: 1,
})

function convertToRoman(number: number) {
  const romanChars: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  var result = ''

  for (var key of Object.keys(romanChars)) {
    var q = Math.floor(number / romanChars[key])
    number -= q * romanChars[key]
    result += key.repeat(q)
  }

  return result
}

function convertToAlphabetical(number: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''

  var charIndex = number % alphabet.length
  var quotient = number / alphabet.length
  if (charIndex - 1 === -1) {
    charIndex = alphabet.length
    quotient--
  }
  result = alphabet.charAt(charIndex - 1) + result
  if (quotient >= 1) {
    result = convertToAlphabetical(quotient) + result
  }

  return result
}
