import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import CustomFontFamilyInputFormView from './fontfamilyformview';
import fontFamilyIcon from '../../theme/icons/font-family.svg';
import { DEFAULT_FONT_FAMILY, showFakeVisualSelection, hideFakeVisualSelection, makeSelectionHighlighter } from '../color-utils';
import { DropdownView, ButtonView, LabeledFieldView } from '@ckeditor/ckeditor5-ui';
import FontFamilyCommand from './fontfamilycommand';
import type { Editor } from 'ckeditor5/src/core';
import Command from '@ckeditor/ckeditor5-core/src/command';

const VISUAL_SELECTION_MARKER_NAME = 'font-family-ui';

// Custom type for LabeledInputTextView
type LabeledInputTextView = LabeledFieldView & {
    fieldView: {
        value: string;
        element: HTMLInputElement;
        select(): void;
    };
}


export default class FontFamilyUI extends Plugin {
	static get pluginName() {
		return 'customFontFamilyUI';
	}

	init() {
		const editor = this.editor;

		editor.ui.componentFactory.add('customFontFamily', locale => {
			const dropdown = createDropdown(locale);
			const form = new CustomFontFamilyInputFormView(editor.locale);

			const command = editor.commands.get('customFontFamily') as FontFamilyCommand;

			this._setUpDropdown(dropdown, form, command);
			this._setUpForm(dropdown, form);

			makeSelectionHighlighter(editor, VISUAL_SELECTION_MARKER_NAME);

			return dropdown;
		});
	}

	private _setUpDropdown(dropdown: DropdownView, form: CustomFontFamilyInputFormView, command: FontFamilyCommand) {
		const editor = this.editor;
		const t = editor.t;
		const button = dropdown.buttonView as ButtonView;

		dropdown.bind('isEnabled').to(command);
		dropdown.panelView.children.add(form);

		button.set({
			label: t('Font Family'),
			icon: fontFamilyIcon,
			tooltip: true
		});

		button.on('open', () => {
			const fieldView: LabeledInputTextView['fieldView'] = form.fontFamilyInputView.fieldView as LabeledInputTextView['fieldView'];
			fieldView.value = (fieldView.element as HTMLInputElement).value = command.value as string || DEFAULT_FONT_FAMILY;

			fieldView.select();
			form._updateNameList(fieldView.value);
			form.focus();
		}, { priority: 'low' });

		dropdown.on('submit', () => {
			editor.execute('customFontFamily', (form.fontFamilyInputView.fieldView.element as HTMLInputElement).value);
			closeUI();
		});

		this.listenTo(dropdown, 'execute', evt => {
			editor.execute('customFontFamily', (evt.source as any).commandParam);
			closeUI();
		});

		dropdown.on('cancel', () => closeUI());

		dropdown.on('change:isOpen', (evt, name, isVisible) => {
			if (isVisible) {
				showFakeVisualSelection(editor.model, VISUAL_SELECTION_MARKER_NAME);
			} else {
				hideFakeVisualSelection(editor.model, VISUAL_SELECTION_MARKER_NAME);
			}
		});

		const closeUI = () => {
			editor.editing.view.focus();
			dropdown.isOpen = false;
		};
	}

	private _setUpForm(dropdown: DropdownView, form: CustomFontFamilyInputFormView) {
		form.nameListView.items.delegate('execute').to(dropdown);
	}
}
