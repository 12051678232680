import {hasMatch} from 'Event/attendee-rules/matcher'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import {useEvent} from 'Event/EventProvider'
import {UseFormMethods} from 'react-hook-form'
import {Answer} from 'Event/SubmissionsProvider'
import {Question} from 'organization/Event/QuestionsProvider'

export function useCheckQuestionRules(props: {
  watch: UseFormMethods['watch']
  answers?: Answer[]
}) {
  const {watch, answers: savedAnswers = []} = props
  const {groups, tags} = useAttendeeProfile()
  const {event} = useEvent()
  const {forms} = event

  // Get the current answers that the user is selecting on the questions form.
  const {answers: currentAnswers = []} = watch()

  // Combine the submitted answers and current answers that are not submiited yet.
  const answers = currentAnswers.map((currentAnswer: Answer) => {
    const submittedAnswer = savedAnswers.find(
      (s) => s.question_id === currentAnswer.question_id,
    )

    return {
      ...submittedAnswer,
      ...currentAnswer,
      question_id:
        typeof currentAnswer.question_id === 'string'
          ? parseInt(currentAnswer.question_id)
          : currentAnswer.question_id,
    }
  })

  return (question: Question) => {
    // If no rules have been configured, we'll show the question.
    if (!question.rules || question.rules.length === 0) {
      return true
    }

    const matchesRules = hasMatch(
      {groups, tags, answers, forms},
      question.rules,
    )

    if (matchesRules) {
      return true
    }

    return false
  }
}
