import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {SimpleBlog, useSimpleBlogUpdate} from 'Event/template/SimpleBlog'
import Settings from 'Event/template/SimpleBlog/Dashboard/Hero/HeroConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Dashboard/Hero/HeroConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {
  useAutoUpdate,
  useTemplateEditor,
} from 'organization/Event/TemplateEditor'
import {usePruneAssets} from 'lib/asset'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export function HeroConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useSimpleBlogUpdate()
  const {handleSubmit, control, register, watch, formState} = useForm()
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<SimpleBlog>()

  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const welcomeText = replaceAtPath(data, 'welcomeText', '{{welcome_text}}')

    pruneAssets({heroImage: data.heroImage}, saved)
    update({
      ...data,
      localization: addTranslation({
        welcome_text: welcomeText ?? '',
      }),
    })

    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      title="Hero"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
