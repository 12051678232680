import React from 'react'
import Button from '@material-ui/core/Button'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function AddBlogPostButtonButton(props: {
  postId: string
  onAdd?: (button: NavButtonWithSize) => void
}) {
  const {postId, onAdd} = props
  const update = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const add = () => {
    const newButton: NavButtonWithSize = {
      text: 'Button',
      link: '',
      size: 12,
      rules: [],
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      isCertificateButton: false,
      areaId: null,
      formId: null,
      certificateId: null,
      actionId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
      fontSize: 16,
      padding: 8,
      height: 34,
      zapierTag: null,
      highLevelTag: null,
      image: '',
      backgroundColor: '#000000',
      textColor: '#FFFFFF',
      hoverTextColor: '#FFFFFF',
      disableHover: true,
      webhook: null,
    }

    if (onAdd) {
      onAdd(newButton)
      return
    }

    generateHashId([
      'blogpost_button',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      const componentId = `blogpost_button_${id}`
      update({
        blogPosts: {
          [postId]: {
            buttons: {
              [componentId]: {...newButton, text: `{{${componentId}_text}}`},
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_text`]: 'Button',
        }),
      })
    })
  }

  return (
    <Button
      color="primary"
      aria-label="add new button"
      onClick={add}
      variant="outlined"
      fullWidth
    >
      Add Button
    </Button>
  )
}
