import React from 'react'
import {SignUpProps} from 'Event/auth/SignUp'
import styled from 'styled-components'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {rgba} from 'lib/color'
import Description from 'Event/auth/SignUp/Description'
import {useGuestVariables} from 'Event'
import EmailField from 'Event/auth/Login/EmailField'
import SubmitButton from 'Event/auth/SignUp/SubmitButton'
import {
  RightContainer,
  FormContainer,
} from 'Event/template/NiftyFifty/Login/RightPanel'
import FirstNameField from 'Event/auth/SignUp/FirstNameField'
import LastNameField from 'Event/auth/SignUp/LastNameField'
import PhoneNumberField from 'Event/auth/SignUp/PhoneNumberField'
import ErrorMessage from 'Event/auth/SignUp/ErrorMessage'
import Visible from 'lib/ui/layout/Visible'

export default function RightPanel(props: SignUpProps) {
  const template = useNiftyFiftyTemplate()
  const {login, textColor} = template

  return (
    <RightContainer
      backgroundColor={rgba(
        login.loginRightPanel.backgroundColor,
        login.loginRightPanel.backgroundOpacity,
      )}
    >
      <FormContainer
        textColor={textColor}
        descriptionTextColor={login.description.color}
      >
        <Content {...props} />
      </FormContainer>
    </RightContainer>
  )
}

export function Content(props: SignUpProps) {
  const template = useNiftyFiftyTemplate()
  const {signUp} = template
  const v = useGuestVariables()

  return (
    <>
      <Description aria-label="event sign up description">
        {v(signUp.description)}
      </Description>
      <StyledForm onSubmit={props.onSubmit}>
        <FirstNameField {...props} />
        <LastNameField {...props} />
        <EmailField {...props} />
        <Visible when={signUp.hasPhoneNumberField}>
          <PhoneNumberField {...props} />
        </Visible>
        <ErrorMessage error={props.responseError?.message} />
        <SubmitButton {...props} />
      </StyledForm>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
`
