import React from 'react'
import Page from 'Event/template/SimpleBlog/Login/Page'
import {SignUpProps} from 'Event/auth/SignUp'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import {useGuestVariables} from 'Event'
import Description from 'Event/auth/SignUp/Description'
import EmailField from 'Event/auth/Login/EmailField'
import styled from 'styled-components'
import SubmitButton from 'Event/auth/SignUp/SubmitButton'
import FirstNameField from 'Event/auth/SignUp/FirstNameField'
import LastNameField from 'Event/auth/SignUp/LastNameField'
import PhoneNumberField from 'Event/auth/SignUp/PhoneNumberField'
import ErrorMessage from 'Event/auth/SignUp/ErrorMessage'
import Visible from 'lib/ui/layout/Visible'

export default function SignUp(props: SignUpProps) {
  return (
    <Page isPreview={false}>
      <Content {...props} />
    </Page>
  )
}

export function Content(props: SignUpProps) {
  const {signUp} = useSimpleBlogTemplate()
  const v = useGuestVariables()

  return (
    <>
      <Description>{v(signUp.description)}</Description>
      <StyledForm onSubmit={props.onSubmit}>
        <FirstNameField {...props} />
        <LastNameField {...props} />
        <EmailField {...props} />
        <Visible when={signUp.hasPhoneNumberField}>
          <PhoneNumberField {...props} />
        </Visible>
        <ErrorMessage error={props.responseError?.message} />
        <SubmitButton {...props} />
      </StyledForm>
    </>
  )
}

const StyledForm = styled.form`
  width: 100%;
`
