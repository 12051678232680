import React, {useCallback} from 'react'
import {Agenda} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/NiftyFifty/Dashboard/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function AgendaItemConfig(props: {agenda: Agenda; id: string}) {
  const {agenda, id} = props

  const save = useNiftyFiftyUpdate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const {
      agendaList: {items},
    } = data
    const {
      [id]: {text, link, description, ...others},
    } = items

    save({
      localization: addTranslation({
        [`agenda_item_${id}_text`]: text,
        [`agenda_item_${id}_link`]: link,
        [`agenda_item_${id}_description`]: description,
      }),
      agendaList: {
        items: {
          [id]: {
            ...others,
            text: `{{agenda_item_${id}_text}}`,
            link: `{{agenda_item_${id}_link}}`,
            description: `{{agenda_item_${id}_description}}`,
          },
        },
      },
    })
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    save({
      localization: removeTranslations([
        `agenda_item_${id}_text`,
        `agenda_item_${id}_description`,
        `agenda_item_${id}_link`,
      ]),
      agendaList: {
        items: {
          [id]: REMOVE,
        },
      },
    })
  }, [id, save, removeTranslations])

  return (
    <Config title="Agenda" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings agenda={agenda} id={id} />
      </SettingsPanel>
    </Config>
  )
}
