import React from 'react'
import {Question} from 'organization/Event/QuestionsProvider'
import {Tiny} from 'lib/ui/typography/index'
import Box from 'lib/ui/Box'
import Button from 'lib/ui/Button'
import {useSubmissionsExport} from 'organization/Event/Form/FormActions'

export default function ExportSubmission(props: {question?: Question}) {
  if (!props.question) {
    return null
  }
  return <Export question={props.question} />
}

function Export(props: {question: Question}) {
  const endpoint = `/questions/${props.question.id}/submissions`
  const {
    exportSubmissions,
    errorMessage: error,
    successMessage,
    processing,
  } = useSubmissionsExport(endpoint)

  return (
    <Box textAlign="right" mb={3}>
      <Button
        onClick={exportSubmissions}
        variant="outlined"
        aria-label={`export ${props.question.label} submissions`}
        disabled={processing}
        color="primary"
      >
        Download Submission
      </Button>
      <ExportError>{error}</ExportError>
      <Success>{successMessage}</Success>
    </Box>
  )
}

function ExportError(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Tiny color="error">{props.children}</Tiny>
}

function Success(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <Tiny color="primary">{props.children}</Tiny>
}
