import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import styled from 'styled-components'
import {
  Header,
  MenuLeftSide,
  NumHolder,
} from 'organization/Event/Form/FormModeration/Question'
import {InlinePageLoader} from 'lib/ui/layout/FullPageLoader'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useFormSubmissions} from 'lib/event-api/forms/use-form-submissions'
import SubmissionsColumn from 'organization/Event/Form/FormModeration/QATab/SubmissionsColumn'
import {useMoveSubmission} from 'organization/Event/Form/FormModeration/QATab/use-move-submission'
import SectionMenu from 'organization/Event/Form/FormModeration/QATab/SectionMenu'

export default function QATab() {
  const {form} = useForm()
  const moveSubmission = useMoveSubmission({form})

  const {data: incomingSubmissions = []} = useFormSubmissions({
    form,
    status: 'incoming',
  })
  const {data: approvedSubmissions = []} = useFormSubmissions({
    form,
    status: 'approved',
  })
  const {data: onDeckSubmissions = []} = useFormSubmissions({
    form,
    status: 'on_deck',
  })

  const [currentDragingStatus, setCurrentDragingStatus] = useState<string>('')

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const {source, destination} = result

    const sourceStatus = source.droppableId as FormSubmissionStatus
    const destinationStatus = destination.droppableId as FormSubmissionStatus

    moveSubmission({
      fromStatus: sourceStatus,
      fromIndex: source.index,
      toStatus: destinationStatus,
      toIndex: destination.index,
    })
  }

  return (
    <Grid container>
      <Header item xs={4}>
        <MenuLeftSide>
          INCOMING <NumHolder>{incomingSubmissions?.length ?? 0}</NumHolder>
        </MenuLeftSide>
        <MenuRightSide>
          <SectionMenu status="incoming" />
        </MenuRightSide>
      </Header>
      <Header item xs={4}>
        <MenuLeftSide>
          APPROVED <NumHolder>{approvedSubmissions?.length ?? 0}</NumHolder>
        </MenuLeftSide>
        <MenuRightSide>
          <SectionMenu status="approved" />
        </MenuRightSide>
      </Header>
      <Header item xs={4}>
        <MenuLeftSide>
          ON DECK <NumHolder>{onDeckSubmissions?.length ?? 0}</NumHolder>
        </MenuLeftSide>
        <MenuRightSide>
          <SectionMenu status="on_deck" />
        </MenuRightSide>
      </Header>

      <DragDropContext
        onDragEnd={(result) => onDragEnd(result)}
        onDragStart={(res) => {
          setCurrentDragingStatus(res.source.droppableId)
        }}
      >
        <SubmissionsColumn
          submissions={incomingSubmissions}
          status="incoming"
          onMoveRight={(index) =>
            moveSubmission({
              fromStatus: 'incoming',
              fromIndex: index,
              toStatus: 'approved',
              toIndex: 0,
            })
          }
          onDelete={(index) => {
            moveSubmission({
              fromStatus: 'incoming',
              fromIndex: index,
              toStatus: 'deleted',
              toIndex: 0,
            })
          }}
          onAnswer={(index) => {
            moveSubmission({
              fromStatus: 'incoming',
              fromIndex: index,
              toStatus: 'answered',
              toIndex: 0,
            })
          }}
          canDrop={currentDragingStatus !== 'incoming'} // disable re-ordering incoming
        />
        <SubmissionsColumn
          submissions={approvedSubmissions}
          status="approved"
          onMoveLeft={(index) =>
            moveSubmission({
              fromStatus: 'approved',
              fromIndex: index,
              toStatus: 'incoming',
              toIndex: 0,
            })
          }
          onMoveRight={(index) =>
            moveSubmission({
              fromStatus: 'approved',
              fromIndex: index,
              toStatus: 'on_deck',
              toIndex: 0,
            })
          }
          canDrop
          onDelete={(index) => {
            moveSubmission({
              fromStatus: 'approved',
              fromIndex: index,
              toStatus: 'deleted',
              toIndex: 0,
            })
          }}
          onAnswer={(index) => {
            moveSubmission({
              fromStatus: 'approved',
              fromIndex: index,
              toStatus: 'answered',
              toIndex: 0,
            })
          }}
        />
        <SubmissionsColumn
          submissions={onDeckSubmissions}
          status="on_deck"
          onMoveLeft={(index) =>
            moveSubmission({
              fromStatus: 'on_deck',
              fromIndex: index,
              toStatus: 'approved',
              toIndex: 0,
            })
          }
          canDrop
          onDelete={(index) => {
            moveSubmission({
              fromStatus: 'on_deck',
              fromIndex: index,
              toStatus: 'deleted',
              toIndex: 0,
            })
          }}
          onAnswer={(index) => {
            moveSubmission({
              fromStatus: 'on_deck',
              fromIndex: index,
              toStatus: 'answered',
              toIndex: 0,
            })
          }}
        />
      </DragDropContext>
    </Grid>
  )
}

export const Loader = () => {
  return (
    <LoaderHolder>
      <InlinePageLoader />
    </LoaderHolder>
  )
}

const MenuRightSide = styled.div`
  display: flex;
  align-items: center;
`

const LoaderHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]};
  width: 100%;
`
