import React, {useState} from 'react'
import {Speaker} from 'Event/Speakers'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Config from 'organization/Event/Configurable/Config'
import TextField from 'lib/ui/TextField'
import Configurable, {useConfig} from 'organization/Event/Configurable'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import {Controller} from 'react-hook-form'
import TextEditor, {TextEditorContainer} from 'lib/ui/form/TextEditor'
import Slider from 'lib/ui/Slider'
import {useTemplate} from 'Event/TemplateProvider'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'
import styled from 'styled-components'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'

export default function SpeakerConfig(props: {speaker: Speaker; id: string}) {
  const {speaker, id} = props
  const [rules, setRules] = useState(speaker.rules)
  const {
    form: {control, register},
  } = useConfig()
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()
  const set = useSaveTemplate()
  const v = useAttendeeVariables()

  const handleSave = (data: any) => {
    const name = replaceAtPath(
      data,
      `speakers.items.${id}.name`,
      `{{${id}_name}}`,
    )
    const text = replaceAtPath(
      data,
      `speakers.items.${id}.text`,
      `{{${id}_text}}`,
    )

    const saveData = {
      speakers: {
        ...data.speakers,
        items: {
          [id]: {
            ...data.speakers.items[id],
            rules,
          },
        },
      },
      localization: addTranslation({
        [`${id}_name`]: name ?? '',
        [`${id}_text`]: text ?? '',
      }),
    }

    set(saveData)
  }

  const {speakers} = useTemplate()
  const handleRemove = () => {
    set({
      speakers: {
        items: {
          [props.id]: REMOVE,
        },
      },
      localization: removeTranslations([`${id}_name`, `${id}_text`]),
    })
  }

  return (
    <Config title="Speaker Config" onSave={handleSave} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`speakers.items.${id}.isActive`}
          control={control}
          defaultValue={speaker?.isActive ?? true}
          render={({onChange, value}) => (
            <EnabledSwitch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
        <TextField
          name={`speakers.items.${id}.name`}
          label="Speaker Name"
          required
          fullWidth
          inputProps={{
            ref: register,
            'aria-label': 'set speaker name',
          }}
          defaultValue={v(speaker.name)}
        />

        <Controller
          name={`speakers.items.${id}.text`}
          defaultValue={v(speaker.text || '')}
          control={control}
          render={({onChange, value}) => (
            <StyledTextEditorContainer>
              <TextEditor data={value} onChange={onChange} />
            </StyledTextEditorContainer>
          )}
        />

        <Controller
          name={`speakers.items.${id}.image`}
          control={control}
          defaultValue={speaker.image}
          render={({onChange, value}) => (
            <ImageAssetUploader
              onChange={onChange}
              value={value}
              uploadLabel="Image"
              uploadInputProps={{
                'aria-label': 'speaker image upload',
              }}
              width={300}
              height={300}
              canResize
              disableAutoRemove
            />
          )}
        />
        <Controller
          name="speakers.imageSize"
          defaultValue={speakers.imageSize}
          control={control}
          render={({onChange, value}) => (
            <Slider
              min={1}
              hideUnits
              max={11}
              step={1}
              value={value}
              onChange={onChange}
              valueLabelDisplay="auto"
              aria-label="speaker image size"
              label="Image Width"
            />
          )}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules} />
    </Config>
  )
}

export function SpeakerConfigurable(props: {
  children: [JSX.Element, JSX.Element]
}) {
  return <Configurable aria-label="edit speaker">{props.children}</Configurable>
}

const StyledTextEditorContainer = styled(TextEditorContainer)`
  overflow-x: visible !important;
`
