import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import Config from 'organization/Event/Configurable/Config'
import {useConfig} from 'organization/Event/Configurable'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React from 'react'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Slider from 'lib/ui/Slider'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function InputFieldConfig() {
  const saveTemplate = useSaveTemplate()
  const template = useTemplate()
  const {
    form: {register, control},
  } = useConfig()

  const addTranslation = useAddTranslation()

  const save = (data: any) => {
    const firstNameLabel = replaceAtPath(
      data,
      'waiver.firstNameInputLabel',
      '{{waiver_first_name_label}}',
    )
    const lastNameLabel = replaceAtPath(
      data,
      'waiver.lastNameInputLabel',
      '{{waiver_last_name_label}}',
    )

    saveTemplate({
      ...data,
      localization: addTranslation({
        waiver_first_name_label: firstNameLabel ?? '',
        waiver_last_name_label: lastNameLabel ?? '',
      }),
    })
  }

  return (
    <Config title="Input Field" onSave={save}>
      <SettingsPanel>
        <TextField
          label="First Name Input Label"
          name="waiver.firstNameInputLabel"
          defaultValue={template.waiver.firstNameInputLabel}
          inputProps={{
            ref: register,
            'aria-label': 'first name input label',
          }}
        />
        <TextField
          label="Last Name Input Label"
          name="waiver.lastNameInputLabel"
          defaultValue={template.waiver.lastNameInputLabel}
          inputProps={{
            ref: register,
            'aria-label': 'last name input label',
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Input Background</Title>
        <Controller
          name="waiver.input.backgroundColor"
          control={control}
          defaultValue={template.waiver.input.backgroundColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="input background color"
            />
          )}
        />
        <Divider />
        <Title>Typography</Title>
        <Controller
          name="waiver.input.textColor"
          control={control}
          defaultValue={template.waiver.input.textColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
        <Divider />
        <Title>Border</Title>
        <Controller
          name="waiver.input.borderWidth"
          defaultValue={template.waiver.input.borderWidth}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Thickness"
              value={value}
              onChange={onChange}
              aria-label="input border width"
            />
          )}
        />
        <Controller
          name="waiver.input.borderRadius"
          defaultValue={template.waiver.input.borderRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Radius"
              value={value}
              onChange={onChange}
              aria-label="input border radius"
            />
          )}
        />
        <Controller
          name="waiver.input.borderColor"
          control={control}
          defaultValue={template.waiver.input.borderColor}
          render={({value, onChange}) => (
            <ColorPicker label="Color" color={value} onPick={onChange} />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
