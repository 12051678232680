import {UpsellPageTemplate} from '../upsell-page'
import {withDefaults} from './object'

export function fillUpsellPageTemplate(template: UpsellPageTemplate) {
  const backgroundDefaults: UpsellPageTemplate['background'] = {
    image: '',
    color: '',
    opacity: 0,
    position: 'center center',
    repeat: 'none',
  }
  const typography: UpsellPageTemplate['typography'] = {
    font: null,
    fontSize: 20,
    textColor: '#000000',
  }

  return withDefaults(
    {
      sections: {},
      background: backgroundDefaults,
      padding: {top: 0, right: 0, bottom: 0, left: 0},
      typography,
      contentMaxWidth: 1280,
      ticket_id: 0,
    },
    template,
  )
}
