import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from './Styling'
import {AgendaListSettings} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import Settings from './Settings'
import {useAddTranslation} from 'Event/LanguageProvider'

export function AgendaListConfig(props: {agendaList: AgendaListSettings}) {
  const {agendaList} = props

  const save = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const {
      agendaList: {title, description, footer, menuTitle},
    } = data

    save({
      localization: addTranslation({
        [`agenda_list_title`]: title,
        [`agenda_list_menuTitle`]: menuTitle,
        [`agenda_list_description`]: description,
        [`agenda_list_footer`]: footer,
      }),
      agendaList: {
        ...data,
        title: `{{agenda_list_title}}`,
        menuTitle: `{{agenda_list_menuTitle}}`,
        description: `{{agenda_list_description}}`,
        footer: `{{agenda_list_footer}}`,
      },
    })
  }

  return (
    <Config title="Agendas" onSave={handleSave}>
      <SettingsPanel>
        <Settings agendaList={agendaList} />
      </SettingsPanel>
      <StylingPanel>
        <Styling agendaList={agendaList} />
      </StylingPanel>
    </Config>
  )
}
