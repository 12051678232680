import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useConfig} from 'organization/Event/Configurable'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Label from 'lib/ui/TextField/Label'
import FontSelect from 'lib/FontSelect'
import {AgendaListSettings} from 'Event/template/Panels/Dashboard/AgendaList'
import {usePanelsTemplate} from 'Event/template/Panels'

export default function Styling(props: {agendaList: AgendaListSettings}) {
  const {agendaList} = props
  const {
    form: {control},
  } = useConfig()
  const template = usePanelsTemplate()
  const {textColor} = template
  const agendaColor = agendaList.color ? agendaList.color : textColor

  return (
    <>
      <CollapsibleSection
        label="Typography"
        storageKey="dashboard.agendalist.text.styling"
      >
        <Label>Font</Label>
        <Controller
          control={control}
          name={`agendaList.font`}
          defaultValue={agendaList.font || null}
          render={({value, onChange}) => (
            <FontSelect value={value} onChange={onChange} />
          )}
        />
        <Controller
          name={`agendaList.color`}
          control={control}
          defaultValue={agendaColor}
          render={({value, onChange}) => (
            <ColorPicker
              label="Font Color"
              aria-label="font color"
              color={value}
              onPick={onChange}
            />
          )}
        />
      </CollapsibleSection>
      <CollapsibleSection
        label="Separator"
        storageKey="dashboard.agendalist.separator.styling"
      >
        <Controller
          name={`agendaList.separator.color`}
          control={control}
          defaultValue={agendaList.separator?.color || '#ffffff'}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              aria-label="separator color"
              color={value}
              onPick={onChange}
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}
