import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useMarketplacePurchasePageSampleEvents} from 'lib/event-api/event/use-marketplace-purchase-page-templates'
import LoadingGrid from 'organization/Marketplace/config/TemplateSelector/LoadingGrid'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import PurchasePageEventTemplateCard from 'organization/Marketplace/config/TemplateSelector/PurchasePageEventTemplateCard'

interface ObvioEventsGridProps {
  updateTemplate: UpdateTemplateFunction
}

export default function PurchasePageObvioEventsGrid(
  props: ObvioEventsGridProps,
) {
  const {updateTemplate} = props
  const {data: events} = useMarketplacePurchasePageSampleEvents()

  if (!events) {
    return <LoadingGrid />
  }

  if (events.length < 1) {
    return (
      <Typography>You don't have any events with a Purchase Page</Typography>
    )
  }

  return (
    <>
      {events.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <PurchasePageEventTemplateCard
            event={event}
            updateTemplate={updateTemplate}
          />
        </Grid>
      ))}
    </>
  )
}
