import {useTemplate} from 'Event/TemplateProvider'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import React from 'react'

export default function RequiresPasswordSwitch(props: {disabled?: boolean}) {
  const {login} = useTemplate()
  const save = useSaveTemplate()
  const toggleRequiresPassword = (requiresPassword: boolean) => {
    save({
      login: {
        requiresPassword,
      },
    })
  }

  return (
    <StyledEnabledSwitch
      checked={login.requiresPassword}
      onChange={onChangeCheckedHandler(toggleRequiresPassword)}
      aria-label="toggle requires password"
      disabled={props.disabled}
    />
  )
}

const StyledEnabledSwitch = styled(EnabledSwitch)`
  margin-bottom: 0;
`
