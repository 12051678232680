import {Template} from 'Event/template'
import {DEFAULTS, Lefty} from 'Event/template/Lefty'
import {CARDS} from 'Event/template/Cards'
import {PANELS} from 'Event/template/Panels'
import {createHashMap} from 'lib/list'
import {baseProps, navButtonWithSize} from 'Event/template/converter'
import {
  TICKET_RIBBON_LIST,
  TicketRibbonListProps,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/TicketRibbonList'
import {GREY_RIBBON} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbon'
import {
  RESOURCE_LIST,
  ResourceListProps,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList'
import {
  EMOJI_LIST,
  EmojiListProps,
} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/EmojiList'
import {SidebarItemProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'

export function convert(template: Template): Lefty {
  return {
    name: DEFAULTS.name,
    version: DEFAULTS.version,
    ...baseProps(template),
    title: title(template),
    login: login(template),
    footer: footer(template),
    header: header(template),
    checkIn: checkIn(template),
    mainNav: mainNav(template),
    sidebar: sidebar(template),
    speakers: speakers(template),
    featuredSpeakers: featuredSpeakers(template),
    sponsors: sponsors(template),
    postStyles: postStyles(template),
    progressBar: progressBar(template),
    welcomeText: welcomeText(template),
    sidebarItems: sidebarItems(template),
    heroImageSize: heroImageSize(template),
    heroImage: heroImage(template),
    pageBackgroundPosition: pageBackgroundPosition(template),
    logoPosition: logoPosition(template),
    logoPadding: logoPadding(template),
    logoIsCenter: logoIsCenter(template),
    pageBackground: pageBackground(template),
    zoomBackgrounds: zoomBackgrounds(template),
    background: background(template),
    featurePageHeader: featurePageHeader(template),
  }
}

function title(template: Template): Lefty['title'] {
  if ('title' in template) {
    return template.title
  }

  return DEFAULTS.title
}

function footer(template: Template): Lefty['footer'] {
  if ('footer' in template) {
    return template.footer
  }

  return DEFAULTS.footer
}

function header(template: Template): Lefty['header'] {
  if ('header' in template) {
    return {
      ...DEFAULTS.header,
      ...template.header,
    }
  }

  return DEFAULTS.header
}

function checkIn(template: Template): Lefty['checkIn'] {
  if ('progressBar' in template) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.progressBar.step1Text,
      step2Label: template.progressBar.step2Text,
      step3Label: template.progressBar.step3Text,
    }
  }

  if (template.name === PANELS) {
    return {
      ...DEFAULTS.checkIn,
      step1Label: template.step1Label,
      step1Icon: template.step1Icon ?? DEFAULTS.checkIn.step1Icon,
      step2Label: template.step2Label,
      step2Icon: template.step2Icon ?? DEFAULTS.checkIn.step2Icon,
      step3Label: template.step3Label,
      step3Icon: template.step3Icon ?? DEFAULTS.checkIn.step3Icon,
    }
  }

  return template.checkIn
}

function mainNav(template: Template): Lefty['mainNav'] {
  if (template.name === CARDS) {
    return {
      buttons: navButtonWithSize(template.mainNav.buttons),
    }
  }

  return template.mainNav
}

function sidebar(template: Template): Lefty['sidebar'] {
  if ('sidebar' in template) {
    return template.sidebar
  }

  return DEFAULTS.sidebar
}

function speakers(template: Template): Lefty['speakers'] {
  return template.speakers
}

function featuredSpeakers(template: Template): Lefty['speakers'] {
  if ('featuredSpeakers' in template) {
    return template.featuredSpeakers
  }

  return {
    items: {},
  }
}

function sponsors(template: Template): Lefty['sponsors'] {
  return {
    ...DEFAULTS.sponsors,
    isEnabled: template.sponsors.isEnabled,
    title: template.sponsors.title,
    description: template.sponsors.description,
    questionIcon: template.sponsors.questionIcon,
    items: template.sponsors.items,
  }
}

function postStyles(template: Template): Lefty['postStyles'] {
  return template.postStyles
}

function progressBar(template: Template): Lefty['progressBar'] {
  if ('progressBar' in template) {
    return template.progressBar
  }

  if ('checkIn' in template) {
    return {
      ...DEFAULTS.progressBar,
      step1Text: template.checkIn.step1Label,
      step2Text: template.checkIn.step2Label,
      step3Text: template.checkIn.step3Label,
    }
  }

  return {
    ...DEFAULTS.progressBar,
    step1Text: template.step1Label,
    step2Text: template.step2Label,
    step3Text: template.step3Label,
    textColor: template.textColor ?? DEFAULTS.progressBar.textColor,
  }
}

function welcomeText(template: Template): Lefty['welcomeText'] {
  if ('welcomeMessage' in template) {
    return template.welcomeMessage.text ?? DEFAULTS.welcomeText
  }

  if ('hero' in template && 'body' in template.hero) {
    return template.hero.body
  }

  return DEFAULTS.welcomeText
}

function sidebarItems(template: Template): Lefty['sidebarItems'] {
  if ('sidebarItems' in template) {
    return template.sidebarItems
  }

  const sidebarItems: SidebarItemProps[] = []

  if ('resourceList' in template) {
    const resources: ResourceListProps = {
      ...template.resourceList,
      type: RESOURCE_LIST,
      isEnabled: true,
      description: '',
      offsetBottom: 0,
      offsetTop: 0,
      resources: createHashMap(
        template.resourceList.resources.map((item) => {
          return {
            ...item,
            icon: 'fas fa-link',
          }
        }),
      ),
    }

    sidebarItems.push(resources)
  }

  if ('emojiList' in template) {
    const emojis: EmojiListProps = {
      ...template.emojiList,
      type: EMOJI_LIST,
    }
    sidebarItems.push(emojis)
  }

  if ('ticketRibbons' in template) {
    const ribbons: TicketRibbonListProps = {
      type: TICKET_RIBBON_LIST,
      ribbons: createHashMap(
        template.ticketRibbons.map((item) => ({
          ...item,
          name: GREY_RIBBON,
          text: item.hoverText,
          color: item.backgroundColor,
        })),
      ),
    }

    sidebarItems.push(ribbons)
  }

  return createHashMap(sidebarItems)
}

function heroImageSize(template: Template): Lefty['heroImageSize'] {
  if ('hero' in template && 'imageSize' in template.hero) {
    return template.hero.imageSize
  }

  if ('heroImageSize' in template) {
    return template.heroImageSize
  }

  return DEFAULTS.heroImageSize
}

function heroImage(template: Template): Lefty['heroImage'] {
  if ('hero' in template && 'image' in template.hero) {
    return template.hero.image ?? DEFAULTS.heroImage
  }

  if ('heroImage' in template) {
    return template.heroImage
  }

  return DEFAULTS.heroImage
}

function pageBackgroundPosition(
  template: Template,
): Lefty['pageBackgroundPosition'] {
  if ('backgroundPosition' in template) {
    return template.backgroundPosition ?? DEFAULTS.pageBackgroundPosition
  }

  return DEFAULTS.pageBackgroundPosition
}

function logoPosition(template: Template): Lefty['logoPosition'] {
  if ('logoPosition' in template) {
    return template.logoPosition
  }
  return DEFAULTS.logoPosition
}

function logoPadding(template: Template): Lefty['logoPadding'] {
  if ('logoPadding' in template) {
    return template.logoPadding
  }
  return DEFAULTS.logoPadding
}

function logoIsCenter(template: Template): Lefty['logoIsCenter'] {
  if ('logoIsCenter' in template) {
    return template.logoIsCenter
  }
  return DEFAULTS.logoIsCenter
}

function pageBackground(template: Template): Lefty['pageBackground'] {
  if ('dashboardBackground' in template) {
    return {
      ...DEFAULTS.pageBackground,
      ...template.dashboardBackground,
    }
  }

  return DEFAULTS.pageBackground
}

function zoomBackgrounds(template: Template): Lefty['zoomBackgrounds'] {
  return template.zoomBackgrounds
}

function background(template: Template): Lefty['background'] {
  if ('background' in template) {
    return {
      ...DEFAULTS.background,
      ...template.background,
    }
  }

  return DEFAULTS.background
}

function featurePageHeader(template: Template): Lefty['featurePageHeader'] {
  if ('featurePageHeader' in template) {
    return {
      ...DEFAULTS.featurePageHeader,
      ...template.featurePageHeader,
    }
  }

  return DEFAULTS.featurePageHeader
}

function login(template: Template): Lefty['login'] {
  return {
    ...DEFAULTS.login,
    ...template.login,
    passwordReset: {
      ...DEFAULTS.login.passwordReset,
      ...template.login.passwordReset,
    },
  }
}
