import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {withStyles} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import {onChangeCheckedHandler, onUnknownChangeHandler} from 'lib/dom'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {spacing} from 'lib/ui/theme'
import RoomList from 'organization/Event/Area/RoomList'
import {useArea} from 'organization/Event/Area/AreaProvider'
import {useAreaRoutes} from 'organization/Event/Area/AreaRoutes'
import React, {useState} from 'react'
import Page from 'organization/Event/Page'
import HasPermission from 'organization/HasPermission'
import {EVENT_SETTINGS, START_AREAS} from 'organization/PermissionsProvider'
import ExportAreaAttendees from 'organization/Event/Area/ExportAreaAttendees'
import ClearRoomAssignmentsButton from 'organization/Event/Area/ClearRoomAssignmentsButton'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import {useToggle} from 'lib/toggle'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {useRooms} from 'organization/Event/Area/RoomsProvider'
import {
  Area as AreaModel,
  BALANCED,
  LOAD_BALANCE_STRATEGIES,
} from 'organization/Event/AreasProvider'
import NameField from 'organization/Event/Area/NameField'
import DeleteAreaButton from 'organization/Event/Area/DeleteAreaButton'
import OfflineTextConfig from 'organization/Event/Area/OfflineTextConfig'
import RegistrationSwitch from 'organization/Event/Area/RegistrationSwitch'
import {ENTERPRISE} from 'obvio/Billing/plans'
import IfOwnerHasPlan from 'organization/auth/IfOwnerPlan'
import Tooltip from '@material-ui/core/Tooltip'
import PageBreadcrumbs, {
  PageBreadcrumbProps,
  ZoomAreasBreadcrumbs,
} from 'organization/Event/Page/PageBreadcrumbs'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import AreaRegistrationURL from 'organization/Event/Area/AreaRegistrationURL'

const SETTING_CANNOT_BE_CHANGED_DESCRIPTION =
  'This setting can only be modified at the time that you create the area.'

const CONFIRM_TURN_OFF_TECH_CHECK_REASSIGN =
  "This area is currently assigned to Tech Check.  If you turn this setting off, and an attendee hasn't completed their tech check, they may see an offline message when they try to check in and their original room is unavailable.  Are you sure you wish to disable?"

export default function Area() {
  const {area, update, processing} = useArea()
  const routes = useAreaRoutes()
  const {rooms, totalAttendees} = useRooms()
  const areaRoutes = useAreaRoutes()
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)
  const {
    flag: showingConfirmReassignDialog,
    toggle: toggleConfirmReassignDialog,
  } = useToggle()

  const updateKey = <T extends keyof AreaModel>(key: T) => (
    val: AreaModel[T],
  ) =>
    update({
      [key]: val,
    })

  /**
   * If an area is assigned to Tech Check, we want to confirm with the
   * user that they'd really want to turn OFF re-assign.
   * @param reassign
   * @returns
   */
  const handleSetReassign = (reassign: boolean) => {
    // Not TC, free to turn on/off
    if (!area.is_tech_check) {
      update({
        reassign_on_offline: reassign,
      })
      return
    }

    // Don't need to confirm on turning on
    if (reassign) {
      update({
        reassign_on_offline: reassign,
      })
      return
    }

    toggleConfirmReassignDialog()
  }

  const turnOffTechCheckReassign = () => {
    /**
     * Only allow turning off TC re-assign via confirm dialog
     */
    if (!showingConfirmReassignDialog) {
      return
    }

    update({reassign_on_offline: false})
    toggleConfirmReassignDialog()
  }

  return (
    <>
      <ConfirmDialog
        description={CONFIRM_TURN_OFF_TECH_CHECK_REASSIGN}
        onConfirm={turnOffTechCheckReassign}
        showing={showingConfirmReassignDialog}
        onCancel={toggleConfirmReassignDialog}
      />
      <ZoomAreasBreadcrumbs page={area.name}>
        <Page>
          <ErrorAlert>{error}</ErrorAlert>
          <HasPermission required={EVENT_SETTINGS}>
            <Box>
              <Left>
                <ExportAreaAttendees area={area} />
                <RelativeLink to={areaRoutes.rules} disableStyles>
                  <RulesButton variant="outlined" aria-label="Area Rules">
                    Rules
                  </RulesButton>
                </RelativeLink>
              </Left>
              <div>
                <ClearRoomAssignmentsButton
                  onError={setError}
                  clearError={clearError}
                />
              </div>
            </Box>
          </HasPermission>
          <NameField />
          <DeleteAreaButton />
          <HasPermission required={[EVENT_SETTINGS, START_AREAS]}>
            <Box>
              <FormControlLabel
                disabled={processing}
                control={
                  <Switch
                    checked={area.is_open}
                    onChange={onChangeCheckedHandler(updateKey('is_open'))}
                    color="primary"
                    inputProps={{
                      'aria-label': 'toggle area open status',
                    }}
                  />
                }
                label="Open Area"
              />
              <HasPermission required={EVENT_SETTINGS}>
                <FormControlLabel
                  disabled={processing}
                  control={
                    <Switch
                      checked={area.reassign_on_offline}
                      onChange={onChangeCheckedHandler(handleSetReassign)}
                      color="primary"
                      inputProps={{
                        'aria-label': 'toggle re-assign on offline',
                      }}
                    />
                  }
                  label="Re-assign on offline"
                />
              </HasPermission>
            </Box>
          </HasPermission>
          <HasPermission required={EVENT_SETTINGS}>
            <FormControl required component="fieldset" fullWidth disabled>
              <Tooltip
                title={SETTING_CANNOT_BE_CHANGED_DESCRIPTION}
                placement="bottom-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={area.requires_approval}
                      onChange={onChangeCheckedHandler(
                        updateKey('requires_approval'),
                      )}
                      inputProps={{
                        'aria-label': 'toggle requires approval',
                      }}
                    />
                  }
                  label="Requires approval?"
                />
              </Tooltip>
            </FormControl>
            <FormControl required component="fieldset" fullWidth disabled>
              <Tooltip
                title={SETTING_CANNOT_BE_CHANGED_DESCRIPTION}
                placement="bottom-start"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={area.allows_multiple_devices}
                      inputProps={{
                        'aria-label': 'toggle allows multiple devices',
                      }}
                      onChange={onChangeCheckedHandler(
                        updateKey('allows_multiple_devices'),
                      )}
                    />
                  }
                  label="Can the same user use multiple devices to join at the same time?"
                />
              </Tooltip>
            </FormControl>
            <IfOwnerHasPlan plan={ENTERPRISE}>
              <FormControl>
                <FormControlLabel
                  control={<RegistrationSwitch />}
                  label="Registration Enabled"
                />
              </FormControl>
              <AreaRegistrationURL area={area} />
            </IfOwnerHasPlan>
            <IfOwnerHasPlan plan={ENTERPRISE}>
              <StyledSelect
                value={area.load_balance_strategy ?? BALANCED}
                disabled={processing}
                fullWidth
                onChange={onUnknownChangeHandler(
                  updateKey('load_balance_strategy'),
                )}
                label="Attendee Room Distribution"
                aria-label="pick load balancing strategy"
              >
                {LOAD_BALANCE_STRATEGIES.map((strategy) => (
                  <StyledOption
                    key={strategy}
                    value={strategy}
                    aria-label={`pick ${strategy}`}
                  >
                    {strategy}
                  </StyledOption>
                ))}
              </StyledSelect>
            </IfOwnerHasPlan>
            <OfflineTextSection />
            <RelativeLink to={routes.rooms.create} disableStyles>
              <CreateRoomButton
                variant="outlined"
                color="primary"
                aria-label="create rooms"
              >
                Create Rooms
              </CreateRoomButton>
            </RelativeLink>
          </HasPermission>
          <Typography>Total Attendees: {totalAttendees}</Typography>
          <RoomList rooms={rooms} isTechCheck={area.is_tech_check} />
        </Page>
      </ZoomAreasBreadcrumbs>
    </>
  )
}

function OfflineTextSection() {
  const {flag: configVisible, toggle: toggleConfig} = useToggle()

  return (
    <>
      <OfflineTextConfig isVisible={configVisible} onClose={toggleConfig} />
      <OfflineTextButton
        onClick={toggleConfig}
        variant="outlined"
        color="primary"
        aria-label="offline text button"
      >
        Offline Text
      </OfflineTextButton>
    </>
  )
}

export function AreaBreadcrumbs(props: PageBreadcrumbProps) {
  const areaRoutes = useAreaRoutes()
  const {area} = useArea()

  return (
    <PageBreadcrumbs
      parent={{
        title: area.name,
        url: areaRoutes.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

const OfflineTextButton = withStyles({
  root: {
    marginBottom: spacing[4],
    display: 'block',
  },
})(Button)

const CreateRoomButton = withStyles({
  root: {
    marginBottom: spacing[4],
  },
})(Button)

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
`

const RulesButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing[2]};
`

const StyledOption = styled(Option)`
  text-transform: capitalize;
`

const StyledSelect = styled(Select)`
  text-transform: capitalize;
`
