import {CHECKBOX, CheckboxQuestion} from 'Event/Question/Checkbox'
import {CurrencyQuestion} from 'Event/Question/Currency'
import {
  LongAnswerQuestion,
  LONG_ANSWER_TEXT,
} from 'Event/Question/LongAnswerText'
import {RADIO, RadioQuestion} from 'Event/Question/Radio'
import {SELECT, SelectQuestion} from 'Event/Question/Select'
import {
  SelectPriorityQuestion,
  SELECT_PRIORITY,
} from 'Event/Question/SelectPriority'
import {
  ShortAnswerQuestion,
  SHORT_ANSWER_TEXT,
} from 'Event/Question/ShortAnswerText'
import {HasRules} from 'Event/attendee-rules'
import {Form} from 'organization/Event/FormsProvider'
import React, {useEffect, useState} from 'react'

export type QuestionBase = HasRules & {
  // ID can be a number (saved record), or a string for an unsaved
  // question using a UUID as a placeholder
  id: number | string
  label: string
  helper_text: string | null
  is_required: boolean
  validation_rule: string | null
}

export type HasCharacterLimit = {
  character_limit: number | null
  character_limit_error_message: string | null
}

export type Option = {
  value: string
  action_key: null | string
  action_id: null | number
}

export type HasOptions = {
  options: Option[]
}

export type Question = TextQuestion | CurrencyQuestion | QuestionWithOptions

export type TextQuestion = ShortAnswerQuestion | LongAnswerQuestion

export type QuestionWithOptions =
  | RadioQuestion
  | SelectQuestion
  | CheckboxQuestion
  | SelectPriorityQuestion

/**
 * Validation Rules
 *
 * These types match up to the types defined at the API. We duplicate
 * them here assuming they rarely change, and this way we avoid
 * re-fetching types.
 */

export const EMAIL = 'email'
export const PHONE_NUMBER = 'phone_number'
export const ALPHA_NUMERIC = 'alpha_numeric'
export const NUMERIC = 'numeric'

export interface QuestionsContextProps {
  questions: Question[]
  add: (question: Question) => void
  update: (question: Question) => void
  remove: (question: Question) => void
  setQuestions: (questions: Question[]) => void
}

export const QuestionsContext = React.createContext<
  QuestionsContextProps | undefined
>(undefined)

export default function QuestionsProvider(props: {
  children: React.ReactElement
  form: Form
}) {
  const [questions, setQuestions] = useState<Question[]>([])
  const {form} = props

  useEffect(() => {
    setQuestions(form.questions)
  }, [form])

  const add = (question: Question) => {
    const added = [...questions, question]
    setQuestions(added)
  }

  const update = (target: Question) => {
    const updated = questions.map((q) => {
      const isTarget = q.id === target.id
      if (isTarget) {
        return target
      }

      return q
    })

    setQuestions(updated)
  }

  const remove = (target: Question) => {
    const remaining = questions.filter((q) => q.id !== target.id)
    setQuestions(remaining)
  }

  return (
    <QuestionsContext.Provider
      value={{questions, add, update, remove, setQuestions}}
    >
      {props.children}
    </QuestionsContext.Provider>
  )
}

export function useQuestions() {
  const context = React.useContext(QuestionsContext)
  if (context === undefined) {
    throw new Error(`useQuestions must be used within a QuestionsProvider`)
  }

  return context
}

export function isQuestionWithOptions(question?: {
  type: Question['type']
}): question is QuestionWithOptions {
  if (!question) {
    return false
  }

  return (
    question.type === RADIO ||
    question.type === SELECT ||
    question.type === CHECKBOX ||
    question.type === SELECT_PRIORITY
  )
}

export function hasOptions(question: Question) {
  return isQuestionWithOptions(question) && question.options.length > 0
}

export function isTextQuestion(question?: {
  type: Question['type']
}): question is TextQuestion {
  if (!question) {
    return false
  }

  return (
    question.type === SHORT_ANSWER_TEXT || question.type === LONG_ANSWER_TEXT
  )
}
