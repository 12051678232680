import React, {useEffect, useRef} from 'react'
import {useHistory} from 'react-router'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import {ReactComponent as Bot} from 'assets/images/icons/white-bot.svg'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import ConfirmUnsavedChangesDialog from 'organization/Event/Configurable/ConfirmUnsavedChangesDialog'
import Complete from 'organization/Obie/Blocks/ProcessForm/Complete'
import ProgressBar from 'organization/Obie/Blocks/ProcessForm/ProgressBar'
import {useObieQuestions} from 'organization/Obie/ObieQuestionsProvider'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {Block, useObieService} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'

export default function ProcessForm() {
  const {
    org_id: _,
    category: category_id,
    block: block_id,
    profile: profile_id,
  } = useParams<{
    org_id: string
    category: string
    block: string
    profile: string
  }>()

  const {routes} = useOrganization()
  const {
    clickUnsavedDiscard,
    clickUnsavedCancel,
    goToNext,
    initializeBlock,
    openUnsavedDialog,
    submitCompletion,
  } = useObieQuestions()
  const {
    awaitingCompletion,
    blocks,
    completion,
    getBlock,
    profileId,
    setProfileId,
  } = useObieService()
  const history = useHistory()

  const blockRef = useRef<Block>()

  // Not to be confused between profile_id and profileId. The former is coming
  // out of the query string. The latter is coming out of state.
  useEffect(() => {
    if (!profile_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setProfileId(parseInt(profile_id))
  }, [setProfileId, profile_id])

  useEffect(() => {
    // We need the category_id and block_id and profile_id to be able to do this
    // work.
    if (!category_id || !block_id || profileId === undefined) {
      return
    }

    // Now that blocks are loaded per profile, it's possible that user could hit
    // refresh on a form page, in which case we wouldn't have any blocks loaded.
    // In this situation, we're going to send them back to the profileRoute. BUT,
    // we only want to redirect when we're not awaiting a completion.
    if (!blocks.length && !awaitingCompletion && !completion) {
      return history.push(
        profileRoute(routes, String(category_id), String(profileId)).root,
      )
    }

    // If we don't already have a Block retrieved...
    if (!blockRef.current) {
      // We need the Block, based on query params from the listing page.
      blockRef.current = getBlock(parseInt(category_id), parseInt(block_id))

      // If there are no Questions in the Block that user has started, all we can
      // do is submitCompletion(), there's no form that needs to be rendered here,
      // so we don't need to set the Questions into state.
      if (!blockRef.current.questions.length) {
        submitCompletion(blockRef.current.id)

        return
      }

      // Now that we have a Block out of the provider, we want to set the Questions
      // back into state, so we have everything we need to work from.
      initializeBlock(blockRef.current)
    }
  }, [
    awaitingCompletion,
    block_id,
    blocks,
    category_id,
    completion,
    getBlock,
    history,
    initializeBlock,
    profileId,
    routes,
    submitCompletion,
  ])

  const handleSubmit = () => {
    goToNext(profileId, true)
  }

  return (
    <Page>
      <ConfirmUnsavedChangesDialog
        expandable={false}
        onDiscard={clickUnsavedDiscard}
        onCancel={clickUnsavedCancel}
        open={openUnsavedDialog}
        onSubmit={handleSubmit}
      />

      <ProgressBar hidden={Boolean(completion)} />

      <Header>
        <Logo>
          <TitleBox to={routes.obie.root}>
            <IconBox>
              <Bot />
            </IconBox>
            <Title>OBIE</Title>
          </TitleBox>
        </Logo>
      </Header>

      <Complete category_id={category_id} />

      <FormContent block={blockRef.current} />
    </Page>
  )
}

function FormContent(props: {block?: Block}) {
  const {block} = props
  const {completion, profileId} = useObieService()
  const {renderQuestion} = useObieQuestions()

  // If there is a completion available, we don't want to render anything...
  if (completion || !block) {
    return null
  }

  return (
    <Content>
      {block.questions.map((question) => renderQuestion(profileId, question))}
    </Content>
  )
}

const Page = styled.div`
  background: #131d34;
  width: 100%;
  overflow-x: hidden;
  min-height: 100%;
`

const Header = styled.div`
  display: flex;
`

const Logo = styled.div`
  display: block;
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleBox = styled(RelativeLink)`
  display: flex;
  justify-content: start;
  width: 100%;
  padding: ${(props) => props.theme.spacing[8]};

  &:hover {
    text-decoration: none !important;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: ${(props) => props.theme.spacing[2]};
`

const Content = styled.div`
  position: relative;
  display: block;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
  }
`
