import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import {useSimpleBlogTemplate} from 'Event/template/SimpleBlog'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Box from 'lib/ui/Box'
import ImageAssetUploader from 'lib/asset/ImageAssetUploader'
import Title from 'lib/ui/ConfigPanel/Title'
import BackgroundPositionSelector from 'lib/ui/BackgroundPositionSelector'
import {useToggleArray} from 'lib/toggle'
import PositionSelectorButton from 'lib/ui/BackgroundPositionSelector/BackgroundPositionSelectorButton'

export default function Settings(props: {
  control: UseFormMethods['control']
  watch: UseFormMethods['watch']
}) {
  const {control, watch} = props
  const template = useSimpleBlogTemplate()
  const headerIsEnabled = watch('header.isEnabled')

  const [showingPositionSelector, togglePositionSelector] = useToggleArray()
  const [
    showingDashboardPositionSelector,
    toggleDashboardPositionSelector,
  ] = useToggleArray()

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Controller
          name="header.isEnabled"
          defaultValue={template.header.isEnabled}
          control={control}
          render={({value, onChange}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              arial-label="config header background image visible switch"
              labelPlacement="end"
              label={value ? 'Header Enabled' : 'Header Disabled'}
            />
          )}
        />
        <Controller
          name="header.shadowEnabled"
          defaultValue={template.header.shadowEnabled}
          control={control}
          render={({value, onChange}) => (
            <DropShadowToggle
              isVisible={headerIsEnabled ?? template.header.isEnabled}
              enableShadow={value}
              setEnableShadow={onChange}
            />
          )}
        />
      </Box>
      <Title>Logo</Title>
      <Controller
        name="logo"
        control={control}
        defaultValue={template.logo}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            removeButtonProps={{
              'aria-label': 'remove logo',
            }}
            uploadInputProps={{
              'aria-label': 'logo upload',
            }}
            disableAutoRemove
          />
        )}
      />
      <Title>Header Background</Title>
      <Controller
        name="header.background"
        control={control}
        defaultValue={template.header.background}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'header background upload',
            }}
            disableAutoRemove
            additionalActions={
              <PositionSelectorButton onClick={togglePositionSelector} />
            }
          />
        )}
      />
      <Controller
        name="header.backgroundPosition"
        control={control}
        defaultValue={template.header.backgroundPosition}
        render={({onChange, value}) => (
          <BackgroundPositionSelector
            showing={showingPositionSelector}
            onChange={onChange}
            value={value}
            label="Background Position"
          />
        )}
      />

      <Title>Dashboard Background</Title>
      <Controller
        name="dashboardBackground.image"
        control={control}
        defaultValue={template.dashboardBackground.image}
        render={({onChange, value}) => (
          <ImageAssetUploader
            onChange={onChange}
            value={value}
            uploadLabel="Upload"
            canResize
            uploadInputProps={{
              'aria-label': 'dashboard background upload',
            }}
            disableAutoRemove
            additionalActions={
              <PositionSelectorButton
                onClick={toggleDashboardPositionSelector}
              />
            }
          />
        )}
      />

      <Controller
        name="dashboardBackground.position"
        control={control}
        defaultValue={template.dashboardBackground.position}
        render={({onChange, value}) => (
          <BackgroundPositionSelector
            showing={showingDashboardPositionSelector}
            onChange={onChange}
            value={value}
            label="Background Position"
          />
        )}
      />
    </>
  )
}

function DropShadowToggle(props: {
  isVisible: boolean
  enableShadow?: boolean
  setEnableShadow: (disable: boolean) => void
}) {
  const {enableShadow, setEnableShadow, isVisible} = props

  if (!isVisible) {
    return null
  }

  const label = enableShadow ? 'Shadow Enabled' : 'Shadow Disabled'

  return (
    <Switch
      checked={enableShadow}
      onChange={onChangeCheckedHandler(setEnableShadow)}
      arial-label="config header dropShawdowVisible visible switch"
      labelPlacement="end"
      label={label}
    />
  )
}
