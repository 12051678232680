import React from 'react'

export interface ThankYouPageContextProps {
  loginUrl: string | null
}

export const ThankYouPageContext = React.createContext<
  ThankYouPageContextProps | undefined
>(undefined)

export function useThankYouPageContext() {
  const context = React.useContext(ThankYouPageContext)
  if (context === undefined) {
    throw new Error(
      'useThankYouPage must be used within a ThankYouPageProvider',
    )
  }

  return context
}
