import {
  BulletedListBlock,
  createBulletItem,
} from 'Event/Marketplace/Block/BulletedList'
import {uuid} from 'lib/uuid'

const defaultItems = {
  [uuid()]: createBulletItem(),
}

export const createBulletedListBlock = (): BulletedListBlock => ({
  type: 'BulletedList',
  visibility: 'all',
  background: {
    image: '',
    color: '',
    opacity: 0,
  },
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  },
  alignment: {
    vertical: 'start',
    horizontal: 'left',
  },
  border: {
    width: 0,
    color: '#FFFFFF',
    radius: 0,
  },
  icon: 'fa-solid fa-circle-small',
  font: null,
  fontSize: 24,
  color: '#3490DC',
  iconColor: '#3490DC',
  iconSize: 24,
  bulletSpace: 10,
  textSpace: 10,
  items: defaultItems,
})
