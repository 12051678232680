import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {client} from 'lib/ui/api-client'
import {useQuery} from 'react-query'

interface ListEventTicketsParams {
  name: string
}

export function useEventTickets(params?: ListEventTicketsParams) {
  const {event} = useEvent()

  return useQuery({
    queryKey: ['events', event.id, 'tickets'],
    queryFn: () => {
      const urlSearchParams = new URLSearchParams()
      if (params?.name) {
        urlSearchParams.append('name', params.name)
      }

      const query =
        Array.from(urlSearchParams).length > 0
          ? `?${urlSearchParams.toString()}`
          : ''

      return client
        .get<{
          tickets: MarketplaceTicket[]
        }>(marketplaceApi(`/events/${event.id}/tickets${query}`))
        .then(({tickets}) => tickets)
    },
  })
}
