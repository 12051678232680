import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import TypographySection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/TypographySection'
import BackgroundSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BackgroundSection'
import SizeSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/SizeSection'
import BorderSection from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig/Styling/BorderSection'
import ContentInput from 'Event/Dashboard/components/NavButton/NavButtonConfig/ContentInput'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'

export default function SubmitButtonConfig() {
  const {
    form: {register, control, watch},
  } = useConfig()
  const saveTemplate = useSaveTemplate()
  const {
    setPasswordForm: {button},
  } = useTemplate()
  const addTranslation = useAddTranslation()
  const v = useAttendeeVariables()

  const handleSave = (data: any) => {
    const label = replaceAtPath(data, `text`, `{{set_password_form_button}}`)

    saveTemplate({
      setPasswordForm: {
        button: data,
      },
      localization: addTranslation({
        set_password_form_button: label ?? '',
      }),
    })
  }

  return (
    <Config title="Submit Button" onSave={handleSave}>
      <SettingsPanel>
        <ContentInput
          button={{
            ...button,
            text: v(button.text),
          }}
          control={control}
          register={register}
          watch={watch}
        />
      </SettingsPanel>
      <StylingPanel>
        <TypographySection
          button={button}
          control={control}
          storageKey="step1.submitButton.config.styling.typography"
        />
        <BackgroundSection
          button={button}
          control={control}
          watch={watch}
          storageKey="step1.submitButton.config.styling.background"
        />
        <SizeSection
          button={button}
          control={control}
          disableNewLine
          sizeUnit="percent"
          storageKey="step1.submitButton.config.styling.size"
        />
        <BorderSection
          button={button}
          control={control}
          storageKey="step1.submitButton.config.styling.border"
        />
      </StylingPanel>
    </Config>
  )
}
