import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import GroupInput from 'lib/ui/form/GroupInput'
import TagsInput from 'lib/ui/form/TagsInput'
import {Group, Tag} from 'organization/Event/AttendeesProvider'
import React, {useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import Switch from 'lib/ui/form/Switch'
import {onChangeCheckedHandler} from 'lib/dom'

export default function PageSettings() {
  const {signUp} = useTemplate()
  const save = useSaveTemplate()

  const {
    form: {control, watch},
  } = useConfig()
  const hasPhoneNumberField = watch(
    'hasPhoneNumberField',
    signUp.hasPhoneNumberField,
  )

  const [tags, setTags] = useState<Tag[]>(signUp.tags || [])
  const [groups, setGroups] = useState<Group[]>(signUp.groups || [])

  const addGroup = () => {
    const added = [...groups, {key: '', value: ''}]
    setGroups(added)
  }

  const updateGroup = (targetIndex: number) => (updated: Group) => {
    const updatedList = groups.map((g, i) => {
      const isTarget = i === targetIndex
      if (isTarget) {
        return updated
      }

      return g
    })

    setGroups(updatedList)
  }

  const removeGroup = (targetIndex: number) => () => {
    const updated = [...groups]
    updated.splice(targetIndex, 1)
    setGroups(updated)
  }

  const handleSave = (updates: any) => {
    save({
      signUp: {
        ...updates,
        tags,
        groups,
      },
    })
  }

  return (
    <Config title="Sign Up Page Settings" onSave={handleSave}>
      <SettingsPanel>
        <Controller
          name="hasPhoneNumberField"
          defaultValue={signUp.hasPhoneNumberField ?? false}
          control={control}
          render={({onChange, value}) => (
            <Switch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
              aria-label="toggle collect phone number"
              labelPlacement="end"
              label="Collect Phone number"
            />
          )}
        />
        <PhoneNumberField control={control} hidden={!hasPhoneNumberField} />
      </SettingsPanel>
      <RulesPanel>
        <>
          <TagsInput
            value={tags}
            onChange={setTags}
            name="tags"
            aria-label="tags"
            label="Tags"
          />
          {groups.map((group, index) => (
            <GroupInput
              key={index}
              group={group}
              onChange={updateGroup(index)}
              remove={removeGroup(index)}
            />
          ))}
          <Button
            aria-label="add group"
            variant="outlined"
            color="primary"
            onClick={addGroup}
            fullWidth
            size="large"
          >
            Add Group
          </Button>
        </>
      </RulesPanel>
    </Config>
  )
}

function PhoneNumberField(props: {
  control: UseFormMethods['control']
  hidden: boolean
}) {
  const {control, hidden} = props

  const {signUp} = useTemplate()

  if (hidden) {
    return null
  }

  return (
    <Controller
      name="phoneNumberRequired"
      control={control}
      defaultValue={signUp.phoneNumberRequired ?? false}
      render={({value, onChange}) => (
        <Switch
          checked={value}
          onChange={onChangeCheckedHandler(onChange)}
          aria-label="toggle phone number required"
          labelPlacement="end"
          label="Phone number required"
        />
      )}
    />
  )
}
