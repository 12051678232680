import React from 'react'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import {Draggable} from 'react-beautiful-dnd'
import {formatPrice} from 'lib/currency'
import {SHORT_DATE_TIME_FORMAT, useLocalTime} from 'lib/date-time'
import EditButton from 'lib/ui/Button/CustomButton'
import {
  InvisibleIcon,
  MenuIcon,
  PowerOffIcon,
  PowerOnIcon,
  VisibleIcon,
} from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {colors} from 'lib/ui/theme'
import {routesWithValue} from 'lib/url'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {
  CopyCell,
  TableCell,
  TableRow,
  DateTableCell,
  IndicatorTableCell,
  MenuTableCell,
  PriceTableCell,
} from 'organization/Marketplace/Tickets/TicketListing'
import CopyIconButton from 'lib/ui/IconButton/CopyIconButton'
import {useEvent} from 'Event/EventProvider'
import {useDeleteTicket} from 'lib/marketplace-api/tickets/use-delete-ticket'
import {useUpdateTicket} from 'lib/marketplace-api/tickets/use-update-ticket'
import {useDuplicateTicket} from 'lib/marketplace-api/tickets/use-duplicate-ticket'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'

export default function TicketItem(props: {
  index: number
  ticket: MarketplaceTicket
}) {
  const {index, ticket} = props
  const eventRoutes = useEventRoutes()
  const {event} = useEvent()

  const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

  const {mutate: deleteTicket, isLoading: isDeletingTicket} = useDeleteTicket({
    ticket,
  })

  const {mutate: updateTicket, isLoading: isUpdatingTicket} = useUpdateTicket({
    ticket,
  })
  const {
    mutate: duplicateTicket,
    isLoading: isDuplicatingTicket,
  } = useDuplicateTicket({
    ticket,
  })

  const toggleTicket = (property: keyof MarketplaceTicket) => {
    updateTicket({
      ...ticket,
      [property]: !ticket[property],
    })
  }

  const isProcessing =
    isUpdatingTicket || isDeletingTicket || isDuplicatingTicket

  const localTime = useLocalTime()

  const localizedAvailableFrom = localTime(
    ticket.available_from || '',
    SHORT_DATE_TIME_FORMAT,
  )
  const availableFrom = ticket.available_from ? localizedAvailableFrom : '--'

  const localizedAvailableTo = localTime(
    ticket.available_to || '',
    SHORT_DATE_TIME_FORMAT,
  )
  const availableTo = ticket.available_to ? localizedAvailableTo : '--'

  const ticketRoute = (ticket: MarketplaceTicket) =>
    routesWithValue(
      ':ticket',
      String(ticket.id),
      eventRoutes.marketplace.tickets[':ticket'],
    )

  const copyURL = (slug: string) => {
    const url = `${marketplaceStoreUrl}/${event.slug}?ticket=${slug}`
    navigator.clipboard.writeText(url)
  }

  return (
    <Draggable draggableId={`ticket-${index}`} index={index}>
      {(provided) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          aria-label="ticket-item"
        >
          <TableCell>
            <RelativeLink to={ticketRoute(ticket).root}>
              <EditButton
                variant="text"
                textColor={colors.primary}
                aria-label="edit"
              >
                {ticket.name}
              </EditButton>
            </RelativeLink>
          </TableCell>
          <CopyCell>
            {ticket.slug}
            <CopyIconButton
              aria-label="copy url"
              beforeCopyTooltip="Copy Ticket's public URL to your clipboard"
              onClick={() => copyURL(ticket.slug)}
            />
          </CopyCell>
          <DateTableCell>{availableFrom}</DateTableCell>
          <DateTableCell>{availableTo}</DateTableCell>
          <PriceTableCell>${formatPrice(ticket.price)}</PriceTableCell>
          <IndicatorTableCell>
            {VisibilityIcon(ticket)}
            {ActiveIcon(ticket)}
          </IndicatorTableCell>
          <MenuTableCell>
            <Menu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              button={({open}) => (
                <IconButton onClick={open} aria-label="view ticket actions">
                  <MenuIcon iconSize={18} />
                </IconButton>
              )}
              disabled={isProcessing}
            >
              <MenuItem
                children="Duplicate"
                onClick={() => duplicateTicket()}
              />
              <Divider />
              <MenuItem
                children="Toggle Hidden"
                onClick={() => toggleTicket('hidden')}
              />
              <Divider />
              <MenuItem
                children="Toggle Active"
                onClick={() => toggleTicket('active')}
              />
              <Divider />
              <MenuItem
                children="Delete"
                color="danger"
                onClick={() => deleteTicket()}
              />
            </Menu>
          </MenuTableCell>
        </TableRow>
      )}
    </Draggable>
  )
}

function VisibilityIcon(ticket: MarketplaceTicket) {
  if (ticket.hidden) {
    return (
      <IndicatorIcon title="This ticket is hidden from listings - Only direct access via the ticket slug is available">
        <InvisibleIcon iconSize={16} />
      </IndicatorIcon>
    )
  }

  return (
    <IndicatorIcon title="This ticket is visible in listings">
      <VisibleIcon color="primary" iconSize={16} />
    </IndicatorIcon>
  )
}

function ActiveIcon(ticket: MarketplaceTicket) {
  if (ticket.active) {
    return (
      <IndicatorIcon title="This Ticket is available for purchase">
        <PowerOnIcon color="primary" iconSize={16} />
      </IndicatorIcon>
    )
  }

  return (
    <IndicatorIcon title="This Ticket is NOT available for purchase">
      <PowerOffIcon iconSize={16} />
    </IndicatorIcon>
  )
}

const IndicatorIcon = styled.div`
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing[2]};
`
