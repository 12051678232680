import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import {usePurchasePageEvents} from 'lib/marketplace-api/purchase-page/use-purchase-page-events'
import LoadingGrid from 'organization/Marketplace/config/TemplateSelector/LoadingGrid'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import PurchasePageEventTemplateCard from 'organization/Marketplace/config/TemplateSelector/PurchasePageEventTemplateCard'

interface UserEventsGridProps {
  updateTemplate: UpdateTemplateFunction
}

export default function PurchasePageUserEventsGrid(props: UserEventsGridProps) {
  const {updateTemplate} = props
  const {event: currentEvent} = useEvent()
  const {data: events} = usePurchasePageEvents()

  // Don't want to include current Event as a selection
  const otherEvents = events
    ? events.filter((item) => item.id !== currentEvent.id)
    : []

  if (!events) {
    return <LoadingGrid />
  }

  if (otherEvents.length < 1) {
    return (
      <Typography>You don't have any events with a Purchase Page</Typography>
    )
  }

  return (
    <>
      {otherEvents.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <PurchasePageEventTemplateCard
            event={event}
            updateTemplate={updateTemplate}
          />
        </Grid>
      ))}
    </>
  )
}
