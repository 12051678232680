import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {VideoBlock} from '.'
import React, {useMemo, useState} from 'react'
import styles from './Video.module.css'

interface VideoSize {
  width: number
  height: number
}

interface VideoContainerProps extends VideoBlock {
  children: (size: VideoSize) => JSX.Element
}

export default function VideoContainer(
  props: VideoContainerProps & BlockComponentProps,
) {
  const {children, ...videoBlockProps} = props
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const {
    aspectRatio: {value: aspectRatio, customHeightRatio, customWidthRatio},
    borderWidth,
    padding,
  } = videoBlockProps

  const width = container?.clientWidth ?? 0
  const height = container?.clientHeight ?? 0

  const size = useMemo(() => {
    if (!aspectRatio) {
      return {
        width: 100,
        height: 100,
      }
    }

    if (aspectRatio === '1:1') {
      return resize({
        width,
        height,
        widthRatio: 1,
        heightRatio: 1,
        borderWidth,
        padding,
      })
    }

    if (aspectRatio === '16:9') {
      return resize({
        width,
        height,
        widthRatio: 16,
        heightRatio: 9,
        borderWidth,
        padding,
      })
    }

    if (aspectRatio === '9:16') {
      return resize({
        width,
        height,
        widthRatio: 9,
        heightRatio: 16,
        borderWidth,
        padding,
      })
    }

    return resize({
      width,
      height,
      widthRatio: customWidthRatio,
      heightRatio: customHeightRatio,
      borderWidth,
      padding,
    })
  }, [
    aspectRatio,
    borderWidth,
    customHeightRatio,
    customWidthRatio,
    padding,
    width,
    height,
  ])

  return (
    <Container {...videoBlockProps} className={styles.root} ref={setContainer}>
      {children(size)}
    </Container>
  )
}

function resize(params: {
  widthRatio: number
  heightRatio: number
  height: number
  width: number
  borderWidth: number
  padding: {
    top: number
    right: number
    bottom: number
    left: number
  }
}) {
  const {height, heightRatio, width, widthRatio, borderWidth, padding} = params
  const unitHeight =
    (height - borderWidth * 2 - padding.top - padding.bottom) / heightRatio
  const unitWidth =
    (width - borderWidth * 2 - padding.left - padding.right) / widthRatio

  const unitPx = Math.min(unitHeight, unitWidth)
  return {
    width: unitPx * widthRatio,
    height: unitPx * heightRatio,
  }
}
