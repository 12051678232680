import React from 'react'
import {useForm} from 'react-hook-form'
import {TextProps} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/Text'
import {useAutoUpdateSidebarItem} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/Text/TextConfig/Settings'
import Styling from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/Text/TextConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export function TextConfig(
  props: ComponentConfigProps & {
    text: TextProps
    id: string
  },
) {
  const {showing, onClose, text, id} = props

  const {handleSubmit, control, watch, setValue, formState} = useForm()
  const addTranslation = useAddTranslation()
  const saveTemplate = useSaveTemplate()

  const save = (data: TextProps) => {
    const body = replaceAtPath(data, 'body', `{{${id}_body}}`)

    saveTemplate({
      sidebarItems: {
        [id]: data,
      },
      localization: addTranslation({[`${id}_body`]: body ?? ''}),
    })
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: watch(),
    when: showing,
  })

  return (
    <ComponentConfig
      title="Text"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} text={text} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} text={text} />
      </StylingPanel>
      <RulesPanel rules={text.rules} name="rules" control={control}>
        <ScheduleInput setValue={setValue} control={control} values={text} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
