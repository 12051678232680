import {useEvent} from 'Event/EventProvider'
import {Room} from 'Event/room'
import {InlinePageLoader} from 'lib/ui/layout/FullPageLoader'
import {api} from 'lib/url'
import {useOrganization} from 'organization/OrganizationProvider'
import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {scrollIntoView} from 'seamless-scroll-polyfill'

export default function Rooms(props: {areaId: number}) {
  const {areaId} = props

  const {rooms} = useRooms(areaId)

  if (!areaId) {
    return null
  }

  if (!rooms) {
    return <Loading />
  }

  return (
    <Table cellSpacing={0}>
      <thead>
        <Header>
          <Cell align="center">Room #</Cell>
          <Cell>Room Description</Cell>
          <Cell align="center">Online Now</Cell>
        </Header>
      </thead>
      <tbody>
        {rooms.map((room: Room, index: number) => (
          <Row key={room.id} isEven={index % 2 === 0}>
            <Cell align="center">{room.number}</Cell>
            <Cell>{room.description}</Cell>
            <Cell align="center">{room.attendee_count}</Cell>
          </Row>
        ))}
      </tbody>
    </Table>
  )
}

function Loading() {
  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (!ref || !ref.current) {
      return
    }

    scrollIntoView(ref.current, {behavior: 'smooth'})
  }, [])

  return (
    <p ref={ref}>
      <InlinePageLoader />
    </p>
  )
}

function useRooms(areaId: number) {
  const [rooms, setRooms] = useState<Room[] | null>(null)

  const {event} = useEvent()
  const {client} = useOrganization()

  useEffect(() => {
    setRooms(null)

    if (!areaId) {
      return
    }

    const url = api(`/events/${event.id}/areas/${areaId}/rooms`)
    client.get<Room[]>(url).then(setRooms)
  }, [areaId, client, event.id])

  return {
    rooms,
  }
}

const Table = styled.table`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[6]};
  padding-bottom: ${(props) => props.theme.spacing[6]};
`

const Header = styled.tr`
  background-color: #f2f5f9;
  height: ${(props) => props.theme.spacing[10]};
`

const Row = styled.tr<{
  isEven: boolean
}>`
  height: ${(props) => props.theme.spacing[20]};
  background-color: ${(props) =>
    props.isEven
      ? props.theme.colors.table.rowPrimary
      : props.theme.colors.table.rowSecondary};
`

const Cell = styled.td<{
  align?: 'center' | 'left' | 'right'
}>`
  text-align: ${(props) => props.align ?? 'left'};
`
