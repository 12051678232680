import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import {useEditMode, useSetEditMode} from 'Event/EditModeProvider'

export default function RightItems(props: {children?: JSX.Element}) {
  const isEditMode = useEditMode()

  const setIsEditMode = useSetEditMode()

  const toggle = () => setIsEditMode(!isEditMode)

  if (props.children !== undefined) {
    return props.children
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={!isEditMode} onChange={toggle} color="primary" />
        }
        label="Preview"
      />
    </>
  )
}
