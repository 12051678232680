import React from 'react'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import {useObieService} from 'organization/Obie/ObieServiceProvider'

export default function DeleteProfileDialog(props: {
  categoryId?: number
  profileId?: number
  onClose: () => void
  open: boolean
}) {
  const {categoryId, profileId, onClose, open} = props
  const {getCategory, deleteProfile} = useObieService()

  if (!open || !categoryId || !profileId) {
    return null
  }

  const category = getCategory(categoryId)
  const profile = (category.profiles || []).find(
    (profile) => profile.id === profileId,
  )

  if (!profile) {
    return null
  }

  const onSubmit = () => {
    deleteProfile(categoryId, profileId).then(() => onClose())
  }

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>{category.name} : Remove Profile</DialogTitle>
      <DialogContent>
        <div>Are you sure you would like to remove the profile named:</div>
        <StyledStrong>{profile.name}</StyledStrong>
        <div>
          Note: All progress completed within this Profile will also be removed.
        </div>

        <StyledButton
          type="button"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Continue
        </StyledButton>
        <Button
          type="button"
          variant="contained"
          color="grey"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  )
}

const StyledStrong = styled.div`
  font-weight: 700;
  margin: ${(props) => props.theme.spacing[2]} 0
    ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]};
`

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[3]} 0;
`
