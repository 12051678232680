import React from 'react'
import {BlogPost, BUTTONS} from 'Event/Dashboard/components/BlogPosts'
import styled from 'styled-components'
import {createPositions, orderedIdsByPosition} from 'lib/list'
import {
  DragDropContext,
  Droppable,
  DroppableProvidedProps,
  DropResult,
} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import BlogPostButton from 'Event/Dashboard/components/BlogPosts/Buttons/BlogPostButton'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import AddBlogPostButtonButton from 'Event/Dashboard/components/BlogPosts/Buttons/AddBlogPostButtonButton'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'

export default function Buttons(props: {
  post: BlogPost
  postId: string
  onAdd?: (button: NavButtonWithSize) => void
  onUpdate?: (id: string, button: NavButtonWithSize | typeof REMOVE) => void
  sectionId?: string
}) {
  const {
    post: {buttons, attachment},
    postId,
    onUpdate,
  } = props
  const isEditMode = useEditMode()
  const ids = orderedIdsByPosition(buttons)

  const buttonComponents = ids.map((id, index) => (
    <BlogPostButton
      key={index}
      button={buttons[id]}
      id={id}
      index={index}
      postId={postId}
      onUpdate={onUpdate}
    />
  ))

  const showing = attachment === BUTTONS
  if (!showing) {
    return null
  }

  if (isEditMode) {
    return <Draggable {...props}>{buttonComponents}</Draggable>
  }

  return <Container {...props}>{buttonComponents}</Container>
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    children: JSX.Element | JSX.Element[]
    post: BlogPost
  } & Partial<DroppableProvidedProps>
>((props, ref) => {
  const {
    post: {buttonsPosition, buttonsWidth},
  } = props

  return (
    <Box justifyContent={buttonsPosition} ref={ref}>
      <Sizer width={buttonsWidth}>{props.children}</Sizer>
    </Box>
  )
})

function Draggable(props: {
  children: JSX.Element[]
  className?: string
  post: BlogPost
  postId: string
  onAdd?: (button: NavButtonWithSize) => void
  useAlternateDrag?: (
    postId: string,
    post: BlogPost,
  ) => (result: DropResult) => void
  sectionId?: string
}) {
  const {postId, post, onAdd, sectionId} = props
  const handleDrag = useHandleDrag(postId, post, sectionId)

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={`blog_post_${postId}_buttons`}>
        {(provided) => (
          <Container {...props} ref={provided.innerRef} {...provided.innerRef}>
            <>
              {props.children}
              {provided.placeholder}
              <AddBlogPostButtonButton postId={postId} onAdd={onAdd} />
            </>
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function useHandleDrag(postId: string, post: BlogPost, sectionId?: string) {
  const update = useSaveTemplate()

  if (sectionId) {
    return (result: DropResult) => {
      const {destination, source} = result

      if (!destination) {
        return
      }

      const ids = orderedIdsByPosition(post.buttons)

      const moved = Array.from(ids)
      const [removed] = moved.splice(source.index, 1)
      moved.splice(destination.index, 0, removed)

      update({
        dashboardSections: {
          [sectionId]: {
            items: {
              [postId]: {
                buttons: createPositions(moved),
              },
            },
          },
        },
      })
    }
  }

  return (result: DropResult) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    const ids = orderedIdsByPosition(post.buttons)

    const moved = Array.from(ids)
    const [removed] = moved.splice(source.index, 1)
    moved.splice(destination.index, 0, removed)

    update({
      blogPosts: {
        [postId]: {
          buttons: createPositions(moved),
        },
      },
    })
  }
}

const Sizer = styled.div<{
  width?: number
}>`
  width: ${(props) => props.width || 100}%;
`
const Box = styled.div<{
  justifyContent?: string
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
`
