import React from 'react'

export default function InputLabel(props: {
  children?: React.ReactNode
  disabled?: boolean
}) {
  if (!props.children) {
    return null
  }

  return (
    <span
      style={{
        fontSize: `16px`,
        lineHeight: `18px`,
        fontWeight: 400,
        color: props.disabled ? '#c4c4c4' : '#000000',
        display: 'block',
        marginBottom: '12px',
      }}
    >
      {props.children}
    </span>
  )
}
