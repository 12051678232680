import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {useEvent} from 'Event/EventProvider'
import LoadingGrid from 'organization/Marketplace/config/TemplateSelector/LoadingGrid'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import ThankYouPageEventTemplateCard from 'organization/Marketplace/config/TemplateSelector/ThankYouPageEventTemplateCard'
import {useThankYouPageEvents} from 'lib/marketplace-api/thank-you-page/use-thank-you-page-events'

interface UserEventsGridProps {
  updateTemplate: UpdateTemplateFunction
}

export default function ThankYouPageUserEventsGrid(props: UserEventsGridProps) {
  const {updateTemplate} = props
  const {event: currentEvent} = useEvent()
  const {data: events} = useThankYouPageEvents()

  // Don't want to include current Event as a selection
  const otherEvents = events
    ? events.filter((item) => item.id !== currentEvent.id)
    : []

  if (!events) {
    return <LoadingGrid />
  }

  if (otherEvents.length < 1) {
    return (
      <Typography>You don't have any events with a Thank You Page</Typography>
    )
  }

  return (
    <>
      {otherEvents.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <ThankYouPageEventTemplateCard
            event={event}
            updateTemplate={updateTemplate}
          />
        </Grid>
      ))}
    </>
  )
}
