import React, {useState} from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {formatPrice} from 'lib/currency'
import {useToggle} from 'lib/toggle'
import ErrorAlert from 'lib/ui/alerts/ErrorAlert'
import Button from 'lib/ui/Button'
import {colors} from 'lib/ui/theme'
import {Title, Typography} from 'lib/ui/typography'
import {ADDON_KEY_ZOOM_MEETINGS} from 'obvio/Billing/plans'
import {usePurchaseAddOns} from 'obvio/Billing/purchase-add-ons'
import {ConfirmationProps} from 'organization/AddOns/PurchaseAddOnPage/ConfirmDialog'
import {useOrganization} from 'organization/OrganizationProvider'
import {useDefaultPaymentMethod} from 'obvio/Billing/DefaultPaymentMethodProvider'

type SavedCardConfirmationProps = ConfirmationProps & {
  onUseOtherCard: () => void
}

export function SavedCardConfirmation(props: SavedCardConfirmationProps) {
  return (
    <>
      <DialogTitle>Are you sure?</DialogTitle>
      <Content {...props} />
    </>
  )
}

function Content(props: SavedCardConfirmationProps) {
  const {
    addOnDetail,
    addOnKey,
    onSuccess,
    onUseOtherCard,
    price,
    quantity,
  } = props
  const purchaseAddOns = usePurchaseAddOns()
  const {flag: processing, toggle: toggleProcessing} = useToggle()
  const [error, setError] = useState<string | null>(null)
  const clearError = () => setError(null)
  const canPurchase = !processing && Boolean(price)
  const {paymentMethod} = useDefaultPaymentMethod()
  const {organization} = useOrganization()

  const duration = addOnDetail.duration ? props.duration : undefined

  if (!paymentMethod) {
    return null
  }

  const purchaseWithSavedCard = () => {
    if (processing) {
      return
    }

    toggleProcessing()

    purchaseAddOns({
      quantity,
      duration,
      addOnKey,
      paymentMethodId: paymentMethod.id,
      organizationId: organization.id,
    })
      .then(onSuccess)
      .catch((e) => {
        setError(e.message)
        toggleProcessing()
      })
  }

  const addOnText =
    addOnKey === ADDON_KEY_ZOOM_MEETINGS
      ? `${(duration || 1) * (addOnDetail.duration || 1)} Days `
      : `${addOnDetail.block} Block of `

  const {card} = paymentMethod
  const confirmText = `The card on file ending in ${card?.last4} for ${
    organization.name
  } will be charged ${formatPrice(price)}.`

  return (
    <Dialog open>
      <DialogContent>
        <Title mb={5}>Are you sure?</Title>

        <ErrorAlert onClose={clearError}>{error}</ErrorAlert>

        <Typography>You're about to purchase:</Typography>
        <Typography color={colors.text.muted} margin="20px 10px">
          {quantity} * {addOnText}
          {addOnDetail.details.displayName}
        </Typography>
        <Typography aria-label="confirmation text">{confirmText}</Typography>

        <Actions>
          <Button onClick={props.onClose} disabled={processing}>
            Cancel
          </Button>
          <Button
            aria-label="use a different card"
            color="grey"
            disabled={processing}
            onClick={onUseOtherCard}
            variant="contained"
          >
            Use a Different Card
          </Button>
          <Button
            aria-label="confirm purchase"
            autoFocus
            color="primary"
            disabled={!canPurchase}
            onClick={purchaseWithSavedCard}
            variant="contained"
          >
            Purchase Add-on
          </Button>
        </Actions>
      </DialogContent>
    </Dialog>
  )
}

const Actions = styled.div`
  text-align: right;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  margin-top: ${(props) => props.theme.spacing[10]};

  & > * {
    margin-right: ${(props) => props.theme.spacing[4]} !important;

    &:last-child {
      margin-right: 0;
    }
  }
`
