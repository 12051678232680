import React, {ReactNode} from 'react'

export const Label = (props: {
  children: ReactNode | ReactNode[]
  onClick?: () => void
  className?: any
  style?: React.CSSProperties
}) => {
  const {onClick, className, style} = props

  return (
    <span
      className={className}
      onClick={onClick}
      style={{
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        display: 'block',
        ...style,
      }}
    >
      {props.children}
    </span>
  )
}
