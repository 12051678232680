import React, {useMemo} from 'react'
import Block, {BlockComponentProps} from '../../Block'
import {BlockBase} from '../../Block/base'
import {PurchasePageBlock} from '../../purchase-page'
import {Layouts, Responsive, WidthProvider} from 'react-grid-layout'
import Container from '../../Block/Container'
import {getBlocks} from '../../Block/get-blocks'
import ReactGridLayout from 'react-grid-layout'
import {useResizedLayouts} from '../../lib/use-resize-layout'
import {HashMap} from '../../lib/list'

export interface GridBlock extends BlockBase {
  type: 'Grid'
  blocks: HashMap<PurchasePageBlock>
  layouts: Layouts
  numColumns: number
  blockSpacing: number
  disableItemResize?: boolean
  onSizeInit?: () => void
  showingAddBlockDialog?: boolean
  toggleAddBlockDialog?: () => void
  ResponsiveReactGridLayoutProps?: ReactGridLayout.ResponsiveProps &
    ReactGridLayout.WidthProviderProps
  children?: React.ReactNode
}

const rowHeight = 10

export default function Grid(props: GridBlock & BlockComponentProps) {
  const {
    template,
    disableItemResize,
    layouts,
    blockSpacing,
    numColumns,
    onSizeInit,
    ResponsiveReactGridLayoutProps,
    children,
  } = props
  // Fix bug where API casts empty objects to an Array
  const layoutsObject = Array.isArray(layouts) ? {} : layouts

  const resizedLayouts = useResizedLayouts({
    layoutsObject,
    blockSpacing,
    rowHeight,
    onSizeInit,
  })

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), [])

  return (
    <Container {...props}>
      <ResponsiveReactGridLayout
        breakpoints={
          {desktop: 0, mobile: 10000000000} // always desktop only
        }
        layouts={disableItemResize ? layoutsObject : resizedLayouts}
        cols={{desktop: numColumns, mobile: numColumns}}
        isDraggable={false}
        isResizable={false}
        rowHeight={rowHeight}
        style={{
          width: '100%',
          minHeight: '100px',
        }}
        margin={[blockSpacing, blockSpacing]}
        {...ResponsiveReactGridLayoutProps}
      >
        {children ||
          Object.entries(getBlocks(props)).map(([id, block]) => (
            <Block block={block} key={id} id={id} template={template} />
          ))}
      </ResponsiveReactGridLayout>
    </Container>
  )
}
