import {useCertificates} from 'lib/event-api/certificates/use-certificates'

interface UseCertificateParams {
  id: number
}

export function useCertificate(params: UseCertificateParams) {
  const {id} = params

  const {data: certificates} = useCertificates()
  if (!certificates) {
    return {
      data: undefined,
      isLoading: true,
    }
  }

  return {
    data: certificates.find((certificate) => certificate.id === id) ?? null,
    isLoading: false,
  }
}
