
import Command from '@ckeditor/ckeditor5-core/src/command';
import { isCustomButtonElement } from './utils';

export default class ObvioCustomButtonCommand extends Command {
	override execute( options: {newValue: string} ) {
		const model = this.editor.model;
		const selection = model.document.selection;
		const element = selection.getSelectedElement()!;

		this.editor.model.change( writer => {
			if ( isCustomButtonElement( element ) ) {
				const attributes = {
					...Object.fromEntries( element.getAttributes() ),
					text: options.newValue
				};

				const customButtonElement = writer.createElement( 'customButton', attributes );
				this.editor.model.insertContent( customButtonElement );
				writer.setSelection( customButtonElement, 'on' );
			}
		} );
	}

	override refresh() {
		const model = this.editor.model;
		const selection = model.document.selection;
		const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition()!, 'customButton' );

		this.isEnabled = allowedIn !== null;
	}
}
