import {client} from 'lib/ui/api-client'
import {api} from 'lib/url'
import {useQuery} from 'react-query'

export type ObvioTooltip = {
  key: string
  value?: string | null
}

export function useObvioTooltip(key: string) {
  return useQuery({
    queryKey: ['obvio_tooltips', key],
    queryFn: () => client.get<ObvioTooltip>(api(`/obvio_tooltips/${key}`)),
  })
}
