import {
  SimpleBlog,
  useSimpleBlogTemplate,
  useSimpleBlogUpdate,
} from 'Event/template/SimpleBlog'
import {ProgressBarProps} from 'Event/template/SimpleBlog/check-in/ProgressBar'
import {onChangeCheckedHandler} from 'lib/dom'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import Slider from 'lib/ui/Slider'
import TextField, {NumberField} from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useAttendeeVariables} from 'Event'
import {TemplateSave} from 'Event/TemplateUpdateProvider'

export default function ProgressBarConfig(props: ProgressBarProps) {
  const {textKey, percentKey} = props
  const save = useSimpleBlogUpdate()
  const {progressBar} = useSimpleBlogTemplate()
  const {
    form: {control, register},
  } = useConfig()

  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const handleSave: TemplateSave<SimpleBlog> = (data) => {
    const label = replaceAtPath(
      data,
      `progressBar.${textKey}`,
      `{{${textKey}}}`,
    )

    save({
      ...data,
      localization: addTranslation({
        [textKey]: label ?? 'Step',
      }),
    })
  }

  return (
    <Config title="Progress Bar" onSave={handleSave}>
      <SettingsPanel>
        <Controller
          name="progressBar.showing"
          control={control}
          defaultValue={progressBar.showing}
          render={({value, onChange}) => (
            <EnabledSwitch
              checked={value}
              onChange={onChangeCheckedHandler(onChange)}
            />
          )}
        />
        <Divider />
        <Title>Progress Bar</Title>
        <TextField
          name={`progressBar.${textKey}`}
          label="Text"
          defaultValue={v(progressBar[textKey])}
          fullWidth
          inputProps={{
            ref: register,
          }}
        />
        <Controller
          name={`progressBar.${percentKey}`}
          defaultValue={progressBar[percentKey]}
          control={control}
          render={({value, onChange}) => (
            <NumberField
              label="Percent Complete"
              fullWidth
              value={value}
              onChange={onChange}
              inputProps={{
                min: 1,
                max: 100,
              }}
            />
          )}
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Progress Bar</Title>
        <Controller
          name="progressBar.barColor"
          defaultValue={progressBar.barColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Bar Color"
              color={value}
              onPick={onChange}
              aria-label="bar color"
            />
          )}
        />
        <Controller
          name="progressBar.backgroundColor"
          defaultValue={progressBar.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="BG Color"
              color={value}
              onPick={onChange}
              aria-label="bar background color"
            />
          )}
        />
        <Controller
          name="progressBar.textColor"
          defaultValue={progressBar.textColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Text Color"
              color={value}
              onPick={onChange}
              aria-label="text color"
            />
          )}
        />
        <Divider />
        <Title>Border</Title>
        <Controller
          name="progressBar.borderRadius"
          defaultValue={progressBar.borderRadius}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Radius"
              value={value}
              onChange={onChange}
              aria-label="progress bar border radius"
              min={0}
              max={30}
            />
          )}
        />
        <Controller
          name="progressBar.thickness"
          defaultValue={progressBar.thickness}
          control={control}
          render={({value, onChange}) => (
            <Slider
              label="Thickness"
              valueLabelDisplay="auto"
              aria-label="progress bar thickness"
              value={value}
              onChange={onChange}
              step={1}
              min={30}
              max={60}
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
