import React, {useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceUpload'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/Styling'
import Config from 'organization/Event/Configurable/Config'
import {useConfig} from 'organization/Event/Configurable'
import {
  useAddTranslation,
  useLanguages,
  useRemoveTranslations,
} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {get as getAtPath} from 'lodash'

export function ResourceItemConfig(props: {
  id: string
  sectionId: string
  resource: Resource
}) {
  const {resource, id, sectionId} = props

  const deleteFile = useDeleteFile()

  const {
    form: {setValue, control},
  } = useConfig()

  const save = useSaveTemplate()

  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()
  const template = useTemplate()
  const languages = useLanguages()

  const handleSave = (data: any) => {
    const name = replaceAtPath(
      data,
      `dashboardSections.${sectionId}.items.${id}.name`,
      `{{dashboard_${sectionId}_items_${id}_name}}`,
    )

    const filePath = replaceAtPath(
      data,
      `dashboardSections.${sectionId}.items.${id}.filePath`,
      `{{dashboard_${sectionId}_items_${id}_filePath}}`,
    )

    save({
      ...data,
      localization: addTranslation({
        [`dashboard_${sectionId}_items_${id}_name`]: name ?? '',
        [`dashboard_${sectionId}_items_${id}_filePath`]: filePath ?? '',
      }),
    })
  }

  const remove = useCallback(() => {
    if (resource.filePath) {
      deleteFile(resource.filePath).catch((e) => {
        // Log error, but prevent it from crashing app
        console.error(e)
      })
    }

    for (const language of languages) {
      const filePath = getAtPath(
        template,
        `localization.translations.${language.name}.${id}_filePath`,
      )

      if (filePath) {
        deleteFile(filePath).catch((e) => {
          // Log error, but prevent it from crashing
          // app
          console.error(e)
        })
      }
    }

    save({
      localization: removeTranslations([
        `dashboardSections_${sectionId}_items_${id}_name`,
      ]),
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [
    resource.filePath,
    save,
    removeTranslations,
    sectionId,
    id,
    deleteFile,
    languages,
    template,
  ])

  return (
    <Config title="Resource" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings resource={resource} id={id} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling resource={resource} id={id} sectionId={sectionId} />
      </StylingPanel>
      <RulesPanel
        rules={resource.rules}
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.rules`}
      >
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
          fromName={`dashboardSections.${sectionId}.items.${id}.showingFrom`}
          untilName={`dashboardSections.${sectionId}.items.${id}.showingUntil`}
        />
      </RulesPanel>
    </Config>
  )
}
