import FormControl from '@material-ui/core/FormControl'
import {RuleConfigProps} from 'Event/attendee-rules/RuleConfig/RuleList/RuleForm/SourceConfig'
import {Rule} from 'Event/attendee-rules'
import {
  IS,
  createFormResponseRule,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import {onUnknownChangeHandler} from 'lib/dom'
import React, {useEffect, useState} from 'react'
import Select, {SelectProps} from 'lib/ui/Select'
import styled from 'styled-components'
import Option from 'lib/ui/Select/Option'
import {
  FormResponseRule,
  FORM_RESPONSE,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import FormSelect from 'organization/Event/FormsProvider/FormSelect'
import {Form, useForms} from 'organization/Event/FormsProvider'
import {Question} from 'organization/Event/QuestionsProvider'
import OptionSelect from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule/OptionSelect'
import RuleTypeSelect from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule/RuleTypeSelect'
import {RADIO} from 'Event/Question/Radio'
import {SELECT} from 'Event/Question/Select'
import {CHECKBOX} from 'Event/Question/Checkbox'
import {SHORT_ANSWER_TEXT} from 'Event/Question/ShortAnswerText'
import {LONG_ANSWER_TEXT} from 'Event/Question/LongAnswerText'

/**
 * Only these question types can be targeted using form response rules.
 */
export const ALLOWED_QUESTION_TYPES: Question['type'][] = [
  'radio',
  'select',
  'checkbox',
]

export default function FormResponseRuleConfig(props: RuleConfigProps) {
  const {rule, form} = props
  const [formId, setFormId] = useState<number | null>(
    getFormId(rule) || form?.id || null,
  )
  const {forms} = useForms()
  const selectedForm = form ?? forms.find((f) => f.id === formId)

  return (
    <Box>
      <FormSelect
        value={formId}
        onChange={setFormId}
        disabled={Boolean(selectedForm)}
      />
      <FormResponseConfig {...props} form={selectedForm} />
    </Box>
  )
}

function FormResponseConfig(props: {form?: Form} & RuleConfigProps) {
  if (!props.form) {
    return null
  }

  return <FormResponseConfigBody form={props.form} {...props} />
}

function FormResponseConfigBody(
  props: {
    form: Form
  } & RuleConfigProps,
) {
  const {form, rule, onSet} = props

  const [type, setType] = useState<FormResponseRule['type']>(getType(rule))
  const [question, setQuestion] = useState<Question | null>(
    getQuestion({form, rule}),
  )
  const [value, setValue] = useState<FormResponseRule['value']>(getValue(rule))

  const onChangeQuestion = (question: Question | null) => {
    setQuestion(question)
    setValue('')
  }

  const questionId = question?.id
  const formId = form.id

  useEffect(() => {
    const isValid = questionId && value && formId
    if (!isValid) {
      onSet(null)
      return
    }

    const rule = createFormResponseRule(type, formId, questionId, value)
    onSet(rule)
  }, [type, onSet, questionId, value, formId])

  return (
    <Container>
      <FormControl fullWidth>
        <QuestionSelect
          form={form}
          question={question}
          setQuestion={onChangeQuestion}
        />
      </FormControl>
      <FormControl fullWidth>
        <RuleTypeSelect type={type} onChange={setType} />
      </FormControl>
      <FormControl fullWidth>
        <OptionSelect question={question} value={value} onChange={setValue} />
      </FormControl>
    </Container>
  )
}

export function QuestionSelect(
  props: Omit<SelectProps, 'onChange' | 'value'> & {
    form: Form
    question: Question | null
    setQuestion: (question: Question) => void
  },
) {
  const {question, form, setQuestion} = props
  const questions = form.questions.filter((question) =>
    [RADIO, SELECT, CHECKBOX, SHORT_ANSWER_TEXT, LONG_ANSWER_TEXT].includes(
      question.type,
    ),
  )

  const handleSelectQuestion = (questionId: number) => {
    const question = questions.find((question) => question.id === questionId)
    if (question) {
      setQuestion(question)
    }
  }

  const value = question ? `${question.id}` : ''

  return (
    <Select
      {...props}
      value={value}
      onChange={onUnknownChangeHandler(handleSelectQuestion)}
      fullWidth
      aria-label="pick question"
    >
      {questions.map((question: Question, index: number) => (
        <Option
          value={question.id}
          key={index}
          aria-label={`pick ${question.label}`}
        >
          {question.label}
        </Option>
      ))}
    </Select>
  )
}

function isFormResponseRule(rule: Rule | null): rule is FormResponseRule {
  if (!rule) {
    return false
  }

  return rule.source === FORM_RESPONSE
}

function getType(rule: Rule | null): FormResponseRule['type'] {
  if (isFormResponseRule(rule)) {
    return rule.type
  }

  return IS
}

function getFormId(rule: Rule | null) {
  if (isFormResponseRule(rule)) {
    return rule.form_id
  }

  return null
}

function getQuestion(params: {rule: Rule | null; form: Form}): Question | null {
  const {
    rule,
    form: {questions},
  } = params

  if (!isFormResponseRule(rule)) {
    return null
  }

  const target = questions.find((q) => q.id === rule.question_id)
  if (!target) {
    return null
  }

  // Only want to allow questions with options.
  if (!('options' in target)) {
    return null
  }

  return target
}

function getValue(rule: Rule | null) {
  if (isFormResponseRule(rule)) {
    return rule.value
  }

  return ''
}

const Container = styled.div`
  margin-top: ${(props) => props.theme.spacing[4]};
`

const Box = styled.div`
  > div[role='presentation'] {
    margin-top: -${(props) => props.theme.spacing[4]};
  }
`
