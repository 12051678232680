import {useEvent} from 'Event/EventProvider'
import {Breadcrumb, WithBreadcrumbs} from 'lib/ui/BreadcrumbProvider'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import React from 'react'

export type PageBreadcrumbProps = {
  children: JSX.Element
  parent?: Breadcrumb
  page: string
}

export default function PageBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()
  const {event} = useEvent()

  const parent = props.parent || {
    title: event.name,
    url: eventRoutes.root,
  }

  const breadcrumbs: Breadcrumb[] = [
    parent,
    {
      title: props.page,
    },
  ]

  return (
    <WithBreadcrumbs breadcrumbs={breadcrumbs}>
      {props.children}
    </WithBreadcrumbs>
  )
}

export function EventBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Event',
        url: eventRoutes.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function DesignBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Design',
        url: eventRoutes.dashboard,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function MarketplaceBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Marketplace',
        url: eventRoutes.marketplace.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function TicketsBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Ticket',
        url: eventRoutes.marketplace.tickets.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function CheckInBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Check In',
        url: eventRoutes.password_create,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function FeaturesBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Features',
        url: eventRoutes.forms.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function AttendeesBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Attendees',
        url: eventRoutes.attendees,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function ServicesBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Services',
        url: eventRoutes.services.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function ZoomAreasBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Zoom Areas',
        url: eventRoutes.areas.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function CommunicationBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Communication',
        url: eventRoutes.broadcasts,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}

export function CertificateBreadcrumbs(props: PageBreadcrumbProps) {
  const eventRoutes = useEventRoutes()

  return (
    <PageBreadcrumbs
      parent={{
        title: 'Certificates',
        url: eventRoutes.certificates.root,
      }}
      page={props.page}
    >
      {props.children}
    </PageBreadcrumbs>
  )
}
