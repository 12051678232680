import TextField from 'lib/ui/TextField'
import Box from 'lib/ui/Box'
import Button from 'lib/ui/Button'
import Grid from 'lib/ui/Grid'
import Close from '@material-ui/icons/Close'
import ActionSelect from 'Event/ActionsProvider/ActionSelect'
import IconButton from 'lib/ui/IconButton'
import {Option} from 'organization/Event/QuestionsProvider'
import React from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {QuestionTypeConfigProps} from 'organization/Event/Form/dialog/Form/QuestionTypeConfig'
import styled from 'styled-components'
import {typesWithOptions} from 'Event/Question'
import Label from 'lib/ui/TextField/Label'

export default function OptionsInput(props: QuestionTypeConfigProps) {
  const {control, register, errors} = props

  const {fields, append, remove} = useFieldArray<Option>({
    control,
    name: 'options',
  })

  const hasOptions = typesWithOptions.includes(props.questionType)

  if (!hasOptions) {
    return null
  }

  const addOption = () => {
    append({
      value: '',
      action_key: null,
    })
  }

  const removeOption = (index: number) => () => {
    remove(index)
  }

  return (
    <>
      {fields.map((option, index) => {
        const error = errors?.options ? errors.options[index] : null
        const errorMessage = error?.value?.message

        return (
          <Box key={option.id}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Label>Option</Label>
                <OptionTextField
                  variant="outlined"
                  name={`options[${index}].value`}
                  inputProps={{
                    'aria-label': 'question option',
                    ref: register({
                      pattern: {
                        value: /^[^,]+$/,
                        message: 'Commas are not allowed.',
                      },
                    }),
                  }}
                  placeholder="Option"
                  defaultValue={option.value}
                  fullWidth
                  error={Boolean(errorMessage)}
                  helperText={errorMessage}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-left"
                  height="100%"
                >
                  <Controller
                    control={control}
                    name={`options[${index}].action_key`}
                    defaultValue={option.action_key}
                    render={({value, onChange}) => (
                      <ActionSelect
                        disableMargin
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <IconButton
                    onClick={removeOption(index)}
                    type="button"
                    aria-label="remove option"
                  >
                    <Close color="error" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )
      })}
      <Box mb={2} textAlign="right">
        <Button
          variant="outlined"
          onClick={addOption}
          color="primary"
          type="button"
          aria-label="add option"
        >
          Add Option
        </Button>
      </Box>
    </>
  )
}

const OptionTextField = styled(TextField)`
  font-size: 16px;
  height: 48px;
  margin: 0;
`
