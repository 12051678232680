import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/Dashboard/components/BodyHtmlEmbed/BodyHTMLEmbedConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {Template} from 'Event/template'

export function BodyHTMLEmbedConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useSaveTemplate()
  const {
    handleSubmit,
    register,
    formState: {isDirty},
  } = useForm()

  const save = (data: Pick<Template, 'bodyHTMLEmbed'>) => {
    update({
      ...data,
    })
    onClose()
  }

  return (
    <ComponentConfig
      title="Embed HTML"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={isDirty}
    >
      <SettingsPanel>
        <Settings register={register} />
      </SettingsPanel>
      <Footer>
        <SaveButton />
      </Footer>
    </ComponentConfig>
  )
}
