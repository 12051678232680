import React from 'react'
import {orderedIdsByPosition} from 'Event/Marketplace/lib/list'
import PreviewButton from 'organization/Marketplace/UpsellPageConfig/PreviewButton'
import {useUpdateTemplate} from 'organization/Marketplace/UpsellPageConfig/UpsellPageTemplateUpdateProvider'
import UpsellPageButtonConfig from 'organization/Marketplace/config/BlockConfig/UpsellPageButtonConfig'
import ConfigPage from 'organization/Marketplace/config/ConfigPage'
import UpsellPageTemplateSelector from 'organization/Marketplace/config/TemplateSelector/UpsellPageTemplateSelector'
import {UpsellPage, UpsellPageTemplate} from 'Event/Marketplace/upsell-page'
import {Client} from 'lib/ui/api-client'
import {useUpdateUpsellPage} from 'lib/marketplace-api/upsell-page/use-update-upsell-page'

interface ContentProps {
  upsellPage: UpsellPage
  template: UpsellPageTemplate
  client: Client
}

export default function UpsellPageConfigContent(props: ContentProps) {
  const {template, upsellPage, client} = props
  const updateTemplate = useUpdateTemplate()

  const {sections} = template

  const sectionIds = orderedIdsByPosition(sections)
  const hasSections = sectionIds.length > 0

  const updateUpsellPage = useUpdateUpsellPage({client, upsellPage})

  const setEnabled = (enabled: boolean) => {
    updateUpsellPage.mutateAsync({is_enabled: enabled})
  }

  if (!hasSections) {
    return <UpsellPageTemplateSelector updateTemplate={updateTemplate} />
  }

  return (
    <ConfigPage
      template={template}
      updateTemplate={updateTemplate}
      availableBlocks={[
        'Title',
        'Text',
        'Image',
        'Video',
        'Button',
        'BulletedList',
        'NumberedList',
        'FaqList',
        'CountdownTimer',
        'Icon',
        'Separator',
        'Grid',
        'Spacer',
        'UpsellPurchaseButton',
        'UpsellDeclineButton',
      ]}
      rightToolbarItems={
        <>
          <PreviewButton />
        </>
      }
      ButtonConfig={UpsellPageButtonConfig}
      isUpsellPage
      enabled={upsellPage.is_enabled}
      setEnabled={setEnabled}
      disableEnableToggle={updateUpsellPage.isLoading}
    />
  )
}
