import React from 'react'
import styled from 'styled-components'
import {Speaker} from 'Event/Speakers'
import {HashMap} from 'lib/list'
import {Agenda} from 'Event/template/Panels/Dashboard/AgendaList'

export default function SpeakerImages<T extends Agenda>(props: {
  agenda: T
  speakers: HashMap<Speaker>
}) {
  const {agenda, speakers} = props

  return (
    <SpeakerImagesContainer>
      {agenda.speakers.map((id) => (
        <SpeakerImage key={id} speaker={speakers[id]} />
      ))}
    </SpeakerImagesContainer>
  )
}

function SpeakerImage(props: {speaker: Speaker | undefined}) {
  const {speaker} = props

  if (!speaker || !speaker.image) {
    return null
  }

  return <SpeakerAvatar src={speaker.image} />
}

const SpeakerImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: ${(props) => props.theme.spacing[1]};
    justify-content: flex-start;
  }
`

const SpeakerAvatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: -9px;
`
