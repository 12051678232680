import Dialog from 'lib/ui/Dialog'
import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import grey from '@material-ui/core/colors/grey'
import IconButton from 'lib/ui/IconButton'
import styled from 'styled-components'
import Form from 'organization/Event/WaiverConfig/AdditionalWaivers/WaiversTable/WaiverRow/ConfigDialog/Form'
import {Waiver} from 'Event/template'

export default function ConfigDialog(props: {
  showing: boolean
  onClose: () => void
  waiver: Waiver
  id: string
}) {
  const {showing, waiver, onClose, id} = props
  if (!waiver) {
    return null
  }

  return (
    <Dialog open={showing} onClose={onClose}>
      <CloseButton
        onClick={props.onClose}
        aria-label="close additional waiver config"
      >
        <CloseIcon fontSize="small" />
      </CloseButton>
      <DialogTitle>Additional Waiver</DialogTitle>
      <DialogContent>
        <Form waiver={waiver} onDone={onClose} id={id} />
      </DialogContent>
    </Dialog>
  )
}

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${(props) => props.theme.spacing[2]};
  right: ${(props) => props.theme.spacing[2]};

  svg {
    color: ${grey[500]};
  }
`
