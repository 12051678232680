import React from 'react'

export type OptionProps = {
  value?: any
  selected?: any
  children?: string | number | React.ReactNode
  disabled?: boolean
  'aria-label'?: string
}

export default function SelectProps(props: OptionProps) {
  const {value, children, selected, disabled} = props

  return (
    <option value={value} selected={selected} disabled={disabled}>
      {children}
    </option>
  )
}
