import {Rule} from 'Event/attendee-rules'
import styled from 'styled-components'
import GroupRuleLabel, {
  GROUP,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/GroupRule'
import React from 'react'
import ConditionSelector from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/ConditionSelector'
import {TAGS} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import NestedRuleLabel from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule'
import TagsRuleLabel from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TagsRule'
import {NESTED_RULE} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/NestedRule'
import FormResponseRuleLabel, {
  FORM_RESPONSE,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/FormResponseRule'
import TimeRuleLabel, {
  TIME,
} from 'Event/attendee-rules/RuleConfig/RuleList/SingleRule/TimeRule'
import {Form} from 'organization/Event/FormsProvider'

export default function SingleRule(props: {
  rule: Rule
  isFirstRule: boolean
  className?: string
  onEdit: () => void
  onUpdate: (rule: Rule) => void
  form?: Form
}) {
  const updateCondition = (condition: Rule['condition']) => {
    props.onUpdate({
      ...props.rule,
      condition,
    })
  }

  return (
    <Box className={props.className}>
      <StyledConditionSelector
        visible={!props.isFirstRule}
        onSelect={updateCondition}
        rule={props.rule}
      />
      <Content onClick={props.onEdit}>
        <RuleValue rule={props.rule} form={props.form} />
      </Content>
    </Box>
  )
}

export function RuleValue(props: {rule: Rule; form?: Form}) {
  switch (props.rule.source) {
    case TAGS:
      return <TagsRuleLabel rule={props.rule} />
    case GROUP:
      return <GroupRuleLabel rule={props.rule} />
    case NESTED_RULE:
      return <NestedRuleLabel rule={props.rule} />
    case FORM_RESPONSE:
      return <FormResponseRuleLabel rule={props.rule} form={props.form} />
    case TIME:
      return <TimeRuleLabel rule={props.rule} />
  }
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
  padding: ${(props) => `${props.theme.spacing[2]} ${props.theme.spacing[4]}`};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.menu.itemHoverBackground};
  }
`

const StyledConditionSelector = styled(ConditionSelector)`
  margin-right: ${(props) => props.theme.spacing[4]}!important;
`
