/**
 * Toolbar items to display
 *
 * Defined here instead of in TextEditor/index to avoid circular dependencies.
 *
 * resource: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/configuration.html
 */
export const defaultToolbar = [
  'heading',
  'customFontFamily',
  'customFontSize',
  'fontColor',
  'fontBackgroundColor',
  'alignment',
  'bold',
  'italic',
  'strikethrough',
  'superscript',
  'removeFormat',
  '-',
  'link',
  'mediaEmbed',
  'imageUpload',
  'htmlEmbed',
  'bulletedList',
  'numberedList',
  'outdent',
  'indent',
  'blockQuote',
  'insertTable',
  'customTheme',
]
