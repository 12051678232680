import React, {CSSProperties, useState} from 'react'
import HelpOutline from '@material-ui/icons/HelpOutline'
import {ClickAwayListener, Tooltip} from '@material-ui/core'
import styled from 'styled-components'
import {useObvioTooltip} from 'lib/event-api/obvio-tooltips/use-obvio-tooltip'

interface ObvioTooltipProps {
  tooltipKey: string
  size?: number
  style?: CSSProperties
}

export default function ObvioTooltip(props: ObvioTooltipProps) {
  const {tooltipKey, size, style} = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggle = () => setIsOpen((isTrue) => !isTrue)

  const handleBackgroundClick = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const {data} = useObvioTooltip(tooltipKey)

  if (!data) {
    return null
  }

  return (
    <div style={style}>
      <ClickAwayListener onClickAway={handleBackgroundClick}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleBackgroundClick}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<div dangerouslySetInnerHTML={{__html: data.value ?? ''}} />}
          >
            <TooltipButton
              size={size}
              onClick={toggle}
              aria-label="toggle obvio tooltip"
            />
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  )
}

const TooltipButton = styled(HelpOutline)<{size?: number}>`
  cursor: pointer;
  font-size: ${(props) => props.size}px;
`
