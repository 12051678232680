import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useQueryParams} from 'lib/url'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AccessTokens, {
  ACCESS_TOKENS,
} from 'organization/Event/Services/AccessTokens'
import Apps, {APPS} from 'organization/Event/Services/Apps'
import Page from 'organization/Event/Page'
import TabPanel from 'lib/ui/tabs/TabPanel'
import {AttendeesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import ObvioTooltip from 'organization/ObvioTooltip'
import Grid from 'lib/ui/Grid'

export default function Services() {
  const {activeTab} = useQueryParams()
  const [tab, setTab] = React.useState(0)

  // If we've got ?activeTab=accessTokens in the URI, we want to flip to the
  // access tokens tab, it's been targeted on purpose.
  useEffect(() => {
    if ((activeTab || '') === 'accessTokens') {
      setTab(1)
    }
  }, [activeTab])

  const handleChange = (_: React.ChangeEvent<{}>, tab: number) => {
    setTab(tab)
  }
  return (
    <AttendeesBreadcrumbs page="Services">
      <Page>
        <ServiceTab>
          <Grid container>
            <Grid item xs={11}>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="service config tabs"
              >
                <Tab label={APPS} />
                <Tab label={ACCESS_TOKENS} />
              </Tabs>
            </Grid>

            <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
              <ToolTip currentIndex={tab} />
            </Grid>
          </Grid>

          <TabPanel currentIndex={tab} index={0} render>
            <Apps />
          </TabPanel>
          <TabPanel currentIndex={tab} index={1} render>
            <AccessTokens />
          </TabPanel>
        </ServiceTab>
      </Page>
    </AttendeesBreadcrumbs>
  )
}

const ToolTip = (props: {currentIndex: number}) => {
  if (props.currentIndex !== 0) return null

  return (
    <ObvioTooltip
      style={{width: '100%', display: 'flex', justifyContent: 'end'}}
      tooltipKey="attendees_services_main"
    />
  )
}

const ServiceTab = styled.div`
  flex-grow: 1;
  width: '100%';
`
