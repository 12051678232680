import React from 'react'
import styled from 'styled-components'
import {Speaker} from 'Event/Speakers'
import {Draggable} from 'react-beautiful-dnd'
import {useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {useAttendeeVariables} from 'Event'
import InnerContent from 'lib/ui/form/TextEditor/Content'
import {useEditMode} from 'Event/EditModeProvider'
import Image from 'Event/Speakers/Image'
import SpeakerConfig, {SpeakerConfigurable} from 'Event/Speakers/SpeakerConfig'

type SpeakerProps = {
  id: string
  index: number
  speaker: Speaker
}

export default function Card(props: SpeakerProps) {
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return <Content {...props} />
  }
  return (
    <Draggable draggableId={String(props.id)} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Content {...props} />
        </div>
      )}
    </Draggable>
  )
}

function Content(props: SpeakerProps) {
  const {speaker, index} = props
  const template = useNiftyFiftyTemplate()
  const v = useAttendeeVariables()
  const {rowBackgroundPrimary, rowBackgroundSecondary} = template
  const size = speaker.image ? template.speakers.imageSize : 0

  return (
    <Container
      aria-label="speaker"
      backgroundColor={
        index % 2 === 0 ? rowBackgroundPrimary : rowBackgroundSecondary
      }
      spacing={template.speakers.spacing}
    >
      <SpeakerConfigurable>
        <SpeakerConfig speaker={props.speaker} id={props.id} />
        <FlexBox>
          <Left size={size}>
            <StyledImage speaker={speaker} />
          </Left>
          <Right leftSize={size}>
            <SpeakerName color={template.textColor}>
              {v(speaker.name)}
            </SpeakerName>

            <StyledBody color={template.textColor}>
              <InnerContent>{v(speaker.text)}</InnerContent>
            </StyledBody>
          </Right>
        </FlexBox>
      </SpeakerConfigurable>
    </Container>
  )
}

const Container = styled((props) => {
  const {backgroundColor: _, ...rest} = props
  return <div {...rest} />
})<{
  backgroundColor: string | null
  spacing: number
}>`
  display: flex;
  position: relative;
  background: ${(props) => props.backgroundColor};
  margin-bottom: ${(props) => props.theme.spacing[props.spacing]};
  padding: ${(props) => props.theme.spacing[5]}
    ${(props) => props.theme.spacing[9]};
`

const Left = styled.div<{
  size: number
}>`
  width: ${(props) => props.size * 10}%;
`

const Right = styled.div<{
  leftSize: number
}>`
  flex: 1;
  padding-left: ${(props) => (props.leftSize ? props.theme.spacing[5] : 0)};
`

const StyledBody = styled.div<{
  color: string
}>`
  color: ${(props) => props.color};
  white-space: pre-wrap;
  list-style-position: inside;
`

const SpeakerName = styled.div<{
  color: string
}>`
  line-height: 22px;
  font-weight: 700;
  font-size: 18px;
  color: ${(props) => props.color};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 14px;
    line-height: 17px;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  img {
    width: 100%;
    border-radius: 10px;
  }
`

const FlexBox = styled.div`
  display: flex;
  width: 100%;
`
