import React from 'react'
import Dialog from 'lib/ui/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'lib/ui/Button'

export default function ConfirmUnsavedChangesDialog(props: {
  open: boolean
  expandable?: boolean
  onDiscard: () => void
  onCancel: () => void
  onSubmit?: (e: React.FormEvent) => void
}) {
  const {
    onSubmit = (event) => {
      event.preventDefault()
    },
  } = props
  return (
    <Dialog
      expandable={props.expandable}
      open={props.open}
      disableBackdropClick
      onClose={props.onCancel}
    >
      <DialogTitle>Save Updates?</DialogTitle>
      <DialogContent>
        Closing this window without saving will cause you to lose the changes
        you have made. Would you like to save your changes, or discard them?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="danger"
          type="button"
          onClick={props.onDiscard}
          aria-label="discard updates made not yet saved"
        >
          Discard
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          aria-label="save changes made not yet saved"
          color="success"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
