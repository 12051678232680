import React, {useEffect, useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton as RemoveListButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {EmojiListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/EmojiList'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/EmojiList/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {Rule} from 'Event/attendee-rules'

export function EmojiListConfig(
  props: ComponentConfigProps & {
    list: EmojiListProps
  },
) {
  const {showing, onClose} = props
  const {list} = props
  const [emojiWidth, setEmojiWidth] = useState(list.emojiWidth)
  const [emojis, setEmojis] = useState(list.emojis)
  const {remove: removeItem, update: updateItem} = useEditSidebarItem()
  const {control, setValue, handleSubmit, formState} = useForm()
  const [rules, setRules] = useState<Rule[]>(list.rules || [])

  useEffect(() => {
    if (showing) {
      return
    }

    setEmojiWidth(list.emojiWidth)
    setEmojis(list.emojis)
  }, [showing, list])

  const save = () => {
    const data: EmojiListProps = {
      ...list,
      emojiWidth,
      emojis,
      rules,
    }

    updateItem(data)
    onClose()
  }

  useAutoUpdateSidebarItem({item: {emojiWidth, emojis}, when: showing})

  return (
    <ComponentConfig
      title="Emojis"
      showing={showing}
      onClose={onClose}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          emojis={emojis}
          setEmojis={setEmojis}
          emojiWidth={emojiWidth}
          setEmojiWidth={setEmojiWidth}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={list} />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveListButton onClick={removeItem} />
      </Footer>
    </ComponentConfig>
  )
}
