import React from 'react'
import styled, {createGlobalStyle, useTheme} from 'styled-components'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@obvio/ckeditor'
import {useTextEditor, Theme} from 'lib/ui/form/TextEditor/TextEditorProvider'
import {defaultToolbar} from 'lib/ui/form/TextEditor/toolbar'

/**
 * Variables / Merge codes that can be inserted into the text editor
 * such as {{first name}}.
 */
export type ObvioVariable = {
  label: string
  value: string
}

export type TextEditorProps = {
  data: string
  onChange: (value: string) => void
  className?: string
  disabled?: boolean
  toolbar?: string[]
  customLinks?: string[]
  variables?: ObvioVariable[]
}

export default function TextEditor(props: TextEditorProps) {
  const updateValue = (_: any, editor: any) => {
    props.onChange(editor.getData())
  }

  const {
    ckUploadUrl,
    ckToken,
    theme,
    selectTheme,
    ckLicenseKey,
  } = useTextEditor()

  return (
    <div className={props.className}>
      <CKEditor
        disabled={props.disabled}
        editor={ClassicEditor}
        // In case DB returns 'null' for a text field, we don't
        // want to crash the app so let's just set is as a
        // blank string as a precaution.
        data={props.data || ''}
        onChange={updateValue}
        config={
          {
            licenseKey: ckLicenseKey,
            removePlugins: ['Markdown'],
            toolbar: {
              items: props.toolbar || defaultToolbar,
              shouldNotGroupWhenFull: true,
            },
            theme: {
              mode: theme,
              callback: selectTheme,
            },
            links: {
              rexlink: props.customLinks || [],
            },
            link: {
              addTargetToExternalLinks: false,
              decorators: {
                openInNewTab: {
                  mode: 'manual',
                  label: 'Open in a new tab',
                  attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  },
                },
              },
            },
            obvioVariables: {
              items: props.variables || [],
            },

            /**
             * Required for media embed to render in HTML
             */

            mediaEmbed: {
              previewsInData: true,
              extraProviders: [
                {
                  name: 'Searchie',
                  url: /searchie\.io\/watch\/(\w+)/,
                  html: (match: string[]) => mediaElement(match[1], 'Searchie'),
                },
                {
                  name: 'Searchie - Memebership',
                  url: /membership\.io\/watch\/(\w+)/,
                  html: (match: string[]) => mediaElement(match[1], 'Searchie'),
                },
                {
                  name: 'Wistia',
                  url: /wistia\.com\/medias\/(\w+)/,
                  html: (match: string[]) => mediaElement(match[1], 'Wistia'),
                },
              ],
            },
            cloudServices: {
              /**
               * CKEditor expects the endpoint to fetch the token. Since we
               * require custom client auth, we'll use a function that
               * returns the token.
               *
               * Reference: https://ckeditor.com/docs/ckeditor5/latest/api/module_cloud-services_cloudservices-CloudServicesConfig.html#member-tokenUrl
               *
               * @returns
               */
              tokenUrl: () => Promise.resolve(ckToken),
              uploadUrl: ckUploadUrl,
            },
          } as any
        }
      />
      <CustomThemeCkditor theme={theme} />
      <CkPopupZIndex />
    </div>
  )
}

function mediaElement(mediaId: string, site: 'Searchie' | 'Wistia') {
  if (!mediaId) {
    return ''
  }

  const embedUrl =
    site === 'Wistia'
      ? `https://fast.wistia.net/embed/iframe/${mediaId}`
      : `https://app.membership.io/file/${mediaId}/embed`

  // https://ckeditor.com/docs/ckeditor5/latest/features/media-embed.html#previewable-media
  return `
  <div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
    <iframe src="${embedUrl}"
      style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
      frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
    </iframe>
  </div>
  `
}

function CustomThemeCkditor(props: {theme: Theme}) {
  const appTheme = useTheme()

  // If a user has explicitly selected a text editor theme, we'll always
  // prefer that...
  if (props.theme === 'Dark') {
    return <DarkCKEditor />
  }

  if (props.theme === 'Light') {
    return <LightCKEditor />
  }

  // Otherwise the text editor should inherit the app's theme. That is, dark editor
  // in dark dialogs etc.
  if (appTheme.name === 'dark') {
    return <DarkCKEditor />
  }

  return <LightCKEditor />
}

/**
 * Fix CKEditor scroll when within a dialog. CKEditor has a min-width, and
 * anything below will show blank whitespace with scroll. This container
 * will fix alignment, and hide whitespace.
 */

export const TextEditorContainer = styled.div`
  // overflow-x: hidden;
  margin-bottom: ${(props) => props.theme.spacing[5]};

  /**
   * Add a required min-height to prevent the pop-ups from being
   * cut off when inside a dialog.
   */
  .ck-editor__editable_inline {
    min-height: 200px;
  }
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne {
    bottom: 0;
    top: 100%;
  }
`

/*
Fix CKEditor link pop-up not appearing when inside a Dialog. Note that
this also required setting disableEnforceFocus on the Dialog
component.
*/
const CkPopupZIndex = createGlobalStyle`
  body {
    --ck-z-modal: 1500;
    --ck-z-panel: 1500;
  }
`

export const LightCKEditor = createGlobalStyle`

  .ck-editor, .ck-editor__editable, ck-editor__editable_inline {
    color: #000000;
  }

`

/**
 * Customize the CKEditor Theme
 * Reference: https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/theme-customization.html
 */

export const DarkCKEditor = createGlobalStyle<{backgroundColor?: string}>`
    .ck-editor, .ck-editor__editable, ck-editor__editable_inline {
      color: hsl(0, 0%, 98%);
      a {
        color: #93c4ec;
      }
    }

 body {
    /* Overrides the border radius setting in the theme. */
    --ck-border-radius: 4px;

    /* Overrides the default font size in the theme. */
    --ck-font-size-base: inherit;

    /* Helper variables to avoid duplication in the colors. */
    --ck-custom-background: hsl(270, 1%, 29%);
    --ck-custom-foreground: hsl(255, 3%, 18%);
    --ck-custom-border: hsl(300, 1%, 22%);
    --ck-custom-white: hsl(0, 0%, 100%);

    /* -- Overrides generic colors. ------------------------------------------------------------- */

    --ck-color-base-foreground: var(--ck-custom-background);
    --ck-color-focus-border: hsl(208, 90%, 62%);
    --ck-color-text: hsl(0, 0%, 98%);
    --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
    --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);
    --ck-color-base-background: ${(props) =>
      props.backgroundColor || '#353535'};
    --ck-color-base-border: ${(props) => props.backgroundColor || '#353535'};
    /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

    --ck-color-button-default-background: var(--ck-custom-background);
    --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
    --ck-color-button-default-active-background: hsl(270, 2%, 20%);
    --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
    --ck-color-button-default-disabled-background: var(--ck-custom-background);

    --ck-color-button-on-background: var(--ck-custom-foreground);
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
    --ck-color-button-on-active-background: hsl(255, 4%, 14%);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

    --ck-color-button-action-background: hsl(168, 76%, 42%);
    --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
    --ck-color-button-action-active-background: hsl(168, 76%, 36%);
    --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
    --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
    --ck-color-button-action-text: var(--ck-custom-white);

    --ck-color-button-save: hsl(120, 100%, 46%);
    --ck-color-button-cancel: hsl(15, 100%, 56%);

    /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

    --ck-color-dropdown-panel-background: var(--ck-custom-background);
    --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */

    --ck-color-dialog-background: var(--ck-custom-background);
    --ck-color-dialog-form-header-border: var(--ck-custom-border);
    
    /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

    --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
    --ck-color-split-button-hover-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

    --ck-color-input-background: var(--ck-custom-background);
    --ck-color-input-border: hsl(257, 3%, 43%);
    --ck-color-input-text: hsl(0, 0%, 98%);
    --ck-color-input-disabled-background: hsl(255, 4%, 21%);
    --ck-color-input-disabled-border: hsl(250, 3%, 38%);
    --ck-color-input-disabled-text: hsl(0, 0%, 78%);

    /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

    --ck-color-labeled-field-label-background: var(--ck-custom-background);

    /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

    --ck-color-list-background: var(--ck-custom-background);
    --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
    --ck-color-list-button-on-background: var(--ck-color-base-active);
    --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
    --ck-color-list-button-on-text: var(--ck-color-base-background);

    /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

    --ck-color-panel-background: var(--ck-custom-background);
    --ck-color-panel-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

    --ck-color-toolbar-background: var(--ck-custom-background);
    --ck-color-toolbar-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

    --ck-color-tooltip-background: hsl(252, 7%, 14%);
    --ck-color-tooltip-text: hsl(0, 0%, 93%);

    /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);

    /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

    --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
    --ck-color-widget-hover-border: hsl(43, 100%, 68%);
    --ck-color-widget-editable-focus-background: var(--ck-custom-white);

    /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

    --ck-color-link-default: hsl(190, 100%, 75%);
  }
`
