import {ObvioEvent} from 'Event'
import {UpsellPage} from 'Event/Marketplace/upsell-page'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {Client} from 'lib/ui/api-client'
import {Organization} from 'organization'
import {useMutation, useQueryClient} from 'react-query'

export function useCreateUpsellPage(params: {
  event: ObvioEvent
  organization: Organization
}) {
  const {event} = params
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (args: {client: Client}) => {
      const {client} = args
      const url = marketplaceApi(`/events/${event.id}/upsell_page`)
      const data = await client.post<UpsellPage>(url)

      return data
    },
    onSuccess: (UpsellPage) => {
      qc.setQueryData<UpsellPage>(['events', event.id, 'upsell_page'], () => {
        return UpsellPage
      })
    },
  })
}
