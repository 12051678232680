import React, {useEffect, useMemo, useState, useCallback} from 'react'
import {FormSubmission} from 'lib/event-api/forms/use-form-submissions'
import {useQueryClient} from 'react-query'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import {Form} from 'organization/Event/FormsProvider'
import {createPublic as createEcho} from 'lib/sockets/echo'
import {Channel} from 'laravel-echo/dist/channel'
import PublicChannel from 'pusher-js/types/src/core/channels/channel'

type FormSubmissionSocketUpdate = FormSubmission & {
  prev_status: FormSubmissionStatus | null
}

type FormSocketContextProps = {
  channel: Channel | null
}

const FormSocketContext = React.createContext<
  FormSocketContextProps | undefined
>(undefined)

export default function FormSocketProvider(props: {
  children: React.ReactElement
  form: Form
}) {
  const {form} = props

  const [socketId, setSocketId] = useState<string | null>(null)
  const [channel, setChannel] = useState<Channel | null>(null)

  const {id: formId} = form

  const echo = useMemo(() => createEcho(), [])
  const disconnect = useCallback(() => setSocketId(null), [])

  useEffect(() => {
    const channel = `form.${formId}`
    const publicChannel = echo.channel(channel)
    const connection = ((publicChannel as unknown) as PublicChannel).pusher
      .connection

    connection.bind('connected', () => {
      setSocketId(connection.socket_id)
    })

    connection.bind('disconnected', disconnect)
    setChannel(publicChannel)

    return () => {
      echo.leave(channel)
    }
  }, [echo, disconnect, formId])

  return (
    <FormSocketContext.Provider value={{channel}} key={socketId}>
      {props.children}
    </FormSocketContext.Provider>
  )
}

export function useFormSocket() {
  const context = React.useContext(FormSocketContext)
  if (context === undefined) {
    throw new Error('useFormSocket must be used within a FormSocketContext')
  }

  return context
}

export function useListenForFormSubmissionSavedUpdates(form: Form) {
  const {channel} = useFormSocket()
  const formId = form.id

  const qc = useQueryClient()

  // Listen for socket updates
  useEffect(() => {
    if (!channel) {
      return
    }

    channel?.listen(
      '.form_submission.saved',
      (updated: FormSubmissionSocketUpdate) => {
        // Invalidate queries to trigger a refetch
        if (updated.prev_status) {
          qc.invalidateQueries([
            'forms',
            formId,
            'submissions',
            {status: updated.prev_status},
          ])
        }

        qc.invalidateQueries([
          'forms',
          formId,
          'submissions',
          {status: updated.status},
        ])
      },
    )

    return () => {
      channel?.stopListening('.form_submission.saved')
    }
  }, [channel, qc, formId])
}
