import styled from 'styled-components'
import React, {useState} from 'react'
import {background, useNiftyFiftyTemplate} from 'Event/template/NiftyFifty'
import {MenuIconButton} from 'lib/ui/IconButton/MenuIconButton'
import Menu from 'Event/template/NiftyFifty/Dashboard/Menu'
import MainNavMobile from 'Event/template/NiftyFifty/Dashboard/MainNav/MainNavMobile'
import Emojis from 'Event/template/NiftyFifty/Dashboard/EmojiList'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled from 'lib/ui/layout/Scheduled'
import {rgba} from 'lib/color'
import {User} from 'auth/user'

export default function MobilePanel(props: {
  children: React.ReactElement
  onChangeTab: (tab: number) => void
  user: User
}) {
  const [menuVisible, setMenuVisible] = useState(false)
  const toggleMenu = () => setMenuVisible(!menuVisible)
  const template = useNiftyFiftyTemplate()

  const handleChangeTab = (tab: number) => {
    props.onChangeTab(tab)
    setMenuVisible(false)
  }

  return (
    <Box>
      <StyledMenuIconButton
        active={menuVisible}
        iconColor={template.menu.iconColor}
        onClick={toggleMenu}
      />
      <Content
        menuVisible={menuVisible}
        onChangeTab={handleChangeTab}
        user={props.user}
      >
        {props.children}
      </Content>
    </Box>
  )
}

function Content(props: {
  menuVisible: boolean
  children: React.ReactElement
  onChangeTab: (tab: number) => void
  user: User
}) {
  const template = useNiftyFiftyTemplate()
  const {leftPanel} = template

  if (props.menuVisible) {
    return <Menu onChangeTab={props.onChangeTab} user={props.user} />
  }

  return (
    <>
      <Top
        background={background({
          enabled: template.dashboardBackground.enabled,
          image: template.dashboardBackground.image,
          color: leftPanel.backgroundColor,
          opacity: leftPanel.backgroundOpacity,
        })}
      >
        <DashboardLogo
          src={template.dashboardLogo.image}
          size={template.dashboardLogo.size}
          enabled={template.dashboardLogo.enabled}
        />
        <MainNavMobile />
        <VisibleOnMatch rules={template.emojiList.rules}>
          <Scheduled component={template.emojiList}>
            <Emojis />
          </Scheduled>
        </VisibleOnMatch>
      </Top>
      <Panel
        backgroundColor={rgba(
          template.rightPanel.backgroundColor,
          template.rightPanel.backgroundOpacity,
        )}
      >
        {props.children}
      </Panel>
    </>
  )
}

function DashboardLogo(props: {
  src?: string | null
  size: number
  enabled: boolean
}) {
  if (props.src && props.enabled) {
    return (
      <StyledDashboardLogo
        src={props.src}
        size={props.size}
        alt="dsahboard logo"
      />
    )
  }
  return null
}

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: auto;
`

const Top = styled.div<{
  background: string
}>`
  background: ${(props) => props.background};
  text-align: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing[18]} 0;
  width: 100%;
`

const Panel = styled.div<{
  backgroundColor: string
}>`
  flex: 1;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  padding: ${(props) => props.theme.spacing[6]}
    ${(props) => props.theme.spacing[6]} ${(props) => props.theme.spacing[9]};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.backgroundColor};
  width: 100%;
`

const StyledMenuIconButton = styled(MenuIconButton)`
  position: absolute;
  top: 24px;
  left: 12px;
`

const StyledDashboardLogo = styled.img<{size: number}>`
  width: ${(props) => props.size}%;
  height: auto;
  margin: 0 auto;
`
