import Command from "@ckeditor/ckeditor5-core/src/command";

export default class InsertVariableCommand extends Command {
	override execute(value: string) {
		const editor = this.editor;

		editor.model.change((writer) => {
			const insertPosition = editor.model.document.selection.getFirstPosition()!;
			writer.insertText( value, insertPosition );
		});
	}

	override refresh() {
		this.isEnabled = true;
	}
}
