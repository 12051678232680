import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {Attendee} from 'Event/attendee'
import {useAttendees} from 'organization/Event/AttendeesProvider'
import Alert from '@material-ui/lab/Alert'
import Page from 'organization/Event/Page'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {onChangeStringHandler} from 'lib/dom'
import UpdateDialog from 'organization/Event/AttendeeManagement/attendee-dialog/UpdateDialog'
import CreateDialog from 'organization/Event/AttendeeManagement/attendee-dialog/CreateDialog'
import AttendeesTable from 'organization/Event/AttendeeManagement/AttendeesTable'
import AssignmentsDialog from 'organization/Event/AttendeeManagement/AssignmentsDialog'
import PointsDialog from 'organization/Event/AttendeeManagement/PointsDialog'
import AttendeeFilterDialog from 'organization/Event/AttendeeManagement/AttendeeFilterDialog'
import DownloadCertificateDialog from 'organization/Event/AttendeeManagement/DownloadCertificateDialog'
import {useEffect} from 'react'
import AttendeeTools from 'organization/Event/AttendeeManagement/AttendeeTools'
import AddAttendeeButton from 'organization/Event/AttendeeManagement/AddAttendeeButton'
import HasPermission from 'organization/HasPermission'
import {
  EVENT_SETTINGS,
  UPDATE_ATTENDEES,
} from 'organization/PermissionsProvider'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {useDebouncedHandler} from 'lib/debounce'

export default function AttendeeManagement() {
  const {
    error: attendeesError,
    clearError,
    attendees,
    searchTerm,
    search: searchAttendee,
  } = useAttendees()
  const [editing, setEditing] = useState<Attendee | null>(null)
  const [viewAssignments, setViewAssignments] = useState<Attendee | null>(null)
  const [createDialogVisible, setCreateDialogVisible] = useState(false)
  const [pointsTarget, setPointsTarget] = useState<Attendee | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showingFilterDialog, setShowingFilterDialog] = useState(false)
  const [
    showDownloadCertificateDialog,
    setShowDownloadCertificateDialog,
  ] = useState(false)

  // Need to have separate state for showing dialog rather than inferring from 'editing' because
  // we have a hook below that automatically updates the currently edited attendee with any
  // changes. Setting this to `null` is immediately overwritten, and the dialog is never
  // closed.
  const [showingEditDialog, setShowingEditDialog] = useState(false)

  const [search, setSearch] = useDebouncedHandler(searchTerm, searchAttendee)

  /**
   * Handle updated the current attendee being edited.
   */
  useEffect(() => {
    if (!editing) {
      return
    }

    const updated = attendees.find((a) => a.id === editing.id)
    if (!updated) {
      return
    }

    setEditing(updated)
  }, [attendees, editing])

  const toggleCreateDialog = () => setCreateDialogVisible(!createDialogVisible)

  const toggleFilterDialog = () => setShowingFilterDialog(!showingFilterDialog)

  const toggleDownloadCertificateDialog = () =>
    setShowDownloadCertificateDialog(!showDownloadCertificateDialog)

  const edit = (attendee: Attendee) => () => {
    setEditing(attendee)
    setShowingEditDialog(true)
  }
  const stopEditing = () => {
    setEditing(null)
    setShowingEditDialog(false)
  }

  const viewAttendeeAssignments = (attendee: Attendee) => () =>
    setViewAssignments(attendee)
  const stopViewingAssignments = () => setViewAssignments(null)

  const showPointsDialog = (attendee: Attendee) => () =>
    setPointsTarget(attendee)
  const hidePointsDialog = () => setPointsTarget(null)

  const onSuccess = useCallback((message: string | null) => {
    setSuccessMessage(message)
  }, [])
  const onError = useCallback(
    (message: string | null) => setErrorMessage(message),
    [],
  )
  const onCloseSuccess = () => setSuccessMessage(null)

  const error = attendeesError || errorMessage

  return (
    <>
      <CreateDialog
        isVisible={createDialogVisible}
        onClose={toggleCreateDialog}
      />
      <UpdateDialog
        attendee={editing}
        onClose={stopEditing}
        showing={showingEditDialog}
      />
      <AssignmentsDialog
        attendee={viewAssignments}
        onClose={stopViewingAssignments}
      />
      <PointsDialog attendee={pointsTarget} onClose={hidePointsDialog} />
      <AttendeeFilterDialog
        isVisible={showingFilterDialog}
        onClose={toggleFilterDialog}
      />
      <DownloadCertificateDialog
        isVisible={showDownloadCertificateDialog}
        onClose={toggleDownloadCertificateDialog}
        onSuccess={onSuccess}
        onError={onError}
      />
      <PageBreadcrumbs page="Attendees">
        <Page>
          <Success onClose={onCloseSuccess}>{successMessage}</Success>
          <Error onClose={clearError}>{error}</Error>
          <HasPermission required={UPDATE_ATTENDEES}>
            <Actions>
              <AddAttendeeButton onClick={toggleCreateDialog} />
            </Actions>
          </HasPermission>
          <SearchBox>
            <StyledTextField
              variant="outlined"
              label="Search"
              value={search}
              onChange={onChangeStringHandler(setSearch)}
              fullWidth
              inputProps={{
                'aria-label': 'search for attendee',
              }}
            />
          </SearchBox>
          <Right>
            <AddFilterButton
              variant="text"
              color="primary"
              size="small"
              aria-label="filter attendee"
              onClick={toggleFilterDialog}
            >
              Filters
            </AddFilterButton>
            <HasPermission required={EVENT_SETTINGS}>
              <AttendeeTools
                onSuccess={onSuccess}
                onError={onError}
                showDownloadCertificateDialog={toggleDownloadCertificateDialog}
              />
            </HasPermission>
          </Right>
          <AttendeesTable
            onSelectEdit={edit}
            onSelectAssignments={viewAttendeeAssignments}
            onUpdatePoints={showPointsDialog}
            onRemoveAttendeeError={onError}
          />
        </Page>
      </PageBreadcrumbs>
    </>
  )
}

function Success(props: {children: string | null; onClose: () => void}) {
  if (!props.children) {
    return null
  }

  return (
    <StyledAlert severity="info" onClose={props.onClose}>
      {props.children}
    </StyledAlert>
  )
}

function Error(props: {children: string | null; onClose: () => void}) {
  if (!props.children) {
    return null
  }

  return (
    <StyledAlert severity="error" onClose={props.onClose}>
      {props.children}
    </StyledAlert>
  )
}

const StyledAlert = styled(Alert)`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`
const Actions = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`
const SearchBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
`

const AddFilterButton = styled(Button)`
  min-width: 120px;
`

const Right = styled.div`
  text-align: right;
  margin-bottom: ${(props) => props.theme.spacing[5]};
`
