import React, {CSSProperties} from 'react'

export interface IconProps {
  className: string
  iconSize: number
  style?: CSSProperties
  color?: string
}

export default function Icon(props: IconProps) {
  const {className, iconSize, color, style} = props

  return (
    <i
      className={className}
      style={{
        color,
        fontSize: iconSize + 'px',
        width: iconSize + 'px',
        height: iconSize + 'px',
        ...style,
      }}
    />
  )
}
