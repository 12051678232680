import {useEditMode} from 'Event/EditModeProvider'
import AddSectionConfig from 'Event/template/Townhall/Dashboard/Main/AddSectionButton/AddSectionConfig'
import {useToggleArray} from 'lib/toggle'
import Button from 'lib/ui/Button'
import React from 'react'
import styled from 'styled-components'

export default function AddSectionButton() {
  const [showingConfig, toggleConfig] = useToggleArray()

  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return (
    <>
      <AddSectionConfig showing={showingConfig} onClose={toggleConfig} />
      <StyledButton
        onClick={toggleConfig}
        fullWidth
        variant="contained"
        color="secondary"
        size="large"
        aria-label="add section"
      >
        Add Section
      </StyledButton>
    </>
  )
}

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.spacing[3]};
  margin-bottom: ${(props) => props.theme.spacing[3]};
`
