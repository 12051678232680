import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import {EVENT_SETTINGS, usePermissions} from 'organization/PermissionsProvider'
import {useToggleArray} from 'lib/toggle'
import SendCertificateDialog from 'organization/Event/AttendeeManagement/SendCertificateDialog'

export default function SendCertificateMenuItem(props: {
  disabled?: boolean
  onSuccess: (message: string | null) => void
  onError: (message: string | null) => void
}) {
  const {onError, onSuccess, disabled} = props
  const [showingDialog, toggleDialog] = useToggleArray()
  const {can} = usePermissions()

  if (!can(EVENT_SETTINGS)) {
    return null
  }

  return (
    <>
      <SendCertificateDialog
        isVisible={showingDialog}
        onClose={toggleDialog}
        onSuccess={onSuccess}
        onError={onError}
      />
      <MenuItem onClick={toggleDialog} disabled={disabled}>
        Send Certificate
      </MenuItem>
    </>
  )
}
