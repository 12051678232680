import React, {useCallback} from 'react'
import {Agenda} from 'Event/template/Panels/Dashboard/AgendaList'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Panels/Dashboard/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {usePanelsUpdate} from 'Event/template/Panels'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export function AgendaItemConfig(props: {agenda: Agenda; id: string}) {
  const {agenda, id} = props
  const removeTranslations = useRemoveTranslations()
  const addTranslations = useAddTranslation()

  const save = usePanelsUpdate()

  const handleSave = useCallback(
    (data) => {
      const {
        agendaList: {
          items: {[id]: updated},
        },
      } = data
      save({
        localization: addTranslations({
          [`${id}_text`]: updated.text,
          [`${id}_description`]: updated.description,
          [`${id}_link`]: updated.link,
        }),
        agendaList: {
          items: {
            [id]: {
              ...updated,
              text: `{{${id}_text}}`,
              description: `{{${id}_description}}`,
              link: `{{${id}_link}}`,
            },
          },
        },
      })
    },
    [id, save, addTranslations],
  )

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    save({
      localization: removeTranslations([
        `${id}_text`,
        `${id}_description`,
        `${id}_link`,
      ]),
      agendaList: {
        items: {
          [id]: REMOVE,
        },
      },
    })
  }, [id, save, removeTranslations])

  return (
    <Config title="Agenda" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings agenda={agenda} id={id} />
      </SettingsPanel>
    </Config>
  )
}
