import styled from 'styled-components'
import React, {ReactNode, useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import {handleAutocomplete, onChangeStringHandler} from 'lib/dom'
import {Autocomplete} from '@material-ui/lab'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {ajax, useObserve} from 'lib/rx'
import {debounceTime, filter, map, switchMap, tap} from 'rxjs/operators'
import {useAuthToken} from 'obvio/auth'
import Button from '@material-ui/core/Button'
import {useToggle} from 'lib/toggle'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {OntraportTag} from 'organization/Event/Services/Apps/Ontraport/Config/Tags'
import CreatTagForm from 'organization/Event/Services/Apps/Ontraport/Config/Tags/CreateTagForm'

/**
 * Minimum amount of chars required to perform a search.
 */
const MIN_SEARCH_CHARS = 3

export default function AutocompleteTags(props: {
  value?: OntraportTag | null
  onChange: (convertKitTag: OntraportTag | null) => void
  inputLabel?: string
  inputVariant?: 'filled' | 'standard' | 'outlined' | undefined
  disabled?: boolean
  errorText?: string
  endAdornment?: (loading: boolean, arrow: ReactNode) => JSX.Element | null
}) {
  const {endAdornment, value, onChange} = props
  const [name, setName] = useState('')
  const {value$, onReady} = useObserve(name)
  const [options, setOptions] = React.useState<OntraportTag[]>([])
  const [loading, setLoading] = React.useState(false)
  const {flag: showCreateForm, toggle: toggleShowCreateForm} = useToggle()

  const {
    event: {slug},
  } = useEvent()
  const token = useAuthToken()

  const handleOnCreate = (newTag: OntraportTag) => {
    toggleShowCreateForm()
    onChange(newTag)
  }

  useEffect(() => {
    if (!onReady) {
      return
    }

    value$
      .pipe(
        filter((value) => {
          return value.length >= MIN_SEARCH_CHARS
        }),
        tap(() => {
          setLoading(true)
        }),
        debounceTime(500),
        switchMap((name) => {
          const url = api(
            `/events/${slug}/integrations/ontraport/tags?search=${name}`,
          )

          return ajax.get(url, {
            accessToken: token,
          })
        }),
        map((res) => res.response),
        tap(() => {
          setLoading(false)
        }),
      )
      .subscribe({
        next: (options) => {
          setOptions(options)
        },
      })

    onReady()
  }, [value$, token, slug, onReady])

  return (
    <Box>
      <Autocomplete
        disablePortal
        options={options}
        value={value || null}
        onChange={handleAutocomplete(onChange)}
        getOptionLabel={(option) => option.tag_name}
        loading={loading}
        closeIcon=""
        aria-label="tag id holder"
        noOptionsText={'Type to search. Minimum length: 3 characters.'}
        getOptionSelected={(option, value) => option.tag_id === value.tag_id}
        renderInput={(params) => {
          return (
            <>
              <TextField
                {...params}
                onChange={onChangeStringHandler(setName)}
                variant={props.inputVariant ? props.inputVariant : 'standard'}
                fullWidth
                helperText={props.errorText ? props.errorText : ''}
                error={Boolean(props.errorText)}
                label={
                  props.inputLabel !== undefined
                    ? props.inputLabel
                    : 'Ontraport Tag'
                }
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'tag id',
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: endAdornment ? (
                    endAdornment(loading, params.InputProps.endAdornment)
                  ) : (
                    <Button
                      color="primary"
                      size="large"
                      aria-label="create tag"
                      onClick={toggleShowCreateForm}
                    >
                      Create new tag
                    </Button>
                  ),
                }}
              />
            </>
          )
        }}
      />
      <CreateNewTagDialog
        visible={showCreateForm}
        onClose={toggleShowCreateForm}
        onCreate={handleOnCreate}
      />
    </Box>
  )
}

export const CreateNewTagDialog = (props: {
  visible: boolean
  onClose: () => void
  onCreate: (newTag: OntraportTag) => void
}) => {
  const {visible, onCreate, onClose} = props

  return (
    <Dialog open={visible} onClose={onClose}>
      <DialogTitle>Create new tag</DialogTitle>
      <DialogContent>
        <CreatTagForm onCreate={onCreate} />
      </DialogContent>
    </Dialog>
  )
}

const Box = styled.div`
  position: relative;
  .MuiAutocomplete-popper {
    margin-top: -20px !important;
  }
`
