import {useEditMode} from 'Event/EditModeProvider'
import {TownhallSponsor} from 'Event/template/Townhall/Sponsors/SponsorList'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Button from 'lib/ui/Button'
import {generateHashId} from 'lib/crypto/hash'
import React from 'react'

export default function AddTownhallSponsorButton() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const isEditMode = useEditMode()
  const {sponsors} = useTemplate()
  const save = useSaveTemplate()

  const add = () => {
    const numSponsors = Object.keys(sponsors.items).length
    const position = numSponsors + 1

    const newSponsor: TownhallSponsor = {
      name: 'Sponsor',
      description: '',
      formId: null,
      buttons: {},
      image: null,
      position,
      level: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      logoSize: 100,
    }
    generateHashId([
      'townhall_',
      'sponsor',
      position.toString(),
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      save({
        sponsors: {
          items: {
            [id]: newSponsor,
          },
        },
      })
    })
  }

  if (!isEditMode) {
    return null
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      aria-label="add sponsor"
      onClick={add}
    >
      Add Sponsor
    </Button>
  )
}
