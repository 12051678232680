import {api} from 'lib/url'
import {Form} from 'organization/Event/FormsProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import {useQuery} from 'react-query'

export interface FormStatistics {
  total_submission_count: number
  unique_attendee_submission_count: number
  questions: QuestionStatistics[]
}

export interface QuestionStatistics {
  id: string | number
  answer_total: number
  answers: {
    [key: string]: {
      submission_count: number
    }
  } | null
  submission_count: number
}

interface UseFormSubmissionsParams {
  form: Form
}

export function useFormStatistics(params: UseFormSubmissionsParams) {
  const {form} = params
  const {client} = useOrganization()

  return useQuery({
    queryKey: [
      'forms',
      form.id,
      'statistics',
      {
        populate_statistics_status: form.populate_statistics_status,
      },
    ],
    queryFn: () =>
      client.get<FormStatistics>(api(`/forms/${form.id}/statistics`)),
  })
}
