import ConfigDialog, {
  SaveButton,
} from 'organization/Event/DashboardConfig/ConfigDialog'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {generateHashId} from 'lib/crypto/hash'
import FeaturePageSelect, {
  FeaturePage,
  featurePages,
} from 'Event/Dashboard/components/FeaturePageSelect'

export default function AddFeaturePageDialog(props: {
  showing: boolean
  onClose: () => void
}) {
  const {showing, onClose} = props

  const {handleSubmit} = useForm()
  const update = useTownhallUpdate()

  const [page, setPage] = useState<FeaturePage | null>(null)

  const option = featurePages.find((featurePage) => featurePage.key === page)

  const save = () => {
    if (!option) {
      return
    }

    generateHashId([
      'townhall_',
      'feature_page_dialog',
      new Date().valueOf().toString(),
      Math.random().toString(),
    ]).then((id) => {
      update({
        navBar: {
          menuItems: {
            [id]: {
              isEnabled: true,
              title: option.label,
              link: option.link,
            },
          },
        },
      })
    })

    onClose()
  }

  return (
    <ConfigDialog
      isVisible={showing}
      onClose={onClose}
      title="Add Feature Page"
    >
      <form onSubmit={handleSubmit(save)}>
        <FeaturePageSelect value={page ?? 'speakers'} onChange={setPage} />
        <SaveButton disabled={!option} />
      </form>
    </ConfigDialog>
  )
}
