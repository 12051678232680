import {PurchasePageBlock, PurchasePageSection} from '../purchase-page'
import {HashMap} from '../lib/list'
import {isPast} from '../lib/date'

export const getBlocks = (section: {blocks: PurchasePageSection['blocks']}) =>
  Object.entries(section.blocks).reduce((acc, [id, block]) => {
    // Hide countdown timer blocks that have expired
    if (block.type === 'CountdownTimer' && isPast(block.countdownTimer.end)) {
      return acc
    }

    acc[id] = block
    return acc
  }, {} as HashMap<PurchasePageBlock>)
