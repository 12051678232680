import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

interface DeleteTicketParams {
  ticket: MarketplaceTicket
}

export function useDeleteTicket(params: DeleteTicketParams) {
  const {ticket: removed} = params
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: () => {
      const url = marketplaceApi(`/tickets/${removed.id}`)
      return client!.delete<MarketplaceTicket>(url)
    },
    onSuccess: () => {
      qc.setQueryData<MarketplaceTicket[]>(
        ['events', event.id, 'tickets'],
        (tickets) => {
          if (!tickets) {
            return []
          }

          return tickets.filter((ticket) => ticket.id !== removed.id)
        },
      )

      qc.removeQueries(['tickets', removed.id])
    },
  })
}
