import React from 'react'
import TextField from 'Event/auth/Login/TextField'
import {onChangeStringHandler} from 'lib/dom'
import PhoneNumberField, {
  PhoneNumberFieldProps,
} from 'lib/ui/TextField/PhoneNumberField'

interface RegistrationFormPhoneNumberFieldProps
  extends Omit<PhoneNumberFieldProps, 'inputComponent' | 'onChange'> {
  onChange?: (value: string) => void
}

export default function AreaRegistrationFormPhoneNumberField(
  props: RegistrationFormPhoneNumberFieldProps,
) {
  return <PhoneNumberField inputComponent={TextFieldWithRef} {...props} />
}

// TextField expects ref to be passed through inputProps.ref, so we'll
// wrap it, and forward it along correctly.
const TextFieldWithRef = React.forwardRef<
  HTMLInputElement,
  RegistrationFormPhoneNumberFieldProps
>((props, ref) => {
  const {inputProps, onChange, value, ...otherProps} = props

  return (
    <TextField
      inputProps={{
        ...inputProps,
        ref,
      }}
      {...otherProps}
      value={value || null}
      // Need to wrap in onChangeStringHandler, as the base text field is a MUI Textfield,
      // not lib/ui/Textfield
      onChange={onChange ? onChangeStringHandler(onChange) : undefined}
    />
  )
})
