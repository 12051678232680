import React from 'react'
import styles from './NavButton.module.css'
import {Content} from './Content'
import {LinkButtonProps} from './types'
import {AbsoluteLink} from '../../lib/ui/AbsoluteLink'

export default function LinkButton(props: LinkButtonProps) {
  const {id, link: dataLink, onClick, isLoginLink} = props
  // set default link if is not provided, that is not saved in db, it's just for render
  const link =
    // eslint-disable-next-line no-script-url
    !dataLink || dataLink?.length === 0 ? 'javascript:void(0);' : dataLink

  // Button may have been removed, but a property was set
  // after the fact. This would show up as an empty
  // button so let's just render null.
  const wasRemoved = Object.keys(props).length === 1
  if (wasRemoved) {
    return null
  }

  if (!link) {
    return null
  }

  const isElementLink = link.startsWith('#')
  const isJavascriptLink = link.startsWith('javascript')

  // If this button is a link to an #element, or `javascript:` then we'll disable
  // any new tab settings, and also always enable the link.
  if (isElementLink || isJavascriptLink || isLoginLink) {
    return (
      <AbsoluteLink
        className={styles.flexBox}
        to={link}
        onClick={onClick}
        disableStyles
        newTab={false}
        id={id}
        style={{
          minWidth: props.minSize ? `${props.minSize?.width}%` : 'auto',
          minHeight: props.minSize ? `${props.minSize?.height}%` : 'auto',
        }}
      >
        <Content {...props} />
      </AbsoluteLink>
    )
  }

  return (
    <AbsoluteLink
      className={styles.flexBox}
      to={link}
      onClick={onClick}
      disableStyles
      newTab
      id={id}
      style={{
        minWidth: props.minSize ? `${props.minSize?.width}%` : 'auto',
        minHeight: props.minSize ? `${props.minSize?.height}%` : 'auto',
      }}
    >
      <Content {...props} />
    </AbsoluteLink>
  )
}
