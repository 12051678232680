import {useEvent} from 'Event/EventProvider'
import {eventRoutes} from 'Event/Routes'
import React from 'react'
import {Redirect} from 'react-router'

/**
 * Event Slug for Tony Robbin's Business Mastery
 */
const BUSINESS_MASTERY_SLUG = 'bm0824'

export default function BusinessMasteryRoute(props: {children: JSX.Element}) {
  const {event} = useEvent()

  const isBusinessMastery = event.slug === BUSINESS_MASTERY_SLUG
  if (!isBusinessMastery) {
    return <Redirect to={eventRoutes.root} />
  }

  return props.children
}
