import React, {useCallback, useEffect, useState} from 'react'
import {api} from 'lib/url'
import {useAsync} from 'lib/async'
import {getLocalStorageItem} from 'lib/localStorage'
import {useOrganization} from 'organization/OrganizationProvider'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'

/**
 * CK Editor upload URL
 */
const uploadUrl = process.env.REACT_APP_CK_EDITOR_UPLOAD_URL
const licenseKey = process.env.REACT_APP_CK_EDITOR_LICENSE_KEY

const THEME_KEY = `__obvio_editor_custom_theme_mode__`

export type Theme = 'Auto' | 'Light' | 'Dark'
const DEFAULT_THEME: Theme = 'Auto'

export type TextEditorContextPropos = {
  ckToken: string
  ckUploadUrl: string
  ckLicenseKey: string
  theme: Theme
  selectTheme: (theme: Theme) => void
}

type TextEditorContextProps = TextEditorContextPropos

const TextEditorContext = React.createContext<
  TextEditorContextProps | undefined
>(undefined)

export default function TextEditorProvider(props: {children: React.ReactNode}) {
  const {loading, token} = useAccessToken()
  const [theme, setTheme] = useState<Theme | null>(null)

  const selectTheme = (theme: Theme) => {
    setTheme(theme)
    window.localStorage.setItem(THEME_KEY, theme)
  }

  useEffect(() => {
    if (theme) {
      return
    }

    const saved = getLocalStorageItem(THEME_KEY)
    if (saved && isTheme(saved)) {
      setTheme(saved)
    } else {
      setTheme(DEFAULT_THEME)
    }
  }, [theme])

  if (loading || !theme) {
    return <FullPageLoader />
  }

  if (!token) {
    throw new Error(
      'Missing access token for CKEditor; did fetching a token from API fail?',
    )
  }

  if (!uploadUrl) {
    throw new Error(
      'Missing upload URL for CKEditor; did you forget to set one in env?',
    )
  }

  if (!licenseKey) {
    throw new Error(
      'Missing license key for CKEditor; did you forget to set one in env?',
    )
  }

  return (
    <TextEditorContext.Provider
      value={{
        ckToken: token,
        ckUploadUrl: uploadUrl,
        ckLicenseKey: licenseKey,
        selectTheme,
        theme: theme,
      }}
    >
      {props.children}
    </TextEditorContext.Provider>
  )
}

export function useTextEditor() {
  const context = React.useContext(TextEditorContext)
  if (context === undefined) {
    throw new Error(`useTextEditor must be used within a TextEditorProvider`)
  }
  return context
}

export function useAccessToken() {
  const {client} = useOrganization()

  const fetch = useCallback(() => {
    const url = api('/ckeditor/token')
    return client.get<{token: string}>(url)
  }, [client])

  const {loading, data} = useAsync(fetch)

  return {
    loading,
    token: data?.token || null,
  }
}

function isTheme(theme: string): theme is Theme {
  return theme === 'Dark' || theme === 'Auto' || theme === 'Light'
}
