import React from 'react'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {TemplateSave, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from './Styling'
import {AgendaListSettings} from 'Event/template/Panels/Dashboard/AgendaList'
import Settings from './Settings'
import {Template} from 'Event/template'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export function AgendaListConfig(props: {agendaList: AgendaListSettings}) {
  const {agendaList} = props
  const addTranslation = useAddTranslation()

  const save = useSaveTemplate()
  const handleSave: TemplateSave<Template> = (data) => {
    const title = replaceAtPath(
      data,
      'agendaList.title',
      '{{agendaList_page_title}}',
    )

    const menuTitle = replaceAtPath(
      data,
      'agendaList.menuTitle',
      '{{agendaList_menu_title}}',
    )

    save({
      ...data,
      localization: addTranslation({
        agendaList_page_title: title ?? 'Agendas',
        agendaList_menu_title: menuTitle ?? 'Agendas',
      }),
    })
  }

  return (
    <Config title="Agendas" onSave={handleSave}>
      <SettingsPanel>
        <Settings agendaList={agendaList} />
      </SettingsPanel>
      <StylingPanel>
        <Styling agendaList={agendaList} />
      </StylingPanel>
    </Config>
  )
}
