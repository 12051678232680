import {Controller} from 'react-hook-form'
import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import ColorPicker from 'lib/ui/ColorPicker'
import Slider from 'lib/ui/Slider'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function SubmitButtonConfig() {
  const saveTemplate = useSaveTemplate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()
  const {changePassword} = useTemplate()

  const {
    form: {register},
  } = useConfig()

  const save = (data: any) => {
    const label = replaceAtPath(
      data,
      'changePassword.submitButton.label',
      '{{change_password_submit_button_label}}',
    )
    saveTemplate({
      ...data,
      localization: addTranslation({
        change_password_submit_button_label: label ?? '',
      }),
    })
  }

  return (
    <Config title="Submit Button" onSave={save}>
      <SettingsPanel>
        <TextField
          label="Submit Label"
          name="changePassword.submitButton.label"
          defaultValue={v(changePassword.submitButton.label)}
          inputProps={{
            'aria-label': 'submit button label',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}

export function StyleFields() {
  const {
    form: {control},
  } = useConfig()

  const {changePassword} = useTemplate()

  return (
    <>
      <Controller
        name="changePassword.submitButton.backgroundColor"
        defaultValue={changePassword.submitButton.backgroundColor}
        control={control}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Submit Button Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="changePassword.submitButton.textColor"
        defaultValue={changePassword.submitButton.textColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Submit Button Color"
            color={value}
            onPick={onChange}
            aria-label="submit button color"
          />
        )}
      />
      <Controller
        name="changePassword.submitButton.hoverColor"
        defaultValue={changePassword.submitButton.hoverColor}
        control={control}
        render={({value, onChange}) => (
          <BackgroundPicker
            label="Submit Button Hover Background"
            background={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="changePassword.submitButton.borderRadius"
        defaultValue={changePassword.submitButton.borderRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            label="Border Radius"
            aria-label="border radius"
            value={value}
            onChange={onChange}
            step={1}
            min={0}
            max={60}
          />
        )}
      />
    </>
  )
}
