import React from 'react'
import Button, {ButtonProps} from '@material-ui/core/Button'
import styled from 'styled-components'

export default function SubmitButton(props: ButtonProps) {
  const {children} = props
  return (
    <StyledButton
      variant="contained"
      color="primary"
      type="submit"
      fullWidth
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export const StyledButton = styled(Button)`
  background: #3565a3 !important;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px !important;
  text-transform: none !important;
  padding: 4px 0;
`
