import React, {useEffect, useState, useCallback} from 'react'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {useAutoUpdateSidebarItem} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import SidebarNavButtonConfigBase from 'Event/Dashboard/components/Sidebar/SidebarNavButtonConfig'
import {usePruneAssets} from 'lib/asset'
import {generateHashId} from 'lib/crypto/hash'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export function SidebarNavButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
    listId: string
  },
) {
  const {button, id, showing, onClose, listId} = props
  const [rules, setRules] = useState(button.rules || [])
  const addTranslation = useAddTranslation()
  const removeTranslations = useRemoveTranslations()
  const saveTemplate = useSaveTemplate()

  const pruneAssets = usePruneAssets()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(button.rules || [])
  }, [showing, button, props.id])

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: NavButtonWithSize) => {
    const image = updated.image
    const text = replaceAtPath(updated, 'text', `{{${id}_text}}`)

    pruneAssets({image}, button)
    saveTemplate({
      sidebarItems: {
        [listId]: {
          buttons: {[id]: updated},
        },
      },
      localization: addTranslation({
        [`${id}_text`]: text ?? '',
      }),
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing id')
    }

    onClose()
    saveTemplate({
      sidebarItems: {
        [listId]: {
          buttons: {[id]: REMOVE},
        },
      },
      localization: removeTranslations([`${id}_text`]),
    })
  }, [id, listId, onClose, removeTranslations, saveTemplate])

  const insert = (button: NavButtonWithSize) => {
    generateHashId([
      'sidebar_nav_button',
      Math.random().toString(),
      Date.now().toString(),
    ]).then((id) => {
      const componentId = `sidebar_button_${id}`

      saveTemplate({
        sidebarItems: {
          [listId]: {
            buttons: {
              [componentId]: {
                ...button,
                text: `{{${componentId}_text}}`,
              },
            },
          },
        },
        localization: addTranslation({
          [`${componentId}_text`]: button.text ?? '',
        }),
      })
    })
  }

  const save = (formData: any) => {
    const button: NavButtonWithSize = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, button)
      onClose()
      return
    }

    insert(button)
    onClose()
  }

  useAutoUpdateSidebarItem({
    item: id
      ? {
          buttons: {
            [id]: watch(),
          },
        }
      : {},
    when: showing && Boolean(id),
  })

  return (
    <SidebarNavButtonConfigBase
      button={button}
      id={id}
      watch={watch}
      control={control}
      register={register}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      handleSubmit={handleSubmit}
      save={save}
      showing={showing}
      onClose={onClose}
      formState={formState}
    />
  )
}
