import {onChangeCheckedHandler} from 'lib/dom'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {Label} from 'lib/ui/typography'
import TextEditor from 'lib/ui/form/TextEditor'
import {TextProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/Text'
import React from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  text: TextProps
}) {
  const {control, text} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isEnabled"
        defaultValue={text.isEnabled}
        control={control}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Label>Body</Label>
      <Controller
        name="body"
        control={control}
        defaultValue={v(text.body)}
        render={({value, onChange}) => (
          <TextEditor data={value} onChange={onChange} />
        )}
      />
    </>
  )
}
