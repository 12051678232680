import Command from '@ckeditor/ckeditor5-core/src/command';
import { isCustomButtonElement } from '../utils';

export default class ImageResizeCommand extends Command {
	override refresh() {
		const element = this.editor.model.document.selection.getSelectedElement();

		this.isEnabled = isCustomButtonElement( element );

		if ( !element || !element.hasAttribute( 'width' ) ) {
			this.value = null;
		} else {
			this.value = {
				width: element.getAttribute( 'width' ),
				height: null
			};
		}
	}

	override execute( options: {width: string | number} ) {
		const model = this.editor.model;
		const imageElement = model.document.selection.getSelectedElement()!;

		model.change( writer => {
			writer.setAttribute( 'width', options.width, imageElement );
		} );
	}
}

