import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import ColorPicker from 'lib/ui/ColorPicker'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import Slider from 'lib/ui/Slider'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import VisibilitySetting from 'organization/Marketplace/config/BlockConfig/VisibilitySettings'
import {SeparatorBlock} from 'Event/Marketplace/Block/Separator'
import {useConfigurableSection} from 'organization/Marketplace/config/ConfigurableSection'
import {Label} from 'lib/ui/typography'
import MarginPaddingInputs from 'lib/ui/MarginPaddingInputs'
import MoveSectionButtons from 'organization/Marketplace/config/BlockConfig/MoveSectionButtons'
import {useSection} from 'Event/Marketplace/Section'

interface SeparatorConfigProps extends SeparatorBlock {
  id: string
  sectionId: string
  onMoveSectionUp?: () => void
  onMoveSectionDown?: () => void
}

export default function SeparatorConfig(props: SeparatorConfigProps) {
  const {
    padding,
    alignment,
    id,
    separatorStyle,
    color,
    height,
    opacity,
    width,
    radius,
    onMoveSectionUp,
    onMoveSectionDown,
  } = props
  const {
    form: {control},
  } = useConfig()
  const [visibility, setVisibility] = useState(props.visibility)

  const {update} = useConfigurableSection()
  const {calculateVisibility} = useSection()

  const handleRemove = () => {
    update({
      blocks: {
        [id]: REMOVE,
      },
    })
    calculateVisibility()
  }

  const save = (data: any) => {
    data.visibility = visibility
    update({
      blocks: {
        [id]: data,
      },
    })
    calculateVisibility()
  }

  return (
    <Config title="Separator Block" onSave={save} onRemove={handleRemove}>
      <SettingsPanel>
        <Controller
          name={`separatorStyle`}
          defaultValue={separatorStyle}
          control={control}
          render={({value, onChange}) => (
            <Select
              fullWidth
              label="Separator Style"
              value={value}
              onChange={onUnknownChangeHandler(onChange)}
            >
              <Option value="solid">Solid</Option>
              <Option value="dotted">Dotted</Option>
              <Option value="dashed">Dashed</Option>
            </Select>
          )}
        />
        <VisibilitySetting
          visibility={visibility}
          setVisibility={setVisibility}
        />
        <MoveSectionButtons
          onMoveUp={onMoveSectionUp}
          onMoveDown={onMoveSectionDown}
        />
      </SettingsPanel>
      <StylingPanel>
        <CollapsibleSection
          label="Separator"
          storageKey={`blocks-${id}-styling`}
        >
          <Controller
            name={`color`}
            defaultValue={color}
            control={control}
            render={({value, onChange}) => (
              <ColorPicker
                label="Color"
                color={value}
                onPick={onChange}
                aria-label="separator color"
              />
            )}
          />
          <Controller
            name={`opacity`}
            defaultValue={opacity}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Opacity"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator opacity"
                value={value}
                onChange={onChange}
                step={1}
                min={0}
                max={100}
              />
            )}
          />
          <Controller
            name={`height`}
            defaultValue={height}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Height"
                unit="px"
                valueLabelDisplay="auto"
                aria-label="separator height"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
          <Controller
            name={`width`}
            defaultValue={width}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Width"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator width"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
          <Controller
            name={`radius`}
            defaultValue={radius}
            control={control}
            render={({value, onChange}) => (
              <Slider
                label="Border Radius"
                unit="%"
                valueLabelDisplay="auto"
                aria-label="separator border radius"
                value={value}
                onChange={onChange}
                step={1}
                min={1}
                max={100}
              />
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Alignment"
          storageKey={`blocks-${id}-alignment`}
        >
          <Controller
            name={`alignment.vertical`}
            defaultValue={alignment.vertical}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Vertical"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="start">Top</Option>
                <Option value="center">Center</Option>
                <Option value="end">Bottom</Option>
              </Select>
            )}
          />
          <Controller
            name={`alignment.horizontal`}
            defaultValue={alignment.horizontal}
            control={control}
            render={({value, onChange}) => (
              <Select
                fullWidth
                label="Horizontal"
                value={value}
                onChange={onUnknownChangeHandler(onChange)}
              >
                <Option value="left">Left</Option>
                <Option value="center">Center</Option>
                <Option value="right">Right</Option>
              </Select>
            )}
          />
        </CollapsibleSection>
        <CollapsibleSection
          label="Advanced Spacing"
          storageKey={`blocks-${id}-spacing`}
          open={false}
        >
          <Label>Padding</Label>
          <MarginPaddingInputs
            control={control}
            namePrefix={`padding`}
            values={padding}
          />
        </CollapsibleSection>
      </StylingPanel>
    </Config>
  )
}
