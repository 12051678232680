import React from 'react'
import styled from 'styled-components'
import {ValidationError} from 'lib/ui/api-client'
import {useState} from 'react'
import Title from 'lib/ui/PageHeader/Title'
import Page from 'organization/Event/Page'
import {useHistory} from 'react-router-dom'
import {CertificateBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {certificateRoutes} from 'organization/Event/Certificates/CertificateRoutes'
import {useForm} from 'react-hook-form'
import {fieldError} from 'lib/form'
import {spacing} from 'lib/ui/theme'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PageHeader from 'lib/ui/PageHeader'
import {Certificate} from 'lib/event-api/certificates/types'
import {useAddCertificate} from 'lib/event-api/certificates/use-add-certificate'

interface CreateCertificateData {
  title: string
  file: string
}

export const pdfUploadId = 'certificate-pdf-upload'
export const certificatePdfMaxSizeBytes = 10000000 // 10MB

export default function CreateCertificateForm() {
  const [file, setFile] = useState<null | File>(null)
  const {register, errors, handleSubmit} = useForm()
  const [serverError, setServerError] = useState<
    ValidationError<CreateCertificateData>
  >(null)
  const history = useHistory()
  const eventRoutes = useEventRoutes()
  const goToCertificate = (certificate: Certificate) => {
    history.push(certificateRoutes({certificate, eventRoutes}).design)
  }

  const addCertificate = useAddCertificate()

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    if (file && file.size > certificatePdfMaxSizeBytes) {
      return
    }

    setFile(file)
  }

  const submit = async (data: CreateCertificateData) => {
    if (!file || addCertificate.isLoading) {
      return
    }

    addCertificate.mutate(
      {
        title: data.title,
        source_pdf: file,
      },
      {
        onError: (error: any) => setServerError(error),
        onSuccess: goToCertificate,
      },
    )
  }

  const nameError = fieldError('title', {form: errors, response: serverError})

  return (
    <CertificateBreadcrumbs page="Create">
      <Page>
        <PageHeader>
          <Title text="Create Certificate" />
        </PageHeader>
        <form onSubmit={handleSubmit(submit)}>
          <TextField
            label="Title"
            name="title"
            required
            fullWidth
            variant="outlined"
            disabled={addCertificate.isLoading}
            inputProps={{
              ref: register({
                required: 'Title is required',
              }),
              'aria-label': 'certificate title input',
            }}
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <Box>
            <Button
              variant="outlined"
              color="primary"
              disabled={addCertificate.isLoading}
              aria-label="select pdf to upload"
            >
              <UploadButtonLabel htmlFor={pdfUploadId}>
                Upload PDF Doc
              </UploadButtonLabel>
            </Button>
            <input
              id={pdfUploadId}
              type="file"
              accept=".pdf,.jpeg,.jpg,.png,.gif"
              onChange={handleFileSelect}
              hidden
              aria-label="pdf upload input"
            />
            <FileBox file={file} />
          </Box>
          <Error>{serverError && serverError.message}</Error>
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={addCertificate.isLoading}
              type="submit"
              aria-label="create certificate"
            >
              Create
            </Button>
          </div>
        </form>
      </Page>
    </CertificateBreadcrumbs>
  )
}

function FileBox(props: {file: File | null}) {
  const {file} = props
  if (!file) {
    return null
  }

  return <Box>{file.name}</Box>
}

const Box = styled.div`
  margin: ${(props) => props.theme.spacing[4]} 0;
`

const UploadButtonLabel = styled.label`
  cursor: pointer;
`

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)
