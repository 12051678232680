export async function generateHashId(parts: string[], length = 7) {
  return (await hash(parts.join('.'))).slice(0, length)
}

export async function hash(value: string) {
  const enc = new TextEncoder()
  const hash = await crypto.subtle.digest('SHA-1', enc.encode(value))
  return Array.from(new Uint8Array(hash))
    .map((v) => v.toString(16).padStart(2, '0'))
    .join('')
}
