import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useSection} from '../../Section'
import {BlockBase} from '../base'
import {Duration, now, duration, date} from '../../lib/date'
import {useInterval} from '../../lib/interval'
import {rgba} from '../../lib/color'
import {BlockComponentProps} from '../../Block'
import Container from '../../Block/Container'
import {Ordered} from '../../lib/list'
import styles from './CountdownTimer.module.css'
import {Font, getFontStyles} from '../../lib/font'

export interface CountDownTimerSettings extends Ordered {
  enabled: boolean
  end: string
  backgroundColor: string
  textColor: string
  backgroundOpacity: number
  description: string
  numberColor: string
  numberBackgroundColor: string
  numberBackgroundOpacity: number
  numberBackgroundRadius: number
  numberBackgroundWidth: number
  numberBackgroundHeight: number
  separator: string
  labels: Duration
  font: Font | null
  fontSize: number
}

export interface CountdownTimerBlock extends BlockBase {
  type: 'CountdownTimer'
  countdownTimer: CountDownTimerSettings
  isMobileMode?: boolean
}

type CountDownTimerProps = CountdownTimerBlock['countdownTimer'] & {
  id: string
  narrow?: boolean
  onRender?: () => void
  onHidden?: () => void
}

export default function CountdownTimer(
  props: CountdownTimerBlock & BlockComponentProps,
) {
  const {calculateVisibility} = useSection()
  return (
    <Container {...props}>
      <Timer
        {...props.countdownTimer}
        id={props.id || ''}
        isEditMode={props.isEditMode}
        isMobileMode={props.isMobileMode}
        onHidden={calculateVisibility}
      />
    </Container>
  )
}

function Timer(
  props: CountDownTimerProps & {isEditMode?: boolean; isMobileMode?: boolean},
) {
  const {
    textColor,
    end,
    enabled,
    description,
    onRender,
    onHidden,
    narrow,
    isMobileMode,
    isEditMode,
  } = props

  const [showing, setShowing] = useState(true)
  const [timeLeft, setTimeLeft] = useState<Duration>({
    hours: '00',
    minutes: '00',
    seconds: '00',
  })

  const timerRef = useRef<HTMLDivElement>(null)

  const resize = useCallback(() => {
    if (!timerRef.current || !timerRef.current.parentElement) {
      return
    }

    const itemWidth = timerRef.current.clientWidth
    const parentWidth = timerRef.current.parentElement.clientWidth

    const shouldScale = isMobileMode || itemWidth > parentWidth
    if (shouldScale) {
      const scale = parentWidth / (itemWidth + 100)
      timerRef.current.style.scale = scale.toString()
      return
    }

    timerRef.current.style.scale = '1'
  }, [isMobileMode])

  // render on every tick
  resize()

  const update = useCallback(() => {
    if (!showing || !enabled || !end) {
      // Nothing to update
      return
    }

    const current = now()
    const isAfter = date(end).isAfter(current)
    const diff = duration(current, end)

    if (!isEditMode) {
      setShowing(isAfter)
    }

    setTimeLeft(diff)
  }, [enabled, end, isEditMode, showing])

  useInterval(update, 1000)

  const checkHidden = () => {
    return !showing || !enabled
  }

  const isHidden = checkHidden()

  /**
   * Notify any parents that we're now rendering content
   * in case they need to re-calculate sizes.
   */
  useEffect(() => {
    if (isHidden) {
      onHidden && onHidden()
      return
    }

    onRender && onRender()
  }, [isHidden, onRender, onHidden])

  if (isHidden) {
    return null
  }

  return (
    <div
      ref={timerRef}
      className={styles.root}
      style={{
        color: textColor,
        padding: narrow ? '8px' : '16px',
      }}
      aria-label="count down timer"
    >
      <Body {...props} timeLeft={timeLeft} />
      <p>{description}</p>
    </div>
  )
}

function Body(
  props: CountDownTimerProps & {
    timeLeft: Duration
  },
) {
  const {
    numberColor,
    numberBackgroundColor,
    numberBackgroundOpacity,
    numberBackgroundRadius,
    separator,
    description,
    id,
    timeLeft,
    labels,
  } = props

  const numberBackground = numberBackgroundColor
    ? rgba(numberBackgroundColor, numberBackgroundOpacity / 100)
    : '#FFFFFF'

  const hasDescription = Boolean(description)

  // Check whether a duration's index is the last unit. Useful to check if
  // we need to render a separator.
  const isLast = (index: number) => index === Object.keys(timeLeft).length - 1

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: hasDescription ? '16px' : 0,
      }}
    >
      {Object.entries(timeLeft).map(([key, value], index) => (
        <React.Fragment key={`${id}-${key}`}>
          <div style={{display: 'flex'}}>
            <span
              className={styles.number}
              style={{
                ...getFontStyles(props.font),
                fontSize: props.fontSize,
                lineHeight: 'normal',
                minWidth: props.numberBackgroundWidth,
                minHeight: props.numberBackgroundHeight,
                color: numberColor,
                borderRadius: `${numberBackgroundRadius / 2}%`,
                background: numberBackground,
              }}
            >
              <div style={{width: '100%'}}>{value}</div>
              <div className={styles.label} style={{fontSize: props.fontSize}}>
                {labels[key as keyof Duration]}
              </div>
            </span>
            <span
              className={styles.separator}
              style={{
                ...getFontStyles(props.font),
                fontSize: props.fontSize,
                display: !isLast(index) ? 'flex' : 'none',
              }}
            >
              {key === 'days' ? ' ' : separator}
            </span>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
