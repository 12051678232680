import {useLanguage} from 'Event/LanguageProvider'
import {Language} from 'Event/LanguageProvider/language'
import React, {useCallback, useState} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import RuleConfig from 'Event/attendee-rules/RuleConfig'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'

export default function SetRulesButton(props: {language: Language['name']}) {
  const {language} = props
  const {languages} = useLanguage()
  const [dialogVisible, setDialogVisible] = useState(false)
  const toggleDialog = () => setDialogVisible(!dialogVisible)
  const saveTemplate = useSaveTemplate()

  const target = languages.find((l) => l.name === language)
  const save = useCallback(
    (rules) => {
      if (!target) {
        return
      }

      const updatedLanguage = {
        ...target,
        rules,
      }

      const updates = {
        localization: {
          languages: languages.map((l) => {
            const isTarget = l.name === updatedLanguage.name
            if (!isTarget) {
              return l
            }

            return updatedLanguage
          }),
        },
      }

      saveTemplate(updates)
    },
    [target, languages, saveTemplate],
  )

  const description =
    'Select this language for an attendee when the following rules match:'

  return (
    <>
      <Dialog open={dialogVisible && Boolean(target)} onClose={toggleDialog}>
        <DialogContent>
          <Box py={3} px={1}>
            <StyledRuleConfig
              visible
              onChange={save}
              rules={target?.rules || []}
              description={description}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Button onClick={toggleDialog} variant="outlined" aria-label="set rules">
        Selection Rules
      </Button>
    </>
  )
}

const StyledRuleConfig = styled(RuleConfig)`
  margin-bottom: 0;
`
