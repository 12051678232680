import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import React from 'react'
import FontStyleInput from 'lib/ui/typography/FontStyleInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import {EnabledSwitch} from 'lib/ui/form/Switch'
import {Controller, UseFormMethods} from 'react-hook-form'
import {AgendaListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import {onChangeCheckedHandler} from 'lib/dom'
import {useAttendeeVariables} from 'Event'

export default function Settings(props: {
  control: UseFormMethods['control']
  register: UseFormMethods['register']
  list: AgendaListProps
}) {
  const {control, register, list} = props
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="isEnabled"
        control={control}
        defaultValue={list.isEnabled}
        render={({value, onChange}) => (
          <EnabledSwitch
            checked={value}
            onChange={onChangeCheckedHandler(onChange)}
            arial-label="config enabled switch"
          />
        )}
      />
      <Label>Title</Label>
      <TextField
        name="title"
        defaultValue={v(list.title)}
        aria-label="update agendas title"
        fullWidth
        inputProps={{
          ref: register,
        }}
      />
      <Label>Description</Label>
      <Controller
        name="description"
        control={control}
        defaultValue={v(list.description)}
        render={({value, onChange}) => (
          <Input
            id="agenda-adornment-description"
            type="text"
            value={value}
            onChange={onChange}
            aria-label="update agendas description"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <Controller
                  name="descriptionFontStyles"
                  control={control}
                  defaultValue={list.descriptionFontStyles || ''}
                  render={({value, onChange}) => (
                    <FontStyleInput onChange={onChange} value={value} />
                  )}
                />
              </InputAdornment>
            }
          />
        )}
      />
      <Label>Footer</Label>
      <Controller
        name="footer"
        control={control}
        defaultValue={list.footer || ''}
        render={({value, onChange}) => (
          <Input
            id="agenda-adornment-footer"
            type="text"
            value={value}
            onChange={onChange}
            aria-label="update agendas footer"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <Controller
                  name="footerFontStyles"
                  control={control}
                  defaultValue={list.footerFontStyles || ''}
                  render={({value, onChange}) => (
                    <FontStyleInput onChange={onChange} value={value} />
                  )}
                />
              </InputAdornment>
            }
          />
        )}
      />
    </>
  )
}
