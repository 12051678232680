import React from 'react'
import Configurable from 'organization/Event/Configurable'
import {PurchaseFormBlock} from 'Event/Marketplace/Block/PurchaseForm'
import {BlockComponentProps} from 'Event/Marketplace/Block'
import PurchaseFormTitle from 'Event/Marketplace/Block/PurchaseForm/PurchaseFormTitle'
import PurchaseFormTitleConfig from 'organization/Marketplace/config/ConfigurablePurchaseForm/PurchaseFormTitleConfig'

export interface ConfigurablePurchaseFormTitleProps
  extends PurchaseFormBlock,
    BlockComponentProps {
  children: string
  inputName: string
}

export default function ConfigurablePurchaseFormTitle(
  props: ConfigurablePurchaseFormTitleProps,
) {
  if (props.title?.enabled === false) {
    return null
  }

  return (
    <Configurable>
      <PurchaseFormTitleConfig {...props} />
      <PurchaseFormTitle {...props} />
    </Configurable>
  )
}
