import React from 'react'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import {SubHead} from 'lib/ui/typography'
import Button from 'lib/ui/Button'
import defaultLogo from 'assets/images/logo_vertical.png'
import grey from '@material-ui/core/colors/grey'
import Icon from 'lib/ui/Icon'
import {UpdateTemplateFunction} from 'organization/Marketplace/config/types'
import {ObvioEvent} from 'Event'

const marketplaceStoreUrl = process.env.REACT_APP_MARKETPLACE_STORE_APP_URL

interface TemplateCardProps {
  event: ObvioEvent
  updateTemplate: UpdateTemplateFunction
  onSelect: () => void
  loading: boolean
  pageLabel: string
  onView: () => void
}

export default function TemplateCard(props: TemplateCardProps) {
  const {event, onSelect, loading, pageLabel} = props

  const logo = event.logo?.url || defaultLogo

  const onViewPurchasePage = () => {
    window.open(`${marketplaceStoreUrl}/${event.slug}`, '_blank')
  }

  return (
    <StyledCard>
      <Box>
        <Image src={logo} alt={event.name} aria-label="event logo" />
      </Box>
      <Container>
        <StyledCardContent>
          <SubHead>{event.name}</SubHead>
        </StyledCardContent>
        <StyledCardActions>
          <ActionsContainer>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              aria-label="select template"
              disabled={loading}
              onClick={onSelect}
            >
              Select
            </Button>
            <Button
              variant="text"
              color="primary"
              fullWidth
              aria-label="view sample dashboard"
              startIcon={<Icon className="fas fa-external-link" />}
              onClick={onViewPurchasePage}
            >
              View {pageLabel}
            </Button>
          </ActionsContainer>
        </StyledCardActions>
      </Container>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    grid-gap: ${(props) => props.theme.spacing[6]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`

const Container = styled.div`
  padding: ${(props) => props.theme.spacing[4]};
`

const StyledCardContent = styled(CardContent)`
  padding: ${(props) => props.theme.spacing[6]} 0;
`

const StyledCardActions = styled(CardActions)`
  display: block !important;
  padding: 0;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.spacing[2]};
`

const Box = styled.div`
  padding: ${(props) => props.theme.spacing[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 180px;

  &:hover {
    background: ${grey[200]};
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 152px;
`
