import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import styled from 'styled-components'
import {spacing} from 'lib/ui/theme'
import React, {useState} from 'react'
import {Controller, UseFormMethods} from 'react-hook-form'
import {useValidatedForm} from 'lib/form'
import moment from 'moment'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'
import {useOrganization} from 'organization/OrganizationProvider'
import {useToggle} from 'lib/toggle'
import {useEvent} from 'Event/EventProvider'
import {api} from 'lib/url'
import {ObvioEvent} from 'Event'
import Center from 'lib/ui/layout/Center'
import spinner from 'assets/images/obvio_spinner_80.gif'
import Box from '@material-ui/core/Box'
import DescribedTextField from 'lib/ui/TextField/DescribedTextField'
import Subheading from 'lib/ui/typography/Subheading'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'
import {TextFieldProps} from '@material-ui/core/TextField'
import UrlField from 'lib/ui/TextField/UrlField'
import {slugHelperText} from 'organization/EventList/CreateEventForm/Form'

export interface DuplicateEventData {
  name: string
  slug: string
  start: string
  end: string
  num_expected_attendees: number
  copy_areas: boolean
  copy_rooms: boolean
  copy_rules: boolean
  copy_broadcasts: boolean
  copy_certificates: boolean
}

export default function Form(props: {
  onComplete: () => void
  onCancel: () => void
}) {
  const {
    errors,
    handleSubmit,
    control,
    responseError,
    setResponseError,
    watch,
    setValue,
    register,
  } = useValidatedForm()

  const duplicateEvent = useDuplicateEvent()
  const {flag: submitting, toggle: toggleSubmitting} = useToggle()
  const [hasEndDateTimeChange, setHasEndDateTimeChange] = useState(false)

  const submit = (data: DuplicateEventData) => {
    if (submitting) {
      return
    }

    toggleSubmitting()

    duplicateEvent(data)
      .then(() => {
        setTimeout(() => {
          props.onComplete()
        }, 5000)
      })
      .catch((error) => {
        setResponseError(error)
        toggleSubmitting()
      })
  }

  const hasDuplicateAreas = watch('copy_areas')
  const inThreeDays = moment().add(3, 'days').toISOString()
  const inSixDays = moment().add(6, 'days').toISOString()
  const slug = watch('slug')
  const systemDomainId = watch('system_domain_id')

  const handleStartDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }
    setValue('start', date.toISOString())
    if (!hasEndDateTimeChange) {
      setValue('end', date.toISOString())
    }
  }

  const handleEndDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      throw new Error('Date is required')
    }
    setValue('end', date.toISOString())
    setHasEndDateTimeChange(true)
  }

  if (submitting) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Controller
        name="name"
        defaultValue=""
        control={control}
        rules={{
          required: 'Name is required',
        }}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Event Name*"
            aria-label="event name"
            description={'Your event name'}
            name="name"
            required
            placeholder="Name"
            fullWidth
            disabled={submitting}
            value={value}
            onChange={onChange}
            helperText={errors.name}
            error={Boolean(errors.name)}
          />
        )}
      />
      <UrlField
        title="Unique URL"
        description={slugHelperText(errors, slug, systemDomainId)}
        register={register}
        errors={errors}
        submitting={submitting}
        control={control}
        fullWidth
      />
      <DateHeaderBox>
        <Subheading>Event date/time</Subheading>
      </DateHeaderBox>

      <Controller
        name="start"
        control={control}
        defaultValue={inThreeDays}
        rules={{
          required: 'Start is required',
        }}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            disabled={submitting}
            value={value}
            onChange={handleStartDate}
            fullWidth
            label="Start"
            TextFieldComponent={(props: TextFieldProps) => (
              <DescribedTextField
                aria-label="start"
                description="Enter start date/time of event"
                name="start"
                placeholder="Start date/time"
                fullWidth
                disabled={submitting}
                onChange={onChange}
                value={props.value as string}
                onClick={props.onClick}
              />
            )}
          />
        )}
      />
      <Controller
        name="end"
        control={control}
        defaultValue={inSixDays}
        rules={{
          required: 'End is required',
        }}
        render={({onChange, value}) => (
          <LocalizedDateTimePicker
            disabled={submitting}
            value={value}
            onChange={handleEndDate}
            fullWidth
            label="End"
            TextFieldComponent={(props: TextFieldProps) => (
              <DescribedTextField
                aria-label="end"
                description="Enter end date/time of event"
                name="end"
                placeholder="End date/time"
                fullWidth
                disabled={submitting}
                onChange={onChange}
                value={props.value as string}
                onClick={props.onClick}
              />
            )}
          />
        )}
      />
      <Controller
        name="num_expected_attendees"
        defaultValue=""
        control={control}
        rules={{
          required: 'Expected Number of Attendees is required',
        }}
        render={({onChange, value}) => (
          <DescribedTextField
            title="Expected Number of Attendees"
            description="Expected Number of Attendees"
            aria-label="expected number of attendees"
            error={Boolean(errors.numAttendees)}
            helperText={errors.numAttendees}
            type="number"
            name="num_expected_attendees"
            placeholder="Number of attendees"
            fullWidth
            disabled={submitting}
            value={value}
            onChange={onChange}
            required
          />
        )}
      />
      <FormControl fullWidth disabled={submitting}>
        <FormControlLabel
          control={
            <Controller
              type="checkbox"
              control={control}
              name="copy_areas"
              defaultValue={false}
              render={({onChange, value}) => (
                <Switch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{'aria-label': 'toggle duplicate areas'}}
                />
              )}
            />
          }
          label="Duplicate Areas"
        />
      </FormControl>
      <CopyRoomsSwitch
        showing={hasDuplicateAreas}
        submitting={submitting}
        control={control}
      />

      <FormControl fullWidth disabled={submitting}>
        <FormControlLabel
          control={
            <Controller
              type="checkbox"
              control={control}
              name="copy_certificates"
              defaultValue={false}
              render={({onChange, value}) => (
                <Switch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{'aria-label': 'toggle duplicate certificates'}}
                />
              )}
            />
          }
          label="Duplicate Certificates"
        />
      </FormControl>

      <FormControl fullWidth disabled={submitting}>
        <FormControlLabel
          control={
            <Controller
              type="checkbox"
              control={control}
              name="copy_broadcasts"
              defaultValue={false}
              render={({onChange, value}) => (
                <Switch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{'aria-label': 'toggle duplicate broadcasts'}}
                />
              )}
            />
          }
          label="Duplicate Broadcasts"
        />
      </FormControl>
      <ErrorMessage>{responseError && responseError.message}</ErrorMessage>
      <ActionButton
        type="submit"
        variant="contained"
        fullWidth
        color="primary"
        size="large"
        disabled={submitting}
        aria-label="submit"
      >
        Duplicate Event
      </ActionButton>
      <ActionButton
        variant="outlined"
        fullWidth
        color="primary"
        size="large"
        onClick={props.onCancel}
        disabled={submitting}
      >
        Cancel
      </ActionButton>
    </form>
  )
}

function CopyRoomsSwitch(props: {
  showing: boolean
  control: UseFormMethods['control']
  submitting: boolean
}) {
  const {showing, control, submitting} = props
  if (!showing) {
    return null
  }

  return (
    <>
      <FormControl fullWidth disabled={submitting}>
        <FormControlLabel
          control={
            <Controller
              type="checkbox"
              control={control}
              name="copy_rules"
              defaultValue={false}
              render={({onChange, value}) => (
                <Switch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{'aria-label': 'toggle duplicate rules'}}
                />
              )}
            />
          }
          label="Duplicate Rules"
        />
      </FormControl>
      <FormControl fullWidth disabled={submitting}>
        <FormControlLabel
          control={
            <Controller
              type="checkbox"
              control={control}
              name="copy_rooms"
              defaultValue={false}
              render={({onChange, value}) => (
                <Switch
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{'aria-label': 'toggle duplicate rooms'}}
                />
              )}
            />
          }
          label="Duplicate Rooms"
        />
      </FormControl>
    </>
  )
}

function ErrorMessage(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return <ErrorText color="error">{props.children}</ErrorText>
}

const ErrorText = withStyles({
  root: {
    marginBottom: spacing[3],
  },
})(Typography)

const ActionButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[2]};
`

function useDuplicateEvent() {
  const {client} = useOrganization()
  const {event} = useEvent()
  const url = api(`/events/${event.id}/duplicate`)

  return (data: DuplicateEventData) => client.post<ObvioEvent>(url, data)
}

function Loader() {
  return (
    <StyledCenter>
      <Container>
        <Spinner src={spinner} alt="loading" />
        <Box mb={1}>
          <Typography variant="h5" align="center">
            We're Creating a Duplicate of Your Event For You.
          </Typography>
        </Box>
        <Typography align="center">
          You'll be redirected to your events page, and can navigate away from
          this page while we build your new event. <br /> It will be ready soon!
        </Typography>
      </Container>
    </StyledCenter>
  )
}

const StyledCenter = styled(Center)`
  background: #ffffff;
  z-index: 10000;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Spinner = styled.img`
  width: 64px;
`
const DateHeaderBox = styled(Box)`
  margin-top: ${(props) => props.theme.spacing[10]};
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`
