import Published from 'Event/Dashboard/editor/views/Published'
import styled from 'styled-components'
import React from 'react'
import {useState} from 'react'
import EditModeOnly from 'Event/Dashboard/editor/views/EditModeOnly'
import PostFormStylesConfig from 'Event/Dashboard/components/BlogPosts/PostFormStylesConfig'
import PostStylesConfig from 'Event/Dashboard/components/BlogPosts/PostStylesConfig'
import AddBlogPostButton from 'Event/Dashboard/components/BlogPosts/AddBlogPostButton'
import {EditPost} from 'Event/Dashboard/components/BlogPosts/BlogPostConfig'
import {Editable} from 'Event/Dashboard/editor/views/EditComponent'
import VisibleOnMatch from 'Event/attendee-rules/VisibleOnMatch'
import Scheduled from 'lib/ui/layout/Scheduled'
import {useLoadFont} from 'lib/FontSelect'
import {rgba} from 'lib/color'
import {BlogPostProps, getSortedIds} from 'Event/Dashboard/components/BlogPosts'
import {useLeftyTemplate} from 'Event/template/Lefty'
import CountDownTimers from 'Event/template/Lefty/Dashboard/CountDownTimers'

export function BlogPostList(props: {
  children: (props: BlogPostProps) => JSX.Element
  PostStylesConfig?: React.FC
}) {
  const template = useLeftyTemplate()
  const {blogPosts: posts, postStyles} = template
  const backgroundColor = rgba(
    postStyles.containerBackgroundColor,
    postStyles.containerBackgroundOpacity / 100,
  )
  const PostStylesConfigComponent = props.PostStylesConfig || PostStylesConfig

  const [editing, setEditing] = useState<string | null>(null)

  const sortedIds = getSortedIds(posts)

  useLoadFont(postStyles.titleFont)

  return (
    <Container
      backgroundColor={backgroundColor}
      padding={postStyles.containerPadding}
      borderWidth={postStyles.containerBorderWidth}
      borderRadius={postStyles.containerBorderRadius}
      borderColor={postStyles.containerBorderColor}
    >
      <EditPost id={editing} onClose={() => setEditing(null)} />
      <StyledCountDownTimers />
      <EditModeOnly>
        <PostStylesConfigComponent />
        <PostFormStylesConfig />
        <StyledAddBlogPostButton />
      </EditModeOnly>
      {sortedIds.map((id, index) => {
        const post = posts[id]

        const isLast = index === sortedIds.length - 1

        return (
          <Editable key={id} onEdit={() => setEditing(id)}>
            <Published component={post}>
              <VisibleOnMatch rules={post.rules}>
                <Scheduled
                  component={{
                    showingFrom: post.publishAt,
                    showingUntil: post.showingUntil,
                  }}
                >
                  {props.children({
                    isLast,
                    post,
                    postId: id,
                  })}
                </Scheduled>
              </VisibleOnMatch>
            </Published>
          </Editable>
        )
      })}
    </Container>
  )
}

const StyledAddBlogPostButton = styled(AddBlogPostButton)`
  margin-bottom: ${(props) => props.theme.spacing[5]}!important;
`

const Container = styled.div<{
  backgroundColor: string
  padding?: {
    left: number
    right: number
    top: number
    bottom: number
  }
  borderWidth?: number
  borderRadius?: number
  borderColor?: string
}>`
  border: 1px solid;
  background-color: ${(props) => props.backgroundColor};
  padding-left: ${(props) => (props.padding ? props.padding.left : 0)}px;
  padding-right: ${(props) => (props.padding ? props.padding.right : 0)}px;
  padding-top: ${(props) => (props.padding ? props.padding.top : 0)}px;
  padding-bottom: ${(props) => (props.padding ? props.padding.bottom : 0)}px;
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : 0)}px;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)}px;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : 'transparent'};
`

const StyledCountDownTimers = styled(CountDownTimers)`
  div div {
    justify-content: center;
  }
`
