import React, {useCallback} from 'react'
import {generateHashId} from 'lib/crypto/hash'
import {useForm} from 'react-hook-form'
import {useLeftyUpdate} from 'Event/template/Lefty'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Lefty/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Lefty/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
export default function TimerConfig(
  props: ComponentConfigProps & {
    countDownTimer: CountDownTimerSettings
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, countDownTimer} = props
  const updateTemplate = useLeftyUpdate()

  const {control, handleSubmit, formState, watch} = useForm()

  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const update = (id: string, data: CountDownTimerSettings) => {
    const {labels, description, separator} = data

    const updated = {
      ...data,
      labels: {
        days: `{{countdown_timer_items_${id}_labels_days}}`,
        hours: `{{countdown_timer_items_${id}_labels_hours}}`,
        minutes: `{{countdown_timer_items_${id}_labels_minutes}}`,
        seconds: `{{countdown_timer_items_${id}_labels_seconds}}`,
      },
      description: `{{countdown_timer_items_${id}_description}}`,
      separator: `{{countdown_timer_items_${id}_separator}}`,
    }

    updateTemplate({
      localization: addTranslation({
        [`countdown_timer_items_${id}_labels_days`]: labels.days || '',
        [`countdown_timer_items_${id}_labels_hours`]: labels.hours,
        [`countdown_timer_items_${id}_labels_minutes`]: labels.minutes,
        [`countdown_timer_items_${id}_labels_seconds`]: labels.seconds,
        [`countdown_timer_items_${id}_description`]: description,
        [`countdown_timer_items_${id}_separator`]: separator,
      }),
      countDownTimers: {
        [id]: updated,
      },
    })
  }

  const removeCountDownTimer = useCallback(() => {
    if (!id) {
      throw new Error('Missing count down timer id')
    }

    updateTemplate({
      localization: removeTranslations([
        `countdown_timer_items_${id}_labels_days`,
        `countdown_timer_items_${id}_labels_hours`,
        `countdown_timer_items_${id}_labels_minutes`,
        `countdown_timer_items_${id}_labels_seconds`,
        `countdown_timer_items_${id}_description`,
        `countdown_timer_items_${id}_separator`,
      ]),
      countDownTimers: {
        [id]: REMOVE,
      },
    })
  }, [updateTemplate, id, removeTranslations])

  const save = (data: CountDownTimerSettings) => {
    if (id) {
      update(id, data)
    } else {
      generateHashId([
        'lefty_',
        'countdown_timer_',
        new Date().valueOf().toString(),
        Math.random().toString(),
      ]).then((id) => {
        update(id, data)
      })
    }
    onClose()
  }

  const showingRemoveButton = Boolean(id)

  useAutoUpdate({
    values: {
      countDownTimers: {
        [id!]: watch(),
      },
    },
    when: Boolean(id),
  })

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Count Down Timer Config"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} countDownTimer={countDownTimer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} countDownTimer={countDownTimer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove countdown"
          onClick={removeCountDownTimer}
          showing={showingRemoveButton}
        />
      </Footer>
    </ComponentConfig>
  )
}
