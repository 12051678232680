import {useEvent} from 'Event/EventProvider'
import {MarketplaceTicket} from 'Event/Marketplace/lib/marketplace-ticket'
import {marketplaceApi} from 'lib/marketplace-api/url'
import {useEventMarketplaceClient} from 'lib/marketplace-api/use-event-marketplace-client'
import {useMutation, useQueryClient} from 'react-query'

interface DuplicateTicketParams {
  ticket: MarketplaceTicket
}

export function useDuplicateTicket(params: DuplicateTicketParams) {
  const {ticket} = params
  const {client} = useEventMarketplaceClient()
  const qc = useQueryClient()
  const {event} = useEvent()

  return useMutation({
    mutationFn: () => {
      const url = marketplaceApi(`/tickets/${ticket.id}/duplicate`)
      return client!.post<MarketplaceTicket>(url)
    },
    onSuccess: (duplicated) => {
      qc.setQueryData<MarketplaceTicket[]>(
        ['events', event.id, 'tickets'],
        (tickets) => {
          if (!tickets) {
            return [duplicated]
          }

          return [...tickets, duplicated]
        },
      )

      qc.setQueryData<MarketplaceTicket>(
        ['tickets', ticket.id],
        () => duplicated,
      )
    },
  })
}
