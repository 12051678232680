import React from 'react'
import styled from 'styled-components'
import {useEventRoutes} from 'organization/Event/EventRoutes'
import {routesWithValue} from 'lib/url'
import Page from 'organization/Event/Page'
import PageHeader from 'lib/ui/PageHeader'
import Title from 'lib/ui/PageHeader/Title'
import HasPermission from 'organization/HasPermission'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import NewItemButton from 'lib/ui/PageHeader/NewItemButton'
import PageBreadcrumbs from 'organization/Event/Page/PageBreadcrumbs'
import {EVENT_DESIGN} from 'organization/PermissionsProvider'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import EditButton from 'lib/ui/Button/CustomButton'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import {MenuIcon} from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import {colors} from 'lib/ui/theme'
import {useCertificates} from 'lib/event-api/certificates/use-certificates'
import {useDeleteCertificate} from 'lib/event-api/certificates/use-delete-certificate'
import {Certificate} from 'lib/event-api/certificates/types'

export default function CertificatesList() {
  const routes = useEventRoutes()

  return (
    <PageBreadcrumbs page="Certificates">
      <Page>
        <PageHeader>
          <Title text="Certificates" />
          <HasPermission required={EVENT_DESIGN}>
            <RelativeLink disableStyles to={routes.certificates.create}>
              <NewItemButton
                text="New Certificate"
                aria-label="create certificate"
              />
            </RelativeLink>
          </HasPermission>
        </PageHeader>
        <Certificates />
      </Page>
    </PageBreadcrumbs>
  )
}

function Certificates() {
  const {data: certificates} = useCertificates()
  const deletCertificate = useDeleteCertificate()
  const routes = useEventRoutes()
  const certificateRoute = (certificate: Certificate) =>
    routesWithValue(
      ':certificate',
      String(certificate.id),
      routes.certificates[':certificate'],
    )

  if (certificates === undefined) {
    return null
  }

  const isEmpty = certificates.length === 0

  if (isEmpty) {
    return <div>No certificates have been created</div>
  }

  return (
    <Box>
      <TableBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificates.map((certificate) => (
              <TableRow aria-label="certificate" key={certificate.id}>
                <TableCell>
                  <RelativeLink to={certificateRoute(certificate).design}>
                    <EditButton
                      variant="text"
                      textColor={colors.primary}
                      aria-label="certificate-name"
                    >
                      {certificate.title}
                    </EditButton>
                  </RelativeLink>
                </TableCell>
                <MenuTableCell>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    button={({open}) => (
                      <IconButton
                        onClick={open}
                        aria-label="view ticket actions"
                      >
                        <MenuIcon iconSize={18} />
                      </IconButton>
                    )}
                  >
                    <MenuItem
                      children="Delete"
                      color="danger"
                      disabled={deletCertificate.isLoading}
                      onClick={() => deletCertificate.mutate({certificate})}
                    />
                  </Menu>
                </MenuTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableBox>
    </Box>
  )
}

const TableBox = styled.div`
  overflow: auto;
`

const MenuTableCell = styled(TableCell)`
  width: 50px;
`
