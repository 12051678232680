import {SignJWT} from 'jose'

interface GenerateJwtParams {
  payload: Record<string, any>
  expirySecs?: number
  secret: string
  headers?: Record<string, string>
}
export async function signJwt(params: GenerateJwtParams) {
  const {secret, headers = {}} = params

  const encodedSecret = new TextEncoder().encode(secret)
  const alg = 'HS256'

  const jwt = await new SignJWT({
    ...params.payload,
  })
    .setProtectedHeader({alg, ...headers})
    .setIssuedAt()
    .sign(encodedSecret)

  return jwt
}
