import React from 'react'
import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import {
  PurchasePageBlock,
  PurchasePageTemplate,
} from 'Event/Marketplace/purchase-page'
import {usePurchase} from 'Event/Marketplace/PurchaseContext'

export default function MissingRequiredBlocksAlert(props: {
  template: PurchasePageTemplate
}) {
  const {template} = props
  const error = useError(template)

  if (!error) {
    return null
  }

  return <ErrorText color="error">{error}</ErrorText>
}

function useError(template: PurchasePageTemplate) {
  const {tickets} = usePurchase()
  const hasBlock = useHasBlock(template)

  const hasPurchaseForm = hasBlock('PurchaseForm')
  const hasTicketSelector = hasBlock('TicketSelector')

  if (tickets.length === 0) {
    return 'At least 1 Ticket is required for the Purchase Page to function as expected.'
  }

  if (tickets.length > 0 && !hasPurchaseForm) {
    return 'At least 1 Purchase Form Content Block is required for the Purchase Page to function as expected.'
  }

  if (tickets.length >= 2 && (!hasPurchaseForm || !hasTicketSelector)) {
    return 'At least 1 Ticket Selector Content Block and 1 Purchase Form Content Block is required for the Purchase Page to function as expected.'
  }

  return null
}

function useHasBlock(template: PurchasePageTemplate) {
  const {sections} = template

  return (type: PurchasePageBlock['type']) => {
    if (!sections) {
      return false
    }

    for (const key of Object.keys(sections)) {
      if (!sections[key].blocks) {
        continue
      }

      for (const blockKey of Object.keys(sections[key].blocks)) {
        if (sections[key].blocks[blockKey]['type'] === type) {
          return true
        }
      }
    }

    return false
  }
}

export const ErrorText = styled(Typography)`
  text-align: center;
  background: ${(props) => props.theme.colors.error};
  color: white;
  padding: 4.5px 0px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  left: 232px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 0px;
  }
`
