import {useEvent} from 'Event/EventProvider'
import ForgotPassword from 'Event/auth/ForgotPassword'
import DisabledOverlay from 'lib/ui/DisabledOverlay'
import {DesignBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import Page from 'organization/Event/TemplateEditor/Page'
import React from 'react'

export default function ForgotPasswordConfig() {
  const event = useEvent()
  const emailLoginOnly = event.event.allows_email_only_login

  return (
    <DesignBreadcrumbs page="Forgot Password">
      <Page>
        <>
          <DisabledOverlay
            show={!!emailLoginOnly}
            message={
              'The Password function has been disabled for this event. <br/> The Password function can be enabled on the Event Settings page.'
            }
          />
          <ForgotPassword />
        </>
      </Page>
    </DesignBreadcrumbs>
  )
}
