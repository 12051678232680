import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import { ListDropdownItemDefinition, addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import fontSizeIcon from '../theme/font-size.svg';
import { FONT_SIZE_LIST } from '../utils';
import Command from '@ckeditor/ckeditor5-core/src/command';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import CustomButtonFontSizeCommand from './custombuttonfontsizecommand';

class CustomButtonFontSizeUI extends Plugin {
    static get requires() {
        return [ ContextualBalloon ];
    }

    static get pluginName() {
        return 'CustomButtonFontSizeUI';
    }

    init() {
        this._createButton();
    }

    private _createButton() {
        const editor = this.editor;

        editor.ui.componentFactory.add('customButtonFontSize', locale => {
            const dropdownView = createDropdown(locale);
            const command = editor.commands.get('customButtonFontSize') as CustomButtonFontSizeCommand;

            addListToDropdown(dropdownView, getDropdownItemsDefinitions(command));

            (dropdownView.buttonView as ButtonView).set({
                label: 'Font Size',
                icon: fontSizeIcon,
                tooltip: true,
                withText: false
            });

            dropdownView.bind('isEnabled').to(command);

            dropdownView.extendTemplate({
                attributes: {
                    class: ['ck-font-size-dropdown']
                }
            });

            this.listenTo(dropdownView, 'execute', evt => {
                editor.execute('customButtonFontSize', (evt.source as any).commandParam);
                editor.editing.view.focus();
            });

            return dropdownView;
        });
    }
}

function getDropdownItemsDefinitions(command: Command): Collection<ListDropdownItemDefinition> {
    const itemDefinitions = new Collection<ListDropdownItemDefinition>();

    for (const name of FONT_SIZE_LIST) {
        const definition: ListDropdownItemDefinition = {
            type: 'button',
            model: new Model({
                commandParam: name.toString(),
                label: name.toString(),
                class: 'ck-fontsize-option',
                withText: true
            })
        };

        definition.model.bind('isOn').to(command, 'value', (value: unknown) => value === name);

        // Add the item definition to the collection.
        itemDefinitions.add(definition);
    }

    return itemDefinitions;
}

export default CustomButtonFontSizeUI;
