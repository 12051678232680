import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import {useEvent} from 'Event/EventProvider'
import {MailchimpTag} from 'Event/mailchimp'
import {onChangeStringHandler} from 'lib/dom'
import React from 'react'

export default function MailchimpTagInput(
  props: Omit<TextFieldProps, 'value' | 'onChange'> & {
    onChange: (tag: MailchimpTag | null) => void
    value?: MailchimpTag | null
    inputVariant?: 'filled' | 'standard' | 'outlined'
  },
) {
  const {disabled, value, onChange, error, ...textFieldProps} = props
  const {event} = useEvent()

  const name = value?.name || ''

  if (!event.has_mailchimp) {
    return null
  }

  return (
    <TextField
      value={name}
      label="Mailchimp Tag"
      variant={props.inputVariant}
      fullWidth
      inputProps={{
        'aria-label': 'mailchimp tag',
      }}
      onChange={onChangeStringHandler((name) => onChange({name}))}
      disabled={disabled}
      error={Boolean(error)}
      helperText={error}
      {...textFieldProps}
    />
  )
}
